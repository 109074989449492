import * as React from "react";
import {useServices} from "../../../../../hooks/useServices";
import {useEventState} from "../../../../../hooks/useEventState";
import {FrontendRoute} from "../../../../../../../shared/routes/FrontendRoute";
import {SessionStateType} from "../../../../../../../shared/types/SessionStateType";

/******************************************************************
 * SessionResultBadge
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function SessionResultBadge(props: {
    position: number
    sessionPath: string
    sessionState: SessionStateType
    fastestLap: boolean
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {router} = useServices()
    const {eventPath, eventOwner, eventOwnerType} = useEventState()

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function gotoSession() {
        router.showRoute(
            FrontendRoute.EVENT_SESSION(
                eventOwnerType,
                eventOwner?.path,
                eventPath,
                props.sessionPath))
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="session-result-badge"
            data-fastest-lap={props.fastestLap}
            data-position={props.position}
            data-clickable={!!props.sessionPath}
            data-not-finished={props.sessionState && props.sessionState !== "finished"}
            data-not-participated={!props.position}
            onClick={gotoSession}>
            {props.position ?? "–"}
        </div>
    );

}
