import * as React from "react"
import {useEffect, useRef, useState} from "react"
import {UploadableImage} from "../uploadable/UploadableImage"
import {SharedConfig} from "../../../../../shared/config/SharedConfig"
import {useServices} from "../../../hooks/useServices"
import {IUserData} from "../../../../../shared/models/IUserData";
import {IGroupData} from "../../../../../shared/models/IGroupData";
import {FrontendBaseRouteType} from "../../../../../shared/types/FrontendBaseRouteType";
import {useElementSize} from "../../../hooks/useElementSize";

/******************************************************************
 * AvatarImage
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function AvatarImage(props: {
    user?: IUserData
    mode?: "settings" | "default"
    group?: IGroupData
    editable?: boolean
}) {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const imageRef = useRef<HTMLDivElement>()

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {api, dict} = useServices()
    const [imageElementWidth]= useElementSize(imageRef)

    /* ----------------------------------------------------------------
    * STATES
    * --------------------------------------------------------------*/

    const [imageSrc, setImageSrc] = useState<string>()

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        if (!props.user && !props.group) return
        updateImageSource()
    }, [props.group, props.user, imageElementWidth])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    async function upload(formData: FormData): Promise<Response> {
        switch (routeType()) {
            case "member":
                return await api.user.uploadAvatar(formData)
            case "group":
                formData.append("groupID", props.group?._id)
                return await api.group.uploadAvatar(formData)
        }
        return null
    }

    async function updateImageSource() {
        switch (routeType()) {
            case "member":
                if (props.user?.avatar) {
                    setImageSrc(api.file.getImageURLByFile(props.user.avatar, imageElementWidth))
                } else {
                    setImageSrc(require("ASSETS/images/default-user-avatar.png"))
                }
                break;
            case "group":
                if (props.group?.avatar) {
                    setImageSrc(api.file.getImageURLByFile(props.group.avatar, imageElementWidth))
                } else {
                    setImageSrc(require("ASSETS/images/default-group-avatar.png"))
                }
                break;
        }
    }

    function showUploadButton() {
        if (props.mode == "settings") {
            return true
        }
        switch (routeType()) {
            case "member":
                return !props.user?.avatar
            case "group":
                return !props.group?.avatar
        }
        return false
    }

    function routeType(): FrontendBaseRouteType {
        if (props.user) {
            return "member"
        }
        if (props.group) {
            return "group"
        }
        return null
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="avatar-image"
            style={{
                aspectRatio: SharedConfig.IMAGE_AVATAR_ASPECT_RATIO,
                position: "relative",
                width: props.mode == "settings" ? "200px" : null
            }}>
            <UploadableImage
                ref={imageRef}
                uploadMethode={upload}
                showUploadButton={showUploadButton()}
                editable={props.editable}
                framing={props.mode == "settings" ? (props.user ? "circle" : "rounded-8") : null}
                imageSrc={imageSrc}
                buttonLabel={dict("form.upload.avatar.select")}/>
        </div>
    )

}
