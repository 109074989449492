import * as React from "react";
import {DriverLicenseType} from "../../../../../shared/types/DriverLicenseType";
import {Icon} from "../../icons/Icon";
import {DriverLicenseUtils} from "../../../../../shared/utils/DriverLicenseUtils";
import {IconType} from "../../icons/IconType";

/******************************************************************
 * DriverLicenseBadge
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export type DriverLicenseBadgeSize = "extra-large" | "large" | "normal" | "tiny"

export function DriverLicenseBadge(props: {
    licenseType: DriverLicenseType
    size?: DriverLicenseBadgeSize
}) {

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function scale(): number {
        switch (props.size) {
            case "extra-large":
                return 1.4
            case "large":
                return 1.2
            case "tiny":
            default:
                return 1
        }
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="driverlicense-badge">
            <Icon
                type={DriverLicenseUtils.getLicenseIconName(props.licenseType, props.size == "tiny") as IconType}
                scale={scale()}/>
        </div>
    );

}
