import * as React from "react";
import {Headline} from "../../../../ui/text/headings/Headline";
import {ContentLayout} from "../../../../ui/layout/content/ContentLayout";
import {Badge} from "../../../../ui/info/badge/Badge";
import {FrontendMailUtils} from "../utils/FrontendMailUtils";
import {Divider} from "../../../../ui/utils/divider/Divider";
import {IconButton} from "../../../../ui/buttons/icon/IconButton";
import {LabelButton} from "../../../../ui/buttons/label/LabelButton";
import {useServices} from "../../../../hooks/useServices";
import {MailModalType} from "../MailModalType";
import {IMailData} from "../../../../../../shared/models/IMailData";
import {IUserData} from "../../../../../../shared/models/IUserData";
import {SendTestmail} from "./sendtest/SendTestmail";

/******************************************************************
 * MailModalHeader
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function MailModalHeader(props: {
    mailData: IMailData
    recipients: IUserData[]
    editable: boolean
    modalType: MailModalType
    showPreview: boolean
    onTogglePreview: (showPreview: boolean) => void
    onRequestSendMail: () => void
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {state, dict} = useServices()

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function title(): string {
        switch (props.mailData?.state) {
            case "template":
                return dict("mail.editor.title.template")
            case "draft":
                return props.modalType
                    ? dict("mail.editor.title.new")
                    : dict("mail.editor.title.edit")
            case "sent":
                return dict("mail.editor.title.sent")
            case "sending":
            case "failed":
                return dict("mail.editor.title.sending")
        }

    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            className="mail-modal-header"
            columnTemplate="auto min-content"
            justifyContent="space-between"
            alignItems="start">
            <Headline
                text={title()}
                style="modal"/>
            <ContentLayout
                gap="tiny"
                alignItems="center"
                columnTemplate={props.showPreview
                    ? `repeat(${props.editable ? 6 : 1}, min-content)`
                    : `repeat(${props.editable ? 5 : 1}, min-content)`}>
                <Badge
                    size="small"
                    color={FrontendMailUtils.stateColor(props.mailData)}
                    label={props.mailData ? dict("mail.state." + props.mailData?.state) : "–"}/>
                {props.editable && <>
                    <Divider style="action-bar"/>
                    <IconButton
                        type="help"
                        size="small"
                        scale={0.8}
                        onClick={() => state.showHelp.setValue("mail.edit")}/>
                    <Divider style="action-bar"/>
                    <IconButton
                        type={props.showPreview ? "hidden" : "visible"}
                        size="small"
                        onClick={() => props.onTogglePreview(!props.showPreview)}/>
                </>}
                {props.mailData?.state !== "template" && props.showPreview && props.editable &&
                    <ContentLayout
                        gap="tiny"
                        alignItems="center"
                        columnTemplate="min-content min-content">
                        <Divider style="action-bar"/>
                        <LabelButton
                            label={dict("mail.send.button.label")}
                            style="primary-small"
                            disabled={(props.mailData?.subject?.length ?? 0) == 0}
                            onClick={() => state.showConfirm.setValue({
                                type: "sendMail",
                                customElement: <SendTestmail mailID={props.mailData._id}/>,
                                payload: {
                                    onSendConfirmed: props.onRequestSendMail,
                                    numRecipients: props.recipients.length
                                }
                            })}/>
                    </ContentLayout>}
            </ContentLayout>
        </ContentLayout>
    )
}
