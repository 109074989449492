import * as React from "react";
import {useSocketState} from "../../../hooks/useSocketState";

/******************************************************************
 * SocketIndicator
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function SocketIndicator() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {isSocketConnected} = useSocketState();

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="socket-indicator" data-connected={isSocketConnected}/>
    );

}
