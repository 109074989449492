import * as React from "react";

/******************************************************************
 * OneEightyDriftTargetIcon
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function OneEightyDriftTargetIcon(props: {
    scale?: number
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="oneeighty-drift-target-icon">
            <svg width={22 * (props.scale ?? 1)} height={19 * (props.scale ?? 1)} viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.75 3.44971C11.3398 3.44971 14.25 6.35986 14.25 9.94971C14.25 13.5396 11.3399 16.4497 7.75 16.4497" stroke="white" strokeWidth="2"/>
                <mask id="mask0_1279_6818" style={{maskType: "alpha"}} maskUnits="userSpaceOnUse" x="0" y="6" width="22" height="7">
                    <rect y="8.94971" width="22" height="2" fill="white"/>
                    <rect y="11.9497" width="22" height="1" fill="white"/>
                    <rect y="6.94971" width="22" height="1" fill="white"/>
                </mask>
                <g mask="url(#mask0_1279_6818)">
                    <path d="M0 6.94971H7.96887C9.64292 6.94971 11 8.30679 11 9.98083V9.98083C11 11.6549 9.64292 13.012 7.96887 13.012H0V6.94971Z" fill="white"/>
                    <path d="M16.0583 6.19971H22V13.762H16.0583C16.0583 13.762 17.059 12.4742 17.059 9.90996C17.059 7.34573 16.0583 6.19971 16.0583 6.19971Z" fill="white"/>
                </g>
            </svg>
        </div>
    );

}
