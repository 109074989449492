import {FrontendSpeaker} from "../../../core/services/FrontendSpeaker";
import {StateValue} from "@webfruits/toolbox/dist/state/StateValue";
import {FrontendServices} from "../../../core/FrontendServices";
import {SpeakerUtils} from "../../../utils/SpeakerUtils";

/******************************************************************
 * StateAnnouncements
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class StateAnnouncements {

    /******************************************************************
     * Properties
     *****************************************************************/

    public socketDisconnected = new StateValue<boolean>()
    public enteredDriver = new StateValue<boolean>()
    public invalidDriver = new StateValue<boolean>()
    public startedDriver = new StateValue<boolean>()
    public finishedDriver = new StateValue<boolean>()

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(private _services: FrontendServices) {
        this.initAnnouncements();
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public announceSocketDisconnected() {
        if (!this.socketDisconnected.getValue()) return
        let text = this.dict("speaker.text.state.socketDisconnected")
        this.speaker.speak(text, true)
    }

    public announceEnteredDriver(name: string) {
        if (!this.enteredDriver.getValue()) return
        let text = this.dict("speaker.text.state.enteredDriver")
        text = text.replace("{NAME}", name)
        this.speaker.speak(text)
    }

    public announceInvalidDriver(name: string) {
        if (!this.invalidDriver.getValue()) return
        let text = this.dict("speaker.text.state.invalidDriver")
        text = text.replace("{NAME}", name)
        this.speaker.speak(text, true)
    }

    public announceStartedDriver(name: string) {
        if (!this.startedDriver.getValue()) return
        let text = this.dict("speaker.text.state.startedDriver")
        text = text.replace("{NAME}", name)
        this.speaker.speak(text)
    }

    public announceFinishedDriver(name: string) {
        if (!this.finishedDriver.getValue()) return
        let text = this.dict("speaker.text.state.finishedDriver")
        text = text.replace("{NAME}", name)
        this.speaker.speak(text)
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initAnnouncements() {
        SpeakerUtils.initAnnoucementSetting(this.socketDisconnected, "state.socketDisconnected")
        SpeakerUtils.initAnnoucementSetting(this.enteredDriver, "state.enteredDriver")
        SpeakerUtils.initAnnoucementSetting(this.invalidDriver, "state.invalidDriver")
        SpeakerUtils.initAnnoucementSetting(this.startedDriver, "state.startedDriver")
        SpeakerUtils.initAnnoucementSetting(this.finishedDriver, "state.finishedDriver")
    }

    private get speaker(): FrontendSpeaker {
        return this._services.speaker
    }

    private dict(key: string): string {
        return this._services.dict.get(key)
    }


}
