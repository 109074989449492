import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {useServices} from "../../../../../hooks/useServices";
import {Headline} from "../../../../../ui/text/headings/Headline";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {LabelButton} from "../../../../../ui/buttons/label/LabelButton";
import {ICommandLineResponse} from "../../../../../../../shared/types/ICommandLineResponse";
import {ISystemFileData} from "../../../../../../../shared/types/ISystemFileData";
import {Table} from "../../../../../ui/table/Table";
import {ITableColumnDefinition} from "../../../../../ui/table/ITableColumnDefinition";
import {TimeUtils} from "../../../../../../../shared/utils/TimeUtils";
import {IconType} from "../../../../../ui/icons/IconType";
import {Modal} from "../../../../modals/Modal";
import {SizeUtils} from "../../../../../../../shared/utils/SizeUtils";
import {SelectInput, SelectInputOptionType} from "../../../../../ui/form/elements/select/SelectInput";
import {DatabaseBackupScheduleType} from "../../../../../../../shared/types/DatabaseBackupScheduleType";
import {useSocketSignals} from "../../../../../hooks/useSocketSignals";
import {useMobileStyle} from "../../../../../hooks/useMobileStyle";
import {useEnviroment} from "../../../../../hooks/useEnviroment";

/******************************************************************
 * DatabaseBackup
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function DatabaseBackup() {

    /* ----------------------------------------------------------------
 	 * REF
 	 * --------------------------------------------------------------*/

    const ref = useRef<HTMLDivElement>()

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, api, state} = useServices();
    const {serverAdminUpdate} = useSocketSignals()
    const [isMobileMode] = useMobileStyle(ref, 600)
    const {isDevEnviroment} = useEnviroment();

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [backupProgressing, setBackupProgressing] = useState<string>()
    const [isLoadingData, setIsLoadingData] = useState(true)
    const [backupList, setBackupList] = useState<ISystemFileData[]>()
    const [restoreCandidate, setRestoreCandidate] = useState<ISystemFileData>()
    const [currentSchedule, setCurrentSchedule] = useState<DatabaseBackupScheduleType>()

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        loadBackupList()
        updateCurrentSchedule()
    }, [serverAdminUpdate])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function loadBackupList() {
        setIsLoadingData(true)
        const fileList: ISystemFileData[] = await api.admin.getDatabaseBackups()
        setBackupList(fileList)
        setIsLoadingData(false)
    }

    async function updateCurrentSchedule() {
        const schedule = await api.settings.getValue("database.backup.schedule")
        setCurrentSchedule(schedule)
    }

    async function createBackup() {
        setBackupProgressing("create")
        const response: ICommandLineResponse = await api.admin.createDatabaseBackup()
        if (response.error) {
            state.showMessage.setValue({
                type: "data",
                message: "**" + dict(response.error.code) + "**  \n –––  \n" + response.log
            })
        }
        setBackupProgressing(null)
    }

    async function installMongoDBTools() {
        setBackupProgressing("install")
        const response: ICommandLineResponse = await api.admin.installMongoDBTools()
        state.showMessage.setValue({
            type: "data",
            message: response.error
                ? "**" + dict(response.error.code) + "**  \n –––  \n" + response.log
                : response.log
        })
        setBackupProgressing(null)
    }

    async function deleteBackup(data: ISystemFileData): Promise<Response> {
        setBackupProgressing("delete")
        await api.admin.deleteDatabaseBackup(data.path)
        setBackupProgressing(null)
        return null
    }

    async function restoreBackup(): Promise<Response> {
        if (!restoreCandidate) {
            return null
        }
        setBackupProgressing("restore")
        const response: ICommandLineResponse = await api.admin.restoreDatabaseBackup(restoreCandidate.path)
        state.showMessage.setValue({
            type: "data",
            message: response.error
                ? "**" + dict(response.error.code) + "**  \n –––  \n" + response.log
                : response.log
        })
        setRestoreCandidate(null)
        setBackupProgressing(null)
        return null
    }

    function backupScheduleOptions(): SelectInputOptionType<DatabaseBackupScheduleType>[] {
        return [
            {text: dict("admin.database.backup.schedule.never"), value: "never"},
            {text: dict("admin.database.backup.schedule.hourly"), value: "hourly"},
            {text: dict("admin.database.backup.schedule.daily"), value: "daily"},
        ];
    }

    async function updateBackupSchedule(value: string) {
        await api.settings.setValue("database.backup.schedule", value)
    }

    function columnsDefinition(): ITableColumnDefinition<ISystemFileData>[] {
        return [{
            type: "text",
            dataKey: "date",
            sortKey: "date",
            columnName: dict("admin.database.backup.table.date"),
            value: data => TimeUtils.formatDate(data.date),
            size: "150px"
        }, {
            type: "text",
            dataKey: "name",
            sortKey: "name",
            columnName: dict("admin.database.backup.table.name"),
            size: "3fr"
        }, {
            type: "text",
            dataKey: "size",
            sortKey: "size",
            align: "right",
            columnName: dict("admin.database.backup.table.size"),
            value: data => SizeUtils.bytesToSize(data.size),
            size: "100px"
        }, {
            type: "icon-button",
            size: "40px",
            value: () => "restore" as IconType,
            action: (data: ISystemFileData) => {
                setRestoreCandidate(data)
                return null
            }
        }, {
            type: "delete",
            size: "40px",
            action: deleteBackup
        }];
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (<>
        <ContentLayout
            ref={ref}
            className="database-backup"
            framed={true}
            gap="small">
            <ContentLayout
                justifyContent="space-between"
                columnTemplate="auto min-content">
                <Headline text={dict("admin.database.backup.title")} style="h5-underlined"/>
                <ContentLayout columns={isMobileMode ? 1 : 3} alignItems="center" gap="small" justifyItems="end">
                    {isDevEnviroment &&
                        <LabelButton
                            label={dict("admin.database.install.mongodbtools")}
                            style="secondary-small"
                            disabled={!!backupProgressing}
                            progressing={backupProgressing === "install"}
                            onClick={installMongoDBTools}/>}
                    <LabelButton
                        label={dict("admin.database.backup.create")}
                        style="primary-small"
                        disabled={!!backupProgressing}
                        progressing={backupProgressing === "create"}
                        onClick={createBackup}/>
                    <SelectInput
                        options={backupScheduleOptions()}
                        size="small"
                        defaultValue={currentSchedule}
                        onChange={updateBackupSchedule}/>
                </ContentLayout>
            </ContentLayout>
            {backupList?.length > 0 &&
                <Table<ISystemFileData>
                    rowsData={backupList}
                    columnsDefinition={columnsDefinition()}
                    sortKey="date"
                    isLoadingData={isLoadingData}
                    style="admin-compact"/>}
        </ContentLayout>
        {restoreCandidate &&
            <Modal
                width="small"
                closeAction={!!backupProgressing ? null : () => setRestoreCandidate(null)}>
                <ContentLayout>
                    <Headline text={dict("admin.database.backup.restore.title")} style="modal"/>
                    <ContentLayout columns={2} gap="small">
                        <LabelButton
                            label={dict("admin.database.backup.restore.cancel")}
                            style="secondary-small"
                            disabled={!!backupProgressing}
                            onClick={() => setRestoreCandidate(null)}/>
                        <LabelButton
                            label={dict("admin.database.backup.restore.button")}
                            style="primary-small"
                            disabled={!!backupProgressing}
                            progressing={!!backupProgressing}
                            onClick={restoreBackup}/>
                    </ContentLayout>
                </ContentLayout>
            </Modal>}
    </>);

}
