import * as React from "react";
import {Headline} from "../../../../ui/text/headings/Headline";
import {Form} from "../../../../ui/form/Form";
import {Input} from "../../../../ui/form/elements/input/Input";
import {Modal} from "../../Modal";
import {useServices} from "../../../../hooks/useServices";
import {IKeyValuePair} from "../../../../../../shared/types/IKeyValuePair";
import {SharedConfig} from "../../../../../../shared/config/SharedConfig";
import {IMultilangString} from "../../../../../../shared/models/submodels/IMultilangString";

/******************************************************************
 * CreateAcademyChapterModal
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function CreateAcademyChapterModal(props: {
    onClose: () => void
    onSuccess: () => void
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, api} = useServices();

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function createChapter(formData: IKeyValuePair): Promise<Response> {
        let title: IMultilangString = {}
        SharedConfig.LANGUAGES.map(language => {
            title[language] = formData[language]
        })
        const response = await api.academy.createChapter(title)
        if (response.status === 200) {
            props.onSuccess()
        }
        return response
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal
            width="small"
            closeAction={props.onClose}
            className="academy-create-chapter-modal">
            <Headline
                text={dict("academy.create.chapter.title")}
                style="modal"/>
            <Form
                actionLabel={dict("academy.create.chapter.submit.label")}
                onSuccess={async () => props.onClose()}
                action={createChapter}>
                <>{SharedConfig.LANGUAGES.map(language => (
                    <Input
                        key={language}
                        label={dict(`input.multilang.${language}.label`)}
                        type="text"
                        id={language}
                        required={true}
                        placeholder={dict("academy.create.chapter.placeholder")}/>
                ))}</>
            </Form>
        </Modal>
    );

}
