import * as React from "react";
import {ContextMenu} from "../ContextMenu";
import {useServices} from "../../../hooks/useServices";
import {useRouteOwner} from "../../../hooks/useRouteOwner";
import {ContextMenuLabel} from "../elements/ContextMenuLabel";
import {ContextMenuDivider} from "../elements/ContextMenuDivider";
import {ITrackFilterData} from "../../../../../shared/models/submodels/ITrackFilterData";
import {useGroupState} from "../../../hooks/useGroupState";

/******************************************************************
 * TrackFilterContextMenu
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function TrackFilterContextMenu(props: {
    data: ITrackFilterData
    onActionSelected?: () => void
}) {

    /* ----------------------------------------------------------------
     * SERVICES
     * --------------------------------------------------------------*/

    const {dict, state} = useServices();
    const {isRouteOwnerAuthUser} = useRouteOwner();
    const {isGroupAdmin} = useGroupState();

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function editTrackFilter() {
        props.onActionSelected?.()
        state.showTrackFilter.setValue(props.data)
    }

    function deleteTrackFilter() {
        props.onActionSelected?.()
        state.showConfirm.setValue({
            type: "deleteTrackFilter",
            payload: {
                trackFilterName: props.data.name,
                trackFilterID: props.data._id,
                trackFilterPath: props.data.path
            }
        })
    }

    function editLabel() {
        if (props.data.isPreset) {
            return dict("track.filter.readonly.label");
        }
        return dict("track.filter.edit.label");
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContextMenu
            type="icon"
            iconType="menuDots"
            iconScale={0.9}
            enabled={isRouteOwnerAuthUser || isGroupAdmin}>
            <ContextMenuLabel
                label={editLabel()}
                icon={props.data.isPreset ? "blueprint" : "edit"}
                onClick={editTrackFilter}/>
            {!props.data.isPreset &&
                <ContextMenuDivider style="small"/>}
            {!props.data.isPreset &&
                <ContextMenuLabel
              label={dict("track.filter.delete.label")}
              icon="delete"
              warning={true}
              onClick={deleteTrackFilter}/>}
        </ContextMenu>
    );

}
