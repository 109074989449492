import * as React from "react";
import {IStintData} from "../../../../../../../shared/models/IStintData";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {useServices} from "../../../../../hooks/useServices";
import {LabelValueText} from "../../../../../ui/text/labelvalue/LabelValueText";
import {CapiTargetUtils} from "../../../../../../../shared/utils/CapiTargetUtils";
import {ICapiTargetData} from "../../../../../../../shared/types/ICapiTargetData";
import {StintSetupWarnings} from "../setup/StintSetupWarnings";
import {TimeUtils} from "../../../../../../../shared/utils/TimeUtils";
import {StintUtils} from "../../../../../../../shared/utils/StintUtils";

/******************************************************************
 * StintGymkhanaMetrics
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function StintGymkhanaMetrics(props: {
    stint: IStintData
}) {

    /* ----------------------------------------------------------------
     * REFS
     * --------------------------------------------------------------*/

    const {dict} = useServices()

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function scoredTargets(): ICapiTargetData[] {
        return props.stint.targetsData.filter(target => {
            return (target._disabled === false || !target._disabled)
                && CapiTargetUtils.getTargetCode(target) !== 0
                && target.score > 0
        })
    }

    function speedTargets(): ICapiTargetData[] {
        return scoredTargets().filter(target => {
            return targetCode(target) === 4
        })
    }

    function angleTargets(): ICapiTargetData[] {
        return scoredTargets().filter(target => {
            return targetCode(target) === 5
        })
    }

    function oneEightyTargets(): ICapiTargetData[] {
        return scoredTargets().filter(target => {
            return targetCode(target) === 6
        })
    }

    function threeSixtyTargets(): ICapiTargetData[] {
        return scoredTargets().filter(target => {
            return targetCode(target) === 7
        })
    }

    function targetCode(target: ICapiTargetData) {
        return CapiTargetUtils.getTargetCode(target)
    }

    function stintInvalid(): boolean {
        return props.stint.state === "invalid"
    }

    function lostPointsByFalseStart(): number {
        const rawTargetScore = props.stint?.targetsData?.reduce((sum, target) => {
            return sum + (target.score ?? 0)
        }, 0)
        const driftScore = props.stint?.endData?.total_score
        return (driftScore ?? 0) - (rawTargetScore ?? 0)
    }

    function hasFinishTarget(): boolean {
        return !!props.stint?.targetsData?.find(target => targetCode(target) === 0)
    }

    function hasTimedout(): boolean {
        return drivenTime() > 60
    }

    function drivenTime(): number {
        const latestTarget = props.stint?.targetsData[props.stint?.targetsData?.length - 1]
        if (!latestTarget) return null
        return TimeUtils.calcTimeInSeconds(latestTarget.crossing_time, props.stint.startData.signal_time)
    }

    function hasNoEndData(): boolean {
        return !props.stint.endData?.finished_time
    }

    function drivenTimeInfo(): string {
        if (!hasFinishTarget()) {
            return dict("stint.gymkhana.noFinishInfo")
        }
        if (hasNoEndData()) {
            return dict("stint.gymkhana.drivenTime.noEndDataInfo")
        }
        if (hasTimedout()) {
            return dict("stint.gymkhana.drivenTime.timedoutInfo") + (drivenTime()
                ? " – " + TimeUtils.formatDrivenTime(drivenTime())
                : "")
        }
        return TimeUtils.formatDrivenTime(props.stint?.drivenTime) ?? "–"
    }

    function score(): string {
        if (hasNoEndData()) {
            return dict("stint.gymkhana.score.noEndDataInfo")
        }
        const targetsScore = props.stint.targetsData.reduce((sum, target) => {
            return sum + (target.score ?? 0)
        }, 0)
        const endScore = props.stint.endData?.total_score
        if (hasTimedout() && targetsScore > endScore) {
            return endScore.toString() + " (" + (targetsScore) + ")"
        }
        return endScore?.toString() ?? "–"
    }

    function isInvalidDrivenTime(): boolean {
        return !hasFinishTarget()
            || hasTimedout()
            || StintUtils.hasGymkhanaTargetsAfterTimeout(props.stint)
            || hasNoEndData()
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout className="stint-targets-metrics">
            {stintInvalid() && <ContentLayout
                framed={true}
                gap="small">
                <StintSetupWarnings
                    stint={props.stint}/>
            </ContentLayout>}
            {!stintInvalid() && <ContentLayout
                framed={true}
                gap="small">
                <LabelValueText
                    size="small"
                    label={dict("stint.metrics.signalTime")}
                    value={TimeUtils.formatDate(props.stint?.startData?.signal_time) ?? "–"}/>
                <LabelValueText
                    size="small"
                    label={dict("stint.metrics.drivenTime")}
                    warning={isInvalidDrivenTime()}
                    value={drivenTimeInfo()}/>
                <LabelValueText
                    size="small"
                    label={dict("stint.metrics.totalScore")}
                    warning={hasNoEndData()}
                    value={score()}/>
                {props.stint?.falseStart && <LabelValueText
                    size="small"
                    warning={true}
                    label={dict("stint.metrics.falseStart")}
                    value={lostPointsByFalseStart()?.toString()}/>}
                <LabelValueText
                    size="small"
                    label={dict("stint.metrics.speedTargets")}
                    value={speedTargets()?.length.toString() ?? "–"}/>
                <LabelValueText
                    size="small"
                    label={dict("stint.metrics.angleTargets")}
                    value={angleTargets()?.length.toString() ?? "–"}/>
                <LabelValueText
                    size="small"
                    label={dict("stint.metrics.oneEightyTargets")}
                    value={oneEightyTargets()?.length.toString() ?? "–"}/>
                <LabelValueText
                    size="small"
                    label={dict("stint.metrics.threeSixtyTargets")}
                    value={threeSixtyTargets()?.length.toString() ?? "–"}/>
            </ContentLayout>}
        </ContentLayout>
    );

}
