import {FrontendServices} from "../core/FrontendServices";
import {APIRoute} from "../../shared/routes/APIRoute";
import {LogType} from "../../shared/types/LogType";
import {ILogEntryData} from "../../shared/types/ILogEntryData";
import {ConvertDataUtils} from "../../shared/utils/ConvertDataUtils";
import {LogLevelType} from "../../shared/types/LogLevelType";
import {MongoObjectIDType} from "../../shared/types/MongoObjectIDType";

/******************************************************************
 * LogAPI
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class LogAPI {

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(private _frontend: FrontendServices) {
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    async getSessionLogData(sessionID: MongoObjectIDType): Promise<ILogEntryData[]> {
        if (!sessionID) return null
        const response = await this._frontend.api.request("GET", APIRoute.SESSION_LOG, {sessionID}, true)
        if (response.status !== 200) {
            return null
        }
        return await response.json();
    }

    async getLogData(logType: LogType): Promise<ILogEntryData[]> {
        const response = await this._frontend.api.request("GET", APIRoute.LOG, {logType}, true)
        if (response.status !== 200) {
            return null
        }
        const textResponse = await response.text();
        return ConvertDataUtils.convertTextToLogEntries(textResponse)
    }

    async getHistoryLogData(filePath: string): Promise<ILogEntryData[]> {
        const response = await this._frontend.api.request("GET", APIRoute.HISTORY_LOG, {filePath}, true)
        if (response.status !== 200) {
            return null
        }
        const textResponse = await response.text();
        return ConvertDataUtils.convertTextToLogEntries(textResponse)
    }

    async getLogHistory(logType: LogType): Promise<string[]> {
        const response = await this._frontend.api.request("GET", APIRoute.LOG_HISTORY, {logType}, true)
        if (response.status !== 200) {
            return null
        }
        const text = await response.text()
        return text?.split("\n")?.filter(line => line !== "")
    }

    async info(message: string): Promise<boolean> {
        const response = await this._frontend.api.request("POST", APIRoute.LOG, {
            message: message,
            level: "info" as LogLevelType
        }, true)
        return response.status === 200
    }

    async error(error: Error): Promise<boolean> {
        const response = await this._frontend.api.request("POST", APIRoute.LOG, {
            error: {
                name: error?.name,
                message: error?.message,
                stack: error?.stack?.toString()
            },
            message: error?.message,
            level: "error" as LogLevelType
        }, true)
        return response.status === 200
    }

}
