import * as React from "react";
import {ReactElement} from "react";
import {ISessionLeaderboardEntry} from "../../../../../../shared/types/ISessionLeaderboardEntry";
import {TimeUtils} from "../../../../../../shared/utils/TimeUtils";
import {ISessionData} from "../../../../../../shared/models/ISessionData";

/******************************************************************
 * TableLapSplitTimesCell
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function TableLeaderboardLapSectorTimesCell(props: {
    entry: ISessionLeaderboardEntry
}) {

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function sectorElements(): ReactElement[] {
        const elements: ReactElement[] = [];
        const bestLapStint = props.entry.bestLapStint;
        const session: ISessionData = props.entry.session;
        const times = bestLapStint?.splitTimes;
        for (let i = 0; i < props.entry.session.setup.numSectors; i++) {
            let time = "–";
            if (times
                && times[i] !== undefined
                && times.length == session.setup.numSectors) {
                time = TimeUtils.formatDrivenTime(times[i]);
            }
            elements.push(
                <div key={i} className="table-leaderboard-sectortimes-cell-sector">
                    {time}
                </div>
            );
        }
        return elements
    }

    function theme(): "dark" | "light" {
        switch (props.entry.state) {
            case "ready":
            case "driving":
                return "light"
            case "invalid":
            case "finished":
                return "dark"
        }
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="table-leaderboard-sectortimes-cell"
            data-all-rejected={props.entry.hasOnlyRejectedStints && props.entry.session.setup.useStintApproval}
            data-theme={theme()}>
            {sectorElements()}
        </div>
    );

}
