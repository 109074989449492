import * as React from "react";
import {useEffect, useState} from "react";
import {ContentLayout} from "../../../../ui/layout/content/ContentLayout";
import {useResultState} from "../../../../hooks/useResultState";
import {useServices} from "../../../../hooks/useServices";
import {Table} from "../../../../ui/table/Table";
import {EmptyDataInfo} from "../../../../ui/info/empty/EmptyDataInfo";
import {ITableColumnDefinition} from "../../../../ui/table/ITableColumnDefinition";
import {IScoreboardEntry} from "../../../../../../shared/types/IScoreboardEntry";
import {useEventState} from "../../../../hooks/useEventState";

/******************************************************************
 * ResultScoreboard
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function ResultScoreboard() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, router, state, api} = useServices()
    const {resultScoreboard, resultSessions} = useResultState()
    const {eventChildrenOrder, eventChildren} = useEventState()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [initialized, setInitialized] = useState<boolean>(false)

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        setTimeout(() => setInitialized(true), 100)
    }, [])

    useEffect(() => {
        if (!initialized) return
        updateResultScoreboard()
    }, [eventChildrenOrder, eventChildren])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function updateResultScoreboard() {
        const result = await api.result.getResultByRoute(router.currentFrontendRoute)
        state.result.setValue(result)
    }

    function driverOrTeamHeaderName() {
        const hasTeam = resultScoreboard?.some(entry => !!entry.team)
        const hasDriver = resultScoreboard?.some(entry => !!entry.driver)
        if (hasTeam && hasDriver) {
            return dict("table.head.driversAndTeams")
        }
        if (hasTeam) {
            return dict("table.head.teams")
        }
        return dict("table.head.drivers")
    }

    function columnsDefinition(): ITableColumnDefinition<IScoreboardEntry>[] {
        const numSessions = resultScoreboard?.[0].sessions?.length ?? 0
        const sessionSize = numSessions > 0 ? numSessions * 0.5 + "fr" : null
        return [
            {
                columnName: dict("table.head.position"),
                type: "number",
                size: "60px",
                sortKey: "position",
                value: (entry: IScoreboardEntry) => {
                    return entry.position + "."
                }
            },
            {
                columnName: driverOrTeamHeaderName(),
                type: "scoreboard-driver-team",
                size: "4fr",
                sticky: true
            },
            {
                columnNames: resultScoreboard?.[0].sessions?.map(session => session.sessionName) ?? [],
                type: "scoreboard-sessions",
                size: sessionSize
            },
            {
                dataKey: "participatedSessions",
                columnName: dict("table.head.numSessions"),
                type: "number",
                size: "100px",
                sortKey: "participatedSessions",
                align: "center"
            },
            {
                columnName: dict("table.head.avgPosition"),
                type: "number",
                size: "100px",
                sortKey: "avgPosition",
                align: "center",
                value: (entry: IScoreboardEntry) => entry.avgPosition?.toFixed(1) ?? "–"
            },
            {
                columnName: dict("table.head.avgPoints"),
                type: "number",
                size: "100px",
                align: "center",
                sortKey: "avgPoints",
                value: (entry: IScoreboardEntry) => entry.avgPoints?.toFixed(1) ?? "–"
            },
            {
                dataKey: "points",
                columnName: dict("table.head.points"),
                type: "number-bold",
                size: "100px",
                sortKey: "points",
                align: "center"
            },
        ];
    }

    function emptyDataInfo(): string {
        if (!resultSessions || resultSessions?.length == 0) {
            return dict("result.scoreboard.no.sessions.info")
        }
        return dict("result.scoreboard.no.session.data.info")
            .replaceAll("{SESSION_NAMES}", resultSessions.map(session => session.name).join(", "))
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout className="result-scoreboard">
            {resultScoreboard?.length > 0
                ? <Table
                    style="leaderboard"
                    sortKey="points"
                    rowsData={resultScoreboard}
                    visibleRows={25}
                    columnsDefinition={columnsDefinition()}/>
                : <EmptyDataInfo text={emptyDataInfo()}/>}
        </ContentLayout>
    );

}
