import * as React from "react";
import {ContextMenu} from "../ContextMenu";
import {ContextMenuSelectable} from "../elements/ContextMenuSelectable";
import {ContextMenuDivider} from "../elements/ContextMenuDivider";
import {useServices} from "../../../hooks/useServices";
import {useEventState} from "../../../hooks/useEventState";
import {PrivacyType} from "../../../../../shared/types/PrivacyType";
import {useRouteOwner} from "../../../hooks/useRouteOwner";
import {useTrackState} from "../../../hooks/useTrackState";
import {useRouteStates} from "../../../hooks/useRouteStates";
import {useGroupState} from "../../../hooks/useGroupState";
import {IEventData} from "../../../../../shared/models/IEventData";

/******************************************************************
 * PrivacySelector
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function PrivacySelector(props: {
    eventData?: IEventData
}) {

    /* ----------------------------------------------------------------
     * SERVICES
     * --------------------------------------------------------------*/

    const {api, dict} = useServices();
    const {routeType} = useRouteStates()
    const {groupID, groupPrivacy} = useGroupState();
    const {eventID, eventPrivacy} = useEventState();
    const {trackID, trackPrivacy} = useTrackState();
    const {isRouteOwnerAuthUser} = useRouteOwner();
    const {isGroupAdmin} = useGroupState();

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function changePrivacy(privacy: PrivacyType) {
        if (props.eventData) {
            await api.event.update(props.eventData._id, {privacy: privacy});
            return
        }
        switch (routeType) {
            case "event":
                await api.event.update(eventID, {privacy: privacy});
                break;
            case "track":
                await api.track.update(trackID, {privacy: privacy});
                break;
            case "group":
                await api.group.update(groupID, {privacy: privacy});
                break;
        }
    }

    function privacy(): PrivacyType {
        if (props.eventData) {
            return props.eventData.privacy
        }
        switch (routeType) {
            case "event":
                return eventPrivacy
            case "track":
                return trackPrivacy
            case "group":
                return groupPrivacy
        }
        return null
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContextMenu
            type="icon"
            iconType={privacy()}
            iconScale={0.85}
            iconSize="small"
            stopPropagation={true}
            className="privacy-selector"
            enabled={isRouteOwnerAuthUser || isGroupAdmin}>
            <ContextMenuSelectable
                icon="private"
                label={dict("event.privacy.private")}
                onClick={() => changePrivacy("private")}
                selected={privacy() == "private"}/>
            <ContextMenuDivider style="small"/>
            <ContextMenuSelectable
                icon="public"
                label={dict("event.privacy.public")}
                onClick={() => changePrivacy("public")}
                selected={privacy() == "public"}/>
        </ContextMenu>
    );

}
