import * as React from "react";
import {ApprovalStateType} from "../../../../../shared/types/ApprovalStateType";
import {useServices} from "../../../hooks/useServices";

/******************************************************************
 * ApprovalStateInfo
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function ApprovalStateInfo(props: {
    state: ApprovalStateType
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict} = useServices()

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="approval-state-info"
            data-state-type={props.state ?? "pending"}>
            {dict("approval.state.info." + (props.state ?? "pending"))}
        </div>
    );

}
