import * as React from "react";

/******************************************************************
 * HelpIcon
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function HelpIcon(props: {
    scale?: number
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="help-icon">
            <svg width={16 * (props.scale ?? 1)} height={19 * (props.scale ?? 1)} viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.47266 6.4731C3.47266 6.4731 3.5397 4.93674 5.03415 3.70486C5.92152 2.97346 6.98719 2.76163 7.94206 2.74859C8.81406 2.73789 9.59342 2.88547 10.0594 3.11266C10.856 3.50281 12.4115 4.45255 12.4115 6.4731C12.4115 8.59932 11.053 9.56304 9.50868 10.625C7.96441 11.6869 7.56961 12.7284 7.56961 13.9221" stroke="white" strokeWidth="2.06452" strokeMiterlimit="10" strokeLinecap="round"/>
                    <path d="M7.56961 18.9498C8.3924 18.9498 9.05941 18.2828 9.05941 17.46C9.05941 16.6372 8.3924 15.9702 7.56961 15.9702C6.74681 15.9702 6.0798 16.6372 6.0798 17.46C6.0798 18.2828 6.74681 18.9498 7.56961 18.9498Z" fill="white"/>
            </svg>
        </div>
    );

}
