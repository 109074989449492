import {ErrorCode} from "../keys/ErrorCode";
import {CountryCodeType} from "../types/CountryCodeType";

/******************************************************************
 * CountryValidation
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class CountryValidation {

    static CODES: CountryCodeType[] = ["de", "at", "ch", "unlisted"]

    static schemaValidator() {
        return {
            validate: {
                validator: value => CountryValidation.isValid(value),
                message: ErrorCode.COUNTRY_INVALID
            },
        }
    };

    static isValid(value: CountryCodeType): boolean {
        return CountryValidation.CODES.some(code => code == value)
    }

}
