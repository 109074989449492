import * as React from "react";

/******************************************************************
 * FolderIcon
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function FolderIcon(props: {
    scale?: number
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="folder-icon">
            <svg width={16 * (props.scale ?? 1)} height={11 * (props.scale ?? 1)} viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.8967 8.07773L15.6334 11.9576C15.4573 12.2595 15.2051 12.51 14.902 12.6841C14.5989 12.8582 14.2555 12.9498 13.9059 12.9498H1.40703C0.828187 12.9498 0.467531 12.3219 0.759187 11.8219L3.02244 7.94208C3.19857 7.64014 3.45076 7.38964 3.75388 7.21554C4.05699 7.04144 4.40044 6.94983 4.75 6.94983H17.2489C17.8277 6.94983 18.1884 7.57773 17.8967 8.07773ZM4.75 5.94983H15V4.44983C15 3.62139 14.3284 2.94983 13.5 2.94983H8.5L6.5 0.949829H1.5C0.671562 0.949829 0 1.62139 0 2.44983V11.1388L2.15866 7.4382C2.69419 6.52014 3.68716 5.94983 4.75 5.94983Z" fill="white"/>
            </svg>
        </div>
    );

}
