import * as React from "react";
import {PlanFeatureType} from "../../../../../../shared/types/PlanFeatureType";
import {useServices} from "../../../../hooks/useServices";
import {ContentLayout} from "../../../layout/content/ContentLayout";
import {Headline} from "../../../text/headings/Headline";
import {Divider} from "../../../utils/divider/Divider";
import {Icon, IconColorType} from "../../../icons/Icon";
import {IconType} from "../../../icons/IconType";
import {PlanAccessType} from "../../../../../../shared/types/PlanAccessType";
import {Badge} from "../../../info/badge/Badge";

/******************************************************************
 * PlanFeature
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function PlanFeature(props: {
    feature: PlanFeatureType
    access: PlanAccessType
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, state} = useServices()

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function iconType(): IconType {
        switch (props.access.type) {
            case "none":
                return "close"
            case "partial":
                return "check"
            case "full":
                return "check"
            case "max":
            case "future":
                return null
        }
    }

    function color(): IconColorType {
        switch (props.access.type) {
            case "none":
                return "red"
            case "partial":
                return "yellow"
            case "full":
                return "green"
            case "max":
                return "yellow"
            case "future":
                return "white"
        }
    }

    function iconScale(): number {
        switch (props.access.type) {
            case "none":
                return 1
            case "partial":
                return 1
            case "full":
                return 1
            case "max":
            case "future":
                return null
        }
    }

    function showFeatureInfo() {
        const articleID = dict("info.plan.feature." + props.feature)
        state.showArticle.setValue(articleID)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    function badgeLabel(): string {
        switch (props.access.type) {
            case "max":
                return dict(props.access.dictKey).replaceAll("{MAX}", props.access.value.toString())
            case "future":
                return dict(props.access.dictKey)
        }
        return props.access.type
    }

    return (
        <div
            className="plan-feature"
            onClick={showFeatureInfo}>
            <ContentLayout>
                <ContentLayout
                    justifyContent="space-between"
                    columnTemplate="auto min-content">
                    <ContentLayout
                        gap="tiny"
                        columnTemplate="auto min-content">
                        <Headline
                            text={dict("plan.feature." + props.feature + ".name")}
                            disabled={props.access.type == "none"}
                            style="h4"/>
                        <Icon
                            type="info"
                            scale={0.8}/>
                    </ContentLayout>
                    <ContentLayout justifyItems="center">
                        {iconType()
                            ? <Icon
                                type={iconType()}
                                scale={iconScale()}
                                iconColor={color()}/>
                            : <Badge
                                label={badgeLabel()}
                                hideBackground={true}
                                color={color()}/>}
                    </ContentLayout>
                </ContentLayout>
                <Divider style="modal"/>
            </ContentLayout>
        </div>
    );

}
