import {IStintData} from "../models/IStintData";
import {ISessionData} from "../models/ISessionData";
import {ICapiTargetData} from "../types/ICapiTargetData";
import {DriftTargetCodeType} from "../types/DriftTargetCodeType";
import {TimeUtils} from "./TimeUtils";
import {ITeamData} from "../models/submodels/ITeamData";
import {MongoObjectIDType} from "../types/MongoObjectIDType";
import {IUserData} from "../models/IUserData";
import {CapiTargetUtils} from "./CapiTargetUtils";
import {IStintLapData} from "../types/IStintLapData";

/******************************************************************
 * StintUtils
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class StintUtils {

    static isStintValid(stint: IStintData, session: ISessionData, bestStintUntilNow?: IStintData): boolean {
        switch (stint.state) {
            case "ready":
            case "invalid":
                return false
        }
        switch (session.setup.stintValidationMode) {
            case "valid-when-started":
                return !!stint.startData?.signal_time
            case "valid-when-at-least-one-lap":
                return stint.computedLaps.length >= 1
            case "valid-when-at-least-50secs":
                const startTime = stint.startData.signal_time
                const endTime = stint.targetsData[stint.targetsData?.length - 1]?.crossing_time
                return TimeUtils.hasDurationBeenReached(startTime, endTime, "00:00:50")
            case "valid-when-improved":
                switch (session.setup.mode) {
                    case "lap":
                        if (!bestStintUntilNow) {
                            return stint.computedLaps.length >= 1
                        }
                        return bestStintUntilNow.bestLapTime > stint.bestLapTime
                    case "race":
                        if (!bestStintUntilNow) {
                            return stint.computedLaps.length >= 1
                        }
                        const hasLapsBeenImproved = stint.drivenLaps > 0 && bestStintUntilNow.drivenLaps < stint.drivenLaps
                        const hasLapsBeenEqual = stint.drivenLaps > 0 && bestStintUntilNow.drivenLaps == stint.drivenLaps
                        const hasTimeBeenImproved = bestStintUntilNow.drivenTime > stint.drivenTime && stint.drivenTime > 0
                        if (hasLapsBeenImproved) return true
                        return hasLapsBeenEqual && hasTimeBeenImproved
                    case "gymkhana":
                        if (!bestStintUntilNow) {
                            return true
                        }
                        return bestStintUntilNow.score < stint.score
                }
            case "valid-when-completed":
                switch (session.setup.mode) {
                    case "lap":
                    case "race":
                        switch (session.setup.finishType) {
                            case "laps":
                                return session.setup.laps == stint.computedLaps.length
                            case "duration":
                                const startTime = stint.startData.signal_time
                                const endTime = stint.targetsData[stint.targetsData?.length - 1]?.crossing_time
                                const duration = session.setup.duration
                                return TimeUtils.hasDurationBeenReached(startTime, endTime, duration)
                        }
                    case "gymkhana":
                        const lastTarget = stint.targetsData[stint.targetsData?.length - 1]
                        return lastTarget?.target_code == 0
                }
        }
    }

    static isLapValid(lap: IStintLapData): boolean {
        if (!lap) return false
        return !lap.hasUnwantedTargets
            && !lap.isMissingFinishTarget
            && !lap.isMissingSectorTargets
            && !lap.wasBelowMinLapTime
    }

    static isLapTimeNotComputable(lap: IStintLapData): boolean {
        if (!lap) return false
        return lap.isMissingFinishTarget
    }

    static isSectorTargetAllowed(target: ICapiTargetData, session: ISessionData): boolean {
        if (CapiTargetUtils.getTargetCode(target) == 0) {
            return true
        }
        return session.setup.sectorTargets.filter((targetCode: DriftTargetCodeType) => {
            return CapiTargetUtils.getTargetCode(target) == parseInt(targetCode.split("_")[1])
        }).length > 0
    }

    static isJokerTargetAllowed(target: ICapiTargetData, session: ISessionData): boolean {
        const jokerLaps = session.setup.numJokerLaps ?? 0
        if (jokerLaps == 0) return false
        const jokerTarget = session.setup.jokerLapTarget?.split("_")[1] ?? undefined
        if (!jokerTarget) return false
        return CapiTargetUtils.getTargetCode(target) == parseInt(jokerTarget)
    }

    static getEarliestSignalTimestamp(stints: IStintData[]): string {
        let earliestTimestamp: string = '';
        for (const stint of stints) {
            if (stint.startData?.signal_time) {
                if (!earliestTimestamp) {
                    earliestTimestamp = stint.startData.signal_time;
                } else {
                    const currentTimestamp = Date.parse(stint.startData.signal_time);
                    const earliestTimestampValue = Date.parse(earliestTimestamp);
                    if (currentTimestamp < earliestTimestampValue) {
                        earliestTimestamp = stint.startData.signal_time;
                    }
                }
            }
        }
        return earliestTimestamp;
    }

    static getOverallDrivenLapsOfLeadingDriver(stints: IStintData[]): number {
        const lapsByUser: { [user: string]: number } = {};
        for (const stint of stints) {
            if (lapsByUser[stint.user] === undefined) {
                lapsByUser[stint.user] = stint.drivenLaps ?? 0;
            } else {
                lapsByUser[stint.user] += stint.drivenLaps ?? 0;
            }
        }
        let maxDrivenLaps = 0;
        for (const user in lapsByUser) {
            if (lapsByUser[user] > maxDrivenLaps) {
                maxDrivenLaps = lapsByUser[user];
            }
        }
        return maxDrivenLaps;
    }

    static getOverallDrivenLapsOfDriver(stints: IStintData[]): number {
        let overallDrivenLaps = 0;
        for (const stint of stints) {
            overallDrivenLaps += stint.drivenLaps ?? 0;
        }
        return overallDrivenLaps;
    }

    static getOverallDrivenLapsOfLeadingTeam(sessionStints: IStintData[], teams: ITeamData[]): number {
        const lapsByUser: { [userID: string]: number } = {};
        for (const stint of sessionStints) {
            if (lapsByUser[stint.user] === undefined) {
                lapsByUser[stint.user] = stint.drivenLaps ?? 0;
            } else {
                lapsByUser[stint.user] += stint.drivenLaps ?? 0;
            }
        }
        const lapsByTeam: { [team: string]: number } = {};
        for (const userID in lapsByUser) {
            const teamName = teams.find((team: ITeamData) => {
                return team.members.some((member: MongoObjectIDType | IUserData) => {
                    if (member._id) {
                        return member._id.equals(userID)
                    }
                    return member.equals(userID)
                })
            })?.name;
            if (teamName) {
                if (lapsByTeam[teamName] === undefined) {
                    lapsByTeam[teamName] = lapsByUser[userID];
                } else {
                    lapsByTeam[teamName] += lapsByUser[userID];
                }
            }
        }
        let maxDrivenLaps = 0;
        for (const team in lapsByTeam) {
            if (lapsByTeam[team] > maxDrivenLaps) {
                maxDrivenLaps = lapsByTeam[team];
            }
        }
        return maxDrivenLaps;
    }

    static getOverallDrivenLapsOfTeamByAnyMember(
        sessionStints: IStintData[],
        teams: ITeamData[],
        userID: MongoObjectIDType
    ): number {
        const team = teams.find((team: ITeamData) => {
            return team.members.some((member: MongoObjectIDType | IUserData) => {
                if (member._id) {
                    return member._id.equals(userID)
                }
                return member.equals(userID)
            })
        })
        if (!team) return 0;
        const teamStints = sessionStints.filter((stint: IStintData) => {
            return team.members.some((member: MongoObjectIDType | IUserData) => {
                if (member._id) {
                    return member._id.equals(stint.user)
                }
                return member.equals(stint.user)
            })
        })
        let overallDrivenLaps = 0;
        for (const stint of teamStints) {
            overallDrivenLaps += stint.drivenLaps ?? 0;
        }
        return overallDrivenLaps;
    }

    static isOwnStint(authUser: IUserData, stint: IStintData | IStintData[]): boolean {
        if (Array.isArray(stint)) {
            return stint.some((stint: IStintData) => {
                return StintUtils.isOwnStint(authUser, stint)
            })
        }
        if (stint.user._id) {
            return stint.user._id.equals(authUser._id)
        }
        return stint.user.equals(authUser._id)
    }

    static hasGymkhanaTargetsAfterTimeout(stint: IStintData): boolean {
        if (stint.state !== "finished") return false
        const latestTarget = stint.targetsData?.[stint.targetsData.length - 1]
        if (!latestTarget) return false
        const computedDrivenTime = TimeUtils.calcTimeInSeconds(latestTarget.crossing_time, stint.startData.signal_time)
        return computedDrivenTime > 61
    }

}
