import * as React from "react";
import {ContentLayout} from "../../../../ui/layout/content/ContentLayout";
import {useServices} from "../../../../hooks/useServices";
import {Headline} from "../../../../ui/text/headings/Headline";
import {DatabaseBackup} from "./backup/DatabaseBackup";
import {DatabaseInfo} from "./info/DatabaseInfo";
import {FrontendConfig} from "../../../../../core/FrontendConfig";

/******************************************************************
 * AdminDatabase
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function AdminDatabase() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict} = useServices();

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout className="admin-database">
            <Headline text={dict("admin.database.title")} style="modal"/>
            {FrontendConfig.DB_BACKUP_DIR_AVAILABLE &&
                <DatabaseBackup/>}
            <DatabaseInfo/>
        </ContentLayout>
    );

}
