import * as React from "react";
import {useEffect, useState} from "react";
import {GroupFinderMap} from "../../modals/groups/map/GroupFinderMap";
import {useServices} from "../../../hooks/useServices";
import {IGroupData} from "../../../../../shared/models/IGroupData";
import {GroupFinderList} from "../../modals/groups/list/GroupFinderList";
import {TabbedContent} from "../../../ui/layout/tabbed/TabbedContent";
import {ContentLayout} from "../../../ui/layout/content/ContentLayout";
import {Headline} from "../../../ui/text/headings/Headline";
import {HomeActionBar} from "../../../ui/bar/home/HomeActionBar";

/******************************************************************
 * GroupsMain
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function GroupsMain() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, api, state} = useServices();

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [localGroups, setLocalGroups] = useState<IGroupData[]>([]);
    const [onlineGroups, setOnlineGroups] = useState<IGroupData[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        loadGroups();
    }, [])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function loadGroups() {
        setLoading(true)
        const publicGroups = await api.group.getPublicGroups()
        setLocalGroups(publicGroups.filter(group => group.type === "local"))
        setOnlineGroups(publicGroups.filter(group => group.type === "online"))
        setLoading(false)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return <>
        <ContentLayout
            className="groups-main"
            alignContent="start"
            rowTemplate={"min-content 1fr"}
            fullHeight={true}>
            <Headline text={dict("groups.title")} style="h1"/>
            <TabbedContent
                contentHeight="stretch"
                showContentByID={0}>
                <GroupFinderMap
                    initialZoomLevel={4}
                    fullHeight={true}
                    projection="globe"
                    onTouchEnd={() => state.showMobileMenu.setValue(false)}
                    tabbedContent={{name: dict("groups.finder.list.local.label")}}
                    publicGroups={localGroups}/>
                <GroupFinderList
                    tabbedContent={{name: dict("groups.finder.list.online.label")}}
                    isLoading={loading}
                    groups={onlineGroups}/>
            </TabbedContent>
        </ContentLayout>
        <HomeActionBar/>
    </>

}
