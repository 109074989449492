import * as React from "react";
import {useRef, useState} from "react";
import {useServices} from "../../../../hooks/useServices";
import {useGroupState} from "../../../../hooks/useGroupState";
import {useMobileStyle} from "../../../../hooks/useMobileStyle";
import {MongoObjectIDType} from "../../../../../../shared/types/MongoObjectIDType";
import {ContentLayout} from "../../../../ui/layout/content/ContentLayout";
import {InfoText} from "../../../../ui/text/infotext/InfoText";
import {Headline} from "../../../../ui/text/headings/Headline";
import {UserInfo} from "../../../../ui/info/user/UserInfo";
import {LabelButton} from "../../../../ui/buttons/label/LabelButton";
import {Modal} from "../../Modal";
import {DriverList} from "../../../../ui/list/driver/DriverList";
import {useEventState} from "../../../../hooks/useEventState";
import {GroupUtils} from "../../../../../../shared/utils/GroupUtils";

/******************************************************************
 * EventContactSettings
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function EventContactSettings() {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const rootRef = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, api} = useServices()
    const {groupData, groupContact, groupMembers} = useGroupState()
    const {eventID, eventContact} = useEventState()
    const [isMobile] = useMobileStyle(rootRef, 600)

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [showSelectMember, setShowSelectMember] = useState<boolean>(false)

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function saveContact(userID: MongoObjectIDType) {
        if (!groupMembers || groupMembers.length == 0) return
        setShowSelectMember(false)
        await api.event.update(eventID, {contact: userID})
    }
    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return <>
        <ContentLayout
            ref={rootRef}
            className="event-contact-settings"
            alignSelf="start">
            <InfoText
                text={dict("event.settings.contact.info")}/>
            <ContentLayout framed={true}>
                <Headline
                    text={dict("event.settings.contact.title")}
                    style={"h5-underlined"}/>
                <ContentLayout
                    justifyContent="space-between"
                    alignItems="center"
                    columnTemplate={isMobile ? null : "1fr min-content"}>
                    <UserInfo
                        user={eventContact ?? groupContact}
                        clickToShowProfile={true}
                        style="normal"/>
                    <ContentLayout>
                        <LabelButton
                            label={dict("event.settings.contact.addDriver.label")}
                            style="secondary"
                            onClick={() => setShowSelectMember(true)}/>
                    </ContentLayout>
                </ContentLayout>
            </ContentLayout>
        </ContentLayout>
        {showSelectMember &&
            <Modal
                width="medium"
                closeAction={() => setShowSelectMember(false)}>
                <Headline
                    text={dict("event.select.contact.title")}
                    style="modal"/>
                <ContentLayout>
                <InfoText text={dict("select.contact.info")} size="small"/>
                <DriverList
                    data={groupMembers
                        ?.filter(member => GroupUtils.isAdmin(groupData, member.user))
                        ?.map(member => member.user)}
                    primaryIconType="add"
                    onPrimaryIconClick={saveContact}
                    emptyDataInfo={dict("event.contact.empty.info")}/>
                </ContentLayout>
            </Modal>}
    </>

}
