import {useEffect, useState} from "react";
import {IconType} from "../../ui/icons/IconType";
import {IconColorType} from "../../ui/icons/Icon";
import {useServices} from "../../hooks/useServices";
import {FrontendSpeakerStateType} from "../../../core/services/FrontendSpeaker";

/******************************************************************
 * useSpeakerState
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function useSpeakerState() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {speaker} = useServices();

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [speakerStateIconType, setSpeakerStateIconType] = useState<IconType>(null)
    const [speakerStateIconColor, setSpeakerStateIconColor] = useState<IconColorType>(null)
    const [speakerState, setSpeakerState] = useState<FrontendSpeakerStateType>(null)

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        updateSpeakerType()
        speaker.speakerState.onChangeSignal.add(updateSpeakerType)
        return () => {
            speaker.speakerState.onChangeSignal.remove(updateSpeakerType)
        }
    }, [])

    useEffect(() => {
        switch (speaker.speakerState.getValue()) {
            case "not-available":
                setSpeakerStateIconType("cancel")
                setSpeakerStateIconColor("red")
                return
            case "not-allowed":
                setSpeakerStateIconType("warning")
                setSpeakerStateIconColor("yellow")
                return
            case "no-voices":
                setSpeakerStateIconType("warning")
                setSpeakerStateIconColor("red")
                return
            case "ok":
            default:
                setSpeakerStateIconType(null)
                setSpeakerStateIconColor(null)
                return
        }
    }, [speakerState])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function updateSpeakerType() {
        setSpeakerState(speaker.speakerState.getValue())
    }

    /* ----------------------------------------------------------------
 	 * RETURN
 	 * --------------------------------------------------------------*/

    return {
        speakerState,
        speakerStateIconType,
        speakerStateIconColor
    }

}
