import * as React from "react";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {Headline} from "../../../../../ui/text/headings/Headline";
import {InfoText} from "../../../../../ui/text/infotext/InfoText";
import {LabelButton} from "../../../../../ui/buttons/label/LabelButton";
import {useServices} from "../../../../../hooks/useServices";
import {Spacer} from "../../../../../ui/utils/spacer/Spacer";

/******************************************************************
 * StintsOverviewPlanUpgradeInfo
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function StintsOverviewPlanUpgradeInfo(props: {
    availableStintsLength: number
    accessableStintsLength: number
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, state} = useServices()

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            className="stints-overview-plan-upgrade-info"
            gap="none"
            framed={true}>
            <Spacer height="normal"/>
            <Headline
                text={dict("stints.overview.plan.upgrade.title")}
                align="center"
                style="modal"/>
            <ContentLayout justifyItems="center">
                <InfoText
                    align="center"
                    text={dict("stints.overview.plan.upgrade.info")
                        .replaceAll("{AVAILABLE_STINTS}", props.availableStintsLength.toString())
                        .replaceAll("{ACCESSABLE_STINTS}", props.accessableStintsLength.toString())}/>
                <ContentLayout justifyItems={"center"}>
                    <LabelButton
                        label={dict("subscription.upgrade.info.button.showPlans")}
                        style="primary"
                        onClick={() => state.showSubscriptionPlans.setValue(true)}/>
                </ContentLayout>
            </ContentLayout>
            <Spacer height="normal"/>
        </ContentLayout>
    );

}
