import * as React from "react";

/******************************************************************
 * ArrowIcon
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function ArrowIcon(props: {
    scale?: number
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="arrow-icon">
            <svg width={13 * (props.scale ?? 1)} height={9 * (props.scale ?? 1)} viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.52877 0.710022L6.11377 5.29502L10.6988 0.710022L12.1138 2.12502L6.11377 8.12502L0.11377 2.12502L1.52877 0.710022Z" fill="white"/>
            </svg>
        </div>
    );

}
