import * as React from "react";
import {LabelButton} from "../../../ui/buttons/label/LabelButton";
import {useServices} from "../../../hooks/useServices";
import {FrontendConfig} from "../../../../core/FrontendConfig";
import {Divider} from "../../../ui/utils/divider/Divider";
import {LanguageSelector} from "../../../ui/form/elements/language/LanguageSelector";
import {FrontendRoute} from "../../../../../shared/routes/FrontendRoute";
import {useRouteStates} from "../../../hooks/useRouteStates";
import {Spacer} from "../../../ui/utils/spacer/Spacer";

/******************************************************************
 * HomeMenu
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function HomeMenu() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {state, dict, router} = useServices();
    const {showGroups, showHome, showEvents, showPlans} = useRouteStates()

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="metabar-system-home-menu">
            <div className="metabar-system-home-menu-main">
                <LabelButton
                    label={dict("home.nav.welcome")}
                    style={showHome ? "main-menu-selected" : "main-menu"}
                    onClick={() => router.showRoute(FrontendRoute.HOME)}/>
                <LabelButton
                    label={dict("home.nav.events")}
                    style={showEvents ? "main-menu-selected" : "main-menu"}
                    onClick={() => router.showRoute(FrontendRoute.EVENTS)}/>
                <LabelButton
                    label={dict("home.nav.groups")}
                    style={showGroups ? "main-menu-selected" : "main-menu"}
                    onClick={() => router.showRoute(FrontendRoute.GROUPS)}/>
                <LabelButton
                    label={dict("home.nav.plans")}
                    style={showPlans ? "main-menu-selected" : "main-menu"}
                    onClick={() => router.showRoute(FrontendRoute.PLANS)}/>
                <Spacer height="large"/>
                <LabelButton
                    label={dict("home.nav.login")}
                    style="action-main"
                    onClick={() => state.showLogin.setValue("login")}/>
            </div>
            <div className="metabar-system-home-menu-meta">
                <Divider style="metabar-home"/>
                <LabelButton
                    label={dict("meta.imprint.termsofuse")}
                    style="small-link"
                    onClick={() => state.showArticle.setValue(dict("article.id.termsofuse"))}/>
                <LabelButton
                    label={dict("meta.imprint.title")}
                    style="small-link"
                    onClick={() => state.showArticle.setValue(dict("article.id.imprint"))}/>
                <LabelButton
                    label={dict("meta.privacy.title")}
                    style="small-link"
                    onClick={() => state.showArticle.setValue(dict("article.id.privacy"))}/>
                <LabelButton
                    label={dict("meta.version.title")
                        .replace("{VERSION}", FrontendConfig.SERVER_VERSION)}
                    style="small-link"
                    onClick={() => state.showVersion.setValue(true)}/>
                <Divider style="metabar-home"/>
                <LanguageSelector size="small"/>
            </div>
        </div>
    );

}
