import {ErrorCode} from "../keys/ErrorCode";
import {SharedConfig} from "../config/SharedConfig";

/******************************************************************
 * SessionLapsValidation
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class SessionLapsValidation {

    static get REGEXP() {
        return new RegExp(`^(0|[1-9]\\d{0,${SharedConfig.SESSION_MAX_LAPS.toString().length - 1}}|${SharedConfig.SESSION_MAX_LAPS})$`);
    }

    static schemaValidator(errorCode?: string) {
        return {
            validate: {
                validator: value => SessionLapsValidation.isValid(value),
                message: errorCode ?? ErrorCode.SESSION_LAPS_INVALID
            },
        }
    };

    static isValid(value: number): boolean {
        return SessionLapsValidation.REGEXP.test(value.toString());
    }
}
