import * as React from "react";
import {useRef, useState} from "react";
import {Headline} from "../../../../ui/text/headings/Headline";
import {Input} from "../../../../ui/form/elements/input/Input";
import {Modal} from "../../Modal";
import {useServices} from "../../../../hooks/useServices";
import {IAcademyChapterData} from "../../../../../../shared/models/submodels/IAcademyChapterData";
import {ArticleTeaser} from "../../../../ui/teaser/article/ArticleTeaser";
import {LabelButton} from "../../../../ui/buttons/label/LabelButton";
import {ContentLayout} from "../../../../ui/layout/content/ContentLayout";
import {MultiSelectOption} from "../../../../ui/form/elements/select/options/MultiSelectOption";
import {useUtils} from "../../../../hooks/useUtils";
import {SharedConfig} from "../../../../../../shared/config/SharedConfig";
import {IMultilangString} from "../../../../../../shared/models/submodels/IMultilangString";
import {IMultilangObjectID} from "../../../../../../shared/models/submodels/IMultilangObjectID";
import {useMobileStyle} from "../../../../hooks/useMobileStyle";
import {CopyArticleContentModal} from "../../article/CopyArticleContentModal";
import {MongoObjectIDType} from "../../../../../../shared/types/MongoObjectIDType";

/******************************************************************
 * EditAcademyChapterModal
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function EditAcademyChapterModal(props: {
    chapterData: IAcademyChapterData
    onClose: () => void
    onSuccess: () => void
    onUpdateAcademyRequested?: () => void
}) {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const contentRef = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, api} = useServices();
    const {
        multilangString,
        multilangObjectID,
        updateMultilangString,
        updateMultilangObjectID
    } = useUtils();
    const [isMobileStyle] = useMobileStyle(contentRef, 600)

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [articleID, setArticleID] = useState<IMultilangObjectID>(props.chapterData.article)
    const [chapterTitle, setChapterTitle] = useState<IMultilangString>(props.chapterData?.title)
    const [addSpacerAbove, setAddSpacerAbove] = useState<boolean>(props.chapterData?.addSpacerAbove ?? false)
    const [isSending, setIsSending] = useState<boolean>(false)
    const [copyTargetArticleID, setCopyTargetArticleID] = useState<MongoObjectIDType>(null)
    const [version, setVersion] = useState<number>(0)

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function updateChapter() {
        setIsSending(true)
        const response = await api.academy.updateChapter(props.chapterData._id, {
            title: chapterTitle,
            article: articleID,
            addSpacerAbove: addSpacerAbove
        })
        if (response.status === 200) {
            props.onSuccess()
        }
        props.onClose()
        setIsSending(false)
    }

    function copySourceArticleIDs(): MongoObjectIDType[] {
        return Object.keys(articleID).filter(language => articleID[language] !== copyTargetArticleID)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal
            width="large"
            closeAction={props.onClose}
            className="academy-edit-chapter-modal">
            <Headline
                text={dict("academy.edit.chapter.title")}
                style="modal"/>
            <ContentLayout>
                <ContentLayout ref={contentRef} columns={isMobileStyle ? 1 : 2}>
                    <>{SharedConfig.LANGUAGES.map(language => (
                        <ContentLayout key={language} framed={true} gap="small">
                            <ContentLayout
                                columnTemplate={articleID[language] ? "1fr min-content" : null}
                                justifyContent="space-between">
                                <Headline
                                    text={dict(`input.multilang.${language}.label`)}
                                    style="h5-underlined"/>
                                {articleID[language] &&
                                    <LabelButton
                                        label={dict("article.copy.modal.button.label")}
                                        style="secondary-small"
                                        onClick={() => setCopyTargetArticleID(articleID[language])}/>}
                            </ContentLayout>
                            <Input
                                type="text"
                                id="chapterTitle"
                                defaultValue={multilangString(chapterTitle, language, true)}
                                required={true}
                                placeholder={dict("academy.create.chapter.placeholder")}
                                action={async (value) => {
                                    setChapterTitle({...updateMultilangString(chapterTitle, value, language)})
                                    return null
                                }}/>
                            <ArticleTeaser
                                key={version}
                                articleID={multilangObjectID(articleID, language, true)}
                                editable={true}
                                onSelected={value => {
                                    setArticleID({...updateMultilangObjectID(articleID, value, language)})
                                }}/>
                        </ContentLayout>
                    ))}</>
                </ContentLayout>
                <MultiSelectOption
                    data={{
                        value: addSpacerAbove ? "false" : "true",
                        text: dict("academy.chapter.addSpacerAbove.label")
                    }}
                    selected={addSpacerAbove}
                    onChange={value => setAddSpacerAbove(value == "true")}/>
                <LabelButton
                    label={dict("academy.edit.chapter.submit.label")}
                    style="primary"
                    progressing={isSending}
                    onClick={updateChapter}/>
            </ContentLayout>
            {copyTargetArticleID &&
                <CopyArticleContentModal
                    onCloseRequested={() => setCopyTargetArticleID(null)}
                    onSuccessfulCopy={() => {
                        setCopyTargetArticleID(null)
                        setVersion(prevState => prevState + 1)
                        props.onUpdateAcademyRequested?.()
                    }}
                    targetArticleID={copyTargetArticleID}
                    sourceArticleIDs={copySourceArticleIDs().map(language => articleID[language])}/>}
        </Modal>
    );

}
