import * as React from "react";
import {Input} from "../elements/input/Input";
import {Form} from "../Form";
import {useServices} from "../../../hooks/useServices";
import {InfoText} from "../../text/infotext/InfoText";
import {ContentLayout} from "../../layout/content/ContentLayout";

/******************************************************************
 * VerifyForm
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function VerifyForm(props: {
    info: string
    forceProcessing?: boolean
    actionLabel: string
    action: (code: string) => Promise<Response>
    onVerified?: () => void
}) {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {state, api, dict} = useServices();

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function resendVerificationCode(e: MouseEvent) {
        e.preventDefault()
        await api.user.requestVerificationCode({email: state.authUser.getValue().email})
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout>
            <InfoText text={props.info}/>
            <Form
                forceProcessing={props.forceProcessing}
                actionLabel={props.actionLabel}
                action={async (formData) => props.action(formData?.code)}
                onSuccess={async () => props.onVerified?.()}>
                <Input
                    id="code"
                    type="text"
                    customLinkLabel={dict("form.verify.button.requestCode")}
                    onCustomLinkClick={resendVerificationCode}
                    label={dict("form.verify.code.input.label")}/>
            </Form>
        </ContentLayout>
    )
}
