import * as React from "react";

/******************************************************************
 * MapTargetCrosshair
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function MapTargetCrosshair(props: {
    scale?: number
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <svg
            className={"map-target-crosshair"}
            width={256 * (props.scale ?? 1)}
            height={256 * (props.scale ?? 1)}
            viewBox="0 0 256 256"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g>
                <rect width="256" height="256"/>
                <rect y="125.5" width="90" height="5" fill="black"/>
                <rect x="90" y="127.137" width="22.6838" height="2" fill="black"/>
                <rect x="255.288" y="130.5" width="90" height="5" transform="rotate(-180 255.288 130.5)"
                      fill="black"/>
                <rect x="165.288" y="128.863" width="22.6838" height="2" transform="rotate(-180 165.288 128.863)"
                      fill="black"/>
                <rect x="130.144" width="90" height="5" transform="rotate(90 130.144 0)" fill="black"/>
                <rect x="128.507" y="90" width="22.6838" height="2" transform="rotate(90 128.507 90)" fill="black"/>
                <rect x="125.144" y="256" width="90" height="5" transform="rotate(-90 125.144 256)" fill="black"/>
                <rect x="126.781" y="166" width="22.6838" height="2" transform="rotate(-90 126.781 166)"
                      fill="black"/>
                <mask id="mask0_1894_1596" maskUnits="userSpaceOnUse" x="0" y="0"
                      width="118" height="118">
                    <rect width="118" height="118" fill="#D9D9D9"/>
                </mask>
                <g mask="url(#mask0_1894_1596)">
                    <circle cx="127.401" cy="128.08" r="91.3447" stroke="black" strokeWidth="10"/>
                </g>
                <mask id="mask1_1894_1596" maskUnits="userSpaceOnUse" x="137" y="0"
                      width="119" height="118">
                    <rect x="255.288" width="118" height="118" transform="rotate(90 255.288 0)" fill="#D9D9D9"/>
                </mask>
                <g mask="url(#mask1_1894_1596)">
                    <circle cx="127.208" cy="127.401" r="91.3447" transform="rotate(90 127.208 127.401)"
                            stroke="black" strokeWidth="10"/>
                </g>
                <mask id="mask2_1894_1596" maskUnits="userSpaceOnUse" x="137" y="138"
                      width="119" height="118">
                    <rect x="255.288" y="256" width="118" height="118" transform="rotate(-180 255.288 256)"
                          fill="#D9D9D9"/>
                </mask>
                <g mask="url(#mask2_1894_1596)">
                    <circle cx="127.886" cy="127.92" r="91.3447" transform="rotate(-180 127.886 127.92)"
                            stroke="black" strokeWidth="10"/>
                </g>
                <mask id="mask3_1894_1596" maskUnits="userSpaceOnUse" x="0" y="138"
                      width="118" height="118">
                    <rect y="256" width="118" height="118" transform="rotate(-90 0 256)" fill="#D9D9D9"/>
                </mask>
                <g mask="url(#mask3_1894_1596)">
                    <circle cx="128.08" cy="128.599" r="91.3447" transform="rotate(-90 128.08 128.599)"
                            stroke="black" strokeWidth="10"/>
                </g>
                <mask id="mask4_1894_1596" maskUnits="userSpaceOnUse" x="0" y="138"
                      width="118" height="118">
                    <rect y="256" width="118" height="118" transform="rotate(-90 0 256)" fill="#D9D9D9"/>
                </mask>
                <g mask="url(#mask4_1894_1596)">
                    <circle cx="128.08" cy="128.599" r="59.5704" transform="rotate(-90 128.08 128.599)"
                            stroke="#F50B35" strokeWidth="4"/>
                </g>
                <mask id="mask5_1894_1596" maskUnits="userSpaceOnUse" x="138" y="138"
                      width="118" height="118">
                    <rect x="256" y="256" width="118" height="118" transform="rotate(180 256 256)" fill="#D9D9D9"/>
                </mask>
                <g mask="url(#mask5_1894_1596)">
                    <circle cx="128.599" cy="127.92" r="59.5704" transform="rotate(180 128.599 127.92)"
                            stroke="#F50B35" strokeWidth="4"/>
                </g>
                <mask id="mask6_1894_1596" maskUnits="userSpaceOnUse" x="137" y="0"
                      width="119" height="118">
                    <rect x="255.288" width="118" height="118" transform="rotate(90 255.288 0)" fill="#D9D9D9"/>
                </mask>
                <g mask="url(#mask6_1894_1596)">
                    <circle cx="127.208" cy="127.401" r="59.5704" transform="rotate(90 127.208 127.401)"
                            stroke="#F50B35" strokeWidth="4"/>
                </g>
                <mask id="mask7_1894_1596" maskUnits="userSpaceOnUse" x="0" y="0"
                      width="118" height="118">
                    <rect width="118" height="118" fill="#D9D9D9"/>
                </mask>
                <g mask="url(#mask7_1894_1596)">
                    <circle cx="127.401" cy="128.08" r="59.5704" stroke="#F50B35" strokeWidth="4"/>
                </g>
                <circle cx="128" cy="128" r="4.5" fill="#F50B35"/>
            </g>
        </svg>
    );

}
