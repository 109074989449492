import * as React from "react";

/******************************************************************
 * NextIcon
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function NextIcon(props: {
    scale?: number
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="next-icon">
            <svg width={8 * (props.scale ?? 12)} height={16 * (props.scale ?? 1)} viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.999598 11.2443C0.610326 10.8551 0.609982 10.224 0.99883 9.83432L4.17019 6.65604C4.55968 6.26569 4.55968 5.63372 4.17019 5.24337L0.998829 2.06509C0.609982 1.67539 0.610326 1.04436 0.999598 0.655091C1.38917 0.265518 2.02079 0.265518 2.41037 0.655092L6.99788 5.2426C7.3884 5.63312 7.3884 6.26629 6.99788 6.65681L2.41037 11.2443C2.02079 11.6339 1.38917 11.6339 0.999598 11.2443Z" fill="white"/>
            </svg>
        </div>
    );

}
