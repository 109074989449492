import * as React from "react";
import {ICapiTargetData} from "../../../../../../shared/types/ICapiTargetData";
import {useServices} from "../../../../hooks/useServices";
import {CapiTargetUtils} from "../../../../../../shared/utils/CapiTargetUtils";
import {Icon} from "../../../icons/Icon";
import {ReactElement} from "react";

/******************************************************************
 * TableStintTargetCell
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function TableStintTargetCell(props: {
    target: ICapiTargetData
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict} = useServices()

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function targetName() {
        return dict("drift.gymkhana.targetCode_" + CapiTargetUtils.getTargetCode(props.target))
    }

    function targetIcon(): ReactElement {
        switch (CapiTargetUtils.getTargetCode(props.target)) {
            case 0:
                return <Icon type="finishTarget" scale={0.9}/>
            case 4:
                return <Icon type="speedDriftTarget" scale={0.9}/>
            case 5:
                return <Icon type="angleDriftTarget" scale={0.9}/>
            case 6:
                return <Icon type="180DriftTarget" scale={0.9}/>
            case 7:
                return <Icon type="360DriftTarget" scale={0.9}/>
        }
        return null;
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="table-stint-target-cell"
            data-target-disabled={props.target._disabled}>
            <div className="table-stint-target-name-cell-icon">{targetIcon()}</div>
            <div className="table-stint-target-name-cell-name">{targetName()}</div>
        </div>
    );

}
