import * as React from "react";
import {ISessionLeaderboardEntry} from "../../../../../../../shared/types/ISessionLeaderboardEntry";
import {Table} from "../../../Table";
import {ITableColumnDefinition} from "../../../ITableColumnDefinition";
import {ISessionData} from "../../../../../../../shared/models/ISessionData";
import {LeaderboardUtils} from "../../../../../../../shared/utils/LeaderboardUtils";
import {useServices} from "../../../../../hooks/useServices";

/******************************************************************
 * TeamLeaderboard
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function TeamLeaderboard(props: {
    entries: ISessionLeaderboardEntry[]
    session: ISessionData
}) {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {state} = useServices()

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function showTeamMemberLeaderboardEntry(entry: ISessionLeaderboardEntry) {
        state.showLeaderboardEntry.setValue(entry)
    }

    function tableDefinition(): ITableColumnDefinition<ISessionLeaderboardEntry>[] {
        switch (props.session.setup.mode) {
            case "lap":
                return lapColumnsDefinition()
            case "race":
                return raceColumnsDefinition()
            case "gymkhana":
                return gymkhanaColumnsDefinition()
        }
    }

    function lapColumnsDefinition(): ITableColumnDefinition<ISessionLeaderboardEntry>[] {
        const session: ISessionData = props.session
        const sessionNumSectors = session.setup.numSectors
        const sectorsSize = sessionNumSectors > 1 ? sessionNumSectors + "fr" : null
        const timerSize = LeaderboardUtils.showTimer(session) ? "120px" : null
        return [
            {type: "leaderboard-driver-state", size: "60px"},
            {type: "leaderboard-driver", size: "4fr"},
            {type: "leaderboard-best-lap-time", size: "120px"},
            {type: "leaderboard-lap-sector-times", size: sectorsSize},
            {type: "leaderboard-timer", size: timerSize},
            {type: "leaderboard-laps", size: "100px"},
            {type: "leaderboard-stints", size: "80px"},
            {type: "leaderboard-entry-menu", size: "40px"}
        ]
    }

    function raceColumnsDefinition(): ITableColumnDefinition<ISessionLeaderboardEntry>[] {
        const session: ISessionData = props.session
        const stintsSize = session.setup.stints > 0 ? "80px" : null
        const timerSize = LeaderboardUtils.showTimer(session) ? "120px" : null
        const jokerLapSize = LeaderboardUtils.showJokerLap(session) ? "90px" : null
        const pointsSize = LeaderboardUtils.showPoints(session) ? "90px" : null
        return [
            {type: "leaderboard-driver-state", size: "60px"},
            {type: "leaderboard-driver", size: "4fr"},
            {type: "leaderboard-laps", size: "100px"},
            {type: "leaderboard-driven-time", size: "120px"},
            {type: "leaderboard-avg-lap-time", size: "120px"},
            {type: "leaderboard-best-lap-time", size: "120px"},
            {type: "leaderboard-timer", size: timerSize},
            {type: "leaderboard-joker-lap", size: jokerLapSize},
            {type: "leaderboard-stints", size: stintsSize},
            {type: "leaderboard-points", size: pointsSize},
            {type: "leaderboard-entry-menu", size: "40px"}
        ]
    }

    function gymkhanaColumnsDefinition(): ITableColumnDefinition<ISessionLeaderboardEntry>[] {
        const pointsSize =
            props.session.state === "finished"
            && props.session.setup.points?.length > 0
                ? "1.2fr"
                : null
        return [
            {type: "leaderboard-driver-state", size: "60px"},
            {type: "leaderboard-driver", size: "4fr"},
            {type: "leaderboard-score", size: "1.5fr"},
            {type: "leaderboard-driven-time", size: "1.5fr"},
            {type: "leaderboard-targethits", size: "1fr"},
            {type: "leaderboard-stints", size: "1fr"},
            {type: "leaderboard-points", size: pointsSize},
            {type: "leaderboard-entry-menu", size: "40px"}
        ]
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="team-leaderboard">
            <Table
                style="leaderboard-compact"
                rowsData={props.entries}
                visibleRows={10}
                onRowClick={showTeamMemberLeaderboardEntry}
                columnsDefinition={tableDefinition()}
            />
        </div>
    );

}
