import * as React from "react";
import {DriftTrackConditionType} from "../../../../../../shared/types/DriftTrackConditionType";
import {useServices} from "../../../../hooks/useServices";
import {useRouteOwner} from "../../../../hooks/useRouteOwner";
import {MultiSelectInput} from "../select/MultiSelectInput";
import {useGroupState} from "../../../../hooks/useGroupState";

/******************************************************************
 * DriftTrackConditionInput
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function DriftTrackConditionInput(props: {
    trackConditions: DriftTrackConditionType[]
    onChange: (value: DriftTrackConditionType[]) => void
    readonly?: boolean
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict} = useServices()
    const {isRouteOwnerAuthUser} = useRouteOwner()
    const {isGroupAdmin} = useGroupState()

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="drift-track-condition-input">
            <MultiSelectInput
                label={dict("drift.trackCondition")}
                defaultValue={props.trackConditions}
                readonly={(!isRouteOwnerAuthUser && !isGroupAdmin) || props.readonly}
                helpTopic="drift.trackCondition"
                onChange={props.onChange}
                options={[
                    {value: "drift_asphalt", text: dict("drift.trackCondition.driftAsphalt")},
                    {value: "drift_asphalt_wet", text: dict("drift.trackCondition.driftAsphaltWet")},
                    {value: "drift_dirt", text: dict("drift.trackCondition.driftDirt")},
                    {value: "drift_ice", text: dict("drift.trackCondition.driftIce")}
                ]} />
        </div>
    );

}
