import {IKeyValuePair} from "../types/IKeyValuePair";

/******************************************************************
 * ObjectUtils
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class ObjectUtils {

    static isEmtpy(obj: Object): boolean {
        if (!obj) return true;
        return Object.keys(obj).length == 0;
    }

    static hasNestedObject(obj: Object): boolean {
        if (!obj) return false;
        return Object.values(obj).filter(value => typeof value == "object").length > 0;
    }

    static getChangedProperties(oldObject: IKeyValuePair, newObject: IKeyValuePair): IKeyValuePair {
        const changes: IKeyValuePair = {};
        for (const key in newObject) {
            if (newObject.hasOwnProperty(key)) {
                const oldValue = JSON.stringify(oldObject[key]);
                const newValue = JSON.stringify(newObject[key]);
                if (oldValue !== newValue) {
                    changes[key] = newValue;
                }
            }
        }
        return changes;
    }

    static hasChangedProperties(oldObject: IKeyValuePair, newObject: IKeyValuePair): boolean {
        return Object.keys(this.getChangedProperties(oldObject, newObject)).length > 0;
    }


}
