import * as React from "react";
import {Modal, ModalType} from "../Modal";
import {useServices} from "../../../hooks/useServices";
import ReactMarkdown from "react-markdown";
import {MessageModalType} from "../ModalType";
import rehypeExternalLinks from "rehype-external-links";

/******************************************************************
 * MessageModal
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function MessageModal() {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {state} = useServices();

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function close() {
        state.showMessage.setValue(null);
    }

    function modalType(): ModalType {
        switch (state.showMessage.getValue().type as MessageModalType) {
            case "data":
                return "data";
            case "error":
                return "error";
            case "long":
                return "normal";
            default:
                return "normal";
        }
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal
            closeAction={close}
            width={state.showMessage.getValue().type == "data" ? "medium" : "small"}
            allowHorizontalScroll={true}
            type={modalType()}>
            <div className="message-modal" data-type={state.showMessage.getValue().type}>
                <ReactMarkdown
                    rehypePlugins={[[rehypeExternalLinks, {target: '_blank'}]]}>
                    {state.showMessage.getValue()?.message?.replaceAll("\n", "  \n")}
                </ReactMarkdown>
            </div>
        </Modal>
    );

}
