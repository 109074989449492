/******************************************************************
 * ArrayUtils
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class ArrayUtils {

    static shuffledArray(array: unknown[]): unknown[] {
        if (!array) return null;
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }

}
