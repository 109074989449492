import {useEffect, useState} from "react";
import {IEventData} from "../../../shared/models/IEventData";
import {PrivacyType} from "../../../shared/types/PrivacyType";
import {EventStateType} from "../../../shared/types/EventStateType";
import {IUserData} from "../../../shared/models/IUserData";
import {useServices} from "./useServices";
import * as moment from "moment";
import {EventAccessType} from "../../../shared/types/EventAccessType";
import {IFolderData} from "../../../shared/models/submodels/IFolderData";
import {MongoObjectIDType} from "../../../shared/types/MongoObjectIDType";
import {ITeamData} from "../../../shared/models/submodels/ITeamData";
import {IFileData} from "../../../shared/models/IFileData";
import {EventOwnerType} from "../../../shared/types/EventOwnerType";
import {EventChildrenType} from "../../../shared/types/EventChildrenType";
import {ITrackData} from "../../../shared/models/submodels/ITrackData";
import {FollowSessionGameIDRuleType} from "../../../shared/types/FollowSessionGameIDRuleType";
import {SharedConfig} from "../../../shared/config/SharedConfig";
import {EventUtils} from "../../../shared/utils/EventUtils";
import {ILocationData} from "../../../shared/types/ILocationData";
import {IArticleData} from "../../../shared/models/IArticleData";

/******************************************************************
 * EventState Hook
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function useEventState() {

    /* ----------------------------------------------------------------
 	 * CORE
 	 * --------------------------------------------------------------*/

    const {state} = useServices();

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [eventID, setEventID] = useState<MongoObjectIDType>(state.event.getValue()?._id);
    const [eventData, setEventData] = useState<IEventData>(state.event.getValue())
    const [eventName, setEventName] = useState<string>(state.event.getValue()?.name);
    const [eventPath, setEventPath] = useState<string>(state.event.getValue()?.path);
    const [eventState, setEventState] = useState<EventStateType>(state.event.getValue()?.state);
    const [eventPrivacy, setEventPrivacy] = useState<PrivacyType>(state.event.getValue()?.privacy);
    const [eventOwner, setEventOwner] = useState<IUserData>(state.event.getValue()?.owner);
    const [eventOwnerType, setEventOwnerType] = useState<EventOwnerType>(state.event.getValue()?.ownerType);
    const [eventContact, setEventContact] = useState<IUserData>(state.event.getValue()?.contact);
    const [eventImage, setEventImage] = useState<IFileData>(state.event.getValue()?.image);
    const [eventDate, setEventDate] = useState<string>(state.event.getValue()?.date);
    const [eventIsPermanent, setEventIsPermanent] = useState<boolean>(state.event.getValue()?.isPermanent);
    const [eventAboutArticle, setEventAboutArticle] = useState<MongoObjectIDType | IArticleData>(state.event.getValue()?.aboutArticle)
    const [eventLocation, setEventLocation] = useState<string>(state.event.getValue()?.location);
    const [eventMapLocation, setEventMapLocation] = useState<ILocationData>(state.event.getValue()?.mapLocation)
    const [eventDateUntil, setEventDateUntil] = useState<string>(state.event.getValue()?.dateUntil);
    const [eventRegisteredDrivers, setEventRegisteredDrivers] = useState<IUserData[]>(state.event.getValue()?.drivers.registered);
    const [eventRequestingDrivers, setEventRequestingDrivers] = useState<IUserData[]>(state.event.getValue()?.drivers.requesting);
    const [eventPendingDrivers, setEventPendingDrivers] = useState<IUserData[]>(state.event.getValue()?.drivers.pending);
    const [eventAccess, setEventAccess] = useState<EventAccessType>(state.event.getValue()?.drivers.access);
    const [eventMaxDrivers, setEventMaxDrivers] = useState<number>(state.event.getValue()?.drivers.maxDrivers);
    const [eventChildren, setEventChildren] = useState<EventChildrenType>()
    const [eventFolders, setEventFolders] = useState<IFolderData[]>(state.event.getValue()?.children.folders);
    const [eventChildrenOrder, setEventChildrenOrder] = useState<MongoObjectIDType[]>(state.event.getValue()?.children.order);
    const [eventTeams, setEventTeams] = useState<ITeamData[]>(state.event.getValue()?.teams);
    const [eventTracks, setEventTracks] = useState<ITrackData[]>(state.event.getValue()?.tracks);
    const [eventResetSessionStartTimeDelay, setEventResetSessionStartTimeDelay] = useState<number>(state.event.getValue()?.resetSessionStartTimeDelay ?? SharedConfig.DEFAULT_EVENT_SESSION_START_TIME_DELAY);
    const [eventFollowSessionStartTimeDelay, setEventFollowSessionStartTimeDelay] = useState<number>(state.event.getValue()?.followSessionStartTimeDelay ?? SharedConfig.DEFAULT_EVENT_SESSION_START_TIME_DELAY);
    const [eventFollowSessionGameIDRule, setEventFollowSessionGameIDRule] = useState<FollowSessionGameIDRuleType>(state.event.getValue()?.followSessionGameIDRule ?? SharedConfig.DEFAULT_EVENT_FOLLOW_SESSION_GAME_ID_RULE);
    const [eventFollowSessionFinishOld, setEventFollowSessionFinishOld] = useState<boolean>(state.event.getValue()?.followSessionFinishOld ?? SharedConfig.DEFAULT_EVENT_FOLLOW_SESSION_FINISH_OLD);
    const [eventIsFinished, setEventIsFinished] = useState<boolean>(state.event.getValue()?.state == "finished");
    const [eventIsLive, setEventIsLive] = useState<boolean>(state.event.getValue()?.state == "live");
    const [eventEntryFee, setEventEntryFee] = useState<string>(state.event.getValue()?.drivers.entryFee);
    const [eventEntryFeeMessage, setEventEntryFeeMessage] = useState<string>(state.event.getValue()?.drivers.entryFeeMessage);
    const [eventRegisteredMessage, setEventRegisteredMessage] = useState<string>(state.event.getValue()?.drivers.registeredMessage);
    const [eventRequestsForbidden, setEventRequestsForbidden] = useState<boolean>(state.event.getValue()?.drivers.requestsForbidden);

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        updateEventStates();
        updateEventChildren();
        state.event.onChangeSignal.add(updateEventStates);
        state.eventChildren.onChangeSignal.add(updateEventChildren);
        return () => {
            state.event.onChangeSignal.remove(updateEventStates);
            state.eventChildren.onChangeSignal.remove(updateEventChildren);
        }
    }, [])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function updateEventStates() {
        const event: IEventData = state.event.getValue()
        setEventID(event?._id)
        setEventData(event)
        setEventName(event?.name)
        setEventPath(event?.path)
        setEventState(event?.state)
        setEventAboutArticle(event?.aboutArticle)
        setEventPrivacy(event?.privacy)
        setEventDate(event?.date ? moment(event.date).format("YYYY-MM-DD") : null)
        setEventDateUntil(event?.dateUntil ? moment(event.dateUntil).format("YYYY-MM-DD") : null)
        setEventIsPermanent(event?.isPermanent)
        setEventOwner(event?.owner)
        setEventOwnerType(event?.ownerType)
        setEventContact(event?.contact)
        setEventImage(event?.image)
        setEventLocation(event?.location)
        setEventMapLocation(event?.mapLocation)
        setEventAccess(event?.drivers?.access)
        setEventMaxDrivers(event?.drivers?.maxDrivers)
        setEventRegisteredDrivers(event?.drivers?.registered)
        setEventRequestingDrivers(event?.drivers?.requesting)
        setEventPendingDrivers(event?.drivers?.pending)
        setEventFolders(event?.children?.folders)
        setEventChildrenOrder(event?.children?.order)
        setEventTeams(event?.teams)
        setEventTracks(event?.tracks)
        setEventResetSessionStartTimeDelay(event?.resetSessionStartTimeDelay ?? SharedConfig.DEFAULT_EVENT_SESSION_START_TIME_DELAY)
        setEventFollowSessionStartTimeDelay(event?.followSessionStartTimeDelay ?? SharedConfig.DEFAULT_EVENT_SESSION_START_TIME_DELAY)
        setEventFollowSessionGameIDRule(event?.followSessionGameIDRule ?? SharedConfig.DEFAULT_EVENT_FOLLOW_SESSION_GAME_ID_RULE)
        setEventFollowSessionFinishOld(event?.followSessionFinishOld ?? SharedConfig.DEFAULT_EVENT_FOLLOW_SESSION_FINISH_OLD)
        setEventIsFinished(event?.state == "finished")
        setEventIsLive(event?.state == "live")
        setEventEntryFee(event?.drivers.entryFee)
        setEventEntryFeeMessage(event?.drivers.entryFeeMessage)
        setEventRegisteredMessage(event?.drivers.registeredMessage)
        setEventRequestsForbidden(event?.drivers.requestsForbidden)
    }

    function updateEventChildren() {
        setEventChildren(EventUtils.createEnrichedEventChildren(state.eventChildren.getValue()))
    }

    /* ----------------------------------------------------------------
 	 * RETURN
 	 * --------------------------------------------------------------*/

    return {
        eventID,
        eventData,
        eventName,
        eventPath,
        eventState,
        eventAboutArticle,
        eventOwner,
        eventOwnerType,
        eventContact,
        eventPrivacy,
        eventImage,
        eventDate,
        eventDateUntil,
        eventIsPermanent,
        eventLocation,
        eventMapLocation,
        eventAccess,
        eventMaxDrivers,
        eventRegisteredDrivers,
        eventRequestingDrivers,
        eventPendingDrivers,
        eventFolders,
        eventChildren,
        eventChildrenOrder,
        eventTeams,
        eventTracks,
        eventResetSessionStartTimeDelay,
        eventFollowSessionStartTimeDelay,
        eventFollowSessionGameIDRule,
        eventFollowSessionFinishOld,
        eventIsFinished,
        eventIsLive,
        eventEntryFee,
        eventEntryFeeMessage,
        eventRegisteredMessage,
        eventRequestsForbidden
    };
}
