import * as React from "react";
import {useRef, useState} from "react";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {Headline} from "../../../../../ui/text/headings/Headline";
import {LabelButton} from "../../../../../ui/buttons/label/LabelButton";
import {Table} from "../../../../../ui/table/Table";
import {IDictionaryEntryData} from "../../../../../../../shared/models/IDictionaryEntryData";
import {ITableColumnDefinition} from "../../../../../ui/table/ITableColumnDefinition";
import {useServices} from "../../../../../hooks/useServices";
import {TransferDictionaryType} from "../../../../../../../shared/types/TransferDictionaryType";
import {IMergedCollectionResult} from "../../../../../../../shared/types/IMergedCollectionResult";
import {useMobileStyle} from "../../../../../hooks/useMobileStyle";

/******************************************************************
 * TransferDictionary
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function TransferDictionary() {

    /* ----------------------------------------------------------------
 	 * REF
 	 * --------------------------------------------------------------*/

    const ref = useRef<HTMLDivElement>()

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, api} = useServices();
    const [isMobileMode] = useMobileStyle(ref, 600)

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [progressing, setProgressing] = useState<TransferDictionaryType>()
    const [updatedDictionaryEntries, setUpdatedDictionaryEntries] = useState<IDictionaryEntryData[]>()
    const [insertedDictionaryEntries, setInsertedDictionaryEntries] = useState<IDictionaryEntryData[]>()

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    async function transferDictionary(direction: TransferDictionaryType) {
        setProgressing(direction)
        setUpdatedDictionaryEntries(null)
        setInsertedDictionaryEntries(null)
        const response = await api.admin.transferDictionary(direction) as IMergedCollectionResult<IDictionaryEntryData>
        setUpdatedDictionaryEntries(response.updated)
        setInsertedDictionaryEntries(response.inserted)
        setProgressing(null)
    }

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function dictColumnsData(): ITableColumnDefinition<IDictionaryEntryData>[] {
        return [{
            dataKey: "key",
            sortKey: "key",
            columnName: dict("admin.dictionary.key.label"),
            type: "text",
            size: "4fr"
        }, {
            dataKey: "de",
            sortKey: "de",
            columnName: dict("language.de"),
            type: "text",
            size: "4fr"
        }, {
            dataKey: "en",
            sortKey: "en",
            columnName: dict("language.en"),
            type: "text",
            size: "4fr"
        }];
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            ref={ref}
            className="transfer-dictionary"
            framed={true}>
            <ContentLayout
                columnTemplate={isMobileMode ? null : "auto min-content"}
                justifyContent="space-between">
                <Headline
                    text={dict("admin.database.transfer.dictionary.title")}
                    style="h5-underlined"/>
                <ContentLayout
                    columnTemplate={"min-content min-content"}
                    gap="small"
                    justifyItems={isMobileMode ? "end" : null}>
                    <LabelButton
                        label={dict("admin.database.transfer.dictionary.localToMaster")}
                        style="primary-small"
                        disabled={!!progressing}
                        progressing={progressing == "localToMaster"}
                        onClick={() => transferDictionary("localToMaster")}/>
                    <LabelButton
                        label={dict("admin.database.transfer.dictionary.masterToLocal")}
                        disabled={!!progressing}
                        progressing={progressing == "masterToLocal"}
                        style="primary-small"
                        onClick={() => transferDictionary("masterToLocal")}/>
                </ContentLayout>
            </ContentLayout>
            {insertedDictionaryEntries?.length > 0 && <ContentLayout framed={true} gap="small">
                <Headline
                    text={dict("admin.database.transfer.dictionary.added.entries")}
                    style="h5"/>
                <Table<IDictionaryEntryData>
                    rowsData={insertedDictionaryEntries}
                    sortDirection="asc"
                    sortKey="key"
                    style="admin-compact"
                    visibleRows={15}
                    columnsDefinition={dictColumnsData()}/>
            </ContentLayout>}
            {updatedDictionaryEntries?.length > 0 && <ContentLayout framed={true} gap="small">
                <Headline
                    text={dict("admin.database.transfer.dictionary.updated.entries")}
                    style="h5"/>
                <Table<IDictionaryEntryData>
                    rowsData={updatedDictionaryEntries}
                    sortDirection="asc"
                    sortKey="key"
                    style="admin-compact"
                    visibleRows={15}
                    columnsDefinition={dictColumnsData()}/>
            </ContentLayout>}
        </ContentLayout>
    );

}
