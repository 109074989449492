import {useCallback, useState} from "react";

/******************************************************************
 * useForceUpdate
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function useForceUpdate() {
    const [, setCounter] = useState(0);
    return useCallback(() => setCounter(counter => counter + 1), []);
}
