import * as React from "react";
import {TimeUtils} from "../../../../../../shared/utils/TimeUtils";
import {ISessionLeaderboardEntry} from "../../../../../../shared/types/ISessionLeaderboardEntry";
import {ISessionData} from "../../../../../../shared/models/ISessionData";
import {ReactElement} from "react";
import {Icon} from "../../../icons/Icon";

/******************************************************************
 * TableLeaderboardBestLapTimeCell
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function TableLeaderboardBestLapTimeCell(props: {
    entry: ISessionLeaderboardEntry
}) {

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function primaryValue() {
        const session: ISessionData = props.entry.session
        const entry = props.entry
        if (entry.isStartOrder) {
            switch (session.setup.mode) {
                case "race":
                    return ""
            }
        }
        return TimeUtils.formatDrivenTime(bestEntryLapTime())
    }

    function secondaryValue() {
        const session: ISessionData = props.entry.session
        switch (session.setup.mode) {
            case "lap":
                if (props.entry.position == 1) return undefined
                const gap = timeGapToLeader()
                if (!gap || gap <= 0) return undefined
                return "+" + TimeUtils.formatDrivenTime(gap)
        }
        return undefined
    }

    function highlightLap(): boolean {
        const session: ISessionData = props.entry.session
        switch (session.setup.mode) {
            case "lap":
                return true
            case "race":
                return isLeaderboardBestLapTime()
            case "gymkhana":
                return false
        }
    }

    function isLeaderboardBestLapTime(): boolean {
        const bestLapTime = bestLeaderboardLapTime()
        if (!bestLapTime) return false
        const entryLapTime = bestEntryLapTime()
        return entryLapTime === bestLapTime
    }

    function timeGapToLeader() {
        const leaderBestLapTime = bestEntryLapTime(props.entry.entries[0])
        const thisBestLapTime = bestEntryLapTime(props.entry)
        if (!leaderBestLapTime || !thisBestLapTime) {
            return undefined
        }
        return thisBestLapTime - leaderBestLapTime
    }

    function primaryPrefixIcon(): ReactElement {
        const session: ISessionData = props.entry.session
        const entry = props.entry
        if (entry.state !== "finished") {
            return null
        }
        switch (session.setup.mode) {
            case "lap":
                switch (session.setup.classificationMode) {
                    case "teams":
                        switch (entry.type) {
                            case "primary":
                                switch (session.setup.teamScoringMode) {
                                    case "best":
                                        return <Icon type="star" scale={0.5}/>
                                    case "average":
                                        return <Icon type="avg" scale={0.5}/>
                                    case "sum":
                                        return <Icon type="sum" scale={0.5}/>
                                }
                                break
                            case "secondary-team":
                                return null
                        }
                }
                break
            case "race":
                break;
            case "gymkhana":
                break;
        }
        return null
    }

    function bestLeaderboardLapTime() {
        let leaderboardLapTime: number = null
        props.entry.entries.forEach(entry => {
            const entryLapTime = bestEntryLapTime(entry)
            if (!entryLapTime) return
            if (!leaderboardLapTime || leaderboardLapTime > entryLapTime) {
                leaderboardLapTime = entryLapTime
            }
        })
        return leaderboardLapTime
    }

    function bestEntryLapTime(otherEntry?: ISessionLeaderboardEntry): number {
        const session: ISessionData = props.entry.session
        const entry = otherEntry ?? props.entry
        switch (session.setup.mode) {
            case "lap":
                if (session.setup.classificationMode === "teams") {
                    switch (entry.type) {
                        case "primary":
                            switch (session.setup.teamScoringMode) {
                                case "best":
                                    return entry.overall?.bestLapTime
                                case "average":
                                    return entry.overall?.avgTeamBestLapTime
                                case "sum":
                                    return entry.overall?.sumTeamBestLapTime
                            }
                            break
                        case "secondary-team":
                            return entry.bestLapStint.lapTime
                    }
                }
                return entry.bestLapStint.lapTime
            case "race":
                switch (session.setup.timing) {
                    case "sync":
                        return entry.overall.bestLapTime
                    case "async":
                        return entry.bestDrivenTimeStint.bestLapTime
                }
                break
            case "gymkhana":
                return null
        }
    }

    function size() {
        switch (props.entry.type) {
            case "primary":
                return "normal"
            case "secondary-team":
                return "small"
        }
    }

    function isPrimary() {
        const session: ISessionData = props.entry.session
        switch (session.setup.mode) {
            case "lap":
                return true
            case "race":
            case "gymkhana":
                return false
        }
    }

    function theme(): "dark" | "light" {
        switch (props.entry.state) {
            case "ready":
            case "driving":
                return "light"
            case "invalid":
            case "finished":
                return "dark"
        }
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="table-leaderboard-bestlaptime-cell"
            data-all-rejected={props.entry.hasOnlyRejectedStints && props.entry.session.setup.useStintApproval}
            data-size={size()}
            data-theme={theme()}
            data-is-primary={isPrimary()}
            data-is-startorder={props.entry.isStartOrder}>
            <div
                className="table-leaderboard-bestlaptime-cell-primary"
                data-highlight={highlightLap()}>
                {primaryPrefixIcon()}{primaryValue()}
            </div>
            {secondaryValue() !== undefined && <div className="table-leaderboard-bestlaptime-cell-secondary">
                {secondaryValue()}
            </div>}
        </div>
    );

}
