import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {HelpButton} from "../../../buttons/help/HelpButton";
import {SelectedOption} from "./options/SelectedOption";
import {MultiSelectOptionType, MultiSelectPresetOptionType} from "./options/MultiSelectOption";
import {EditIcon} from "../../../icons/EditIcon";
import {MultiSelectModal} from "./modal/MultiSelectModal";

/******************************************************************
 * MultiSelectInput
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function MultiSelectInput(props: {
    label: string
    options: MultiSelectOptionType[]
    presetOptions?: MultiSelectPresetOptionType[]
    onChange: (value: string[]) => void
    minSelected?: number
    readonly?: boolean
    showFilter?: boolean
    sortOptionsByText?: boolean
    keepFilterOnOptionsChange?: boolean,
    defaultValue?: string[]
    helpTopic?: string
}) {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const rootRef = useRef<HTMLDivElement>();

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [showOptions, setShowOptions] = useState<boolean>(false)
    const [isValid, setIsValid] = useState<boolean>(true)

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        if (!props.defaultValue) return;
        setIsValid(props.defaultValue.length >= (props.minSelected ?? 1));
    }, [props.defaultValue])

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            ref={rootRef}
            className="multiselect-field"
            data-show-options={showOptions}
            data-valid={isValid}
            data-readonly={props.readonly}>
            <div className="multiselect-field-header">
                <label>{props.label}</label>
                <HelpButton helpTopic={props.helpTopic}/>
            </div>
            <div
                className="multiselect-field-selection"
                onClick={() => setShowOptions(true)}>
                <div className="multiselect-field-selection-tags">
                    {props.defaultValue?.map(value => {
                        const label = props.options.filter(option => option.value == value)[0]?.text
                        return <SelectedOption key={value} label={label}/>
                    })}
                </div>
                <EditIcon/>
            </div>
            {showOptions &&
                <MultiSelectModal
                    label={props.label}
                    keepFilterOnOptionsChange={props.keepFilterOnOptionsChange}
                    options={props.options?.sort((a, b) => props.sortOptionsByText ? a.text.localeCompare(b.text) : 0)}
                    presetOptions={props.presetOptions}
                    showFilter={props.showFilter}
                    minSelected={props.minSelected}
                    onClose={() => setShowOptions(false)}
                    defaultValue={props.defaultValue}
                    onChange={props.onChange}/>}
        </div>
    );

}
