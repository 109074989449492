import * as React from "react";

/******************************************************************
 * ImageIcon
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function ImageIcon(props: {
    scale?: number
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="image-icon">
            <svg width={20 * (props.scale ?? 1)} height={18 * (props.scale ?? 1)} viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.1429 0.37793H2.85714C2.09964 0.378757 1.37339 0.680042 0.837751 1.21568C0.302112 1.75132 0.000827168 2.47756 0 3.23507V14.6636C0.000827168 15.4212 0.302112 16.1474 0.837751 16.683C1.37339 17.2187 2.09964 17.52 2.85714 17.5208H17.1429C17.9004 17.52 18.6266 17.2187 19.1622 16.683C19.6979 16.1474 19.9992 15.4212 20 14.6636V3.23507C19.9992 2.47756 19.6979 1.75132 19.1622 1.21568C18.6266 0.680042 17.9004 0.378757 17.1429 0.37793ZM13.5714 3.23507C13.9952 3.23507 14.4095 3.36075 14.7619 3.59621C15.1143 3.83167 15.389 4.16634 15.5512 4.55789C15.7134 4.94945 15.7558 5.38031 15.6731 5.79598C15.5904 6.21165 15.3863 6.59347 15.0867 6.89316C14.787 7.19284 14.4052 7.39693 13.9895 7.47961C13.5738 7.56229 13.1429 7.51986 12.7514 7.35767C12.3598 7.19548 12.0252 6.92083 11.7897 6.56844C11.5542 6.21605 11.4286 5.80175 11.4286 5.37793C11.4292 4.80979 11.6551 4.26509 12.0569 3.86335C12.4586 3.46162 13.0033 3.23566 13.5714 3.23507ZM2.85714 16.0922C2.47826 16.0922 2.1149 15.9417 1.84699 15.6738C1.57908 15.4059 1.42857 15.0425 1.42857 14.6636V11.6444L5.6625 7.88105C6.071 7.51876 6.60226 7.32584 7.14805 7.3416C7.69384 7.35736 8.21308 7.58061 8.6 7.96588L11.4996 10.8592L6.26652 16.0922H2.85714ZM18.5714 14.6636C18.5714 15.0425 18.4209 15.4059 18.153 15.6738C17.8851 15.9417 17.5217 16.0922 17.1429 16.0922H8.28705L13.7076 10.6717C14.0914 10.3453 14.5784 10.1655 15.0822 10.1642C15.586 10.1629 16.0739 10.3402 16.4594 10.6645L18.5714 12.4244V14.6636Z" fill="white"/>
            </svg>
        </div>
    );

}
