import * as React from "react";
import {SubscriptionUtils} from "../../../../utils/SubscriptionUtils";
import {InfoText} from "../../text/infotext/InfoText";
import {useServices} from "../../../hooks/useServices";

/******************************************************************
 * PaddleSandboxModeInfo
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function PaddleSandboxModeInfo() {

    /* ----------------------------------------------------------------
 	 * HOOK
 	 * --------------------------------------------------------------*/

    const {dict} = useServices()

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return <>
        {SubscriptionUtils.isSandBoxMode() && <>
            <InfoText
                text={dict("plan.change.preview.sandbox")}
                align="center"
                size="small"
                style="warning"/>
        </>}
    </>;

}
