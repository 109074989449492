import * as React from "react";
import {ITableColumnDefinition} from "../../ITableColumnDefinition";

/******************************************************************
 * TableSessionsHeaderCell
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function TableSessionsHeaderCell(props: {
    columnDefinition: ITableColumnDefinition<any>
}) {

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/


    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="table-sessions-header-cell"
            data-key={props.columnDefinition.dataKey}>
            <div className="table-sessions-header-cell-labels">
                {props.columnDefinition.columnNames?.map((columnName, index) => {
                    return <div
                        key={index}
                        className="table-session-header-cell-label-box">
                        <div className="table-session-header-cell-label">
                            {columnName}
                        </div>
                    </div>})
                }
            </div>
        </div>
    );
}
