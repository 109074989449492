import * as React from "react"
import {useEffect, useState} from "react"
import {Input} from "../../../../../../ui/form/elements/input/Input"
import {DriverList} from "../../../../../../ui/list/driver/DriverList"
import {ContentLayout} from "../../../../../../ui/layout/content/ContentLayout"
import {MongoObjectIDType} from "../../../../../../../../shared/types/MongoObjectIDType"
import {useEventState} from "../../../../../../hooks/useEventState"
import {useServices} from "../../../../../../hooks/useServices"
import {IUserData} from "../../../../../../../../shared/models/IUserData"
import {useRouteStates} from "../../../../../../hooks/useRouteStates"
import {useGroupState} from "../../../../../../hooks/useGroupState";
import {GroupMemberRoleType} from "../../../../../../../../shared/types/GroupMemberRoleType";
import {useSimulatorStore} from "../../../../admin/simulator/hooks/useSimulatorStore";

/******************************************************************
 * AvailableDrivers
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

const MAX_DISPLAY_DRIVERS = 100

export function AvailableDrivers() {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {dict, api} = useServices()
    const {routeType} = useRouteStates()
    const {eventID, eventRegisteredDrivers} = useEventState()
    const {groupID, groupMembers} = useGroupState()
    const {simulationDrivers, setSimulationDrivers} = useSimulatorStore()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [allUsers, setAllUsers] = useState<IUserData[]>()
    const [registeredDrivers, setRegisteredDrivers] = useState<IUserData[]>()
    const [filteredUsers, setFilteredUsers] = useState<IUserData[]>()
    const [isLoadingData, setIsLoadingData] = useState<boolean>(false)
    const [isSavingData, setIsSavingData] = useState<boolean>(false)
    const [filter, setFilter] = useState<string>("")

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        switch (routeType) {
            case "event":
                setRegisteredDrivers(eventRegisteredDrivers)
                return
            case "group":
                setRegisteredDrivers(groupMembers?.map(member => member.user))
                return
            case "admin":
                setRegisteredDrivers(simulationDrivers?.map(driver => driver.user))
                return
        }
    }, [eventRegisteredDrivers, groupMembers, simulationDrivers])

    useEffect(() => {
        loadUsers()
    }, [registeredDrivers])

    useEffect(() => {
        filterData()
    }, [filter, allUsers])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function loadUsers() {
        setIsLoadingData(true)
        setAllUsers(await api.user.getAll())
        setIsLoadingData(false)
    }

    async function filterData() {
        const outputData = getFilteredDrivers()
        if (outputData?.length > MAX_DISPLAY_DRIVERS) {
            setFilteredUsers(null)
        } else {
            setFilteredUsers(outputData)
        }
    }

    function getAvailableDrivers() {
        return allUsers?.filter(allUser => {
            return registeredDrivers?.filter(
                registeredUser => {
                    return registeredUser && registeredUser?._id == allUser?._id
                }).length == 0
        })
    }

    function getFilteredDrivers() {
        const hasNoFilter = filter == "" || !filter || filter.length == 0
        return getAvailableDrivers()?.filter(user => {
            if (hasNoFilter) return true
            return user.nick?.toLowerCase().includes(filter.toLowerCase())
        })
    }

    async function addUser(userID: MongoObjectIDType) {
        setIsSavingData(true)
        switch (routeType) {
            case "event":
                const eventDrivers = registeredDrivers.filter(user => !!user)
                eventDrivers.push(userID)
                await api.event.registerDriver(eventID, userID)
                break
            case "group":
                const members = [...groupMembers]
                members.push({
                    role: "member" as GroupMemberRoleType,
                    user: userID
                })
                await api.group.update(groupID, {members})
                break
            case "admin":
                const drivers = [...simulationDrivers]
                drivers.push({
                    user: allUsers.filter(user => user._id == userID)[0]
                })
                setSimulationDrivers(drivers)
                break
        }
        setIsSavingData(false)
    }

    function emptyDataInfo(): string {
        const availableDrivers = getAvailableDrivers()
        if (availableDrivers?.length > 0) {
            if (filter && filter.length > 0 && getFilteredDrivers().length <= MAX_DISPLAY_DRIVERS) {
                return dict("drivers.add.filter.nodata")
            }
            return dict("drivers.add.use.filter").replace(
                "{NUM_AVAILABLE_USERS}",
                availableDrivers.length.toString())
        }
        return dict("drivers.add.nodata")
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout>
            <Input
                type="text"
                onChange={(value) => setFilter(value?.toLowerCase() ?? "")}
                placeholder={dict("drivers.filter.placeholder")}
                label={dict("drivers.filter.input.label")}/>
            <DriverList
                data={filteredUsers}
                hideFilter={true}
                primaryIconType="add"
                isBusy={isLoadingData || isSavingData}
                userClickable={false}
                onPrimaryIconClick={addUser}
                emptyDataInfo={emptyDataInfo()}/>
        </ContentLayout>
    )

}
