import * as React from "react";
import {useEffect, useState} from "react";
import {Headline} from "../../../ui/text/headings/Headline";
import {useServices} from "../../../hooks/useServices";
import {Modal} from "../Modal";
import {FrontendRoute} from "../../../../../shared/routes/FrontendRoute";
import {useEventState} from "../../../hooks/useEventState";
import {ContentLayout} from "../../../ui/layout/content/ContentLayout";
import {Input} from "../../../ui/form/elements/input/Input";
import {SessionStartTimeDelayValidation} from "../../../../../shared/validation/SessionStartTimeDelayValidation";
import {SelectInput} from "../../../ui/form/elements/select/SelectInput";
import {FollowSessionGameIDRuleType} from "../../../../../shared/types/FollowSessionGameIDRuleType";
import {LabelButton} from "../../../ui/buttons/label/LabelButton";
import {KeyController} from "../../../ui/keyboard/KeyController";

/******************************************************************
 * CreateFollowSessionModal
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function CreateFollowSessionModal() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, state, router, api} = useServices();
    const {
        eventID,
        eventOwnerType,
        eventOwner,
        eventPath,
        eventFollowSessionStartTimeDelay,
        eventFollowSessionGameIDRule,
        eventFollowSessionFinishOld,
    } = useEventState()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [originalSession] = useState(state.showCreateFollowSession.getValue()?.originalSession)
    const [isBusy, setIsBusy] = useState(false)
    const [isUpdating, setIsUpdating] = useState(false)
    const [isRequestingCreateFollowSession, setIsRequestingCreateFollowSession] = useState(false)

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        if (!isUpdating && isRequestingCreateFollowSession) {
            createFollowSession()
        }
    }, [isUpdating])

    /* ----------------------------------------------------------------
 	 * METHODS
 	 * --------------------------------------------------------------*/

    async function createFollowSession() {
        setIsBusy(true)
        setIsRequestingCreateFollowSession(false)
        if (isUpdating) {
            setIsRequestingCreateFollowSession(true)
            return
        }
        const {
            newSessionPath,
            originalSessionPath
        } = await api.session.createFollowSession(eventID, originalSession?._id)
        await router.showRoute(FrontendRoute.EVENT_SESSION(eventOwnerType, eventOwner.path, eventPath, originalSessionPath))
        await router.showRoute(FrontendRoute.EVENT_SESSION(eventOwnerType, eventOwner.path, eventPath, newSessionPath))
        state.showCreateFollowSession.setValue(null)
    }

    async function updateFollowSessionStartTimeDelay(value: string) {
        setIsUpdating(true)
        await api.event.update(eventID, {followSessionStartTimeDelay: parseInt(value)});
        setIsUpdating(false)
        return null
    }

    async function updateFollowSessionGameIDRule(rule: FollowSessionGameIDRuleType) {
        return api.event.update(eventID, {followSessionGameIDRule: rule});
    }

    async function updateFollowSessionFinishOld(value: "true" | "false") {
        return api.event.update(eventID, {followSessionFinishOld: value === "true"});
    }

    function placeholder() {
        return SessionStartTimeDelayValidation.MIN_MINUTES
            + "..." + SessionStartTimeDelayValidation.MAX_MINUTES
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal
            width="small"
            closeAction={() => state.showCreateFollowSession.setValue(null)}>
            <ContentLayout
                columnTemplate="auto min-content"
                justifyContent="space-between">
                <Headline
                    text={dict("session.addFollowSession.label")}
                    style="modal"/>
                <LabelButton
                    label={dict("event.settings.followsession.label")}
                    style="secondary-small"
                    onClick={() => state.showHelp.setValue("event.settings.followsession")}/>
            </ContentLayout>
            <ContentLayout>
                {!!originalSession?.setup?.startTime &&
                    <Input
                        readonly={isBusy}
                        type="number"
                        minNumber={SessionStartTimeDelayValidation.MIN_MINUTES}
                        maxNumber={SessionStartTimeDelayValidation.MAX_MINUTES}
                        stepNumber={1}
                        label={dict("event.settings.session.followSessionStartTimeDelay.label")}
                        helpTopic={"event.settings.session.followSessionStartTimeDelay"}
                        placeholder={placeholder()}
                        defaultValue={eventFollowSessionStartTimeDelay?.toString()}
                        action={updateFollowSessionStartTimeDelay}/>}
                <SelectInput
                    label={dict("event.settings.session.followSessionGameIDRule.label")}
                    defaultValue={eventFollowSessionGameIDRule}
                    helpTopic="event.settings.session.followSessionGameIDRule"
                    onChange={updateFollowSessionGameIDRule}
                    readonly={isBusy}
                    options={[
                        {
                            value: "increase" as FollowSessionGameIDRuleType,
                            text: dict("event.settings.session.followSessionGameIDRule.increase")
                        },
                        {
                            value: "keep" as FollowSessionGameIDRuleType,
                            text: dict("event.settings.session.followSessionGameIDRule.keep")
                        }
                    ]}/>
                <SelectInput
                    label={dict("event.settings.session.followSessionFinishOld.label")}
                    defaultValue={eventFollowSessionFinishOld ? "true" : "false"}
                    helpTopic="event.settings.session.followSessionFinishOld"
                    onChange={updateFollowSessionFinishOld}
                    readonly={isBusy}
                    options={[
                        {
                            value: "true" as FollowSessionGameIDRuleType,
                            text: dict("event.settings.session.followSessionFinishOld.true")
                        },
                        {
                            value: "false" as FollowSessionGameIDRuleType,
                            text: dict("event.settings.session.followSessionFinishOld.false")
                        }
                    ]}/>
                <LabelButton
                    label={dict("session.addFollowSession.label")}
                    style="primary"
                    progressing={isBusy}
                    onClick={createFollowSession}/>
                <KeyController
                    enabled={!isBusy}
                    code="Enter"
                    onKey={createFollowSession}/>
            </ContentLayout>
        </Modal>
    );

}
