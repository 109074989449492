import * as React from "react";
import {Modal} from "../Modal";
import {useServices} from "../../../hooks/useServices";
import {Headline} from "../../../ui/text/headings/Headline";
import {LogViewer} from "../../main/admin/logs/log/LogViewer";
import {useSessionState} from "../../../hooks/useSessionState";

/******************************************************************
 * SessionLogModal
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function SessionLogModal() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, state} = useServices()
    const {sessionLogData} = useSessionState()


    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal
            className="session-log-modal"
            width="extra-large"
            closeAction={() => state.showSessionLog.setValue(false)}>
            <Headline
                text={dict("session.log.title")}
                style="modal"/>
            <LogViewer
                logData={sessionLogData}
                logType="drift"
                isSessionLog={true}/>
        </Modal>
    );

}
