import * as React from "react";
import {IStintData} from "../../../../../../../shared/models/IStintData";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {LabelValueText} from "../../../../../ui/text/labelvalue/LabelValueText";
import {useServices} from "../../../../../hooks/useServices";
import {TimeUtils} from "../../../../../../../shared/utils/TimeUtils";
import {IStintLapData} from "../../../../../../../shared/types/IStintLapData";
import {useSessionState} from "../../../../../hooks/useSessionState";
import {StintSetupWarnings} from "../setup/StintSetupWarnings";
import {RaceDirectorUtils} from "../../../../../../../shared/utils/RaceDirectorUtils";
import {CapiTargetUtils} from "../../../../../../../shared/utils/CapiTargetUtils";

/******************************************************************
 * StintLapsMetrics
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function StintLapsMetrics(props: {
    stint: IStintData
}) {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {dict} = useServices()
    const {
        sessionData,
        sessionNumSectors,
        sessionFalseStartPenality,
        sessionMode,
        sessionLaps
    } = useSessionState()

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function theoreticalBestLapTime() {
        if (sessionNumSectors === 1) {
            return null
        }
        if (!validLaps() || validLaps().length === 0) {
            return null
        }
        const bestSplitTimes = []
        validLaps().forEach(lap => {
            lap.splitTimes.forEach((splitTime, i) => {
                if (isLapValid(lap) && splitTime < (bestSplitTimes[i] ?? Number.MAX_SAFE_INTEGER)) {
                    bestSplitTimes[i] = splitTime
                }
            })
        })
        return bestSplitTimes.reduce((a, b) => a + b, 0)
    }

    function averageLapTimeOverDrivenTime() {
        const laps = props.stint.computedLaps
        if (laps.length === 0) {
            return null
        }
        return props.stint.drivenTime / props.stint.computedLaps.length
    }

    function medianLapTimeOverValidLaps(): number | null {
        return TimeUtils.getMedianLapTime(validLaps()?.map(lap => lap.time))
    }

    function validLapRate() {
        if ((props.stint.computedLaps?.length ?? 0) === 0) {
            return null
        }
        return Math.round((validLaps()?.length ?? 0) / props.stint.computedLaps.length * 100) + "%"
    }

    function validLaps(): IStintLapData[] {
        return props.stint.computedLaps.filter(lap => {
            return !lap.isMissingFinishTarget
                && !lap.isMissingSectorTargets
                && !lap.wasBelowMinLapTime
                && !lap.hasUnwantedTargets
        })
    }

    function isLapValid(lap: IStintLapData): boolean {
        return !lap.isMissingFinishTarget
            && !lap.isMissingSectorTargets
            && !lap.wasBelowMinLapTime
            && !lap.hasUnwantedTargets
    }

    function stintInvalid(): boolean {
        return props.stint.state === "invalid"
    }

    function falseStartValue() {
        if (sessionMode != "race") {
            return "–"
        }
        return sessionFalseStartPenality ? "+" + sessionFalseStartPenality + "s" : "0s"
    }

    function getFirstTargetDrivenTime(): number {
        const firstTarget = props.stint?.targetsData?.[0]
        if (CapiTargetUtils.isFinishTarget(firstTarget)) {
            return firstTarget?.driven_time ?? null
        }
        return null
    }

    function getFirstTargetDistance(): string {
        const firstTarget = props.stint?.targetsData?.[0]
        if (CapiTargetUtils.isFinishTarget(firstTarget)) {
            if (firstTarget?.driven_distance !== null) {
                return firstTarget?.driven_distance + " m";
            }
        }
        return null
    }

    function theoreticalBestDrivenTime(lapTime: number): number {
        if (!lapTime) {
            return null
        }
        return sessionLaps * lapTime
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout className="stint-lap-metrics">
            {stintInvalid() &&
                <ContentLayout
                    framed={true}
                    gap="small">
                    <StintSetupWarnings
                        stint={props.stint}/>
                </ContentLayout>}
            {!stintInvalid() &&
                <ContentLayout
                    framed={true}
                    gap="small">
                    <LabelValueText
                        size="small"
                        label={dict("stint.metrics.signalTime")}
                        value={TimeUtils.formatDate(props.stint.startData?.signal_time) ?? "–"}/>
                    {props.stint.falseStart && sessionFalseStartPenality > 0 &&
                        <LabelValueText
                            size="small"
                            warning={sessionMode == "race"}
                            label={dict("stint.metrics.falseStart")}
                            value={falseStartValue()}/>}
                    <LabelValueText
                        size="small"
                        warning={RaceDirectorUtils.hasTimeOffset(props.stint, sessionData)}
                        label={dict("stint.metrics.drivenTime")}
                        value={RaceDirectorUtils.drivenTime(props.stint, sessionData)}/>
                    {/*{sessionMode == "race" && sessionFinishType == "laps" && sessionLaps > 1 && <>*/}
                    {/*    <LabelValueText*/}
                    {/*        size="small"*/}
                    {/*        label={dict("stint.metrics.theoreticalBestDrivenTime.bestLap")}*/}
                    {/*        value={TimeUtils.formatDrivenTime(theoreticalBestDrivenTime(props.stint.bestLapTime)) ?? "–"}/>*/}
                    {/*    {theoreticalBestLapTime() &&*/}
                    {/*        <LabelValueText*/}
                    {/*            size="small"*/}
                    {/*            label={dict("stint.metrics.theoreticalBestDrivenTime.theoreticalBestLap")}*/}
                    {/*            value={TimeUtils.formatDrivenTime(theoreticalBestDrivenTime(theoreticalBestLapTime())) ?? "–"}/>*/}
                    {/*    }*/}
                    {/*</>}*/}
                    <LabelValueText
                        size="small"
                        warning={RaceDirectorUtils.hasLapsOffset(props.stint, sessionData)}
                        label={dict("stint.metrics.laps")}
                        value={RaceDirectorUtils.drivenLaps(props.stint, sessionData)}/>
                    <LabelValueText
                        size="small"
                        label={dict("stint.metrics.validLapRate")}
                        value={validLapRate() ?? "–"}/>
                    {props.stint?.raceDirector?.message &&
                        <LabelValueText
                            size="small"
                            warning={true}
                            label={dict("stint.metrics.raceDirectorMessage")}
                            value={props.stint.raceDirector.message}/>}
                </ContentLayout>}
            {!stintInvalid() &&
                <ContentLayout
                    framed={true}
                    gap="small">
                    <LabelValueText
                        size="small"
                        label={dict("stint.metrics.bestLap")}
                        value={TimeUtils.formatDrivenTime(props.stint.bestLapTime) ?? "–"}/>
                    {theoreticalBestLapTime() &&
                        <LabelValueText
                            size="small"
                            label={dict("stint.metrics.theoreticalBestLap")}
                            value={TimeUtils.formatDrivenTime(theoreticalBestLapTime()) ?? "–"}/>}
                    <LabelValueText
                        size="small"
                        label={dict("stint.metrics.averageLapTimeOverDrivenTime")}
                        value={TimeUtils.formatDrivenTime(averageLapTimeOverDrivenTime()) ?? "–"}/>
                    <LabelValueText
                        size="small"
                        label={dict("stint.metrics.medianLapTimeOverValidLaps")}
                        value={TimeUtils.formatDrivenTime(medianLapTimeOverValidLaps()) ?? "–"}/>
                </ContentLayout>}
            {!stintInvalid() &&
                <ContentLayout
                    framed={true}
                    gap="small">
                    <LabelValueText
                        size="small"
                        label={dict("stint.metrics.reactionTime")}
                        value={TimeUtils.formatDrivenTime(getFirstTargetDrivenTime()) ?? "–"}/>
                    {/*<LabelValueText*/}
                    {/*    size="small"*/}
                    {/*    label={dict("stint.metrics.startDistance")}*/}
                    {/*    value={getFirstTargetDistance() ?? "–"}/>*/}
                </ContentLayout>}
        </ContentLayout>
    )
}
