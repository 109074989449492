import * as React from "react"
import {ReactElement, useEffect, useRef, useState} from "react"
import {HelpButton} from "../../../buttons/help/HelpButton"
import {TextValidation} from "../../../../../../shared/validation/TextValidation"
import {InlineVerifyAction} from "../../inlineaction/verify/InlineVerifyAction"
import {PasswordValidation} from "../../../../../../shared/validation/PasswordValidation"
import {useServices} from "../../../../hooks/useServices"
import {LabelButton} from "../../../buttons/label/LabelButton"
import {SessionDurationValidation} from "../../../../../../shared/validation/SessionDurationValidation"
import {IconType} from "../../../icons/IconType"
import {Icon} from "../../../icons/Icon"
import ReactMarkdown from "react-markdown";

/******************************************************************
 * Text
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

type InputType =
    | "text"
    | "email"
    | "password"
    | "textarea"
    | "date"
    | "datetime-local"
    | "number"
    | "duration"
    | "color"

export function Input(props: {
    id?: string,
    type: InputType
    label?: string
    placeholder?: string
    pattern?: string
    required?: boolean
    readonly?: boolean
    removeGapWhenReadonly?: boolean
    maxNumber?: number
    minNumber?: number
    maxTextareaLength?: number
    stepNumber?: number
    helpTopic?: string
    customLinkLabel?: string
    inlineHelp?: string
    focus?: boolean
    showInvalid?: boolean
    spellCheck?: boolean
    defaultValue?: string
    clearOnSubmit?: boolean
    size?: "normal" | "small"
    inline?: boolean
    icon?: IconType
    onValidated?: (isValid: boolean) => void
    onChange?: (value: any) => void
    onCustomLinkClick?: (e: MouseEvent) => void
    preventActionOnBlur?: boolean
    action?: (value: string) => Promise<Response>
    processing?: boolean
}) {

    /* ----------------------------------------------------------------
 	 * REF
 	 * --------------------------------------------------------------*/

    const inputRef = useRef<HTMLInputElement>()
    const textareaRef = useRef<HTMLTextAreaElement>()

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {dict, state, error} = useServices()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [currentValue, setCurrentValue] = useState<string>()
    const [isInternalSaving, setIsInternalSaving] = useState<boolean>(false)
    const [isExternalProcessing, setIsExternalProcessing] = useState<boolean>(false)
    const [isValid, setIsValid] = useState<boolean>(false)
    const [isEditing, setIsEditing] = useState<boolean>(false)
    const [hasFocus, setHasFocus] = useState<boolean>(false)
    const [type, setType] = useState<InputType>()
    const [callActionOnBlur, setCallActionOnBlur] = useState<boolean>()

    /* ----------------------------------------------------------------
 	 * Effects
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        if (props.focus) {
            inputRef.current.focus()
        }
    }, [props.focus])

    useEffect(() => {
        setCallActionOnBlur(!props.preventActionOnBlur)
    }, [props.preventActionOnBlur])

    useEffect(() => {
        setCurrentValue(props.defaultValue)
        if (!props.defaultValue) {
            inputElememt().value = props.readonly ? "–" : ""
        }
    }, [props.defaultValue])

    useEffect(() => {
        updateTextAreaHeight()
        updateType()
        props.onChange?.(currentValue)
        if (currentValue) {
            inputElememt().value = currentValue
        }
        checkValidity()
    }, [currentValue, props.type])

    useEffect(() => {
        setIsExternalProcessing(props.processing)
    }, [props.processing])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function reset() {
        inputElememt()?.blur()
        if (currentValue && inputElememt()) {
            inputElememt().value = currentValue
        }
        props.onChange?.(currentValue)
        setIsInternalSaving(false)
        setIsEditing(false)
        updateType()
    }

    async function save() {
        if (!inputElememt().validity.valid) return
        setIsInternalSaving(true)
        setIsEditing(true)
        const response = await props.action(inputElememt().value)
        if (response && response?.status != 200) {
            const errorJSON = await response.json()
            state.showMessage.setValue({
                type: "error",
                message: error.createMessage(errorJSON)
            })
            reset()
            return
        }
        inputElememt()?.blur()
        if (props.clearOnSubmit) {
            inputElememt().value = ""
        }
        setCurrentValue(props.clearOnSubmit ? "" : inputElememt()?.value)
        setIsInternalSaving(false)
        setIsEditing(false)
        updateType()

    }

    function checkValidity() {
        if (props.type == "date") {
            setIsValid(true)
            props.onValidated?.(true)
        } else {
            const isValid = inputElememt().validity.valid
            setIsValid(isValid)
            props.onValidated?.(isValid)
        }
    }

    function pattern(): string {
        if (props.pattern) {
            return props.pattern
        }
        switch (props.type) {
            case "text":
                return TextValidation.REGEXP.source
            case "email":
                return null
            case "password":
                return PasswordValidation.REGEXP.source
            case "duration":
                return SessionDurationValidation.REGEXP.source
        }
        return null
    }

    function showInputIcon() {
        if (props.readonly) return false
        if (props.type == "color") return false
        if (props.action && callActionOnBlur) return true
        return !(!!props.action && isEditing)

    }

    function inputElememt(): HTMLInputElement | HTMLTextAreaElement {
        return inputRef?.current ?? textareaRef?.current
    }

    function updateType() {
        switch (props.type) {
            case "duration":
                setType("text")
                return
            case "number":
                if (props.readonly) {
                    setType("text")
                    return
                }
        }
        if (props.readonly && (props.type == "date" || props.type == "datetime-local") && !props.defaultValue) {
            setType("text")
            return
        }
        setType(props.type)
    }

    function inputIcon(): ReactElement {
        if (props.icon) {
            return <Icon
                className="input-icon-type"
                type={props.icon}
                scale={props.size == "small" ? 0.8 : 1}/>
        }
        switch (props.type) {
            case "date":
                return <Icon type="date" className="input-icon-type"/>
            default:
                return <Icon type="edit" className="input-icon-type"/>
        }
    }

    /* ----------------------------------------------------------------
 	 * EVENTS
 	 * --------------------------------------------------------------*/

    function updateTextAreaHeight() {
        if (!textareaRef.current) return
        textareaRef.current.style.height = "5px"
        textareaRef.current.style.height = (textareaRef.current.scrollHeight) + "px"
    }

    function onChange() {
        if (props.onChange) {
            props.onChange(inputElememt().value)
        }
        checkValidity()
        updateTextAreaHeight()
    }

    function onFocus() {
        updateType()
        setIsEditing(true)
        setHasFocus(true)
        checkValidity()
    }

    function onBlur() {
        updateType()
        setHasFocus(false)
        const hasChanged = inputElememt().value != currentValue
        if (!hasChanged) {
            setIsEditing(false)
        }
        if (props.action && callActionOnBlur && isValid && hasChanged) {
            save()
        }
    }

    function showHeader() {
        return props.label || props.helpTopic || props.customLinkLabel
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="input-field"
            data-busy={isInternalSaving || isExternalProcessing}
            data-is-empty={inputElememt()?.value == "" && !currentValue ? true : null}
            data-readonly={props.readonly}
            data-remove-gap-when-readonly={props.removeGapWhenReadonly}
            data-size={props.size ?? "default"}
            data-inline={props.inline}
            data-action={!!props.action}>
            {showHeader() &&
                <div className="input-field-header">
                    {(props.label || props.customLinkLabel) &&
                        <label htmlFor={props.id}>{props.label ?? " "}</label>}
                    {props.helpTopic &&
                        <HelpButton helpTopic={props.helpTopic}/>}
                    {props.customLinkLabel && props.onCustomLinkClick &&
                        <LabelButton label={props.customLinkLabel} style="small-link"
                                     onClick={props.onCustomLinkClick}/>}
                </div>}
            <div
                className="input-field-body"
                data-type={props.type}
                data-focus={hasFocus}
                data-show-invalid={props.showInvalid}>
                {props.type == "textarea"
                    ? <textarea
                        ref={textareaRef}
                        id={props.id}
                        readOnly={props.readonly}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onInput={onChange}
                        maxLength={props.maxTextareaLength}
                        onChange={onChange}
                        spellCheck={props.spellCheck}
                        defaultValue={currentValue ?? null}
                        placeholder={props.placeholder ?? dict("form.input.text.placeholder")}/>
                    : <input
                        ref={inputRef}
                        type={type}
                        id={props.id}
                        max={props.maxNumber}
                        min={props.minNumber}
                        step={props.stepNumber}
                        pattern={pattern()}
                        readOnly={props.readonly}
                        required={props.required}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onInput={onChange}
                        onChange={onChange}
                        spellCheck={props.spellCheck}
                        defaultValue={currentValue ?? (props.type == "color" ? "#000000" : null)}
                        placeholder={props.placeholder ?? dict("form.input.text.placeholder")}/>
                }
                {showInputIcon() && inputIcon()}
                {!!props.action &&
                    <InlineVerifyAction
                        isBusy={isInternalSaving || isExternalProcessing}
                        show={isEditing}
                        size={props.size}
                        preventSave={!isValid}
                        useEnterForSave={props.type != "textarea"}
                        hideSaveButton={callActionOnBlur}
                        hideResetButton={callActionOnBlur}
                        onReset={reset}
                        onSave={save}/>}
            </div>
            {props.inlineHelp &&
                <ReactMarkdown className="input-field-inline-help">{props.inlineHelp}</ReactMarkdown>}
        </div>
    )
}
