import * as React from "react";
import {IStintData} from "../../../../../../../shared/models/IStintData";
import {useSessionState} from "../../../../../hooks/useSessionState";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {useEffect, useState} from "react";
import {IInvalidEnterProp} from "../../../../../../../shared/types/IInvalidEnterProp";
import {CapiEnterUtils} from "../../../../../../../shared/utils/CapiEnterUtils";
import {InvalidSetupPropsUtils} from "../../../../../../utils/InvalidSetupPropsUtils";
import {useServices} from "../../../../../hooks/useServices";
import {Headline} from "../../../../../ui/text/headings/Headline";
import {Icon} from "../../../../../ui/icons/Icon";
import ReactMarkdown from "react-markdown";
import {TimeUtils} from "../../../../../../../shared/utils/TimeUtils";
import {StringUtils} from "../../../../../../../shared/utils/StringUtils";
import {DriftUtils} from "../../../../../../../shared/utils/DriftUtils";

/******************************************************************
 * StintSetupWarnings
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function StintSetupWarnings(props: {
    stint: IStintData
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict} = useServices()
    const {sessionData} = useSessionState();

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [invalidProps, setInvalidProps] = useState<IInvalidEnterProp[]>()

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        setInvalidProps(CapiEnterUtils.settingsValidator(props.stint.enterData, sessionData).invalidProps)
    }, [props.stint, sessionData])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function shouldValue(invalidProp: IInvalidEnterProp) {
        if (Array.isArray(invalidProp.should)) {
            return invalidProp.should.map((shouldValue) => {
                return parseValue(invalidProp, shouldValue)
            })?.join("  \n") ?? ""
        }
        return parseValue(invalidProp, invalidProp.should)
    }

    function parseValue(prop: IInvalidEnterProp, value: string) {
        switch (prop.name) {
            case "wheels":
                return dict(InvalidSetupPropsUtils.getPropDictKey(prop) + "." + StringUtils.toCamelCase(value)?.replace("Tires", ""))
            case "track_bundle":
            case "track_condition":
            case "setup_mode":
            case "game_mode":
                return dict(InvalidSetupPropsUtils.getPropDictKey(prop) + "." + StringUtils.toCamelCase(value))
            case "tuning_type":
            case "engine_type":
                return DriftUtils.getTuningWithoutEngine(dict(InvalidSetupPropsUtils.getPropDictKey(prop) + "." + value))
            case "start_time":
                return value ? TimeUtils.formatDate(value) : "–"
            default:
                return value
        }
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout className="stint-setup-warnings">
            <Headline text={dict("stint.setup.warning.label")} style="h5-underlined"/>
            <>{invalidProps && invalidProps.map((invalidProp, index) => {
                return (
                    <div className="stint-setup-warning" key={index}>
                        <div className="stint-setup-warning-name">
                            <Icon type="warning" scale={0.8}/>{dict(InvalidSetupPropsUtils.getPropDictKey(invalidProp))}
                        </div>
                        <div className="stint-setup-warning-values">
                            <span className="stint-setup-warning-is">{parseValue(invalidProp, invalidProp.isValue)}</span>
                            <Icon type="next" scale={0.7}/>
                            <span className="stint-setup-warning-should"><ReactMarkdown>{shouldValue(invalidProp)}</ReactMarkdown></span>
                        </div>
                    </div>
                )
            })}</>
        </ContentLayout>
    );

}
