import * as React from "react";

/******************************************************************
 * MailIcon
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function MailIcon(props: {
    scale?: number
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="mail-icon">
            <svg width={20 * (props.scale ?? 1)} height={21 * (props.scale ?? 1)} viewBox="0 0 20 21" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M17.0873 2.95264H2.9127C2.28635 2.95331 1.68584 3.20242 1.24294 3.64532C0.800047 4.08822 0.550932 4.68872 0.550262 5.31507V15.4398C0.550932 16.0662 0.800047 16.6667 1.24294 17.1096C1.68584 17.5525 2.28635 17.8016 2.9127 17.8022H17.0873C17.7137 17.8016 18.3142 17.5525 18.7571 17.1096C19.2 16.6667 19.4491 16.0662 19.4498 15.4398V5.31507C19.4491 4.68872 19.2 4.08822 18.7571 3.64532C18.3142 3.20242 17.7137 2.95331 17.0873 2.95264ZM16.4891 6.86036L10.4143 11.5852C10.2958 11.6773 10.1501 11.7273 10 11.7273C9.84997 11.7273 9.7042 11.6773 9.58574 11.5852L3.5109 6.86036C3.43953 6.80646 3.37958 6.73892 3.33454 6.66165C3.28949 6.58438 3.26026 6.49893 3.24852 6.41026C3.23679 6.32159 3.2428 6.23148 3.26619 6.14515C3.28959 6.05883 3.32991 5.97801 3.38481 5.90741C3.43971 5.8368 3.50809 5.7778 3.58599 5.73385C3.66388 5.6899 3.74974 5.66187 3.83856 5.65139C3.92738 5.6409 4.0174 5.64818 4.10339 5.67279C4.18938 5.69739 4.26962 5.73885 4.33944 5.79473L10 10.1973L15.6606 5.79473C15.8021 5.68784 15.9801 5.64096 16.1559 5.66423C16.3317 5.6875 16.4913 5.77904 16.6002 5.91907C16.7091 6.05909 16.7585 6.23635 16.7377 6.41249C16.7169 6.58864 16.6276 6.74953 16.4891 6.86036Z"
                    fill="white"/>
            </svg>
        </div>
    );

}

