import * as React from "react";
import {Modal} from "../../Modal";
import {Headline} from "../../../../ui/text/headings/Headline";
import {useServices} from "../../../../hooks/useServices";
import {DriverLicensePlans} from "../../../../ui/plans/DriverLicensePlans";
import {ContentLayout} from "../../../../ui/layout/content/ContentLayout";
import {LabelButton} from "../../../../ui/buttons/label/LabelButton";

/******************************************************************
 * SubscriptionPlansModal
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function SubscriptionPlansModal() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, state} = useServices()

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal
            className="subscription-modal"
            width="extra-large"
            minHeight="medium"
            closeAction={() => state.showSubscriptionPlans.setValue(false)}>
            <ContentLayout
                justifyContent="space-between"
                columnTemplate="1fr min-content">
                <Headline
                    text={dict("subscription.modal.title")}
                    style="modal"/>
                <LabelButton
                    label={dict("mysubscription.modal.label")}
                    style="primary-small"
                    onClick={() => state.showSubscriptionInfo.setValue(true)}/>
            </ContentLayout>
            <DriverLicensePlans/>
        </Modal>
    );

}
