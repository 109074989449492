import * as React from "react";
import {useEffect, useState} from "react";
import {Modal} from "../Modal";
import {useServices} from "../../../hooks/useServices";
import {IGroupData} from "../../../../../shared/models/IGroupData";
import {ComponentInitializer} from "../../../ui/utils/init/ComponentInitializer";
import {GroupHomeHeader} from "../../main/group/home/header/GroupHomeHeader";
import {ArticleReaderBox} from "../../../ui/article/reader/ArticleReaderBox";
import {ContentLayout} from "../../../ui/layout/content/ContentLayout";
import {GroupMembersInfobox} from "../../../ui/infobox/members/GroupMembersInfobox";
import {TracksInfobox} from "../../../ui/infobox/tracks/TracksInfobox";
import {LabelButton} from "../../../ui/buttons/label/LabelButton";
import {Spacer} from "../../../ui/utils/spacer/Spacer";
import {ActionBar} from "../../../ui/bar/ActionBar";
import {FrontendRoute} from "../../../../../shared/routes/FrontendRoute";
import {GroupUtils} from "../../../../../shared/utils/GroupUtils";
import {IUserData} from "../../../../../shared/models/IUserData";

/******************************************************************
 * GroupPreviewModal
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function GroupPreviewModal() {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {dict, state, api, router} = useServices()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [group, setGroup] = useState<IGroupData>(null)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [showContactButton, setShowContactButton] = useState<IUserData>()

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        loadGroupData()
    }, [])

    useEffect(() => {
        if (group) {
            setShowContactButton(GroupUtils.getContact(group))
        }
    }, [group])


    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    async function loadGroupData() {
        setIsLoading(true)
        const result = await api.group.getGroupDataByID(state.showGroupPreview.getValue())
        setGroup(result)
        setIsLoading(false)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal
            className="group-preview-modal"
            width="medium"
            closeAction={() => state.showGroupPreview.setValue(null)}>
            <ComponentInitializer isPropertyAvailable={!isLoading}>
                <ContentLayout>
                    <GroupHomeHeader group={group} showAvatar={true}/>
                    {group?.aboutArticle &&
                        <ArticleReaderBox
                            type="group"
                            articleID={group?.aboutArticle?._id ?? group?.aboutArticle}/>}
                    <ContentLayout>
                        <GroupMembersInfobox
                            group={group}
                            isPreview={true}/>
                        <TracksInfobox
                            group={group}
                            isPreview={true}/>
                    </ContentLayout>
                </ContentLayout>
                <Spacer height="modal-actionbar"/>
            </ComponentInitializer>
            {!isLoading &&
                <ActionBar location="modal">
                    {showContactButton &&
                        <LabelButton
                            label={dict("group.contact.label")}
                            style={"action-main"}
                            onClick={() => state.showContact.setValue({
                                sendMessageTo: group,
                                messageType: "group"
                            })}/>}
                    <LabelButton
                        label={dict("group.goto.label")}
                        style={"action-main"}
                        onClick={() => router.showRoute(FrontendRoute.GROUP(group.path))}/>
                </ActionBar>}
        </Modal>
    );

}
