import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {Modal} from "../Modal";
import {Headline} from "../../../ui/text/headings/Headline";
import {useServices} from "../../../hooks/useServices";
import {ContentLayout} from "../../../ui/layout/content/ContentLayout";
import {LabelButton} from "../../../ui/buttons/label/LabelButton";
import {Divider} from "../../../ui/utils/divider/Divider";
import {FormMessage} from "../../../ui/form/elements/message/FormMessage";
import {Input} from "../../../ui/form/elements/input/Input";
import {DriftCarSetupInput} from "../../../ui/form/elements/drift/DriftCarSetupInput";
import {DriftCarSetupType} from "../../../../../shared/types/DriftCarSetupType";
import {DriftTrackConditionInput} from "../../../ui/form/elements/drift/DriftTrackConditionInput";
import {DriftTrackConditionType} from "../../../../../shared/types/DriftTrackConditionType";
import {DriftTiresInput} from "../../../ui/form/elements/drift/DriftTiresInput";
import {DriftTiresType} from "../../../../../shared/types/DriftTiresType";
import {DriftEnginesInput} from "../../../ui/form/elements/drift/DriftEnginesInput";
import {DriftEngineType} from "../../../../../shared/types/DriftEngineType";
import {DriftTuningType} from "../../../../../shared/types/DriftTuningType";
import {DriftTuningsInput} from "../../../ui/form/elements/drift/DriftTuningsInput";
import {TextValidation} from "../../../../../shared/validation/TextValidation";
import {useTrackState} from "../../../hooks/useTrackState";
import {ITrackFilterData} from "../../../../../shared/models/submodels/ITrackFilterData";
import {FrontendRoute} from "../../../../../shared/routes/FrontendRoute";
import {useRouteOwner} from "../../../hooks/useRouteOwner";
import {useGroupState} from "../../../hooks/useGroupState";
import {ComponentInitializer} from "../../../ui/utils/init/ComponentInitializer";
import {InfoText} from "../../../ui/text/infotext/InfoText";

/******************************************************************
 * TrackFilterModal
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function TrackFilterModal() {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const ref = useRef<HTMLDivElement>()

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {state, dict, api, error, router} = useServices();
    const {trackID, trackPath, trackFilters, trackOwner, trackOwnerType} = useTrackState()
    const {isRouteOwnerAuthUser} = useRouteOwner();
    const {isGroupAdmin} = useGroupState();

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [mode, setMode] = useState<"create" | "edit">("create")
    const [isInitialized, setIsInitialized] = useState<boolean>(false)
    const [isValid, setIsValid] = useState<boolean>()
    const [driverLicenseAllowedToEdit, setDriverLicenseAllowedToEdit] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>()
    const [isEditable, setIsEditable] = useState<boolean>(false)
    const [isPreset, setIsPreset] = useState<boolean>()
    const [isBusy, setIsBusy] = useState<boolean>()
    const [name, setName] = useState<string>()
    const [carSetup, setCarSetup] = useState<DriftCarSetupType[]>(["RACE"])
    const [trackConditions, setTrackConditions] = useState<DriftTrackConditionType[]>(["drift_asphalt"])
    const [tires, setTires] = useState<DriftTiresType[]>(["normal"])
    const [engines, setEngines] = useState<DriftEngineType[]>(["any"])
    const [tunings, setTunings] = useState<DriftTuningType[]>(["any"])
    const [readyToClose, setReadyToClose] = useState<boolean>()

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        if (canUserEditOrCreate() && !isPreset) {
            api.track.canCreateTrack().then((canCreateOrEditFilters) => {
                if (canCreateOrEditFilters) {
                    setDriverLicenseAllowedToEdit(true)
                    initModal()
                } else {
                    if (hasTrackFilterData()) {
                        initModal()
                    } else {
                        state.showTrackFilter.setValue(null)
                        showUpgradeInfo()
                    }
                }
            })
        } else {
            initModal()
        }
    }, [])

    useEffect(() => {
        setIsValid(!!name && TextValidation.isValid(name));
    }, [name, carSetup, trackConditions, tires, engines, tunings])

    useEffect(() => {
        setIsEditable(canUserEditOrCreate() && !isPreset && driverLicenseAllowedToEdit)
    }, [isRouteOwnerAuthUser, isGroupAdmin, isPreset, driverLicenseAllowedToEdit])

    useEffect(() => {
        if (!readyToClose) return;
        switch (mode) {
            case "create":
                const createFilter = trackFilters[trackFilters.length - 1]
                if (!createFilter.path) return
                router.showRoute(FrontendRoute.TRACK_FILTER(trackOwnerType, trackOwner.path, trackPath, createFilter.path))
                close()
                break;
            case "edit":
                const filterState = (state.showTrackFilter.getValue() as ITrackFilterData)
                const editFilter = (trackFilters.filter(data => data._id == filterState._id))[0]
                if (!editFilter.path) return
                if (filterState.path && filterState.path == editFilter.path) return
                router.showRoute(FrontendRoute.TRACK_FILTER(trackOwnerType, trackOwner.path, trackPath, editFilter.path))
                close()
                break;
        }
    }, [trackFilters, readyToClose])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function initModal() {
        const stateValue: ITrackFilterData | boolean = state.showTrackFilter.getValue()
        const trackFilter = stateValue as ITrackFilterData;
        if (typeof stateValue == "object") {
            setMode("edit")
            setIsPreset(isPresetFilter())
            applyFilterData(trackFilter)
        }
        setIsInitialized(true)
    }

    function hasTrackFilterData(): boolean {
        const stateValue: ITrackFilterData | boolean = state.showTrackFilter.getValue()
        return typeof stateValue == "object"
    }

    function isPresetFilter(): boolean {
        const stateValue: ITrackFilterData | boolean = state.showTrackFilter.getValue()
        const trackFilter = stateValue as ITrackFilterData;
        return trackFilter?.isPreset
    }

    function canUserEditOrCreate(): boolean {
        return isRouteOwnerAuthUser || isGroupAdmin
    }

    function showUpgradeInfo() {
        state.showSubscriptionUpgradeInfo.setValue({
            message: dict("subscription.upgrade.info.track.filters")
        })
    }

    function close() {
        setIsBusy(false)
        state.showTrackFilter.setValue(null);
    }

    function applyFilterData(trackFilter: ITrackFilterData) {
        if (!trackFilter) return
        setName(trackFilter.name)
        setCarSetup(trackFilter.carSetup)
        setTrackConditions(trackFilter.trackConditions)
        setTires(trackFilter.tires)
        setEngines(trackFilter.engines)
        setTunings(trackFilter.tunings)
    }

    function getCurrentFilter(): ITrackFilterData {
        return {
            name: name,
            carSetup: carSetup,
            trackConditions: trackConditions,
            tires: tires,
            engines: engines,
            tunings: tunings
        }
    }

    async function saveTrackFilter() {
        setIsBusy(true)
        const filters: ITrackFilterData[] = trackFilters.concat()
        switch (mode) {
            case "create":
                filters.push(getCurrentFilter())
                break;
            case "edit":
                const editFilter = filters.filter(data => data._id == (state.showTrackFilter.getValue() as ITrackFilterData)._id)[0]
                delete editFilter.path
                editFilter.name = name
                editFilter.carSetup = carSetup
                editFilter.trackConditions = trackConditions
                editFilter.tires = tires
                editFilter.engines = engines
                editFilter.tunings = tunings
                break;
        }
        const response = await api.track.update(trackID, {filters: filters})
        if (response.status != 200) {
            setIsBusy(false)
            const responseJSON = await response.json()
            setErrorMessage(error.createMessage(responseJSON))
        } else {
            setReadyToClose(true)
        }
    }

    function showPlans() {
        state.showTrackFilter.setValue(null)
        state.showSubscriptionPlans.setValue(true)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal
            closeAction={close}
            width="medium"
            className="track-filter-modal"
            position="global"
            data-readonly={isBusy || !isEditable}>
            <Headline
                text={mode == "create"
                    ? dict("track.filter.create.headline")
                    : isPreset ? dict(name) : name}
                style="modal"/>
            <ComponentInitializer isPropertyAvailable={isInitialized}>
                <ContentLayout ref={ref}>
                    {canUserEditOrCreate() && !driverLicenseAllowedToEdit &&
                        <ContentLayout framed={true} gap="small" justifyItems="center">
                            <InfoText
                                text={dict("track.filter.driverLicense.noedit.info")}
                                align="center"
                                size="small"/>
                            <LabelButton
                                label={dict("subscription.upgrade.info.button.showPlans")}
                                style="primary-small"
                                onClick={showPlans}/>
                        </ContentLayout>}
                    {isEditable && <Input
                        type="text"
                        label={dict("track.filter.name.label")}
                        defaultValue={name}
                        action={async (name: string) => {
                            setName(name);
                            return null;
                        }}
                    />}
                    {isEditable && <Divider style="modal"/>}
                    <ContentLayout>
                        <DriftCarSetupInput
                            carSetups={carSetup}
                            readonly={!isEditable}
                            onChange={(setups) => setCarSetup(setups)}/>
                        <DriftTrackConditionInput
                            trackConditions={trackConditions}
                            readonly={!isEditable}
                            onChange={(conditions) => setTrackConditions(conditions)}/>
                        <DriftTiresInput
                            tires={tires}
                            readonly={!isEditable}
                            onChange={(tires) => setTires(tires)}/>
                    </ContentLayout>
                    <Divider style="modal"/>
                    <DriftEnginesInput
                        engines={engines}
                        readonly={!isEditable}
                        onChange={(engines) => setEngines(engines)}/>
                    <DriftTuningsInput
                        tunings={tunings}
                        readonly={!isEditable}
                        onChange={(tunings) => setTunings(tunings)}/>
                    {isEditable && <Divider style="modal"/>}
                    {errorMessage && <FormMessage message={errorMessage} type="error"/>}
                    {isEditable &&
                        <LabelButton
                            label={dict(`track.filter.${mode == "edit" ? "save" : "create"}.label`)}
                            style="primary"
                            progressing={isBusy}
                            disabled={!isValid}
                            onClick={saveTrackFilter}/>}
                </ContentLayout>
            </ComponentInitializer>
        </Modal>
    )
}
