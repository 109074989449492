import * as React from "react";
import {useRef, useState} from "react";
import {IArticleImage} from "../../../../../../shared/models/IArticleData";
import {ContentLayout} from "../../../layout/content/ContentLayout";
import {useServices} from "../../../../hooks/useServices";
import {UploadableImage} from "../../../image/uploadable/UploadableImage";
import {MongoObjectIDType} from "../../../../../../shared/types/MongoObjectIDType";
import {Input} from "../../../form/elements/input/Input";
import {Picture} from "../../../image/Picture";
import {InfoText} from "../../../text/infotext/InfoText";
import {useMobileStyle} from "../../../../hooks/useMobileStyle";
import {LanguageType} from "../../../../../../shared/types/LanguageType";
import {useAuthUser} from "../../../../hooks/useAuthUser";

/******************************************************************
 * ArticleImageBlock
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function ArticleImageBlock(props: {
    articleID: MongoObjectIDType
    image: IArticleImage
    language: LanguageType
    editing: boolean
    onChange: (data: IArticleImage) => void
}) {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const rootRef = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {api, dict} = useServices()
    const {authUserHasPermission} = useAuthUser()
    const [isMobileStyle] = useMobileStyle(rootRef, 600)

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [uploading, setUploading] = useState<boolean>(false)

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    async function updateImage(file: File) {
        setUploading(true)
        const fileData = await api.file.uploadImage({
            ownerID: props.articleID,
            ownerType: "article",
            fileID: props.image.file?._id,
            file: file
        })
        if (!fileData) {
            setUploading(false)
            return null
        }
        setUploading(false)
        props.image.file = fileData._id
        props.onChange(props.image)
        return null
    }

    async function updateCaption(caption: string, translate?: boolean): Promise<Response> {
        if (translate) {
            caption = await api.translate.text(caption, null, props.language)
        }
        props.image.caption = caption
        props.onChange(props.image)
        return null
    }

    async function updateAlt(alt: string, translate?: boolean): Promise<Response> {
        if (translate) {
            alt = await api.translate.text(alt, null, props.language)
        }
        props.image.alt = alt
        props.onChange(props.image)
        return null
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            className="article-image-block"
            ref={rootRef}
            framed={true}
            gap="small">
            {props.editing
                ? <ContentLayout
                    columnTemplate={isMobileStyle ? null : "1fr 2fr"}
                    gap="small">
                    <ContentLayout alignSelf="stretch">
                        <UploadableImage
                            onChange={updateImage}
                            progressing={uploading}
                            framing="rounded-4"
                            showUploadButton={props.editing}
                            editable={props.editing}
                            style="relative"
                            imageSrc={api.file.getImageURLByFile(props.image.file)}
                            fit="contain"
                            buttonLabel={dict("form.upload.article.select")}/>
                    </ContentLayout>
                    <ContentLayout gap="small">
                        <Input
                            type="textarea"
                            size="small"
                            label={dict("article.image.caption")}
                            defaultValue={props.image.caption}
                            customLinkLabel={authUserHasPermission("api:translate") ? dict("input.translate.label") : null}
                            onCustomLinkClick={() => updateCaption(props.image.caption, true)}
                            action={updateCaption}/>
                        <Input
                            type="text"
                            size="small"
                            label={dict("article.image.alt")}
                            defaultValue={props.image.alt}
                            customLinkLabel={authUserHasPermission("api:translate") ? dict("input.translate.label") : null}
                            onCustomLinkClick={() => updateAlt(props.image.alt, true)}
                            action={updateAlt}/>
                    </ContentLayout>
                </ContentLayout>
                : <ContentLayout gap="tiny">
                    {props.image.file &&
                        <Picture
                            file={props.image.file}
                            alt={props.image.alt}
                            framing="rounded-4"
                            allowMediaViewer={true}/>}
                    {props.image.caption &&
                        <InfoText
                            size="small"
                            text={props.image.caption}/>}
                </ContentLayout>}
        </ContentLayout>
    )
}
