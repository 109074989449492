import * as React from "react";
import {useRef} from "react";
import {Headline} from "../../../../ui/text/headings/Headline";
import {Picture} from "../../../../ui/image/Picture";
import {ContentLayout} from "../../../../ui/layout/content/ContentLayout";
import {useTrackState} from "../../../../hooks/useTrackState";
import {useElementSize} from "../../../../hooks/useElementSize";
import {Spacer} from "../../../../ui/utils/spacer/Spacer";
import {useDeviceInfo} from "../../../../hooks/useDeviceInfo";

/******************************************************************
 * TrackHeader
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function TrackHeader() {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const rootRef = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {trackName, trackLayout, trackImage} = useTrackState()
    const rootWidth = useElementSize(rootRef)[0]
    const {isMobile} = useDeviceInfo()

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function computeAspectRatio(): number {
        if (!rootWidth) return 16 / 9;
        const x1 = 400, y1 = 1.5;
        const x2 = 1000, y2 = 2;
        const m = (y2 - y1) / (x2 - x1);
        const b = y1 - m * x1;
        const clampedWidth = Math.max(x1, Math.min(x2, rootWidth));
        return m * clampedWidth + b;
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            className="track-header"
            size="content"
            ref={rootRef}
            justifyItems="center"
            alignContent="start">
            <ContentLayout
                gap="tiny"
                justifyItems="center">
                <Spacer height="normal"/>
                <Headline
                    text={trackName}
                    align="center"
                    style="h1"/>
                {trackLayout && <Headline
                    text={trackLayout}
                    align="center"
                    style="h2"/>}
                <Spacer height="large"/>
            </ContentLayout>
            <Picture
                file={trackImage}
                framing="rounded-8"
                defaultAspectRatio={computeAspectRatio()}
                forceDefaultAspectRatio={true}
                shadow="large"
                allowMediaViewer={true}/>
        </ContentLayout>
    );

}
