import * as React from "react";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {SelectInput} from "../../../../../ui/form/elements/select/SelectInput";
import {useServices} from "../../../../../hooks/useServices";
import {Input} from "../../../../../ui/form/elements/input/Input";
import {useSessionState} from "../../../../../hooks/useSessionState";
import {SessionPointsValditation} from "../../../../../../../shared/validation/SessionPointsValditation";
import {useRouteOwner} from "../../../../../hooks/useRouteOwner";
import {useGroupState} from "../../../../../hooks/useGroupState";
import {useMobileStyle} from "../../../../../hooks/useMobileStyle";
import {useRef} from "react";

/******************************************************************
 * SessionPointsSetup
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function SessionPointsSetup() {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const ref = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {dict, api} = useServices()
    const {isRouteOwnerAuthUser} = useRouteOwner()
    const {isGroupAdmin} = useGroupState()
    const [isMobileMode] = useMobileStyle(ref, 500)
    const {
        sessionID,
        sessionMode,
        sessionPoints,
        sessionFastestLapPoints,
        sessionIsFinished
    } = useSessionState()

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    async function updateSessionPoints(points: string): Promise<Response> {
        if (points == "custom") {
            points = sessionPoints.join("-");
        }
        return await api.session.update(sessionID, {
            setup: {
                points: points ? points.split("-").map(point => parseFloat(point)) : null
            }
        })
    }

    async function updateSessionFastestLapPoints(fastLapPoints: string): Promise<Response> {
        if (!fastLapPoints || fastLapPoints == "") {
            fastLapPoints = "0";
        }
        return await api.session.update(sessionID, {
            setup: {
                fastestLapPoints: parseFloat(fastLapPoints)
            }
        })
    }

    function isReadonly() {
        if (sessionIsFinished) return true;
        return !isRouteOwnerAuthUser && !isGroupAdmin;
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            ref={ref}
            columns={isMobileMode ? 1 : 2}
            className="session-points-setup">
            <Input
                type="text"
                label={dict("session.points")}
                readonly={isReadonly()}
                defaultValue={sessionPoints?.join("-")}
                helpTopic="session.points"
                placeholder={isRouteOwnerAuthUser ? dict("session.points.input.placeholder") : dict("input.not.set")}
                showInvalid={true}
                pattern={SessionPointsValditation.STRING_INPUT_REGEXP.source}
                action={updateSessionPoints}/>
            {(isRouteOwnerAuthUser || isGroupAdmin) && !sessionIsFinished &&
                <SelectInput
                    label={dict("session.points.templates")}
                    onChange={updateSessionPoints}
                    readonly={isReadonly()}
                    required={false}
                    defaultValue={sessionPoints?.join("-") ?? "custom"}
                    options={[{
                        value: "custom",
                        text: dict("session.points.template.custom")
                    }, {
                        value: dict("session.points.template.drift.dogs2023.points"),
                        text: dict("session.points.template.drift.dogs2023.label")
                    }, {
                        value: dict("session.points.template.formel1.60er.points"),
                        text: dict("session.points.template.formel1.60er.label")
                    }, {
                        value: dict("session.points.template.formel1.2003.points"),
                        text: dict("session.points.template.formel1.2003.label")
                    }, {
                        value: dict("session.points.template.formel1.2010.points"),
                        text: dict("session.points.template.formel1.2010.label")
                    }]}/>}
            {sessionMode == "race" &&
                <Input
                    type="number"
                    maxNumber={100}
                    readonly={isReadonly()}
                    minNumber={0}
                    label={dict("session.fastestLapPoints")}
                    defaultValue={sessionFastestLapPoints > 0 ? sessionFastestLapPoints.toString() : null}
                    showInvalid={true}
                    placeholder="0"
                    action={updateSessionFastestLapPoints}/>}
        </ContentLayout>
    );

}
