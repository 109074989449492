import {FrontendConfig} from "../core/FrontendConfig";

/******************************************************************
 * CSSUtils
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class CSSUtils {

    static getCSSVariableValueFromRoot(variableName: string): number {
        const rootElement = document.getElementsByTagName(FrontendConfig.ROOT_ELEMENT_NAME)[0];
        return parseInt(getComputedStyle(rootElement).getPropertyValue(variableName));
    }

    static setCSSVariableValueToRoot(variableName: string, value: string) {
        const rootElement = document.getElementsByTagName(FrontendConfig.ROOT_ELEMENT_NAME)[0] as HTMLElement
        rootElement.style.setProperty(variableName, value);
    }

    static getCSSValueFromElementByClass(className: string, propertyName: string): number {
        const element = document.getElementsByClassName(className)[0];
        return parseInt(getComputedStyle(element).getPropertyValue(propertyName));
    }

    static getTransformXValueFromElement(element: HTMLElement): number {
        const style = window.getComputedStyle(element);
        return parseInt(style.getPropertyValue("transform").split(",")[4]);
    }

}
