import * as React from "react";
import {useServices} from "../../../hooks/useServices";
import {useNotificationState} from "../../../hooks/useNotificationState";
import {LabelButton} from "../../buttons/label/LabelButton";
import {useState} from "react";

/******************************************************************
 * CapiSettingsDisplay
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function CapiSettingsDisplay(props: {
    capiPassword: string
    nick: string
    gameID?: string
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, state} = useServices();
    const {setNotification} = useNotificationState()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [origin] = useState<string>(window.location.origin)

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    async function copyPasswordAndNickToClipboard() {
        try {
            navigator.clipboard.writeText(props.capiPassword + "@" + props.nick)
                .then(async () => {
                    setNotification({type: "info", message: dict("capi.user.passwordAndNick.copiedToClipboard")})
                })
        } catch (e) {
            setNotification({type: "info", message: dict("notification.copyToClipboardFailed")})
        }
    }

    async function copyUrlToClipboard() {
        try {
            navigator.clipboard.writeText(origin)
                .then(async () => {
                    setNotification({type: "info", message: dict("capi.url.copiedToClipboard")})
                })
        } catch (e) {
            setNotification({type: "info", message: dict("notification.copyToClipboardFailed")})
        }
    }

    function showHelp() {
        state.showHelp.setValue("capi.gameid")
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="capi-settings-display">
            <img src={require("ASSETS/images/capi-screenshot.png")} alt="capi-screen"/>
            <LabelButton
                className="capi-settings-display-copy-password-and-nick-button"
                label={dict("capi.copyToClipboard.label")}
                style={"primary-small"}
                onClick={copyPasswordAndNickToClipboard}
            />
            <LabelButton
                className="capi-settings-display-copy-url-button"
                label={dict("capi.copyToClipboard.label")}
                style={"primary-small"}
                onClick={copyUrlToClipboard}
            />
            {!props.gameID && <LabelButton
                className="capi-settings-display-gameid-button"
                label={dict("capi.gameid.help")}
                style="primary-small"
                onClick={showHelp}/>}
            <svg viewBox="0 0 714 584">
                <text x="257" y="240" fontSize="25" fill="white">
                    {props.capiPassword}@{props.nick}
                </text>
                <text x="257" y="319" fontSize="28" fill="white">
                    {origin}
                </text>
                {props.gameID &&
                    <text
                        x="257"
                        y="398"
                        fontSize="28"
                        fill="white">
                        {props.gameID}
                    </text>}
            </svg>
        </div>
    );
}
