import * as React from "react";
import {ContextMenu} from "../ContextMenu";
import {useServices} from "../../../hooks/useServices";
import {useRouteOwner} from "../../../hooks/useRouteOwner";
import {ContextMenuLabel} from "../elements/ContextMenuLabel";
import {ContextMenuDivider} from "../elements/ContextMenuDivider";
import {useGroupState} from "../../../hooks/useGroupState";
import {IResultData} from "../../../../../shared/models/IResultData";

/******************************************************************
 * ResultContextMenu
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function ResultContextMenu(props: {
    data: IResultData
    onClicked: () => void
    onRequestEditName: () => void
    onToggle?: (showMenu: boolean) => void
}) {

    /* ----------------------------------------------------------------
     * SERVICES
     * --------------------------------------------------------------*/

    const {dict, state} = useServices();
    const {isRouteOwnerAuthUser} = useRouteOwner();
    const {isGroupAdmin} = useGroupState()

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function editName() {
        props.onClicked()
        props.onRequestEditName()
    }

    function deleteResult() {
        props.onClicked()
        state.showConfirm.setValue({
            type: "deleteResult",
            payload: {
                resultName: props.data.name,
                resultID: props.data._id
            }
        })
    }

    async function showModal() {
        props.onClicked()
        state.showResultSetup.setValue(props.data)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContextMenu
            type="icon"
            iconType="menuDots"
            iconScale={0.9}
            onToggle={props.onToggle}
            stopPropagation={true}
            enabled={isRouteOwnerAuthUser || isGroupAdmin}>
            <ContextMenuLabel
                label={dict("result.rename.label")}
                icon="title"
                onClick={editName}/>
            <ContextMenuDivider style="small"/>
            <ContextMenuLabel
                label={dict("result.settings.label")}
                icon="settings"
                iconScale={0.9}
                onClick={showModal}/>
            <ContextMenuDivider style="small"/>
            <ContextMenuLabel
                label={dict("result.delete.label")}
                icon="delete"
                warning={true}
                onClick={deleteResult}/>
        </ContextMenu>
    );

}
