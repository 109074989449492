import * as React from "react";
import {ITrackData} from "../../../../../shared/models/submodels/ITrackData";
import {Picture} from "../../image/Picture";

/******************************************************************
 * TrackSidebarButton
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function TrackSidebarButton(props: {
    data: ITrackData
    selected: boolean
    onClick: (trackData: ITrackData) => void
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <button
            className="track-sidebar-button"
            onClick={() => props.onClick(props.data)}
            data-selected={props.selected}>
            <Picture
                file={props.data.image}
                fit="cover"
                framing="rounded-4"
                allowMediaViewer={true}/>
            <div className="track-sidebar-button-labels">
                <h2>{props.data.name}</h2>
                {props.data.layout && <h3>{props.data.layout}</h3>}
            </div>
        </button>
    );

}
