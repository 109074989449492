import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {SessionButton} from "../../../../../ui/buttons/session/SessionButton";
import {DOMUtils} from "@webfruits/toolbox/dist/utils/DOMUtils";
import {ISessionData} from "../../../../../../../shared/models/ISessionData";
import {useEventState} from "../../../../../hooks/useEventState";
import {IFolderData} from "../../../../../../../shared/models/submodels/IFolderData";
import {DragDropable} from "../../../../../ui/draggable/DragDropable";
import {useServices} from "../../../../../hooks/useServices";
import {Folder} from "../../../../../ui/utils/folder/Folder";
import {FoldingUtils} from "../../../../../../utils/FoldingUtils";
import {IResultData} from "../../../../../../../shared/models/IResultData";
import {ResultButton} from "../../../../../ui/buttons/result/ResultButton";
import {useRouteOwner} from "../../../../../hooks/useRouteOwner";
import {useGroupState} from "../../../../../hooks/useGroupState";
import {MongoObjectIDType} from "../../../../../../../shared/types/MongoObjectIDType";

/******************************************************************
 * EventChildrenMenu
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

type EventChildType = {
    type: "folder" | "session" | "result",
    folded?: boolean,
    data: IFolderData | ISessionData | IResultData
}

export function EventChildrenMenu() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {api} = useServices();
    const {eventID, eventFolders, eventChildren, eventChildrenOrder} = useEventState();
    const {isRouteOwnerAuthUser} = useRouteOwner();
    const {isGroupAdmin} = useGroupState();

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const dragElementRef = useRef<HTMLDivElement>();
    const dropElementRef = useRef<HTMLDivElement>();

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [childrenList, setChildrenList] = useState<EventChildType[]>();
    const [isBusy, setIsBusy] = useState<boolean>();
    const [dragging, setDragging] = useState<boolean>(false)

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        let mergedChildrenList = createMergedChildrenList();
        let sortedChildrenList = createSortedChildrenList(mergedChildrenList);
        let foldedChildrenList = createFoldedChildrenList(sortedChildrenList);
        setChildrenList(foldedChildrenList);
    }, [eventChildren, eventFolders, eventChildrenOrder])

    /* ----------------------------------------------------------------
 	 * METHODEDS
 	 * --------------------------------------------------------------*/

    function createMergedChildrenList(): EventChildType[] {
        let mergedList: EventChildType[] = [];
        eventChildren?.sessions?.forEach(session => {
            mergedList.push({
                type: "session",
                data: session
            })
        })
        eventChildren?.results?.forEach(result => {
            mergedList.push({
                type: "result",
                data: result
            })
        })
        eventFolders?.forEach(folder => {
            mergedList.push({
                type: "folder",
                data: folder
            })
        })
        return mergedList;
    }

    function createSortedChildrenList(mergedList: EventChildType[]): EventChildType[] {
        let sortedList: EventChildType[] = [];
        eventChildrenOrder?.forEach(id => {
            const orderedChild = mergedList.filter(child => child.data._id == id)[0];
            if (orderedChild) {
                sortedList.push(orderedChild);
            }
            mergedList = mergedList.filter(child => child.data._id != id);
        })
        return sortedList.concat(mergedList);
    }

    function createFoldedChildrenList(childrenList: EventChildType[]): EventChildType[] {
        let folderIsOpen = true;
        return childrenList.map(child => {
            if (child.type == "folder") {
                folderIsOpen = FoldingUtils.isEventFolderOpen(child.data._id)
            }
            return {
                data: child.data,
                type: child.type,
                folded: !folderIsOpen
            };
        });
    }

    async function updateOrder() {
        if (!dropElementRef.current?.parentElement) return
        setIsBusy(true);
        const dragElementIndex = DOMUtils.getElementIndex(dragElementRef.current)
        const dropElementIndex = DOMUtils.getElementIndex(dropElementRef.current)
        const reorderdList = [...childrenList];
        const droppedElementData = reorderdList.splice(dragElementIndex, 1)[0] as EventChildType;
        reorderdList.splice(dropElementIndex, 0, droppedElementData);
        const newOrder = reorderdList.map(child => child.data._id);
        await api.event.updateOrder(eventID, newOrder);
        setIsBusy(false);
    }

    function updateFolding() {
        setChildrenList(childrenList => {
            return createFoldedChildrenList(childrenList);
        })
    }

    function hasFolderChildren(folderID: MongoObjectIDType) {
        const folderIndex = childrenList.findIndex(child => child.data._id == folderID);
        const nextChild = childrenList[folderIndex + 1];
        if (!nextChild) return false;
        return nextChild.type != "folder";
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="event-children-menu"
            data-disabled={isBusy}>
            {childrenList?.map((child: EventChildType) => {
                switch (child.type) {
                    case "session":
                        return <DragDropable
                            key={child.data._id}
                            disable={!isRouteOwnerAuthUser && !isGroupAdmin}
                            onDrop={updateOrder}
                            onDragStart={() => setDragging(true)}
                            onDragEnd={() => setDragging(false)}
                            dragElementRef={dragElementRef}
                            dropElementRef={dropElementRef}>
                            <SessionButton
                                session={child.data as ISessionData}
                                isDragging={dragging}
                                folded={child.folded}/>
                        </DragDropable>
                    case "result":
                        return <DragDropable
                            key={child.data._id}
                            disable={!isRouteOwnerAuthUser && !isGroupAdmin}
                            onDrop={updateOrder}
                            onDragStart={() => setDragging(true)}
                            onDragEnd={() => setDragging(false)}
                            dragElementRef={dragElementRef}
                            dropElementRef={dropElementRef}>
                            <ResultButton
                                data={child.data as IResultData}
                                isDragging={dragging}
                                folded={child.folded}/>
                        </DragDropable>
                    case "folder":
                        return <DragDropable
                            key={child.data._id}
                            disable={!isRouteOwnerAuthUser && !isGroupAdmin}
                            onDrop={updateOrder}
                            onDragStart={() => setDragging(true)}
                            onDragEnd={() => setDragging(false)}
                            dragElementRef={dragElementRef}
                            dropElementRef={dropElementRef}>
                            <Folder
                                data={child.data as IFolderData}
                                hideArrow={!hasFolderChildren(child.data._id)}
                                isDragging={dragging}
                                onChange={updateFolding}/>
                        </DragDropable>
                }
            })}
        </div>
    );

}
