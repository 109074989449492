import * as React from "react";
import {useEffect, useState} from "react";
import {Headline} from "../../../ui/text/headings/Headline";
import {useServices} from "../../../hooks/useServices";
import {LabelButton} from "../../../ui/buttons/label/LabelButton";
import {ContentLayout} from "../../../ui/layout/content/ContentLayout";
import {TabbedContent} from "../../../ui/layout/tabbed/TabbedContent";
import {GroupFinderList} from "./list/GroupFinderList";
import {GroupFinderMap} from "./map/GroupFinderMap";
import {IGroupData} from "../../../../../shared/models/IGroupData";
import {InfoText} from "../../../ui/text/infotext/InfoText";
import {useAuthUser} from "../../../hooks/useAuthUser";
import {Modal} from "../Modal";

/******************************************************************
 * GroupsFinderModal
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function GroupsFinderModal(props: {
    onMapTouchStart?: () => void
    onMapTouchEnd?: () => void
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, state, api} = useServices();
    const {authUserID, authUserExists} = useAuthUser()

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [localGroups, setLocalGroups] = useState<IGroupData[]>([]);
    const [onlineGroups, setOnlineGroups] = useState<IGroupData[]>([]);
    const [userGroups, setUserGroups] = useState<IGroupData[]>([])
    const [loading, setLoading] = useState<boolean>(true);

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        loadGroups();
    }, [])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function loadGroups() {
        setLoading(true)
        const publicGroups = await api.group.getPublicGroups()
        const userGroups = await api.group.getUserGroups(authUserID)
        setUserGroups(userGroups)
        setLocalGroups(publicGroups.filter(group => group.type === "local"))
        setOnlineGroups(publicGroups.filter(group => group.type === "online"))
        setLoading(false)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal
            width="large"
            className="groups-finder"
            closeAction={() => state.showGroupsFinder.setValue(false)}>
            <ContentLayout
                gap="none"
                fullHeight={true}
                rowTemplate={"min-content 1fr"}>
                <ContentLayout columnTemplate={"auto min-content"} justifyContent={"space-between"}>
                    <Headline text={dict("groups.finder.title")} style={"modal"}/>
                    <LabelButton
                        label={dict("groups.help.button.label")}
                        style={"secondary-small"}
                        onClick={() => state.showAcademy.setValue(dict("academy.id.groups"))}/>
                </ContentLayout>
                <ContentLayout
                    fullHeight={true}
                    rowTemplate={"min-content 1fr"}>
                    <InfoText text={dict("groups.finder.info")} size="small"/>
                    <TabbedContent
                        style="small"
                        contentHeight="stretch"
                        showContentByID={authUserExists ? 0 : 1}>
                        <GroupFinderList
                            tabbedContent={{
                                name: dict("groups.finder.list.user.label"),
                                disabled: !authUserExists
                            }}
                            isLoading={loading}
                            showPrivacyInfo={true}
                            groups={userGroups}/>
                        <GroupFinderList
                            tabbedContent={{name: dict("groups.finder.list.local.label")}}
                            isLoading={loading}
                            showMemberInfo={true}
                            groups={localGroups}/>
                        <GroupFinderList
                            tabbedContent={{name: dict("groups.finder.list.online.label")}}
                            isLoading={loading}
                            showMemberInfo={true}
                            groups={onlineGroups}/>
                        <GroupFinderMap
                            onTouchStart={props.onMapTouchStart}
                            onTouchEnd={props.onMapTouchEnd}
                            fullHeight={true}
                            projection="globe"
                            initialZoomLevel={4}
                            tabbedContent={{name: dict("groups.finder.map.label")}}
                            publicGroups={localGroups}/>
                    </TabbedContent>
                </ContentLayout>
            </ContentLayout>
        </Modal>
    );

}
