import * as React from "react";
import {useEffect, useRef} from "react";

/******************************************************************
 * IntersectingPixel
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function IntersectingPixel(props: {
    showRedPixel?: boolean
    leftOffset?: number
    onIntersectionChange: (isIntersecting: boolean) => void
}) {

    /* ----------------------------------------------------------------
     * REFS
     * --------------------------------------------------------------*/

    const ref = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        if (!ref.current) return null
        let observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                props.onIntersectionChange(entry.isIntersecting)
            });
        }, {
            threshold: [0, 1]
        });
        observer.observe(ref.current)
        return () => {
            if (!ref.current) return
            observer.disconnect();
        }
    }, [ref.current])

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="intersection-pixel"
            style={{
                position: "relative"
            }}>
            <div
                ref={ref}
                style={{
                    position: "absolute",
                    zIndex: 100,
                    top: 0,
                    left: props.leftOffset || 0,
                    width: 1,
                    height: 1,
                    backgroundColor: "red",
                    visibility: props.showRedPixel ? "visible" : "hidden"
                }}/>
        </div>
    );

}
