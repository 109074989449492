import * as React from "react";
import {Input} from "../../../../../ui/form/elements/input/Input";
import {useServices} from "../../../../../hooks/useServices";
import {useEventState} from "../../../../../hooks/useEventState";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {MapInput} from "../../../../../ui/map/input/MapInput";
import {ILocationData} from "../../../../../../../shared/types/ILocationData";
import {Headline} from "../../../../../ui/text/headings/Headline";
import {InfoText} from "../../../../../ui/text/infotext/InfoText";
import {SharedConfig} from "../../../../../../../shared/config/SharedConfig";
import {useState} from "react";
import {URLUtils} from "@webfruits/toolbox/dist/utils/URLUtils";

/******************************************************************
 * EventLocation
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function EventLocationSettings() {

    /* ----------------------------------------------------------------
       * HOOKS
       * --------------------------------------------------------------*/

    const {dict, api} = useServices();
    const {eventID, eventLocation, eventMapLocation, eventIsFinished} = useEventState();

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [location, setLocation] = useState<string>("")

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    async function updateLocation(value: string): Promise<Response> {
        return api.event.update(eventID, {location: value});
    }

    async function updateMapLocation(value: ILocationData): Promise<Response> {
        return await api.event.update(eventID, {mapLocation: value})
    }

    async function clearMapLocation(): Promise<Response> {
        return await api.event.update(eventID, {mapLocation: null})
    }

    function updateCharCounter(value: string) {
        setLocation(value)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            className="event-location-settings">
            <ContentLayout framed={true}>
                <Headline
                    text={dict("event.maplocation.title")}
                    style="h5-underlined"/>
                <InfoText
                    text={dict("event.maplocation.info")}
                    size="small"/>
                <MapInput
                    type="event"
                    location={eventMapLocation}
                    onSave={updateMapLocation}
                    onClear={clearMapLocation}/>
            </ContentLayout>
            <ContentLayout framed={true}>
                <Headline
                    text={dict("event.location.input.label")}
                    style="h5-underlined"/>
                <ContentLayout gap="none">
                    <InfoText
                        text={dict("event.location.input.info")}
                        size="small"/>
                    <Input
                        type="textarea"
                        readonly={eventIsFinished}
                        customLinkLabel={dict("markdown.support") + " | " + (SharedConfig.MAX_LOCATION_LENGTH - (location?.length ?? 0)).toString()}
                        onCustomLinkClick={() => URLUtils.openLink(dict("markdown.syntax.href"))}
                        maxTextareaLength={SharedConfig.MAX_LOCATION_LENGTH}
                        placeholder={dict("event.location.input.placeholder")}
                        defaultValue={eventLocation}
                        onChange={updateCharCounter}
                        action={updateLocation}/>
                </ContentLayout>
            </ContentLayout>
        </ContentLayout>
    );

}
