import * as React from "react";
import {IStintLapData} from "../../../../../../../../shared/types/IStintLapData";
import {Modal} from "../../../../Modal";
import {Headline} from "../../../../../../ui/text/headings/Headline";
import {useServices} from "../../../../../../hooks/useServices";
import {LapTarget} from "./target/LapTarget";
import {TimeUtils} from "../../../../../../../../shared/utils/TimeUtils";
import {ICapiTargetData} from "../../../../../../../../shared/types/ICapiTargetData";
import {IStintData} from "../../../../../../../../shared/models/IStintData";
import {StintUtils} from "../../../../../../../../shared/utils/StintUtils";
import {InfoText} from "../../../../../../ui/text/infotext/InfoText";
import {ContentLayout} from "../../../../../../ui/layout/content/ContentLayout";

/******************************************************************
 * LapModal
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function LapModal(props: {
    lap: IStintLapData
    lapNumber: number
    stint: IStintData
    onClose?: () => void
}) {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {dict} = useServices()

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function title() {
        const isInvalid = !StintUtils.isLapValid(props.lap)
        const isNotComputable = StintUtils.isLapTimeNotComputable(props.lap)
        const time = TimeUtils.formatDrivenTime(props.lap.time)
        return dict("stint.lap.detail.title")
            .replace("{LAP}", props.lapNumber?.toString())
            .replace("{TIME}", isInvalid
                ? (isNotComputable ? "n/a" : "(" + time + ")")
                : TimeUtils.formatDrivenTime(props.lap.time))
    }

    function prevLapsLastFinishTarget(): ICapiTargetData {
        const prevLaps: IStintLapData[] = props.stint?.computedLaps?.filter(computedLap => {
            const computedLapIndex = props.stint.computedLaps.indexOf(computedLap)
            const thisLapIndex = props.lapNumber - 1
            return computedLapIndex < thisLapIndex
                && !computedLap.isMissingFinishTarget
        })
        const finishTargets: ICapiTargetData[] = prevLaps?.map(lap => lap.targetData?.find(target => target.target_code === 0))
        const lastFinishTarget = finishTargets?.[finishTargets.length - 1]
        return lastFinishTarget ?? {
            crossing_time: props.stint.startData.signal_time
        }
    }

    function prevLapTarget(target: ICapiTargetData) {
        const targetIndex = props.lap.targetData.indexOf(target)
        if (targetIndex === 0) {
            return prevLapsLastFinishTarget()
        }
        return props.lap?.targetData?.[targetIndex - 1]
    }

    function showWarning(): boolean {
        return !StintUtils.isLapValid(props.lap)
    }

    function warningText(): string {
        if (StintUtils.isLapTimeNotComputable(props.lap)) {
            return dict("stint.lap.time.not.computeable")
        }
        return dict("stint.lap.time.invalid")
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal
            width="medium"
            className="lap-modal"
            closeAction={props.onClose}>
            <Headline text={title()} style="modal"/>
            <ContentLayout>
                {showWarning() && <InfoText
                    text={warningText()}
                    style="info"/>}
                <div>
                    <LapTarget
                        target={prevLapsLastFinishTarget()}
                        stint={props.stint}
                        lap={props.lap}/>
                    <>{props.lap?.targetData?.map(target => {
                        return <LapTarget
                            key={target.crossing_time}
                            lap={props.lap}
                            stint={props.stint}
                            startTarget={prevLapsLastFinishTarget()}
                            prevTarget={prevLapTarget(target)}
                            target={target}/>
                    })}</>
                </div>
            </ContentLayout>
        </Modal>
    );

}
