import * as React from "react";
import {StateInfo} from "../../info/state/StateInfo";
import {EventStateType} from "../../../../../shared/types/EventStateType";
import {SessionStateType} from "../../../../../shared/types/SessionStateType";
import {CheckIcon} from "../../icons/CheckIcon";
import {KeyShortcutInfo} from "../../keyboard/KeyShortcutInfo";
import {IKeyShortcut} from "../../../../utils/interfaces/IKeyShortcut";

/******************************************************************
 * ContextMenuState
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function ContextMenuState(props: {
    state: EventStateType | SessionStateType
    selected: boolean
    compact?: boolean
    keyShortcut?: IKeyShortcut
    onClick: () => void
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="context-menu-state"
            onClick={props.onClick}
            data-has-shortcut={props.keyShortcut ? "true" : "false"}
            data-selected={props.selected ? "true" : "false"}>
            <CheckIcon />
            <StateInfo
                state={props.state}
                compact={props.compact} />
            {props.keyShortcut &&
                <KeyShortcutInfo
                    keyShortcut={props.keyShortcut}/>}
        </div>
    );

}
