import {IEventData} from "../../shared/models/IEventData";
import {EventOwnerType} from "../../shared/types/EventOwnerType";
import {TrackOwnerType} from "../../shared/types/TrackOwnerType";
import {CapiRoute} from "../../shared/routes/CapiRoute";
import {ISessionData} from "../../shared/models/ISessionData";

/******************************************************************
 * GameIDUtils
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class GameIDUtils {

    static gameID(eventData: IEventData, sessionData: ISessionData): string {
        if (!eventData || !sessionData) return ""
        return GameIDUtils.baseGameID(eventData) + "/" + GameIDUtils.specificGameID(sessionData)
    }

    static baseGameID(eventData: IEventData): string {
        if (!eventData) return ""
        return ""
            + GameIDUtils.capiOwnerPath(eventData.ownerType).replace("/", "")
            + eventData.owner.path
            + eventData.path
    }

    static specificGameID(sessionData: ISessionData): string {
        if (!sessionData) return ""
        return sessionData.path.replace("/", "")
    }

    static capiOwnerPath(ownerType: EventOwnerType | TrackOwnerType): string {
        if (!ownerType) return ""
        switch (ownerType) {
            case "user":
                return CapiRoute.USER
            case "group":
                return CapiRoute.GROUP
        }
    }

}
