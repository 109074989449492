import * as React from "react";
import {ReactElement, useEffect, useRef} from "react";
import {ContentLayout} from "../layout/content/ContentLayout";
import {Headline} from "../text/headings/Headline";
import {LabelButton} from "../buttons/label/LabelButton";
import {useMobileStyle} from "../../hooks/useMobileStyle";
import {useRouteOwner} from "../../hooks/useRouteOwner";
import {EmptyDataInfo} from "../info/empty/EmptyDataInfo";
import {useGroupState} from "../../hooks/useGroupState";
import {InfoText} from "../text/infotext/InfoText";
import {useServices} from "../../hooks/useServices";
import {IconButton} from "../buttons/icon/IconButton";
import {ComponentInitializer} from "../utils/init/ComponentInitializer";

/******************************************************************
 * Infobox
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function Infobox(props: {
    title: string
    isPreview?: boolean
    className?: string
    hasData: boolean
    noDataInfo: string
    children: ReactElement | ReactElement[] | null
    loading?: boolean
    createButtonLabel: string
    createButtonOnClick: () => void
    linkButtonLabel: string
    linkButtonOnClick: () => void
    helpButtonLabel: string
    helpButtonOnClick: () => void
    higherDriverLicenseRequiredOwnerInfo?: string
    minHeight?: number
    onRefElement?: (element: HTMLDivElement) => void
}) {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const rootRef = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const [isMobileMode] = useMobileStyle(rootRef, 600)
    const {dict, state} = useServices()

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const {isRouteOwnerAuthUser} = useRouteOwner()
    const {isGroupAdmin, isGroupOwner} = useGroupState()

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        props.onRefElement?.(rootRef.current)
    }, [rootRef])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function allowCreateButton(): boolean {
        if (props.isPreview) return false
        return isRouteOwnerAuthUser || (isGroupAdmin || isGroupOwner)
    }

    function secondaryButton(): ReactElement {
        return <ContentLayout
            alignSelf="center"
            justifyItems="center">
            {props.hasData
                ? <LabelButton
                    label={props.linkButtonLabel}
                    style="secondary-small"
                    onClick={props.linkButtonOnClick}/>
                : <LabelButton
                    label={props.helpButtonLabel}
                    style="secondary-small"
                    onClick={props.helpButtonOnClick}/>}
        </ContentLayout>

    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            ref={rootRef}
            framed={true}
            disabled={props.isPreview}
            minHeight={200}
            className={props.className ?? "infobox"}>
            <ContentLayout
                columnTemplate={"auto min-content"}
                gap="small"
                justifyContent="space-between">
                <Headline
                    text={props.title}
                    style="h5-underlined"/>
                <ContentLayout
                    columns={isMobileMode ? 1 : (allowCreateButton() ? 2 : 1)}
                    gap="small">
                    {!props.isPreview && !props.loading && <>
                        {!isMobileMode &&
                            secondaryButton()}
                        {allowCreateButton() &&
                            <IconButton
                                type="add"
                                size="small"
                                onClick={props.createButtonOnClick}/>}
                    </>}
                </ContentLayout>
            </ContentLayout>
            <ComponentInitializer isPropertyAvailable={!props.loading}>
                <ContentLayout>
                    {props.hasData &&
                        <>{props.children}</>}
                    {!props.loading && !props.hasData &&
                        <EmptyDataInfo text={props.noDataInfo} size="small"/>}
                    {props.higherDriverLicenseRequiredOwnerInfo &&
                        <ContentLayout
                            framed={true}
                            justifyItems="center">
                            <InfoText
                                text={props.higherDriverLicenseRequiredOwnerInfo}
                                align="center"
                                size="small"/>
                            <LabelButton
                                label={dict("plan.license.upgrade.label")}
                                style="primary-small"
                                onClick={() => state.showSubscriptionPlans.setValue(true)}/>
                        </ContentLayout>}
                    {!props.isPreview && (allowCreateButton() || isMobileMode) && !props.loading &&
                        <ContentLayout justifyItems="center">
                            {secondaryButton()}
                        </ContentLayout>}
                </ContentLayout>
            </ComponentInitializer>
        </ContentLayout>
    );

}
