import * as React from "react";
import {Modal} from "../Modal";
import {useServices} from "../../../hooks/useServices";
import {SplitView} from "../../../ui/splitview/SplitView";
import {SplitViewContent} from "../../../ui/splitview/content/SplitViewContent";
import {GroupGeneralSettings} from "./settings/general/GroupGeneralSettings";
import {GroupDeleteSettings} from "./settings/delete/GroupDeleteSettings";
import {useState} from "react";
import {GroupAboutSettings} from "./settings/about/GroupAboutSettings";
import {GroupContactSettings} from "./settings/contact/GroupContactSettings";
import {useGroupState} from "../../../hooks/useGroupState";

/******************************************************************
 * GroupSettingsModal
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function GroupSettingsModal() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, state} = useServices()
    const {isGroupOwner} = useGroupState()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [splitViewMobileMode, setSplitViewMobileMode] = useState<boolean>(false)

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal
            width="extra-large"
            height={splitViewMobileMode ? "auto" : "medium"}
            className="group-settings-modal"
            padding="none"
            closeAction={() => state.showGroupSettings.setValue(false)}>
            <SplitView
                padding="modal"
                title={dict("group.settings.title")}
                onMobileMode={setSplitViewMobileMode}>
                <SplitViewContent
                    label={dict("group.settings.general")}
                    content={<GroupGeneralSettings/>}/>
                <SplitViewContent
                    label={dict("group.settings.about")}
                    content={<GroupAboutSettings/>}/>
                <SplitViewContent
                    label={dict("group.settings.contact")}
                    content={<GroupContactSettings/>}/>
                {isGroupOwner &&
                    <SplitViewContent
                        label={dict("group.settings.delete")}
                        content={<GroupDeleteSettings/>}/>}
            </SplitView>
        </Modal>
    );

}
