import * as React from "react";
import {IStintData} from "../../../../../../shared/models/IStintData";
import {useSessionState} from "../../../../hooks/useSessionState";
import {CarImage} from "../../../image/car/CarImage";
import {useState} from "react";
import {IntersectingPixel} from "../../../utils/observer/IntersectingPixel";

/******************************************************************
 * TableStintCarCell
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function TableStintCarCell(props: {
    stint: IStintData
}) {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {sessionClassificationMode} = useSessionState();

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [isSticky, setIsSticky] = useState<boolean>(false)
    const [hasCarImage, setHasCarImage] = useState<boolean>(false)

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="table-stint-car-cell"
            data-is-team-session={sessionClassificationMode == "teams"}
            data-is-sticky={isSticky}
            data-has-car-image={hasCarImage}>
            <IntersectingPixel
                showRedPixel={false}
                leftOffset={-40}
                onIntersectionChange={isIntersecting => setIsSticky(!isIntersecting)}/>
            <CarImage
                className="table-stint-car-cell-image"
                carName={props.stint.enterData.car_name}
                userID={props.stint.user._id}
                onCarImageAvailable={() => setHasCarImage(true)}/>
            <div
                className="table-stint-car-cell-name">
                {props.stint.enterData.car_name}
            </div>
        </div>
    );

}
