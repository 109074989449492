import {SessionSetupSectionType} from "../../../../../shared/types/SessionSetupSectionType";
import {ISessionData} from "../../../../../shared/models/ISessionData";

/******************************************************************
 * SessionSetupCompatibility
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class SessionSetupCompatibility {

    static isSectionAvailable(
        setupType: SessionSetupSectionType,
        session: ISessionData
    ): boolean {
        const notAvailableSections: SessionSetupSectionType[] = [];
        if (!session) return false
        switch (session.setup.classificationMode) {
            case "solo":
                notAvailableSections.push("timing")
                notAvailableSections.push("points")
                notAvailableSections.push("startOrder")
                notAvailableSections.push("rights")
                break
        }
        switch (session.setup.mode) {
            case "lap":
                notAvailableSections.push("falseStart")
                notAvailableSections.push("startOrder")
                break
            case "race":
                switch (session.setup.timing) {
                    case "sync":
                        break;
                    case "async":
                        notAvailableSections.push("startOrder")
                        break;
                }
                break
            case "gymkhana":
                notAvailableSections.push("falseStart")
                notAvailableSections.push("startOrder")
                break
        }
        return notAvailableSections.filter(section => setupType == section).length == 0
    }

}
