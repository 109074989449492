import * as React from "react";

/******************************************************************
 * SidebarIcon
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function SidebarIcon(props: {
    scale?: number
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="sidebar-icon">
            <svg width={16 * (props.scale ?? 1)} height={17 * (props.scale ?? 1)} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_1439_6802)">
                    <rect x="1" y="2.39941" width="14" height="14" rx="1.5" stroke="white"/>
                    <rect x="2.5" y="3.89941" width="3" height="11" rx="1" fill="white"/>
                </g>
                <defs>
                    <clipPath id="clip0_1439_6802">
                        <rect width="15" height="16" fill="white" transform="translate(0.5 0.949707)"/>
                    </clipPath>
                </defs>
            </svg>
        </div>
    );

}
