import * as React from "react";
import {ReactElement, useState} from "react";
import {ICapiTargetData} from "../../../../../../../../../shared/types/ICapiTargetData";
import {useServices} from "../../../../../../../hooks/useServices";
import {useRouteOwner} from "../../../../../../../hooks/useRouteOwner";
import {useGroupState} from "../../../../../../../hooks/useGroupState";
import {TimeUtils} from "../../../../../../../../../shared/utils/TimeUtils";
import {IStintLapData} from "../../../../../../../../../shared/types/IStintLapData";
import {Icon} from "../../../../../../../ui/icons/Icon";
import {DriftTargetCodeType} from "../../../../../../../../../shared/types/DriftTargetCodeType";
import {useAuthUser} from "../../../../../../../hooks/useAuthUser";
import {TargetContextMenu} from "../../../../../../../ui/context/target/TargetContextMenu";
import {IStintData} from "../../../../../../../../../shared/models/IStintData";
import {useSessionState} from "../../../../../../../hooks/useSessionState";

/******************************************************************
 * LapTarget
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function LapTarget(props: {
    lap: IStintLapData
    target: ICapiTargetData
    stint: IStintData
    startTarget?: ICapiTargetData
    prevTarget?: ICapiTargetData,
}) {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {dict} = useServices()
    const {authUserID} = useAuthUser()
    const {isRouteOwnerAuthUser} = useRouteOwner()
    const {isGroupAdmin} = useGroupState()
    const {
        sessionNumSectors,
        sessionSectorTargets,
        sessionJokerLapTarget,
        sessionEditTargetsForbidden,
        sessionIsFinished
    } = useSessionState()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [isBusy, setIsBusy] = useState<boolean>(false)

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function targetCode() {
        return props.target._overwrittenTargetCode ?? props.target.target_code
    }

    function hasPrevTarget() {
        return props.prevTarget !== undefined
    }

    function originalTargetName() {
        if (props.target.target_code == undefined) {
            return dict("lap.target.startSignal")
        }
        return dict("drift.targetCode_" + props.target.target_code)
    }

    function overwrittenTargetName() {
        if (props.target._overwrittenTargetCode == undefined) {
            return null
        }
        return dict("drift.targetCode_" + props.target._overwrittenTargetCode)
    }

    function timeGapToPrevTarget(): string {
        if (!hasPrevTarget()) {
            return null
        }
        const timeGap = TimeUtils.calcTimeInSeconds(props.target.crossing_time, props.prevTarget.crossing_time) + "s"
        if (targetIndex() === 0) {
            return timeGap
        }
        return "+" + timeGap

    }

    function timeToStartTarget(): string {
        if (!props.startTarget || !hasPrevTarget() || targetIndex() === 0) {
            return null
        }
        return " = " + TimeUtils.calcTimeInSeconds(props.target.crossing_time, props.startTarget.crossing_time) + "s"
    }

    function targetInfo() {
        if (props.target._disabled) {
            return dict("lap.target.disabled")
        }
        if (props.prevTarget) {
            return null
        }
        return TimeUtils.formatDate(props.target.crossing_time, true)
    }

    function targetWarning() {
        if (!hasPrevTarget()) {
            return null
        }
        if (isFinishTarget()) {
            if (props.lap.wasBelowMinLapTime
                && !isLastTargetOfLap()) {
                return dict("stint.lap.warning.wasBelowMinLapTime")
            }
        } else {
            if (props.lap.hasUnwantedTargets) {
                if (isTargetOutOfNumSectorTargets()) {
                    return dict("stint.lap.warning.toManyTargets")
                }
                if (!isTargetAllowed()) {
                    return dict("stint.lap.warning.targetNotAllowed")
                }
            }
        }
        return null
    }

    function isLastTargetOfLap(): boolean {
        return targetIndex() === props.lap.targetData?.length - 1
    }

    function isFinishTarget(): boolean {
        return targetCode() === 0
    }

    function targetIndex(): number {
        return props.lap.targetData?.indexOf(props.target)
    }

    function isTargetOutOfNumSectorTargets() {
        return targetIndex() >= sessionNumSectors - 1;
    }

    function isTargetAllowed(): boolean {
        return sessionSectorTargets.some((sectorTarget: DriftTargetCodeType) => {
            return sectorTarget === "targetCode_" + targetCode()
        })
    }

    function showMissingFinishTarget(): boolean {
        return isLastTargetOfLap()
            && !isFinishTarget()
            && props.lap.isMissingFinishTarget
    }

    function showMissingSectorTargets(): boolean {
        return isLastTargetOfLap()
            && props.lap.isMissingSectorTargets
            && numMissingSectorTargets() > 0
    }

    function numMissingSectorTargets(): number {
        const nonFinishTargets = props.lap.targetData.filter(target => target.target_code !== 0)
        return (sessionNumSectors - 1) - nonFinishTargets.length
    }

    function missingSectorTargetElements(): ReactElement[] {
        const elements: ReactElement[] = []
        for (let i = 0; i < numMissingSectorTargets(); i++) {
            elements.push(<div className="lap-target-missing-sector-target" key={i}>
                <div className="lap-target-missing-sector-target-frame">
                    {dict("stint.lap.warning.missingSectorTarget")}
                </div>
            </div>)
        }
        return elements
    }

    function canEdit(): boolean {
        if (sessionIsFinished) {
            return false
        }
        if (isRouteOwnerAuthUser || isGroupAdmin) {
            return true
        }
        return props.stint.user._id === authUserID && !sessionEditTargetsForbidden
    }

    function isJokerTarget(): boolean {
        return props.target?.target_code === parseInt(sessionJokerLapTarget?.split("_")?.[1] ?? undefined)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="lap-target">
            {hasPrevTarget() && <div className="lap-target-time">
                {timeGapToPrevTarget()} {timeToStartTarget()}
            </div>}
            {showMissingSectorTargets() && <div className="lap-target-missing-sector-targets">
                {missingSectorTargetElements()}
            </div>}
            <div className="lap-target-primary"
                 data-is-prev-lap-last-target={!hasPrevTarget()}
                 data-is-disabled={props.target._disabled}
                 data-is-busy={isBusy}
                 data-has-warning={!!targetWarning()}>
                <div className="lap-target-name">
                    <div className="lap-target-name-original"
                         data-is-overwritten={!!overwrittenTargetName()}>
                        {originalTargetName()}
                    </div>
                    {overwrittenTargetName() && <div className="lap-target-name-overwritten">
                        <Icon type="next" scale={0.8}/>
                        {overwrittenTargetName()}
                    </div>}
                </div>
                <div className="lap-target-meta">
                    {(targetWarning() || targetInfo()) && <div className="lap-target-meta-info">
                        {targetInfo() ?? targetWarning()}
                    </div>}
                    {isJokerTarget() &&
                        <Icon type="joker"/>}
                    {canEdit() && hasPrevTarget() &&
                        <TargetContextMenu
                            target={props.target}
                            stint={props.stint}
                            onProgressingChanged={setIsBusy}/>}
                </div>
            </div>
            {showMissingFinishTarget() && <div className="lap-target-missing-finish-target">
                {dict("stint.lap.warning.missingFinishTarget")}
            </div>}
        </div>
    );

}
