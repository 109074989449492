import * as React from "react";

/******************************************************************
 * OnlineIcon
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function OnlineIcon(props: {
    scale?: number
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="online-icon">
            <svg width={20 * (props.scale ?? 1)} height={21 * (props.scale ?? 1)} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="8" y="8.94971" width="4" height="4" rx="2" fill="white"/>
                <path d="M6.07776 7.83105C5.28479 8.63176 4.79507 9.73332 4.79507 10.9493C4.79507 12.1652 5.28479 13.2668 6.07776 14.0675" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M13.8964 14.0674C14.6894 13.2667 15.1791 12.1651 15.1791 10.9492C15.1791 9.73323 14.6894 8.63167 13.8964 7.83096" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M3.71093 4.52539C2.07733 6.17495 1.06845 8.4443 1.06845 10.9493C1.06845 13.4543 2.07733 15.7236 3.71093 17.3732" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M16.2632 17.373C17.8968 15.7235 18.9057 13.4541 18.9057 10.9491C18.9057 8.44415 17.8968 6.17479 16.2632 4.52524" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </div>
    );

}
