import * as React from "react";

/******************************************************************
 * WatchIcon
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function WatchIcon(props: {
    scale?: number
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="watch-icon">
            <svg width={14 * (props.scale ?? 1)} height={16 * (props.scale ?? 1)} viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.0934 9.35596C13.0934 12.7222 10.3658 15.4497 6.99963 15.4497C3.63342 15.4497 0.905884 12.7222 0.905884 9.35596C0.905884 6.30908 3.14124 3.78369 6.06213 3.33545V2.32471H5.24182C5.04846 2.32471 4.89026 2.1665 4.89026 1.97314V0.80127C4.89026 0.60791 5.04846 0.449707 5.24182 0.449707H8.75745C8.95081 0.449707 9.10901 0.60791 9.10901 0.80127V1.97314C9.10901 2.1665 8.95081 2.32471 8.75745 2.32471H7.93713V3.33545C9.03577 3.50537 10.0377 3.96826 10.858 4.64209L11.6637 3.83643C11.8014 3.69873 12.024 3.69873 12.1617 3.83643L12.9908 4.66553C13.1285 4.80322 13.1285 5.02588 12.9908 5.16357L12.1295 6.0249L12.1119 6.04248C12.733 6.9917 13.0934 8.13135 13.0934 9.35596ZM7.93713 10.4106V5.97217C7.93713 5.77881 7.77893 5.62061 7.58557 5.62061H6.4137C6.22034 5.62061 6.06213 5.77881 6.06213 5.97217V10.4106C6.06213 10.604 6.22034 10.7622 6.4137 10.7622H7.58557C7.77893 10.7622 7.93713 10.604 7.93713 10.4106Z" fill="white"/>
            </svg>
        </div>
    );

}
