import * as React from "react";
import {ContentLayout} from "../../../ui/layout/content/ContentLayout";
import {Headline} from "../../../ui/text/headings/Headline";
import {useServices} from "../../../hooks/useServices";
import {InfoText} from "../../../ui/text/infotext/InfoText";
import {HomeActionBar} from "../../../ui/bar/home/HomeActionBar";
import {DriverLicensePlans} from "../../../ui/plans/DriverLicensePlans";
import {Spacer} from "../../../ui/utils/spacer/Spacer";
import {FrontendConfig} from "../../../../core/FrontendConfig";

/******************************************************************
 * PlansMain
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function PlansMain() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict} = useServices();

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return <>
        <ContentLayout
            className="plans-main"
            fullHeight={true}
            rowTemplate="min-content auto">
            <Headline text={dict("plans.title")} style="h1"/>
            <ContentLayout
                fullHeight={true}
                gap="medium-to-large"
                rowTemplate="min-content auto">
                <ContentLayout>
                    <InfoText
                        text={dict("plans.info")}
                        style="abstract"/>
                    {!FrontendConfig.PADDLE_ENABLED_PER_DEFAULT &&
                        <InfoText
                            text={dict("beta.disclaimer.text")}
                            style="warning"
                            size="small"/>}
                </ContentLayout>
                {FrontendConfig.PADDLE_ENABLED_PER_DEFAULT &&
                    <ContentLayout
                        gap="none"
                        fullHeight={true}
                        rowTemplate="auto min-content">
                        <DriverLicensePlans showSignupButton={true}/>
                        <Spacer height="footer-scroll"/>
                    </ContentLayout>}
            </ContentLayout>
        </ContentLayout>
        <HomeActionBar/>
    </>

}
