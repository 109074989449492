import * as React from "react";
import {useServices} from "../../../../hooks/useServices";
import {ContentLayout} from "../../../layout/content/ContentLayout";
import {SelectInput, SelectInputOptionType} from "../select/SelectInput";
import {MailServiceType, MailServiceTypes} from "../../../../../../shared/types/MailServiceType";
import {useEffect, useState} from "react";

/******************************************************************
 * MailEngineSelector
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function MailServiceSelector() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, api} = useServices()

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [isUpdating, setIsUpdating] = useState<boolean>(false)
    const [mailService, setMailService] = useState<MailServiceType>("plesk")

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        requestSetting()
    }, [])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function requestSetting() {
        api.settings.getValue("mail.service.engine").then((value: string) => {
            setMailService(value as MailServiceType)
        })
    }

    function serviceOptions(): SelectInputOptionType[] {
        return MailServiceTypes.map((service: string) => {
            return {value: service, text: dict("mail.service." + service)}
        })
    }

    async function updateMailService(serviceType: MailServiceType) {
        setIsUpdating(true)
        await api.settings.setValue("mail.service.engine", serviceType)
        requestSetting()
        setIsUpdating(false)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout gap="small">
            <SelectInput
                options={serviceOptions()}
                size="small"
                defaultValue={mailService}
                label={dict("mail.service.label")}
                processing={isUpdating}
                onChange={updateMailService}/>
        </ContentLayout>
    );

}
