import * as React from "react";

/******************************************************************
 * SpeedDriftTargetIcon
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function SpeedDriftTargetIcon(props: {
    scale?: number
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="speed-drift-target-icon">
            <svg width={22 * (props.scale ?? 1)} height={19 * (props.scale ?? 1)} viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0_1279_6816" style={{maskType: "alpha"}} maskUnits="userSpaceOnUse" x="0" y="6" width="22" height="7">
                    <path d="M0 8.94971H22V10.9497H0V8.94971Z" fill="white"/>
                    <path d="M0 11.9497H22V12.9497H0V11.9497Z" fill="white"/>
                    <path d="M0 6.94971H22V7.94971H0V6.94971Z" fill="white"/>
                </mask>
                <g mask="url(#mask0_1279_6816)">
                    <rect x="13.2664" y="5.50635" width="8.73358" height="8.67676" fill="white"/>
                    <rect y="5.50635" width="8.59436" height="8.67676" fill="white"/>
                </g>
                <path fillRule="evenodd" clipRule="evenodd" d="M11.9122 3.44971L11.9122 16.4497L10 16.4497L10 3.44971L11.9122 3.44971Z" fill="white"/>
            </svg>
        </div>
    );

}
