import * as React from "react";
import {useServices} from "../../../../../hooks/useServices";
import {useEventState} from "../../../../../hooks/useEventState";
import {LabelButton} from "../../../../../ui/buttons/label/LabelButton";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {Spacer} from "../../../../../ui/utils/spacer/Spacer";
import {InfoText} from "../../../../../ui/text/infotext/InfoText";

/******************************************************************
 * EventDeleteSettings
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function EventDeleteSettings(props: {
    isTraining: boolean
}) {

    /* ----------------------------------------------------------------
       * HOOKS
       * --------------------------------------------------------------*/

    const {dict, state} = useServices();
    const {eventID, eventName} = useEventState();

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function deleteEvent() {
        state.showConfirm.setValue({
            type: "deleteEvent",
            payload: {
                eventName: eventName,
                eventID: eventID
            }
        })
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            className="event-delete-settings"
            alignSelf="start"
            justifyItems="center">
            <Spacer height="large"/>
            <InfoText
                text={dict("event.delete.info")}
                align="center"/>
            <LabelButton
                label={dict(props.isTraining
                    ? "training.delete.button.label"
                    : "event.delete.button.label")}
                onClick={deleteEvent}
                style="primary" />
        </ContentLayout>
    );

}
