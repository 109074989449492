import * as React from "react"
import {useEffect, useState} from "react"
import {useTrackState} from "../../../../hooks/useTrackState";
import {TrackFilterButton} from "../../../../ui/buttons/track/TrackFilterButton";
import {EmptyDataInfo} from "../../../../ui/info/empty/EmptyDataInfo";
import {ActionBar} from "../../../../ui/bar/ActionBar";
import {IconButton} from "../../../../ui/buttons/icon/IconButton";
import {useServices} from "../../../../hooks/useServices";
import {useRouteOwner} from "../../../../hooks/useRouteOwner";
import {useGroupState} from "../../../../hooks/useGroupState";
import {LabelButton} from "../../../../ui/buttons/label/LabelButton";
import {ContentLayout} from "../../../../ui/layout/content/ContentLayout";
import {TrackFilterPresets} from "../../../../../../shared/config/TrackFilterPresets";
import {useRouteStates} from "../../../../hooks/useRouteStates";
import {FrontendRoute} from "../../../../../../shared/routes/FrontendRoute";
import {LabelText} from "../../../../ui/text/label/LabelText";
import {ITrackFilterData} from "../../../../../../shared/models/submodels/ITrackFilterData";

/******************************************************************
 * TrackSidebarMenu
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function TrackSidebarMenu() {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {dict, router, state} = useServices()
    const {trackPath, trackFilters, trackOwnerType, trackOwner} = useTrackState()
    const {isRouteOwnerAuthUser} = useRouteOwner()
    const {isGroupAdmin} = useGroupState()
    const {showTrackHome} = useRouteStates()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [customFilters, setCustomFilters] = useState(trackFilters?.filter(filter => !filter.isPreset))

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        setCustomFilters(trackFilters?.filter(filter => !filter.isPreset))
    }, [trackFilters])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function gotoTrackHomePage() {
        router.showRoute(FrontendRoute.TRACK(trackOwnerType, trackOwner.path, trackPath))
    }

    function showEmtpyInfo() {
        return (isRouteOwnerAuthUser || isGroupAdmin) && customFilters?.length == 0
    }

    function showTrackFilter() {
        state.showTrackFilter.setValue(true)
    }

    function defaultFilter(): ITrackFilterData {
        return TrackFilterPresets.PRESETS.find(preset => preset.presetType === "default")
    }

    function enginePresetFilters(): ITrackFilterData[] {
        return TrackFilterPresets.PRESETS.filter(preset => preset.presetType === "engine")
    }

    function tuningPresetFilters(): ITrackFilterData[] {
        return TrackFilterPresets.PRESETS.filter(preset => preset.presetType === "tuning")
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            className="track-sidebar-menu"
            rowTemplate="min-content auto">
            <ContentLayout gap="none">
                <LabelButton
                    label={dict("track.overview.menu.title")}
                    selected={showTrackHome}
                    style="sidebar"
                    onClick={gotoTrackHomePage}/>
                {TrackFilterPresets.PRESETS.length > 0 &&
                    <ContentLayout gap="none">
                        <TrackFilterButton data={defaultFilter()}/>
                        <div>
                            <LabelText
                                label={dict("track.filter.preset.tunings.label")}
                                style="sidebar"/>
                            {tuningPresetFilters().map(filter => {
                                return <TrackFilterButton key={filter.name} data={filter}/>
                            })}
                        </div>
                        <div>
                            <LabelText
                                label={dict("track.filter.preset.engines.label")}
                                style="sidebar"/>
                            {enginePresetFilters().map(filter => {
                                return <TrackFilterButton key={filter.name} data={filter}/>
                            })}
                        </div>
                    </ContentLayout>}
                {(customFilters?.length > 0 || isGroupAdmin || isGroupAdmin) &&
                    <div>
                        <LabelText
                            label={dict("track.filter.custom.label")}
                            style="sidebar"/>
                        {customFilters?.map(filter => {
                            return <TrackFilterButton
                                key={filter._id}
                                data={filter}/>
                        })}
                    </div>}
            </ContentLayout>
            {showEmtpyInfo() &&
                <ContentLayout alignSelf="stretch">
                    <EmptyDataInfo
                        size="small"
                        text={dict("track.trackFilter.custom.empty")}/>
                </ContentLayout>}
            {(isRouteOwnerAuthUser || isGroupAdmin) && <>
                <ActionBar location="sidebar">
                    <IconButton
                        type="add"
                        size="small"
                        opacityMode="none"
                        onClick={showTrackFilter}/>
                </ActionBar>
            </>}
        </ContentLayout>
    )

}
