import * as React from "react";
import {useRouteStates} from "../../../hooks/useRouteStates";
import {TrackHomePage} from "./TrackHomePage";
import {TrackFilterPage} from "./TrackFilterPage";
import {ContentLayout} from "../../../ui/layout/content/ContentLayout";

/******************************************************************
 * TrackMain
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function TrackMain() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {showTrackHome, showTrackFilter} = useRouteStates();

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout className="track-main" alignItems="stretch">
            {showTrackHome && <TrackHomePage/>}
            {showTrackFilter && <TrackFilterPage/>}
        </ContentLayout>
    );

}
