import * as React from "react";
import {ReactElement} from "react";
import {IMailBodyBlockData, IMailData} from "../../../../../../../shared/models/IMailData";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {MailBodyBlockActionBar} from "../actionbar/MailBodyBlockActionBar";
import {MailUnsupportedBlock} from "./MailUnsupportedBlock";
import {MailMarkdownBodyBlock} from "./MailMarkdownBodyBlock";
import {MailImageBodyBlock} from "./MailImageBodyBlock";
import {useServices} from "../../../../../hooks/useServices";
import {Headline} from "../../../../../ui/text/headings/Headline";
import {MailUtils} from "../../../../../../../shared/utils/MailUtils";
import {MailVerficiationBodyBlock} from "./MailVerficiationBodyBlock";

/******************************************************************
 * MailBodyBlock
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function MailBodyBlock(props: {
    mailData: IMailData
    bodyBlock: IMailBodyBlockData
    onChange: () => void
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {api, dict} = useServices()

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    async function update(): Promise<Response> {
        await api.mail.updateMail(props.mailData._id, props.mailData)
        props.onChange()
        return null
    }

    function getBlockComponent(): ReactElement {
        const blockType = MailUtils.getBodyBlockType(props.bodyBlock)
        switch (blockType) {
            case "markdown":
                return <MailMarkdownBodyBlock
                    data={props.bodyBlock.markdown}
                    onChange={update}/>
            case "image":
                return <MailImageBodyBlock
                    mailID={props.mailData._id}
                    image={props.bodyBlock.image}
                    onChange={update}/>
            case "verificationCode":
                return <MailVerficiationBodyBlock/>
            default:
                return <MailUnsupportedBlock
                    bodyBlock={props.bodyBlock}/>
        }
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="mail-body-block">
            <ContentLayout framed={true}>
                <Headline
                    text={dict(`mail.body.block.${MailUtils.getBodyBlockType(props.bodyBlock)}.title`)}
                    style="h6"
                    nowrap={true}/>
                {getBlockComponent()}
                <MailBodyBlockActionBar
                    mailData={props.mailData}
                    block={props.bodyBlock}
                    onChange={props.onChange}/>
            </ContentLayout>
        </div>
    )
}
