import * as React from "react";
import {useEffect, useState} from "react";
import {ContentLayout} from "../../../../ui/layout/content/ContentLayout";
import {Headline} from "../../../../ui/text/headings/Headline";
import {useServices} from "../../../../hooks/useServices";
import {LabelButton} from "../../../../ui/buttons/label/LabelButton";
import {useAuthUser} from "../../../../hooks/useAuthUser";
import {ComponentInitializer} from "../../../../ui/utils/init/ComponentInitializer";
import {Input} from "../../../../ui/form/elements/input/Input";
import {Table} from "../../../../ui/table/Table";
import {ITableColumnDefinition} from "../../../../ui/table/ITableColumnDefinition";

/******************************************************************
 * AdminMailFilter
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

type MailEntryType = { mail: string }

export function AdminMailFilter() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, api} = useServices();
    const {authUserID} = useAuthUser()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [dataLoaded, setDataLoaded] = useState<boolean>(false)
    const [whitelistEnabled, setWhitelistEnabled] = useState<boolean>()
    const [blacklistEnabled, setBlacklistEnabled] = useState<boolean>()
    const [whitelist, setWhitelist] = useState<MailEntryType[]>()
    const [blacklist, setBlacklist] = useState<MailEntryType[]>()

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        if (!authUserID) return
        loadData()
    }, [authUserID])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    async function loadData() {
        setWhitelistEnabled(await api.settings.getValue("mail.whitelist.enabled"))
        setBlacklistEnabled(await api.settings.getValue("mail.blacklist.enabled"))
        await updateWhiteList()
        await updateBlickList()
        setDataLoaded(true)
    }

    async function enableWhiteList(enable: boolean) {
        if (enable) {
            await enableBlackList(false)
        }
        await api.settings.setValue("mail.whitelist.enabled", enable)
        setWhitelistEnabled(await api.settings.getValue("mail.whitelist.enabled"))
    }

    async function enableBlackList(enable: boolean) {
        if (enable) {
            await enableWhiteList(false)
        }
        await api.settings.setValue("mail.blacklist.enabled", enable)
        setBlacklistEnabled(await api.settings.getValue("mail.blacklist.enabled"))
    }

    async function addMailToWhitelist(mail: string): Promise<Response> {
        if (!mail) return null
        const response = await api.user.addMailToWhitelist(mail)
        await updateWhiteList()
        return response
    }

    async function addMailToBlackList(mail: string): Promise<Response> {
        if (!mail) return null
        mail = mail.trim().toLowerCase()
        if (blacklist?.find(entry => entry.mail === mail)) return null
        const newBlacklist = blacklist ? [...blacklist, {mail}] : [{mail}]
        const response = await api.settings.setValue("mail.blacklist", newBlacklist.map(entry => entry.mail))
        setBlacklist(newBlacklist)
        return response
    }

    async function updateWhiteList() {
        const response: string[] = await api.settings.getValue("mail.whitelist")
        setWhitelist(response?.map(mail => ({mail})))
    }

    async function updateBlickList() {
        const response: string[] = await api.settings.getValue("mail.blacklist")
        setBlacklist(response?.map(mail => ({mail})))
    }

    async function deleteMailFromWhitelist(data: MailEntryType): Promise<Response> {
        const newWhitelist = whitelist.filter(entry => entry.mail !== data.mail)
        const response = await api.settings.setValue("mail.whitelist", newWhitelist.map(entry => entry.mail))
        await updateWhiteList()
        return response
    }

    async function deleteMailFromBlacklist(data: MailEntryType): Promise<Response> {
        const newBlacklist = blacklist.filter(entry => entry.mail !== data.mail)
        const response = await api.settings.setValue("mail.blacklist", newBlacklist.map(entry => entry.mail))
        setBlacklist(newBlacklist)
        return response
    }

    function mailListColumnsDefinition(listType: "whitelist" | "blacklist"): ITableColumnDefinition<MailEntryType>[] {
        const action = listType === "whitelist" ? deleteMailFromWhitelist : deleteMailFromBlacklist
        return [{
            columnName: dict("admin.mailFilter.mail"),
            dataKey: "mail",
            sortKey: "mail",
            type: "text",
            size: "3.5fr"
        }, {
            type: "delete",
            size: "40px",
            action: action
        }]
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ComponentInitializer isPropertyAvailable={dataLoaded}>
            <ContentLayout className="admin-mailfilter">
                <Headline text={dict("admin.mailFilter.title")} style="modal"/>
                <ContentLayout>
                    <ContentLayout framed={true}>
                        <ContentLayout columns={2} justifyContent="space-between" columnTemplate="auto min-content">
                            <Headline text={dict("admin.mailFilter.whitelist.title")} style="h5-underlined"/>
                            {whitelistEnabled
                                ? <LabelButton
                                    label={dict("admin.mailFilter.disable")}
                                    style="primary-small"
                                    onClick={() => enableWhiteList(false)}/>
                                : <LabelButton
                                    label={dict("admin.mailFilter.enable")}
                                    style="secondary-small"
                                    onClick={() => enableWhiteList(true)}/>}
                        </ContentLayout>
                        <Input
                            type="email"
                            size="small"
                            clearOnSubmit={true}
                            defaultValue={""}
                            placeholder={dict("admin.mailFilter.mail.placeholder")}
                            preventActionOnBlur={true}
                            action={addMailToWhitelist}/>
                        <ContentLayout framed={true} gap="small">
                            <Table<MailEntryType>
                                rowsData={whitelist}
                                sortKey="mail"
                                sortDirection="asc"
                                showFilter={whitelist?.length > 0}
                                visibleRows={5}
                                style="admin-compact"
                                columnsDefinition={mailListColumnsDefinition("whitelist")}/>
                        </ContentLayout>
                    </ContentLayout>
                    <ContentLayout framed={true}>
                        <ContentLayout columns={2} justifyContent="space-between" columnTemplate="auto min-content">
                            <Headline text={dict("admin.mailFilter.blacklist.title")} style="h5-underlined"/>
                            {blacklistEnabled
                                ? <LabelButton
                                    label={dict("admin.mailFilter.disable")}
                                    style="primary-small"
                                    onClick={() => enableBlackList(false)}/>
                                : <LabelButton
                                    label={dict("admin.mailFilter.enable")}
                                    style="secondary-small"
                                    onClick={() => enableBlackList(true)}/>}
                        </ContentLayout>
                        <Input
                            type="email"
                            size="small"
                            clearOnSubmit={true}
                            defaultValue={""}
                            placeholder={dict("admin.mailFilter.mail.placeholder")}
                            preventActionOnBlur={true}
                            action={addMailToBlackList}/>
                        <ContentLayout framed={true} gap="small">
                            <Table<MailEntryType>
                                rowsData={blacklist}
                                sortKey="mail"
                                sortDirection="asc"
                                showFilter={blacklist?.length > 0}
                                visibleRows={5}
                                style="admin-compact"
                                columnsDefinition={mailListColumnsDefinition("blacklist")}/>
                        </ContentLayout>
                    </ContentLayout>
                </ContentLayout>
            </ContentLayout>
        </ComponentInitializer>
    );
}
