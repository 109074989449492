import * as React from "react";
import {useEffect, useState} from "react";
import {IMailData} from "../../../../../../shared/models/IMailData";
import {ContentLayout} from "../../../../ui/layout/content/ContentLayout";
import {Headline} from "../../../../ui/text/headings/Headline";
import {useServices} from "../../../../hooks/useServices";
import {useSocketSignals} from "../../../../hooks/useSocketSignals";
import {IUserData} from "../../../../../../shared/models/IUserData";
import {LabelValueText} from "../../../../ui/text/labelvalue/LabelValueText";
import {LabelButton} from "../../../../ui/buttons/label/LabelButton";
import {ProgressingAnimation} from "../../../../ui/utils/progress/ProgressingAnimation";
import {MailInfoListModal} from "./MailInfoListModal";

/******************************************************************
 * MailStateInfo
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function MailStateInfo(props: {
    mailData: IMailData
    recipients: IUserData[]
    requestMailData: () => void
    onRequestRetrySendMail: () => void
}) {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {dict} = useServices()
    const {serverAdminMailerUpdate} = useSocketSignals()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [mailData, setMailData] = useState<IMailData>(props.mailData)
    const [showMails, setShowMails] = useState<boolean>(false)

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        if (!serverAdminMailerUpdate) return
        setMailData(serverAdminMailerUpdate)
        switch (serverAdminMailerUpdate.state) {
            case "sent":
            case "failed":
                props.requestMailData()
                break
        }
    }, [serverAdminMailerUpdate])

    useEffect(() => {
        setMailData(props.mailData)
    }, [props.mailData])

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return <>
        <ContentLayout
            className="mail-state-info"
            framed={true}>
            <ContentLayout
                justifyContent="space-between"
                alignItems="center"
                columnTemplate={mailData?.state == "sending"
                    ? "auto min-content"
                    : null}>
                <Headline
                    text={dict("mail.state.info.title")}
                    style="h5-underlined"/>
                {mailData?.state == "sending" &&
                    <ProgressingAnimation/>}
            </ContentLayout>
            <ContentLayout columns={2}>
                <LabelValueText
                    label={dict("mail.state.info.total.mails")}
                    value={props.recipients?.length.toString() ?? "–"}/>
                <LabelValueText
                    label={dict("mail.state.info.mails.to.be.send")}
                    warning={mailData?.state == "failed"}
                    value={mailData?.unsendRecipients?.length.toString() ?? "–"}/>
            </ContentLayout>
            <ContentLayout
                gap="small"
                justifyItems="end"
                justifyContent="space-between"
                columnTemplate={mailData?.state == "failed"
                    ? "min-content min-content"
                    : null}>
                {mailData?.state == "failed" &&
                    <LabelButton
                        label={dict("mail.send.retry")}
                        style="primary-small"
                        onClick={props.onRequestRetrySendMail}/>}
                <LabelButton
                    label={dict("mail.state.info.list.mails")}
                    style="secondary-small"
                    onClick={() => setShowMails(true)}/>
            </ContentLayout>
        </ContentLayout>
        {showMails &&
            <MailInfoListModal
                mailData={props.mailData}
                requestClose={() => setShowMails(false)}/>}
    </>

}
