import * as React from "react";
import {useEffect, useState} from "react";
import {Modal} from "../Modal";
import {Headline} from "../../../ui/text/headings/Headline";
import {useServices} from "../../../hooks/useServices";
import {ContentLayout} from "../../../ui/layout/content/ContentLayout";
import {IUserData} from "../../../../../shared/models/IUserData";
import {UserInfo} from "../../../ui/info/user/UserInfo";
import {MultiSelectOptionType} from "../../../ui/form/elements/select/options/MultiSelectOption";
import {UserPermissionType} from "../../../../../shared/types/UserPermissionType";
import {MultiSelectOptionsList} from "../../../ui/form/elements/select/list/MultiSelectOptionsList";
import {FrontendConfig} from "../../../../core/FrontendConfig";
import {UserUtils} from "../../../../../shared/utils/UserUtils";

/******************************************************************
 * UserPermissionsModal
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function UserPermissionsModal() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, api, state} = useServices();

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [user, setUser] = useState<IUserData>(state.showUserPermissions.getValue());

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        requestUserData()
    }, [])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function userPermissionOptions(): MultiSelectOptionType[] {
        return UserUtils.availableUserPermissions(
            FrontendConfig.PADDLE_ENVIRONMENT,
            FrontendConfig.PADDLE_ENABLED_PER_DEFAULT
        ).map(permission => {
            return {
                value: permission,
                text: dict("user.permission." + permission)
            }
        })
    }

    async function updatePermissions(values: UserPermissionType[]) {
        await api.user.update({
            _id: user._id,
            permissions: values
        })
        await requestUserData()
    }

    async function requestUserData() {
        const userData = await api.user.getUserDataByID(user._id)
        setUser(userData)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal
            className="user-permissions-modal"
            width="medium"
            closeAction={() => state.showUserPermissions.setValue(null)}>
            <ContentLayout>
                <ContentLayout gap="none">
                    <Headline text={dict("modal.user.permission.title")} style="modal"/>
                    <UserInfo user={user} clickToShowProfile={false} style="avatar"/>
                </ContentLayout>
                <MultiSelectOptionsList
                    options={userPermissionOptions()}
                    defaultValues={user.permissions}
                    minSelected={0}
                    onChange={updatePermissions}/>
            </ContentLayout>
        </Modal>
    );

}
