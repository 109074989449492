import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {ITrackFilterData} from "../../../../../../shared/models/submodels/ITrackFilterData";
import {MongoObjectIDType} from "../../../../../../shared/types/MongoObjectIDType";
import {ITableColumnDefinition} from "../../../../ui/table/ITableColumnDefinition";
import {ITrackFilterLeaderboardEntry} from "../../../../../../shared/types/ITrackFilterLeaderboardEntry";
import {ITableCarCellProps} from "../../../../ui/table/cells/car/TableCarCell";
import {DriftUtils} from "../../../../../../shared/utils/DriftUtils";
import {TimeUtils} from "../../../../../../shared/utils/TimeUtils";
import {Table} from "../../../../ui/table/Table";
import {ContentLayout} from "../../../../ui/layout/content/ContentLayout";
import {useServices} from "../../../../hooks/useServices";
import {Headline} from "../../../../ui/text/headings/Headline";
import {EmptyDataInfo} from "../../../../ui/info/empty/EmptyDataInfo";
import {ComponentInitializer} from "../../../../ui/utils/init/ComponentInitializer";
import {useEventState} from "../../../../hooks/useEventState";
import {useMobileStyle} from "../../../../hooks/useMobileStyle";
import {ITrackData} from "../../../../../../shared/models/submodels/ITrackData";
import {InfoText} from "../../../../ui/text/infotext/InfoText";
import {Spacer} from "../../../../ui/utils/spacer/Spacer";
import {LabelButton} from "../../../../ui/buttons/label/LabelButton";
import {useRouteOwner} from "../../../../hooks/useRouteOwner";
import {useGroupState} from "../../../../hooks/useGroupState";
import {useAuthUser} from "../../../../hooks/useAuthUser";
import {TrackUtils} from "../../../../../utils/TrackUtils";

/******************************************************************
 * TrackLeaderboard
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function TrackLeaderboard(props: {
    trackData: ITrackData,
    eventID?: MongoObjectIDType,
    trackFilter: ITrackFilterData,
    onLoaded?: () => void
}) {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const rootRef = useRef<HTMLDivElement>(null)
    const timerRef = useRef<number>()

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {api, dict, router, state} = useServices()
    const {eventOwnerType} = useEventState()
    const {routeOwnerValidDriverLicense, isRouteOwnerGroup} = useRouteOwner()
    const {groupData} = useGroupState()
    const {authUserUseSubscription} = useAuthUser()
    const [isMobileMode] = useMobileStyle(rootRef, 600)

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [filterLeaderboard, setFilterLeaderboard] = useState<ITrackFilterLeaderboardEntry[]>();
    const [loading, setLoading] = useState(false)

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        clearTimeout(timerRef.current)
        setLoading(false)
        setFilterLeaderboard(null)
        loadFilterLeaderboard()
    }, [props.trackFilter, props.trackData, routeOwnerValidDriverLicense])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function loadFilterLeaderboard() {
        if (!props.trackFilter || !props.trackData) {
            return
        }
        const data = await api.leaderboard.getTrackFilterLeaderboard(props.trackData?._id, props.trackFilter.path, props?.eventID)
        setFilterLeaderboard(data)
        timerRef.current = window.setTimeout(() => {
            clearTimeout(timerRef.current)
            setLoading(true)
        }, 200)
    }

    function isHigherDriverLicenseRequired() {
        return TrackUtils.isHigherDriverLicenseRequired(
            authUserUseSubscription,
            routeOwnerValidDriverLicense,
            isRouteOwnerGroup,
            groupData,
            props.trackFilter)
    }

    function columnsDefinition(): ITableColumnDefinition<ITrackFilterLeaderboardEntry>[] {
        return [{
            type: "text-bold",
            columnName: "#",
            align: "center",
            dataKey: "position",
            size: "50px"
        }, {
            type: "driver",
            dataKey: "user",
            sticky: true,
            columnName: dict("track.filter.leaderboard.column.driver"),
            size: "2.3fr"
        }, {
            type: "number-bold",
            align: "right",
            columnName: dict(props.trackData?.type == "lap"
                ? "track.filter.leaderboard.column.lapTime"
                : "track.filter.leaderboard.column.score"),
            size: "110px",
            value: data => {
                switch (props.trackData?.type) {
                    case "lap":
                        return TimeUtils.formatDrivenTime(data.bestLapTime)
                    case "gymkhana":
                        return data.score.toString()
                }
            },
        }, {
            type: "number",
            align: "left",
            columnName: dict(props.trackData?.type == "lap"
                ? "track.filter.leaderboard.column.lapTimeDiff"
                : "track.filter.leaderboard.column.scoreDiff"),
            size: "110px",
            value: data => {
                const firstData = filterLeaderboard[0]
                const index = filterLeaderboard?.indexOf(data)?.toString()
                if (index === "0") return ""
                if (filterLeaderboard.length <= 1) return ""
                switch (props.trackData?.type) {
                    case "lap":
                        const timeDiff = data.bestLapTime - firstData.bestLapTime
                        return "+" + TimeUtils.formatDrivenTime(timeDiff)
                    case "gymkhana":
                        const scoreDiff = firstData.score - data.score
                        return "+" + scoreDiff.toString()
                }
            }
        }, {
            type: "car",
            columnName: dict("track.filter.leaderboard.column.car"),
            size: "3fr",
            value: (data) => {
                return {
                    userID: data.user._id,
                    carName: data.carName
                } as ITableCarCellProps
            }
        }, {
            type: "text",
            columnName: dict("track.filter.leaderboard.column.engine"),
            size: "3fr",
            value: data => dict("drift.engine." + data.carEngine)
        }, {
            type: "text",
            columnName: dict("track.filter.leaderboard.column.tuning"),
            size: "3fr",
            value: data => DriftUtils.getTuningWithoutEngine(dict("drift.tuning." + data.carTuning))
        }];
    }

    function emtpyDataInfoText(): string {
        if (!loading) return ""
        return dict(!!props.eventID
            ? props.trackData.ownerType == "user"
                ? "track.filter.leaderboard.trainingonly.noData"
                : "track.filter.leaderboard.eventonly.noData"
            : "track.filter.leaderboard.noData")
    }

    function eventInfo(): string {
        return dict(eventOwnerType == "group"
            ? "track.filter.leaderboard.event.prefix"
            : "track.filter.leaderboard.training.prefix")
    }

    async function gotoSession(data: ITrackFilterLeaderboardEntry) {
        const route = await api.session.getSessionRoute(data.sessionID)
        if (!route) return
        await router.showRoute(route)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ComponentInitializer isPropertyAvailable={loading} fullHeight={true}>
            <ContentLayout
                ref={rootRef}
                className="track-leaderboard"
                alignItems="stretch"
                rowTemplate="min-content auto min-content">
                <ContentLayout gap="large">
                    <ContentLayout
                        columnTemplate={props.trackData?.layout ? "auto auto" : null}
                        gap={"tiny"}
                        justifyItems="center"
                        justifyContent="center">
                        <Headline
                            text={props.trackData?.name}
                            color="red"
                            style="h4"/>
                        {props.trackData?.layout &&
                            <Headline
                                text={props.trackData?.layout}
                                style="h4"/>}
                    </ContentLayout>
                    <ContentLayout
                        columns={isMobileMode ? 1 : 2}
                        gap={isMobileMode ? "small" : "medium"}
                        alignItems={isMobileMode ? null : "end"}
                        justifyContent={isMobileMode ? null : "space-between"}>
                        <ContentLayout gap="tiny">
                            {!!props.eventID &&
                                <Headline
                                    text={eventInfo()}
                                    align={isMobileMode ? "center" : "left"}
                                    style="h6"/>}
                            <Headline
                                text={TrackUtils.trackFilterName(props.trackFilter, props.trackData?.type, dict)}
                                align={isMobileMode ? "center" : "left"}
                                style="h1"/>
                        </ContentLayout>
                        {filterLeaderboard?.length > 0 &&
                            <Headline
                                text={dict("track.filter.leaderboard.driversCount").replace("{COUNT}", filterLeaderboard?.length?.toString())}
                                align={isMobileMode ? "center" : "right"}
                                style="h2"/>}
                    </ContentLayout>
                </ContentLayout>
                {isHigherDriverLicenseRequired()
                    ? <ContentLayout justifyItems={"center"} alignContent="center">
                        <InfoText
                            text={dict(isRouteOwnerGroup
                                ? "subscription.upgrade.info.group.track.filters"
                                : "subscription.upgrade.info.track.filters")}
                            align="center"
                            style="warning"/>
                        <LabelButton
                            label={dict("subscription.upgrade.info.button.showPlans")}
                            style="primary"
                            onClick={() => state.showSubscriptionPlans.setValue(true)}/>
                    </ContentLayout>
                    : <>{filterLeaderboard?.length > 0
                        ? <ContentLayout>
                            <Table<ITrackFilterLeaderboardEntry>
                                rowsData={filterLeaderboard}
                                showFilter={true}
                                visibleRows={25}
                                onRowClick={gotoSession}
                                columnsDefinition={columnsDefinition()}/>
                        </ContentLayout>
                        : <EmptyDataInfo text={emtpyDataInfoText()}/>}</>}
                {!!props.eventID && filterLeaderboard?.length > 0 &&
                    <ContentLayout
                        justifyItems="center"
                        gap="none">
                        <Spacer height="large"/>
                        <InfoText
                            text={dict("event.track.page.info")}
                            size="small"
                            align={"center"}/>
                        <Spacer height="large"/>
                    </ContentLayout>}
            </ContentLayout>
        </ComponentInitializer>
    );

}
