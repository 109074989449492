import {ErrorCode} from "../keys/ErrorCode";

/******************************************************************
 * YouTubeURLValidation
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class YouTubeURLValidation {

    static get REGEXP() {
        return /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))([\w-]{11})(?:\S+)?$/
    }

    static schemaValidator() {
        return {
            validate: {
                validator: value => YouTubeURLValidation.isValid(value),
                message: ErrorCode.YOUTUBE_URL_INVALID
            },
        }
    };

    static isValid(url: string): boolean {
        if (url === "" || !url) return true
        return YouTubeURLValidation.REGEXP.test(url)
    }

}
