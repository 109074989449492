import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {LabelText} from "../../../../../ui/text/label/LabelText";
import {LabelButton} from "../../../../../ui/buttons/label/LabelButton";
import {DriverList} from "../../../../../ui/list/driver/DriverList";
import {useServices} from "../../../../../hooks/useServices";
import {useEventState} from "../../../../../hooks/useEventState";
import {MongoObjectIDType} from "../../../../../../../shared/types/MongoObjectIDType";
import {useSessionState} from "../../../../../hooks/useSessionState";
import {IUserData} from "../../../../../../../shared/models/IUserData";
import {useRouteOwner} from "../../../../../hooks/useRouteOwner";
import {useGroupState} from "../../../../../hooks/useGroupState";
import {IGroupMemberData} from "../../../../../../../shared/models/submodels/IGroupMemberData";
import {useMobileStyle} from "../../../../../hooks/useMobileStyle";

/******************************************************************
 * SessionDrivers
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function SessionDrivers() {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const ref = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, api} = useServices()
    const {isRouteOwnerAuthUser} = useRouteOwner()
    const {groupMembers, isGroupAdmin} = useGroupState()
    const {eventRegisteredDrivers, eventAccess} = useEventState()
    const {sessionID, sessionForbiddenDrivers, sessionIsFinished} = useSessionState()
    const [isMobileMode] = useMobileStyle(ref, 500)

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [isBusy, setIsBusy] = useState<boolean>(false)
    const [allowedDrivers, setAllowedDrivers] = useState<IUserData[]>()
    const [forbiddenDrivers, setForbiddenDrivers] = useState<IUserData[]>()

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        const allowedDrivers: IUserData[] = []
        const forbiddenDrivers: IUserData[] = []
        if (eventAccess == "only-group-members") {
            groupMembers?.forEach((member: IGroupMemberData) => {
                if (sessionForbiddenDrivers?.includes(member.user._id)) {
                    forbiddenDrivers.push(member.user);
                } else {
                    allowedDrivers.push(member.user)
                }
            })
        } else {
            eventRegisteredDrivers?.forEach(user => {
                if (sessionForbiddenDrivers?.includes(user._id)) {
                    forbiddenDrivers.push(user);
                } else {
                    allowedDrivers.push(user)
                }
            })
        }
        setAllowedDrivers(allowedDrivers);
        setForbiddenDrivers(forbiddenDrivers);
    }, [eventRegisteredDrivers, sessionForbiddenDrivers, groupMembers])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    async function removeAllDrivers() {
        setIsBusy(true)
        switch (eventAccess) {
            case "only-group-members":
                await api.session.update(sessionID, {
                    forbiddenDrivers: groupMembers.map(member => member.user._id)
                })
                break;
            default:
                await api.session.update(sessionID, {
                    forbiddenDrivers: eventRegisteredDrivers.map(user => user._id)
                })
        }
        setIsBusy(false)
    }

    async function forbidDriver(userID: MongoObjectIDType) {
        setIsBusy(true)
        const forbiddenDrivers = sessionForbiddenDrivers?.concat() ?? []
        if (!forbiddenDrivers.includes(userID)) {
            forbiddenDrivers.push(userID);
            await api.session.update(sessionID, {forbiddenDrivers: forbiddenDrivers})
        }
        setIsBusy(false)
    }

    async function allowAllDrivers() {
        setIsBusy(true)
        await api.session.update(sessionID, {forbiddenDrivers: null})
        setIsBusy(false)
    }

    async function allowDriver(userID: MongoObjectIDType) {
        setIsBusy(true)
        let forbiddenDrivers = sessionForbiddenDrivers?.concat() ?? []
        forbiddenDrivers = forbiddenDrivers.filter(uid => {
            return uid != userID
        })
        await api.session.update(sessionID, {forbiddenDrivers: forbiddenDrivers})
        setIsBusy(false)
    }

    function allowedDriversNoDataMessage() {
        switch (eventAccess) {
            case "any-member":
                return dict("session.drivers.allow.all")
            case "only-group-members":
                return dict("session.drivers.allow.nodata");
            case "only-registered-drivers":
            case "only-registered-drivers-after-approval":
            case "only-registered-drivers-after-paid":
                return dict("session.drivers.allow.nodata");
        }
    }

    function showRemoveAllButton(): boolean {
        return (isRouteOwnerAuthUser || isGroupAdmin) && !sessionIsFinished && allowedDrivers && allowedDrivers.length > 0
    }

    function showAddAllButton(): boolean {
        return (isRouteOwnerAuthUser || isGroupAdmin) && !sessionIsFinished && forbiddenDrivers && forbiddenDrivers.length > 0
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            ref={ref}
            columns={eventAccess == "any-member" ? 1 : (isMobileMode ? 1 : 2)}
            size="content">
            <ContentLayout framed={true}>
                <ContentLayout
                    columnTemplate={showRemoveAllButton() ? "1fr min-content" : null}
                    justifyContent="space-between">
                    <LabelText
                        label={dict("session.drivers.allowed")}/>
                    {showRemoveAllButton() &&
                        <LabelButton
                            label={dict("session.drivers.remove")}
                            disabled={isBusy}
                            style="primary-small"
                            onClick={removeAllDrivers}/>}
                </ContentLayout>
                <DriverList
                    data={allowedDrivers}
                    primaryIconType="cancel"
                    isBusy={isBusy}
                    userClickable={true}
                    onPrimaryIconClick={(isRouteOwnerAuthUser || isGroupAdmin) && !sessionIsFinished && forbidDriver}
                    emptyDataInfo={allowedDriversNoDataMessage()}/>
            </ContentLayout>
            {eventAccess != "any-member" &&
                <ContentLayout framed={true}>
                    <ContentLayout
                        columnTemplate={showAddAllButton() ? "1fr min-content" : null}
                        justifyContent="space-between">
                        <LabelText
                            label={dict("session.drivers.forbidden")}/>
                        {showAddAllButton() &&
                            <LabelButton
                                label={dict("session.drivers.add")}
                                disabled={isBusy}
                                style="primary-small"
                                onClick={allowAllDrivers}
                            />}
                    </ContentLayout>
                    <DriverList
                        data={forbiddenDrivers}
                        primaryIconType="add"
                        isBusy={isBusy}
                        userClickable={true}
                        onPrimaryIconClick={(isRouteOwnerAuthUser || isGroupAdmin) && !sessionIsFinished && allowDriver}
                        emptyDataInfo={dict("session.drivers.forbidden.nodata")}/>
                </ContentLayout>}
        </ContentLayout>
    );

}
