import {isValidLanguage, LanguageType} from "../types/LanguageType";
import {SharedConfig} from "../config/SharedConfig";
import {IDictionaryEntryData} from "../models/IDictionaryEntryData";
import {IMultilangString} from "../models/submodels/IMultilangString";
import {IMultilangObjectID} from "../models/submodels/IMultilangObjectID";
import {MongoObjectIDType} from "../types/MongoObjectIDType";

/******************************************************************
 * LanguageUtils
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class LanguageUtils {

    static getLanguageFromRequest(req: any): LanguageType {
        const acceptLanguage = req?.headers['accept-language'];
        const preferredLanguage = acceptLanguage?.split(',')?.[0];
        const language = preferredLanguage?.split('-')?.[0]?.toLowerCase();
        if (isValidLanguage(language)) {
            return language;
        }
        return SharedConfig.DEFAULT_LANGUAGE;
    }

    static hasMissingTranslations(data: IDictionaryEntryData): boolean {
        let hasMissing = false
        SharedConfig.LANGUAGES.forEach((language: LanguageType) => {
            const value = data[language];
            if (!LanguageUtils.hasValue(value, data.key)) {
                hasMissing = true;
            }
        })
        return hasMissing;
    }

    static getMissingTranslations(data: IDictionaryEntryData): LanguageType[] {
        const missingLanguages: LanguageType[] = []
        SharedConfig.LANGUAGES.forEach((language: LanguageType) => {
            const value = data[language];
            if (!LanguageUtils.hasValue(value, data.key)) {
                missingLanguages.push(language);
            }
        })
        return missingLanguages;
    }

    static getBaseTranslation(data: IDictionaryEntryData): {language: LanguageType, value: string} {
        const availableTranslations = LanguageUtils.getAvailableTranslations(data);
        if (availableTranslations.length > 0) {
            const defaultTranslation = availableTranslations.find(translation => translation.language == SharedConfig.DEFAULT_LANGUAGE);
            if (defaultTranslation) {
                return defaultTranslation;
            }
            return availableTranslations[0];
        }
        return null;
    }

    static getLangLocale(language: LanguageType): string {
        switch (language) {
            case "de": return "de-DE";
            case "en": return "en-UK";
        }
    }

    static getValueFromMultilangString(multilangString: IMultilangString, language: LanguageType, skipAlt?: boolean): string {
        if (!multilangString) {
            return undefined;
        }
        if (typeof multilangString == "string") {
            return multilangString
        }
        language = language ?? SharedConfig.DEFAULT_LANGUAGE;
        if (multilangString && multilangString[language]) {
            return multilangString[language];
        }
        if (!skipAlt) {
            const alternativeLanguage = SharedConfig.LANGUAGES.find(lang => !!multilangString[lang]);
            if (alternativeLanguage && multilangString[alternativeLanguage]) {
                return multilangString[alternativeLanguage];
            }
        }
        return "{no translation found}";
    }

    static updateMultiLangString(multilangString: IMultilangString, language: LanguageType, value: string): IMultilangString {
        if (!multilangString || typeof multilangString == "string") {
            multilangString = {};
        }
        multilangString[language] = value;
        return multilangString;
    }

    static getValueFromMultilangObjectID(multilangObject: IMultilangObjectID, language: LanguageType, skipAlt?: boolean): string {
        if (!multilangObject) {
            return null;
        }
        if (typeof multilangObject == "string") {
            return multilangObject;
        }
        language = language ?? SharedConfig.DEFAULT_LANGUAGE;
        if (multilangObject && multilangObject[language]) {
            return multilangObject[language];
        }
        if (!skipAlt) {
            const alternativeLanguage = SharedConfig.LANGUAGES.find(lang => !!multilangObject[lang]);
            if (alternativeLanguage && multilangObject[alternativeLanguage]) {
                return multilangObject[alternativeLanguage];
            }
        }
        return null
    }

    static updateMultiLangObjectID(multilangObject: IMultilangObjectID, language: LanguageType, value: MongoObjectIDType): IMultilangObjectID {
        if (!multilangObject || typeof multilangObject == "string") {
            multilangObject = {};
        }
        multilangObject[language] = value;
        return multilangObject;
    }

    /* ----------------------------------------------------------------
 	 * PRIVATE METHODES
 	 * --------------------------------------------------------------*/

    private static hasValue(value: string, key: string): boolean {
        if (!value ||
            value === "" ||
            value == "{" + key + "}" ||
            value == "undefined") {
            return false;
        }
        return true;
    }

    private static getAvailableTranslations(data: IDictionaryEntryData): {language: LanguageType, value: string}[] {
        const availableTranslations: {language: LanguageType, value: string}[] = []
        SharedConfig.LANGUAGES.forEach((language: LanguageType) => {
            const value = data[language];
            if (LanguageUtils.hasValue(value, data.key)) {
                availableTranslations.push({language: language, value: value});
            }
        })
        return availableTranslations;
    }

}
