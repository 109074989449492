import * as React from "react";
import {useNotificationState} from "../../../hooks/useNotificationState";
import {Overlay} from "../Overlay";
import {useEffect, useRef, useState} from "react";

/******************************************************************
 * NotificationOverlay
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function NotificationOverlay() {

    /* ----------------------------------------------------------------
 	 * REF
 	 * --------------------------------------------------------------*/

    const ref = useRef<HTMLDivElement>()

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {notification, setNotification} = useNotificationState()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [closeIt, setCloseIt] = useState(false)

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        let timeoutID
        if (duration() > 0) {
            timeoutID = setTimeout(() => {
                setCloseIt(true)
            }, duration() * 1000);
        }
        return () => clearTimeout(timeoutID)
    }, [notification])

    useEffect(() => {
        if (ref.current && duration() > 0) {
            ref.current.style.setProperty("--autoclose-animation-duration", duration() + "s")
        }
    }, [ref.current, notification])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function duration() {
        return notification.message.length * 0.05
    }

    function onClose() {
        setNotification(null)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Overlay
            className="notification-overlay"
            clickToClose={true}
            closeIt={closeIt}
            onClose={onClose}>
            <div
                ref={ref}
                className="notification"
                data-autoclose={!!duration()}>
                {notification?.message}
            </div>
        </Overlay>
    );

}
