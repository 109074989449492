import * as React from "react";
import {ReactElement, useState} from "react";
import {Modal} from "../Modal";
import {useServices} from "../../../hooks/useServices";
import {Headline} from "../../../ui/text/headings/Headline";
import {LabelButton} from "../../../ui/buttons/label/LabelButton";
import {InfoText} from "../../../ui/text/infotext/InfoText";
import {useRouteOwner} from "../../../hooks/useRouteOwner";
import {FrontendRoute} from "../../../../../shared/routes/FrontendRoute";
import {useEventState} from "../../../hooks/useEventState";
import {useSessionState} from "../../../hooks/useSessionState";
import {ContentLayout} from "../../../ui/layout/content/ContentLayout";
import {useTrackState} from "../../../hooks/useTrackState";
import {useRouteStates} from "../../../hooks/useRouteStates";
import {FrontendRouteUtils} from "../../../../../shared/utils/FrontendRouteUtils";
import {SelectInput} from "../../../ui/form/elements/select/SelectInput";
import {useGroupState} from "../../../hooks/useGroupState";
import {useResultState} from "../../../hooks/useResultState";
import {useAuthUser} from "../../../hooks/useAuthUser";
import {KeyController} from "../../../ui/keyboard/KeyController";
import {VerifyConfirmModal} from "../verify/VerifyConfirmModal";

/******************************************************************
 * ConfirmModal
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function ConfirmModal() {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {state, dict, api, router} = useServices()
    const {routePath} = useRouteStates()
    const {authUserPath} = useAuthUser()
    const {routeOwnerPath} = useRouteOwner()
    const {eventPath, eventOwnerType, eventOwner} = useEventState()
    const {sessionID} = useSessionState()
    const {resultID} = useResultState()
    const {trackID, trackOwnerType, trackOwner, trackPath, trackFilters} = useTrackState()
    const {groupPath} = useGroupState()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [progressing, setProgressing] = useState<boolean>(false);
    const [isConfirmed, setIsConfirmed] = useState<boolean>(state.showConfirm.getValue()?.confirmed)
    const [showVerificationModal, setShowVerificationModal] = useState<boolean>(false)
    const [verifyProgressing, setVerifyProgressing] = useState<boolean>(false)

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function close() {
        state.showConfirm.setValue(null);
    }

    function getInfoText() {
        const payload = state.showConfirm.getValue().payload;
        switch (state.showConfirm.getValue().type) {
            case "sendMail":
                return dict("mail.send.confirm.text").replaceAll("{NUM_RECIPIENTS}", payload.numRecipients)
            case "cancelSubscription":
                return dict("plan.cancel.confirm.text")
            case "deleteGroupAboutArticle":
                return dict("group.about.delete.text")
            case "deleteEventAboutArticle":
                return dict("event.about.delete.text")
            case "deleteEvent":
                const isTraining = eventOwnerType === "user"
                switch (isTraining) {
                    case true:
                        return dict("training.delete.text").replace("{EVENT_NAME}", payload.eventName);
                    case false:
                        return dict("event.delete.text").replace("{EVENT_NAME}", payload.eventName);
                }
            case "deleteSession":
                return dict("session.delete.text").replace("{SESSION_NAME}", payload.sessionName);
            case "resetSession":
                return dict("session.reset.text").replace("{SESSION_NAME}", payload.sessionName);
            case "deleteResult":
                return dict("result.delete.text").replace("{RESULT_NAME}", payload.resultName);
            case "deleteTrackFilter":
                return dict("track.filter.delete.text")
                    .replace("{TRACK_FILTER_NAME}", payload.trackFilterName)
            case "deleteTrack":
                return dict("track.delete.text")
                    .replace("{TRACK_NAME}", payload.trackName)
            case "deleteGroup":
                return dict("group.delete.text")
                    .replace("{GROUP_NAME}", payload.groupName)
            case "deleteUser":
                return dict("user.delete.text")
                    .replace("{USER_NICK}", payload.userNick)
            case "deleteCar":
                return dict("car.delete.text")
                    .replace("{CAR_NAME}", payload.carName)
            case "deleteLeaderboardEntry":
                return dict("leaderboardEntry.delete.text")
                    .replace("{NUM_STINTS}", payload.stintIDs.length.toString())
            case "deleteStint":
                return dict("stint.delete.text")

        }
    }

    function getButtonLabel() {
        switch (state.showConfirm.getValue().type) {
            case "sendMail":
                return dict("mail.send.button.label")
            case "cancelSubscription":
                return dict("plan.cancel.button.label")
            case "deleteGroupAboutArticle":
                return dict("group.about.delete.button.label")
            case "deleteEventAboutArticle":
                return dict("event.about.delete.button.label")
            case "deleteEvent":
                const isTraining = eventOwnerType === "user"
                switch (isTraining) {
                    case true:
                        return dict("training.delete.label")
                    case false:
                        return dict("event.delete.label")
                }
            case "deleteSession":
                return dict("session.delete.label")
            case "resetSession":
                return dict("session.reset.label")
            case "deleteResult":
                return dict("result.delete.label")
            case "deleteTrackFilter":
                return dict("track.filter.delete.label")
            case "deleteTrack":
                return dict("track.delete.label")
            case "deleteGroup":
                return dict("group.delete.label")
            case "deleteUser":
                return dict("user.delete.label")
            case "deleteCar":
                return dict("car.delete.label")
            case "deleteLeaderboardEntry":
                return dict("leaderboardEntry.delete.label")
            case "deleteStint":
                return dict("stint.delete.label")
        }
    }

    function getHeadline() {
        switch (state.showConfirm.getValue().type) {
            case "sendMail":
                return dict("mail.send.confirm.title")
            case "cancelSubscription":
                return dict("plan.cancel.confirm.title")
            case "deleteGroupAboutArticle":
                return dict("group.about.delete.title")
            case "deleteEventAboutArticle":
                return dict("event.about.delete.title")
            case "deleteEvent":
                const isTraining = eventOwnerType === "user"
                switch (isTraining) {
                    case true:
                        return dict("training.delete.confirm.title")
                    case false:
                        return dict("event.delete.confirm.title")
                }
            case "deleteSession":
                return dict("session.delete.confirm.title")
            case "resetSession":
                return dict("session.reset.confirm.title")
            case "deleteResult":
                return dict("result.delete.confirm.title")
            case "deleteTrackFilter":
                return dict("track.filter.delete.confirm.title")
            case "deleteTrack":
                return dict("track.delete.confirm.title")
            case "deleteGroup":
                return dict("group.delete.confirm.title")
            case "deleteUser":
                return dict("user.delete.confirm.title")
            case "deleteCar":
                return dict("car.delete.confirm.title")
            case "deleteLeaderboardEntry":
                return dict("leaderboardEntry.delete.confirm.title")
            case "deleteStint":
                return dict("stint.delete.confirm.title")
        }
    }

    async function action() {
        setProgressing(true);
        switch (state.showConfirm.getValue().type) {
            case "sendMail":
                state.showConfirm.getValue().payload.onSendConfirmed?.()
                return
            case "cancelSubscription":
                setShowVerificationModal(true)
                return
            case "deleteEvent":
                await deleteEvent()
                break;
            case "deleteSession":
                await deleteSession()
                break;
            case "resetSession":
                await resetSession()
                break;
            case "deleteResult":
                await deleteResult()
                break;
            case "deleteTrackFilter":
                await deleteTrackFilter()
                break;
            case "deleteTrack":
                await deleteTrack()
                break;
            case "deleteGroup":
                await deleteGroup()
                break;
            case "deleteUser":
                await deleteUser()
                break;
            case "deleteCar":
                await deleteCar()
                break;
            case "deleteLeaderboardEntry":
                await deleteLeaderboardEntry()
                break;
            case "deleteGroupAboutArticle":
                await deleteGroupAboutArticle()
                break;
            case "deleteEventAboutArticle":
                await deleteEventAboutArticle()
                break;
            case "deleteStint":
                await deleteStint()
                break;
        }
        setProgressing(false);
        close();
    }

    async function deleteEvent() {
        const payload = state.showConfirm.getValue().payload;
        await api.event.delete(payload?.eventID);
        state.showEventSettings.setValue(false);
        switch (eventOwnerType) {
            case "user":
                return await router.showRoute(FrontendRoute.USER(routeOwnerPath));
            case "group":
                return await router.showRoute(FrontendRoute.GROUP(groupPath));
        }
    }

    async function deleteGroup() {
        const payload = state.showConfirm.getValue().payload;
        await api.group.delete(payload?.groupID);
        state.showGroupSettings.setValue(false);
        await router.showRoute(FrontendRoute.USER(authUserPath));
    }

    async function deleteUser() {
        const payload = state.showConfirm.getValue().payload;
        return await api.user.delete({_id: payload.userID});
    }

    async function deleteCar() {
        const payload = state.showConfirm.getValue().payload;
        await api.car.delete(payload?.carID);
        state.showCar.setValue(null)
    }

    async function deleteLeaderboardEntry() {
        const payload = state.showConfirm.getValue().payload;
        await api.leaderboard.deleteLeaderboardEntry(payload.stintIDs, payload.sessionID);
    }

    async function deleteStint() {
        const payload = state.showConfirm.getValue().payload;
        await api.stint.deleteStint(payload.stintID);
    }

    async function deleteSession() {
        const payload = state.showConfirm.getValue().payload;
        const needReRoute = sessionID && sessionID == payload?.sessionID;
        await api.session.delete(payload?.sessionID);
        if (needReRoute) {
            await router.showRoute(FrontendRoute.EVENT(eventOwnerType, eventOwner.path, eventPath));
        }
    }

    async function deleteResult() {
        const payload = state.showConfirm.getValue().payload;
        const needReRoute = resultID && resultID == payload?.resultID;
        await api.result.delete(payload?.resultID);
        if (needReRoute) {
            await router.showRoute(FrontendRoute.EVENT(eventOwnerType, eventOwner.path, eventPath));
        }
    }

    async function resetSession() {
        const payload = state.showConfirm.getValue().payload;
        await api.session.reset(payload?.sessionID);
    }

    async function deleteGroupAboutArticle() {
        const payload = state.showConfirm.getValue().payload;
        const articleID = payload?.articleID;
        const groupID = payload?.groupID;
        if (!articleID || !groupID) return;
        await api.article.deleteArticle(articleID);
        await api.group.update(groupID, {aboutArticle: null})
    }

    async function deleteEventAboutArticle() {
        const payload = state.showConfirm.getValue().payload;
        const articleID = payload?.articleID;
        const eventID = payload?.eventID;
        if (!articleID || !eventID) return;
        await api.article.deleteArticle(articleID);
        await api.event.update(eventID, {aboutArticle: null})
    }

    async function deleteTrack() {
        const payload = state.showConfirm.getValue().payload;
        if (!trackID || !payload.trackID) return;
        await api.track.delete(payload.trackID)
        state.showCreateTrack.setValue(null)
        if (FrontendRouteUtils.parseTrackRoute(routePath).trackPath == payload.trackPath) {
            state.showTrackSettings.setValue(false)
            switch (trackOwnerType) {
                case "user":
                    router.showRoute(FrontendRoute.USER_TRACKS(trackOwner.path));
                    break;
                case "group":
                    router.showRoute(FrontendRoute.GROUP_TRACKS(trackOwner.path));
                    break;
            }
        }
    }

    async function deleteTrackFilter() {
        const payload = state.showConfirm.getValue().payload;
        if (!trackFilters || !payload.trackFilterID) return;
        const filters = trackFilters.filter(filter => filter._id != payload.trackFilterID)
        await api.track.update(trackID, {filters: filters})
        if (FrontendRouteUtils.parseTrackRoute(routePath).filterPath == payload.trackFilterPath) {
            await router.showRoute(FrontendRoute.TRACK(trackOwnerType, trackOwner.path, trackPath));
        }
    }

    async function verifyAction(code: string): Promise<Response> {
        switch (state.showConfirm.getValue().type) {
            case "cancelSubscription":
                setVerifyProgressing(true)
                const success = await api.paddle.cancelSubscription(code)
                if (!success) {
                    setVerifyProgressing(false)
                }
                return null
        }
        return null
    }

    function customElement(): ReactElement {
        return state.showConfirm.getValue()?.customElement
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    function getConfirmCheckText(doIt: boolean): string {
        switch (doIt) {
            case false:
                switch (state.showConfirm.getValue().type) {
                    case "sendMail":
                        return dict("confirm.send.mail.check.no")
                    case "cancelSubscription":
                        return dict("confirm.cancel.subscription.check.no")
                }
                return dict("confirm.delete.check.no")
            case true:
                switch (state.showConfirm.getValue().type) {
                    case "deleteEvent":
                    case "deleteSession":
                    case "deleteResult":
                    case "deleteTrackFilter":
                    case "deleteTrack":
                    case "deleteGroup":
                    case "deleteUser":
                    case "deleteCar":
                    case "deleteLeaderboardEntry":
                    case "deleteGroupAboutArticle":
                    case "deleteEventAboutArticle":
                    case "deleteStint":
                        return dict("confirm.delete.check.yes")
                    case "resetSession":
                        return dict("confirm.reset.check.yes")
                    case "cancelSubscription":
                        return dict("confirm.cancel.subscription.check.yes")
                    case "sendMail":
                        return dict("confirm.send.mail.check.yes")
                }
        }
    }

    return <>
        <Modal closeAction={close} width="medium" type="normal" position="global">
            <Headline text={getHeadline()} style="modal"/>
            <ContentLayout className="confirm-modal">
                <InfoText text={getInfoText()}/>
                {!!customElement() && customElement()}
                <ContentLayout
                    framed={true}>
                    <Headline
                        text={dict("confirm.delete.check.label")}
                        style="h5-underlined"/>
                    <SelectInput
                        onChange={(value) => setIsConfirmed(value == "yes")}
                        defaultValue={isConfirmed ? "yes" : "no"}
                        options={[
                            {value: "no", text: getConfirmCheckText(false)},
                            {value: "yes", text: getConfirmCheckText(true)}
                        ]}/>
                </ContentLayout>
                <ContentLayout columns={2} className="confirm-modal-buttons">
                    <LabelButton
                        onClick={close}
                        label={dict("generic.abort")}
                        style="secondary"/>
                    <LabelButton
                        onClick={action}
                        disabled={!isConfirmed}
                        progressing={progressing}
                        label={getButtonLabel()}
                        style="primary"/>
                </ContentLayout>
            </ContentLayout>
            <KeyController
                enabled={isConfirmed}
                code="enter"
                onKey={action}/>
        </Modal>
        {showVerificationModal &&
            <VerifyConfirmModal
                action={verifyAction}
                forceProgressing={verifyProgressing}
                requestClose={() => {
                    setProgressing(false)
                    setShowVerificationModal(false)
                }}/>}
    </>

}
