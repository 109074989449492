import * as React from "react";
import {ForgotPasswordForm} from "../../../ui/form/forms/ForgotPasswordForm";
import {Modal} from "../Modal";
import {Headline} from "../../../ui/text/headings/Headline";
import {useServices} from "../../../hooks/useServices";

/******************************************************************
 * ForgotPasswordModal
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function ForgotPasswordModal() {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {state, dict} = useServices();

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function close() {
        state.showForgotPassword.setValue(false);
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal closeAction={close} width="small">
            <Headline text={dict("password.forgot.title")} style="modal"/>
            <ForgotPasswordForm />
        </Modal>
    );

}
