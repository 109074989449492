import * as React from "react";

/******************************************************************
 * WaveIcon
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function WaveIcon(props: {
    scale?: number
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="wave-icon">
            <svg width={16 * (props.scale ?? 1)} height={8 * (props.scale ?? 1)} viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.08148 3.69922C1.08148 3.69922 4.37081 -1.74011 7.74253 3.69922C11.1142 9.13855 14.4036 3.69922 14.4036 3.69922" stroke="white" strokeWidth="2" strokeLinecap="round"/>
            </svg>
        </div>
    );

}
