import * as React from "react";
import {useServices} from "../../../../hooks/useServices";
import {SessionStateType} from "../../../../../../shared/types/SessionStateType";
import {ISessionLeaderboardEntry} from "../../../../../../shared/types/ISessionLeaderboardEntry";

/******************************************************************
 * TableLeaderboardDriverStateCell
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function TableLeaderboardDriverStateCell(props: {
    entry: ISessionLeaderboardEntry
}) {

    /* ----------------------------------------------------------------
 	 * SERVICES
 	 * --------------------------------------------------------------*/

    const {dict} = useServices();

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function showState(): boolean {
        switch (props.entry.session.state as SessionStateType) {
            case "finished":
            case "upcoming":
                return false
            case "ready":
            case "running":
                return true
        }
    }

    function theme(): "dark" | "light" {
        switch (props.entry.state) {
            case "ready":
            case "driving":
                return "light"
            case "invalid":
            case "finished":
                return "dark"
        }
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="table-leaderboard-driverstate-cell"
            data-theme={theme()}
            data-size={props.entry.type === "primary" ? "normal" : "small"}>
            {showState() && <div className="table-leaderboard-driverstate-cell-state">
                {dict("state." + props.entry.state)}
            </div>}
        </div>
    );

}
