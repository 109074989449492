import * as React from "react";
import {useRouteOwner} from "../../../hooks/useRouteOwner";
import {useEventState} from "../../../hooks/useEventState";
import {useSessionState} from "../../../hooks/useSessionState";
import {EditableField} from "../../form/editable/EditableField";
import {useServices} from "../../../hooks/useServices";
import {FrontendRoute} from "../../../../../shared/routes/FrontendRoute";
import {useRouteStates} from "../../../hooks/useRouteStates";
import {useGroupState} from "../../../hooks/useGroupState";
import {GameIDUtils} from "../../../../utils/GameIDUtils";
import {useNotificationState} from "../../../hooks/useNotificationState";

/******************************************************************
 * GameIDInfo
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function GameIDInfo() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {api, router, dict} = useServices()
    const {routeType} = useRouteStates()
    const {isRouteOwnerAuthUser} = useRouteOwner()
    const {eventData, eventPath, eventOwnerType, eventOwner} = useEventState()
    const {isGroupAdmin} = useGroupState()
    const {sessionData, sessionID} = useSessionState()
    const {setNotification} = useNotificationState()

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    async function updatePath(newPath: string): Promise<Response> {
        let response: Response
        switch (routeType) {
            case "event":
                response = await api.session.update(sessionID, {path: "/" + newPath})
                if (response.status == 200) {
                    router.showRoute(FrontendRoute.EVENT_SESSION(eventOwnerType, eventOwner.path, eventPath, "/" + newPath))
                }
                return response;
        }
        return null
    }

    async function copyToClipboard() {
        try {
            navigator.clipboard.writeText(baseGameID() + "/" + specificGameID())
                .then(async () => {
                    setNotification({type: "info", message: dict("capi.gameid.copiedToClipboard")})
                })
        } catch (e) {
            setNotification({type: "info", message: dict("capi.gameid.copyToClipboardFailed")})
        }
    }

    function baseGameID(): string {
        return GameIDUtils.baseGameID(eventData)
    }

    function specificGameID(): string {
        return GameIDUtils.specificGameID(sessionData)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="game-id-info">
            {baseGameID() && <>
                <span onClick={copyToClipboard}>{baseGameID() + "/"}</span>
                {(isRouteOwnerAuthUser || isGroupAdmin)
                    ? <EditableField
                        text={specificGameID()}
                        useEnterToSave={true}
                        action={updatePath}
                        ui="chromeless"/>
                    : <span onClick={copyToClipboard}>{specificGameID()}</span>
                }
            </>}
        </div>);

}
