import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {useServices} from "../../../hooks/useServices";
import {ContentLayout} from "../../layout/content/ContentLayout";
import {UserInfo} from "../../info/user/UserInfo";
import {FrontendRoute} from "../../../../../shared/routes/FrontendRoute";
import {Infobox} from "../Infobox";
import {IGroupMemberData} from "../../../../../shared/models/submodels/IGroupMemberData";
import {ArrayUtils} from "../../../../utils/ArrayUtils";
import {useMobileStyle} from "../../../hooks/useMobileStyle";
import {IGroupData} from "../../../../../shared/models/IGroupData";

/******************************************************************
 * GroupMembersInfobox
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function GroupMembersInfobox(props: {
    group: IGroupData
    isPreview?: boolean
}) {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const ref = useRef<HTMLDivElement>(null);

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {dict, state, router} = useServices();
    const [isSmall] = useMobileStyle(ref, 450)
    const [isMedium] = useMobileStyle(ref, 600)

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [members, setMembers] = useState<IGroupMemberData[]>([])

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        const shuffledGroupMembers = ArrayUtils.shuffledArray(props.group?.members)
        setMembers(shuffledGroupMembers?.slice(0, 20))
    }, [props.group?.members])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function colums(): number {
        const numDrivers = members?.length ?? 0
        if (numDrivers > 30) {
            return isSmall ? 5 : (isMedium ? 6 : 8)
        }
        return isSmall ? 4 : (isMedium ? 5 : 7)
    }

    function title(): string {
        if (props.group?.members?.length == 1) {
            return dict("infobox.groupMembers.single.title")
        }
        if (props.group?.members?.length > 0) {
            return props.group?.members.length + " " + dict("infobox.groupMembers.title")
        }
        return dict("infobox.groupMembers.title")
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Infobox
            title={title()}
            isPreview={props.isPreview}
            hasData={props.group?.members?.length > 0}
            noDataInfo={dict("infobox.groupMembers.empty.info")}
            createButtonLabel={dict("infobox.groupMembers.add.button.label")}
            createButtonOnClick={() => state.showSelectDrivers.setValue(true)}
            linkButtonLabel={dict("infobox.groupMembers.showAll.button.label")}
            linkButtonOnClick={() => router.showRoute(FrontendRoute.GROUP_MEMBERS(props.group?.path))}
            helpButtonLabel={dict("infobox.groupMembers.academy.button.label")}
            helpButtonOnClick={() => state.showAcademy.setValue(dict("academy.id.groups"))}>
            <ContentLayout columns={colums()} ref={ref}>
                {members?.map((groupMember: IGroupMemberData) => {
                    return <UserInfo
                        key={groupMember._id}
                        user={groupMember.user}
                        clickToShowProfile={true}
                        style="avatar"/>
                })}
            </ContentLayout>
        </Infobox>

    );

}
