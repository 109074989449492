import {FrontendView} from "./services/FrontendView"
import {FrontendState} from "./services/FrontendState"
import {FrontendAPI} from "./services/FrontendAPI"
import {registerCoreServices} from "../components/hooks/useServices"
import {FrontendDictionary} from "./services/FrontendDictionary"
import {FrontendRouter} from "./services/FrontendRouter"
import {FrontendError} from "./services/FrontendError"
import {FrontendSocket} from "./services/FrontendSocket"
import {PromisedDelay} from "@webfruits/toolbox/dist/timer/PromisedDelay"
import {FrontendSignal} from "./services/FrontendSignal"
import {FrontendSpeaker} from "./services/FrontendSpeaker"
import {StateValue} from "@webfruits/toolbox/dist/state/StateValue";
import {FrontendTime} from "./services/FrontendTime";
import {FrontendPaddleJS} from "./services/FrontendPaddleJS";

/******************************************************************
 * FrontendServices
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class FrontendServices {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _view: FrontendView
    private _states: FrontendState
    private _api: FrontendAPI
    private _dict: FrontendDictionary
    private _router: FrontendRouter
    private _error: FrontendError
    private _socket: FrontendSocket
    private _signal: FrontendSignal
    private _speaker: FrontendSpeaker
    private _time: FrontendTime
    private _paddleJS: FrontendPaddleJS

    public initState = new StateValue<boolean>(false)

    /******************************************************************
     * Constructor
     *****************************************************************/

    public async init() {
        this.view
        this.state
        this.api
        this.dict
        this.router
        this.error
        this.socket
        this.signal
        this.speaker
        this.time
        this.paddleJS
        this.initState.setValue(true)
        await PromisedDelay.waitUntilValid(() => this.socket.isConnected)
        await PromisedDelay.waitUntilValid(() => this.time.isAvailable)
        registerCoreServices(this)
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    get view(): FrontendView {
        return this._view ?? (this._view = new FrontendView(this))
    }

    get state(): FrontendState {
        return this._states ?? (this._states = new FrontendState(this))
    }

    get api(): FrontendAPI {
        return this._api ?? (this._api = new FrontendAPI(this))
    }

    get dict(): FrontendDictionary {
        return this._dict ?? (this._dict = new FrontendDictionary(this))
    }

    get router(): FrontendRouter {
        return this._router ?? (this._router = new FrontendRouter(this))
    }

    get error(): FrontendError {
        return this._error ?? (this._error = new FrontendError(this))
    }

    get socket(): FrontendSocket {
        return this._socket ?? (this._socket = new FrontendSocket(this))
    }

    get signal(): FrontendSignal {
        return this._signal ?? (this._signal = new FrontendSignal())
    }

    get speaker(): FrontendSpeaker {
        return this._speaker ?? (this._speaker = new FrontendSpeaker(this))
    }

    get time(): FrontendTime {
        return this._time ?? (this._time = new FrontendTime(this))
    }

    get paddleJS(): FrontendPaddleJS {
        return this._paddleJS ?? (this._paddleJS = new FrontendPaddleJS(this))
    }

}
