import * as React from "react";

/******************************************************************
 * SumIcon
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function SumIcon(props: {
    scale?: number
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="sum-icon">
            <svg width={10 * (props.scale ?? 14)} height={16 * (props.scale ?? 1)} viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.41624 11.1667C9.69238 11.1667 9.91624 11.3906 9.91624 11.6667V12.9497C9.91624 13.2258 9.69238 13.4497 9.41624 13.4497H0.583802C0.307659 13.4497 0.0838013 13.2258 0.0838013 12.9497V11.841C0.0838013 11.716 0.130676 11.5954 0.215177 11.5032L4.08641 7.27886C4.25824 7.09136 4.26194 6.80475 4.09502 6.61287L0.424695 2.39396C0.345526 2.30296 0.301922 2.18641 0.301922 2.06579V0.949707C0.301922 0.673565 0.52578 0.449707 0.801922 0.449707H9.28537C9.56151 0.449707 9.78537 0.673565 9.78537 0.949707V2.2327C9.78537 2.50885 9.56151 2.7327 9.28537 2.7327H5.24776C4.81737 2.7327 4.58808 3.24031 4.8726 3.56324L6.92429 5.89191C7.00474 5.98322 7.04913 6.10074 7.04913 6.22244V7.65204C7.04913 7.77997 7.00009 7.90304 6.9121 7.99591L4.70764 10.3228C4.40575 10.6415 4.63165 11.1667 5.07062 11.1667H9.41624Z" fill="white"/>
            </svg>
        </div>
    );

}
