import * as React from "react";
import {IconButton} from "../icon/IconButton";
import {useAuthUser} from "../../../hooks/useAuthUser";
import {useServices} from "../../../hooks/useServices";
import {MongoObjectIDType} from "../../../../../shared/types/MongoObjectIDType";

/******************************************************************
 * FavoriteButton
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function FavoriteButton(props: {
    favoriteID: MongoObjectIDType
    type: "group"
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {api} = useServices()
    const {authUserFavorites} = useAuthUser()

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function toggleFavorites() {
        let favorites = [...authUserFavorites]
        if (isFavorite()) {
            switch (props.type) {
                case "group":
                    const favoriteGroup = favorites?.find(fav => fav.group?._id == props.favoriteID)
                    favorites?.splice(favorites?.indexOf(favoriteGroup), 1)
                    break
            }
        } else {
            switch (props.type) {
                case "group":
                    favorites?.push({group: props.favoriteID})
                    break
            }
        }
        favorites = favorites?.filter(fav => fav.group)
        await api.user.update({favorites})
    }

    function isFavorite(): boolean {
        switch (props.type) {
            case "group":
                return !!authUserFavorites?.find(fav => fav.group?._id == props.favoriteID)
        }
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <IconButton
            type={isFavorite() ? "star" : "emptyStar"}
            iconColor={isFavorite() ? "yellow" : null}
            stopPropagation={true}
            size="small"
            onClick={toggleFavorites}/>
    );

}
