import {FrontendServices} from "../core/FrontendServices";
import {APIRoute} from "../../shared/routes/APIRoute";
import {FrontendConfig} from "../core/FrontendConfig";
import {IFileData} from "../../shared/models/IFileData";
import {MongoObjectIDType} from "../../shared/types/MongoObjectIDType";
import {FileOwnerType} from "../../shared/types/FileOwnerType";

/******************************************************************
 * FileAPI
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class FileAPI {

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(private _frontend: FrontendServices) {
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public getImageURLByFile(
        file: IFileData,
        requiredWidth?: number,
        requiredHeight?: number
    ): string {
        if (!file || !file._id) return null;
        return FrontendConfig.BASE_URL + APIRoute.FILE_IMAGE
            + "?id=" + file._id
            + "&v=" + file.cacheKiller
            + (requiredWidth ? "&w=" + Math.ceil(requiredWidth) : "")
            + (requiredHeight ? "&h=" + Math.ceil(requiredHeight) : "")
    }

    public async getFile(fileID: MongoObjectIDType): Promise<IFileData> {
        if (!fileID) return null;
        const response = await this._frontend.api.request("GET", APIRoute.FILE, {id: fileID})
        if (response.status !== 200) {
            return null
        }
        return await response.json()
    }

    public async uploadImage(args: {
        ownerID: MongoObjectIDType,
        ownerType: FileOwnerType,
        file: File
        fileID?: MongoObjectIDType,
    }): Promise<IFileData> {
        const formData = new FormData();
        formData.append("ownerID", args.ownerID);
        formData.append("ownerType", args.ownerType);
        formData.append("file", args.file);
        if (args.fileID) {
            formData.append("fileID", args.fileID);
        }
        const response = await this._frontend.api.upload(APIRoute.FILE_IMAGE, formData);
        if (response.status !== 200) {
            const errorMessage = this._frontend.error.createMessage(await response.json())
            this._frontend.state.showMessage.setValue({type: "error", message: errorMessage})
            return null
        }
        return await response.json()
    }

}
