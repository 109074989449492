import * as React from "react";
import {ReactElement, useState} from "react";
import {ITableColumnDefinition} from "../ITableColumnDefinition";
import {TableValueCell, TableValueCellStyle, TableValueCellType} from "../cells/value/TableValueCell";
import {TableDeleteCell} from "../cells/delete/TableDeleteCell";
import {TableEditableCell} from "../cells/editable/TableEditableCell";
import {TablePrivacyCell} from "../cells/privacy/TablePrivacyCell";
import {TableStateCell} from "../cells/state/TableStateCell";
import {TableLeaderboardDriverCell} from "../cells/driver/TableLeaderboardDriverCell";
import {TableLeaderboardPositionCell} from "../cells/position/TableLeaderboardPositionCell";
import {TableLeaderboardBestLapTimeCell} from "../cells/bestlaptime/TableLeaderboardBestLapTimeCell";
import {TableLeaderboardLapsCell} from "../cells/laps/TableLeaderboardLapsCell";
import {TableLeaderboardStintsCell} from "../cells/stints/TableLeaderboardStintsCell";
import {TableLeaderboardLapSectorTimesCell} from "../cells/sectortimes/TableLeaderboardLapSectorTimesCell";
import {TableLeaderboardDrivenTimeCell} from "../cells/driventime/TableLeaderboardDrivenTimeCell";
import {TableLeaderboardMedianLapTimeCell} from "../cells/mediantime/TableLeaderboardMedianLapTimeCell";
import {TableStyleType} from "../TableStyleType";
import {SessionDriverStateType} from "../../../../../shared/types/SessionDriverStateType";
import {TableLeaderboardDriverStateCell} from "../cells/driverstate/TableLeaderboardDriverStateCell";
import {TableLeaderboardEntryMenuCell} from "../cells/leaderboardentrymenu/TableLeaderboardEntryMenuCell";
import {TableLeaderboardScoreCell} from "../cells/score/TableLeaderboardScoreCell";
import {TableLeaderboardTargetHitsCell} from "../cells/targethits/TableLeaderboardTargetHitsCell";
import {TableLeaderboardStintCell} from "../cells/stint/TableLeaderboardStintCell";
import {ISessionLeaderboardEntry} from "../../../../../shared/types/ISessionLeaderboardEntry";
import {TableLeaderboardCarCell} from "../cells/car/TableLeaderboardCarCell";
import {TableLeaderboardTeamCell} from "../cells/team/TableLeaderboardTeamCell";
import {TableLeaderboardLastLapTimeCell} from "../cells/lastlaptime/TableLeaderboardLastLapTimeCell";
import {TableLeaderboardTimerCell} from "../cells/duration/TableLeaderboardTimerCell";
import {TableLeaderboardJokerLapCell} from "../cells/jokerlap/TableLeaderboardJokerLapCell";
import {TableLeaderboardPointsCell} from "../cells/points/TableLeaderboardPointsCell";
import {IScoreboardEntry} from "../../../../../shared/types/IScoreboardEntry";
import {TableScoreboardSessionsCell} from "../cells/sessions/TableScoreboardSessionsCell";
import {TableScoreboardDriverTeamCell} from "../cells/driver/TableScoreboardDriverTeamCell";
import {JsonUtils} from "@webfruits/toolbox/dist/utils/JsonUtils";
import {IStintLapData} from "../../../../../shared/types/IStintLapData";
import {TableStintLapTimeCell} from "../cells/laptime/TableStintLapTimeCell";
import {TableStintLapSectorTimesCell} from "../cells/sectortimes/TableStintLapSectorTimesCell";
import {TableStintLapFlagsCell} from "../cells/flags/TableStintLapFlagsCell";
import {IStintData} from "../../../../../shared/models/IStintData";
import {TableStintCarCell} from "../cells/car/TableStintCarCell";
import {TableStintMenuCell} from "../cells/stintmenu/TableStintMenuCell";
import {ICapiTargetData} from "../../../../../shared/types/ICapiTargetData";
import {TableStintTargetCell} from "../cells/target/TableStintTargetCell";
import {TableStintTargetMenuCell} from "../cells/target/TableStintTargetMenuCell";
import {TableDriverCell} from "../cells/driver/TableDriverCell";
import {TableLabelButtonCell} from "../cells/button/TableLabelButtonCell";
import {TableIconButtonCell} from "../cells/button/TableIconButtonCell";
import {IconType} from "../../icons/IconType";
import {TableImageCell} from "../cells/image/TableImageCell";
import {ITableCarCellProps, TableCarCell} from "../cells/car/TableCarCell";
import {IUserData} from "../../../../../shared/models/IUserData";
import {TableUserMenuCell} from "../cells/usermenu/TableUserMenuCell";

/******************************************************************
 * TableBodyRow
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function TableBodyRow<RowDataType, GlobalDataType>(props: {
    columnsDefinition: ITableColumnDefinition<RowDataType>[]
    gridTemplateColumns: string
    onRowClick?: (_id: RowDataType) => void
    rowID: number
    rowData: RowDataType
    globalData?: GlobalDataType
    style: TableStyleType
    hideLowPrioColumns: boolean
}) {

    /* ----------------------------------------------------------------
 	 * STATE
 	 * --------------------------------------------------------------*/

    const [secondaryContent, setSecondaryRow] = useState<ReactElement>(null);

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function createRowColumns(): ReactElement[] {
        return props.columnsDefinition.map((colDef: ITableColumnDefinition<RowDataType>, i) => {
            if (colDef.size === null
                || (colDef.priority == "low" && props.hideLowPrioColumns)) {
                return null;
            }
            switch (colDef.type) {
                case "empty":
                    return <div
                        key={i}
                        className="table-empty-cell"/>
                case "custom":
                    return colDef.customCell(i, props.rowData, props.rowID)
                case "row-index":
                    return <TableValueCell
                        key={i}
                        type="number"
                        style="normal"
                        align="left"
                        value={props.rowID + 1 + "."}/>
                case "text":
                case "text-bold":
                case "number":
                case "number-bold":
                    return <TableValueCell
                        key={i}
                        type={colDef.type.split("-")[0] as TableValueCellType}
                        align={colDef.align ?? "left"}
                        style={colDef.type.split("-")?.[1] as TableValueCellStyle ?? "normal"}
                        onClick={colDef.action
                            ? () => colDef.action(props.rowData)
                            : null}
                        value={colDef.value
                            ? colDef.value(props.rowData)
                            : JsonUtils.getValueFromObject(colDef.dataKey, props.rowData)}/>
                case "image":
                    return <TableImageCell
                        key={i}
                        file={JsonUtils.getValueFromObject(colDef.dataKey, props.rowData)}/>
                case "image-circle":
                    return <TableImageCell
                        key={i}
                        framing="circle"
                        file={JsonUtils.getValueFromObject(colDef.dataKey, props.rowData)}/>
                case "editable":
                    return <TableEditableCell
                        key={i}
                        text={JsonUtils.getValueFromObject(colDef.dataKey, props.rowData)}
                        action={(value: string) => {
                            return colDef.action({
                                ...props.rowData,
                                ...{[colDef.dataKey]: value}
                            })
                        }}/>
                case "label-button":
                    return <TableLabelButtonCell
                        key={i}
                        align={colDef.align ?? "left"}
                        label={colDef.value(props.rowData) as string}
                        action={() => colDef.action(props.rowData)}/>
                case "icon-button":
                    return <TableIconButtonCell
                        key={i}
                        align={colDef.align ?? "left"}
                        icon={colDef.value(props.rowData) as IconType}
                        action={() => colDef.action(props.rowData)}/>
                case "delete":
                    return <TableDeleteCell
                        key={i}
                        action={() => colDef.action(props.rowData)}/>
                case "privacy":
                    return <TablePrivacyCell
                        key={i}
                        state={JsonUtils.getValueFromObject(colDef.dataKey, props.rowData)}/>
                case "state":
                case "state-compact":
                    return <TableStateCell
                        key={i}
                        compact={colDef.type == "state-compact"}
                        align={colDef.align}
                        state={JsonUtils.getValueFromObject(colDef.dataKey, props.rowData)}/>
                case "driver":
                    return <TableDriverCell
                        key={i}
                        sticky={colDef.sticky}
                        user={colDef.value
                            ? colDef.value(props.rowData)
                            : JsonUtils.getValueFromObject(colDef.dataKey, props.rowData)}/>
                case "car":
                    const data: ITableCarCellProps = colDef.value
                        ? colDef.value(props.rowData)
                        : JsonUtils.getValueFromObject(colDef.dataKey, props.rowData)
                    return <TableCarCell
                        key={i}
                        carName={data?.carName}
                        userID={data?.userID}/>
                case "leaderboard-driver-state":
                    return <TableLeaderboardDriverStateCell
                        key={i}
                        entry={props.rowData as ISessionLeaderboardEntry}/>
                case "leaderboard-driver":
                    return <TableLeaderboardDriverCell
                        key={i}
                        entry={props.rowData as ISessionLeaderboardEntry}/>
                case "leaderboard-position":
                    return <TableLeaderboardPositionCell
                        key={i}
                        entry={props.rowData as ISessionLeaderboardEntry}/>
                case "leaderboard-best-lap-time":
                    return <TableLeaderboardBestLapTimeCell
                        key={i}
                        entry={props.rowData as ISessionLeaderboardEntry}/>
                case "leaderboard-last-lap-time":
                    return <TableLeaderboardLastLapTimeCell
                        key={i}
                        entry={props.rowData as ISessionLeaderboardEntry}/>
                case "leaderboard-avg-lap-time":
                    return <TableLeaderboardMedianLapTimeCell
                        key={i}
                        entry={props.rowData as ISessionLeaderboardEntry}/>
                case "leaderboard-lap-sector-times":
                    return <TableLeaderboardLapSectorTimesCell
                        key={i}
                        entry={props.rowData as ISessionLeaderboardEntry}/>
                case "leaderboard-laps":
                    return <TableLeaderboardLapsCell
                        key={i}
                        entry={props.rowData as ISessionLeaderboardEntry}/>
                case "leaderboard-stints":
                    return <TableLeaderboardStintsCell
                        key={i}
                        entry={props.rowData as ISessionLeaderboardEntry}/>
                case "leaderboard-stint":
                    return <TableLeaderboardStintCell
                        key={i}
                        entry={props.rowData as ISessionLeaderboardEntry}/>
                case "leaderboard-driven-time":
                    return <TableLeaderboardDrivenTimeCell
                        key={i}
                        entry={props.rowData as ISessionLeaderboardEntry}/>
                case "leaderboard-entry-menu":
                    return <TableLeaderboardEntryMenuCell
                        key={i}
                        entry={props.rowData as ISessionLeaderboardEntry}/>
                case "leaderboard-score":
                    return <TableLeaderboardScoreCell
                        key={i}
                        entry={props.rowData as ISessionLeaderboardEntry}/>
                case "leaderboard-targethits":
                    return <TableLeaderboardTargetHitsCell
                        key={i}
                        entry={props.rowData as ISessionLeaderboardEntry}/>
                case "leaderboard-car":
                    return <TableLeaderboardCarCell
                        key={i}
                        entry={props.rowData as ISessionLeaderboardEntry}/>
                case "leaderboard-team":
                    return <TableLeaderboardTeamCell
                        key={i}
                        onToggleTeamMembers={(content) => setSecondaryRow(content)}
                        entry={props.rowData as ISessionLeaderboardEntry}/>
                case "leaderboard-timer":
                    return <TableLeaderboardTimerCell
                        key={i}
                        entry={props.rowData as ISessionLeaderboardEntry}/>
                case "leaderboard-joker-lap":
                    return <TableLeaderboardJokerLapCell
                        key={i}
                        entry={props.rowData as ISessionLeaderboardEntry}/>
                case "leaderboard-points":
                    return <TableLeaderboardPointsCell
                        key={i}
                        entry={props.rowData as ISessionLeaderboardEntry}/>
                case "scoreboard-sessions":
                    return <TableScoreboardSessionsCell
                        key={i}
                        entry={props.rowData as IScoreboardEntry}/>
                case "scoreboard-driver-team":
                    return <TableScoreboardDriverTeamCell
                        key={i}
                        sticky={colDef.sticky}
                        entry={props.rowData as IScoreboardEntry}/>
                case "stint-lap-time":
                    return <TableStintLapTimeCell
                        key={i}
                        lap={props.rowData as IStintLapData}
                        stint={props.globalData as IStintData}/>
                case "stint-lap-sector-times":
                    return <TableStintLapSectorTimesCell
                        key={i}
                        lap={props.rowData as IStintLapData}/>
                case "stint-lap-flags":
                    return <TableStintLapFlagsCell
                        key={i}
                        lap={props.rowData as IStintLapData}/>
                case "stint-car":
                    return <TableStintCarCell
                        key={i}
                        stint={props.rowData as IStintData}/>
                case "stint-menu":
                    return <TableStintMenuCell
                        key={i}
                        stint={props.rowData as IStintData}/>
                case "stint-target":
                    return <TableStintTargetCell
                        key={i}
                        target={props.rowData as ICapiTargetData}/>
                case "stint-target-menu":
                    return <TableStintTargetMenuCell
                        key={i}
                        target={props.rowData as ICapiTargetData}
                        stint={props.globalData as IStintData}/>
                case "user-menu":
                    return <TableUserMenuCell
                        key={i}
                        user={props.rowData as IUserData}/>
            }
        })
    }

    function leaderboardEntryState(): SessionDriverStateType {
        switch (props.style) {
            case "leaderboard":
            case "leaderboard-compact":
                const data = props.rowData as ISessionLeaderboardEntry
                return data?.state
        }
        return null
    }

    function onRowClicked() {
        if (!props.onRowClick) return;
        props.onRowClick(props.rowData)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/


    return (
        <div className="table-body-row">
            <div
                className="table-body-row-primary"
                data-clickable={!!props.onRowClick}
                data-leaderboard-entry-state={leaderboardEntryState()}
                onClick={onRowClicked}
                style={{gridTemplateColumns: props.gridTemplateColumns}}>
                {createRowColumns()}
            </div>
            {secondaryContent && <div className="table-body-row-secondary">
                {secondaryContent}
            </div>}
        </div>
    );

}
