import * as React from "react";
import {Children, ReactElement, ReactNode, useEffect, useRef, useState} from "react";
import {SidebarButton} from "./button/SidebarButton";
import {ISplitViewEntry} from "./ISplitViewEntry";
import {IconButton} from "../buttons/icon/IconButton";
import {ActionBar} from "../bar/ActionBar";
import {Headline} from "../text/headings/Headline";
import {useMobileStyle} from "../../hooks/useMobileStyle";
import {useSwipeable} from "react-swipeable";
import {FrontendConfig} from "../../../core/FrontendConfig";
import {ContentLayout} from "../layout/content/ContentLayout";
import {Spacer} from "../utils/spacer/Spacer";

/******************************************************************
 * SplitView
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function SplitView(props: {
    showContentByLabel?: string
    title?: string
    skipTransitions?: boolean
    children?: ReactNode | ReactNode[] | ReactElement | ReactElement[] | null
    sidebarActionBar?: ReactNode
    addPaddingForContentActionBar?: boolean
    onCurrentContentLabelChanged?: (label: string) => void
    onMobileMode?: (isMobile: boolean) => void
    padding?: "none" | "modal"
    fullContentHeight?: boolean
    preventSwipeToNavigate?: boolean
}) {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const ref = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const [isMobileMode] = useMobileStyle(ref, 650)
    const swipeableHandlers = useSwipeable({
        onSwipedRight: () => {
            if (props.preventSwipeToNavigate) return
            setCurrentContentLabel(null)
        },
        preventScrollOnSwipe: false,
        trackTouch: true,
        trackMouse: false,
        delta: FrontendConfig.SWIPE_DELTA,
        swipeDuration: FrontendConfig.SWIPE_DURATION
    });

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [firstLabel, setFirstLabel] = useState<string | null>(null)
    const [currentContent, setCurrentContent] = useState<ReactElement | null>(null)
    const [currentContentLabel, setCurrentContentLabel] = useState<string | null>(null)

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        const label = props.showContentByLabel ?? Children.toArray(props.children)[0]?.['props']?.label;
        setFirstLabel(isMobileMode ? null : label)
    }, [props.children, isMobileMode])

    useEffect(() => {
        const label = props.showContentByLabel ?? firstLabel;
        setCurrentContentLabel(label)
    }, [props.showContentByLabel, firstLabel])

    useEffect(() => {
        if (!currentContentLabel) {
            setCurrentContent(null)
            return
        }
        Children.forEach(props.children, (content: ReactElement) => {
            const entryProp: ISplitViewEntry = content?.props;
            if (!entryProp) return
            if (entryProp.label == currentContentLabel) {
                setCurrentContent(content)
            }
        })
        props.onCurrentContentLabelChanged?.(currentContentLabel)
    }, [currentContentLabel])

    useEffect(() => {
        props.onMobileMode?.(isMobileMode)
    }, [isMobileMode])

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            ref={ref}
            className="splitview"
            data-skip-transitions={props.skipTransitions}
            data-mobile-mode={isMobileMode}
            data-padding={props.padding}
            data-add-padding-for-sidebar-action-bar={!!props.sidebarActionBar}
            data-add-padding-for-content-action-bar={props.addPaddingForContentActionBar || isMobileMode}
            data-show-content={!!currentContentLabel}>
            <div className="splitview-body">
                <div className="splitview-sidebar">
                    {props.title &&
                        <ContentLayout>
                            <Headline
                                text={props.title}
                                style="modal"/>
                        </ContentLayout>}
                    <nav>
                        {Children.map(props.children, (child) => {
                            const entryProps: ISplitViewEntry = child?.['props']
                            if (entryProps?.label) {
                                return <SidebarButton
                                    key={entryProps.label}
                                    label={entryProps.label}
                                    useMobileStyle={isMobileMode}
                                    badgeLabel={entryProps.badgeLabel}
                                    badgeColor={entryProps.badgeColor as "red" | "white"}
                                    selected={isMobileMode ? false : currentContentLabel == entryProps.label}
                                    onClick={setCurrentContentLabel}/>
                            }
                            return child
                        })}
                        <Spacer height="extra-large"/>
                    </nav>
                    {props.sidebarActionBar}
                </div>
                <div
                    className="splitview-main"
                    data-full-height={props.fullContentHeight}
                     {...swipeableHandlers}>
                    {isMobileMode && currentContentLabel &&
                        <Headline
                            text={currentContentLabel}
                            align="center"
                            style="h5"/>}
                    {currentContent}
                </div>
            </div>
            {isMobileMode &&
                <ActionBar location="splitview-back">
                    <IconButton
                        type="prev"
                        size="small"
                        onClick={() => setCurrentContentLabel(null)}/>
                </ActionBar>}
        </div>
    );

}
