import * as React from "react";
import {useEffect, useState} from "react";
import {Modal} from "../../Modal";
import {useServices} from "../../../../hooks/useServices";
import {Headline} from "../../../../ui/text/headings/Headline";
import {usePaddleJS} from "../../../../hooks/usePaddleJS";
import {CheckoutCustomer} from "@paddle/paddle-js/types/checkout/customer";
import {useAuthUser} from "../../../../hooks/useAuthUser";
import {ContentLayout} from "../../../../ui/layout/content/ContentLayout";
import {InfoText} from "../../../../ui/text/infotext/InfoText";
import {ComponentInitializer} from "../../../../ui/utils/init/ComponentInitializer";
import {PaddleSandboxModeInfo} from "../../../../ui/info/sandbox/PaddleSandboxModeInfo";

/******************************************************************
 * SubscriptionUpdatePaymentMethodeModal
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function SubscriptionUpdatePaymentMethodeModal() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {authUserExists, authUserMail, authUserPaddleCustomerID} = useAuthUser()
    const {dict, state, api, language} = useServices()
    const {checkoutData, paddleAvailable} = usePaddleJS()

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [errorMessage, setErrorMessage] = useState<string>()

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        if (!authUserExists) {
            setErrorMessage(dict("plan.checkout.no.user.found.error"))
            return
        }
        if (!paddleAvailable) return
        init()
    }, [paddleAvailable])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function init() {
        const transactionID = await api.paddle.getSubscriptionTransactionID(true)
        if (!transactionID) {
            setErrorMessage(dict("plan.update.paymentmethode.transaction.id.missing"))
            return
        }
        initCheckout(transactionID)
    }

    function initCheckout(transactionID: string) {
        let customer: CheckoutCustomer
        if (authUserPaddleCustomerID) customer = {id: authUserPaddleCustomerID}
        else customer = {email: authUserMail}
        window.Paddle.Checkout.open({
            settings: {
                displayMode: "inline",
                theme: "dark",
                locale: language,
                showAddTaxId: false,
                allowLogout: false,
                showAddDiscounts: false,
                frameTarget: "paddle-checkout-container",
                frameStyle: "position: static; width: 100%; min-width: 312px; background-color: transparent; border: none;"
            },
            transactionId: transactionID,
            customer: customer,
        });
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal
            className="subscription-update-paymentmethode-modal"
            width="medium"
            closeAction={() => state.showSubscriptionUpdatePaymentMethod.setValue(false)}>
            <Headline
                text={dict("plan.update.paymentmethode.title")}
                style="modal"/>
            <ComponentInitializer isPropertyAvailable={!!checkoutData || !!errorMessage}>
                {errorMessage &&
                    <ContentLayout justifyItems="center">
                        <InfoText
                            text={errorMessage}
                            align="center"
                            style="error"/>
                    </ContentLayout>}
                {!errorMessage &&
                    <ContentLayout>
                        <PaddleSandboxModeInfo/>
                        <ContentLayout
                            framed={true}
                            className="paddle-checkout-container"/>
                    </ContentLayout>}
            </ComponentInitializer>
        </Modal>
    );

}
