import * as React from "react";

/******************************************************************
 * FinishTargetIcon
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function FinishTargetIcon(props: {
    scale?: number
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="finish-target-icon">
            <svg width={22 * (props.scale ?? 1)} height={19 * (props.scale ?? 1)} viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="11" y="6.94971" width="3" height="3" fill="white"/>
                <rect x="2" y="9.94971" width="3" height="3" fill="white"/>
                <rect x="14" y="9.94971" width="3" height="3" fill="white"/>
                <rect x="5" y="6.94971" width="3" height="3" fill="white"/>
                <rect x="17" y="6.94971" width="3" height="3" fill="white"/>
                <rect x="8" y="9.94971" width="3" height="3" fill="white"/>
            </svg>
        </div>
    );

}
