import * as React from "react";

/******************************************************************
 * DateIcon
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function DateIcon(props: {
    scale?: number
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="date-icon">
            <svg width={14 * (props.scale ?? 1)} height={15 * (props.scale ?? 1)} viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 9H4V11H14V9ZM16 2H15V0H13V2H5V0H3V2H2C0.895 2 0.00999999 2.895 0.00999999 4L0 18C0 19.105 0.895 20 2 20H16C17.105 20 18 19.105 18 18V4C18 2.895 17.105 2 16 2ZM16 18H2V7H16V18ZM11 13H4V15H11V13Z" fill="white"/>
            </svg>
        </div>
    );

}
