import * as React from "react";
import {InlineDeleteAction} from "../../../form/inlineaction/delete/InlineDeleteAction";
import {useState} from "react";

/******************************************************************
 * TableDeleteCell
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function TableDeleteCell(props: {
    action: () => Promise<Response>
}) {

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [isBusy, setIsBusy] = useState<boolean>(false)

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    async function deleteAction() {
        setIsBusy(true)
        await props.action()
        setIsBusy(false)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="table-delete-cell">
            <InlineDeleteAction isBusy={isBusy} onSave={deleteAction} />
        </div>
    );

}
