import * as React from "react";
import {IStintData} from "../../../../../../../shared/models/IStintData";
import {LabelValueText} from "../../../../../ui/text/labelvalue/LabelValueText";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {useServices} from "../../../../../hooks/useServices";
import {DriftUtils} from "../../../../../../../shared/utils/DriftUtils";

/******************************************************************
 * StintDeviceInfo
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function StintDeviceInfo(props: {
    stint: IStintData
}) {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {dict} = useServices()

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            className="stint-device-info"
            framed={true}
            gap="small">
            <LabelValueText
                size="small"
                label={dict("drift.assistent")}
                value={dict("drift.assistent." + props.stint.enterData.drift_assistant)}/>
            <LabelValueText
                size="small"
                label={dict("drift.softSteering")}
                value={dict("drift.softSteering." + props.stint.enterData.soft_steering)}/>
            <LabelValueText
                size="small"
                label={dict("drift.steeringAngle")}
                value={props.stint.enterData.steering_angle?.toString()}/>
            {props.stint.device &&
                <LabelValueText
                    size="small"
                    label={dict("drift.controlDevice")}
                    value={dict("drift.controlDevice." + props.stint.device)}/>}
        </ContentLayout>
    );
}
