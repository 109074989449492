import {SelectInputOptionType} from "../components/ui/form/elements/select/SelectInput";
import {CountryValidation} from "../../shared/validation/CountryValidation";
import {CountryCodeType} from "../../shared/types/CountryCodeType";
import {ZipCodeValidation} from "../../shared/validation/ZipCodeValidation";
import {ILocationData} from "../../shared/types/ILocationData";
import {LanguageType} from "../../shared/types/LanguageType";
import {LanguageUtils} from "../../shared/utils/LanguageUtils";

/******************************************************************
 * LocationUtils
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class LocationUtils {

    static mapLocationString(dictString: string, location: ILocationData, language: LanguageType): string {
        if (!location) return ""
        return dictString
            .replaceAll("{PLACE}", LanguageUtils.getValueFromMultilangString(location?.place, language))
            .replaceAll("{COUNTRY}", LanguageUtils.getValueFromMultilangString(location?.country, language))
    }

    static countryOptions(dict: (key: string) => string): SelectInputOptionType[] {
        return CountryValidation.CODES.map(code => {
            return {
                value: code,
                text: dict("country." + code)
            }
        })
    }

    static zipRegex(country: CountryCodeType): RegExp {
        switch (country) {
            case "de":
                return ZipCodeValidation.REGEXP_DE
            case "at":
                return ZipCodeValidation.REGEXP_AT
            case "ch":
                return ZipCodeValidation.REGEXP_CH
        }
        return null
    }

}
