import * as React from "react";
import {useState} from "react";
import {useEventState} from "../../../../../hooks/useEventState";
import {useServices} from "../../../../../hooks/useServices";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {DriverList} from "../../../../../ui/list/driver/DriverList";
import {IUserData} from "../../../../../../../shared/models/IUserData";
import {Headline} from "../../../../../ui/text/headings/Headline";
import {LabelButton} from "../../../../../ui/buttons/label/LabelButton";
import {Input} from "../../../../../ui/form/elements/input/Input";
import {SelectInput} from "../../../../../ui/form/elements/select/SelectInput";
import {ITabbedContentChildProps} from "../../../../../ui/layout/tabbed/ITabbedContentChildProps";
import {useGroupState} from "../../../../../hooks/useGroupState";
import {MongoObjectIDType} from "../../../../../../../shared/types/MongoObjectIDType";
import {ConfirmationMailModal} from "../modals/ConfirmationMailModal";
import {Divider} from "../../../../../ui/utils/divider/Divider";
import {ScrollBox} from "../../../../../ui/utils/scrollbox/ScrollBox";

/******************************************************************
 * EventDriversList
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export type EventDriverListType = "requesting" | "pending" | "registered"

export function EventDriversList(props: {
    tabbedContent?: ITabbedContentChildProps
    type: EventDriverListType
    progressing?: boolean
    onRemoveClicked?: (userID: MongoObjectIDType) => void
    onAddToNextClicked?: (userID: MongoObjectIDType) => void
    onContactClicked?: (userID: MongoObjectIDType) => void
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, state, api} = useServices()
    const {
        eventID,
        eventAccess,
        eventEntryFee,
        eventMaxDrivers,
        eventIsFinished,
        eventRequestingDrivers,
        eventRegisteredDrivers,
        eventPendingDrivers,
        eventEntryFeeMessage,
        eventRegisteredMessage,
        eventRequestsForbidden
    } = useEventState()
    const {isGroupAdmin} = useGroupState()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [showConfirmationMail, setShowConfirmationMail] = useState<boolean>(false)
    const [confirmationMessage, setConfirmationMessage] = useState<string>(message)
    const [savingEntryFee, setSavingEntryFee] = useState<boolean>(false)
    const [savingRequestsForbidden, setSavingRequestsForbidden] = useState<boolean>(false)
    const [savingMaxDrivers, setSavingMaxDrivers] = useState<boolean>(false)

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function label(): string {
        switch (props.type) {
            case "requesting":
                return dict("event.drivers.requesting.label") + " (" + (eventRequestingDrivers?.length ?? 0) + ")"
            case "pending":
                return dict("event.drivers.pending.label") + " (" + (eventPendingDrivers?.length ?? 0) + ")"
            case "registered":
                return dict("event.drivers.registered.label") + " (" + (eventRegisteredDrivers?.length ?? 0) + ")"
        }
    }

    function emtpyInfo(): string {
        switch (props.type) {
            case "requesting":
                return dict("event.drivers.requesting.empty.info")
            case "pending":
                return dict("event.drivers.pending.empty.info")
            case "registered":
                return dict("event.drivers.registered.empty.info")
        }
    }

    function listUsers(): IUserData[] {
        switch (props.type) {
            case "requesting":
                return eventRequestingDrivers ?? []
            case "pending":
                return eventPendingDrivers ?? []
            case "registered":
                return eventRegisteredDrivers ?? []
        }
    }

    function hasMessage(): boolean {
        switch (props.type) {
            case "pending":
                return eventEntryFeeMessage?.length > 10
            case "registered":
                return eventRegisteredMessage?.length > 10 || confirmationMessage?.length > 10
        }
        return false
    }

    function defaultMessage(): string {
        switch (props.type) {
            case "pending":
                return dict("event.driver.pending.mail.text")
            case "registered":
                return dict("event.driver.registered.mail.text")
        }
        return ""
    }

    function message(): string {
        switch (props.type) {
            case "pending":
                return eventEntryFeeMessage ?? defaultMessage()
            case "registered":
                return eventRegisteredMessage ?? defaultMessage()
        }
        return ""
    }

    async function saveEntryFee(value: string): Promise<Response> {
        setSavingEntryFee(true)
        await api.event.update(eventID, {drivers: {entryFee: value}})
        setSavingEntryFee(false)
        return null
    }

    async function saveRequestsForbidden(value: "yes" | "no") {
        setSavingRequestsForbidden(true)
        await api.event.update(eventID, {drivers: {requestsForbidden: value === "yes"}})
        setSavingRequestsForbidden(false)
    }

    async function saveMaxDrivers(value: string): Promise<Response> {
        setSavingMaxDrivers(true)
        await api.event.update(eventID, {drivers: {maxDrivers: parseInt(value)}})
        setSavingMaxDrivers(false)
        return null
    }

    function showContactModalToMessageAll() {
        state.showContact.setValue({
            sendMessageTo: listUsers(),
            messageType: "users"
        })
    }

    function listSettingsColumns() {
        const messageButtonColumn = listUsers()?.length > 0 ? " min-content min-content" : ""
        switch (props.type) {
            case "requesting":
                return "240px min-content 180px" + messageButtonColumn
            case "pending":
                return "230px min-content max-content" + messageButtonColumn
            case "registered":
                return "min-content" + messageButtonColumn
        }
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return <>
        <ContentLayout
            framed={true}
            className="event-drivers-list"
            alignSelf="start"
            alignContent="start"
            rowTemplate={eventIsFinished ? null : "min-content min-content min-content"}
            minHeight={360}
            gap="small">
            <ContentLayout
                className="event-driver-list-header"
                columnTemplate={eventIsFinished ? null : "1fr min-content"}
                gap="small"
                justifyContent="space-between">
                <Headline
                    text={label()}
                    style="h5-underlined"/>
                {isGroupAdmin && !eventIsFinished &&
                    <ContentLayout
                        gap={"small"}
                        columnTemplate={props.type == "registered" ? "min-content min-content" : null}>
                        {props.type == "registered" &&
                            <LabelButton
                                label={"+"}
                                style="primary-small"
                                onClick={() => state.showSelectDrivers.setValue(true)}/>}
                        <LabelButton
                            label={"?"}
                            style="secondary-small"
                            onClick={() => state.showHelp.setValue(
                                "event.participation." + props.type + (props.type == "registered" && eventAccess == "only-registered-drivers-after-paid" ? ".paid" : "")
                            )}/>
                    </ContentLayout>}
            </ContentLayout>
            {isGroupAdmin && !eventIsFinished &&
                <ScrollBox scrollX={true} scrollY={false} useFadeOut={true}>
                    <ContentLayout
                        className="event-driver-list-settings"
                        alignItems="center"
                        justifyItems="start"
                        minHeight={40}
                        gap="small"
                        columnTemplate={listSettingsColumns()}
                        framed={false}>
                        {props.type == "requesting" && <>
                            <Input
                                size="small"
                                inline={true}
                                type="number"
                                minNumber={0}
                                maxNumber={1000}
                                processing={savingMaxDrivers}
                                placeholder={dict("event.maxDrivers.placeholder")}
                                defaultValue={eventMaxDrivers?.toString() ?? null}
                                label={dict("event.maxDrivers.label")}
                                action={saveMaxDrivers}/>
                            <Divider style="action-bar"/>
                            <SelectInput
                                size="small"
                                defaultValue={eventRequestsForbidden ? "yes" : "no"}
                                options={[
                                    {value: "yes", text: dict("event.drivers.requestForbidden.true")},
                                    {value: "no", text: dict("event.drivers.requestForbidden.false")}
                                ]}
                                processing={savingRequestsForbidden}
                                onChange={saveRequestsForbidden}/>
                        </>}
                        {props.type == "pending" && <>
                            <Input
                                size="small"
                                inline={true}
                                type="text"
                                minNumber={0}
                                maxNumber={100}
                                processing={savingEntryFee}
                                defaultValue={eventEntryFee}
                                label={dict("event.entryfee.label")}
                                action={saveEntryFee}/>
                            <Divider style="action-bar"/>
                        </>}
                        {props.type != "requesting" &&
                            <LabelButton
                                label={dict("event.drivers.list.message.edit.label")}
                                style={hasMessage()
                                    ? "secondary-small"
                                    : "primary-small"}
                                onClick={() => setShowConfirmationMail(true)}/>}
                        {listUsers()?.length > 0 && <>
                            <Divider style="action-bar"/>
                            <LabelButton
                                label={dict("event.drivers.list.message.toAll.label")}
                                style="secondary-small"
                                onClick={showContactModalToMessageAll}/>
                        </>}
                    </ContentLayout>
                </ScrollBox>}
            <DriverList
                data={listUsers()}
                primaryIconType="mail"
                secondaryIconType="cancel"
                tertiaryIconType="add"
                size="small"
                isBusy={props.progressing}
                userClickable={true}
                emptyDataInfo={emtpyInfo()}
                visibleRows={25}
                onPrimaryIconClick={isGroupAdmin ? props.onContactClicked : null}
                onSecondaryIconClick={isGroupAdmin ? props.onRemoveClicked : null}
                onTertiaryIconClick={isGroupAdmin ? props.onAddToNextClicked : null}/>
        </ContentLayout>
        {showConfirmationMail &&
            <ConfirmationMailModal
                type={props.type}
                message={confirmationMessage}
                defaultMessage={message()}
                onMessageChange={setConfirmationMessage}
                onRequestClose={() => setShowConfirmationMail(false)}/>}
    </>


}
