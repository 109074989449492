import {IInvalidEnterProp} from "../../shared/types/IInvalidEnterProp";

/******************************************************************
 * InvalidSetupPropsUtils
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class InvalidSetupPropsUtils {

    static getPropDictKey(prop: IInvalidEnterProp): string {
        switch (prop.name) {
            case "app_version":
                return "drift.appVersion"
            case "game_mode":
                return "drift.gameMode"
            case "start_time":
                return "drift.startTime"
            case "lap_count":
                return "drift.lapCount"
            case "track_condition":
                return "drift.trackCondition"
            case "track_bundle":
                return "drift.trackBundle"
            case "wheels":
                return "drift.tires"
            case "setup_mode":
                return "drift.carsetup"
            case "engine_type":
                return "drift.engine"
            case "tuning_type":
                return "drift.tuning"
            case "car_name":
                return null
        }
        return null
    }

}
