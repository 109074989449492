import {FrontendServices} from "../FrontendServices"
import {IKeyValuePair} from "../../../shared/types/IKeyValuePair"
import {FrontendConfig} from "../FrontendConfig"
import {UserAPI} from "../../api/UserAPI"
import {DictionaryAPI} from "../../api/DictionaryAPI"
import {FileAPI} from "../../api/FileAPI"
import {ObjectUtils} from "../../../shared/utils/ObjectUtils"
import {RequestMethodeType} from "../../../shared/types/RequestMethodeType"
import {EventAPI} from "../../api/EventAPI"
import {SessionAPI} from "../../api/SessionAPI"
import {TrackAPI} from "../../api/TrackAPI"
import {GroupAPI} from "../../api/GroupAPI"
import {CarAPI} from "../../api/CarAPI"
import {LeaderboardAPI} from "../../api/LeaderboardAPI"
import {ResultAPI} from "../../api/ResultAPI"
import {StintAPI} from "../../api/StintAPI";
import {SettingsAPI} from "../../api/SettingsAPI";
import {InfoAPI} from "../../api/InfoAPI";
import {AdminAPI} from "../../api/AdminAPI";
import {LogAPI} from "../../api/LogAPI";
import {PresetsAPI} from "../../api/PresetsAPI";
import {ProxyAPI} from "../../api/ProxyAPI";
import {AcademyAPI} from "../../api/AcademyAPI";
import {ArticleAPI} from "../../api/ArticleAPI";
import {PaddleAPI} from "../../api/PaddleAPI";
import {TranslateAPI} from "../../api/TranslateAPI";
import {ContactAPI} from "../../api/ContactAPI";
import {MailAPI} from "../../api/MailAPI";

/******************************************************************
 * FrontendAPI
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class FrontendAPI {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _user: UserAPI
    private _group: GroupAPI
    private _dict: DictionaryAPI
    private _file: FileAPI
    private _event: EventAPI
    private _session: SessionAPI
    private _leaderboard: LeaderboardAPI
    private _stint: StintAPI
    private _track: TrackAPI
    private _car: CarAPI
    private _result: ResultAPI;
    private _settings: SettingsAPI;
    private _info: InfoAPI;
    private _admin: AdminAPI;
    private _log: LogAPI;
    private _presets: PresetsAPI;
    private _proxy: ProxyAPI;
    private _academy: AcademyAPI;
    private _article: ArticleAPI;
    private _paddle: PaddleAPI
    private _translate: TranslateAPI
    private _contact: ContactAPI
    private _mail: MailAPI

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(private _frontend: FrontendServices) {
        this.initAPIs()
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    get settings(): SettingsAPI {
        return this._settings;
    }

    get mail(): MailAPI {
        return this._mail;
    }

    get user(): UserAPI {
        return this._user
    }

    get group(): GroupAPI {
        return this._group
    }

    get dict(): DictionaryAPI {
        return this._dict
    }

    get file(): FileAPI {
        return this._file
    }

    get event(): EventAPI {
        return this._event
    }

    get session(): SessionAPI {
        return this._session
    }

    get track(): TrackAPI {
        return this._track
    }

    get car(): CarAPI {
        return this._car
    }

    get stint(): StintAPI {
        return this._stint;
    }

    get leaderboard(): LeaderboardAPI {
        return this._leaderboard
    }

    get result(): ResultAPI {
        return this._result;
    }

    get info(): InfoAPI {
        return this._info;
    }

    get admin(): AdminAPI {
        return this._admin;
    }

    get log(): LogAPI {
        return this._log;
    }

    get presets(): PresetsAPI {
        return this._presets;
    }

    get proxy(): ProxyAPI {
        return this._proxy;
    }

    get academy(): AcademyAPI {
        return this._academy;
    }

    get article(): ArticleAPI {
        return this._article;
    }

    get paddle(): PaddleAPI {
        return this._paddle;
    }

    get translate(): TranslateAPI {
        return this._translate;
    }

    get contact(): ContactAPI {
        return this._contact;
    }

    public async request(
        methode: RequestMethodeType,
        route: string,
        data?: IKeyValuePair,
        useAuthorization: boolean = false,
        abortController?: AbortController
    ): Promise<Response> {
        const query = this.getQuery(methode, data)
        try {
            return await fetch(FrontendConfig.BASE_URL + route + query, {
                method: methode,
                headers: this.getHeaders(data, useAuthorization),
                body: this.getBodyData(methode, data),
                signal: abortController?.signal
            })
        } catch (error) {
            return null
        }
    }

    public async upload(route: string, formData: FormData): Promise<Response> {
        return new Promise<Response>((resolve: (value) => void) => {
            const xhr = new XMLHttpRequest()
            xhr.withCredentials = true
            xhr.addEventListener("readystatechange", function () {
                if (this.readyState === 4) {
                    resolve(new Response(this.response, {
                        status: this.status,
                        statusText: this.statusText,
                    }))
                }
            })
            xhr.open("POST", FrontendConfig.BASE_URL + route)
            xhr.setRequestHeader("Authorization", this.getToken())
            xhr.send(formData)
        })
    }

    public removeTokenFromLocalStorage() {
        localStorage.removeItem("token")
    }

    public saveToken(token: string) {
        localStorage.setItem("token", token)
    }

    public getToken(): string {
        return localStorage.getItem("token")
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initAPIs() {
        this._user = new UserAPI(this._frontend)
        this._group = new GroupAPI(this._frontend)
        this._dict = new DictionaryAPI(this._frontend)
        this._file = new FileAPI(this._frontend)
        this._event = new EventAPI(this._frontend)
        this._session = new SessionAPI(this._frontend)
        this._result = new ResultAPI(this._frontend)
        this._track = new TrackAPI(this._frontend)
        this._car = new CarAPI(this._frontend)
        this._leaderboard = new LeaderboardAPI(this._frontend)
        this._stint = new StintAPI(this._frontend)
        this._settings = new SettingsAPI(this._frontend)
        this._info = new InfoAPI(this._frontend)
        this._admin = new AdminAPI(this._frontend)
        this._log = new LogAPI(this._frontend)
        this._presets = new PresetsAPI(this._frontend)
        this._proxy = new ProxyAPI(this._frontend)
        this._academy = new AcademyAPI(this._frontend)
        this._article = new ArticleAPI(this._frontend)
        this._paddle = new PaddleAPI(this._frontend)
        this._translate = new TranslateAPI(this._frontend)
        this._contact = new ContactAPI(this._frontend)
        this._mail = new MailAPI(this._frontend)
    }

    private getHeaders(data: IKeyValuePair, useAuthorization: boolean): Headers {
        const headers = new Headers()
        if (ObjectUtils.hasNestedObject(data)) {
            headers.append('Content-Type', 'application/json')
        } else {
            headers.append('Content-Type', 'application/x-www-form-urlencoded')
        }
        if (useAuthorization) {
            headers.append('Authorization', this.getToken())
        }
        return headers
    }

    private getBodyData(methode: RequestMethodeType, data: IKeyValuePair): string {
        if (!data) return null
        if (methode == "GET") return null
        if (ObjectUtils.hasNestedObject(data)) {
            return JSON.stringify(data)
        } else {
            return new URLSearchParams(data).toString()
        }
    }

    private getQuery(methode: RequestMethodeType, data: IKeyValuePair): string {
        if (!data) return ""
        if (methode != "GET") return ""
        return "?" + new URLSearchParams(data).toString()
    }

}
