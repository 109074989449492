import * as React from "react";
import {ProgressingAnimation} from "../../utils/progress/ProgressingAnimation";
import {IconType} from "../../icons/IconType";
import {Icon} from "../../icons/Icon";
import {IconButton} from "../icon/IconButton";
import {Badge} from "../../info/badge/Badge";
import {BadgeColorType} from "../../info/badge/BadgeColorType";

/******************************************************************
 * LabelButton
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export type LabelButtonStyleType =
    | "main-menu"
    | "main-menu-selected"
    | "main-hamburger-menu"
    | "sidebar"
    | "link"
    | "small-link"
    | "tabbar"
    | "tabbar-selected"
    | "tabbar-small"
    | "tabbar-small-selected"
    | "primary"
    | "secondary"
    | "primary-small"
    | "secondary-small"
    | "primary-tiny"
    | "tertiary-tiny"
    | "action-main"
    | "action-main-link"
    | "action-main-link-selected"

export function LabelButton(props: {
    label: string
    icon?: IconType
    type?: "button" | "submit"
    badgeLabel?: string
    badgeColor?: BadgeColorType
    className?: string
    iconScale?: number,
    disabled?: boolean
    onClick?: (e) => void
    onIconClick?: () => void
    onRightClick?: () => void
    selected?: boolean
    progressing?: boolean
    style: LabelButtonStyleType
}) {

    /* ----------------------------------------------------------------
 	 * EVENTS
 	 * --------------------------------------------------------------*/

    function onRightClick(e) {
        if (props.onRightClick) {
            e.preventDefault();
            props.onRightClick();
        }
    }

    function onClick(e) {
        props.onClick?.(e)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <button
            tabIndex={-1}
            type={props.type ?? "button"}
            className={"label-button" + (props.className ? " " + props.className : "")}
            data-style={props.style}
            data-selected={props.selected}
            data-has-icon={!!props.icon}
            data-has-no-label={!props.label}
            data-has-badge={!!props.badgeLabel}
            data-progressing={props.progressing ?? false}
            disabled={props.disabled ?? false}
            onContextMenu={onRightClick}
            onClick={onClick}>
            {props.icon && props.onIconClick &&
                <IconButton
                    type={props.icon}
                    scale={props.iconScale ?? 1}
                    stopPropagation={true}
                    opacityMode="none"
                    onClick={props.onIconClick}/>}
            {props.icon && !props.onIconClick &&
                <Icon
                    type={props.icon}
                    scale={props.iconScale}/>}
            {props.label &&
                <div className="label-button-text">
                    {props.label}
                </div>}
            {props.badgeLabel &&
                <Badge
                    label={props.badgeLabel}
                    size="small"
                    color={props.badgeColor ?? "white"}/>}
            {props.progressing
                ? <ProgressingAnimation/>
                : null}
        </button>
    );

}
