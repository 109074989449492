import * as React from "react";
import {useEffect, useState} from "react";
import {useElementSize} from "./useElementSize";

/******************************************************************
 * useMobileStyle
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function useMobileStyle(
    ref: React.RefObject<HTMLElement>,
    belowWidth: number,
) {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const [availableWidth] = useElementSize(ref)

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [isMobile, setIsMobile] = useState<boolean>(availableWidth < belowWidth)
    const [isMobileInfoAvailable, setIsMobileInfoAvailable] = useState<boolean>(!!availableWidth)

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        setIsMobile(availableWidth < belowWidth)
        setIsMobileInfoAvailable(!!availableWidth)
    }, [availableWidth, belowWidth, ref?.current])

    /* ----------------------------------------------------------------
     * RETURN
     * --------------------------------------------------------------*/

    return [isMobile, isMobileInfoAvailable]

}
