import * as React from "react";
import {useRouteStates} from "../../../hooks/useRouteStates";
import {GroupMembersPage} from "./members/GroupMembersPage";
import {TracksPage} from "../tracks/TracksPage";
import {ContentLayout} from "../../../ui/layout/content/ContentLayout";
import {GroupEventsPage} from "./events/GroupEventsPage";
import {GroupHomePage} from "./home/GroupHomePage";

/******************************************************************
 * GroupMain
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function GroupMain() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {
        showGroupHome,
        showGroupMembers,
        showGroupTracks,
        showGroupEvents} = useRouteStates();

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout className="group-main" alignItems="stretch" gap="none">
            {showGroupHome && <GroupHomePage/>}
            {showGroupMembers && <GroupMembersPage/>}
            {showGroupTracks && <TracksPage/>}
            {showGroupEvents && <GroupEventsPage/>}
        </ContentLayout>
    );

}
