import * as React from "react";
import {useState} from "react";
import {ContextMenuLabel} from "../elements/ContextMenuLabel";
import {ContextMenuDivider} from "../elements/ContextMenuDivider";
import {ContextMenu} from "../ContextMenu";
import {useServices} from "../../../hooks/useServices";
import {useDeviceInfo} from "../../../hooks/useDeviceInfo";
import {FrontendConfig} from "../../../../core/FrontendConfig";
import {KeyController} from "../../keyboard/KeyController";

/******************************************************************
 * EventAddChildrenContextMenu
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function EventAddChildrenContextMenu(props: {
    showAddResult?: boolean
    showAddFolder?: boolean
}) {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {dict, state} = useServices();
    const {hasTouch} = useDeviceInfo()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [showAddResult] = useState<boolean>(props.showAddResult ?? true)
    const [showAddFolder] = useState<boolean>(props.showAddFolder ?? true)

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    async function addSession() {
        state.showCreateSession.setValue(true);
    }

    async function addResult() {
        state.showResultSetup.setValue(true);
    }

    async function addFolder() {
        state.showCreateFolder.setValue(true);
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return <>
        <ContextMenu
            type="icon"
            iconType="add"
            iconSize="small"
            iconScale={1}
            iconOpacityMode="none" enabled={true}>
            <ContextMenuLabel
                label={dict("event.session.new")}
                stateIconType="add"
                stateIconScale={0.4}
                keyShortcut={hasTouch ? null : FrontendConfig.SHORTCUT_EVENT_CREATE_SESSION}
                onClick={addSession}
                icon="flag"/>
            {showAddResult && <>
                <ContextMenuDivider style="small"/>
                <ContextMenuLabel
                    label={dict("event.result.new")}
                    stateIconType="add"
                    stateIconScale={0.4}
                    keyShortcut={hasTouch ? null : FrontendConfig.SHORTCUT_EVENT_CREATE_RESULT}
                    onClick={addResult}
                    icon="trophy"/>
            </>}
            {showAddFolder && <>
                <ContextMenuDivider style="small"/>
                <ContextMenuLabel
                    label={dict("event.folder.new")}
                    stateIconType="add"
                    stateIconScale={0.4}
                    keyShortcut={hasTouch ? null : FrontendConfig.SHORTCUT_EVENT_CREATE_FOLDER}
                    onClick={addFolder}
                    icon="folder"/></>}
        </ContextMenu>
        <KeyController
            enabled={true}
            preventOnInputElement={true}
            shortcut={FrontendConfig.SHORTCUT_EVENT_CREATE_SESSION}
            onKey={addSession}/>
        <KeyController
            enabled={true}
            preventOnInputElement={true}
            shortcut={FrontendConfig.SHORTCUT_EVENT_CREATE_RESULT}
            onKey={addResult}/>
        <KeyController
            enabled={true}
            preventOnInputElement={true}
            shortcut={FrontendConfig.SHORTCUT_EVENT_CREATE_FOLDER}
            onKey={addFolder}/>
    </>

}
