import * as React from "react";
import {IStintData} from "../../../../../../../shared/models/IStintData";
import {useServices} from "../../../../../hooks/useServices";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {Input} from "../../../../../ui/form/elements/input/Input";
import {YouTubeURLValidation} from "../../../../../../../shared/validation/YouTubeURLValidation";
import {useRouteOwner} from "../../../../../hooks/useRouteOwner";
import {useGroupState} from "../../../../../hooks/useGroupState";
import {useAuthUser} from "../../../../../hooks/useAuthUser";
import {Headline} from "../../../../../ui/text/headings/Headline";
import {YoutubeThumbnailLink} from "../../../../../ui/image/youtube/YoutubeThumbnailLink";
import {YouTubeUtils} from "../../../../../../utils/YouTubeUtils";
import {useSessionState} from "../../../../../hooks/useSessionState";

/******************************************************************
 * StintVideoProof
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function StintVideoProof(props: {
    stint: IStintData
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, api} = useServices()
    const {authUserID} = useAuthUser()
    const {isRouteOwnerAuthUser} = useRouteOwner()
    const {isGroupAdmin} = useGroupState()
    const {sessionIsFinished} = useSessionState()

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function updateYoutubeURL(youtubeURL: string): Promise<Response> {
        return await api.stint.updateStint(props.stint._id, {youtubeURL})
    }

    function canEdit(): boolean {
        return isRouteOwnerAuthUser
            || isGroupAdmin
            || props.stint.user._id === authUserID
    }

    function youTubeVideoID(): string | null {
        return YouTubeUtils.getVideoIDFromURL(props.stint.youtubeURL)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            className="stint-videoproof"
            framed={true}
            gap="small">
            <Headline text={dict("stint.videoproof.label")} style="h5-underlined"/>
            {youTubeVideoID() &&
                <YoutubeThumbnailLink
                    videoID={youTubeVideoID()}
                    alt={dict("stint.videoproof.label")}/>}
            {canEdit() &&
                <Input
                    type="text"
                    showInvalid={true}
                    icon="youtube"
                    size="small"
                    readonly={sessionIsFinished}
                    helpTopic="stint.videoproof"
                    defaultValue={props.stint.youtubeURL}
                    pattern={YouTubeURLValidation.REGEXP.source}
                    placeholder={dict("stint.videoproof.placeholder")}
                    label={dict("stint.videoproof.input.label")}
                    action={updateYoutubeURL}/>}
        </ContentLayout>
    );

}
