import {ITrackFilterData} from "../../shared/models/submodels/ITrackFilterData";
import {TrackType} from "../../shared/types/TrackType";
import {DriverLicenseUtils} from "../../shared/utils/DriverLicenseUtils";
import {GroupUtils} from "../../shared/utils/GroupUtils";
import {DriverLicenseType} from "../../shared/types/DriverLicenseType";
import {IGroupData} from "../../shared/models/IGroupData";

/******************************************************************
 * TrackUtils
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class TrackUtils {

    static trackFilterName(
        trackFilter: ITrackFilterData,
        trackType: TrackType,
        dict: (key: string) => string
    ): string {
        if (!trackFilter) return ""
        return trackFilter.isPreset
            ? trackFilter.presetType == "default"
                ? trackType == "lap"
                    ? dict("track.filter.besttimes.name")
                    : dict("track.filter.bestscores.name")
                : dict(trackFilter.name)
            : trackFilter.name
    }

    static isHigherDriverLicenseRequired(
        authUserUseSubscription: boolean,
        routeOwnerValidDriverLicense: DriverLicenseType,
        isRouteOwnerGroup: boolean,
        groupData: IGroupData,
        trackFilter: ITrackFilterData
    ): boolean {
        if (!authUserUseSubscription) return false
        if (trackFilter?.isPreset && trackFilter?.presetType == "default") return false
        let validDriverLicense = isRouteOwnerGroup
            // TODO: GroupUtils.getUserWithHighestSubscription(groupData) is not working properly, cause its not updated when a authuser driver license has changed
            ? DriverLicenseUtils.validLicenseType(GroupUtils.getUserWithHighestSubscription(groupData)?.subscription)
            : routeOwnerValidDriverLicense
        switch (validDriverLicense as DriverLicenseType) {
            case "free":
                if (trackFilter?.isPreset && trackFilter?.presetType == "tuning") {
                    return false
                }
                return true
            case "basic":
                return !trackFilter?.isPreset
            case "premium":
                return false
        }
    }

}
