import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {LanguageType} from "../../../../../../../../shared/types/LanguageType";
import {Input} from "../../../../../../ui/form/elements/input/Input";
import {LabelButton} from "../../../../../../ui/buttons/label/LabelButton";
import {ContentLayout} from "../../../../../../ui/layout/content/ContentLayout";
import {useServices} from "../../../../../../hooks/useServices";
import {useAuthUser} from "../../../../../../hooks/useAuthUser";
import {Spacer} from "../../../../../../ui/utils/spacer/Spacer";
import {SelectInput, SelectInputOptionType} from "../../../../../../ui/form/elements/select/SelectInput";
import {SpeakerUtils} from "../../../../../../../utils/SpeakerUtils";
import {FrontendSpeakerFixType} from "../../../../../../../core/services/FrontendSpeaker";
import {Headline} from "../../../../../../ui/text/headings/Headline";
import {Divider} from "../../../../../../ui/utils/divider/Divider";
import {useMobileStyle} from "../../../../../../hooks/useMobileStyle";
import {InfoText, InfoTextStyleType} from "../../../../../../ui/text/infotext/InfoText";
import {useSpeakerState} from "../../../../../../audio/state/useSpeakerState";
import {LanguageUtils} from "../../../../../../../../shared/utils/LanguageUtils";

/******************************************************************
 * SpeakerLanguageSetting
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function SpeakerLanguageSetting(props: {
    speakerLanguage: LanguageType
}) {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const ref = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, api, speaker} = useServices();
    const {
        authUserExists,
        authUserNick,
        authUserSpeakerNick,
        setAuthUserIsUpdating,
        authUserIsUpdating
    } = useAuthUser();
    const [isMobileMode] = useMobileStyle(ref, 400)
    const {speakerState} = useSpeakerState()

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [speakerNick, setSpeakerNick] = useState<string>(getSpeakerNick())
    const [voiceAvailable, setVoiceAvailable] = useState<boolean>()
    const [voiceOptions, setVoiceOptions] = useState<SelectInputOptionType[]>()
    const [voiceName, setVoiceName] = useState<string>();
    const [fixSpeechType, setFixSpeechType] = useState<FrontendSpeakerFixType>();
    const [stateMessage, setStateMessage] = useState<string>(null);
    const [stateMessageStyle, setStateMessageStyle] = useState<InfoTextStyleType>(null);

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        updateVoices()
        setSpeakerNick(getSpeakerNick())
        speaker.voiceName.setValue(getStoredVoiceName())
        speaker.fixSpeechType.setValue(getStoredFixSpeechType())
    }, [props.speakerLanguage])

    useEffect(() => {
        const updatedNick = getSpeakerNick()
        setSpeakerNick(updatedNick)
    }, [authUserSpeakerNick])

    useEffect(() => {
        if (!fixSpeechType) return
        setStoredFixSpeechType(fixSpeechType)
        speaker.fixSpeechType.setValue(fixSpeechType)
    }, [fixSpeechType])

    useEffect(() => {
        if (!voiceName) return
        setStoredVoiceName(voiceName)
        speaker.voiceName.setValue(voiceName)
    }, [voiceName])

    useEffect(() => {
        switch (speakerState) {
            case "not-available":
                setStateMessage(dict("speaker.not.available"))
                setStateMessageStyle("error")
                break
            case "not-allowed":
                setStateMessage(dict("speaker.not.allowed"))
                setStateMessageStyle("warning")
                break
            case "no-voices":
                setStateMessage(dict("speaker.no.voices.available"))
                setStateMessageStyle("error")
                setVoiceAvailable(false)
                break
            case "ok":
            default:
                setStateMessage(null)
                break
        }
    }, [speakerState])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function getSpeakerNick(): string {
        return LanguageUtils.getValueFromMultilangString(authUserSpeakerNick, props.speakerLanguage) ?? authUserNick
    }

    async function updateSpeakerNick(value: string): Promise<Response> {
        setAuthUserIsUpdating(true)
        return await api.user.update({speakerNick: LanguageUtils.updateMultiLangString(authUserSpeakerNick, props.speakerLanguage, value)})
    }

    async function updateVoices() {
        const voices = await SpeakerUtils.availableVoices(props.speakerLanguage)
        if (!voices) {
            setVoiceAvailable(false)
            setVoiceOptions([{value: "no-voice", text: dict("speaker.no.voices.option.info")}])
        }
        setVoiceAvailable(true)
        setVoiceOptions(voices?.map(voice => {
            return {value: voice.name, text: voice.name}
        }))
        const voice = SpeakerUtils.getVoice(getStoredVoiceName(), voices, props.speakerLanguage)
        setVoiceName(voice?.name)
        setFixSpeechType(getStoredFixSpeechType())
    }

    function getStoredVoiceName(): string {
        return localStorage.getItem("speaker." + props.speakerLanguage + ".voice")
    }

    function setStoredVoiceName(value: string) {
        localStorage.setItem("speaker." + props.speakerLanguage + ".voice", value)
    }

    function getStoredFixSpeechType(): FrontendSpeakerFixType {
        return localStorage.getItem("speaker." + props.speakerLanguage + ".replaceSpaces") as FrontendSpeakerFixType || "none"
    }

    function setStoredFixSpeechType(value: FrontendSpeakerFixType) {
        localStorage.setItem("speaker." + props.speakerLanguage + ".replaceSpaces", value)
    }

    function fixSpeechTypeOptions(): SelectInputOptionType[] {
        return [
            {value: "none" as FrontendSpeakerFixType, text: dict("speaker.fixTextToSpeech.none")},
            {value: "underscore" as FrontendSpeakerFixType, text: dict("speaker.fixTextToSpeech.with.underscore")},
        ]
    }

    function speak(text: string) {
        speaker.speak(text)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout framed={true} ref={ref}>
            <Headline
                text={dict("user.speaker.label." + props.speakerLanguage)}
                style="h5-underlined"/>
            {stateMessage &&
                <InfoText
                    text={stateMessage}
                    style={stateMessageStyle}
                    size="small"/>}
            <ContentLayout gap="small">
                <SelectInput
                    label={dict("speaker.voice.label")}
                    options={voiceOptions}
                    size={"small"}
                    readonly={!voiceAvailable}
                    defaultValue={voiceAvailable ? voiceName : "no-voice"}
                    onChange={(voice: string) => setVoiceName(voice)}/>
                <SelectInput
                    label={dict("speaker.fixTextToSpeech.label")}
                    size={"small"}
                    options={fixSpeechTypeOptions()}
                    defaultValue={fixSpeechType}
                    onChange={(option: string) => setFixSpeechType(option as FrontendSpeakerFixType)}/>
            </ContentLayout>
            <Divider style="modal"/>
            {authUserExists && <>
                <ContentLayout
                    gap="small"
                    alignItems="end"
                    columnTemplate={"1fr min-content"}>
                    <Input
                        label={dict("user.speaker.nick.label." + props.speakerLanguage)}
                        type="text"
                        size="small"
                        processing={authUserIsUpdating}
                        helpTopic="user.speaker.nick"
                        defaultValue={speakerNick}
                        action={updateSpeakerNick}/>
                    <div>
                        <LabelButton
                            label={dict("user.speaker.nick.speak")}
                            style="primary-small"
                            progressing={authUserIsUpdating}
                            onClick={() => speak(speakerNick)}/>
                        <Spacer height="small"/>
                    </div>
                </ContentLayout>
                <Divider style="modal"/>
            </>}
            <ContentLayout
                gap="small">
                <Headline text={dict(`speaker.test.title`)} style="h5"/>
                <ContentLayout
                    columns={isMobileMode ? 1 : 2}
                    useFrameMargin={false}
                    gap="small">
                    <LabelButton
                        label={dict(`speaker.test.lapTime`)}
                        style="secondary-small"
                        onClick={() => speak(dict(`speaker.test.lapTime`))}/>
                    <LabelButton
                        label={dict(`speaker.test.minLeft`)}
                        style="secondary-small"
                        onClick={() => speak(dict(`speaker.test.minLeft`))}/>
                    <LabelButton
                        label={dict(`speaker.test.countdown`)}
                        style="secondary-small"
                        onClick={() => speak(dict(`speaker.test.countdown`))}/>
                    <LabelButton
                        label={dict(`speaker.test.fastestLap`)}
                        style="secondary-small"
                        onClick={() => speak(dict(`speaker.test.fastestLap`))}/>
                </ContentLayout>
            </ContentLayout>
        </ContentLayout>
    );

}
