import {SessionSetupSectionType} from "../../../../../../shared/types/SessionSetupSectionType";

/******************************************************************
 * SessionSetupSectionOrder
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class SessionSetupSectionOrder {

    static readonly list: SessionSetupSectionType[] = [
        "basic",
        "track",
        "car",
        "points",
        "startOrder",
        "falseStart",
        "timing",
        "announcements",
        "rights"
    ]
}
