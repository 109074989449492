/******************************************************************
 * CapiRoute
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class CapiRoute {

    static readonly USER = "/m"
    static readonly GROUP = "/g"

}
