import * as React from "react";
import {useEffect, useState} from "react";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {ITabbedContentChildProps} from "../../../../../ui/layout/tabbed/ITabbedContentChildProps";
import {useServices} from "../../../../../hooks/useServices";
import {LogType} from "../../../../../../../shared/types/LogType";
import {ILogEntryData} from "../../../../../../../shared/types/ILogEntryData";
import {ITableColumnDefinition} from "../../../../../ui/table/ITableColumnDefinition";
import {Table} from "../../../../../ui/table/Table";
import {LogLevelType} from "../../../../../../../shared/types/LogLevelType";
import {SelectInput, SelectInputOptionType} from "../../../../../ui/form/elements/select/SelectInput";
import {ConvertDataUtils} from "../../../../../../../shared/utils/ConvertDataUtils";
import {CapiRouteUtils} from "../../../../../../../shared/utils/CapiRouteUtils";
import {FrontendRoute} from "../../../../../../../shared/routes/FrontendRoute";
import {PathUtils} from "../../../../../../../shared/utils/PathUtils";

/******************************************************************
 * LogViewer
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function LogViewer(props: {
    tabbedContent?: ITabbedContentChildProps
    logType: LogType
    logData?: ILogEntryData[]
    isSessionLog?: boolean,
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, api, state, router} = useServices();

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [logData, setLogData] = useState<ILogEntryData[]>([])
    const [logHistoryOptions, setLogHistoryOptions] = useState<SelectInputOptionType[]>([])
    const [selectedLogHistoryOptionValue, setSelectedLogHistoryOptionValue] = useState<string>("latest")
    const [isBusy, setIsBusy] = useState(true)

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        if (props.logData) return
        loadLatestLogData()
        loadHistoryLogs()
    }, [])

    useEffect(() => {
        if (!props.logData) return
        setLogData(props.logData)
        setIsBusy(false)
    }, [props.logData])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function loadLatestLogData() {
        setIsBusy(true)
        const data = await api.log.getLogData(props.logType)
        setLogData(parseLogData(data))
        setIsBusy(false)
    }

    async function loadHistoryLogs() {
        const data = await api.log.getLogHistory(props.logType)
        const options = data?.map(filePath => ({
            value: filePath,
            text: filePath.split("/").pop()
        })) ?? []
        options.sort((a, b) => b.text.localeCompare(a.text))
        options.unshift({
            value: "latest",
            text: dict("admin.logs.logViewer.latestLog.label"),
        })
        setLogHistoryOptions(options)
    }

    async function loadHistoryLogData(filePath: string) {
        if (filePath === "latest") {
            await loadLatestLogData()
            setSelectedLogHistoryOptionValue("latest")
            return
        }
        const data = await api.log.getHistoryLogData(filePath)
        setSelectedLogHistoryOptionValue(filePath)
        setLogData(parseLogData(data))
    }

    function levelName(level: number): LogLevelType {
        switch (level) {
            case 10:
                return "trace"
            case 20:
                return "debug"
            case 30:
                return "info"
            case 40:
                return "warn"
            case 50:
                return "error"
            case 60:
                return "fatal"
            default:
                return "unknown"
        }
    }

    function columnsDefinition(): ITableColumnDefinition<ILogEntryData>[] {
        switch (props.logType) {
            case "drift":
                return driftColumnsDefinition()
            case "backend":
            case "frontend":
            case "paddle":
            case "mail":
                return messageColumnsDefinition()
        }
    }

    function driftColumnsDefinition(): ITableColumnDefinition<ILogEntryData>[] {
        return [{
            type: "state",
            columnName: dict("admin.logs.logViewer.table.column.level"),
            sortKey: "level",
            dataKey: "level",
            align: "center",
            size: props.isSessionLog ? null : "100px"
        }, {
            type: "text",
            columnName: dict("admin.logs.logViewer.table.column.date"),
            sortKey: "time",
            size: "180px",
            value: data => new Date(data.time).toLocaleString()
        }, {
            type: "text",
            columnName: dict("admin.logs.logViewer.table.column.drift.path"),
            sortKey: "drift.request.path",
            dataKey: "drift.request.path",
            size: "3.5fr",
            action: gotoSession
        }, {
            type: "text",
            columnName: dict("admin.logs.logViewer.table.column.drift.user"),
            sortKey: "drift.payload.user_name",
            dataKey: "drift.payload.user_name",
            size: "3fr",
            action: gotoUser
        }, {
            type: "text",
            columnName: dict("admin.logs.logViewer.table.column.drift.message"),
            sortKey: "drift.message",
            dataKey: "drift.message",
            size: "4fr"
        }];
    }

    function messageColumnsDefinition(): ITableColumnDefinition<ILogEntryData>[] {
        return [{
            type: "state",
            columnName: dict("admin.logs.logViewer.table.column.level"),
            sortKey: "level",
            dataKey: "level",
            align: "center",
            size: "100px"
        }, {
            type: "text",
            columnName: dict("admin.logs.logViewer.table.column.date"),
            sortKey: "time",
            size: "180px",
            value: data => new Date(data.time).toLocaleString()
        }, {
            type: "text",
            columnName: dict("admin.logs.logViewer.table.column.message"),
            sortKey: "msg",
            dataKey: "msg",
            size: "6fr"
        }];
    }

    function showDetails(data: ILogEntryData) {
        let message = data.msg
        if (data.error) {
            const error = JSON.parse(JSON.stringify(data.error))
            if (error.stack) {
                error.stack = error.stack.split("\n") as any
            }
            message = ConvertDataUtils.convertObjectToMarkdownJSON(error)
        }
        switch (props.logType) {
            case "drift":
                message = ConvertDataUtils.convertObjectToMarkdownJSON(data.drift)
                break;
            case "paddle":
            case "backend":
            case "mail":
                if (data.info) {
                    message = ConvertDataUtils.convertObjectToMarkdownJSON(data.info)
                }
                break;
        }
        state.showMessage.setValue({
            type: "data",
            message: message
        })
    }

    function parseLogData(data: ILogEntryData[]): ILogEntryData[] {
        return data?.map(entry => {
            const level = levelName(entry.level as number)
            if (level) {
                entry.level = level
            }
            return entry
        })
    }

    async function gotoSession(data: ILogEntryData): Promise<Response> {
        if (!data?.drift?.payload?.game_id) return null
        const capiRoute = CapiRouteUtils.parse(data.drift.payload.game_id)
        router.showRoute(FrontendRoute.EVENT_SESSION(
            capiRoute.ownerType,
            capiRoute.ownerPath,
            capiRoute.eventPath,
            capiRoute.sessionPath))
        state.showSessionLog.setValue(false)
        return null
    }

    async function gotoUser(data: ILogEntryData): Promise<Response> {
        if (!data?.drift?.payload?.user_name) return null
        const userPath = "/" + PathUtils.getURLFriendlyPath(data.drift.payload.user_name)
        router.showRoute(FrontendRoute.USER(userPath))
        state.showSessionLog.setValue(false)
        return null
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (<>
        <ContentLayout className="log-viewer">
            <ContentLayout framed={true} gap="small">
                {logHistoryOptions?.length > 0 &&
                    <ContentLayout columnTemplate="min-content">
                        <SelectInput
                            size="small"
                            minWidth={200}
                            options={logHistoryOptions}
                            defaultValue={selectedLogHistoryOptionValue}
                            onChange={loadHistoryLogData}/>
                    </ContentLayout>}
                <Table<ILogEntryData>
                    rowsData={logData}
                    sortKey="time"
                    isLoadingData={isBusy}
                    style="admin-compact"
                    showFilter={logData?.length > 0}
                    visibleRows={props.isSessionLog ? 10 : 25}
                    onRowClick={showDetails}
                    columnsDefinition={columnsDefinition()}/>
            </ContentLayout>
        </ContentLayout>
    </>);
}
