import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {SelectInput, SelectInputOptionType} from "../../../../../ui/form/elements/select/SelectInput";
import {useServices} from "../../../../../hooks/useServices";
import {useSessionState} from "../../../../../hooks/useSessionState";
import {useEventState} from "../../../../../hooks/useEventState";
import {MongoObjectIDType} from "../../../../../../../shared/types/MongoObjectIDType";
import {useRouteOwner} from "../../../../../hooks/useRouteOwner";
import {useGroupState} from "../../../../../hooks/useGroupState";
import {useMobileStyle} from "../../../../../hooks/useMobileStyle";

/******************************************************************
 * SessionStartOrderSetup
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function SessionStartOrderSetup() {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const ref = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {dict, api} = useServices()
    const {isRouteOwnerAuthUser} = useRouteOwner()
    const {isGroupAdmin} = useGroupState()
    const {eventChildren, eventChildrenOrder} = useEventState()
    const [isMobileMode] = useMobileStyle(ref, 500)
    const {
        sessionID,
        sessionStartOrderFromSession,
        sessionStartOrderReversed,
        sessionIsFinished
    } = useSessionState()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [startOrderSessionOptions, setStartOrderSessionOptions] = useState<SelectInputOptionType[]>()

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        updateAvailableStartOrderSessions()
    }, [eventChildren, eventChildrenOrder])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function updateAvailableStartOrderSessions() {
        const availableSessions = eventChildren?.sessions?.filter(session => {
            if (session.setup.classificationMode == "solo") {
                return false
            }
            if (session.setup.classificationMode == "teams") {
                return false
            }
            return session._id != sessionID
        });
        let options: SelectInputOptionType[] = [];
        options.push({
            value: "disabled",
            text: dict("session.startOrder.disabled")
        })
        if (availableSessions) {
            let order: MongoObjectIDType[] = eventChildren?.sessions?.map(session => session._id)
            order.forEach(childrenID => {
                const availableSession = availableSessions.filter(session => session._id == childrenID)[0]
                if (availableSession) {
                    options.push({
                            value: availableSession._id,
                            text: availableSession.name
                        }
                    )
                }
            })
        }
        setStartOrderSessionOptions(options)
    }

    async function updateSessionOrderReversed(reversed: string): Promise<Response> {
        return await api.session.update(sessionID, {setup: {startOrderReversed: reversed == "true"}})
    }

    async function updateSessionStartOrderFromSession(startOrderSessionID: MongoObjectIDType): Promise<Response> {
        if (startOrderSessionID == "disabled") {
            startOrderSessionID = null
        }
        return await api.session.update(sessionID, {setup: {startOrderFromSession: startOrderSessionID}})
    }

    function isReadonly() {
        if (sessionIsFinished) return true;
        return !isRouteOwnerAuthUser && !isGroupAdmin;
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            ref={ref}
            columns={isMobileMode ? 1 : 2}
            className="session-startorder-setup">
            <SelectInput
                label={dict("session.startOrder.fromSession")}
                helpTopic="ssession.startOrder.fromSession"
                readonly={isReadonly()}
                defaultValue={sessionStartOrderFromSession ?? "disabled"}
                onChange={updateSessionStartOrderFromSession}
                options={startOrderSessionOptions}/>
            <SelectInput
                label={dict("session.startOrder.reversed")}
                defaultValue={sessionStartOrderReversed ? "true" : "false"}
                readonly={isReadonly()}
                options={[
                    {value: "false", text: dict("session.startOrder.reversed.false")},
                    {value: "true", text: dict("session.startOrder.reversed.true")}
                ]}
                onChange={updateSessionOrderReversed}/>
        </ContentLayout>
    );

}
