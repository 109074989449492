import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {Headline} from "../../../../ui/text/headings/Headline";
import {useSessionState} from "../../../../hooks/useSessionState";
import {useServices} from "../../../../hooks/useServices";
import {ComponentInitializer} from "../../../../ui/utils/init/ComponentInitializer";
import {LeaderboardUtils} from "../../../../../../shared/utils/LeaderboardUtils";
import {TimeUtils} from "../../../../../../shared/utils/TimeUtils";
import {ContentLayout} from "../../../../ui/layout/content/ContentLayout";
import {SessionStateType} from "../../../../../../shared/types/SessionStateType";
import {useMobileStyle} from "../../../../hooks/useMobileStyle";
import {CharUtils} from "../../../../../../shared/utils/CharUtils";

/******************************************************************
 * SessionHeader
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function SessionHeader() {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const ref = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, time} = useServices();
    const [isMobileMode] = useMobileStyle(ref, 700)
    const {
        sessionID,
        sessionState,
        sessionName,
        sessionMode,
        sessionClassificationMode,
        sessionTiming,
        sessionFinishType,
        sessionDuration,
        sessionLaps,
        sessionStartTime,
        sessionActiveFrom,
        sessionActiveUntil,
        sessionLeaderboard
    } = useSessionState();

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const hasDurationBeenReachedRef = useRef<boolean>()
    const hasLapsBeenReachedRef = useRef<boolean>()
    const earliestSignalTimestampRef = useRef<string>()
    const maxDrivenLapsRef = useRef<number>()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [timer, setTimer] = useState<string>()
    const [sessionInfo, setSessionInfo] = useState<string>()
    const [timerState, setTimerState] = useState<SessionStateType>("upcoming")

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        updateMaxDrivenLaps()
        updateLapsBeenReached()
        updateHasDurationBeenReached()
        updateEarliestSignalTimestamp()
        setTimer(computeTimerValue())
        updateTimerState()
        setSessionInfo(computeSessionInfoValue())
        let interval = window.setInterval(() => {
            updateMaxDrivenLaps()
            updateTimerState()
            updateEarliestSignalTimestamp()
            updateHasDurationBeenReached()
            updateLapsBeenReached()
            setTimer(computeTimerValue())
        }, 50)
        return () => clearInterval(interval)
    }, [
        sessionLeaderboard,
        sessionDuration,
        sessionLaps,
        sessionState,
        sessionTiming,
        sessionMode,
        sessionStartTime,
        sessionActiveUntil,
        sessionActiveFrom
    ])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function updateTimerState() {
        switch (sessionState) {
            case "upcoming":
                setTimerState("upcoming")
            case "running":
                setTimerState("running")
                if (sessionStartTime && TimeUtils.isFuture(sessionStartTime, time.date)) {
                    setTimerState("upcoming")
                }
                if (sessionActiveFrom && TimeUtils.isFuture(sessionActiveFrom, time.date)) {
                    setTimerState("upcoming")
                }
                if (sessionActiveUntil && TimeUtils.isPast(sessionActiveUntil, time.date)) {
                    setTimerState("finished")
                }
                break
            case "finished":
                setTimerState("finished")
        }
    }

    function computeTimerValue(): string {
        if (sessionStartTime && TimeUtils.isFuture(sessionStartTime, time.date)) {
            const secondsUntilStart = Math.ceil(TimeUtils.calcTimeInSeconds(sessionStartTime, time.date))
            return TimeUtils.formatDrivenTime(secondsUntilStart, 0)
        }
        if (sessionActiveFrom && TimeUtils.isFuture(sessionActiveFrom, time.date)) {
            const secondsUntilStart = Math.ceil(TimeUtils.calcTimeInSeconds(sessionActiveFrom, time.date))
            return TimeUtils.formatDrivenTime(secondsUntilStart, 0)
        }
        if (sessionActiveUntil && TimeUtils.isFuture(sessionActiveUntil, time.date) && sessionTiming == "async") {
            const secondsUntilEnd = Math.ceil(TimeUtils.calcTimeInSeconds(sessionActiveUntil, time.date))
            return TimeUtils.formatDrivenTime(secondsUntilEnd, 0)
        }
        if (sessionActiveUntil && TimeUtils.isPast(sessionActiveUntil, time.date)) {
            return dict("session.header.timer.finished")
        }
        switch (sessionMode) {
            case "lap":
            case "race":
                switch (sessionFinishType) {
                    case "duration":
                        switch (sessionTiming) {
                            case "sync":
                                if (hasDurationBeenReachedRef.current) {
                                    return dict("session.header.timer.finished")
                                }
                                if (!earliestSignalTimestampRef.current
                                    || sessionState !== "running") {
                                    return sessionDuration
                                }
                                return TimeUtils.getFormattedRemainingTimeFromDuration(earliestSignalTimestampRef.current, sessionDuration, time.date)
                            case "async":
                                return sessionDuration
                        }
                        break
                    case "laps":
                        if (!sessionLeaderboard || sessionTiming == "async") {
                            if (sessionLaps == 0) {
                                return "∞"
                            }
                            return sessionLaps.toString()
                        }
                        if (hasLapsBeenReachedRef.current) {
                            return dict("session.header.timer.finished")
                        }
                        return (maxDrivenLapsRef.current ?? 0) + "/" + sessionLaps
                }
                break;
            case "gymkhana":
                return sessionDuration
        }
    }

    function timerInfoText() {
        if (sessionStartTime && TimeUtils.isFuture(sessionStartTime, time.date)) {
            return dict("session.header.startsAt")
                .replace("{START_TIME}", TimeUtils.formatDate(sessionStartTime))
        }
        if (sessionActiveFrom && TimeUtils.isFuture(sessionActiveFrom, time.date)) {
            return dict("session.header.waitUntil")
                .replace("{ACTIVE_FROM}", TimeUtils.formatDate(sessionActiveFrom))
        }
        if (sessionActiveUntil && TimeUtils.isFuture(sessionActiveUntil, time.date) && sessionTiming == "async") {
            return dict("session.header.activUntil")
                .replace("{ACTIVE_UNTIL}", TimeUtils.formatDate(sessionActiveUntil))
        }
        if (sessionActiveUntil && TimeUtils.isPast(sessionActiveUntil, time.date)) {
            return dict("session.header.finishedAt")
                .replace("{ACTIVE_UNTIL}", TimeUtils.formatDate(sessionActiveUntil))
        }
        switch (sessionTiming) {
            case "sync":
                switch (sessionFinishType) {
                    case "laps":
                        if (hasLapsBeenReachedRef.current) {
                            return dict("session.finishType." + sessionFinishType) + ": " + sessionLaps
                        }
                        break
                    case "duration":
                        if (hasDurationBeenReachedRef.current || earliestSignalTimestampRef.current) {
                            return dict("session.finishType." + sessionFinishType) + ": " + sessionDuration
                        }
                }
                return dict("session.finishType." + sessionFinishType)
            case "async":
                return dict("session.finishType.stint." + sessionFinishType)
        }
    }

    function updateEarliestSignalTimestamp() {
        earliestSignalTimestampRef.current = LeaderboardUtils.getEarliestSignalTimestamp(sessionLeaderboard)
    }

    function updateHasDurationBeenReached() {
        hasDurationBeenReachedRef.current = TimeUtils.hasDurationBeenReached(
            earliestSignalTimestampRef.current,
            time.date.toISOString(),
            sessionDuration
        )
    }

    function updateLapsBeenReached() {
        hasLapsBeenReachedRef.current = maxDrivenLapsRef.current >= sessionLaps
    }

    function updateMaxDrivenLaps() {
        maxDrivenLapsRef.current = LeaderboardUtils.getMaxOverallDrivenLaps(sessionLeaderboard)
    }

    function computeSessionInfoValue() {
        if (!sessionMode) return ""
        const mode = dict("session.mode." + sessionMode)
        const timing = dict("session.timing." + sessionTiming)
        const classification = dict("session.classificationMode." + sessionClassificationMode)
        const formattedStartDate = TimeUtils.formatDate(earliestSignalTimestampRef.current)
        const startInfo = dict("session.header.startedAt").replace("{START_DATE}", formattedStartDate)
        if (sessionClassificationMode == "solo") {
            return mode + " | " + classification
        }
        switch (sessionMode) {
            case "lap":
            case "race":
                switch (sessionTiming) {
                    case "sync":
                        return mode + " | " + classification + " | " + timing + (formattedStartDate ? " | " + startInfo : "")
                    case "async":
                        return mode + " | " + classification + " | " + timing
                }
                break
            case "gymkhana":
                return mode + " | " + classification
        }
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ComponentInitializer
            isPropertyAvailable={sessionID}
            className="session-header">
            <main
                ref={ref}
                data-is-mobile-mode={isMobileMode}
                data-timer-state={timerState}>
                <ContentLayout className="session-header-title" gap="tiny">
                    <Headline
                        text={sessionName}
                        style="h1"/>
                    {sessionMode &&
                        <Headline
                            text={sessionInfo}
                            style="h5"/>}
                </ContentLayout>
                <ContentLayout
                    className="session-header-timer"
                    gap="tiny"
                    rowTemplate={sessionFinishType ? "auto min-content" : null}>
                    <Headline
                        text={CharUtils.replaceZeroWithO(timer ?? computeTimerValue())}
                        style="h1-mono"/>
                    {sessionFinishType &&
                        <Headline
                            text={timerInfoText()}
                            style="h5"/>}
                </ContentLayout>
            </main>
        </ComponentInitializer>
    );

}
