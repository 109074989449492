import * as React from "react";

/******************************************************************
 * EmptyStarIcon
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function EmptyStarIcon(props: {
    scale?: number
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="empty-star-icon">
            <svg width={16 * (props.scale ?? 1)} height={19 * (props.scale ?? 1)} viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.5986 16.9164L12.5975 16.9164C12.5907 16.9164 12.584 16.9143 12.5784 16.9104C12.5782 16.9103 12.5781 16.9102 12.578 16.9101L8.29265 13.8033L7.99917 13.5906L7.70569 13.8033L3.41839 16.9115L3.4183 16.9116C3.41257 16.9158 3.40566 16.918 3.39859 16.918C3.39151 16.9179 3.38462 16.9157 3.37892 16.9115C3.37322 16.9073 3.369 16.9014 3.36687 16.8946C3.36475 16.8879 3.36483 16.8806 3.3671 16.8739L3.3672 16.8736L5.03932 11.9209L5.16031 11.5625L4.84837 11.3486L0.514728 8.37672L0.514512 8.37657C0.50864 8.37255 0.504207 8.36675 0.501862 8.36003C0.499517 8.35331 0.499384 8.34601 0.50148 8.33921C0.503578 8.33241 0.507795 8.32645 0.513517 8.32222C0.519164 8.31804 0.525985 8.31575 0.533005 8.31567H5.87835H6.24162L6.35388 7.97019L7.96733 3.00483L7.96743 3.00452C7.9696 2.99782 7.97384 2.99197 7.97955 2.98782C7.98525 2.98368 7.99212 2.98145 7.99917 2.98145C8.00622 2.98145 8.01309 2.98368 8.0188 2.98782C8.0245 2.99197 8.02874 2.99782 8.03092 3.00452L8.031 3.00478L9.64445 7.97181L9.75669 8.31734H10.12H15.4664H15.4666C15.4737 8.31733 15.4807 8.31961 15.4864 8.32384C15.4922 8.32806 15.4964 8.33402 15.4985 8.34083C15.5006 8.34764 15.5005 8.35494 15.4982 8.36167C15.4958 8.3684 15.4914 8.37421 15.4855 8.37824L15.4855 8.37826L11.1502 11.3485L10.838 11.5623L10.959 11.9208L12.6301 16.8722L12.6302 16.8723C12.6319 16.8773 12.6323 16.8827 12.6316 16.8879C12.6308 16.8931 12.6288 16.8981 12.6257 16.9024C12.6226 16.9067 12.6185 16.9102 12.6138 16.9127C12.6091 16.9151 12.6039 16.9163 12.5986 16.9164Z" stroke="white"/>
            </svg>
        </div>
    );

}
