import * as React from "react";

/******************************************************************
 * PrevIcon
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function PrevIcon(props: {
    scale?: number
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="prev-icon">
            <svg width={8 * (props.scale ?? 12)} height={16 * (props.scale ?? 1)} viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.99859 11.2442C7.38786 10.8549 7.38821 10.2239 6.99936 9.83421L3.828 6.65592C3.43851 6.26558 3.43851 5.6336 3.828 5.24325L6.99936 2.06497C7.38821 1.67527 7.38786 1.04425 6.99859 0.654973V0.654973C6.60902 0.2654 5.9774 0.2654 5.58782 0.654973L1.00031 5.24248C0.609789 5.63301 0.609789 6.26617 1.00031 6.65669L5.58782 11.2442C5.9774 11.6338 6.60902 11.6338 6.99859 11.2442V11.2442Z" fill="white"/>
            </svg>
        </div>
    );

}
