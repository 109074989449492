import * as React from "react";
import {useState} from "react";
import {ContextMenu} from "../ContextMenu";
import {ContextMenuDivider} from "../elements/ContextMenuDivider";
import {useServices} from "../../../hooks/useServices";
import {useEventState} from "../../../hooks/useEventState";
import {EventStateType} from "../../../../../shared/types/EventStateType";
import {ContextMenuState} from "../elements/ContextMenuState";
import {useRouteOwner} from "../../../hooks/useRouteOwner";
import {useGroupState} from "../../../hooks/useGroupState";
import {IEventData} from "../../../../../shared/models/IEventData";
import {useRouteStates} from "../../../hooks/useRouteStates";
import {PromisedDelay} from "@webfruits/toolbox/dist/timer/PromisedDelay";

/******************************************************************
 * EventStateSelector
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function EventStateSelector(props: {
    eventData?: IEventData
    compact?: boolean
    updateMethode?: (state: EventStateType) => Promise<void>
    onChange?: (state: EventStateType) => void
}) {

    /* ----------------------------------------------------------------
     * SERVICES
     * --------------------------------------------------------------*/

    const {api} = useServices();
    const {isRouteOwnerAuthUser} = useRouteOwner();
    const {showGroup} = useRouteStates()
    const {eventID, eventState, eventOwnerType} = useEventState();
    const {isGroupAdmin} = useGroupState()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [isBusy, setIsBusy] = useState(false);

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function changeState(state: EventStateType) {
        setIsBusy(true)
        await api.event.update(props.eventData?._id ?? eventID, {state: state})
        props.onChange?.(state)
        if (props.updateMethode) {
            await props.updateMethode(state)
            await PromisedDelay.wait(0.1)
        }
        setIsBusy(false)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContextMenu
            type="state"
            state={props.eventData?.state ?? eventState}
            isBusy={isBusy}
            compactStateInfo={props.compact}
            stopPropagation={true}
            className="event-state-selector"
            enabled={isRouteOwnerAuthUser || isGroupAdmin}>
            {(showGroup || eventOwnerType == "group") && <>
                <ContextMenuState
                    state="upcoming"
                    selected={(props.eventData?.state ?? eventState) == "upcoming"}
                    onClick={() => changeState("upcoming")}/>
                <ContextMenuDivider style="small"/>
            </>}
            <ContextMenuState
                state="live"
                selected={(props.eventData?.state ?? eventState) == "live"}
                onClick={() => changeState("live")}/>
            <ContextMenuDivider style="small"/>
            <ContextMenuState
                state="finished"
                selected={(props.eventData?.state ?? eventState) == "finished"}
                onClick={() => changeState("finished")}/>
        </ContextMenu>
    );

}
