import {ErrorCode} from "../keys/ErrorCode";

/******************************************************************
 * TrackMinLapTimeValidation
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class TrackMinLapTimeValidation {

    static get REGEXP() {
        return /^[1-9]\d{0,2}$/;
    }

    static schemaValidator(errorCode?: string) {
        return {
            validate: {
                validator: value => TrackMinLapTimeValidation.isValid(value),
                message: errorCode ?? ErrorCode.TRACK_MINLAPTIME_INVALID
            },
        }
    };

    static isValid(value: number): boolean {
        return TrackMinLapTimeValidation.REGEXP.test(value?.toString());
    }
}
