import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {Input} from "../../../../ui/form/elements/input/Input";
import {Headline} from "../../../../ui/text/headings/Headline";
import {Table} from "../../../../ui/table/Table";
import {IDictionaryEntryData} from "../../../../../../shared/models/IDictionaryEntryData";
import {useServices} from "../../../../hooks/useServices";
import {ITableColumnDefinition} from "../../../../ui/table/ITableColumnDefinition";
import {useMobileStyle} from "../../../../hooks/useMobileStyle";
import {TransferDictionary} from "../database/transfer/TransferDictionary";
import {useEnviroment} from "../../../../hooks/useEnviroment";
import {ContentLayout} from "../../../../ui/layout/content/ContentLayout";
import {LabelButton} from "../../../../ui/buttons/label/LabelButton";
import {IconButton} from "../../../../ui/buttons/icon/IconButton";
import {LanguageUtils} from "../../../../../../shared/utils/LanguageUtils";
import {useAuthUser} from "../../../../hooks/useAuthUser";
import {SharedConfig} from "../../../../../../shared/config/SharedConfig";

/******************************************************************
 * AdminDictionary
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function AdminDictionary() {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const rootRef = useRef<HTMLDivElement>(null);

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, api} = useServices();
    const [isMobileMode] = useMobileStyle(rootRef, 600)
    const {isDevEnviroment} = useEnviroment();
    const {authUserHasPermission} = useAuthUser()

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [definedListData, setDefinedListData] = useState<IDictionaryEntryData[]>();
    const [unDefinedListData, setUnDefinedListData] = useState<IDictionaryEntryData[]>();
    const [entryKey, setEntryKey] = useState<string>();
    const [entryDE, setEntryDE] = useState<string>();
    const [entryEN, setEntryEN] = useState<string>();
    const [entrySubmitting, setEntrySubmitting] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        loadDictionaryEntries();
    }, [])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function loadDictionaryEntries() {
        setIsLoading(true);
        const dictEntries = await api.dict.getEntries();
        const definedListData: IDictionaryEntryData[] = [];
        const unDefinedListData: IDictionaryEntryData[] = [];
        dictEntries.forEach(entry => {
            let isUndefined = false
            SharedConfig.LANGUAGES.forEach(lang => {
                if (entry[lang] === undefined
                    || entry[lang] === "{" + entry.key + "}"
                    || entry[lang] === "undefined"
                    || entry[lang] === "null") {
                    isUndefined = true
                }
            })
            if (isUndefined) {
                unDefinedListData.push(entry);
            } else {
                definedListData.push(entry);
            }
        })
        setDefinedListData(definedListData);
        setUnDefinedListData(unDefinedListData);
        setIsLoading(false);
    }

    async function translateEntry(data: IDictionaryEntryData): Promise<Response> {
        const response = await api.dict.translateEntry(data);
        await loadDictionaryEntries();
        return response;
    }

    async function updateEntryValue(data: IDictionaryEntryData): Promise<Response> {
        const response = await api.dict.updateEntry(data);
        await loadDictionaryEntries();
        return response;
    }

    async function deleteEntry(data: IDictionaryEntryData): Promise<Response> {
        const response = await api.dict.deleteEntry({_id: data._id});
        await loadDictionaryEntries();
        return response;
    }

    function columnsDefinition(): ITableColumnDefinition<IDictionaryEntryData>[] {
        return [
            {
                dataKey: "key",
                sortKey: "key",
                columnName: dict("admin.dictionary.key.label"),
                type: "editable",
                size: "4fr",
                action: (data) => updateEntryValue(data)
            },
            {
                dataKey: "de",
                sortKey: "de",
                columnName: dict("language.de"),
                type: "editable",
                size: "4fr",
                action: (data) => updateEntryValue(data)
            },
            {
                dataKey: "en",
                sortKey: "en",
                columnName: dict("language.en"),
                type: "editable",
                size: "4fr",
                action: (data) => updateEntryValue(data)
            },
            {
                type: "custom",
                size: authUserHasPermission("api:translate") ? "40px" : null,
                customCell: (key, data) => {
                    return <ContentLayout key={key}>
                        {LanguageUtils.hasMissingTranslations(data) &&
                            <IconButton
                                type="translate"
                                onClick={() => translateEntry(data)}/>}
                    </ContentLayout>
                }
            },
            {
                type: "delete",
                size: "40px",
                action: (data) => deleteEntry(data)
            }
        ]
    }

    async function addEntry(): Promise<Response> {
        setEntrySubmitting(true)
        if (!entryKey || (!entryDE && !entryEN)) {
            return null
        }
        await api.dict.addEntry({
            key: entryKey,
            de: entryDE,
            en: entryEN
        })
        await loadDictionaryEntries()
        setEntryKey(null)
        setEntryDE(null)
        setEntryEN(null)
        setEntrySubmitting(false)
        return null
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            className="admin-dictionary"
            ref={rootRef}>
            {isDevEnviroment && <>
                <TransferDictionary/>
                <ContentLayout framed={true}>
                    <ContentLayout
                        columnTemplate={"auto min-content"}
                        justifyContent="space-between">
                        <Headline
                            text={dict("admin.dictionary.add.title")}
                            style="h5-underlined"/>
                        <LabelButton
                            label={dict("admin.dictionary.add.buttonlabel")}
                            style="primary-small"
                            progressing={entrySubmitting}
                            disabled={!entryKey || !entryDE || !entryKey.trim() || !entryDE.trim()}
                            onClick={addEntry}/>
                    </ContentLayout>
                    <ContentLayout columns={isMobileMode ? 1 : 3} gap={"small"}>
                        <Input
                            type="text"
                            size="small"
                            defaultValue={entryKey}
                            label={dict("admin.dictionary.key.label")}
                            onChange={setEntryKey}/>
                        <Input
                            type="text"
                            size="small"
                            label={dict("language.de")}
                            defaultValue={entryDE}
                            onChange={setEntryDE}/>
                        <Input
                            type="text"
                            size="small"
                            label={dict("language.en")}
                            defaultValue={entryEN}
                            onChange={setEntryEN}/>
                    </ContentLayout>
                </ContentLayout></>}
            {unDefinedListData?.length > 0 &&
                <ContentLayout framed={true}>
                    <Headline text={dict("admin.dictionary.list.undefined.title")} style="h5-underlined"/>
                    <Table
                        rowsData={unDefinedListData}
                        style="admin"
                        isLoadingData={isLoading}
                        columnsDefinition={columnsDefinition()}/>
                </ContentLayout>
            }
            <ContentLayout framed={true}>
                <Headline text={dict("admin.dictionary.list.defined.title")} style="h5-underlined"/>
                <Table
                    rowsData={definedListData}
                    style="admin"
                    isLoadingData={isLoading}
                    sortDirection="asc"
                    sortKey="key"
                    showFilter={true}
                    visibleRows={25}
                    noDataInfo={dict("admin.dictionary.nodata.info")}
                    columnsDefinition={columnsDefinition()}/>
            </ContentLayout>
        </ContentLayout>
    )
        ;
}
