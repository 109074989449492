import * as React from "react";
import {useState} from "react";
import {Modal} from "../Modal";
import {useServices} from "../../../hooks/useServices";
import {SplitView} from "../../../ui/splitview/SplitView";
import {SplitViewContent} from "../../../ui/splitview/content/SplitViewContent";
import {EventDriversSettings} from "../../main/event/settings/drivers/EventDriversSettings";
import {EventTeamSettings} from "../../main/event/settings/team/EventTeamSettings";
import {EventGeneralSettings} from "../../main/event/settings/general/EventGeneralSettings";
import {EventAboutSettings} from "../../main/event/settings/about/EventAboutSettings";
import {EventDateSettings} from "../../main/event/settings/date/EventDateSettings";
import {EventLocationSettings} from "../../main/event/settings/location/EventLocationSettings";
import {EventDeleteSettings} from "../../main/event/settings/delete/EventDeleteSettings";
import {SidebarSpacer} from "../../../ui/splitview/spacer/SidebarSpacer";
import {EventTrackSettings} from "../../main/event/settings/tracks/EventTrackSettings";
import {EventSessionSettings} from "../../main/event/settings/session/EventSessionSettings";
import {useEventState} from "../../../hooks/useEventState";
import {EventUtils} from "../../../../../shared/utils/EventUtils";
import {useGroupState} from "../../../hooks/useGroupState";
import {EventContactSettings} from "./contact/EventContactSettings";
import {BadgeColorType} from "../../../ui/info/badge/BadgeColorType";

/******************************************************************
 * EventSettingsModal
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function EventSettingsModal() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, state} = useServices();
    const {
        eventData,
        eventOwnerType,
        eventPendingDrivers,
        eventTeams,
        eventTracks,
        eventIsFinished,
        eventRequestingDrivers
    } = useEventState()
    const {groupType, isGroupAdmin} = useGroupState()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [splitViewMobileMode, setSplitViewMobileMode] = useState<boolean>(false)
    const [isTraining] = useState(eventOwnerType === "user")

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function requestButtonBadgeLabel(): string {
        return EventUtils.driverStatesInfo(eventData, isGroupAdmin)
    }

    function hasNonRegisteredDrivers(): boolean {
        return eventPendingDrivers?.length > 0 || eventRequestingDrivers?.length > 0
    }

    function driversBadgeColor(): BadgeColorType {
        if (eventIsFinished) return "blue"
        return hasNonRegisteredDrivers() && isGroupAdmin ? "red" : "white"
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal
            width="extra-large"
            height={splitViewMobileMode ? "auto" : "medium"}
            className="event-settings-modal"
            padding="none"
            closeAction={() => state.showEventSettings.setValue(false)}>
            <SplitView
                padding="modal"
                title={dict(isTraining
                    ? "training.settings.title"
                    : "event.settings.title")}
                onMobileMode={setSplitViewMobileMode}>
                <SplitViewContent
                    label={dict("event.settings.general")}
                    content={<EventGeneralSettings isTraining={isTraining}/>}/>
                {!isTraining &&
                    <SplitViewContent
                        label={dict("event.settings.date")}
                        content={<EventDateSettings/>}/>}
                {!isTraining && groupType == "local" &&
                    <SplitViewContent
                        label={dict("event.settings.location")}
                        content={<EventLocationSettings/>}/>}
                {!isTraining &&
                    <SplitViewContent
                        label={dict("event.settings.desc")}
                        content={<EventAboutSettings/>}/>}
                {!isTraining &&
                    <SplitViewContent
                        label={dict("event.settings.contact")}
                        content={<EventContactSettings/>}/>}
                <SidebarSpacer/>
                {!isTraining &&
                    <SplitViewContent
                        label={dict("event.settings.drivers")}
                        badgeLabel={requestButtonBadgeLabel()}
                        badgeColor={driversBadgeColor()}
                        content={<EventDriversSettings/>}/>}
                {!isTraining &&
                    <SplitViewContent
                        label={dict("event.settings.teams")}
                        badgeLabel={eventTeams?.length.toString() ?? "0"}
                        badgeColor={eventIsFinished ? "blue" : "white"}
                        content={<EventTeamSettings/>}/>}
                <SplitViewContent
                    label={dict("event.settings.tracks")}
                    badgeLabel={eventTracks?.length.toString() ?? "0"}
                    badgeColor={eventIsFinished ? "blue" : "white"}
                    content={<EventTrackSettings/>}/>
                {!isTraining &&
                    <SidebarSpacer/>}
                {!isTraining &&
                    <SplitViewContent
                        label={dict("event.settings.sessions")}
                        content={<EventSessionSettings/>}/>}
                <SidebarSpacer/>
                <SplitViewContent
                    label={dict(isTraining
                        ? "training.settings.delete"
                        : "event.settings.delete")}
                    content={<EventDeleteSettings isTraining={isTraining}/>}/>
            </SplitView>
        </Modal>
    );

}
