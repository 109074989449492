/******************************************************************
 * FolderUtils
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class FoldingUtils {

    static isEventFolderOpen(folderID: string): boolean {
        const value = localStorage.getItem("folder.event." + folderID);
        return value != "closed"
    }

    static setEventFolderState(folderID: string, open: boolean) {
        if (open) {
            localStorage.removeItem("folder.event." + folderID);
        } else {
            localStorage.setItem("folder.event." + folderID, "closed");
        }
    }

    static isTeamFolderOpen(folderID: string): boolean {
        const value = localStorage.getItem("folder.team." + folderID);
        return value == "opened"
    }

    static setTeamFolderState(folderID: string, open: boolean) {
        if (open) {
            localStorage.setItem("folder.team." + folderID, "opened");
        } else {
            localStorage.removeItem("folder.team." + folderID);
        }
    }

}
