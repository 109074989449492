import {APIRoute} from "../../shared/routes/APIRoute";
import {FrontendServices} from "../core/FrontendServices";
import {SettingKeyType} from "../../shared/types/SettingKeyType";

/******************************************************************
 * SettingsAPI
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class SettingsAPI {

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(private _frontend: FrontendServices) {
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public async setValue(key: SettingKeyType, value: any): Promise<Response> {
        return await this._frontend.api.request("PATCH", APIRoute.SETTING, {key, value}, true)
    }

    public async getValue(key: SettingKeyType): Promise<any> {
        const response = await this._frontend.api.request("GET", APIRoute.SETTING, {key}, true)
        try {
            return await response?.json();
        } catch (e) {
            this._frontend.state.showMessage.setValue({
                type: "error",
                message: "SettingsAPI: Error while parsing response for key: **" + key + "**",
            });
            return null;
        }
    }

}
