import * as React from "react";
import {useEffect, useState} from "react";
import {UserInfo} from "../../../../ui/info/user/UserInfo";
import {SelectInput, SelectInputOptionType} from "../../../../ui/form/elements/select/SelectInput";
import {ActionBarSpacer} from "../../../../ui/bar/spacer/ActionBarSpacer";
import {IconButton} from "../../../../ui/buttons/icon/IconButton";
import {SessionSpeakerContextMenu} from "../../../../ui/context/speaker/SessionSpeakerContextMenu";
import {ActionBar} from "../../../../ui/bar/ActionBar";
import {useSessionState} from "../../../../hooks/useSessionState";
import {SessionLeaderboardGroupingType} from "../../../../../../shared/types/SessionLeaderboardGroupingType";
import {SessionTeamScoringMode} from "../../../../../../shared/types/SessionTeamScoringMode";
import {useServices} from "../../../../hooks/useServices";
import {GameIDInfo} from "../../../../ui/info/capi/GameIDInfo";
import {useEventState} from "../../../../hooks/useEventState";
import {useRouteOwner} from "../../../../hooks/useRouteOwner";
import {Divider} from "../../../../ui/utils/divider/Divider";
import {SessionStateSelector} from "../../../../ui/context/state/SessionStateSelector";
import {useGroupState} from "../../../../hooks/useGroupState";

/******************************************************************
 * SessionActionBar
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function SessionActionBar() {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {api, dict, state} = useServices()
    const {eventOwner, eventOwnerType, eventState} = useEventState()
    const {isRouteOwnerAuthUser} = useRouteOwner()
    const {isGroupAdmin} = useGroupState()
    const {
        sessionID,
        sessionIsFinished,
        sessionState,
        sessionData,
        sessionTeamScoringMode,
        sessionLogData,
        sessionClassificationMode,
        sessionTiming,
        sessionLeaderboardGrouping
    } = useSessionState()

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [highlightSessionLog, setHighlightSessionLog] = useState<boolean>(false)
    const [numSessionLogEntries, setNumSessionLogEntries] = useState<number>(undefined)

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        if (numSessionLogEntries !== undefined) {
            setHighlightSessionLog(numSessionLogEntries < sessionLogData?.length)
        }
        if (sessionLogData) {
            setNumSessionLogEntries(sessionLogData?.length)
        }
    }, [sessionLogData])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function leaderboardGroupingOptions(): SelectInputOptionType<SessionLeaderboardGroupingType>[] {
        return [
            {value: "stint", text: dict("session.leaderboard.grouping.stint")},
            {value: "engine-and-tuning", text: dict("session.leaderboard.grouping.engine-and-tuning")},
            {value: "engine", text: dict("session.leaderboard.grouping.engine")},
            {value: "tuning", text: dict("session.leaderboard.grouping.tuning")},
            {value: "car-name", text: dict("session.leaderboard.grouping.car-name")},
        ]
    }

    function sessionTeamScoringModeOptions(): SelectInputOptionType<SessionTeamScoringMode>[] {
        return [
            {value: "sum", text: dict("session.teamScoringMode.sum")},
            {value: "average", text: dict("session.teamScoringMode.avg")},
            {value: "best", text: dict("session.teamScoringMode.best")}
        ]
    }

    async function updateLeaderboardGrouping(value: SessionLeaderboardGroupingType) {
        await api.session.update(sessionID, {setup: {leaderboardGrouping: value}})
    }

    async function updateSessionTeamScoringMode(value: SessionTeamScoringMode) {
        await api.session.update(sessionID, {setup: {teamScoringMode: value}})
    }

    function showSoloActionBarElements(): boolean {
        return sessionClassificationMode === "solo"
    }

    function showTeamsActionBarElement(): boolean {
        return sessionClassificationMode === "teams"
            && sessionTiming === "async";
    }

    function showSessionLogs(): boolean {
        return sessionLogData?.length > 0
    }

    function showLog() {
        state.showSessionLog.setValue(true)
        setHighlightSessionLog(false)
    }

    function showSpeaker() {
        if (eventState != "live") return false
        return sessionState === "running" || sessionState === "upcoming"
    }

    function showStateSelector(): boolean {
        const isTraining = eventOwnerType == "user"
        if (isTraining) return false
        if (eventState == "upcoming") {
            if (sessionState == "finished") return true
        }
        return true
    }

    function showGameID(): boolean {
        const isTraining = eventOwnerType == "user"
        if (isTraining && !isRouteOwnerAuthUser) {
            return false
        }
        return eventState == "live" && sessionState != "finished"
    }

    function isReadonly() {
        if (sessionIsFinished) return true;
        return !isRouteOwnerAuthUser && !isGroupAdmin;
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ActionBar location="main">
            {showSoloActionBarElements() && <>
                <UserInfo user={eventOwner} clickToShowProfile={true} style="avatar-only-small"/>
                <SelectInput
                    size="small"
                    style="important"
                    readonly={!isRouteOwnerAuthUser}
                    defaultValue={sessionLeaderboardGrouping}
                    onChange={updateLeaderboardGrouping}
                    options={leaderboardGroupingOptions()}/>
                <Divider style="action-bar"/>
            </>}
            {showTeamsActionBarElement() && <>
                <ActionBarSpacer/>
                <SelectInput
                    size="small"
                    label={dict("session.teamScoringMode.label")}
                    style="important"
                    readonly={isReadonly()}
                    defaultValue={sessionTeamScoringMode}
                    onChange={updateSessionTeamScoringMode}
                    options={sessionTeamScoringModeOptions()}/>
                <Divider style="action-bar"/>
            </>}
            {!showSoloActionBarElements() && !showTeamsActionBarElement() &&
                <ActionBarSpacer/>}
            {showSessionLogs() && <>
                <IconButton
                    type="warning"
                    iconColor={highlightSessionLog ? "yellow" : null}
                    size="small"
                    onClick={showLog}/>
                <Divider style="action-bar"/>
            </>}
            {showSpeaker() && <>
                <SessionSpeakerContextMenu/>
                <Divider style="action-bar"/>
            </>}
            {showGameID() && <>
                <GameIDInfo/>
                <Divider style="action-bar"/>
            </>}
            {showStateSelector() && <>
                <SessionStateSelector
                    sessionData={sessionData}
                    preventShortcutAction={true}
                    compactStateInfo={false}/>
                <Divider style="action-bar"/>
            </>}
            <IconButton
                type="settings"
                size="small"
                scale={0.9}
                onClick={() => state.showSessionSettings.setValue(true)}/>
            <Divider style="action-bar"/>
            <IconButton
                type="academy"
                size="small"
                onClick={() => state.showAcademy.setValue(dict("academy.id.session"))}/>
            <ActionBarSpacer/>
        </ActionBar>
    );

}
