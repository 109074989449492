import * as React from "react";
import {useEffect, useState} from "react";
import {Headline} from "../../../../../ui/text/headings/Headline";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {Input} from "../../../../../ui/form/elements/input/Input";
import {TextValidation} from "../../../../../../../shared/validation/TextValidation";
import {Modal} from "../../../Modal";
import {ISessionPresetData} from "../../../../../../../shared/models/ISessionPresetData";
import {useAuthUser} from "../../../../../hooks/useAuthUser";
import {useSessionState} from "../../../../../hooks/useSessionState";
import {useServices} from "../../../../../hooks/useServices";
import {Form} from "../../../../../ui/form/Form";
import {DriverLicenseUtils} from "../../../../../../../shared/utils/DriverLicenseUtils";
import {PlanFeatureDefinitions} from "../../../../../../../shared/plan/PlanFeatureDefinitions";
import {PlanAccessType} from "../../../../../../../shared/types/PlanAccessType";

/******************************************************************
 * CreatePresetModal
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function CreatePresetModal(props: {
    onClose: () => void
    onCreated: () => void
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {api, dict, state} = useServices();
    const {authUserID, authUserSubscription, authUserUseSubscription} = useAuthUser()
    const [presetName, setPresetName] = useState<string>(undefined)
    const {sessionSetup} = useSessionState()

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        if (!authUserUseSubscription) return;
        const validLicenseType = DriverLicenseUtils.validLicenseType(authUserSubscription)
        const isAllowed = PlanFeatureDefinitions.getFeatureAccess<PlanAccessType>("session-presets", validLicenseType)?.type == "full";
        if (!isAllowed) {
            state.showSubscriptionUpgradeInfo.setValue({message: dict("subscription.upgrade.info.sessionPresets")})
            props.onClose()
        }
    }, [])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function saveCustomPreset(): Promise<Response> {
        const sessionPreset: ISessionPresetData = {
            user: authUserID,
            name: presetName,
            setup: sessionSetup
        }
        const response = await api.presets.addSessionPreset(sessionPreset)
        if (response.status === 200) {
            props.onCreated()
            props.onClose()
        }
        return response
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal
            width="small"
            className="create-preset-modal"
            closeAction={props.onClose}>
            <Headline text={dict("session.preset.create.title")} style="modal"/>
            <ContentLayout>
                <Form
                    action={saveCustomPreset}
                    actionLabel={dict("session.preset.save.label")}>
                    <Input
                        type="text"
                        label={dict("session.preset.name.label")}
                        pattern={TextValidation.REGEXP.source}
                        onChange={setPresetName}/>
                </Form>
            </ContentLayout>
        </Modal>
    );

}
