import * as React from "react";

/******************************************************************
 * ChartIcon
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function ChartIcon(props: {
    scale?: number
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="chart-icon">
            <svg width={14 * (props.scale ?? 1)} height={12 * (props.scale ?? 1)} viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="4.44971" width="3" height="5" rx="1" fill="white"/>
                <rect x="4.5" y="0.449707" width="3" height="9" rx="1" fill="white"/>
                <rect x="8.5" y="2.44971" width="3" height="7" rx="1" fill="white"/>
            </svg>
        </div>
    );

}
