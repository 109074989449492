import {FrontendServices} from "../../core/FrontendServices";
import {useEffect, useState} from "react";
import {LanguageType} from "../../../shared/types/LanguageType";

/******************************************************************
 * ClientServices Hook
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

let coreServices: FrontendServices = null;

export function registerCoreServices(core: FrontendServices) {
    coreServices = core;
}

export function useServices ()  {

    const [language, setLanguage] = useState<LanguageType>(coreServices.state.language.getValue())

    useEffect(() => {
        function updateLanguage() {
            setLanguage(coreServices.state.language.getValue())
        }
        coreServices.state.language.onChangeSignal.add(updateLanguage)
        return () => coreServices.state.language.onChangeSignal.remove(updateLanguage)
    }, [])

    return {
        core: coreServices,
        state: coreServices.state,
        dict: (key: string) => coreServices.dict.get(key),
        api: coreServices.api,
        router: coreServices.router,
        error: coreServices.error,
        socket: coreServices.socket,
        signal: coreServices.signal,
        speaker: coreServices.speaker,
        time: coreServices.time,
        paddle: coreServices.paddleJS,
        language
    }
}
