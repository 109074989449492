import * as React from "react";

/******************************************************************
 * AngleDriftTargetIcon
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function AngleDriftTargetIcon(props: {
    scale?: number
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="angle-drift-target-icon">
            <svg width={22 * (props.scale ?? 1)} height={19 * (props.scale ?? 1)} viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0_1279_6817" style={{maskType: "alpha"}} maskUnits="userSpaceOnUse" x="0" y="6" width="22" height="7">
                    <rect y="8.94971" width="22" height="2" fill="white"/>
                    <rect y="11.9497" width="22" height="1" fill="white"/>
                    <rect y="6.94971" width="22" height="1" fill="white"/>
                </mask>
                <g mask="url(#mask0_1279_6817)">
                    <path d="M0.0652771 4.5686H13.3493L3.42124 14.0771H0.0652771V4.5686Z" fill="white"/>
                    <path d="M22 14.0771L10.0621 14.0771L19.9901 4.5686L22 4.5686L22 14.0771Z" fill="white"/>
                </g>
                <path fillRule="evenodd" clipRule="evenodd" d="M17.5905 4.77341L5.82373 16.5402L4.40952 15.126L16.1763 3.35919L17.5905 4.77341Z" fill="white"/>
            </svg>
        </div>
    );

}
