import * as React from "react";
import {ISessionLeaderboardEntry} from "../../../../../../shared/types/ISessionLeaderboardEntry";

/******************************************************************
 * TableLeaderboardPositionCell
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function TableLeaderboardPositionCell(props: {
    entry: ISessionLeaderboardEntry;
}) {

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function theme(): "dark" | "light" {
        switch (props.entry.state) {
            case "ready":
            case "driving":
                return "light"
            case "invalid":
            case "finished":
                return "dark"
        }
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="table-leaderboard-position-cell"
            data-all-rejected={props.entry.hasOnlyRejectedStints && props.entry.session.setup.useStintApproval}
            data-theme={theme()}>
            {props.entry.position}.
        </div>
    );

}
