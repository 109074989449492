import * as React from "react";
import {useEffect, useState} from "react";
import {Modal} from "../Modal";
import {Form} from "../../../ui/form/Form";
import {Headline} from "../../../ui/text/headings/Headline";
import {Input} from "../../../ui/form/elements/input/Input";
import {FrontendRoute} from "../../../../../shared/routes/FrontendRoute";
import {useServices} from "../../../hooks/useServices";
import {useEventState} from "../../../hooks/useEventState";
import {ISessionData, ISessionSetup} from "../../../../../shared/models/ISessionData";
import {SelectInput, SelectInputOptionType} from "../../../ui/form/elements/select/SelectInput";
import {Picture} from "../../../ui/image/Picture";
import {MongoObjectIDType} from "../../../../../shared/types/MongoObjectIDType";
import {ITrackData} from "../../../../../shared/models/submodels/ITrackData";
import {ContentLayout} from "../../../ui/layout/content/ContentLayout";
import {SessionSetupPresetSelectControl} from "./presets/SessionSetupPresetSelectControl";
import {SessionSetupPresets} from "../../../../../shared/config/SessionSetupPresets";
import {useDeviceInfo} from "../../../hooks/useDeviceInfo";
import {useRouteOwner} from "../../../hooks/useRouteOwner";
import {PlanFeatureDefinitions} from "../../../../../shared/plan/PlanFeatureDefinitions";
import {PlanAccessType} from "../../../../../shared/types/PlanAccessType";
import {DriverLicenseUtils} from "../../../../../shared/utils/DriverLicenseUtils";
import {useAuthUser} from "../../../hooks/useAuthUser";

/******************************************************************
 * CreateSessionModal
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function CreateSessionModal() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {api, dict, state, router} = useServices();
    const {isMobile} = useDeviceInfo()
    const {authUserSubscription, authUserUseSubscription} = useAuthUser()
    const {isRouteOwnerUser}  = useRouteOwner()
    const {
        eventID,
        eventPath,
        eventOwnerType,
        eventOwner,
        eventTracks} = useEventState()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [selectedTrackValue, setSelectedTrackValue] = useState<MongoObjectIDType>(eventTracks?.[0]?._id)
    const [selectedTrackData, setSelectedTrackData] = useState<ITrackData>()
    const [selectableTrackOptions, setSelectableTrackOptions] = useState<SelectInputOptionType<MongoObjectIDType>[]>()
    const [isTraining] = useState<boolean>(eventOwnerType == "user")
    const [sessionSetup, setSessionSetup] = useState<ISessionSetup>(isTraining
        ? SessionSetupPresets.DEFAULT_SOLO_BESTLAP_SETUP
        : SessionSetupPresets.DEFAULT_DRIVER_BESTLAP_SETUP)

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        if (isRouteOwnerUser || !authUserUseSubscription) return;
        const validLicenseType = DriverLicenseUtils.validLicenseType(authUserSubscription)
        const isAllowed = PlanFeatureDefinitions.getFeatureAccess<PlanAccessType>("events", validLicenseType)?.type == "full";
        if (!isAllowed) {
            state.showSubscriptionUpgradeInfo.setValue({message: dict("subscription.upgrade.info.session")})
            state.showCreateSession.setValue(null)
        }
    }, [])

    useEffect(() => {
        updateTrackSelection()
    }, [eventTracks, selectedTrackValue])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function updateTrackSelection() {
        const availableOptions = eventTracks?.map(track => ({
            value: track._id,
            text: track.name + (track.layout ? " | " + track.layout : "")
        }))
        setSelectableTrackOptions(availableOptions)
        setSelectedTrackData(eventTracks?.find(track => track._id == selectedTrackValue))
    }

    async function createSession(formData: {
        name: string,
        trackID: MongoObjectIDType
    }): Promise<Response> {
        const response = await api.session.createSession({
            name: formData.name,
            trackID: formData.trackID,
            setup: sessionSetup,
            eventID: eventID
        });
        if (response.status == 200) {
            const sessionData: ISessionData = await response.json();
            await router.showRoute(FrontendRoute.EVENT_SESSION(eventOwnerType, eventOwner.path, eventPath, sessionData.path));
            state.showCreateSession.setValue(false)
        }
        return response;
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal width="small" closeAction={() => state.showCreateSession.setValue(false)}>
            <Headline text={dict("session.create")} style="modal"/>
            {!!eventID && <Form
                actionLabel={dict("session.create")}
                action={createSession}>
                <ContentLayout>
                    <Input
                        type="text"
                        id="name"
                        focus={!isMobile}
                        required={true}
                        label={dict("session.create.name")}/>
                    <SessionSetupPresetSelectControl
                        onSetup={setSessionSetup}
                        mode="create"/>
                    {eventTracks?.length > 0 && <>
                        <SelectInput
                            id="trackID"
                            label={dict("session.track.selection.label")}
                            defaultValue={selectedTrackValue}
                            helpTopic={"session.track.selection"}
                            onChange={setSelectedTrackValue}
                            options={selectableTrackOptions}/>
                        {selectedTrackData?.image && <Picture file={selectedTrackData.image}/>}
                    </>}
                </ContentLayout>
            </Form>}
        </Modal>
    );

}
