import * as React from "react";
import {Modal} from "../Modal";
import {useServices} from "../../../hooks/useServices";
import {Headline} from "../../../ui/text/headings/Headline";
import {ContentLayout} from "../../../ui/layout/content/ContentLayout";
import {InfoText} from "../../../ui/text/infotext/InfoText";
import {LabelButton} from "../../../ui/buttons/label/LabelButton";
import {ArticleTeaser} from "../../../ui/teaser/article/ArticleTeaser";

/******************************************************************
 * AcceptLegalModal
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function AcceptLegalModal() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {state, dict, api} = useServices();

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [busy, setBusy] = React.useState(false);

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function updateLegalVersion() {
        setBusy(true);
        const latestVersion = await api.user.getLatestLegalVersion()
        if (!latestVersion) {
            return
        }
        await api.user.update({legalVersion: latestVersion})
        state.showAcceptLegal.setValue(false);
    }

    async function close() {
        state.showAcceptLegal.setValue(false);
        await api.user.logout();
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal
            className="accept-legal-modal"
            closeAction={close}
            width="medium">
            <Headline
                text={dict("accept.legal.modal.headline")}
                style="modal"/>
            <ContentLayout>
                <InfoText text={dict("accept.legal.modal.text")}/>
                <ArticleTeaser articleID={dict("article.id.termsofuse")}/>
                <ArticleTeaser articleID={dict("article.id.privacy")}/>
                <LabelButton
                    label={dict("accept.legal.modal.button")}
                    style="primary"
                    progressing={busy}
                    onClick={updateLegalVersion}/>
            </ContentLayout>
        </Modal>
    );

}
