import * as React from "react";
import {useEffect} from "react";
import {useServices} from "../../components/hooks/useServices";
import {FrontendConfig} from "../FrontendConfig";
import {useSocketState} from "../../components/hooks/useSocketState";
import {useAuthUser} from "../../components/hooks/useAuthUser";

/******************************************************************
 * UpdateChecker
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function UpdateChecker() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {state, api} = useServices()
    const {authUserID, authUserExists} = useAuthUser()
    const {isSocketConnected} = useSocketState()

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        checkForUpdate()
        const interval = setInterval(() => {
            checkForUpdate()
        }, FrontendConfig.APP_UPDATE_CHECK_INTERVAL)
        return () => clearInterval(interval)
    }, [isSocketConnected, authUserID])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function checkForUpdate() {
        if (state.showUpdateApp.getValue() == true) return
        if (!isSocketConnected) return
        const version = await api.info.getServerVersion()
        const isNewAppVersionAvailable = version !== FrontendConfig.SERVER_VERSION
        if (isNewAppVersionAvailable) {
            state.showUpdateApp.setValue(true)
            return
        }
        if (!authUserExists) return
        const isNewLegalVersionAvailable = await api.user.isNewLegalVersionAvailableForUser()
        if (isNewLegalVersionAvailable) {
            if (state.showAcceptLegal.getValue() == true) return
            state.showAcceptLegal.setValue(true)
            return
        }
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return <></>

}
