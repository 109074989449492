import * as React from "react";
import {ContentLayout} from "../../../../ui/layout/content/ContentLayout";
import {useServices} from "../../../../hooks/useServices";
import {ISessionData} from "../../../../../../shared/models/ISessionData";
import {useEventState} from "../../../../hooks/useEventState";
import {SessionStartTimeDelayValidation} from "../../../../../../shared/validation/SessionStartTimeDelayValidation";
import {Input} from "../../../../ui/form/elements/input/Input";

/******************************************************************
 * ConfirmResetCustomElement
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function ConfirmResetCustomElement(props: {
    sessionData: ISessionData
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, api} = useServices();
    const {
        eventID,
        eventResetSessionStartTimeDelay,
        eventIsFinished
    } = useEventState();

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function updateResetSessionStartTimeDelay(value: string) {
        return api.event.update(eventID, {resetSessionStartTimeDelay: parseInt(value)});
    }

    function placeholder() {
        return SessionStartTimeDelayValidation.MIN_MINUTES
            + "..." + SessionStartTimeDelayValidation.MAX_MINUTES
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return <>
        {!!props.sessionData?.setup?.startTime &&
            <ContentLayout framed={true}>
                <Input
                    type="number"
                    readonly={eventIsFinished}
                    minNumber={SessionStartTimeDelayValidation.MIN_MINUTES}
                    maxNumber={SessionStartTimeDelayValidation.MAX_MINUTES}
                    stepNumber={1}
                    label={dict("event.settings.session.resetSessionStartTimeDelay.label")}
                    helpTopic={"event.settings.session.resetSessionStartTimeDelay"}
                    placeholder={placeholder()}
                    defaultValue={eventResetSessionStartTimeDelay?.toString()}
                    action={updateResetSessionStartTimeDelay}/>
            </ContentLayout>}
    </>

}
