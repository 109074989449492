import {useEffect, useState} from "react";
import {DeviceUtils} from "../../utils/DeviceUtils";
import {useWindowSize} from "./useWindowSize";

/******************************************************************
 * useDeviceInfo
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function useDeviceInfo() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {windowWidth, windowHeight} = useWindowSize()

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [hasTouch, setHasTouch] = useState<boolean>(DeviceUtils.isTouchDevice());
    const [isMobile, setIsMobile] = useState(computeIsMobile());

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        setHasTouch(DeviceUtils.isTouchDevice())
        setIsMobile(computeIsMobile())
    }, [windowWidth, windowHeight])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function computeIsMobile(): boolean {
        const appLayoutElement = document.getElementsByClassName('app-layout')[0]
        if (!appLayoutElement) return false
        const mode = window.getComputedStyle(appLayoutElement, ':before')
            .getPropertyValue('content')
            .replace(/"/g, '')
            .trim();
        return mode === 'mobile'
    }

    /* ----------------------------------------------------------------
     * RETURN
     * --------------------------------------------------------------*/

    return {
        hasTouch,
        isMobile
    }

}
