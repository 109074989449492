import {ITeamData} from "../models/submodels/ITeamData";
import {IUserData} from "../models/IUserData";
import {MongoObjectIDType} from "../types/MongoObjectIDType";
import {ISessionLeaderboardEntry} from "../types/ISessionLeaderboardEntry";
import {ISessionData} from "../models/ISessionData";
import {IStintData} from "../models/IStintData";
import {SharedConfig} from "../config/SharedConfig";
import {IDrivenTimeAtLap} from "../types/IDrivenTimeAtLap";
import {DriftTuningType} from "../types/DriftTuningType";

/******************************************************************
 * LeaderboardUtils
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class LeaderboardUtils {

    static getTeamForUser(user: IUserData, teams: ITeamData[]): ITeamData {
        if (!user || !teams) {
            return null;
        }
        return teams?.find(team => {
            return team.members.some((member: IUserData | MongoObjectIDType) => {
                if (member.equals) {
                    return member.equals(user._id)
                }
                return member._id?.equals(user._id)
            })
        });
    }

    static getTeamMemberIDs(team: ITeamData): MongoObjectIDType[] {
        return team.members.map((member: IUserData | MongoObjectIDType) => {
            if (member._id) {
                return member._id
            }
            return member
        })
    }

    static getEarliestSignalTimestamp(leaderboardEntries: ISessionLeaderboardEntry[]): string {
        if (!leaderboardEntries || leaderboardEntries.length === 0) {
            return undefined;
        }
        let earliestTimestamp: string = '';
        for (const entry of leaderboardEntries) {
            if (entry.overall?.firstSignalTimestamp) {
                if (!earliestTimestamp) {
                    earliestTimestamp = entry.overall.firstSignalTimestamp;
                } else {
                    const currentTimestamp = Date.parse(entry.overall.firstSignalTimestamp);
                    const earliestTimestampValue = Date.parse(earliestTimestamp);
                    if (currentTimestamp < earliestTimestampValue) {
                        earliestTimestamp = entry.overall.firstSignalTimestamp;
                    }
                }
            }
        }
        return earliestTimestamp;
    }

    static getMaxOverallDrivenLaps(sessionLeaderboard: ISessionLeaderboardEntry[]): number {
        if (!sessionLeaderboard || sessionLeaderboard.length === 0) {
            return undefined;
        }
        let maxDrivenLaps = 0;
        for (const entry of sessionLeaderboard) {
            if (entry.overall?.drivenLaps) {
                if (entry.overall.drivenLaps > maxDrivenLaps) {
                    maxDrivenLaps = entry.overall.drivenLaps;
                }
            }
        }
        return maxDrivenLaps;
    }

    static showJokerLap(session: ISessionData): boolean {
        if (!session.setup.numJokerLaps || session.setup.numJokerLaps === 0) {
            return false
        }
        if (!session.setup.jokerLapPenalty || session.setup.jokerLapPenalty === 0) {
            return false
        }
        if (!session.setup.jokerLapTarget || session.setup.jokerLapTarget == "none") {
            return false
        }
        return true
    }

    static showPoints(session: ISessionData): boolean {
        if (session.state != "finished") return false
        if (session.setup.mode != "race") {
            return session.setup.points?.length > 0
        }
        return session.setup.points?.length > 0 || session.setup.fastestLapPoints > 0
    }

    static showTimer(session: ISessionData): boolean {
        return session.state === "running"
            && session.setup.timing === "async"
            && session.setup.finishType == "duration"
    }

    static computeExcessJokerLaps(stintsData: IStintData[], session: ISessionData): number {
        if (!this.showJokerLap(session)) return 0
        const drivenJokerLaps = stintsData.reduce((sum, stintData) => sum + (stintData.drivenJokerLaps ?? 0), 0)
        const numJokerLaps = session.setup.numJokerLaps ?? 0
        const excessJokerLaps = drivenJokerLaps - numJokerLaps
        if (excessJokerLaps > 0) {
            return excessJokerLaps
        }
        return 0
    }

    static isAnyDriverDrivingOrFinished(entries: ISessionLeaderboardEntry[]): boolean {
        if (!entries || entries.length === 0) return false
        return entries.some(entry => {
            switch (entry.state) {
                case "driving":
                case "finished":
                    return true
            }
            return false
        })
    }

    static mergeLeaderboardEntries(
        startOrderEntries: ISessionLeaderboardEntry[],
        sessionEntries: ISessionLeaderboardEntry[]
    ): ISessionLeaderboardEntry[] {
        const mergedLeaderboardData = startOrderEntries?.map(startOrderEntry => {
            const sessionEntry = sessionEntries?.find(sessionEntry => {
                return sessionEntry.latestStint.user._id === startOrderEntry.latestStint.user._id
            })
            return sessionEntry ?? startOrderEntry
        })
        const newEntries = sessionEntries?.filter(sessionEntry => {
            return !mergedLeaderboardData.find(mergedEntry => {
                return mergedEntry.latestStint.user._id === sessionEntry.latestStint.user._id
            })
        })
        mergedLeaderboardData.push(...newEntries)
        mergedLeaderboardData.forEach((entry, index) => {
            entry.position = index + 1
        })
        return mergedLeaderboardData
    }

    static getBestLapTimeEntry(leaderboard: ISessionLeaderboardEntry[]) {
        const entries = leaderboard?.filter(entry => entry.overall?.bestLapTime)
        entries?.sort((entryA, entryB) => {
            if (!entryA.overall?.bestLapTime) return 1
            if (!entryB.overall?.bestLapTime) return -1
            return entryA.overall.bestLapTime - entryB.overall.bestLapTime
        })
        return entries?.[0]
    }

    static getBestScoreEntry(leaderboard: ISessionLeaderboardEntry[]) {
        const entries = leaderboard?.filter(entry => {
            return entry.bestGymkhanaStint?.score !== undefined || entry.latestStint?.score !== undefined
        })
        entries?.sort((entryA, entryB) => {
            let scoreA = Math.max(entryA.bestGymkhanaStint?.score ?? 0, entryA.latestStint?.score ?? 0);
            let scoreB = Math.max(entryB.bestGymkhanaStint?.score ?? 0, entryB.latestStint?.score ?? 0);
            return scoreB - scoreA
        })
        return entries?.[0]
    }

    static findEntryByStintID(
        leaderboardEntries: ISessionLeaderboardEntry[],
        stintID: MongoObjectIDType
    ): ISessionLeaderboardEntry | null {
        for (const entry of leaderboardEntries) {
            if (entry?.stintIDs?.includes(stintID)) {
                return entry;
            }
        }
        return null;
    }

    static findMostMatchingEntry(
        latestLeaderboard: ISessionLeaderboardEntry[],
        outdatedLeaderboardEntry: ISessionLeaderboardEntry
    ): ISessionLeaderboardEntry | null {
        let mostMatchingEntry: ISessionLeaderboardEntry | null = null;
        let maxCommonStintIDs = 0;
        let allEntries: ISessionLeaderboardEntry[] = latestLeaderboard ? [...latestLeaderboard] : []
        latestLeaderboard?.forEach(entry => {
            entry.team?.leaderboardEntries?.forEach(teamEntry => {
                allEntries.push(teamEntry)
            })
        })
        for (const entry of allEntries) {
            const isTeam = outdatedLeaderboardEntry.team || entry.type == "secondary-team"
            const isNotATeam = !entry.team && !outdatedLeaderboardEntry.team
            if (isTeam || isNotATeam) {
                const commonStintIDs = entry.stintIDs.filter((stintID) =>
                    outdatedLeaderboardEntry.stintIDs.includes(stintID)
                );
                if (commonStintIDs.length > maxCommonStintIDs) {
                    maxCommonStintIDs = commonStintIDs.length;
                    mostMatchingEntry = entry;
                }
            }
        }
        return mostMatchingEntry;
    }

    static timeGapToHeadingEntry(
        entry: ISessionLeaderboardEntry,
        headingEntry: ISessionLeaderboardEntry
    ): number {
        if (headingEntry === entry) return null
        if (headingEntry?.overall?.drivenLaps > 0
            && entry?.overall?.drivenLaps == headingEntry?.overall?.drivenLaps) {
            return (entry?.overall?.drivenTimeSinceOverallFirstStartSignal ?? 0) - (headingEntry?.overall?.drivenTimeSinceOverallFirstStartSignal ?? 0)
        }
        const lapGapToReference = LeaderboardUtils.lapGapToHeadingEntry(entry, headingEntry)
        if (lapGapToReference < SharedConfig.STINT_DRIVEN_TIME_AT_LAP_HISTORY_LENGTH) {
            const referenceDrivenTimeAtHistoryLap = LeaderboardUtils.getDrivenTimeAtHistoryLap(entry.overall.drivenLaps, headingEntry)
            if (referenceDrivenTimeAtHistoryLap === undefined) {
                return null
            }
            const referenceDrivenTime = headingEntry.overall.drivenTimeSinceOverallFirstStartSignal ?? 0
            const entryDrivenTime = entry.overall.drivenTimeSinceOverallFirstStartSignal ?? 0
            return (entryDrivenTime - referenceDrivenTimeAtHistoryLap) + (referenceDrivenTime - referenceDrivenTimeAtHistoryLap)
        }
        return null
    }

    static lapGapToHeadingEntry(
        entry: ISessionLeaderboardEntry,
        headingEntry: ISessionLeaderboardEntry
    ): number {
        if (headingEntry === entry) return null
        const driverDrivenLaps = entry.overall.drivenLaps
        const leaderDrivenLaps = headingEntry.overall.drivenLaps
        const gap = (leaderDrivenLaps ?? 0) - (driverDrivenLaps ?? 0)
        return gap < 0 ? 0 : gap
    }

    static getDrivenTimeAtHistoryLap(lap: number, historyLapEntry: ISessionLeaderboardEntry): number {
        const historyLaps = historyLapEntry?.overall?.drivenTimeAtLapSinceOverallFirstStartSignal
        return historyLaps?.find((drivenTimePerLap: IDrivenTimeAtLap) => {
            return drivenTimePerLap.lap === lap
        })?.drivenTime
    }

    static getUnifiedClubsportTuningValue(tuning: DriftTuningType): DriftTuningType {
        if (tuning.toLowerCase().includes("club")) {
            return "club"
        }
        return tuning
    }


}
