import * as React from "react";
import {TrackSidebarImage} from "../../../ui/image/track/TrackSidebarImage";
import {TrackSidebarInfo} from "./info/TrackSidebarInfo";
import {TrackSidebarMenu} from "./menu/TrackSidebarMenu";

/******************************************************************
 * TrackSidebar
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function TrackSidebar() {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <section className="track-sidebar">
            <TrackSidebarImage/>
            <TrackSidebarInfo/>
            <TrackSidebarMenu/>
        </section>
    );

}
