import * as React from "react";

/******************************************************************
 * PeopleIcon
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function PeopleIcon(props: {
    scale?: number
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="people-icon">
            <svg width={24 * (props.scale ?? 1)} height={24 * (props.scale ?? 1)} viewBox="0 0 24 24" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M15.75 12C14.7862 12 13.8544 11.5697 13.125 10.7888C12.4158 10.027 11.9827 9.01125 11.9062 7.92938C11.8247 6.77531 12.1767 5.71406 12.8972 4.94063C13.6177 4.16719 14.625 3.75 15.75 3.75C16.867 3.75 17.8772 4.17469 18.5953 4.94625C19.3205 5.72531 19.6734 6.78469 19.5919 7.92891C19.5136 9.01219 19.0809 10.0275 18.3731 10.7883C17.6456 11.5697 16.7142 12 15.75 12Z"
                    fill="white"/>
                <path
                    d="M21.9295 20.25H9.57093C9.37223 20.2511 9.17594 20.2065 8.99716 20.1198C8.81838 20.0331 8.66187 19.9065 8.53968 19.7498C8.41007 19.58 8.32057 19.383 8.27787 19.1736C8.23517 18.9643 8.24039 18.748 8.29312 18.5409C8.68781 16.9561 9.66562 15.6417 11.1206 14.7403C12.412 13.9406 14.0559 13.5 15.75 13.5C17.4773 13.5 19.0781 13.9219 20.377 14.7211C21.8353 15.6178 22.8145 16.9397 23.2073 18.5438C23.2594 18.751 23.2641 18.9673 23.2209 19.1765C23.1777 19.3857 23.0878 19.5825 22.958 19.7522C22.8359 19.9082 22.6798 20.0341 22.5015 20.1204C22.3232 20.2067 22.1276 20.251 21.9295 20.25Z"
                    fill="white"/>
                <path
                    d="M6.89062 12.1875C5.24109 12.1875 3.79077 10.6538 3.65624 8.76891C3.58968 7.80328 3.89062 6.91031 4.49999 6.25547C5.1028 5.60719 5.95312 5.25 6.89062 5.25C7.82812 5.25 8.67187 5.60906 9.27796 6.26109C9.89202 6.92109 10.192 7.81219 10.1217 8.76984C9.98718 10.6542 8.53734 12.1875 6.89062 12.1875Z"
                    fill="white"/>
                <path
                    d="M9.96844 13.6617C9.14391 13.2586 8.07376 13.057 6.8911 13.057C5.51016 13.057 4.16907 13.417 3.11438 14.0705C1.9186 14.8125 1.11423 15.893 0.789383 17.1975C0.741844 17.3851 0.737348 17.5811 0.77623 17.7707C0.815112 17.9603 0.896366 18.1387 1.01391 18.2925C1.12545 18.4357 1.26834 18.5514 1.4316 18.6307C1.59486 18.71 1.77413 18.7508 1.95563 18.75H7.15876C7.24658 18.75 7.33161 18.7192 7.39902 18.6629C7.46644 18.6066 7.51197 18.5284 7.52766 18.442C7.53282 18.4125 7.53938 18.383 7.54688 18.3539C7.94438 16.7573 8.87579 15.4083 10.252 14.4249C10.3027 14.3884 10.3434 14.3398 10.3705 14.2836C10.3976 14.2274 10.4103 14.1653 10.4073 14.103C10.4044 14.0407 10.386 13.9801 10.3537 13.9267C10.3214 13.8733 10.2763 13.8288 10.2225 13.7972C10.1489 13.7541 10.0645 13.7086 9.96844 13.6617Z"
                    fill="white"/>
            </svg>
        </div>
    );

}
