import * as React from "react";
import {useEffect, useState} from "react";
import {IUserData} from "../../../../../shared/models/IUserData";
import {useServices} from "../../../hooks/useServices";
import {FrontendRoute} from "../../../../../shared/routes/FrontendRoute";
import {GroupMemberRoleType} from "../../../../../shared/types/GroupMemberRoleType";
import {MemberContextMenu} from "../../context/group/MemberContextMenu";
import {useGroupState} from "../../../hooks/useGroupState";
import {useAuthUser} from "../../../hooks/useAuthUser";
import {Picture} from "../../image/Picture";
import {DriverLicenseBadge} from "../license/DriverLicenseBadge";
import {SubscriptionUtils} from "../../../../utils/SubscriptionUtils";
import {DriverLicenseType} from "../../../../../shared/types/DriverLicenseType";
import {DriverLicenseUtils} from "../../../../../shared/utils/DriverLicenseUtils";

/******************************************************************
 * MemberInfo
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function MemberInfo(props: {
    member: IUserData
    role?: GroupMemberRoleType
}) {

    /* ----------------------------------------------------------------
 	 * SERVIVES
 	 * --------------------------------------------------------------*/

    const {router, dict} = useServices();
    const {authUserID} = useAuthUser()
    const {groupOwner, isGroupAdmin} = useGroupState();

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [focus, setFocus] = useState<boolean>();
    const [defaultImageSource, setDefaultImageSource] = useState<string | null>(null)

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        if (props.member) {
            setDefaultImageSource(require("ASSETS/images/default-user-avatar.png"))
            return
        }
        setDefaultImageSource(require("ASSETS/images/user-not-found-avatar.png"))
    }, [props.member])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/


    function showOptions(): boolean {
        const isAuthUser = authUserID == props.member?._id
        const isGroupOwner = groupOwner?._id == props.member?._id
        if (isGroupOwner) return false
        return isAuthUser || isGroupAdmin
    }

    /* ----------------------------------------------------------------
 	 * EVENTS
 	 * --------------------------------------------------------------*/

    function onClick() {
        return router.showRoute(FrontendRoute.USER(props.member.path))
    }

    function onContextMenuChanged(showContextMenu: boolean) {
        setFocus(showContextMenu);
    }

    function useSubscription(): boolean {
        return SubscriptionUtils.useSubscriptionService(props.member)
    }

    function validDriverLicense(): DriverLicenseType {
        return DriverLicenseUtils.validLicenseType(props.member?.subscription)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="member-info"
            data-focus={focus}
            data-error={!props.member}>
            <div
                className="member-info-avatar"
                onClick={onClick}>
                <Picture
                    file={props.member?.avatar}
                    alt={props.member?.nick}
                    defaultSource={defaultImageSource}/>
                <div className="member-info-role" data-role={props.role}>
                    {dict("group.member.role." + props.role)}
                </div>
                {useSubscription() &&
                    <DriverLicenseBadge
                        licenseType={validDriverLicense()}
                        size="extra-large"/>}
                {showOptions() && <MemberContextMenu
                    member={props.member}
                    role={props.role}
                    onChange={onContextMenuChanged}/>}
            </div>
            <div className="member-info-footer">
                <h6 onClick={onClick}>{props.member?.nick ?? dict("user.not.found")}</h6>
            </div>
        </div>
    );

}
