import * as React from "react";
import {IPaymentData} from "../../../../../shared/types/IPaymentData";
import {ContentLayout} from "../../layout/content/ContentLayout";
import {Headline} from "../../text/headings/Headline";
import {useServices} from "../../../hooks/useServices";
import {TimeUtils} from "../../../../../shared/utils/TimeUtils";
import {AmountUtils} from "../../../../utils/AmountUtils";
import ReactMarkdown from "react-markdown";

/******************************************************************
 * PaymentInfo
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function PaymentInfo(props: {
    data: IPaymentData
}) {

    /* ----------------------------------------------------------------
    * HOOKS
    * --------------------------------------------------------------*/

    const {dict} = useServices();

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function amount() {
        return props.data.balance ?? props.data.total
    }

    function reducedBillingPeriodAmount() {
        if (!props.data.balance) return 0;
        if (!props.data.price) return 0;
        return props.data.price - props.data.balance - (props.data.credit ?? 0)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout className="payment-info">
            <ContentLayout
                justifyContent="space-between"
                columnTemplate="1fr 1fr">
                <Headline
                    text={dict(`plan.change.${props.data.type}.previewtitle`)
                        .replaceAll("{DATE}", TimeUtils.formatDate(props.data.billingAt, false, true))}
                    style="h4"/>
                <ContentLayout gap="tiny" justifyItems="end">
                    <div className="payment-info-total">
                        {AmountUtils.format(amount(), props.data.currency)}
                        {props.data.billingInterval && <>
                            <div className="payment-info-total-billing-interval">
                                {dict(`amount.interval.${props.data.billingInterval}`)}
                            </div>
                            <div className="payment-info-cancel-interval">
                                {dict("plan.cancel." + props.data.billingInterval)}
                            </div>
                        </>}
                    </div>
                    {amount() > 0 &&
                        <ReactMarkdown className="payment-info-tax-info">
                            {dict("plan.change.preview.tax.info")
                                .replaceAll("{TAX_RATE}", (props.data.taxRate * 100).toString())
                                .replaceAll("{TAX}", AmountUtils.format(props.data.tax, props.data.currency, "(", ")"))}
                        </ReactMarkdown>}
                </ContentLayout>
            </ContentLayout>
            {(!!props.data.credit || reducedBillingPeriodAmount() > 0) &&
                <ContentLayout gap="tiny">
                    {!!props.data.credit &&
                        <ReactMarkdown className="payment-info-credit">
                            {dict("plan.change.preview.credit")
                                .replaceAll("{CREDIT}", AmountUtils.format(props.data.credit, props.data.currency))}
                        </ReactMarkdown>}
                    {reducedBillingPeriodAmount() >= 0.01 &&
                        <ReactMarkdown className="payment-info-reduced-billing-period">
                            {dict("plan.change.preview.reduced.billing.period")
                                .replaceAll("{AMOUNT}", AmountUtils.format(reducedBillingPeriodAmount(), props.data.currency))}
                        </ReactMarkdown>}
                </ContentLayout>}
        </ContentLayout>

    );
}
