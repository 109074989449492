import * as React from "react";

/******************************************************************
 * InputDivider
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function InputDivider() {
    return (
        <div className="input-divider"></div>
    );
}
