import * as React from "react";

/******************************************************************
 * SpeakerDisbaledIcon
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function SpeakerDisbaledIcon(props: {
    scale?: number
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="speaker-icon">
            <svg width={16 * (props.scale ?? 1)} height={19 * (props.scale ?? 1)} viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_1301_6908)">
                    <mask id="mask0_1301_6908" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="1" y="0" width="14" height="19">
                        <path d="M14.2134 8.56871V9.94946C14.2134 11.5388 13.683 12.9213 12.6223 14.0971C11.5615 15.2729 10.2509 15.9471 8.69037 16.1197V17.5436H11.4519C11.6388 17.5436 11.8006 17.6119 11.9373 17.7485C12.0739 17.8852 12.1422 18.047 12.1422 18.2339C12.1422 18.4209 12.0739 18.5827 11.9373 18.7194C11.8006 18.856 11.6388 18.9243 11.4519 18.9243H4.54812C4.36114 18.9243 4.19934 18.856 4.0627 18.7194C3.92606 18.5827 3.85775 18.4209 3.85775 18.2339C3.85775 18.047 3.92606 17.8852 4.0627 17.7485C4.19934 17.6119 4.36114 17.5436 4.54812 17.5436H7.30961V16.1197C5.74908 15.9471 4.43845 15.2729 3.37772 14.0971C2.31699 12.9213 1.78662 11.5388 1.78662 9.94946V8.56871C1.78662 8.38174 1.85494 8.21993 1.99158 8.0833C2.12821 7.94666 2.29002 7.87834 2.47699 7.87834C2.66397 7.87834 2.82578 7.94666 2.96241 8.0833C3.09905 8.21993 3.16737 8.38174 3.16737 8.56871V9.94946C3.16737 11.2799 3.6402 12.4179 4.58587 13.3636C5.53154 14.3092 6.66957 14.7821 7.99998 14.7821C9.33039 14.7821 10.4684 14.3092 11.4141 13.3636C12.3598 12.4179 12.8326 11.2799 12.8326 9.94946V8.56871C12.8326 8.38174 12.9009 8.21993 13.0375 8.0833C13.1742 7.94666 13.336 7.87834 13.523 7.87834C13.7099 7.87834 13.8718 7.94666 14.0084 8.0833C14.145 8.21993 14.2134 8.38174 14.2134 8.56871ZM11.4519 4.42647V9.94946C11.4519 10.8987 11.1139 11.7114 10.4379 12.3873C9.76189 13.0633 8.94927 13.4013 8 13.4013C7.05074 13.4013 6.23811 13.0633 5.56212 12.3873C4.88613 11.7114 4.54814 10.8987 4.54814 9.94946V4.42647C4.54814 3.47721 4.88613 2.66459 5.56212 1.9886C6.23811 1.31261 7.05074 0.974609 8 0.974609C8.94927 0.974609 9.76189 1.31261 10.4379 1.9886C11.1139 2.66459 11.4519 3.47721 11.4519 4.42647Z" fill="white"/>
                    </mask>
                    <g mask="url(#mask0_1301_6908)">
                        <path d="M0.377014 0.949707H14.1065L0.377014 14.4027V0.949707Z" fill="white"/>
                        <path d="M15.3738 18.9497L2.59495 18.9497L15.3738 5.95376L15.3738 18.9497Z" fill="white"/>
                    </g>
                    <path d="M1.43106 16.7524L15.0365 3.14697" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                </g>
                <defs>
                    <clipPath id="clip0_1301_6908">
                        <rect width="16" height="18" fill="white" transform="translate(0 0.949707)"/>
                    </clipPath>
                </defs>
            </svg>
        </div>
    );

}
