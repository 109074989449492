import {useSimulatorAPI} from "./useSimulatorAPI";
import {ISimulatorDriverData} from "../drivers/card/ISimulatorDriverData";
import {TimeUtils} from "../../../../../../../shared/utils/TimeUtils";
import {PromisedDelay} from "@webfruits/toolbox/dist/timer/PromisedDelay";
import {useServices} from "../../../../../hooks/useServices";
import {useRef} from "react";

/******************************************************************
 * useSimulatorComputer
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function useSimulatorComputer(props: {
    driver: ISimulatorDriverData
}) {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const scoreRef = useRef(0)

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {time} = useServices()
    const {enter, start, target, end} = useSimulatorAPI()

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/


    function runSimulator() {
        const enterTime = time.date
        const waitStartDelay = 2
        const waitTargetDelay = 2
        const waitEndDelay = 2
        const startTime = new Date(enterTime.getTime() + waitStartDelay * 1000);
        let targetTime = new Date(startTime.getTime() + 200)
        let drivenTime = TimeUtils.calcTimeInSeconds(targetTime, startTime)
        scoreRef.current = 0
        sendEnterEvent()
        sendStartEvent(startTime, waitStartDelay * props.driver.timeScale)
        if (props.driver.mode !== "GYMKHANA") {
            sendTargetEvent({
                crossingTime: targetTime.toISOString(),
                targetCode: 0,
                drivenTime: drivenTime
            }, (drivenTime + waitStartDelay + waitTargetDelay) * props.driver.timeScale)
        }
        for (let lap = 0; lap < props.driver.laps; lap++) {
            const prevLapTargetTime = targetTime.getTime()
            const lapTime = getRandomLapTime()
            const sectorTime = lapTime / props.driver.sectors
            for (let sector = 0; sector < (props.driver.sectors - 1); sector++) {
                targetTime = new Date(targetTime.getTime() + (sectorTime * (Math.random() * 0.2 + 0.9)) * 1000)
                drivenTime = TimeUtils.calcTimeInSeconds(targetTime, startTime)
                sendTargetEvent({
                    crossingTime: targetTime.toISOString(),
                    targetCode: getSectorTargetCode(),
                    drivenTime: drivenTime
                }, (drivenTime + waitStartDelay + waitTargetDelay) * props.driver.timeScale)
            }
            targetTime = new Date(prevLapTargetTime + lapTime * 1000)
            drivenTime = TimeUtils.calcTimeInSeconds(targetTime, startTime)
            sendTargetEvent({
                crossingTime: targetTime.toISOString(),
                targetCode: 0,
                drivenTime: drivenTime,
                isFinish: lap === props.driver.laps - 1
            }, (drivenTime + waitStartDelay + waitTargetDelay) * props.driver.timeScale)
        }
        sendEndEvent({
            finishedTime: new Date(targetTime.getTime() + 1000).toISOString(),
            totalDrivenTime: drivenTime
        }, (drivenTime + waitStartDelay + waitTargetDelay) * props.driver.timeScale + waitEndDelay)
    }

    function endSimulator() {
        sendEndEvent({
            finishedTime: TimeUtils.getCurrentIsoTimeString(time.date),
            totalDrivenTime: 0
        }, null)
    }

    async function sendEnterEvent() {
        await enter(props.driver.gameID, props.driver.user.capiPassword, props.driver.user.nick, {
            game_mode: props.driver.mode,
            lap_count: props.driver.laps,
            setup_mode: props.driver.setup ?? "RACE",
            track_condition: props.driver.trackCondition ?? "drift_asphalt",
            wheels: props.driver.tires ?? "normal",
            track_bundle: props.driver.trackBundle,
            start_time: props.driver.startTime,
            engine_type: props.driver.carEngine,
            tuning_type: props.driver.carTuning,
            car_name: props.driver.carName,
            drift_assistant: false,
            soft_steering: false,
            steering_angle: 70
        })
    }

    async function sendStartEvent(time: Date, wait: number) {
        if (wait) {
            await PromisedDelay.wait(wait)
        }
        await start(props.driver.gameID, props.driver.user.capiPassword, props.driver.user.nick, {
            signal_time: time.toISOString()
        })
    }

    async function sendTargetEvent(data: {
        crossingTime: string,
        targetCode: number,
        drivenTime: number,
        isFinish?: boolean
    }, wait: number) {
        if (wait) {
            await PromisedDelay.wait(wait)
        }
        let score = 0;
        let orientationsData = null
        if (props.driver.mode === "GYMKHANA") {
            if (data.isFinish || data.targetCode !== 0) {
                score = Math.round(Math.random() * 2000);
            }
            if (data.targetCode !== 0) {
                orientationsData = []
                const dataLengthByTargetCode = {
                    4: 1,
                    5: 1,
                    6: 5,
                    7: 5,
                }
                for (let i = 0; i < dataLengthByTargetCode[data.targetCode]; i++) {
                    orientationsData.push({
                        speed: Math.random() * 7 + 2,
                        angle: Math.random() * 60 + 10
                    })
                }
            }
        }
        scoreRef.current += score
        await target(props.driver.gameID, props.driver.user.capiPassword, props.driver.user.nick, {
            crossing_time: data.crossingTime,
            target_code: data.targetCode,
            false_start: props.driver.falseStart,
            driven_distance: 0, // TODO: calculate driven distance
            driven_time: data.drivenTime,
            score: score,
            orientations: orientationsData
        })
    }

    async function sendEndEvent(data: {
        finishedTime: string
        totalDrivenTime: number
    }, wait: number) {
        if (wait) {
            await PromisedDelay.wait(wait)
        }
        const isGymkhana = props.driver.mode === "GYMKHANA"
        await end(props.driver.gameID, props.driver.user.capiPassword, props.driver.user.nick, {
            finished_time: data.finishedTime,
            false_start: props.driver.falseStart,
            total_score: isGymkhana ? scoreRef.current : 0,
            total_driven_distance: 0,
            total_driven_time: data.totalDrivenTime
        })
    }

    function getSectorTargetCode() {
        const availableCodes = props.driver.sectorTargetCodes.split(",")
        const randomIndex = Math.floor(Math.random() * availableCodes.length)
        return parseInt(availableCodes[randomIndex])
    }

    function getRandomLapTime(): number {
        const offsetTime = props.driver.maxLapTime - props.driver.minLapTime
        return props.driver.minLapTime + Math.random() * offsetTime
    }

    /* ----------------------------------------------------------------
     * PUBLIC
     * --------------------------------------------------------------*/

    return {
        runSimulator,
        endSimulator
    };

}
