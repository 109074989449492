import * as React from "react";
import {useEffect, useState} from "react";
import {IStintData} from "../../../../../../../shared/models/IStintData";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {useServices} from "../../../../../hooks/useServices";
import {Table} from "../../../../../ui/table/Table";
import {ITableColumnDefinition} from "../../../../../ui/table/ITableColumnDefinition";
import {IStintLapData} from "../../../../../../../shared/types/IStintLapData";
import {LapModal} from "./lap/LapModal";
import {useSessionState} from "../../../../../hooks/useSessionState";

/******************************************************************
 * StintLapsInfo
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function StintLapsInfo(props: {
    stint: IStintData
}) {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {dict} = useServices()
    const {sessionNumSectors} = useSessionState()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [lapModalData, setLapModalData] = useState<IStintLapData>()

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        updateLapModalData()
    }, [props.stint])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function updateLapModalData() {
        if (lapModalData) {
            const lap = props.stint.computedLaps.find(lap => lap.timestamp === lapModalData.timestamp)
            setLapModalData(lap)
        }
    }

    function columsDefinition(): ITableColumnDefinition<IStintLapData>[] {
        const sectorsSize = sessionNumSectors > 1 ? sessionNumSectors * 1.5 + "fr" : null
        return [
            {
                sortKey: "rowID",
                columnName: dict("table.head.lap"),
                type: "row-index",
                align: "center",
                size: "80px"
            },
            {
                sortKey: "time",
                columnName: dict("table.head.laptime"),
                type: "stint-lap-time",
                size: "2fr"
            },
            {
                columnName: dict("table.head.sectorTimes"),
                type: "stint-lap-sector-times",
                size: sectorsSize
            },
            {
                type: "stint-lap-flags",
                size: "80px"
            },
        ];
    }

    function lapNumber() {
        return props.stint.computedLaps.findIndex(lap => {
            return lap.timestamp === lapModalData.timestamp
        }) + 1
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (<>
        <ContentLayout
            className="stint-laps-info"
            gap="small"
            framed={true}>
            <Table<IStintLapData, IStintData>
                style="standard-compact"
                sortKey={"rowID"}
                sortDirection={"desc"}
                rowsData={props.stint.computedLaps}
                globalData={props.stint}
                onRowClick={setLapModalData}
                columnsDefinition={columsDefinition()}/>
        </ContentLayout>
        {lapModalData && <LapModal
            lap={lapModalData}
            lapNumber={lapNumber()}
            stint={props.stint}
            onClose={() => setLapModalData(null)}/>}
    </>);

}
