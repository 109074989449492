import * as React from "react";
import {ReactElement, useEffect, useRef, useState} from "react";
import {IconButton, IconButtonSizeType} from "../buttons/icon/IconButton";
import {IconType} from "../icons/IconType";
import {StateInfo} from "../info/state/StateInfo";
import {EventStateType} from "../../../../shared/types/EventStateType";
import {SessionStateType} from "../../../../shared/types/SessionStateType";
import {PromisedDelay} from "@webfruits/toolbox/dist/timer/PromisedDelay";
import {useServices} from "../../hooks/useServices";
import {ProgressingAnimation} from "../utils/progress/ProgressingAnimation";
import {IconColorType} from "../icons/Icon";
import {ArrowIcon} from "../icons/ArrowIcon";

/******************************************************************
 * ContextMenu
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function ContextMenu(props: {
    type: "icon" | "state" | "label",
    enabled?: boolean,
    label?: string,
    labelSize?: "normal" | "small",
    labelImportant?: boolean,
    iconOpacityMode?: "medium" | "none"
    iconType?: IconType,
    iconScale?: number,
    iconSize?: IconButtonSizeType,
    stateIconType?: IconType,
    stateIconColor?: IconColorType,
    stateIconScale?: number,
    stopPropagation?: boolean,
    preventCloseWhenClickInside?: boolean,
    className?: string
    compactStateInfo?: boolean
    state?: EventStateType | SessionStateType,
    showMenu?: boolean,
    children: ReactElement | ReactElement[] | null
    onToggle?: (showMenu: boolean) => void
    isBusy?: boolean

}) {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const menuRef = useRef<HTMLDialogElement>();
    const rootRef = useRef<HTMLDivElement>()
    const id = useRef<number>()

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {state} = useServices();

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [showMenu, setShowMenu] = useState<boolean>();

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        props.onToggle?.(showMenu)
        if (showMenu) {
            document.addEventListener("click", onClickAnywhere)
            id.current = state.contextMenuID.getValue() + 1
            state.contextMenuID.setValue(id.current)
            menuRef.current.showModal()
            positionMenu()
        } else {
            menuRef.current?.close()
            document.removeEventListener("click", onClickAnywhere)
        }
        return () => document.removeEventListener("click", onClickAnywhere);
    }, [showMenu])

    useEffect(() => {
        if (props.showMenu) {
            setShowMenu(true)
        }
    }, [props.showMenu])

    useEffect(() => {
        state.contextMenuID.onChangeSignal.add(onContextMenuIDChanged)
        return () => state.contextMenuID.onChangeSignal.remove(onContextMenuIDChanged)
    }, [])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function onContextMenuIDChanged() {
        if (state.contextMenuID.getValue() == id.current) return;
        setShowMenu(false)
    }

    function onClickAnywhere() {
        setShowMenu(false);
    }

    async function toggleMenu() {
        if (showMenu) {
            setShowMenu(false);
            return;
        }
        await PromisedDelay.wait(0.01)
        setShowMenu(true);
    }

    function positionMenu() {
        const bottomSafeArea = parseInt(getComputedStyle(rootRef?.current)?.getPropertyValue('--bottomSafeArea') ?? "0") ?? 0;
        const rootRect = rootRef.current.getBoundingClientRect()
        let x = rootRect.left + (props.type == "label" ? 5 : 0)
        let y = rootRect.top + rootRect.height * (props.type == "label" ? 0.1 : 0.8)
        const menuRect = menuRef.current.getBoundingClientRect()
        const offsetY = window.innerHeight - (y + menuRect.height + 10) - bottomSafeArea
        const offsetX = window.innerWidth - (x + menuRect.width + 10)
        if (offsetY < 0) {
            y = y + offsetY
        }
        if (offsetX < 0) {
            x = x + offsetX
        }
        menuRef.current.style.marginLeft = x + "px"
        menuRef.current.style.marginTop = y + "px";
    }

    function onClick(e: React.MouseEvent) {
        if (props.stopPropagation) {
            e.stopPropagation()
        }
        if (props.preventCloseWhenClickInside) {
            return;
        }
        if (props.stopPropagation) {
            setShowMenu(false)
        }
    }

    function enabled() {
        return props.enabled ?? true
    }

    function isBusy() {
        return props.isBusy ?? false
    }

    function elementType(): ReactElement {
        switch (props.type) {
            case "icon":
                return <IconButton
                    type={props.iconType}
                    scale={props.iconScale ?? 1}
                    size={props.iconSize ?? "normal"}
                    stopPropagation={props.stopPropagation ?? false}
                    opacityMode={props.iconOpacityMode ?? "medium"}
                    onClick={enabled() ? toggleMenu : null}
                    stateIconType={props.stateIconType}
                    stateIconColor={props.stateIconColor}
                    stateIconScale={props.stateIconScale}/>
            case "state":
                return <StateInfo
                    state={props.state}
                    compact={props.compactStateInfo}
                    stopPropagation={props.stopPropagation ?? false}
                    onClick={enabled() ? toggleMenu : null}/>
            case "label":
                return <div
                    className="context-menu-select-label"
                    data-select-label-size={props.labelSize ?? "normal"}
                    data-select-label-readonly={!enabled()}
                    data-select-label-important={props.labelImportant}
                    onClick={enabled() ? toggleMenu : null}>
                    <div className="context-menu-select-label-text">{props.label}</div>
                    <ArrowIcon scale={props.labelSize == "small" ? 0.8 : 1}/>
                </div>
        }
    }

    function dialogStyles(): React.CSSProperties {
        if (props.type != "label") return null
        return {
            minWidth: (rootRef.current?.getBoundingClientRect().width - 10) + "px",
            boxSizing: "border-box"
        }
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <nav
            className={"context-menu" + (props.className ? " " + props.className : "")}
            ref={rootRef}
            data-type={props.type}>
            {isBusy()
                ? <ProgressingAnimation size="small"/>
                : elementType()}
            {showMenu &&
                <dialog
                    onClick={onClick}
                    className="context-menu-flyout"
                    style={dialogStyles()}
                    ref={menuRef}>
                    {props.children}
                </dialog>}
        </nav>
    )

}
