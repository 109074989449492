import * as React from "react";
import {useServices} from "../../../hooks/useServices";

/******************************************************************
 * RouteLevelButton
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function RouteLevelButton(props: {
    label: string
    route?: string
}) {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {router} = useServices()

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <button
            className="route-level-button"
            type="button"
            data-clickable={!!props.route}
            onClick={props.route ? () => router.showRoute(props.route) : null}>
            {props.label}
        </button>
    );

}
