import * as React from "react";
import {useState} from "react";
import {Modal} from "../Modal";
import {useServices} from "../../../hooks/useServices";
import {SplitView} from "../../../ui/splitview/SplitView";
import {SplitViewContent} from "../../../ui/splitview/content/SplitViewContent";
import {UserCapiSettings} from "./settings/capi/UserCapiSettings";
import {UserAccountSettings} from "./settings/account/UserAccountSettings";
import {UserLanguageSettings} from "./settings/language/UserLanguageSettings";
import {UserPermissionSettings} from "./settings/permissions/UserPermissionSettings";
import {useAuthUser} from "../../../hooks/useAuthUser";
import {UserSubscriptionSettings} from "./settings/subscription/UserSubscriptionSettings";
import {Divider} from "../../../ui/utils/divider/Divider";
import {InfoText} from "../../../ui/text/infotext/InfoText";
import {UserNewsletterSettings} from "./settings/newsletter/UserNewsletterSettings";

/******************************************************************
 * AuthUserSettingsModal
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function AuthUserSettingsModal() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, state} = useServices()
    const {
        authUserExists,
        authUserPermissions,
        authUserHasUnpaidSubscription,
        authUserUseSubscription} = useAuthUser()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [splitViewMobileMode, setSplitViewMobileMode] = useState<boolean>(false)

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function defaultContentLabel(): string {
        const type = state.showAuthUserSettings.getValue()
        switch (type) {
            case "subscription":
                return dict("user.settings.subscription")
            case "language":
                return dict("user.settings.language")
            case "account":
                return dict("user.settings.account")
            case "capi":
                return dict("user.settings.capi")
            case "newsletter":
                return dict("user.settings.newsletter")
            case "permission":
                return dict("user.settings.permission")
            default:
                return null
        }
    }

    function onContentChanged(label: string) {
        switch (label) {
            case dict("user.settings.account"):
                state.showAuthUserSettings.setValue("account")
                break
            case dict("user.settings.language"):
                state.showAuthUserSettings.setValue("language")
                break
            case dict("user.settings.subscription"):
                state.showAuthUserSettings.setValue("subscription")
                break
            case dict("user.settings.permission"):
                state.showAuthUserSettings.setValue("permission")
                break
            case dict("user.settings.newsletter"):
                state.showAuthUserSettings.setValue("newsletter")
                break
            default:
                state.showAuthUserSettings.setValue("default")
        }
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal
            width={authUserExists ? "large" : "medium"}
            type={authUserExists ? "normal" : "error"}
            height={!authUserExists || splitViewMobileMode ? "auto" : "medium"}
            className="authuser-settings-modal"
            padding={authUserExists ? "none" : "default"}
            closeAction={() => state.showAuthUserSettings.setValue(null)}>
            {authUserExists
                ?
                <SplitView
                    title={dict("user.settings.title")}
                    padding="modal"
                    showContentByLabel={defaultContentLabel()}
                    onCurrentContentLabelChanged={onContentChanged}
                    onMobileMode={setSplitViewMobileMode}>
                    <SplitViewContent
                        label={dict("user.settings.capi")}
                        content={<UserCapiSettings/>}/>
                    <SplitViewContent
                        label={dict("user.settings.account")}
                        content={<UserAccountSettings/>}/>
                    <SplitViewContent
                        label={dict("user.settings.language")}
                        content={<UserLanguageSettings/>}/>
                    <SplitViewContent
                        label={dict("user.settings.newsletter")}
                        content={<UserNewsletterSettings/>}/>
                    {authUserUseSubscription &&
                        <Divider style="splitview-menu-full"/>}
                    {authUserUseSubscription &&
                        <SplitViewContent
                            label={dict("user.settings.subscription")}
                            badgeLabel={authUserHasUnpaidSubscription ? dict("plan.badge.paynow") : null}
                            badgeColor={"red"}
                            content={<UserSubscriptionSettings/>}/>}
                    {authUserPermissions?.length > 0 &&
                        <Divider style="splitview-menu-full"/>}
                    {authUserPermissions?.length > 0 &&
                        <SplitViewContent
                            label={dict("user.settings.permission")}
                            content={<UserPermissionSettings/>}/>}
                </SplitView>
                :
                <InfoText
                    text={dict("user.settings.notLoggedIn")}
                    align="center"/>}
        </Modal>
    );

}
