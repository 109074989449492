import * as React from "react";
import {IScoreboardEntry} from "../../../../../../shared/types/IScoreboardEntry";
import {DriverInfo} from "../../../info/driver/DriverInfo";
import {TeamInfo} from "../../../info/team/TeamInfo";
import {IntersectingPixel} from "../../../utils/observer/IntersectingPixel";
import {useMainframeSystemContentStyles} from "../../../../hooks/useMainframeSystemContentStyles";
import {useState} from "react";

/******************************************************************
 * TableScoreboardDriverTeamCell
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function TableScoreboardDriverTeamCell(props: {
    entry: IScoreboardEntry
    sticky?: boolean
}) {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {mainFramePaddingLeft} = useMainframeSystemContentStyles()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [isSticky, setIsSticky] = useState<boolean>(false)

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="table-scoreboard-driver-team-cell"
            data-sticky-cell={props.sticky}
            data-is-sticky={isSticky}>
            {props.sticky &&
                <IntersectingPixel
                    showRedPixel={false}
                    leftOffset={-mainFramePaddingLeft - 5}
                    onIntersectionChange={isIntersecting => setIsSticky(!isIntersecting)}/>}
            {props.entry.driver &&
                <DriverInfo
                    user={props.entry.driver}
                    size="normal"
                    clickable={true}
                    theme="dark"/>}
            {props.entry.team &&
                <TeamInfo
                    team={props.entry.team}
                    theme={"dark"}
                    style="leaderboard"/>}
        </div>
    );

}
