import * as React from "react";
import {Modal} from "../Modal";
import {useServices} from "../../../hooks/useServices";
import {Headline} from "../../../ui/text/headings/Headline";
import {InfoText} from "../../../ui/text/infotext/InfoText";
import {ContentLayout} from "../../../ui/layout/content/ContentLayout";
import {LabelButton} from "../../../ui/buttons/label/LabelButton";

/******************************************************************
 * RequestGroupMembershipModal
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function RequestGroupMembershipModal() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {state, dict} = useServices()

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal
            className="request-group-membership-modal"
            width="small"
            closeAction={() => state.showRequestGroupMembership.setValue(false)}>
            <ContentLayout>
                <ContentLayout gap="none">
                    <Headline text={dict("group.request.membership.title")} style="modal"/>
                    <InfoText text={dict("group.request.membership.text")}/>
                </ContentLayout>
                <LabelButton
                    label={dict("group.find.label")}
                    style="primary"
                    onClick={() => {
                        state.showRequestGroupMembership.setValue(false)
                        state.showGroupsFinder.setValue(true)
                    }}/>
            </ContentLayout>
        </Modal>
    );

}
