import * as React from "react";
import {CarImage} from "../../../image/car/CarImage";
import {MongoObjectIDType} from "../../../../../../shared/types/MongoObjectIDType";

/******************************************************************
 * TableCarCell
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export interface ITableCarCellProps {
    carName: string
    userID: MongoObjectIDType
}

export function TableCarCell(props: ITableCarCellProps) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="table-car-cell">
            <CarImage
                className="table-car-cell-image"
                carName={props.carName}
                userID={props.userID}/>
            <div
                className="table-car-cell-name">
                {props.carName}
            </div>
        </div>
    );

}
