import {FrontendServices} from "./FrontendServices";
import {FontDetector} from "@webfruits/toolbox/dist/font/FontDetector";
import {SharedConfig} from "../../shared/config/SharedConfig";
import {PromisedDelay} from "@webfruits/toolbox/dist/timer/PromisedDelay";
import {FrontendConfig} from "./FrontendConfig";

/******************************************************************
 * DriftClubFrontend
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class DriftClubFrontend {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _frontend: FrontendServices;

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor() {
        this.init();
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    get view(): HTMLElement {
        return this._frontend?.view?.view;
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private async init() {
        await this.initFonts();
        await this.initServices();
        await this.initEnvVars();
        await this.initAuthUserState();
        await this.initAuthUserRefreshSubscription();
        await this.initDictionary();
        await this.startUI();
        await this.removePreloader();
        this.initUnhandledErrors();
    }

    private async initFonts() {
        await FontDetector.initializeFonts([
            {fontName: "Montserrat"},
            {fontName: "JetBrainsMono"}
        ])
    }

    private async initServices() {
        this._frontend = new FrontendServices();
        await this._frontend.init();
    }

    private async initEnvVars() {
        const environmentalVars = await this._frontend.api.info.getFrontendEnvVars()
        FrontendConfig.PADDLE_CLIENT_TOKEN = environmentalVars.PADDLE_CLIENT_TOKEN
        FrontendConfig.PADDLE_ENABLED_PER_DEFAULT = environmentalVars.PADDLE_ENABLED_PER_DEFAULT === "true"
        FrontendConfig.PADDLE_ENVIRONMENT = environmentalVars.PADDLE_ENVIRONMENT
        FrontendConfig.PADDLE_AVAILABLE = !!FrontendConfig.PADDLE_ENVIRONMENT && !!FrontendConfig.PADDLE_CLIENT_TOKEN
        FrontendConfig.MAPBOX_ACCESS_TOKEN = environmentalVars.MAPBOX_ACCESS_TOKEN
        FrontendConfig.DB_BACKUP_DIR_AVAILABLE = !!environmentalVars.DB_BACKUP_DIR_AVAILABLE
        FrontendConfig.SERVER_ENVIRONMENT = environmentalVars.SERVER_ENVIRONMENT
    }

    private async initAuthUserState() {
        const response = await this._frontend.api.user.loadAuthUserData();
        if (response === undefined) {
            this._frontend.state.coreErrorMessage.setValue(SharedConfig.NO_DB_AVAILABLE)
        }
    }

    private async initAuthUserRefreshSubscription() {
        if (this._frontend.state.authUser.getValue()?._id) {
            this._frontend.api.user.refreshAuthUserSubscription()
        }
    }

    private async initDictionary() {
        if (this._frontend.state.coreErrorMessage.hasValue()) return
        await this._frontend.dict.loadDictionary()
    }

    private async startUI() {
        this._frontend.view.init();
        if (this._frontend.state.coreErrorMessage.hasValue()) return
        this._frontend.router.showInitRoute()
    }

    private async removePreloader() {
        await PromisedDelay.wait(0.5)
        const preloader = document.getElementById("dc-preloader");
        preloader.style.transform = "scale(1.5)"
        preloader.style.opacity = "0"
        await PromisedDelay.wait(0.3)
        preloader.remove();
    }

    private initUnhandledErrors() {
        if (this._frontend.state.coreErrorMessage.hasValue()) return
        let hasErrorDisplayed = false
        const core = this._frontend
        async function handleError (event: ErrorEvent) {
            if (hasErrorDisplayed) return
            hasErrorDisplayed = true
            core.state.showReloadFrontend.setValue(event.error);
            await core.api.log.error(event.error);
        }
        window.addEventListener("unhandledrejection", handleError)
        window.addEventListener("error", handleError);
    }

}
