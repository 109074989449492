import {ErrorCode} from "../keys/ErrorCode";

/******************************************************************
 * PathValidation
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class PathValidation {

    static readonly RESERVED_PATHS = [
        "/api",
        "/welcome",
        "/plans",
        "/prices",
        "/subscription",
        "/admin",
        "/settings",
        "/setup",
        "/event",
        "/events",
        "/driver",
        "/drivers",
        "/team",
        "/teams",
        "/track",
        "/tracks",
        "/member",
        "/members",
        "/undefined",
        "/null",
        "/sturmkind"
    ];

    static get REGEXP() {
        return /^\/[a-zA-Z0-9_-]+$/;
    }

    static schemaValidator() {
        return {
            validate: {
                validator: value => PathValidation.isValid(value),
                message: ErrorCode.PATH_INVALID
            },
        }
    };

    static isValid(value: string): boolean {
        if (PathValidation.RESERVED_PATHS.filter(word => {
            return word.toLowerCase() == value?.toLowerCase()
        }).length > 0) {
            return false;
        }
        return PathValidation.REGEXP.test(value);
    }
}
