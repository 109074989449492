import * as React from "react";
import {useState} from "react";
import {ContextMenuGroup} from "../elements/ContextMenuGroup";
import {ContextMenuSelectable} from "../elements/ContextMenuSelectable";
import {ContextMenuDivider} from "../elements/ContextMenuDivider";
import {ContextMenu} from "../ContextMenu";
import {useServices} from "../../../hooks/useServices";
import {ICapiTargetData} from "../../../../../shared/types/ICapiTargetData";
import {CapiTargetUtils} from "../../../../../shared/utils/CapiTargetUtils";
import {IStintData} from "../../../../../shared/models/IStintData";

/******************************************************************
 * TargetContextMenu
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function TargetContextMenu(props: {
    target: ICapiTargetData
    stint: IStintData
    onProgressingChanged?: (isBusy: boolean) => void
}) {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {dict, api} = useServices()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [isBusy, setIsBusy] = useState<boolean>(false)

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function enableTarget() {
        const target = originalTarget()
        target._disabled = false
        await updateTargetsData()

    }

    async function disableTarget() {
        const target = originalTarget()
        target._disabled = true
        await updateTargetsData()
    }

    async function overwriteTargetCode(code: number) {
        const target = originalTarget()
        target._overwrittenTargetCode = target.target_code === code ? undefined : code
        await updateTargetsData()
    }

    async function updateTargetsData() {
        props.onProgressingChanged?.(true)
        setIsBusy(true)
        await api.stint.updateStint(props.stint._id, {targetsData: props.stint.targetsData})
        props.onProgressingChanged?.(false)
        setIsBusy(false)
    }

    function originalTarget(): ICapiTargetData {
        return props.stint.targetsData.find(target => target.crossing_time === props.target.crossing_time)
    }

    function targetCode() {
        return CapiTargetUtils.getTargetCode(props.target)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContextMenu
            type={"icon"}
            enabled={true}
            iconType="menuDots"
            iconScale={0.9}
            isBusy={isBusy}
            stopPropagation={true}>
            <ContextMenuGroup name={dict("lap.target.menu.toggle.title")}/>
            <ContextMenuSelectable
                label={dict("lap.target.menu.enabled")}
                onClick={enableTarget}
                selected={!props.target._disabled}/>
            <ContextMenuSelectable
                label={dict("lap.target.menu.disabled")}
                onClick={disableTarget}
                selected={props.target._disabled}/>
            <ContextMenuDivider style="small"/>
            <ContextMenuGroup name={dict("lap.target.menu.change.title")}/>
            <ContextMenuSelectable
                label={dict("drift.targetCode_0")}
                onClick={() => overwriteTargetCode(0)}
                selected={targetCode() == 0}/>
            <ContextMenuSelectable
                label={dict("drift.targetCode_4")}
                onClick={() => overwriteTargetCode(4)}
                selected={targetCode() == 4}/>
            <ContextMenuSelectable
                label={dict("drift.targetCode_5")}
                onClick={() => overwriteTargetCode(5)}
                selected={targetCode() == 5}/>
            <ContextMenuSelectable
                label={dict("drift.targetCode_6")}
                onClick={() => overwriteTargetCode(6)}
                selected={targetCode() == 6}/>
            <ContextMenuSelectable
                label={dict("drift.targetCode_7")}
                onClick={() => overwriteTargetCode(7)}
                selected={targetCode() == 7}/>
        </ContextMenu>
    );

}
