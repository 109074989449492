import * as React from "react";
import {useRef} from "react";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {Input} from "../../../../../ui/form/elements/input/Input";
import {useServices} from "../../../../../hooks/useServices";
import {useEventState} from "../../../../../hooks/useEventState";
import {useMobileStyle} from "../../../../../hooks/useMobileStyle";
import {SelectInput, SelectInputOptionType} from "../../../../../ui/form/elements/select/SelectInput";
import {InfoText} from "../../../../../ui/text/infotext/InfoText";

/******************************************************************
 * EventDateSettings
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function EventDateSettings() {

    /* ----------------------------------------------------------------
 	 * REF
 	 * --------------------------------------------------------------*/

    const ref = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, api} = useServices();
    const {eventID, eventDate, eventDateUntil, eventIsFinished, eventIsPermanent} = useEventState();
    const [isMobileMode] = useMobileStyle(ref, 500)

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function updateDate(date: string): Promise<Response> {
        return await api.event.update(eventID, {date: date ?? null})
    }

    async function updateDateUntil(dateUntil: string): Promise<Response> {
        return await api.event.update(eventID, {dateUntil: dateUntil ?? null})
    }

    async function updatePermanentState(selectValue: string): Promise<Response> {
        return await api.event.update(eventID, {isPermanent: selectValue === "permanent"})
    }

    function formatDate(date: string): string {
        if (!date) return null
        let d = new Date(date);
        return d.toISOString().substring(0, 10)
    }

    function permanentOptions(): SelectInputOptionType[] {
        return [
            {value: "non-permanent", text: dict("event.not.permanent.label")},
            {value: "permanent", text: dict("event.permanent.label")}
        ]
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout className="event-date-settings" ref={ref}>
            <InfoText
                text={dict("event.date.info.text")}/>
            <SelectInput
                options={permanentOptions()}
                defaultValue={eventIsPermanent ? "permanent" : "non-permanent"}
                onChange={updatePermanentState}/>
            {!eventIsPermanent &&
                <ContentLayout columns={isMobileMode ? 1 : 2}>
                    <Input
                        type="date"
                        readonly={eventIsFinished}
                        label={dict("event.date.input.label")}
                        placeholder={dict("event.date.placeholder")}
                        customLinkLabel={eventDate && dict("date.delete.label")}
                        onCustomLinkClick={() => updateDate(null)}
                        defaultValue={formatDate(eventDate)}
                        action={updateDate}/>
                    <Input
                        type="date"
                        readonly={eventIsFinished}
                        label={dict("event.date.until.input.label")}
                        placeholder={dict("event.date.until.placeholder")}
                        customLinkLabel={eventDateUntil && dict("date.delete.label")}
                        onCustomLinkClick={() => updateDateUntil(null)}
                        defaultValue={formatDate(eventDateUntil)}
                        action={updateDateUntil}/>
                </ContentLayout>}
        </ContentLayout>
    );

}
