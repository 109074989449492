import * as React from "react";
import {ContentLayout} from "../../layout/content/ContentLayout";
import {Headline} from "../../text/headings/Headline";
import {useServices} from "../../../hooks/useServices";
import {IconButton} from "../../buttons/icon/IconButton";
import {ITrackFilterData} from "../../../../../shared/models/submodels/ITrackFilterData";
import {ITrackFilterLeaderboardEntry} from "../../../../../shared/types/ITrackFilterLeaderboardEntry";
import {FrontendRoute} from "../../../../../shared/routes/FrontendRoute";
import {useTrackState} from "../../../hooks/useTrackState";
import {Table} from "../../table/Table";
import {ITableColumnDefinition} from "../../table/ITableColumnDefinition";
import {TimeUtils} from "../../../../../shared/utils/TimeUtils";
import {EmptyDataInfo} from "../../info/empty/EmptyDataInfo";
import {ComponentInitializer} from "../../utils/init/ComponentInitializer";
import {Spacer} from "../../utils/spacer/Spacer";
import {LabelButton} from "../../buttons/label/LabelButton";
import {InfoText} from "../../text/infotext/InfoText";
import {TrackUtils} from "../../../../utils/TrackUtils";
import {DriftUtils} from "../../../../../shared/utils/DriftUtils";

/******************************************************************
 * TrackFilterPreviewInfobox
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function TrackFilterPreviewInfobox(props: {
    filter: ITrackFilterData
    loading: boolean
    isHigherLicenseRequired?: boolean
    entries: ITrackFilterLeaderboardEntry[]
    showDetails?: boolean
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, state, router, api} = useServices()
    const {trackType, trackOwnerType, trackOwner, trackPath} = useTrackState()

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function gotoFilterPage() {
        router.showRoute(FrontendRoute.TRACK_FILTER(trackOwnerType, trackOwner.path, trackPath, props.filter.path))
    }

    async function gotoSession(data: ITrackFilterLeaderboardEntry) {
        const route = await api.session.getSessionRoute(data.sessionID)
        if (!route) return
        await router.showRoute(route)
    }

    function columsDefinition(): ITableColumnDefinition<ITrackFilterLeaderboardEntry>[] {


        return [{
            type: "text-bold",
            dataKey: "position",
            align: "center",
            size: "50px"
        }, {
            type: "driver",
            dataKey: "user",
            sticky: true,
            size: "2.3fr"
        }, {
            type: "text",
            size: props.showDetails ? "2fr" : null,
            value: data => dict("drift.engine." + data.carEngine)
        }, {
            type: "text",
            size: props.showDetails ? "2fr" : null,
            value: data => DriftUtils.getTuningWithoutEngine(dict("drift.tuning." + data.carTuning))
        }, {
            type: "number-bold",
            align: "right",
            size: "80px",
            value: data => {
                const firstEntry = props.entries[0]
                const index = props.entries.indexOf(data)
                switch (trackType) {
                    case "lap":
                        if (index === 0) {
                            return TimeUtils.formatDrivenTime(data.bestLapTime)
                        }
                        const timeDiff = data.bestLapTime - firstEntry.bestLapTime
                        return "+" + TimeUtils.formatDrivenTime(timeDiff)
                    case "gymkhana":
                        return data.score?.toString()
                }
            },
        }];
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            gap="small"
            className="track-filter-preview-infobox"
            alignSelf="stretch"
            rowTemplate="min-content auto"
            framed={true}>
            <ContentLayout
                columnTemplate="auto min-content"
                alignItems="center"
                justifyContent="space-between">
                <Headline
                    text={TrackUtils.trackFilterName(props.filter, trackType, dict)}
                    style="h4"
                    onClick={gotoFilterPage}/>
                <ContentLayout
                    alignItems="center"
                    gap="small"
                    columnTemplate="min-content min-content">
                    <IconButton
                        type="settings"
                        size="small"
                        scale={0.9}
                        onClick={() => state.showTrackFilter.setValue(props.filter)}/>
                    <LabelButton
                        label={dict("track.filter.preview.gotoFilterPage.label")}
                        style="secondary-small"
                        onClick={gotoFilterPage}/>
                </ContentLayout>
            </ContentLayout>
            {props.loading
                ? <ComponentInitializer isPropertyAvailable={false}>
                    <div style={{height: 150}}/>
                </ComponentInitializer>
                : <>{props.isHigherLicenseRequired
                    ? <ContentLayout justifyItems={"center"} useFrameMargin={true}>
                        <InfoText
                            text={dict("subscription.upgrade.info.track.filters")}
                            align="center"
                            style="warning"
                            size="small"/>
                        <LabelButton
                            label={dict("subscription.upgrade.info.button.showPlans")}
                            style="primary-small"
                            onClick={() => state.showSubscriptionPlans.setValue(true)}/>
                    </ContentLayout>
                    : <>{props.entries?.length > 0
                        ? <Table<ITrackFilterLeaderboardEntry>
                            rowsData={props.entries}
                            showHeader={false}
                            onRowClick={gotoSession}
                            columnsDefinition={columsDefinition()}/>
                        : <>
                            <EmptyDataInfo
                                text={dict("track.filter.leaderboard.noData")}
                                size="small"/>
                            <Spacer height="normal"/></>}</>}</>}
        </ContentLayout>
    );
}
