import * as React from "react";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {AvatarImage} from "../../../../../ui/image/avatar/AvatarImage";
import {Headline} from "../../../../../ui/text/headings/Headline";
import {useRouteOwner} from "../../../../../hooks/useRouteOwner";
import {useServices} from "../../../../../hooks/useServices";
import {TimeUtils} from "../../../../../../../shared/utils/TimeUtils";
import {useDeviceInfo} from "../../../../../hooks/useDeviceInfo";
import {DriverLicenseInfo} from "../../../../../ui/info/license/DriverLicenseInfo";
import {Spacer} from "../../../../../ui/utils/spacer/Spacer";

/******************************************************************
 * UserHomeHeader
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function UserHomeHeader() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict} = useServices()
    const {
        isRouteOwnerAuthUser,
        routeOwnerNick,
        routeOwnerID,
        routeOwnerData,
        routeOwnerSubscription,
        routeOwnerUseSubscription
    } = useRouteOwner();
    const {isMobile} = useDeviceInfo()

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function memberSince() {
        if (!routeOwnerID) {
            return ""
        }
        const year = TimeUtils.getDateFromObjectID(routeOwnerID)?.getFullYear()?.toString()
        return dict("user.home.header.subTitle").replaceAll("{YEAR}", year)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout className="user-home-header" justifyItems="center">
            {isMobile && <AvatarImage user={routeOwnerData} editable={isRouteOwnerAuthUser}/>}
            <ContentLayout gap="small" justifyItems="center">
                <Headline
                    text={routeOwnerNick}
                    style="h1"
                    align="center"/>
                {routeOwnerUseSubscription &&
                    <DriverLicenseInfo
                        style="main"
                        isRouteOwner={isRouteOwnerAuthUser}
                        subscription={routeOwnerSubscription}/>}
                {!routeOwnerUseSubscription &&
                    <Headline
                        text={memberSince()}
                        style="h5"
                        align="center"/>}
            </ContentLayout>
            {!isMobile && <Spacer height={"small"}/>}
        </ContentLayout>
    );

}
