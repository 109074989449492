import * as React from "react";
import {ContentLayout} from "../../../../ui/layout/content/ContentLayout";
import {useGroupState} from "../../../../hooks/useGroupState";
import {ActionBar} from "../../../../ui/bar/ActionBar";
import {LabelButton} from "../../../../ui/buttons/label/LabelButton";
import {useRouteOwner} from "../../../../hooks/useRouteOwner";
import {useServices} from "../../../../hooks/useServices";
import {MemberInfo} from "../../../../ui/info/group/MemberInfo";
import {useRef} from "react";
import {useMobileStyle} from "../../../../hooks/useMobileStyle";
import {ComponentInitializer} from "../../../../ui/utils/init/ComponentInitializer";
import {BasicPageTitle} from "../../../../ui/text/headings/BasicPageTitle";

/******************************************************************
 * GroupMembersPage
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function GroupMembersPage() {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const ref = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {dict, state} = useServices();
    const {groupMembers, isGroupAdmin} = useGroupState()
    const {isRouteOwnerAuthUser} = useRouteOwner()
    const [isTiny] = useMobileStyle(ref, 300)
    const [isSmall] = useMobileStyle(ref, 600)
    const [isMedium] = useMobileStyle(ref, 1000)

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function subTitle(): string {
        if (!groupMembers || groupMembers?.length == 0) {
            return dict("group.members.subtitle.noMembers")
        }
        if (groupMembers?.length == 1) {
            return dict("group.members.subtitle.oneMember")
        }
        return dict("group.members.subtitle.manyMembers")
            .replaceAll("{NUM_MEMBERS}", groupMembers?.length.toString())
    }

    /* ----------------------------------------------------------------
 	 * RENDER
 	 * --------------------------------------------------------------*/

    return (
        <ComponentInitializer isPropertyAvailable={!!ref.current}>
            <ContentLayout
                ref={ref}
                className="group-members-page"
                alignContent="start">
                <BasicPageTitle
                    title={dict("group.members.title")}
                    subTitle={subTitle()}/>
                <ContentLayout
                    alignContent="start"
                    useFrameMargin={!isTiny}
                    columns={isTiny ? 2 : (isSmall ? 3 : (isMedium ? 4 : 5))}
                    gap="medium-to-large">
                    {groupMembers?.map((member) => {
                        return <MemberInfo
                            key={member._id}
                            member={member.user}
                            role={member.role}/>
                    })}
                </ContentLayout>
            </ContentLayout>
            {(isRouteOwnerAuthUser || isGroupAdmin) &&
                <ActionBar location="main">
                    <LabelButton
                        label={dict("group.member.add.label")}
                        icon="plus"
                        onClick={() => state.showSelectDrivers.setValue(true)}
                        style={"action-main"}/>
                </ActionBar>}
        </ComponentInitializer>
    )
}
