import * as React from "react";
import {DriftTargetCodeType} from "../../../../../../shared/types/DriftTargetCodeType";
import {SelectInput} from "../select/SelectInput";
import {useServices} from "../../../../hooks/useServices";

/******************************************************************
 * TrackJokerLapTargetInput
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function TrackJokerLapTargetInput(props: {
    enabled: boolean
    jokerLapTarget: DriftTargetCodeType | "none"
    onChange: (value: DriftTargetCodeType | "none") => void
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict} = useServices()

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <SelectInput
            label={dict("session.jokerLap.target")}
            helpTopic="session.jokerLap.target"
            readonly={!props.enabled}
            defaultValue={props.jokerLapTarget}
            options={[
                {value: "none", text: dict("track.jokerLap.disabled")},
                {value: "targetCode_4", text: dict("drift.targetCode_4")},
                {value: "targetCode_5", text: dict("drift.targetCode_5")},
                {value: "targetCode_6", text: dict("drift.targetCode_6")},
                {value: "targetCode_7", text: dict("drift.targetCode_7")}
            ]}
            onChange={props.onChange}/>
    );

}
