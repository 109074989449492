/******************************************************************
 * SharedDictionaryKeys
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class SharedDictionaryKeys {

    static readonly ADMIN_TITLE_DICT_KEY = "admin.title";
    static readonly HOME_DICT_KEY = "home.title.welcome";
    static readonly APP_TITLE_DICT_KEY = "app.title";
    static readonly GROUPS_DICT_KEY = "groups.title";
    static readonly EVENTS_DICT_KEY = "events.title";
    static readonly PLANS_DICT_KEY = "plans.title";

}
