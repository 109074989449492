import {FrontendServices} from "../core/FrontendServices";
import {MongoObjectIDType} from "../../shared/types/MongoObjectIDType";
import {ISessionLeaderboardEntry} from "../../shared/types/ISessionLeaderboardEntry";
import {APIRoute} from "../../shared/routes/APIRoute";
import {ISessionData} from "../../shared/models/ISessionData";
import {IEventData} from "../../shared/models/IEventData";
import {ITrackFilterLeaderboardEntry} from "../../shared/types/ITrackFilterLeaderboardEntry";

/******************************************************************
 * LeaderboardAPI
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class LeaderboardAPI {

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(private _frontend: FrontendServices) {
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public async getSessionLeaderboard(sessionID: MongoObjectIDType, isStartOrder: boolean = false, isReversedStartOder: boolean = false): Promise<ISessionLeaderboardEntry[]> {
        const response = await this._frontend.api.request("GET", APIRoute.LEADERBOARD_SESSION, {sessionID});
        let session: ISessionData = this._frontend.state.session.getValue()
        let event: IEventData = this._frontend.state.event.getValue()
        if (session?._id != sessionID) {
            session = await this._frontend.api.session.getSessionByID(sessionID)
        }
        if (session?.event != event?._id) {
            event = await this._frontend.api.event.getEventByID(session.event)
        }
        if (response?.status == 200) {
            const entries = await response.json()
            return LeaderboardAPI.postProcessSessionLeaderboard(entries, session, event, isStartOrder, isReversedStartOder)
        }
        return null
    }

    public async getTrackFilterLeaderboard(
        trackID: MongoObjectIDType,
        filterPath: string,
        eventID: MongoObjectIDType): Promise<ITrackFilterLeaderboardEntry[]> {
        const response = await this._frontend.api.request("GET", APIRoute.LEADERBOARD_TRACK_FILTER, {
            trackID,
            filterPath,
            eventID
        });
        if (response.status == 200) {
            return await response.json()
        }
        return null
    }

    public async deleteLeaderboardEntry(stintIDs: MongoObjectIDType[], sessionID: MongoObjectIDType): Promise<Response> {
        return await this._frontend.api.request("DELETE", APIRoute.LEADERBOARD_ENTRY, {stintIDs, sessionID}, true)
    }

    public async finishLeaderboardEntry(stintIDs: MongoObjectIDType[], sessionID: MongoObjectIDType): Promise<Response> {
        return await this._frontend.api.request("PATCH", APIRoute.LEADERBOARD_ENTRY_END, {stintIDs, sessionID}, true)
    }

    static postProcessSessionLeaderboard(
        entries: ISessionLeaderboardEntry[],
        session: ISessionData,
        event: IEventData,
        isStartOrder?: boolean,
        isReversedStartOrder?: boolean
    ): ISessionLeaderboardEntry[] {
        if (!entries || entries.length == 0) return null
        const entriesSessionID = entries[0]?.session
        if (entriesSessionID != session._id) return null
        if (session.event != event._id) return null
        entries.forEach((entry: ISessionLeaderboardEntry, i: number) => {
            entry.position = i + 1;
            entry.entries = entries
            entry.session = session
            entry.event = event
            entry.isStartOrder = isStartOrder
            entry.isReversedStartOrder = isReversedStartOrder
            if (entry.team) {
                entry._key = entry.team.name
                entry.team.leaderboardEntries?.forEach((teamEntry: ISessionLeaderboardEntry, j: number) => {
                    teamEntry.position = j + 1;
                    teamEntry.entries = entry.team.leaderboardEntries
                    teamEntry.session = session
                    teamEntry.event = event
                })
            } else {
                entry._key = entry.latestStint.user._id
            }
        })
        if (isStartOrder && isReversedStartOrder) {
            entries.reverse()
        }
        return entries
    }

}
