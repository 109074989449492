import {IKeyValuePair} from "../../shared/types/IKeyValuePair";

/******************************************************************
 * FormUtils
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class FormUtils {

    static getValue(formElement: HTMLFormElement, id: string): string {
        return (formElement.elements.namedItem(id) as HTMLInputElement)['value'];
    }

    static getKeyValuePairs(formElement: HTMLFormElement) {
        const elements = Array.from(formElement.elements).filter(inputElement => !!inputElement.id)
        const data: IKeyValuePair = {};
        elements.forEach((element) => {
            data[element.id] = element['value'];
        })
        return data;
    }

    static focusAndPositionCaretToEnd(element: HTMLElement) {
        element.focus();
        const selection = window.getSelection()
        selection.collapse(element.lastChild, element.innerText.length);
    }
}
