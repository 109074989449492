import * as React from "react";
import {useState} from "react";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {AvatarImage} from "../../../../../ui/image/avatar/AvatarImage";
import {Headline} from "../../../../../ui/text/headings/Headline";
import {TimeUtils} from "../../../../../../../shared/utils/TimeUtils";
import {useServices} from "../../../../../hooks/useServices";
import {ILocationData} from "../../../../../../../shared/types/ILocationData";
import {LabelButton} from "../../../../../ui/buttons/label/LabelButton";
import {MapLocationModal} from "../../../../modals/map/MapLocationModal";
import {IGroupData} from "../../../../../../../shared/models/IGroupData";
import {LocationUtils} from "../../../../../../utils/LocationUtils";
import {MarkerFactory} from "../../../../../ui/map/marker/MarkerFactory";

/******************************************************************
 * GroupHomeHeader
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function GroupHomeHeader(props: {
    group: IGroupData
    showAvatar?: boolean
    editable?: boolean
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, language, api, state} = useServices()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [showMap, setShowMap] = useState<boolean>(false)

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function location() {
        if (!props.group?.type) {
            return undefined
        }
        switch (props.group?.type) {
            case "online":
                return dict("group.type.online")
            case "local":
                if (!hasLocation()) {
                    return "–"
                }
                return LocationUtils.mapLocationString(dict("group.home.header.type.local"), props.group?.location, language)
        }
    }

    function founded() {
        const year = TimeUtils.getDateFromObjectID(props.group?._id)?.getFullYear()?.toString()
        return dict("group.home.header.subTitle").replaceAll("{YEAR}", year)
    }

    function hasLocation() {
        return props.group?.location && props.group?.location.place
    }

    async function updateLocation(value: ILocationData): Promise<Response> {
        const response = await api.group.update(props.group?._id, {location: value})
        setShowMap(false)
        return response
    }

    function showMapModal() {
        if (!props.group?.location?.longitude) {
            return null
        }
        state.showMapLocation.setValue({
            type: "group",
            location: props.group.location,
            markers: [MarkerFactory.createGroupMarker(props.group)]
        })
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return <>

        <ContentLayout className="group-home-header" justifyItems="center">
            {props.showAvatar &&
                <AvatarImage
                    group={props.group}
                    editable={props.editable}/>}
            <ContentLayout gap="small" justifyItems="center">
                <Headline text={props.group?.name} style="h1" align="center"/>
                {props.group?.type == "online" &&
                    <Headline text={location().toUpperCase()} style="h4" align="center"/>}
                {props.group?.type == "local" && (!props.editable || hasLocation()) &&
                    <Headline
                        text={location().toUpperCase()}
                        style="h4"
                        align="center"
                        onClick={showMapModal}/>}
                {props.editable && props.group?.type == "local" && !hasLocation() &&
                    <LabelButton
                        label={dict("group.reselect.location.button.label")}
                        style={"primary-small"}
                        onClick={() => setShowMap(true)}/>}
                <Headline text={founded()} style="h5" align="center"/>
            </ContentLayout>
        </ContentLayout>
        {showMap &&
            <MapLocationModal
                type="group"
                location={props.group?.location}
                requestClose={() => setShowMap(false)}
                requestSave={updateLocation}/>}
    </>

}
