import * as React from "react";
import {useEffect, useRef} from "react";
import {IKeyShortcut} from "../../../utils/interfaces/IKeyShortcut";
import {DOMUtils} from "@webfruits/toolbox/dist/utils/DOMUtils";
import {PromisedDelay} from "@webfruits/toolbox/dist/timer/PromisedDelay";

/******************************************************************
 * KeyController
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function KeyController(props: {
    enabled: boolean,
    code?: string,
    shortcut?: IKeyShortcut
    preventOnInputElement?: boolean,
    onKey: (e: KeyboardEvent) => void
}) {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const onKeyDownRef = useRef<(e: KeyboardEvent) => void>();
    const onKeyUpRef = useRef<(e: KeyboardEvent) => void>();
    const isShiftPressedRef = useRef<boolean>(false);
    const isCtrlPressedRef = useRef<boolean>(false);
    const isAltPressedRef = useRef<boolean>(false);
    const isMetaPressedRef = useRef<boolean>(false);
    const isPressedRef = useRef<boolean>(false);
    const codeRef = useRef<string>();

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        if (props.shortcut) {
            if (props.shortcut.key?.match(/[0-9]/)) {
                codeRef.current = "Digit" + props.shortcut.key
            }
            if (props.shortcut.key?.match(/[a-zA-Z]/)) {
                codeRef.current = "Key" + props.shortcut.key.toUpperCase()
            }
        }
        if (props.code) {
            codeRef.current = props.code;
        }
    }, [props.shortcut, props.code])

    useEffect(() => {
        onKeyDownRef.current = (e: KeyboardEvent) => {
            if (props.preventOnInputElement && DOMUtils.isTextInputElementActive()) return
            if (isPressedRef.current) return;
            updateMetaKeys(e, true)
            if (codeRef.current && e?.code?.toLowerCase() == codeRef.current?.toLowerCase()) {
                if (props.shortcut?.useShiftKey) {
                    if (!isShiftPressedRef.current) return
                    isPressedRef.current = true;
                    executeCallback(e);
                } else {
                    if (isMetaKeyPressed()) return;
                    isPressedRef.current = true
                    executeCallback(e)
                }
            }
        }
        onKeyUpRef.current = (e: KeyboardEvent) => {
            updateMetaKeys(e, false)
            isPressedRef.current = false;
        }
        return () => {
            removeWindowKeyListener();
        }
    }, [])

    useEffect(() => {
        removeWindowKeyListener();
        if (props.enabled) {
            addWindowKeyListener();
        }
    }, [props.enabled])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function executeCallback(e: KeyboardEvent) {
        PromisedDelay.wait(0.01)
            .then(() => {
                props.onKey?.(e);
            })
    }

    function addWindowKeyListener() {
        window.addEventListener("keydown", onKeyDownRef.current);
        window.addEventListener("keyup", onKeyUpRef.current);
    }

    function removeWindowKeyListener() {
        window.removeEventListener("keydown", onKeyDownRef.current);
        window.removeEventListener("keyup", onKeyUpRef.current);
    }

    function isMetaKeyPressed() {
        return isShiftPressedRef.current || isCtrlPressedRef.current || isAltPressedRef.current || isMetaPressedRef.current;
    }

    function updateMetaKeys(e: KeyboardEvent, isPressed: boolean) {
        if (e.shiftKey) {
            isShiftPressedRef.current = isPressed;
        }
        if (e.ctrlKey) {
            isCtrlPressedRef.current = isPressed;
        }
        if (e.altKey) {
            isAltPressedRef.current = isPressed;
        }
        if (e.metaKey) {
            isMetaPressedRef.current = isPressed;
        }
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return <></>;

}
