import * as React from "react";
import {useEffect, useState} from "react";
import {Modal} from "../Modal";
import {Headline} from "../../../ui/text/headings/Headline";
import {useServices} from "../../../hooks/useServices";
import {ContentLayout} from "../../../ui/layout/content/ContentLayout";
import {useAuthUser} from "../../../hooks/useAuthUser";
import {Article} from "../../../ui/article/Article";
import {InfoText} from "../../../ui/text/infotext/InfoText";
import {IArticleData} from "../../../../../shared/models/IArticleData";
import {EditModeActionBar} from "../academy/bars/EditModeActionBar";
import {Spacer} from "../../../ui/utils/spacer/Spacer";
import {CopyArticleContentModal} from "../article/CopyArticleContentModal";

/******************************************************************
 * HelpModal
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function HelpModal() {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {state, api, dict, language} = useServices();
    const {authUserHasPermission} = useAuthUser()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [helpKey] = useState<string>("help." + state.showHelp.getValue())
    const [helpArticleData, setHelpArticleData] = useState<IArticleData>(null)
    const [editMode, setEditMode] = useState<boolean>(false)
    const [showCopyArticleModal, setShowCopyArticleModal] = useState<boolean>(false)
    const [version, setVersion] = useState<number>(0)

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        loadHelp()
    }, [language])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function loadHelp() {
        const articleData = await api.article.getArticleByTitle(helpKey, language)
        setHelpArticleData(articleData)
        setVersion(prevState => prevState + 1)
    }

    function close() {
        state.showHelp.setValue(null);
    }

    async function createArticle() {
        const article = await api.article.createArticle(helpKey, language)
        setEditMode(true)
        setHelpArticleData(article)
    }

    function buttonAction() {
        if (helpArticleData) {
            if (editMode) {
                return setEditMode(false)
            }
            return setEditMode(true)
        }
        createArticle()
    }

    function showEditButton(): boolean {
        return authUserHasPermission("help:update")
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal
            closeAction={close}
            type="article"
            width="medium"
            className="help-modal">
            <ContentLayout gap="none">
                <Headline text={dict("help.modal.title")} style="modal"/>
                {helpArticleData
                    ? <Article
                        key={editMode?.toString() + helpArticleData?._id + version.toString()}
                        hideTypeSelect={true}
                        hideTitle={true}
                        style="compact"
                        hideSubTitle={true}
                        articleData={helpArticleData}
                        emptyDataInfo={dict("help.article.empty.data.info")}
                        edit={editMode}/>
                    : <ContentLayout gap="small">
                        <InfoText
                            text={dict("help.not.available.yet.info")}
                            align="center"/>
                        <InfoText
                            text={"[" + state.showHelp.getValue() + "]"}
                            style="code"
                            align="center"/>
                    </ContentLayout>}
            </ContentLayout>
            {showEditButton() && <>
                <Spacer height={"modal-actionbar"}/>
                <EditModeActionBar
                    editMode={editMode}
                    showLanugageSelector={true}
                    onRequestCopyArticleContent={() => setShowCopyArticleModal(true)}
                    onEditModeChange={buttonAction}/>
            </>}
            {showCopyArticleModal &&
                <CopyArticleContentModal
                    onCloseRequested={() => setShowCopyArticleModal(false)}
                    onSuccessfulCopy={() => {
                        setShowCopyArticleModal(false)
                        loadHelp()
                    }}
                    targetArticleID={helpArticleData?._id}/>}
        </Modal>
    );

}
