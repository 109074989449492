import {ErrorCode} from "../keys/ErrorCode";

/******************************************************************
 * SessionPenalityTimeValidation
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class SessionPenalityTimeValidation {

    static readonly MIN = 0;
    static readonly MAX = 60;
    static readonly STEP = 1;

    static get REGEXP() {
        return /^(0|[1-9]\d?|60)$/
    }

    static schemaValidator(errorCode?: string) {
        return {
            validate: {
                validator: value => SessionPenalityTimeValidation.isValid(value),
                message: errorCode ?? ErrorCode.SESSION_PENALITY_TIME_INVALID
            },
        }
    };

    static isValid(value: number): boolean {
        return SessionPenalityTimeValidation.REGEXP.test(value?.toString());
    }
}
