import * as React from "react";
import {ActionBarSpacer} from "../spacer/ActionBarSpacer";
import {LabelButton} from "../../buttons/label/LabelButton";
import {FrontendRoute} from "../../../../../shared/routes/FrontendRoute";
import {Divider} from "../../utils/divider/Divider";
import {ActionBar} from "../ActionBar";
import {useRouteStates} from "../../../hooks/useRouteStates";
import {useServices} from "../../../hooks/useServices";
import {useDeviceInfo} from "../../../hooks/useDeviceInfo";
import {useWindowSize} from "../../../hooks/useWindowSize";

/******************************************************************
 * HomeActionBar
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function HomeActionBar() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, state, router} = useServices();
    const {showGroups, showEvents, showPlans, showHome} = useRouteStates()
    const {isMobile} = useDeviceInfo();
    const {windowWidth} = useWindowSize()

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ActionBar location={isMobile ? "main" : "main-top"}>
            {windowWidth > 700 && isMobile && <>
                <ActionBarSpacer/>
                <LabelButton
                    label={dict("home.nav.welcome")}
                    style={showHome ? "action-main-link-selected" : "action-main-link"}
                    onClick={() => router.showRoute(FrontendRoute.HOME)}/>
                <LabelButton
                    label={dict("home.nav.events")}
                    style={showEvents ? "action-main-link-selected" : "action-main-link"}
                    onClick={() => router.showRoute(FrontendRoute.EVENTS)}/>
                <LabelButton
                    label={dict("home.nav.groups")}
                    style={showGroups ? "action-main-link-selected" : "action-main-link"}
                    onClick={() => router.showRoute(FrontendRoute.GROUPS)}/>
                <LabelButton
                    label={dict("home.nav.plans")}
                    style={showPlans ? "action-main-link-selected" : "action-main-link"}
                    onClick={() => router.showRoute(FrontendRoute.PLANS)}/>
                <Divider style="action-bar"/>
            </>}
            <LabelButton
                label={dict("home.nav.login")}
                style="action-main"
                onClick={() => state.showLogin.setValue("login")}/>
        </ActionBar>
    );

}
