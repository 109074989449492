import * as React from "react";
import {SelectInput} from "../select/SelectInput";
import {useServices} from "../../../../hooks/useServices";
import {DriftTrackBundleType} from "../../../../../../shared/types/DriftTrackBundleType";

/******************************************************************
 * DriftTrackBundleInput
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function DriftTrackBundleInput(props: {
    trackBundle: DriftTrackBundleType
    readonly: boolean
    inlineHelp?: string
    onChange: (trackBundle: DriftTrackBundleType) => void
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict} = useServices()

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <SelectInput
            label={dict("drift.trackBundle")}
            defaultValue={props.trackBundle}
            helpTopic="drift.trackBundle"
            inlineHelp={props.inlineHelp}
            readonly={props.readonly}
            onChange={props.onChange}
            options={[
                {value: "none", text: dict("drift.trackBundle.none")},
                {value: "rally_cross", text: dict("drift.trackBundle.rallyCross")},
                {value: "rally", text: dict("drift.trackBundle.rally")}
            ]}/>
    );

}
