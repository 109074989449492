import * as React from "react";
import {useServices} from "../../../../../hooks/useServices";
import {useSessionState} from "../../../../../hooks/useSessionState";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {Input} from "../../../../../ui/form/elements/input/Input";
import {useRouteOwner} from "../../../../../hooks/useRouteOwner";
import {useGroupState} from "../../../../../hooks/useGroupState";
import {FrontendConfig} from "../../../../../../core/FrontendConfig";
import {useRef} from "react";
import {useMobileStyle} from "../../../../../hooks/useMobileStyle";

/******************************************************************
 * SessionAnnouncementsSetup
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function SessionAnnouncementsSetup() {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const ref = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, api} = useServices()
    const {isRouteOwnerAuthUser} = useRouteOwner()
    const {isGroupAdmin} = useGroupState()
    const [isMobileMode] = useMobileStyle(ref, 500)
    const {
        sessionID,
        sessionAnnounceLeaderboardEveryLap,
        sessionAnnounceLapsRemainingEveryLap,
        sessionAnnounceTimeRemainingEverySeconds,
        sessionAnnounceDrivenTimeEveryLap,
        sessionIsFinished
    } = useSessionState()

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    async function updateLeaderboardEveryLap(laps: string): Promise<Response> {
        return api.session.update(sessionID, {setup: {announceLeaderboardEveryLap: parseInt(laps)}})
    }

    async function updateLapsRemainingEveryLap(laps: string): Promise<Response> {
        return api.session.update(sessionID, {setup: {announceLapsRemainingEveryLap: parseInt(laps)}})
    }

    async function updateTimeRemainingEverySeconds(minutes: string): Promise<Response> {
        const seconds = parseInt(minutes) * 60;
        return api.session.update(sessionID, {setup: {announceTimeRemainingEverySeconds: seconds}})
    }

    async function updateDrivenTimeEveryLaps(laps: string): Promise<Response> {
        return api.session.update(sessionID, {setup: {announceDrivenTimeEveryLap: parseInt(laps)}})
    }

    function timeRemainingEveryMinute(): number {
        const seconds = sessionAnnounceTimeRemainingEverySeconds
            ?? FrontendConfig.DEFAULT_ANNOUNCE_TIME_REMAINING_EVERY_N_SECONDS;
        return seconds / 60;
    }

    function isReadonly() {
        if (sessionIsFinished) return true;
        return !isRouteOwnerAuthUser && !isGroupAdmin;
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            ref={ref}
            className="session-announcements-setup"
            columns={isMobileMode ? 1 : 2}>
            <Input
                type="number"
                label={dict("session.announceLeaderboardEveryLap.label")
                    .replace("{LAPS}", sessionAnnounceLeaderboardEveryLap.toString())}
                minNumber={1}
                maxNumber={50}
                stepNumber={1}
                defaultValue={sessionAnnounceLeaderboardEveryLap.toString()}
                readonly={isReadonly()}
                action={updateLeaderboardEveryLap}/>
            <Input
                type="number"
                label={dict("session.announceLapsRemainingEveryLap.label")
                    .replace("{LAPS}", sessionAnnounceLapsRemainingEveryLap.toString())}
                minNumber={1}
                maxNumber={50}
                stepNumber={1}
                defaultValue={sessionAnnounceLapsRemainingEveryLap.toString()}
                readonly={isReadonly()}
                action={updateLapsRemainingEveryLap}/>
            <Input
                type="number"
                label={dict("session.announceTimeRemainingEveryMinute.label")
                    .replace("{MINUTES}", timeRemainingEveryMinute().toString())}
                minNumber={1}
                maxNumber={20}
                stepNumber={1}
                defaultValue={timeRemainingEveryMinute().toString()}
                readonly={isReadonly()}
                action={updateTimeRemainingEverySeconds}/>
            <Input
                type="number"
                label={dict("session.announceDrivenTimeEveryLap.label")
                    .replace("{LAPS}", sessionAnnounceDrivenTimeEveryLap.toString())}
                minNumber={1}
                maxNumber={100}
                stepNumber={1}
                defaultValue={sessionAnnounceDrivenTimeEveryLap.toString()}
                readonly={isReadonly()}
                action={updateDrivenTimeEveryLaps}/>
        </ContentLayout>
    );

}
