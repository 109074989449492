import * as React from "react";
import {useState} from "react";
import {ContentLayout} from "../../../../ui/layout/content/ContentLayout";
import {Article} from "../../../../ui/article/Article";
import {ActionBar} from "../../../../ui/bar/ActionBar";
import {IconButton} from "../../../../ui/buttons/icon/IconButton";
import {useAuthUser} from "../../../../hooks/useAuthUser";
import {useServices} from "../../../../hooks/useServices";

/******************************************************************
 * UserDrivingSchoolPage
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function UserDrivingSchoolPage() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {authUserIsAdmin} = useAuthUser();
    const {dict} = useServices()

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [editMode, setEditMode] = useState(false);

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            className="user-driverschool-page"
            size="content">
            <Article
                articleID={dict("article.id.user.drivingschool")}
                edit={editMode}/>
            {authUserIsAdmin && <ActionBar location="main">
                <IconButton
                    type="edit"
                    size="small"
                    onClick={() => setEditMode(!editMode)}/>
            </ActionBar>}
        </ContentLayout>
    );

}
