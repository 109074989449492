import * as React from "react";
import {ReactElement, useEffect, useState} from "react";

/******************************************************************
 * ComponentInitializer
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function ComponentInitializer(props: {
    children?: ReactElement | ReactElement[] | null
    className?: string
    fullHeight?: boolean
    minHeight?: number
    isPropertyAvailable: boolean
    onDone?: () => void
}) {

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [initDone, setInitDone] = useState<boolean>()
    const [showProgressing, setShowProgressing] = useState<boolean>(false)

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        if (!props.isPropertyAvailable) {
            setShowProgressing(true)
            setInitDone(false)
            return
        }
        setShowProgressing(false)
        setInitDone(true)
        if (props.onDone) {
            props.onDone()
        }
    }, [props.isPropertyAvailable])

    /* ----------------------------------------------------------------
 	 * RENDER
 	 * --------------------------------------------------------------*/

    return (
        <div
            className={"component-initializer" + (props.className ? " " + props.className : "")}
            data-full-height={props.fullHeight}
            data-show-progressing={showProgressing}
            data-init-done={initDone}
            style={{minHeight: props.minHeight}}>
            {props.children}
        </div>
    );

}
