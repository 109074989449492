import * as React from "react";
import {useState} from "react";
import {Headline} from "../../../../ui/text/headings/Headline";
import {Modal} from "../../Modal";
import {useServices} from "../../../../hooks/useServices";
import {IAcademyChapterData} from "../../../../../../shared/models/submodels/IAcademyChapterData";
import {InfoText} from "../../../../ui/text/infotext/InfoText";
import {LabelButton} from "../../../../ui/buttons/label/LabelButton";
import {FormMessage} from "../../../../ui/form/elements/message/FormMessage";
import {ContentLayout} from "../../../../ui/layout/content/ContentLayout";
import {useUtils} from "../../../../hooks/useUtils";

/******************************************************************
 * DeleteAcademyChapterModal
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function DeleteAcademyChapterModal(props: {
    chapterData: IAcademyChapterData
    onClose: () => void
    onSuccess: () => void
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, api, error} = useServices()
    const {multilangString} = useUtils()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [formMessage, setFormMessage] = useState<string>(null)
    const [isBusy, setIsBusy] = useState<boolean>(false)

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function deleteChapter() {
        setFormMessage(null)
        setIsBusy(true)
        const response = await api.academy.deleteChapter(props.chapterData._id)
        if (response.status === 200) {
            props.onClose()
            props.onSuccess()
            return
        }
        setIsBusy(false)
        const errorJSON = await response.json();
        setFormMessage(error.createMessage(errorJSON))
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal
            width="small"
            closeAction={props.onClose}
            className="academy-delete-chapter-modal">
            <Headline
                text={dict("academy.delete.chapter.title")}
                style="modal"/>
            <ContentLayout>
                <InfoText
                    text={dict("academy.delete.chapter.info")
                        .replace("{TITLE}", multilangString(props.chapterData.title))}/>
                {formMessage && <FormMessage message={formMessage} type="error"/>}
                <LabelButton
                    label={dict("academy.delete.chapter.button.label")}
                    progressing={isBusy}
                    style="primary"
                    onClick={deleteChapter}/>
            </ContentLayout>
        </Modal>
    );

}
