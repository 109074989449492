import {FrontendServices} from "../core/FrontendServices";
import {APIRoute} from "../../shared/routes/APIRoute";
import {MongoObjectIDType} from "../../shared/types/MongoObjectIDType";
import {CarChassisType} from "../../shared/types/CarChassisType";
import {ICarData} from "../../shared/models/ICarData";
import {ICarProperty} from "../../shared/models/submodels/ICarProperty";
import {CarStateType} from "../../shared/types/CarStateType";
import {IErrorResponse} from "../../shared/types/IErrorResponse";

/******************************************************************
 * CarAPI
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class CarAPI {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _carByNameAndOwnerCache: Map<string, Promise<ICarData>>;

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(private _frontend: FrontendServices) {
        this._carByNameAndOwnerCache = new Map();
        this._frontend.state.authUser.onChangeSignal.add(() => {
            this._carByNameAndOwnerCache.clear();
        })
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public async create(data: {
        name: string,
        chassis: CarChassisType,
        state: CarStateType
        properties: ICarProperty[]
    }): Promise<Response> {
        return await this._frontend.api.request("POST", APIRoute.CAR, data, true);
    }

    public async uploadImage(formData): Promise<Response> {
        return await this._frontend.api.upload(APIRoute.CAR_IMAGE, formData);
    }

    public async update(carID: MongoObjectIDType, data: ICarData): Promise<Response> {
        return await this._frontend.api.request("PATCH", APIRoute.CAR, {carID: carID, ...data}, true);
    }

    public async getCarByNameAndOwner(carName: string, userID: MongoObjectIDType): Promise<ICarData | IErrorResponse> {
        const cacheKey = `${carName}-${userID}`;
        if (this._carByNameAndOwnerCache.has(cacheKey)) {
            return this._carByNameAndOwnerCache.get(cacheKey);
        }
        const carDataPromise = this.fetchCarDataByNameAndOwner(carName, userID);
        this._carByNameAndOwnerCache.set(cacheKey, carDataPromise);
        const carData = await carDataPromise;
        if (!carData) {
            this._carByNameAndOwnerCache.delete(cacheKey);
        }
        return carData;
    }

    public async getGarage(ownerID: MongoObjectIDType): Promise<ICarData[]> {
        const response = await this._frontend.api.request("GET", APIRoute.GARAGE, {
            ownerID: ownerID
        }, true)
        if (response.status == 200) {
            return await response.json()
        }
        return null
    }

    public async canCreateCar(): Promise<boolean> {
        const response = await this._frontend.api.request("GET", APIRoute.CAR_CAN_CREATE, {}, true)
        if (response.status == 200) {
            return true
        }
        return false
    }

    public async delete(carID: MongoObjectIDType): Promise<Response> {
        return await this._frontend.api.request("DELETE", APIRoute.CAR, {carID}, true)
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private async fetchCarDataByNameAndOwner(carName: string, userID: MongoObjectIDType): Promise<ICarData> {
        const response = await this._frontend.api.request("GET", APIRoute.CAR, {
            carName: carName,
            ownerID: userID
        }, true);

        if (response.status === 200) {
            return response.json();
        }
        return response.json();
    }
}
