import * as React from "react";
import {useEffect, useState} from "react";
import {Modal} from "../Modal";
import {Headline} from "../../../ui/text/headings/Headline";
import {useServices} from "../../../hooks/useServices";
import {LabelValueText} from "../../../ui/text/labelvalue/LabelValueText";
import {ContentLayout} from "../../../ui/layout/content/ContentLayout";
import {InfoText} from "../../../ui/text/infotext/InfoText";
import {FrontendConfig} from "../../../../core/FrontendConfig";

/******************************************************************
 * VersionModal
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function VersionModal() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, state, api} = useServices();

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [changeLog, setChangeLog] = useState<string>()

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        api.info.getChangeLog()
            .then((changeLog: string) => {
                setChangeLog(changeLog)
            })
    }, [])

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal
            width="medium"
            closeAction={() => state.showVersion.setValue(false)}>
            <Headline text={dict("version.info.title")} style="modal"/>
            <ContentLayout>
                {!FrontendConfig.PADDLE_ENABLED_PER_DEFAULT &&
                    <InfoText
                        text={dict("meta.version.info")}
                        style="warning"
                        size="small"/>}
                <ContentLayout columns={3}>
                    <LabelValueText
                        label={dict("meta.version.server")}
                        value={FrontendConfig.SERVER_VERSION}/>
                    <LabelValueText
                        label={dict("meta.version.frontend")}
                        value={FrontendConfig.FRONTEND_VERSION}/>
                    <LabelValueText
                        label={dict("meta.version.backend")}
                        value={FrontendConfig.BACKEND_VERSION}/>
                </ContentLayout>
                <ContentLayout framed={true} gap="small">
                    <Headline text={dict("meta.version.changelog.title")} style="h5-underlined"/>
                    <InfoText text={changeLog} size={"small"}/>
                </ContentLayout>
            </ContentLayout>
        </Modal>
    );

}
