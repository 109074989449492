import * as React from "react";

/******************************************************************
 * SyncIcon
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function SyncIcon(props: {
    scale?: number
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="sync-icon">
            <svg width={14 * (props.scale ?? 1)} height={14 * (props.scale ?? 1)} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.0068 3.73326C9.18741 2.96605 8.12408 2.54549 6.99602 2.54648C4.96587 2.54826 3.21323 3.94026 2.72932 5.87118C2.69409 6.01174 2.56886 6.111 2.42395 6.111H0.922054C0.725534 6.111 0.576244 5.93259 0.612597 5.73945C1.1797 2.72796 3.82373 0.449707 7 0.449707C8.74158 0.449707 10.3232 1.13472 11.4901 2.24992L12.4262 1.31384C12.8225 0.917576 13.5 1.19823 13.5 1.75864V5.27229C13.5 5.6197 13.2184 5.90132 12.871 5.90132H9.35732C8.79691 5.90132 8.51626 5.22377 8.91252 4.82748L10.0068 3.73326ZM1.12903 7.99809H4.64268C5.20309 7.99809 5.48374 8.67564 5.08748 9.07193L3.99323 10.1662C4.81259 10.9334 5.876 11.354 7.00409 11.353C9.03319 11.3511 10.7865 9.96007 11.2707 8.02829C11.3059 7.88773 11.4311 7.78847 11.5761 7.78847H13.078C13.2745 7.78847 13.4238 7.96688 13.3874 8.16002C12.8203 11.1715 10.1763 13.4497 7 13.4497C5.25842 13.4497 3.67685 12.7647 2.50989 11.6495L1.57381 12.5856C1.17755 12.9818 0.5 12.7012 0.5 12.1408V8.62713C0.5 8.27972 0.781623 7.99809 1.12903 7.99809Z" fill="white"/>
            </svg>
        </div>
    );

}
