import * as React from "react";
import {AvatarImage} from "../../../ui/image/avatar/AvatarImage";
import {GroupSidebarInfo} from "./info/GroupSidebarInfo";
import {GroupSidebarMenu} from "./menu/GroupSidebarMenu";
import {useGroupState} from "../../../hooks/useGroupState";

/******************************************************************
 * GroupSidebar
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function GroupSidebar() {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {groupData, isGroupAdmin} = useGroupState()

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <section className="group-sidebar">
            <AvatarImage
                group={groupData}
                editable={isGroupAdmin}/>
            <GroupSidebarInfo/>
            <GroupSidebarMenu/>
        </section>
    );

}
