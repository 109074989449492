import * as React from "react";
import {useSocketState} from "../../hooks/useSocketState";
import {SocketDisconnectedOverlay} from "./socket/SocketDisconnectedOverlay";
import {useWindowState} from "../../hooks/useWindowState";
import {useNotificationState} from "../../hooks/useNotificationState";
import {NotificationOverlay} from "./notification/NotificationOverlay";

/******************************************************************
 * OverlaySystem
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function OverlaySystem() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {isSocketDisconnected} = useSocketState();
    const {isWindowFocusedForAtLeast10Seconds} = useWindowState();
    const {notification} = useNotificationState()

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return <>
        {isWindowFocusedForAtLeast10Seconds && isSocketDisconnected && <SocketDisconnectedOverlay/>}
        {notification && <NotificationOverlay/>}
    </>

}
