/******************************************************************
 * InaudibleAudioLoop
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class InaudibleAudioLoop {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _oscillator: OscillatorNode;
    private _hasStarted: boolean;
    private _gainNode: GainNode;

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor() {
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public start() {
        if (this._hasStarted) {
            return
        }
        if (!window.AudioContext && !(window as any).webkitAudioContext) {
            return
        }
        if (!this._oscillator) {
            this.initAudioLoop()
        }
        this._hasStarted = true
        this._oscillator.start()
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initAudioLoop() {
        const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
        this._oscillator = audioContext.createOscillator();
        this._oscillator.type = 'sine';
        this._oscillator.frequency.setValueAtTime(19000, audioContext.currentTime);
        this._gainNode = audioContext.createGain();
        this._gainNode.gain.value = 0.001;
        this._oscillator.connect(this._gainNode);
        this._gainNode.connect(audioContext.destination);
    }

}
