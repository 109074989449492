import {PaddleEnviromentType} from "../../shared/types/PaddleEnviromentType";
import {IKeyShortcut} from "../utils/interfaces/IKeyShortcut";
import {ServerEnvironmentType} from "../../shared/types/ServerEnviromentType";

/******************************************************************
 * FrontendConfig
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class FrontendConfig {

    /* ----------------------------------------------------------------
 	 * VERSION
 	 * --------------------------------------------------------------*/

    static get SERVER_VERSION(): string {
        return document.documentElement.querySelectorAll('meta[name="server-version"]')[0].getAttribute("content");
    }

    static get BACKEND_VERSION(): string {
        return document.documentElement.querySelectorAll('meta[name="backend-version"]')[0].getAttribute("content");
    }

    static get FRONTEND_VERSION(): string {
        return process.env.VERSION;
    }

    /* ----------------------------------------------------------------
 	 * Root Element Name
 	 * --------------------------------------------------------------*/

    static readonly ROOT_ELEMENT_NAME = "driftclub-frontend"

    /* ----------------------------------------------------------------
 	 * BASE_URL
 	 * --------------------------------------------------------------*/

    static get BASE_URL(): string {
        return document.documentElement.querySelectorAll('meta[name="baseurl"]')[0].getAttribute("content");
    }

    /* ----------------------------------------------------------------
 	 * SESSION
 	 * --------------------------------------------------------------*/

    static readonly SHOW_SESSION_READY_STATE: boolean = false
    static readonly SHOW_SESSION_SYNC_LAP_RACE_FULLFILLMODE_SETTING: boolean = false

    /* ----------------------------------------------------------------
 	 * TEAMS
 	 * --------------------------------------------------------------*/

    static readonly ALLOW_MULTIPLE_TEAMS_PER_USER: boolean = true

    /* ----------------------------------------------------------------
 	 * ANNOUNCEMENTS
 	 * --------------------------------------------------------------*/

    static readonly DEFAULT_ANNOUNCE_LEADERBOARD_EVERY_N_LAPS = 10
    static readonly DEFAULT_ANNOUNCE_LAPS_REMAINING_EVERY_N_LAPS = 10
    static readonly DEFAULT_ANNOUNCE_TIME_REMAINING_EVERY_N_SECONDS = 5 * 60
    static readonly DEFAULT_ANNOUNCE_DRIVEN_TIME_EVERY_N_LAPS = 10
    static readonly ANNOUNCE_TIME_REMAINING_MIN_SECONDS = 60

    /* ----------------------------------------------------------------
 	 * SWIPE
 	 * --------------------------------------------------------------*/

    static readonly SWIPE_DELTA = 30
    static readonly SWIPE_DURATION = 250

    /* ----------------------------------------------------------------
 	 * CHECK INTERVALS
 	 * --------------------------------------------------------------*/

    static readonly APP_UPDATE_CHECK_INTERVAL = 1000 * 60 * 60 * 2 // 2 hour

    /* ----------------------------------------------------------------
 	 * STINTS
 	 * --------------------------------------------------------------*/

    static readonly ENABLE_STINT_SPEAKER = true

    /* ----------------------------------------------------------------
 	 * LOCAL STORAGE KEYS
 	 * --------------------------------------------------------------*/

    static readonly LOCALSTORAGE_ARTICLE_READER_BOX_VISIBILITY = "article.reader.box.visibility"

    /* ----------------------------------------------------------------
 	 * KEY SHORTCUTS
 	 * --------------------------------------------------------------*/

    static readonly SHORTCUT_EVENT_CREATE_SESSION: IKeyShortcut = {key: "S", framed: true}
    static readonly SHORTCUT_EVENT_CREATE_RESULT: IKeyShortcut = {key: "R", framed: true}
    static readonly SHORTCUT_EVENT_CREATE_FOLDER: IKeyShortcut = {key: "F", framed: true}
    static readonly SHORTCUT_SESSION_GAMEID: IKeyShortcut = {key: "G", useShiftKey: true}
    static readonly SHORTCUT_SESSION_SETTINGS: IKeyShortcut = {key: "S", useShiftKey: true}
    static readonly SHORTCUT_SESSION_RESET: IKeyShortcut = {key: "R", useShiftKey: true}
    static readonly SHORTCUT_SESSION_DELETE: IKeyShortcut = {key: "D", useShiftKey: true}
    static readonly SHORTCUT_ADD_FOLLOW_SESSION: IKeyShortcut = {key: "F", useShiftKey: true}
    static readonly SHORTCUT_SESSION_STATE_UPCOMING: IKeyShortcut = {key: "1", useShiftKey: true}
    static readonly SHORTCUT_SESSION_STATE_OPEN: IKeyShortcut = {key: "2", useShiftKey: true}
    static readonly SHORTCUT_SESSION_STATE_FINISHED: IKeyShortcut = {key: "3", useShiftKey: true}

    /* ----------------------------------------------------------------
 	 * ENVIRONMENT VARIABLES (set in DriftClubFrontend.initEnvVars)
 	 * --------------------------------------------------------------*/

    static PADDLE_ENABLED_PER_DEFAULT: boolean
    static PADDLE_CLIENT_TOKEN: string
    static PADDLE_ENVIRONMENT: PaddleEnviromentType
    static PADDLE_AVAILABLE: boolean
    static MAPBOX_ACCESS_TOKEN: string
    static DB_BACKUP_DIR_AVAILABLE: boolean
    static SERVER_ENVIRONMENT: ServerEnvironmentType
}
