import * as React from "react";
import {useState} from "react";
import {ContextMenu} from "../ContextMenu";
import {useServices} from "../../../hooks/useServices";
import {ContextMenuLabel} from "../elements/ContextMenuLabel";
import {IStintData} from "../../../../../shared/models/IStintData";
import {IconButtonSizeType} from "../../buttons/icon/IconButton";
import {useSessionState} from "../../../hooks/useSessionState";
import {useRouteOwner} from "../../../hooks/useRouteOwner";
import {useGroupState} from "../../../hooks/useGroupState";
import {ContextMenuSelectable} from "../elements/ContextMenuSelectable";
import {ContextMenuDivider} from "../elements/ContextMenuDivider";
import {ContextMenuGroup} from "../elements/ContextMenuGroup";

/******************************************************************
 * StintContextMenu
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function StintContextMenu(props: {
    stint: IStintData
    showReset?: boolean
    iconSize?: IconButtonSizeType
}) {

    /* ----------------------------------------------------------------
     * SERVICES
     * --------------------------------------------------------------*/

    const {api, dict, state} = useServices();
    const {isRouteOwnerAuthUser} = useRouteOwner()
    const {isGroupAdmin} = useGroupState()
    const {sessionDeleteStintsForbidden, sessionMode, sessionUseStintApproval, sessionIsFinished} = useSessionState()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [isBusy, setIsBusy] = useState<boolean>(false)

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function deleteStint() {
        state.showConfirm.setValue({
            type: "deleteStint",
            payload: {
                stintID: props.stint._id
            }
        })
    }

    async function approveStint(needHigherDriverLicense: boolean) {
        if (needHigherDriverLicense) {
            showUpgradeInfo()
            return
        }
        setIsBusy(true)
        await api.stint.updateStint(props.stint._id, {approvalState: "approved"})
        setIsBusy(false)
    }

    async function rejectStint(needHigherDriverLicense: boolean) {
        if (needHigherDriverLicense) {
            showUpgradeInfo()
            return
        }
        setIsBusy(true)
        await api.stint.updateStint(props.stint._id, {approvalState: "rejected"})
        setIsBusy(false)
    }

    async function pendingStint(needHigherDriverLicense: boolean) {
        if (needHigherDriverLicense) {
            showUpgradeInfo()
            return
        }
        setIsBusy(true)
        await api.stint.updateStint(props.stint._id, {approvalState: "pending"})
        setIsBusy(false)
    }

    async function finishStint() {
        setIsBusy(true)
        await api.stint.updateStint(props.stint._id, {state: "finished"})
        setIsBusy(false)
    }

    async function resetToComputedTargetData() {
        setIsBusy(true)
        const originalTargetsData = props.stint.targetsData.map(targetData => {
            return {
                ...targetData,
                _overwrittenTargetCode: undefined,
                _disabled: undefined
            }
        })
        await api.stint.updateStint(props.stint._id, {targetsData: originalTargetsData})
        setIsBusy(false)
    }

    function isAllowedToEdit() {
        if (isRouteOwnerAuthUser || isGroupAdmin) return true
        return sessionDeleteStintsForbidden !== true
    }

    function hasRaceDirector() {
        return isGroupAdmin && sessionMode == "race"
    }

    function showContextMenu() {
        if (sessionIsFinished) return false
        return props.showReset
            || isAllowedToEdit()
            || hasRaceDirector()
    }

    function showUpgradeInfo() {
        state.showSubscriptionUpgradeInfo.setValue({
            message: dict("subscription.upgrade.info.stintApproval"),
        })
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return <>
        {showContextMenu() &&
            <ContextMenu
                type="icon"
                iconType="menuDots"
                iconScale={0.9}
                isBusy={isBusy}
                iconSize={props.iconSize ?? "normal"}
                stopPropagation={true}>
                {props.stint.state !== "finished" &&
                    <ContextMenuLabel
                        label={dict("stint.finish.label")}
                        icon="flag"
                        onClick={finishStint}/>}
                {props.showReset &&
                    <ContextMenuLabel
                        label={dict("stint.reset.label")}
                        icon="restore"
                        onClick={resetToComputedTargetData}/>}
                {hasRaceDirector() &&
                    <ContextMenuLabel
                        label={dict("stint.raceDirector.label")}
                        icon="megaphone"
                        requiredDriverLicense="premium"
                        onClick={() => state.showRaceDirector.setValue(props.stint)}/>}
                {sessionUseStintApproval && <>
                    <ContextMenuDivider style="small"/>
                    <ContextMenuGroup name={dict("stint.approval.label")}/>
                    <ContextMenuSelectable
                        label={dict("stint.pending.label")}
                        icon="pending"
                        iconColor="yellow"
                        requiredDriverLicense="premium"
                        selected={props.stint.approvalState == "pending" || !props.stint.approvalState}
                        onClick={pendingStint}/>
                    <ContextMenuSelectable
                        label={dict("stint.approve.label")}
                        icon="save"
                        requiredDriverLicense="premium"
                        iconColor="green"
                        selected={props.stint.approvalState == "approved"}
                        onClick={approveStint}/>
                    <ContextMenuSelectable
                        label={dict("stint.reject.label")}
                        icon="cancel"
                        requiredDriverLicense="premium"
                        iconColor="red"
                        selected={props.stint.approvalState == "rejected"}
                        onClick={rejectStint}/>
                    <ContextMenuDivider style="small"/>
                </>}
                {isAllowedToEdit() &&
                    <ContextMenuLabel
                        label={dict("stint.delete.label")}
                        icon="delete"
                        warning={true}
                        onClick={deleteStint}/>}
            </ContextMenu>}
    </>
}
