import * as React from "react";
import {useServices} from "../../../../../hooks/useServices";
import {useSessionState} from "../../../../../hooks/useSessionState";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {Input} from "../../../../../ui/form/elements/input/Input";
import {
    SessionPenalityTimeValidation
} from "../../../../../../../shared/validation/SessionPenalityTimeValidation";
import {useRouteOwner} from "../../../../../hooks/useRouteOwner";
import {useGroupState} from "../../../../../hooks/useGroupState";
import {useMobileStyle} from "../../../../../hooks/useMobileStyle";
import {useRef} from "react";

/******************************************************************
 * SessionFalseStartSetup
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function SessionFalseStartSetup() {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const ref = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, api} = useServices()
    const {isRouteOwnerAuthUser} = useRouteOwner()
    const {isGroupAdmin} = useGroupState()
    const [isMobileMode] = useMobileStyle(ref, 500)
    const {
        sessionID,
        sessionFalseStartPenality,
        sessionIsFinished
    } = useSessionState()


    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function updateSessionFalseStartPenality(falseStartPenalty: string): Promise<Response> {
        return await api.session.update(sessionID, {setup: {falseStartPenalty: parseInt(falseStartPenalty)}})
    }

    function readonly() {
        if (sessionIsFinished) return true;
        return !isRouteOwnerAuthUser && !isGroupAdmin;
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            ref={ref}
            columns={isMobileMode ? 1 : 2}
            className="session-falsestart-setup">
            <Input
                type="number"
                minNumber={SessionPenalityTimeValidation.MIN}
                maxNumber={SessionPenalityTimeValidation.MAX}
                stepNumber={SessionPenalityTimeValidation.STEP}
                label={dict("session.falseStartPenality")}
                showInvalid={true}
                readonly={readonly()}
                defaultValue={sessionFalseStartPenality?.toString()}
                pattern={SessionPenalityTimeValidation.REGEXP.source}
                action={updateSessionFalseStartPenality}/>
        </ContentLayout>
    );

}
