import * as React from "react";
import {useEffect, useState} from "react";
import {useServices} from "../../../hooks/useServices";
import {IEventData} from "../../../../../shared/models/IEventData";
import {useRouteOwner} from "../../../hooks/useRouteOwner";
import {FrontendRoute} from "../../../../../shared/routes/FrontendRoute";
import {Table} from "../../table/Table";
import {ITableColumnDefinition} from "../../table/ITableColumnDefinition";
import {ComponentInitializer} from "../../utils/init/ComponentInitializer";
import {Infobox} from "../Infobox";

/******************************************************************
 * TrainingsInfo
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function TrainingsInfobox() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {state, router, api, dict} = useServices()
    const {routeOwnerID, routeOwnerPath, isRouteOwnerAuthUser} = useRouteOwner()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [trainings, setTrainings] = useState<IEventData[]>()
    const [loading, setLoading] = useState<boolean>(true)

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        setLoading(true)
        loadTrainings()
    }, [routeOwnerID])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    async function loadTrainings() {
        const result = await api.event.getOwnerEvents(routeOwnerID, "user")
        result.sort((a, b) => {
            const result = b.state.localeCompare(a.state)
            if (result == 0) {
                return b._id.localeCompare(a._id)
            }
            return result
        })
        setTrainings(result)
        setLoading(false)
    }

    function columnsDefinition(): ITableColumnDefinition<IEventData>[] {
        return [{
            type: "image",
            dataKey: "image",
            size: "50px"
        }, {
            dataKey: "name",
            sortKey: "name",
            type: "text-bold",
            size: "4fr"
        }, {
            type: "privacy",
            dataKey: "privacy",
            size: "50px",
            sortKey: "privacy"
        }];
    }

    function title(): string {
        if (trainings?.length == 1) {
            return dict("infobox.training.single.title")
        }
        if (trainings?.length > 0) {
            return trainings.length + " " + dict("infobox.training.title")
        }
        return dict("infobox.training.title")
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Infobox
            title={title()}
            hasData={trainings?.length > 0}
            loading={loading}
            noDataInfo={dict(isRouteOwnerAuthUser
                ? "infobox.training.empty.info.owner"
                : "infobox.training.empty.info.guest")}
            createButtonLabel={dict("infobox.training.create.button.label")}
            createButtonOnClick={() => state.showCreateEvent.setValue(true)}
            linkButtonLabel={dict("infobox.training.showAll.button.label")}
            linkButtonOnClick={() => router.showRoute(FrontendRoute.USER_TRAINING(routeOwnerPath))}
            helpButtonLabel={dict("infobox.training.academy.button.label")}
            helpButtonOnClick={() => state.showAcademy.setValue(dict("academy.id.training"))}>
            <ComponentInitializer isPropertyAvailable={!loading}>
                <Table<IEventData>
                    rowsData={trainings?.slice(0, 5)}
                    showHeader={false}
                    onRowClick={event => router.showRoute(FrontendRoute.EVENT("user", routeOwnerPath, event.path))}
                    columnsDefinition={columnsDefinition()}/>
            </ComponentInitializer>
        </Infobox>
    );

}
