import * as React from "react";
import {Headline} from "../../../../ui/text/headings/Headline";
import {ContentLayout} from "../../../../ui/layout/content/ContentLayout";
import {LabelText} from "../../../../ui/text/label/LabelText";

/******************************************************************
 * ContentInfo
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function ContentInfo(props: {
    value: string
    label: string
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            className="leaderboard-entry-modal-content-info"
            gap="none"
            useFrameMargin={false}
            justifyItems="end">
            <Headline text={props.value ?? ""} style="h1-mono"/>
            <LabelText label={props.label}/>
        </ContentLayout>
    );

}
