import * as React from "react";
import {useEffect} from "react";
import {Modal} from "../Modal";
import {Headline} from "../../../ui/text/headings/Headline";
import {useServices} from "../../../hooks/useServices";
import {ContentLayout} from "../../../ui/layout/content/ContentLayout";
import {InfoText} from "../../../ui/text/infotext/InfoText";
import {FrontendConfig} from "../../../../core/FrontendConfig";
import {LabelButton} from "../../../ui/buttons/label/LabelButton";

/******************************************************************
 * UpdateAppModal
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function UpdateAppModal() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, api} = useServices();

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [serverVersion, setServerVersion] = React.useState<string>("");

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        api.info.getServerVersion()
            .then((serverVersion: string) => {
                setServerVersion(serverVersion)
            })
    }, [])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function updateToLatestVersion() {
        window.location.reload()
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal width="small">
            <Headline text={dict("update.app.title")} style="modal"/>
            <ContentLayout>
                <InfoText
                    text={dict("update.app.info")
                        .replace("{INSTALLED_VERSION}", FrontendConfig.SERVER_VERSION)
                        .replace("{LATEST_VERSION}", serverVersion)}/>
                <LabelButton
                    label={dict("update.app.button.label")}
                    style={"primary"}
                    onClick={updateToLatestVersion}/>
            </ContentLayout>
        </Modal>
    );

}
