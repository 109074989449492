import * as React from "react";

/******************************************************************
 * UpIcon
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function UpIcon(props: {
    scale?: number
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="up-icon">
            <svg width={12 * (props.scale ?? 8)} height={16 * (props.scale ?? 1)} viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.704465 6.94903C1.09374 7.3383 1.72477 7.33865 2.11446 6.9498L5.29275 3.77844C5.68309 3.38895 6.31507 3.38895 6.70542 3.77844L9.8837 6.9498C10.2734 7.33865 10.9044 7.3383 11.2937 6.94903V6.94903C11.6833 6.55946 11.6833 5.92784 11.2937 5.53826L6.70619 0.950753C6.31566 0.560229 5.6825 0.560229 5.29197 0.950753L0.704465 5.53826C0.314892 5.92784 0.314892 6.55946 0.704465 6.94903V6.94903Z" fill="white"/>
            </svg>
        </div>
    );

}
