import * as React from "react";
import {useServices} from "../../../hooks/useServices";
import {Modal} from "../Modal";
import {SplitView} from "../../../ui/splitview/SplitView";
import {SplitViewContent} from "../../../ui/splitview/content/SplitViewContent";
import {TrackGeneralSettings} from "./settings/TrackGeneralSettings";
import {TrackDeleteSettings} from "./settings/TrackDeleteSettings";
import {SidebarSpacer} from "../../../ui/splitview/spacer/SidebarSpacer";
import {TrackSetupSettings} from "./settings/TrackSetupSettings";
import {TrackConstructSettings} from "./settings/TrackConstructSettings";
import {useRouteOwner} from "../../../hooks/useRouteOwner";
import {useGroupState} from "../../../hooks/useGroupState";
import {useState} from "react";

/******************************************************************
 * TrackSettingsModal
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function TrackSettingsModal() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, state} = useServices()
    const {isRouteOwnerAuthUser} = useRouteOwner();
    const {isGroupAdmin} = useGroupState()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [splitViewMobileMode, setSplitViewMobileMode] = useState<boolean>(false)

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function editable(): boolean {
        return isRouteOwnerAuthUser || isGroupAdmin
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal
            closeAction={() => state.showTrackSettings.setValue(false)}
            width="extra-large"
            height={splitViewMobileMode ? "auto" : "medium"}
            padding="none">
            <SplitView
                padding="modal"
                onMobileMode={setSplitViewMobileMode}
                title={dict("track.settings.title")}>
                {editable() &&
                    <SplitViewContent
                        label={dict("track.settings.general")}
                        content={<TrackGeneralSettings/>}/>}
                {editable() &&
                    <SplitViewContent
                        label={dict("track.settings.construct")}
                        content={<TrackConstructSettings/>}/>}
                <SplitViewContent
                    label={dict("track.settings.setup")}
                    content={<TrackSetupSettings/>}/>
                <SidebarSpacer/>
                {editable() &&
                    <SplitViewContent
                        label={dict("track.settings.delete")}
                        content={<TrackDeleteSettings/>}/>}
            </SplitView>
        </Modal>
    );

}
