import * as React from "react";
import {ReactElement, useEffect, useState} from "react";
import {ISessionLeaderboardEntry} from "../../../../../../shared/types/ISessionLeaderboardEntry";
import {TeamInfo, TeamInfoDriverState} from "../../../info/team/TeamInfo";
import {ITeamData} from "../../../../../../shared/models/submodels/ITeamData";
import {IUserData} from "../../../../../../shared/models/IUserData";
import {IconButton} from "../../../buttons/icon/IconButton";
import {TeamLeaderboard} from "./leaderboard/TeamLeaderboard";
import {FoldingUtils} from "../../../../../utils/FoldingUtils";

/******************************************************************
 * TableLeaderboardTeamCell
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function TableLeaderboardTeamCell(props: {
    entry: ISessionLeaderboardEntry
    onToggleTeamMembers?: (show: ReactElement) => void
}) {

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [showTeamMembers, setShowTeamMembers] = useState<boolean>(FoldingUtils.isTeamFolderOpen(folderID()))

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        FoldingUtils.setTeamFolderState(folderID(), showTeamMembers)
        if (showTeamMembers) {
            return props.onToggleTeamMembers?.(<TeamLeaderboard
                entries={props.entry.team?.leaderboardEntries}
                session={props.entry.session}
            />)
        }
        props.onToggleTeamMembers?.(null)
    }, [showTeamMembers, props.entry])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function teamData(): ITeamData {
        return {
            name: props.entry.team?.name,
            color: props.entry.team?.color,
            members: props.entry.team?.members as IUserData[]
        };
    }

    function toggleTeamMembers() {
        setShowTeamMembers((prevState) => !prevState)
    }

    function theme(): "dark" | "light" {
        switch (props.entry.state) {
            case "ready":
            case "driving":
                return "light"
            case "invalid":
            case "finished":
                return "dark"
        }
    }

    function folderID(): string {
        return "team." + props.entry._key ?? props.entry.team.name
    }

    function driverStates(): TeamInfoDriverState[] {
        return props.entry.team?.members.map((member: IUserData) => {
            return {
                user: member,
                state: props.entry.team.leaderboardEntries.find((entry: ISessionLeaderboardEntry) => {
                    return entry.latestStint?.user._id === member._id
                })?.state
            }
        })
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="table-leaderboard-team-cell" data-theme={theme()}>
            <TeamInfo
                team={teamData()}
                theme={theme()}
                teamState={props.entry.state}
                driverStates={driverStates()}
                style="leaderboard"/>
            <IconButton
                type={showTeamMembers ? "up" : "down"}
                stopPropagation={true}
                onClick={toggleTeamMembers}/>
        </div>
    );

}
