import * as React from "react";

/******************************************************************
 * DriftIcon
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function DriftIcon(props: {
    scale?: number
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="drift-icon">
            <svg width={16 * (props.scale ?? 1)} height={17 * (props.scale ?? 1)} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="7.99998" cy="8.94975" r="3.23466" fill="#D9D9D9"/>
                <circle cx="7.99998" cy="8.94966" r="6.19624" stroke="white" strokeWidth="1.5"/>
            </svg>
        </div>
    );

}
