import * as React from "react";
import {useEffect, useState} from "react";
import {useServices} from "../../../../../hooks/useServices";
import {ITrackData} from "../../../../../../../shared/models/submodels/ITrackData";
import {useEventState} from "../../../../../hooks/useEventState";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {TrackList} from "../../../../../ui/list/track/TrackList";
import {Modal} from "../../../../modals/Modal";
import {Headline} from "../../../../../ui/text/headings/Headline";
import {LabelButton} from "../../../../../ui/buttons/label/LabelButton";
import {useRouteOwner} from "../../../../../hooks/useRouteOwner";

/******************************************************************
 * EventTrackSettings
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function EventTrackSettings() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, api, state} = useServices();
    const {routeOwnerValidDriverLicense} = useRouteOwner()
    const {eventID, eventOwnerType, eventOwner, eventTracks, eventIsFinished} = useEventState()

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [ownerTracks, setOwnerTracks] = useState<ITrackData[]>()
    const [availableTracks, setAvailableTracks] = useState<ITrackData[]>()
    const [showAvailableTracks, setShowAvailableTracks] = useState<boolean>(false)

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        if (!eventOwnerType || !eventOwner) {
            setOwnerTracks(null)
            return
        }
        loadAvailableTracks()
    }, [eventOwnerType, eventOwner, routeOwnerValidDriverLicense])


    useEffect(() => {
        setAvailableTracks(ownerTracks?.filter((track: ITrackData) => {
            return !eventTracks?.find((eventTrack: ITrackData) => eventTrack._id == track._id)
        }))
    }, [eventTracks, ownerTracks])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function loadAvailableTracks() {
        const tracksData = await api.track.ownerTracks(eventOwner._id, eventOwnerType)
        setOwnerTracks(tracksData)
    }

    async function addTrackToEvent(track: ITrackData) {
        if (track._notAllowedToUse) {
            state.showSubscriptionUpgradeInfo.setValue({message: dict("subscription.upgrade.info.track.not.useable")})
            return
        }
        await api.event.update(eventID, {tracks: [...eventTracks, track]})
    }

    async function removeTrackToEvent(data: ITrackData) {
        await api.event.update(eventID, {
            tracks: eventTracks.filter((track: ITrackData) => {
                return track._id != data._id
            })
        })
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (<>
        <ContentLayout className="event-track-settings" gap="small">
            <ContentLayout
                columns={2}
                justifyContent="space-between"
                columnTemplate={"auto min-content"}>
                <Headline
                    text={dict("tracks.event.label")}
                    style="h5"/>
                <LabelButton
                    label={dict("tracks.add.to.event.label")}
                    style="primary-small"
                    disabled={eventIsFinished}
                    onClick={() => setShowAvailableTracks(true)}/>
            </ContentLayout>
            <TrackList
                tracksData={eventTracks}
                readonly={eventIsFinished}
                noDataInfo={dict("tracks.event.noData.info")}
                onRemoveClicked={removeTrackToEvent}/>
        </ContentLayout>
        {showAvailableTracks && <Modal
            width="large"
            closeAction={() => setShowAvailableTracks(false)}>
            <Headline text={dict("tracks.available.title")} style="modal"/>
            <TrackList
                tracksData={availableTracks}
                onAddClicked={addTrackToEvent}
                noDataInfo={dict("tracks.available.noData.info")}/>
        </Modal>}
    </>);

}
