import * as React from "react";
import {SelectInput} from "../select/SelectInput";
import {MultiSelectInput} from "../select/MultiSelectInput";
import {ContentLayout} from "../../../layout/content/ContentLayout";
import {useServices} from "../../../../hooks/useServices";
import {TrackLapCountModeType} from "../../../../../../shared/types/TrackLapCountModeType";
import {DriftTargetCodeType} from "../../../../../../shared/types/DriftTargetCodeType";

/******************************************************************
 * TrackSectorsInput
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function TrackSectorsInput(props: {
    editable: boolean
    numSectors: number
    sectorTargets: DriftTargetCodeType[]
    lapCountMode: TrackLapCountModeType
    onNumSectorsChanged: (value: string | string[] | number) => void
    onSectorTargetsChanged: (value: DriftTargetCodeType[]) => void
    onLapCountModeChanged: (value: TrackLapCountModeType) => void
    isMobileMode?: boolean
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict} = useServices()

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (<ContentLayout>
            <ContentLayout columns={props.numSectors > 1 ? (props.isMobileMode ? 1 : 2) : 1}>
                <SelectInput
                    label={dict("track.numSectors")}
                    defaultValue={props.numSectors}
                    readonly={!props.editable}
                    helpTopic="track.numSectors"
                    onChange={props.onNumSectorsChanged}
                    options={[
                        {value: 1, text: dict("track.numSectors.one")},
                        {value: 2, text: dict("track.numSectors.two")},
                        {value: 3, text: dict("track.numSectors.three")}
                    ]}/>
                {props.numSectors > 1 && <MultiSelectInput
                    label={dict("session.sectorTargets")}
                    helpTopic="session.sectorTargets"
                    readonly={!props.editable}
                    defaultValue={props.sectorTargets}
                    options={[
                        {value: "targetCode_4", text: dict("drift.targetCode_4")},
                        {value: "targetCode_5", text: dict("drift.targetCode_5")},
                        {value: "targetCode_6", text: dict("drift.targetCode_6")},
                        {value: "targetCode_7", text: dict("drift.targetCode_7")}
                    ]}
                    onChange={props.onSectorTargetsChanged}
                    minSelected={1}/>}
            </ContentLayout>
            {props.numSectors > 1 && <>
                <SelectInput
                    label={dict("session.lapCountMode")}
                    defaultValue={props.lapCountMode}
                    readonly={!props.editable}
                    helpTopic="session.lapCountMode"
                    onChange={props.onLapCountModeChanged}
                    options={[{
                        value: "only-finish-targets" as TrackLapCountModeType,
                        text: dict("track.lapCountMode.onlyFinishTargets")
                    }, {
                        value: "finish-targets-and-max-sectors-reached" as TrackLapCountModeType,
                        text: dict("track.lapCountMode.finishTargetsAndMaxSectorsReached")
                    },]}/>
            </>}
        </ContentLayout>
    );
}
