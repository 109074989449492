import * as React from "react";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {SelectInput} from "../../../../../ui/form/elements/select/SelectInput";
import {useServices} from "../../../../../hooks/useServices";
import {useSessionState} from "../../../../../hooks/useSessionState";
import {useRouteOwner} from "../../../../../hooks/useRouteOwner";
import {useGroupState} from "../../../../../hooks/useGroupState";

/******************************************************************
 * SessionRightsSetup
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function SessionRightsSetup() {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {state, dict, api} = useServices()
    const {isRouteOwnerAuthUser} = useRouteOwner()
    const {isGroupAdmin} = useGroupState()
    const {
        sessionID,
        sessionMode,
        sessionDeleteStintsForbidden,
        sessionIsFinished,
        sessionUseStintApproval,
        sessionEditTargetsForbidden
    } = useSessionState()

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    async function updateDeleteStintsForbidden(value: string): Promise<Response> {
        return await api.session.update(sessionID, {
            setup: {
                deleteStintsForbidden: value == "true"
            }
        })
    }

    async function updateEditTargetsForbidden(value: string): Promise<Response> {
        return await api.session.update(sessionID, {
            setup: {
                editTargetsForbidden: value == "true"
            }
        })
    }

    async function updateUseStintApproval(value: string): Promise<Response> {
        return await api.session.update(sessionID, {
            setup: {
                useStintApproval: value == "true"
            }
        })
    }

    function isReadonly() {
        if (sessionIsFinished) return true;
        return !isRouteOwnerAuthUser && !isGroupAdmin;
    }

    function showEditTargetsForbidden(): boolean {
        switch (sessionMode) {
            case "lap":
            case "race":
                return true
            case "gymkhana":
                return false
        }
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            className="session-rights-setup">
            <SelectInput
                label={dict("session.rights.deleteStintsForbidden.label")}
                onChange={updateDeleteStintsForbidden}
                readonly={isReadonly()}
                required={false}
                defaultValue={sessionDeleteStintsForbidden ? "true" : "false"}
                options={[{
                    value: "false",
                    text: dict("session.rights.deleteStintsForbidden.false")
                }, {
                    value: "true",
                    text: dict("session.rights.deleteStintsForbidden.true")
                },]}/>
            {showEditTargetsForbidden() &&
                <SelectInput
                    label={dict("session.rights.editTargetsForbidden.label")}
                    onChange={updateEditTargetsForbidden}
                    readonly={isReadonly()}
                    required={false}
                    helpTopic={"session.editTargetsForbidden"}
                    defaultValue={sessionEditTargetsForbidden ? "true" : "false"}
                    options={[{
                        value: "false",
                        text: dict("session.rights.editTargetsForbidden.false")
                    }, {
                        value: "true",
                        text: dict("session.rights.editTargetsForbidden.true")
                    },]}/>}
            <SelectInput
                label={dict("session.rights.useStintApproval.label")}
                onChange={updateUseStintApproval}
                readonly={isReadonly()}
                required={false}
                helpTopic={"session.stintApproval"}
                onHigherDriverLicenseRequired={() => state.showSubscriptionUpgradeInfo.setValue({
                    message: dict("subscription.upgrade.info.stintApproval"),
                })}
                defaultValue={sessionUseStintApproval ? "true" : "false"}
                options={[{
                    value: "false",
                    requiredDriverLicense: "premium",
                    text: dict("session.rights.useStintApproval.false")
                }, {
                    value: "true",
                    requiredDriverLicense: "premium",
                    text: dict("session.rights.useStintApproval.true")
                },]}/>
        </ContentLayout>
    );

}
