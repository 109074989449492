import * as React from "react";
import {ActionBar} from "../../../../ui/bar/ActionBar";
import {IconButton} from "../../../../ui/buttons/icon/IconButton";
import {LanguageSelector} from "../../../../ui/form/elements/language/LanguageSelector";
import {ActionBarSpacer} from "../../../../ui/bar/spacer/ActionBarSpacer";
import {Divider} from "../../../../ui/utils/divider/Divider";
import {LabelButton} from "../../../../ui/buttons/label/LabelButton";
import {useServices} from "../../../../hooks/useServices";

/******************************************************************
 * EditModeActionBar
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function EditModeActionBar(props: {
    editMode: boolean
    showLanugageSelector?: boolean
    onEditModeChange: (isEditMode: boolean) => void
    onRequestCopyArticleContent?: () => void
    onRequestDelete?: () => void
}) {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {dict} = useServices()

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ActionBar
            location="modal"
            backgroundColor={props.editMode ? "red" : "black"}>
            {props.editMode && props.onRequestCopyArticleContent && <>
                <ActionBarSpacer/>
                <LabelButton
                    label={dict("article.copy.modal.button.label")}
                    style={"secondary-small"}
                    onClick={props.onRequestCopyArticleContent}/>
                <Divider style="action-bar"/>
            </>}
            {props.showLanugageSelector && <>
                <LanguageSelector size="small"/>
                <Divider style="action-bar"/>
            </>}
            {!props.editMode && props.onRequestDelete && <>
                <ActionBarSpacer/>
                <IconButton
                    size="small"
                    type="delete"
                    scale={1}
                    onClick={() => props.onRequestDelete?.()}/>
                <Divider style="action-bar"/>
            </>}
            <IconButton
                size="small"
                type={props.editMode ? "check" : "edit"}
                scale={1}
                opacityMode={props.editMode ? "none" : "medium"}
                onClick={() => props.onEditModeChange(!props.editMode)}/>
            {(props.showLanugageSelector || (!props.editMode && props.onRequestDelete)) &&
                <ActionBarSpacer/>}
        </ActionBar>
    );

}
