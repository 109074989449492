import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {SelectInput, SelectInputOptionType} from "../../../../../ui/form/elements/select/SelectInput";
import {useServices} from "../../../../../hooks/useServices";
import {useSessionState} from "../../../../../hooks/useSessionState";
import {DriftTargetCodeType} from "../../../../../../../shared/types/DriftTargetCodeType";
import {useRouteOwner} from "../../../../../hooks/useRouteOwner";
import {useGroupState} from "../../../../../hooks/useGroupState";
import {TrackLapCountModeType} from "../../../../../../../shared/types/TrackLapCountModeType";
import {Input} from "../../../../../ui/form/elements/input/Input";
import {SessionLapsValidation} from "../../../../../../../shared/validation/SessionLapsValidation";
import {Headline} from "../../../../../ui/text/headings/Headline";
import {DriftTrackConditionInput} from "../../../../../ui/form/elements/drift/DriftTrackConditionInput";
import {DriftTrackBundleInput} from "../../../../../ui/form/elements/drift/DriftTrackBundleInput";
import {DriftTrackConditionType} from "../../../../../../../shared/types/DriftTrackConditionType";
import {DriftTrackBundleType} from "../../../../../../../shared/types/DriftTrackBundleType";
import {SessionPenalityTimeValidation} from "../../../../../../../shared/validation/SessionPenalityTimeValidation";
import {useEventState} from "../../../../../hooks/useEventState";
import {MongoObjectIDType} from "../../../../../../../shared/types/MongoObjectIDType";
import {Picture} from "../../../../../ui/image/Picture";
import {ITrackData} from "../../../../../../../shared/models/submodels/ITrackData";
import {TrackMinLapTimeInput} from "../../../../../ui/form/elements/track/TrackMinLapTimeInput";
import {TrackSectorsInput} from "../../../../../ui/form/elements/track/TrackSectorsInput";
import {TrackJokerLapTargetInput} from "../../../../../ui/form/elements/track/TrackJokerLapTargetInput";
import {LabelButton} from "../../../../../ui/buttons/label/LabelButton";
import {SessionSetupPresets} from "../../../../../../../shared/config/SessionSetupPresets";
import {TrackTargetPreProcessorInput} from "../../../../../ui/form/elements/track/TrackTargetPreProcessorInput";
import {TargetPreProcessorMode} from "../../../../../../../shared/types/TargetPreProcessorMode";
import {useMobileStyle} from "../../../../../hooks/useMobileStyle";

/******************************************************************
 * SessionSectorsSetup
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function SessionTrackSetup() {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const ref = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {dict, api} = useServices()
    const {isRouteOwnerAuthUser} = useRouteOwner()
    const {isGroupAdmin} = useGroupState()
    const {eventTracks} = useEventState()
    const [isMobileMode] = useMobileStyle(ref, 500)
    const {
        sessionID,
        sessionMode,
        sessionSetup,
        sessionNumSectors,
        sessionSectorTargets,
        sessionLapCountMode,
        sessionMinLapTime,
        sessionJokerLapTarget,
        sessionNumJokerLaps,
        sessionTrackID,
        sessionTrackBundle,
        sessionTrackConditions,
        sessionJokerLapPenalty,
        sessionTargetPreProcessor,
        sessionIsFinished
    } = useSessionState()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [selectedTrackValue, setSelectedTrackValue] = useState<MongoObjectIDType>()
    const [selectedTrackData, setSelectedTrackData] = useState<ITrackData>()
    const [selectableTrackOptions, setSelectableTrackOptions] = useState<SelectInputOptionType<MongoObjectIDType>[]>()
    const [syncingTrackData, setSyncingTrackData] = useState(false)
    const [showSyncTrackDataButton, setShowSyncTrackDataButton] = useState(false)

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        updateTrackSelection()
    }, [eventTracks, sessionTrackID, sessionSetup, isGroupAdmin, isRouteOwnerAuthUser])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function updateTrackSelection() {
        const availableOptions = eventTracks?.map(track => ({
            value: track._id,
            text: track.name + (track.layout ? " | " + track.layout : "")
        }))
        const trackData = eventTracks?.find(track => track._id === sessionTrackID)
        if (eventTracks?.length == 0 || !trackData) {
            availableOptions?.unshift({
                value: "none",
                text: dict("session.track.selection.none")
            })
        }
        setSelectableTrackOptions(availableOptions)
        setSelectedTrackValue(sessionTrackID || "none")
        setSelectedTrackData(trackData)
        setShowSyncTrackDataButton((isGroupAdmin || isRouteOwnerAuthUser)
            && eventTracks?.length > 0
            && !SessionSetupPresets.isTrackEqualToSession(trackData, sessionSetup))
    }

    async function updateSessionNumSectors(numSectors: number) {
        await api.session.update(sessionID, {setup: {numSectors: numSectors}})
    }

    async function updateSessionSectorTargets(sectorTargets: DriftTargetCodeType[]) {
        await api.session.update(sessionID, {setup: {sectorTargets: sectorTargets}})
    }

    async function updateSessionLapCountMode(lapCountMode: TrackLapCountModeType) {
        await api.session.update(sessionID, {setup: {lapCountMode: lapCountMode}})
    }

    async function updateSessionMinLapTime(minLapTime: string): Promise<Response> {
        return await api.session.update(sessionID, {setup: {minLapTime: parseInt(minLapTime)}})
    }

    async function updateSessionJokerLapTarget(target: DriftTargetCodeType): Promise<Response> {
        return await api.session.update(sessionID, {setup: {jokerLapTarget: target}})
    }

    async function updateSessionNumJokerLaps(numJokerLaps: string): Promise<Response> {
        return await api.session.update(sessionID, {
            setup: {
                numJokerLaps: numJokerLaps ? parseInt(numJokerLaps) : 0
            }
        })
    }

    async function updateSessionTrackConditions(trackConditions: DriftTrackConditionType[]): Promise<Response> {
        return await api.session.update(sessionID, {setup: {trackConditions: trackConditions}})
    }

    async function updateSessionTrackBundle(trackBundle: DriftTrackBundleType): Promise<Response> {
        return await api.session.update(sessionID, {setup: {trackBundle: trackBundle}})
    }

    async function updateSessionJokerLapPenality(value: string): Promise<Response> {
        return await api.session.update(sessionID, {setup: {jokerLapPenalty: parseInt(value)}})
    }

    async function updateSessionTrack(trackID: MongoObjectIDType): Promise<Response> {
        return await api.session.update(sessionID, {setup: {track: trackID == "none" ? null : trackID}})
    }

    async function updateSessionTargetPreProcessor(value: TargetPreProcessorMode): Promise<Response> {
        return await api.session.update(sessionID, {setup: {targetPreProcessor: value}})
    }

    function showJokerLapSetup(): boolean {
        return sessionMode === "race"
            && (sessionTrackBundle === "rally" || sessionTrackBundle === "rally_cross")
    }

    function showSectorSetup(): boolean {
        return sessionMode !== "gymkhana"
    }

    function showMinLapTimeSetup(): boolean {
        return sessionMode !== "gymkhana"
    }

    function showTrackBundleSetup(): boolean {
        return sessionMode !== "gymkhana"
    }

    function isReadonly(): boolean {
        if (sessionIsFinished) return true;
        return !isRouteOwnerAuthUser && !isGroupAdmin;
    }

    async function syncTrackDataToSessionSetup() {
        if (!sessionTrackID) return
        setSyncingTrackData(true)
        await updateSessionTrack(sessionTrackID)
        setSyncingTrackData(false)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            ref={ref}
            className="session-track-setup">
            <ContentLayout columns={isMobileMode ? 1 : 2}>
                <ContentLayout framed={true}>
                    <ContentLayout
                        columns={showSyncTrackDataButton ? (isMobileMode ? 1 : 2) : 1}
                        justifyContent="space-between"
                        columnTemplate={showSyncTrackDataButton ? "auto min-content" : null}>
                        <Headline
                            text={dict("session.track.selection.title")}
                            style="h5-underlined"/>
                        {showSyncTrackDataButton &&
                            <LabelButton
                                label={dict("session.track.sync.trackdata.label")}
                                style="primary-small"
                                disabled={isReadonly()}
                                progressing={syncingTrackData}
                                onClick={syncTrackDataToSessionSetup}/>}
                    </ContentLayout>
                    <ContentLayout gap="small">
                        <SelectInput
                            label={dict("session.track.selection.label")}
                            defaultValue={selectedTrackValue}
                            readonly={isReadonly()}
                            helpTopic={"session.track.selection"}
                            onChange={updateSessionTrack}
                            options={selectableTrackOptions}/>
                        {selectedTrackData?.image &&
                            <Picture file={selectedTrackData.image}/>}
                    </ContentLayout>
                </ContentLayout>
                {showMinLapTimeSetup() &&
                    <ContentLayout framed={true}>
                        <Headline text={dict("track.minLapTime.headline")} style="h5-underlined"/>
                        <TrackMinLapTimeInput
                            editable={!isReadonly()}
                            lapTime={sessionMinLapTime?.toString()}
                            action={updateSessionMinLapTime}/>
                    </ContentLayout>}
            </ContentLayout>
            {showSectorSetup() &&
                <ContentLayout framed={true}>
                    <Headline text={dict("track.sectors.headline")} style="h5-underlined"/>
                    <TrackSectorsInput
                        editable={!isReadonly()}
                        numSectors={sessionNumSectors}
                        sectorTargets={sessionSectorTargets}
                        lapCountMode={sessionLapCountMode}
                        isMobileMode={isMobileMode}
                        onNumSectorsChanged={updateSessionNumSectors}
                        onSectorTargetsChanged={updateSessionSectorTargets}
                        onLapCountModeChanged={updateSessionLapCountMode}/>
                </ContentLayout>}
            <ContentLayout framed={true}>
                {showTrackBundleSetup() &&
                    <Headline text={dict("session.track.conditionAndBundle")} style="h5-underlined"/>}
                <ContentLayout columns={showTrackBundleSetup() ? (isMobileMode ? 1 : 2) : 1}>
                    <DriftTrackConditionInput
                        readonly={isReadonly()}
                        trackConditions={sessionTrackConditions}
                        onChange={updateSessionTrackConditions}/>
                    {showTrackBundleSetup() &&
                        <DriftTrackBundleInput
                            readonly={isReadonly()}
                            trackBundle={sessionTrackBundle}
                            inlineHelp={!showJokerLapSetup() ? dict("session.track.bundle.inlineHelp") : null}
                            onChange={updateSessionTrackBundle}/>}
                </ContentLayout>
            </ContentLayout>
            {showJokerLapSetup() &&
                <ContentLayout framed={true}>
                    <Headline text={dict("session.track.jokerLap.headline")} style="h5-underlined"/>
                    <ContentLayout columns={isMobileMode ? 1 : 2}>
                        <TrackJokerLapTargetInput
                            enabled={!isReadonly()}
                            jokerLapTarget={sessionJokerLapTarget}
                            onChange={updateSessionJokerLapTarget}/>
                        <Input
                            type="number"
                            pattern={SessionLapsValidation.REGEXP.source}
                            showInvalid={true}
                            required={true}
                            readonly={isReadonly()}
                            helpTopic="session.jokerLap.numJokerLaps"
                            label={dict("session.jokerLap.numJokerLaps")}
                            placeholder="0 .. 100"
                            defaultValue={sessionNumJokerLaps?.toString()}
                            action={updateSessionNumJokerLaps}/>
                    </ContentLayout>
                    <Input
                        type="number"
                        minNumber={SessionPenalityTimeValidation.MIN}
                        maxNumber={SessionPenalityTimeValidation.MAX}
                        stepNumber={SessionPenalityTimeValidation.STEP}
                        label={dict("session.jokerLap.penality")}
                        showInvalid={true}
                        readonly={isReadonly()}
                        defaultValue={sessionJokerLapPenalty?.toString()}
                        pattern={SessionPenalityTimeValidation.REGEXP.source}
                        action={updateSessionJokerLapPenality}/>
                </ContentLayout>}
            {sessionMode != "gymkhana" &&
                <ContentLayout framed={true}>
                    <Headline text={dict("track.targetPreProcessor.headline")} style="h5-underlined"/>
                    <TrackTargetPreProcessorInput
                        readonly={isReadonly()}
                        mode={sessionTargetPreProcessor}
                        onModeChanged={updateSessionTargetPreProcessor}/>
                </ContentLayout>}
        </ContentLayout>
    )
}
