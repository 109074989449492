import * as React from "react";
import {useState} from "react";
import {Modal} from "../../Modal";
import {useServices} from "../../../../hooks/useServices";
import {ContentLayout} from "../../../../ui/layout/content/ContentLayout";
import {Headline} from "../../../../ui/text/headings/Headline";
import {InfoText} from "../../../../ui/text/infotext/InfoText";
import {LabelButton} from "../../../../ui/buttons/label/LabelButton";

/******************************************************************
 * SubscriptionUpgradeInfoModal
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function SubscriptionUpgradeInfoModal() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {state, dict} = useServices()

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [message] = useState<string>(state.showSubscriptionUpgradeInfo.getValue().message)

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function showPlans() {
        closeModal()
        state.showSubscriptionPlans.setValue(true)
    }

    function closeModal() {
        state.showSubscriptionUpgradeInfo.setValue(null)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal
            className="subscription-upgrade-info-modal"
            width="medium"
            closeAction={closeModal}>
            <Headline
                text={dict("subscription.upgrade.info.title")}
                style="modal"/>
            <ContentLayout>
                <InfoText text={message}/>
                <ContentLayout justifyItems={"center"}>
                    <LabelButton
                        label={dict("subscription.upgrade.info.button.showPlans")}
                        style="primary"
                        onClick={showPlans}/>
                </ContentLayout>
            </ContentLayout>
        </Modal>
    );

}
