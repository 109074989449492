import * as React from "react";
import {useEffect, useState} from "react";
import {IStintData} from "../../../../shared/models/IStintData";
import {IStintLapData} from "../../../../shared/types/IStintLapData";
import {SessionDriverStateType} from "../../../../shared/types/SessionDriverStateType";
import {StintUtils} from "../../../../shared/utils/StintUtils";
import {useServices} from "../../hooks/useServices";
import {TimeUtils} from "../../../../shared/utils/TimeUtils";
import {ICapiTargetData} from "../../../../shared/types/ICapiTargetData";
import {StintSpeakerMode} from "../../../utils/types/StintSpeakerMode";

/******************************************************************
 * useStintAnnouncer
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function useStintAnnouncer(props: {
    stint: IStintData
    mode: StintSpeakerMode
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {speaker} = useServices()

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [hasInitialized, setHasInitialized] = useState<boolean>(false)
    const [latestTarget, setLatestTarget] = useState<ICapiTargetData>(getLatestTarget())
    const [initalTarget, setInitalTarget] = useState<ICapiTargetData>()
    const [latestLap, setLatestLap] = useState<IStintLapData>(latestLapData())
    const [lapNumber, setLapNumber] = useState<number>(props.stint.drivenLaps)
    const [stintState, setStintState] = useState<SessionDriverStateType>(props.stint.state)
    const [falseStart, setFalseStart] = useState<boolean>(props.stint.falseStart)

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        setHasInitialized(true)
    }, [])

    useEffect(() => {
        if (!initalTarget) {
            setInitalTarget(getLatestTarget())
        }
        setLatestLap(props.stint.computedLaps?.[props.stint.computedLaps?.length - 1])
        setLatestTarget(getLatestTarget())
        setStintState(props.stint.state)
        setFalseStart(props.stint.falseStart)
    }, [props.stint])

    useEffect(() => {
        if (props.mode !== "targets") return
        if (!initalTarget
            || !latestTarget
            || initalTarget?.crossing_time === latestTarget?.crossing_time
            || props.stint.state === "finished") {
            return
        }
        speaker.stint.annouceTarget(latestTarget)
    }, [latestTarget])

    useEffect(() => {
        if (props.mode !== "laps") return
        if (stintState !== "driving") return
        if (lapNumber !== props.stint.drivenLaps && props.stint.drivenLaps > 0) {
            setLapNumber(props.stint.drivenLaps)
            if (latestLap.isJokerLap) {
                speaker.stint.annouceJokerLap()
            }
            speaker.stint.annouceLapTime(latestLap?.time, isNewBestLap(), StintUtils.isLapValid(latestLap))
        }
    }, [latestLap])

    useEffect(() => {
        if (!hasInitialized) return
        if (!initalTarget) return
        switch (stintState) {
            case "ready":
                speaker.stint.annouceReady()
                break;
            case "invalid":
                speaker.stint.annouceInvalid()
                break;
            case "driving":
                speaker.stint.annouceStarted()
                break;
            case "finished":
                if (props.mode == "targets") {
                    speaker.stint.annouceTarget(latestTarget)
                }
                speaker.stint.annouceFinished()
                if (props.mode == "targets") {
                    speaker.stint.annouceTotalScore(props.stint.score)
                }
                speaker.stint.annouceDrivenTime(props.stint.drivenTime)
                break;
        }
    }, [stintState])

    useEffect(() => {
        if (!hasInitialized || !falseStart) return
        speaker.stint.annouceFalseStart()
    }, [falseStart])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function getLatestTarget(): ICapiTargetData {
        return props.stint.targetsData?.[props.stint.targetsData?.length - 1]
    }

    function latestLapData() {
        if (props.stint.computedLaps?.length > 0) {
            return props.stint.computedLaps[props.stint.computedLaps.length - 1]
        }
        return null
    }

    function isNewBestLap() {
        const fastestLap = TimeUtils.getBestLapTimeLap(props.stint.computedLaps)
        if (!fastestLap || !latestLap || props.stint.drivenLaps <= 1) {
            return false
        }
        return fastestLap.timestamp === latestLap.timestamp
    }

}
