import {ISessionData} from "../models/ISessionData";
import {ICapiEnterData} from "../types/ICapiEnterData";
import {IInvalidEnterProp} from "../types/IInvalidEnterProp";
import {AppVersionUtils} from "./AppVersionUtils";
import {SharedConfig} from "../config/SharedConfig";
import {CapiSyncUtils} from "./CapiSyncUtils";
import {TimeUtils} from "./TimeUtils";
import {DriftTuningType} from "../types/DriftTuningType";

/******************************************************************
 * CapiEnterUtils
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class CapiEnterUtils {

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public static settingsValidator(enterData: ICapiEnterData, session: ISessionData): {
        valid: boolean,
        invalidProps?: IInvalidEnterProp[]
    } {
        const sessionSetup = session.setup
        const invalidProps: IInvalidEnterProp[] = []
        const checkPropsMap = {
            app_version: "app_version",
            game_mode: "mode",
            start_time: "startTime",
            lap_count: "laps",
            track_condition: "trackConditions",
            track_bundle: "trackBundle",
            wheels: "tires",
            setup_mode: "carSetup",
            engine_type: "engines",
            tuning_type: "tunings"
        };
        let areAllSettingsValid = true;
        for (const key in checkPropsMap) {
            let isSettingValid = true
            const enterSetting = enterData[key]
            let sessionSetting = sessionSetup[checkPropsMap[key]]
            switch (key) {
                case "tuning_type":
                    const fixedAllowedTunings = sessionSetting?.map(tuning => tuning.toLowerCase().replace("fai-gt1", "fia-gt1") as DriftTuningType)
                    const fixedEnterTuning = enterSetting?.toLowerCase().replace("fai-gt1", "fia-gt1")
                    isSettingValid = fixedAllowedTunings.some(tuning => {
                        switch (tuning) {
                            case "club":
                                return fixedEnterTuning?.includes("club")
                            case "any":
                                return true
                            default:
                                return this.isStringSettingValid(fixedAllowedTunings, fixedEnterTuning)
                        }
                    })
                    break
                case "app_version": {
                    isSettingValid = AppVersionUtils.isVersionAllowed(enterSetting)
                    break;
                }
                case "game_mode":
                    switch (sessionSetting) {
                        case "lap":
                        case "race":
                            isSettingValid = enterSetting?.toLowerCase() == "race"
                            break;
                        case "gymkhana":
                            isSettingValid = sessionSetting == enterSetting?.toLowerCase()
                            break;
                    }
                    break
                case "start_time":
                    if (!sessionSetting) {
                        isSettingValid = true
                        break
                    }
                    if (TimeUtils.isPast(sessionSetting)) {
                        isSettingValid = true
                        break
                    }
                    isSettingValid = TimeUtils.isSameDate(sessionSetting, enterSetting)
                    break
                case "lap_count":
                    isSettingValid = SharedConfig.VALIDATE_LAPS ? CapiSyncUtils.computeSyncedLaps(session) == enterSetting : true
                    break
                default:
                    isSettingValid = this.isStringSettingValid(sessionSetting, enterSetting)
                    break
            }
            if (!isSettingValid) {
                invalidProps.push({
                    name: key,
                    should: sessionSetting,
                    isValue: enterSetting
                })
                areAllSettingsValid = false
            }
        }
        if (areAllSettingsValid) {
            return {valid: true}
        }
        return {
            valid: areAllSettingsValid,
            invalidProps: invalidProps
        }
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private static isStringSettingValid(sessionSetting: string | string[], enterSetting: string): boolean {
        let hasValidSetting = false
        if (Array.isArray(sessionSetting)) {
            for (const setting of sessionSetting) {
                if (setting === 'any' || setting.toLowerCase() === enterSetting?.toLowerCase()) {
                    hasValidSetting = true;
                    break;
                }
            }
        } else if (sessionSetting == undefined || sessionSetting === 'any' || sessionSetting?.toLowerCase() === enterSetting?.toLowerCase()) {
            hasValidSetting = true;
        }
        return hasValidSetting;
    }

}
