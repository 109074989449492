import * as React from "react";

/******************************************************************
 * ContextMenuDivider
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function ContextMenuDivider(props: {
    style?: "normal" | "small" | "space"
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="context-menu-divider" data-style={props.style ?? "normal"}/>
    );

}
