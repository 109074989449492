import * as React from "react";
import {Headline} from "../../../../ui/text/headings/Headline";
import {AuthUserPlanInfo} from "../../../../ui/info/plan/AuthUserPlanInfo";
import {Modal} from "../../Modal";
import {useServices} from "../../../../hooks/useServices";

/******************************************************************
 * SubscriptionInfoModal
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function SubscriptionInfoModal() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, state} = useServices()

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal
            width={"medium"}
            closeAction={() => state.showSubscriptionInfo.setValue(false)}>
            <Headline
                text={dict("mysubscription.modal.label")}
                style="modal"/>
            <AuthUserPlanInfo
                type={"summary"}
                onRequestClose={() => state.showSubscriptionInfo.setValue(false)}/>
        </Modal>
    );

}
