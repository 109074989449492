import * as React from "react";
import {ISessionLeaderboardEntry} from "../../../../../../shared/types/ISessionLeaderboardEntry";
import {TimeUtils} from "../../../../../../shared/utils/TimeUtils";

/******************************************************************
 * TableLeaderboardMedianLapTimeCell
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function TableLeaderboardMedianLapTimeCell(props: {
    entry: ISessionLeaderboardEntry
}) {

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function averageLapTime() {
        if (props.entry.isStartOrder) {
            return ""
        }
        let medianLapTime = undefined
        switch (props.entry.session.setup.timing) {
            case "sync":
                medianLapTime = props.entry.overall?.medianLapTime
                break
            case "async":
                if (props.entry.state === "finished") {
                    switch (props.entry.session.setup.mode) {
                        case "race":
                            medianLapTime = props.entry.bestDrivenTimeStint?.medianLapTime
                            break
                    }
                } else {
                    medianLapTime = props.entry.latestStint?.medianLapTime
                }
                break
        }
        return TimeUtils.formatDrivenTime(medianLapTime);
    }

    function size() {
        switch (props.entry.type) {
            case "primary":
                return "normal"
            case "secondary-team":
                return "small"

        }
    }

    function theme(): "dark" | "light" {
        switch (props.entry.state) {
            case "ready":
            case "driving":
                return "light"
            case "invalid":
            case "finished":
                return "dark"
        }
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="table-leaderboard-medianlaptime-cell"
            data-all-rejected={props.entry.hasOnlyRejectedStints && props.entry.session.setup.useStintApproval}
            data-size={size()}
            data-theme={theme()}>
            {averageLapTime()}
        </div>
    );

}
