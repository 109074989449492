import * as React from "react";
import {useEffect, useState} from "react";
import {Infobox} from "../Infobox";
import {FrontendRoute} from "../../../../../shared/routes/FrontendRoute";
import {ComponentInitializer} from "../../utils/init/ComponentInitializer";
import {IGroupData} from "../../../../../shared/models/IGroupData";
import {Table} from "../../table/Table";
import {useServices} from "../../../hooks/useServices";
import {useRouteOwner} from "../../../hooks/useRouteOwner";
import {ITableColumnDefinition} from "../../table/ITableColumnDefinition";
import {ContentLayout} from "../../layout/content/ContentLayout";
import {FavoriteButton} from "../../buttons/favorite/FavoriteButton";
import {useAuthUser} from "../../../hooks/useAuthUser";

/******************************************************************
 * UserGroupsInfobox
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function UserGroupsInfobox() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, state, api, router} = useServices()
    const {routeOwnerID, isRouteOwnerAuthUser, routeOwnerNick, routeOwnerPath} = useRouteOwner()
    const {authUserID, authUserFavorites} = useAuthUser()

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [loading, setLoading] = useState<boolean>(true)
    const [userGroups, setUserGroups] = useState<IGroupData[]>()

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        setLoading(true)
        loadUserGroups()
    }, [routeOwnerID, authUserFavorites])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function loadUserGroups() {
        const allUserGroups = await api.group.getUserGroups(routeOwnerID)
        const favoriteGroups: IGroupData[] = []
        const otherGroups: IGroupData[] = []
        allUserGroups?.forEach(group => {
            const isFavorite = authUserFavorites
                .find(fav => (fav.group?._id ?? fav.group) == group?._id)
            if (isFavorite) {
                favoriteGroups.push(group)
            } else {
                otherGroups.push(group)
            }
        })
        if (!allUserGroups || allUserGroups.length == 0) {
            setUserGroups(null)
            setLoading(false)
            return
        }
        setUserGroups([...favoriteGroups, ...otherGroups])
        setLoading(false)
    }

    function title(): string {
        if (!userGroups || userGroups?.length == 0) {
            return dict("infobox.userGroups.empty.title")
        }
        if (userGroups?.length == 1) {
            return dict("infobox.userGroups.single.title")
        }
        return dict("infobox.userGroups.title")
            .replaceAll("{COUNT}", userGroups?.length.toString() ?? "0")
    }

    function columnsDefinition(): ITableColumnDefinition<IGroupData>[] {
        return [{
            type: "image-circle",
            dataKey: "avatar",
            size: "50px"
        }, {
            dataKey: "name",
            type: "text-bold",
        }, {
            type: "privacy",
            dataKey: "privacy",
            size: "45px"
        }, {
            type: "custom",
            size: authUserID ? "45px" : null,
            customCell: (key, data) => {
                return <ContentLayout key={key} justifyItems="center">
                    <FavoriteButton favoriteID={data._id} type={"group"}/>
                </ContentLayout>
            }
        }]
    }

    function gotoGroup(data: IGroupData) {
        router.showRoute(FrontendRoute.GROUP(data.path))
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Infobox
            title={title()}
            loading={loading}
            hasData={userGroups?.length > 0}
            noDataInfo={dict(isRouteOwnerAuthUser
                ? "infobox.userGroups.empty.info.owner"
                : "infobox.userGroups.empty.info.guest").replaceAll("{NICK}", routeOwnerNick)}
            createButtonLabel={dict("infobox.userGroups.create.button.label")}
            createButtonOnClick={() => state.showCreateGroup.setValue(true)}
            linkButtonLabel={dict("infobox.userGroups.showAll.button.label").replaceAll("{NICK}", routeOwnerNick)}
            linkButtonOnClick={() => router.showRoute(FrontendRoute.USER_GROUPS(routeOwnerPath))}
            helpButtonLabel={dict("infobox.userGroups.academy.button.label")}
            helpButtonOnClick={() => state.showAcademy.setValue(dict("academy.id.groups"))}>
            <ComponentInitializer isPropertyAvailable={!loading}>
                <Table<IGroupData>
                    rowsData={userGroups?.slice(0, 6)}
                    showHeader={false}
                    noDataInfo={dict(isRouteOwnerAuthUser
                        ? "user.groups.member.noDataInfo"
                        : "user.groups.member.noDataInfo.guest").replaceAll("{NICK}", routeOwnerNick)}
                    columnsDefinition={columnsDefinition()}
                    onRowClick={gotoGroup}/>
            </ComponentInitializer>
        </Infobox>
    );

}
