import {IStintData} from "../models/IStintData";
import {TimeUtils} from "./TimeUtils";
import {ISessionData} from "../models/ISessionData";

/******************************************************************
 * RaceDirectorUtils
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class RaceDirectorUtils {

    public static hasData(stint: IStintData, session: ISessionData): boolean {
        return stint
            && (RaceDirectorUtils.hasTimeOffset(stint, session)
                || RaceDirectorUtils.hasLapsOffset(stint, session))
    }

    public static hasLapsOffset(stint: IStintData, session: ISessionData): boolean {
        if (!stint || !stint.raceDirector) return false
        if (session.setup.mode != "race") return false
        return stint.raceDirector.lapsOffset && stint.raceDirector.lapsOffset != 0
    }

    public static hasTimeOffset(stint: IStintData, session: ISessionData): boolean {
        if (!stint || !stint.raceDirector) return false
        if (session.setup.mode != "race") return false
        return stint.raceDirector.timeOffset && stint.raceDirector.timeOffset != 0
    }

    public static drivenLaps(stint: IStintData, session: ISessionData, summarized: boolean = false): string {
        if (RaceDirectorUtils.hasLapsOffset(stint, session)) {
            if (summarized) {
                return ((stint.computedLaps?.length ?? 0) + stint.raceDirector.lapsOffset).toString()
            }
            return (stint.computedLaps?.length ?? 0)
                + (stint.raceDirector.lapsOffset < 0 ? " - " : " + ")
                + Math.abs(stint.raceDirector.lapsOffset)
        }
        return stint.computedLaps?.length?.toString() ?? "-"
    }

    public static drivenTime(stint: IStintData, session: ISessionData, summarized: boolean = false): string {
        if (!stint || !stint.drivenTime) return "–"
        if (RaceDirectorUtils.hasTimeOffset(stint, session)) {
            if (summarized) {
                return TimeUtils.formatDrivenTime(stint.drivenTime + stint.raceDirector.timeOffset)
            }
            const isLessThenAMinute = Math.abs(stint.raceDirector.timeOffset) < 60
            return TimeUtils.formatDrivenTime(stint.drivenTime)
                + (stint.raceDirector.timeOffset < 0 ? " - " : " + ")
                + TimeUtils.formatDrivenTime(Math.abs(stint.raceDirector.timeOffset), 0) + (isLessThenAMinute ? "s" : "")
        }
        return TimeUtils.formatDrivenTime(stint.drivenTime) ?? "–"
    }
}
