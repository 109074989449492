import * as React from "react";
import {ITabbedContentChildProps} from "../../../../ui/layout/tabbed/ITabbedContentChildProps";
import {IEventData} from "../../../../../../shared/models/IEventData";
import {EventTeaser} from "../../../../ui/teaser/event/EventTeaser";

/******************************************************************
 * EventFinderTeaserList
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function EventFinderTeaserList(props: {
    tabbedContent?: ITabbedContentChildProps
    isLoading: boolean
    areLiveEvents: boolean
    events: IEventData[]
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="event-finder-teaser-list">
            <>{props.events?.map(event => (
                <EventTeaser
                    key={event._id}
                    size="small"
                    clickToGoToEvent={true}
                    event={event}/>
            ))}</>
        </div>
    );

}
