import * as React from "react";

/******************************************************************
 * CopyIcon
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function CopyIcon(props: {
    scale?: number
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="copy-icon">
            <svg width={16 * (props.scale ?? 1)} height={17 * (props.scale ?? 1)} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.4286 3.75635H5.42858C4.74659 3.75635 4.09254 4.02727 3.6103 4.5095C3.12807 4.99174 2.85715 5.64579 2.85715 6.32778V14.3278C2.85715 15.0098 3.12807 15.6638 3.6103 16.1461C4.09254 16.6283 4.74659 16.8992 5.42858 16.8992H13.4286C14.1106 16.8992 14.7646 16.6283 15.2469 16.1461C15.7291 15.6638 16 15.0098 16 14.3278V6.32778C16 5.64579 15.7291 4.99174 15.2469 4.5095C14.7646 4.02727 14.1106 3.75635 13.4286 3.75635ZM12.2696 10.8992H10V13.1688C10 13.4763 9.76358 13.7403 9.45608 13.7556C9.37881 13.7594 9.3016 13.7474 9.22911 13.7204C9.15663 13.6934 9.09038 13.6519 9.03438 13.5986C8.97838 13.5452 8.93379 13.481 8.90333 13.4099C8.87287 13.3388 8.85715 13.2623 8.85715 13.1849V10.8992H6.5875C6.28 10.8992 6.01608 10.6628 6.00072 10.3553C5.997 10.278 6.009 10.2008 6.036 10.1283C6.063 10.0558 6.10444 9.98958 6.1578 9.93358C6.21116 9.87758 6.27533 9.833 6.34644 9.80253C6.41754 9.77207 6.49408 9.75635 6.57143 9.75635H8.85715V7.4867C8.85715 7.1792 9.09358 6.91528 9.40108 6.89992C9.47834 6.8962 9.55555 6.9082 9.62804 6.9352C9.70053 6.9622 9.76678 7.00364 9.82278 7.057C9.87878 7.11036 9.92336 7.17454 9.95382 7.24564C9.98429 7.31674 10 7.39328 10 7.47063V9.75635H12.2857C12.3631 9.75626 12.4398 9.7719 12.5109 9.80232C12.5821 9.83274 12.6464 9.87731 12.6998 9.93332C12.7533 9.98933 12.7948 10.0556 12.8218 10.1281C12.8488 10.2007 12.8609 10.278 12.8571 10.3553C12.8421 10.6628 12.5771 10.8992 12.2696 10.8992Z" fill="white"/>
                <path d="M12.9957 2.6137C12.8179 2.11272 12.4895 1.67901 12.0554 1.37209C11.6214 1.06516 11.103 0.900056 10.5714 0.899414H2.57143C1.88944 0.899414 1.23539 1.17033 0.753154 1.65257C0.270918 2.1348 0 2.78886 0 3.47084V11.4708C0.000641788 12.0024 0.16575 12.5208 0.472674 12.9549C0.779597 13.3889 1.2133 13.7173 1.71429 13.8951V5.47084C1.71429 4.71308 2.01531 3.98636 2.55112 3.45054C3.08694 2.91472 3.81367 2.6137 4.57143 2.6137H12.9957Z" fill="white"/>
            </svg>
        </div>
    );

}
