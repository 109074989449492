import * as React from "react";
import {IArticleYoutubeBlockData} from "../../../../../../shared/models/IArticleData";
import {ContentLayout} from "../../../layout/content/ContentLayout";
import {Input} from "../../../form/elements/input/Input";
import {YouTubeURLValidation} from "../../../../../../shared/validation/YouTubeURLValidation";
import {useServices} from "../../../../hooks/useServices";
import {YouTubeUtils} from "../../../../../utils/YouTubeUtils";
import {YoutubeThumbnailLink} from "../../../image/youtube/YoutubeThumbnailLink";
import {useMobileStyle} from "../../../../hooks/useMobileStyle";
import {useRef} from "react";

/******************************************************************
 * ArticleYouTubeBlock
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function ArticleYouTubeBlock(props: {
    youtube: IArticleYoutubeBlockData
    editing: boolean
    onChange: (data: IArticleYoutubeBlockData) => void
}) {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const rootRef = useRef<HTMLDivElement>(null)


    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {dict} = useServices()
    const [isMobileStyle] = useMobileStyle(rootRef, 600)

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    async function updateYoutubeID(youtubeURL: string): Promise<Response> {
        props.youtube.videoID = YouTubeUtils.getVideoIDFromURL(youtubeURL)
        props.onChange(props.youtube)
        return null
    }

    function getYouTubeURL(): string {
        return YouTubeUtils.getVideoURLFromID(props.youtube.videoID)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            className="article-youtube-block"
            ref={rootRef}
            framed={true}
            gap="small">
            {props.editing
                ? <ContentLayout
                    columnTemplate={(isMobileStyle || !props.youtube.videoID) ? null : "1fr 2fr"}
                    gap="small">
                    {props.youtube.videoID &&
                        <YoutubeThumbnailLink videoID={props.youtube.videoID}/>}
                    <Input
                        type="text"
                        showInvalid={true}
                        icon="youtube"
                        size="small"
                        helpTopic="stint.videoproof"
                        defaultValue={getYouTubeURL()}
                        pattern={YouTubeURLValidation.REGEXP.source}
                        placeholder={dict("article.block.youtube.placeholder")}
                        label={dict("article.block.youtube.title")}
                        action={updateYoutubeID}/>
                </ContentLayout>
                : props.youtube.videoID &&
                <YoutubeThumbnailLink videoID={props.youtube.videoID}/>
            }
        </ContentLayout>
    )
}
