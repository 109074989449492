import {useEffect, useState} from "react";
import {useServices} from "./useServices";
import {SocketConnectionType} from "../../utils/types/SocketConnectionType";

/******************************************************************
 * useSocketState
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function useSocketState() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {state} = useServices();

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [socketConnectionState, setSocketConnectionState] = useState<SocketConnectionType>(state.socket.getValue());
    const [isSocketConnected, setIsSocketConnected] = useState<boolean>(false);
    const [isSocketDisconnected, setIsSocketDisconnected] = useState<boolean>(false);
    const [isSocketConnecting, setIsSocketConnecting] = useState<boolean>(false);

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        updateSocketState();
        state.socket.onChangeSignal.add(updateSocketState)
        return () => state.socket.onChangeSignal.remove(updateSocketState)
    }, [])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function updateSocketState() {
        setSocketConnectionState(state.socket.getValue())
        setIsSocketConnected(state.socket.getValue() === "connected")
        setIsSocketDisconnected(state.socket.getValue() === "disconnected")
        setIsSocketConnecting(state.socket.getValue() === "connecting")
    }

    /* ----------------------------------------------------------------
     * RETURN
     * --------------------------------------------------------------*/

    return {
        socketConnectionState,
        isSocketConnected,
        isSocketDisconnected,
        isSocketConnecting
    }

}
