import * as React from "react";
import ReactMarkdown from "react-markdown";

/******************************************************************
 * PlanTitle
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function PlanHeadline(props: {
    title: string
    size?: "tiny" | "small" | "large" | "medium"
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <h3
            className="plan-headline"
            data-size={props.size ?? "large"}>
            <ReactMarkdown>
                {props.title}
            </ReactMarkdown>
        </h3>
    );

}
