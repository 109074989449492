import * as React from "react";
import {MutableRefObject, ReactElement, useEffect, useRef, useState} from "react";
import {DOMUtils} from "@webfruits/toolbox/dist/utils/DOMUtils";
import {useWindowState} from "../../hooks/useWindowState";

/******************************************************************
 * Draggable
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function DragDropable(props: {
    children: ReactElement | ReactElement[] | null
    disable?: boolean
    dragElementRef: MutableRefObject<HTMLDivElement>
    dropElementRef: MutableRefObject<HTMLDivElement>
    onDrop: () => void
    onDragStart?: () => void
    onDragEnd?: () => void
}) {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {hasFocusOnInputElement} = useWindowState()

    /* ----------------------------------------------------------------
     * REFS
     * --------------------------------------------------------------*/

    const thisElementRef = useRef<HTMLDivElement>();

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [dragMode, setDragMode] = useState<"dragging" | "drop-above" | "drop-below">()
    const [isDragable, setIsDragable] = useState<boolean>(!props.disable)

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        setIsDragable(!props.disable && !hasFocusOnInputElement)
    }, [hasFocusOnInputElement])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function allowDropping(e) {
        e.preventDefault()
        e.stopPropagation()
    }

    function startDragging(e: React.DragEvent) {
        e.dataTransfer.effectAllowed = "move";
        e.dataTransfer.dropEffect = "move";
        props.dragElementRef.current = thisElementRef.current;
        setDragMode("dragging");
        if (props.onDragStart) props.onDragStart()
    }

    function endDragging() {
        if (props.onDragEnd) props.onDragEnd()
        setDragMode(null)
    }

    function enterDropElement() {
        if (dragMode == "dragging") return;
        props.dropElementRef.current = thisElementRef.current;
        const dragElementIndex = DOMUtils.getElementIndex(props.dragElementRef.current)
        const dropElementIndex = DOMUtils.getElementIndex(props.dropElementRef.current)
        const indexDelta = dragElementIndex - dropElementIndex;
        if (indexDelta > 0) {
            setDragMode(("drop-above"));
        }
        if (indexDelta < 0) {
            setDragMode(("drop-below"));
        }
    }

    function leaveDropElement() {
        if (dragMode == "dragging") return;
        if (props.dropElementRef.current === thisElementRef.current) {
            props.dropElementRef.current = null;
        }
        if (props.dragElementRef.current !== thisElementRef.current) {
            setDragMode(null);
        }
    }

    function drop() {
        setDragMode(null);
        if (props.onDragEnd) props.onDragEnd()
        props.onDrop();
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="drag-dropable"
            ref={thisElementRef}
            draggable={isDragable}
            data-drag-mode={dragMode}
            onDragStart={startDragging}
            onDragEnter={enterDropElement}
            onDragLeave={leaveDropElement}
            onDragEnd={endDragging}
            onDragOver={allowDropping}
            onDrop={drop}>
            {props.children}
        </div>
    );

}
