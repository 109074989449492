import * as React from "react";

/******************************************************************
 * UploadIcon
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function UploadIcon(props: {
    scale?: number
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="upload-icon">
            <svg width={20 * (props.scale ?? 1)} height={21 * (props.scale ?? 1)} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.70538 10.1463C6.09465 10.5356 6.72568 10.5359 7.11538 10.1471L9.29366 7.97571C9.68401 7.58621 10.316 7.58621 10.7063 7.97571L12.8846 10.1471C13.2743 10.5359 13.9053 10.5356 14.2946 10.1463C14.6842 9.75672 14.6842 9.1251 14.2946 8.73553L10.7071 5.14802C10.3166 4.75749 9.68341 4.75749 9.29289 5.14802L5.70538 8.73553C5.31581 9.1251 5.31581 9.75672 5.70538 10.1463Z" fill="white"/>
                <rect x="3.5" y="17.002" width="13" height="1.98389" rx="0.991943" fill="white"/>
                <rect x="9.00806" y="14.9883" width="8.15479" height="1.98389" rx="0.991943" transform="rotate(-90 9.00806 14.9883)" fill="white"/>
            </svg>
        </div>
    );

}
