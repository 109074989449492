import * as React from "react";
import {ISessionLeaderboardEntry} from "../../../../../../shared/types/ISessionLeaderboardEntry";
import {CharUtils} from "../../../../../../shared/utils/CharUtils";

/******************************************************************
 * TableLeaderboardTargetHitsCell
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function TableLeaderboardTargetHitsCell(props: {
    entry: ISessionLeaderboardEntry
}) {

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function gymkhanaTargetHits(): number {
        if (props.entry.session.setup.mode !== "gymkhana") {
            return undefined
        }
        if (props.entry.state === "finished") {
            return props.entry.bestGymkhanaStint?.targetHits ?? undefined
        }
        return props.entry.latestStint.targetHits ?? undefined;
    }

    function theme(): "dark" | "light" {
        switch (props.entry.state) {
            case "ready":
            case "driving":
                return "light"
            case "invalid":
            case "finished":
                return "dark"
        }
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="table-leaderboard-targethits-cell"
            data-all-rejected={props.entry.hasOnlyRejectedStints && props.entry.session.setup.useStintApproval}
            data-theme={theme()}>
            {CharUtils.replaceZeroWithO(gymkhanaTargetHits()) ?? "–"}
        </div>
    );

}
