import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {UploadableImage} from "../uploadable/UploadableImage";
import {useServices} from "../../../hooks/useServices";
import {useRouteOwner} from "../../../hooks/useRouteOwner";
import {useTrackState} from "../../../hooks/useTrackState";
import {SharedConfig} from "../../../../../shared/config/SharedConfig";
import {useGroupState} from "../../../hooks/useGroupState";
import {useElementSize} from "../../../hooks/useElementSize";

/******************************************************************
 * TrackSidebarImage
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function TrackSidebarImage() {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const imageRef = useRef<HTMLImageElement>()

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {api, dict} = useServices();
    const {isRouteOwnerAuthUser} = useRouteOwner();
    const {isGroupAdmin} = useGroupState()
    const {trackID, trackImage} = useTrackState();
    const [imageElementWidth]= useElementSize(imageRef)

    /* ----------------------------------------------------------------
    * STATES
    * --------------------------------------------------------------*/

    const [imageSrc, setImageSrc] = useState<string>();

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        updateImageSource()
    }, [trackImage, imageElementWidth])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    async function updateImageSource() {
        if (trackImage) {
            setImageSrc(api.file.getImageURLByFile(trackImage, imageElementWidth))
            return;
        }
    }

    async function upload(formData: FormData): Promise<Response> {
        formData.append("trackID", trackID);
        return await api.track.uploadImage(formData);
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="track-sidebar-image"
            style={{
                aspectRatio: trackImage?.metaData?.aspectRatio ?? SharedConfig.IMAGE_TRACK_ASPECT_RATIO,
                position: "relative"
            }}>
            <UploadableImage
                ref={imageRef}
                id="trackfile"
                uploadMethode={upload}
                showUploadButton={!trackImage}
                editable={isRouteOwnerAuthUser || isGroupAdmin}
                imageSrc={imageSrc}
                mediaViewerFile={trackImage}
                fit="contain"
                buttonLabel={dict("form.upload.track.select")}/>
        </div>
    );

}
