import * as React from "react";
import {Modal} from "../Modal";
import {Headline} from "../../../ui/text/headings/Headline";
import {VerifyForm} from "../../../ui/form/forms/VerifyForm";
import {useServices} from "../../../hooks/useServices";

/******************************************************************
 * VerifyAccountModal
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function VerifyAccountModal() {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {state, api, dict} = useServices();

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function close() {
        state.showVerifyAccount.setValue(false);
        await api.user.logout();
    }

    async function updateUserData() {
        await api.user.loadAuthUserData();
        state.showVerifyAccount.setValue(false);
    }

    async function verifyAccount(code: string): Promise<Response> {
        return await api.user.verifyAccount(code)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal width="small" closeAction={close}>
            <Headline text={dict("verify.account.title")} style="modal"/>
            <VerifyForm
                info={dict("form.verify.info")}
                actionLabel={dict("form.verify.button.verifyAccount")}
                action={verifyAccount}
                onVerified={updateUserData}/>
        </Modal>
    );

}
