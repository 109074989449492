import * as React from "react";
import {useEventState} from "../../../../hooks/useEventState";
import {ComponentInitializer} from "../../../../ui/utils/init/ComponentInitializer";
import {Headline} from "../../../../ui/text/headings/Headline";
import {useResultState} from "../../../../hooks/useResultState";
import {useServices} from "../../../../hooks/useServices";
import {useRef} from "react";
import {useMobileStyle} from "../../../../hooks/useMobileStyle";

/******************************************************************
 * ResultHeader
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function ResultHeader() {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const ref = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict} = useServices()
    const {eventID} = useEventState()
    const {resultName, resultSessions} = useResultState()
    const [isMobileMode] = useMobileStyle(ref, 700)

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function sessionResultCountText() {
        if (numFinishedSessions() == numTotalSessions()) {
            return ""
        }
        return dict("result.header.counter")
            .replace("{FINISHED}", numFinishedSessions().toString())
            .replace("{TOTAL}", numTotalSessions().toString())
    }

    function sessionResultInfoText() {
        return dict("result.header.info")
            .replace("{TOTAL}", numTotalSessions().toString())
    }

    function numFinishedSessions() {
        return resultSessions?.filter(session => session.state == "finished")?.length ?? 0
    }

    function numTotalSessions() {
        return resultSessions?.length ?? 0
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ComponentInitializer
            isPropertyAvailable={eventID}
            className="result-header">
            <main ref={ref} data-is-mobile-mode={isMobileMode}>
                <div className="result-header-title">
                    <Headline
                        text={resultName}
                        style="h1"/>
                    <Headline
                        text={sessionResultInfoText()}
                        style="h5"/>
                </div>
                <div className="result-header-info">
                    <Headline
                        text={sessionResultCountText()}
                        style="h1-mono"/>
                    {numFinishedSessions() != numTotalSessions() && <Headline
                        text={dict("result.header.counter.info")}
                        style="h5"/>}
                </div>
            </main>
        </ComponentInitializer>
    );

}
