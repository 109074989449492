import * as React from "react";
import {ReactElement, useEffect, useState} from "react";
import {Headline} from "../../../ui/text/headings/Headline";
import {ContentLayout} from "../../../ui/layout/content/ContentLayout";
import {Modal} from "../Modal";
import {useServices} from "../../../hooks/useServices";
import {MongoObjectIDType} from "../../../../../shared/types/MongoObjectIDType";
import {InfoText} from "../../../ui/text/infotext/InfoText";
import {IArticleData} from "../../../../../shared/models/IArticleData";
import {LabelValueText} from "../../../ui/text/labelvalue/LabelValueText";
import {LabelButton} from "../../../ui/buttons/label/LabelButton";
import {ITableColumnDefinition} from "../../../ui/table/ITableColumnDefinition";
import {Table} from "../../../ui/table/Table";

/******************************************************************
 * CopyArticleContentModal
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function CopyArticleContentModal(props: {
    onCloseRequested?: () => void
    onSuccessfulCopy?: () => void
    targetArticleID: MongoObjectIDType
    sourceArticleIDs?: MongoObjectIDType[]
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, api} = useServices()

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [sourceArticles, setSourceArticles] = useState<IArticleData[]>([])
    const [targetArticle, setTargetArticle] = useState<IArticleData>(null)
    const [isProcessing, setIsProcessing] = useState<boolean>(false)
    const [selectedArticle, setSelectedArticle] = useState<IArticleData>()

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        loadArticles()
    }, [props.sourceArticleIDs, props.targetArticleID])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function loadArticles() {
        const sourceArticles = await api.article.getArticles(props.sourceArticleIDs)
        const targetArticle = await api.article.getArticleByID(props.targetArticleID)
        setSourceArticles(sourceArticles)
        setTargetArticle(targetArticle)
    }

    async function copyContent(sourceArticleID: MongoObjectIDType) {
        setIsProcessing(true)
        const success = await api.article.copyContent(sourceArticleID, props.targetArticleID)
        if (success) {
            props.onSuccessfulCopy?.()
            setSelectedArticle(null)
        }
        setIsProcessing(false)
    }

    function ArticleInfo(article: IArticleData): ReactElement {
        return (
            <ContentLayout framed={true} key={article?._id}>
                {article?.title &&
                    <LabelValueText
                        label={dict("article.title.label")}
                        size="small"
                        value={article.title}/>}
                {article?.subTitle &&
                    <LabelValueText
                        label={dict("article.subTitle.label")}
                        size="small"
                        value={article.subTitle}/>}
                {article?.abstract &&
                    <LabelValueText
                        label={dict("article.abstract.label")}
                        size="small"
                        value={article.abstract}/>}
                {article?.blocks?.length > 0 &&
                    <LabelValueText
                        label={dict("article.blocks.label")}
                        size="small"
                        value={article.blocks.length.toString()}/>}
                {article?.language &&
                    <LabelValueText
                        label={dict("article.language.label")}
                        size="small"
                        value={dict("language." + article.language)}/>}
            </ContentLayout>
        )
    }

    function columnsDefinition(): ITableColumnDefinition<IArticleData>[] {
        return [{
            dataKey: "language",
            type: "text-bold",
            size: "50px"
        }, {
            columnName: dict("article.select.column.title"),
            dataKey: "title",
            type: "text-bold"
        }, {
            columnName: dict("article.select.column.subTitle"),
            dataKey: "subTitle",
            type: "text"
        }, {
            columnName: dict("article.select.column.abstract"),
            dataKey: "abstract",
            type: "text"
        }]
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal
            width="large"
            className={"copy-article-content-modal"}
            closeAction={props.onCloseRequested}>
            <Headline
                text={dict("article.copy.content.dialog.title")}
                style="modal"/>
            <ContentLayout>
                <InfoText
                    text={dict("article.copy.content.dialog.info")}
                    size="small"/>
                <ContentLayout framed={true}>
                    <Headline
                        text={dict("article.copy.content.dialog.target")}
                        style={"h5-underlined"}/>
                    {ArticleInfo(targetArticle)}
                </ContentLayout>
                <ContentLayout framed={true}>
                    <Headline
                        text={dict("article.copy.content.dialog.sources")}
                        style={"h5-underlined"}/>
                    <Table<IArticleData>
                        sortKey="title"
                        showFilter={true}
                        rowsData={sourceArticles}
                        visibleRows={6}
                        onRowClick={article => setSelectedArticle(article)}
                        columnsDefinition={columnsDefinition()}/>
                </ContentLayout>
            </ContentLayout>
            {selectedArticle &&
                <Modal
                    width={"small"}
                    closeAction={() => setSelectedArticle(null)}>
                    <Headline
                        text={dict("article.copy.content.dialog.confirm.title")}
                        style="modal"/>
                    <ContentLayout>
                        <InfoText
                            text={dict("article.copy.content.dialog.confirm.info")}
                            size="small"/>
                        <LabelButton
                            label={dict("article.copy.content.dialog.confirm.button.label")}
                            style="primary"
                            progressing={isProcessing}
                            onClick={() => copyContent(selectedArticle._id)}/>
                    </ContentLayout>
                </Modal>}
        </Modal>
    );

}
