import {ICapiRoute} from "../types/ICapiRoute";
import {CapiOwnerType} from "../types/CapiOwnerType";
import {CapiRoute} from "../routes/CapiRoute";
import {CapiEventType} from "../types/CapiEventType";

/******************************************************************
 * CapiRouteUtils
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class CapiRouteUtils {

    /* ----------------------------------------------------------------
 	 * PUBLIC METHODES
 	 * --------------------------------------------------------------*/

    static parse(path: string): ICapiRoute {
        if (!path.startsWith("/")) {
            path = "/" + path;
        }
        const split = path.split("/")
        const ownerType = CapiRouteUtils.getOwnerType(split[1])
        return {
            eventPath: split[3] ? "/" + split[3] : null,
            sessionPath: split[4] ? "/" + split[4] : null,
            ownerPath: split[2] ? "/" + split[2] : null,
            eventType: split[5] as CapiEventType,
            ownerType: ownerType
        }
    }

    /* ----------------------------------------------------------------
 	 * PRIVATE METHODES
 	 * --------------------------------------------------------------*/

    private static getOwnerType(ownerPath: string): CapiOwnerType {
        switch (ownerPath) {
            case CapiRoute.USER.replace("/", ""):
                return "user"
            case CapiRoute.GROUP.replace("/", ""):
                return "group"
        }
        return null
    }
}
