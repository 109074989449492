import {FrontendServices} from "../core/FrontendServices";
import {APIRoute} from "../../shared/routes/APIRoute";
import {MongoObjectIDType} from "../../shared/types/MongoObjectIDType";
import {IStintData} from "../../shared/models/IStintData";

/******************************************************************
 * StintAPI
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class StintAPI {

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(private _frontend: FrontendServices) {
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public async updateStint(stintID: MongoObjectIDType, data: IStintData): Promise<Response> {
        return await this._frontend.api.request("PATCH", APIRoute.STINT, {stintID: stintID, ...data}, true)
    }

    public async deleteStint(stintID: MongoObjectIDType): Promise<Response> {
        return await this._frontend.api.request("DELETE", APIRoute.STINT, {stintID}, true)
    }

    public async getStint(stintID: MongoObjectIDType): Promise<IStintData> {
        const response = await this._frontend.api.request("GET", APIRoute.STINT, {stintID});
        if (response.status == 200) {
            return await response.json()
        }
        return null
    }

    public async getStints(stintIDs: MongoObjectIDType[]): Promise<IStintData[]> {
        if (!stintIDs || stintIDs.length == 0) {
            return null
        }
        const response = await this._frontend.api.request("POST", APIRoute.STINTS, {stintIDs}, true);
        const result = await response.json()
        if (response.status !== 200) {
            this._frontend.state.showMessage.setValue({
                type: "error",
                message: this._frontend.dict.get(result.error.code)
            })
            return null
        }
        return result
    }

    public async getStintsBySessionAndUsers(sessionID: MongoObjectIDType, userIDs: MongoObjectIDType[]): Promise<IStintData[]> {
        if (!sessionID || !userIDs) {
            return null
        }
        const response = await this._frontend.api.request("GET", APIRoute.STINTS_BY_SESSION_AND_USERS, {sessionID, userIDs}, true);
        const result = await response.json()
        if (response.status !== 200) {
            this._frontend.state.showMessage.setValue({
                type: "error",
                message: this._frontend.dict.get(result.error.code)
            })
            return null
        }
        return result
    }

}
