import * as React from "react";
import {useRef, useState} from "react";
import {ContentLayout} from "../../../../ui/layout/content/ContentLayout";
import {InfoText} from "../../../../ui/text/infotext/InfoText";
import {useEventState} from "../../../../hooks/useEventState";
import {useServices} from "../../../../hooks/useServices";
import {EventDriversInfo} from "./drivers/EventDriversInfo";
import {ComponentInitializer} from "../../../../ui/utils/init/ComponentInitializer";
import {Headline} from "../../../../ui/text/headings/Headline";
import {useMobileStyle} from "../../../../hooks/useMobileStyle";
import {EventTeamsInfo} from "./teams/EventTeamsInfo";
import {EventTracksInfo} from "./tracks/EventTracksInfo";
import {EventLatestSessionsInfo} from "./sessions/EventLatestSessionsInfo";
import {LabelButton} from "../../../../ui/buttons/label/LabelButton";
import {useDeviceInfo} from "../../../../hooks/useDeviceInfo";
import {useGroupState} from "../../../../hooks/useGroupState";
import {useAuthUser} from "../../../../hooks/useAuthUser";

/******************************************************************
 * EventInfo
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function EventInfo() {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const ref = useRef<HTMLDivElement>(null);

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {dict, state} = useServices();
    const {
        eventData,
        eventTeams,
        eventState,
        eventTracks,
        eventOwnerType,
        eventChildren,
        eventIsFinished,
        eventLocation
    } = useEventState();
    const {groupType} = useGroupState()
    const {authUserExists} = useAuthUser()
    const [isMobileMode, isMobileModeAvailable] = useMobileStyle(ref, 600)
    const {isMobile} = useDeviceInfo()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [isTraining] = useState<boolean>(eventOwnerType == "user")

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ComponentInitializer isPropertyAvailable={!!eventData && isMobileModeAvailable}>
            <ContentLayout
                ref={ref}
                className="event-info-page"
                columns={isMobileMode ? 1 : 2}>
                <ContentLayout>
                    {authUserExists && eventLocation && !eventIsFinished && groupType == "local" &&
                        <ContentLayout framed={true}>
                            <Headline text={dict("event.location.info.label")} style="h5-underlined"/>
                            <InfoText text={eventLocation}/>
                        </ContentLayout>}
                    {eventState == "live" &&
                        <ContentLayout framed={true}>
                            <ContentLayout
                                columnTemplate={isMobile ? "auto min-content" : null}
                                justifyContent="space-between">
                                <Headline text={dict("event.sessions.latest.label")} style="h5-underlined"/>
                                {isMobile && <>
                                    {eventChildren?.sessions?.length > 0
                                        ? <LabelButton
                                            label={dict("event.sessions.showMenu")}
                                            style="primary-small"
                                            onClick={() => state.showMobileMenu.setValue(true)}/>
                                        : <LabelButton
                                            label={dict("event.add.session.label")}
                                            style="primary-small"
                                            onClick={() => state.showCreateSession.setValue(true)}/>}
                                </>}
                            </ContentLayout>
                            <EventLatestSessionsInfo/>
                        </ContentLayout>}
                    {eventTracks?.length > 0 &&
                        <ContentLayout framed={true}>
                            <Headline text={dict("event.tracks.label")} style="h5-underlined"/>
                            <EventTracksInfo/>
                        </ContentLayout>}
                </ContentLayout>
                {!isTraining &&
                    <ContentLayout>
                        <ContentLayout framed={true}>
                            <Headline text={dict("event.drivers.info.label")} style="h5-underlined"/>
                            <EventDriversInfo/>
                        </ContentLayout>
                        {eventTeams?.length > 0 &&
                            <ContentLayout framed={true}>
                                <Headline text={dict("event.teams.label")} style="h5-underlined"/>
                                <EventTeamsInfo/>
                            </ContentLayout>}
                    </ContentLayout>}
            </ContentLayout>
        </ComponentInitializer>
    );

}
