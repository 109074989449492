import {useEffect, useState} from "react";

/******************************************************************
 * useWindowSize
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function useWindowSize() {

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        window.addEventListener('resize', onWindowResized);
        onWindowResized();
        return () => {
            window.removeEventListener('resize', onWindowResized);
        };
    }, []);

    /* ----------------------------------------------------------------
     * EVENTS
     * --------------------------------------------------------------*/

    function onWindowResized() {
        setWindowWidth(window.innerWidth)
        setWindowHeight(window.innerHeight)
    }

    /* ----------------------------------------------------------------
     * RETURN
     * --------------------------------------------------------------*/

    return {
        windowWidth,
        windowHeight
    };

}
