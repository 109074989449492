import * as React from "react";
import {useEventState} from "../../../../../hooks/useEventState";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {UserInfo} from "../../../../../ui/info/user/UserInfo";
import {InfoText} from "../../../../../ui/text/infotext/InfoText";
import {useServices} from "../../../../../hooks/useServices";
import {useEffect, useRef, useState} from "react";
import {useMobileStyle} from "../../../../../hooks/useMobileStyle";
import {IUserData} from "../../../../../../../shared/models/IUserData";
import {EmptyDataInfo} from "../../../../../ui/info/empty/EmptyDataInfo";

/******************************************************************
 * EventDriverInfo
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function EventDriversInfo() {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const ref = useRef<HTMLDivElement>(null);

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {dict, api} = useServices();
    const {eventRegisteredDrivers, eventAccess, eventID, eventIsFinished} = useEventState();
    const [isSmall] = useMobileStyle(ref, 450)

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [drivers, setDrivers] = useState<IUserData[]>([])

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        updateDriversList()
    }, [eventRegisteredDrivers])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    async function updateDriversList() {
        const drivers = await api.event.getUsersDrivenByEvent(eventID)
        if (drivers?.length > 0 && eventRegisteredDrivers?.length > 0) {
            let allDrivers = [...drivers, ...eventRegisteredDrivers]
            const uniqueDrivers = allDrivers.filter((driver, index, self) =>
                index === self.findIndex((t) => (
                    t._id === driver._id
                )))
            setDrivers(uniqueDrivers)
            return
        }
        if (drivers?.length > 0) {
            setDrivers(drivers)
            return
        }
        setDrivers(eventRegisteredDrivers)
    }

    function colums(): number {
        const numDrivers = drivers?.length ?? 0
        if (numDrivers > 30) {
            return isSmall ? 5 : 6
        }
        return isSmall ? 4 : 5
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout className="event-drivers-info">
            {eventAccess && !eventIsFinished &&
                <InfoText text={dict("event.drivers.access.info." + eventAccess)}/>}
            {drivers?.length > 0 &&
                <ContentLayout columns={colums()} ref={ref}>
                    {drivers.map((user) => {
                        return <UserInfo
                            key={user._id}
                            user={user}
                            clickToShowProfile={true}
                            style="avatar"/>
                    })}
                </ContentLayout>}
            {drivers?.length == 0 &&
                <ContentLayout framed={true}>
                    <EmptyDataInfo
                        text={dict("event.drivers.empty")}
                        size="small"/>
                </ContentLayout>}
        </ContentLayout>

    );

}
