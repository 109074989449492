import * as React from "react";
import {IArticleLinkBlockData} from "../../../../../../shared/models/IArticleData";
import {ContentLayout} from "../../../layout/content/ContentLayout";
import {MongoObjectIDType} from "../../../../../../shared/types/MongoObjectIDType";
import {ArticleTeaser} from "../../../teaser/article/ArticleTeaser";

/******************************************************************
 * ArticleLinkBlock
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function ArticleLinkBlock(props: {
    data: IArticleLinkBlockData
    editing?: boolean
    onChange?: (data: IArticleLinkBlockData) => void
}) {

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    async function update(articleID: MongoObjectIDType): Promise<Response> {
        props.data.articleID = articleID
        props.onChange?.(props.data)
        return null
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            className="article-link-block"
            gap="small">
            <ArticleTeaser
                articleID={props.data.articleID}
                editable={props.editing ?? false}
                onSelected={update}/>
        </ContentLayout>
    );
}
