import * as React from "react";
import {ApprovalStateType} from "../../../../../shared/types/ApprovalStateType";
import {ContentLayout} from "../../layout/content/ContentLayout";
import {Icon} from "../../icons/Icon";

/******************************************************************
 * ApprovalStateIcon
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function ApprovalStateIcon(props: {
    state: ApprovalStateType
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout alignItems="center" justifyItems="center">
            {(props.state == "pending" || !props.state) &&
                <Icon type="pending" iconColor="yellow"/>}
            {props.state == "approved" &&
                <Icon type="save" iconColor="green"/>}
            {props.state == "rejected" &&
                <Icon type="cancel" iconColor="red"/>}
        </ContentLayout>
    );

}
