import * as React from "react";
import {useEffect, useState} from "react";
import {ContentLayout} from "../../../../ui/layout/content/ContentLayout";
import {useServices} from "../../../../hooks/useServices";
import {Headline} from "../../../../ui/text/headings/Headline";
import {AdminCacheInfo} from "../../../../../../shared/types/AdminCacheInfo";
import {Table} from "../../../../ui/table/Table";
import {ITableColumnDefinition} from "../../../../ui/table/ITableColumnDefinition";
import {IAdminCacheEntryData} from "./IAdminCacheEntryData";
import {ISessionData} from "../../../../../../shared/models/ISessionData";
import {FrontendRoute} from "../../../../../../shared/routes/FrontendRoute";
import {LabelButton} from "../../../../ui/buttons/label/LabelButton";
import {useMobileStyle} from "../../../../hooks/useMobileStyle";

/******************************************************************
 * AdminCache
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function AdminCache() {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const ref = React.useRef<HTMLDivElement>(null);

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, api, router} = useServices();
    const [useMobileMode] = useMobileStyle(ref, 600);

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [cacheInfo, setCacheInfo] = useState<AdminCacheInfo>();
    const [cachesTableData, setCachesTableData] = useState<IAdminCacheEntryData[]>([]);
    const [queuesTableData, setQueuesTableData] = useState<IAdminCacheEntryData[]>([]);
    const [isLoadingData, setIsLoadingData] = useState(true);

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        requestCacheSizes()
    }, [])

    useEffect(() => {
        setCachesTableData([{
            name: dict("admin.caches.sessionCacheSize"),
            size: cacheInfo?.sessionCacheSize,
        }, {
            name: dict("admin.caches.eventCacheSize"),
            size: cacheInfo?.eventCacheSize,
        }, {
            name: dict("admin.caches.groupCacheSize"),
            size: cacheInfo?.groupCacheSize,
        }, {
            name: dict("admin.caches.userCacheSize"),
            size: cacheInfo?.userCacheSize,
        }])
        setQueuesTableData([{
            name: dict("admin.caches.capiRequestQueueSize"),
            size: cacheInfo?.capiRequestQueueSize,
        }])
    }, [cacheInfo])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function clearDocumentCaches() {
        await api.admin.clearDocumentCaches()
        await requestCacheSizes()
        return null
    }

    async function clearLeaderboardCaches() {
        await api.admin.clearLeaderboardCaches()
        await requestCacheSizes()
        return null
    }

    async function clearCapiQueues() {
        await api.admin.clearCapiQueues()
        await requestCacheSizes()
        return null
    }

    async function requestCacheSizes() {
        setIsLoadingData(true)
        setCacheInfo(await api.admin.getCacheInfo())
        setIsLoadingData(false)
        return null
    }

    function cachesColumnsDefinition(): ITableColumnDefinition<IAdminCacheEntryData>[] {
        return [
            {
                type: "text",
                columnName: dict("admin.caches.columnName"),
                dataKey: "name",
                size: "2fr"
            }, {
                type: "number",
                columnName: dict("admin.caches.columnSize"),
                dataKey: "size",
                size: "1fr",
                align: "center"
            }
        ]
    }

    function sessionLeaderboardCacheSessionColumnsDefinition(): ITableColumnDefinition<ISessionData>[] {
        return [{
            type: "text",
            columnName: dict("admin.caches.sessionLeaderboardCacheSessions.column.name"),
            dataKey: "name",
            size: "3fr"
        }, {
            type: "text",
            columnName: dict("admin.caches.sessionLeaderboardCacheSessions.column.path"),
            size: "3fr",
            value: data => sessionRoute(data)?.replace("/event", ""),
            action: gotoSession
        }, {
            type: "state",
            dataKey: "state",
            size: "80px",
            align: "center"
        }];
    }

    async function gotoSession(data: ISessionData): Promise<Response> {
        await router.showRoute(sessionRoute(data))
        return null
    }

    function sessionRoute(data: ISessionData): string {
        return FrontendRoute.EVENT_SESSION(
            data.event.ownerType,
            data.event.owner.path,
            data.event.path,
            data.path)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout className="admin-cache" ref={ref}>
            <ContentLayout columns={useMobileMode ? 1 : 2}>
                <ContentLayout framed={true}>
                    <ContentLayout
                        columnTemplate="auto min-content"
                        justifyContent="space-between">
                        <Headline
                            text={dict("admin.caches.title")}
                            style="h5-underlined"/>
                        <LabelButton
                            label={dict("admin.caches.clear.button.label")}
                            style="primary-small"
                            onClick={clearDocumentCaches}/>
                    </ContentLayout>
                    <Table
                        rowsData={cachesTableData}
                        style="admin-compact"
                        isLoadingData={isLoadingData}
                        columnsDefinition={cachesColumnsDefinition()}/>
                </ContentLayout>
                <ContentLayout framed={true}>
                    <ContentLayout
                        columnTemplate="auto min-content"
                        justifyContent="space-between">
                        <Headline
                            text={dict("admin.caches.queues.title")}
                            style="h5-underlined"/>
                        <LabelButton
                            label={dict("admin.caches.queues.clear.button.label")}
                            style="primary-small"
                            onClick={clearCapiQueues}/>
                    </ContentLayout>
                    <Table
                        rowsData={queuesTableData}
                        style="admin-compact"
                        isLoadingData={isLoadingData}
                        columnsDefinition={cachesColumnsDefinition()}/>
                </ContentLayout>
            </ContentLayout>
            <ContentLayout framed={true}>
                <ContentLayout
                    columnTemplate="auto min-content"
                    justifyContent="space-between">
                    <Headline
                        text={dict("admin.caches.sessionLeaderboardCacheSessions.title")}
                        style="h5-underlined"/>
                    <LabelButton
                        label={dict("admin.caches.sessionLeaderboardCache.clear.button.label")}
                        style="primary-small"
                        onClick={clearLeaderboardCaches}/>
                </ContentLayout>
                <Table
                    rowsData={cacheInfo?.sessionLeaderboardCacheSessions || []}
                    style="admin-compact"
                    visibleRows={10}
                    isLoadingData={isLoadingData}
                    columnsDefinition={sessionLeaderboardCacheSessionColumnsDefinition()}/>
            </ContentLayout>
        </ContentLayout>
    );

}
