import * as React from "react";
import {useEffect} from "react";
import {Modal} from "../Modal";
import {Form} from "../../../ui/form/Form";
import {Headline} from "../../../ui/text/headings/Headline";
import {Input} from "../../../ui/form/elements/input/Input";
import {IEventData} from "../../../../../shared/models/IEventData";
import {FrontendRoute} from "../../../../../shared/routes/FrontendRoute";
import {useServices} from "../../../hooks/useServices";
import {MongoObjectIDType} from "../../../../../shared/types/MongoObjectIDType";
import {EventOwnerType} from "../../../../../shared/types/EventOwnerType";
import {useRouteStates} from "../../../hooks/useRouteStates";
import {useRouteOwner} from "../../../hooks/useRouteOwner";
import {useGroupState} from "../../../hooks/useGroupState";
import {DriverLicenseUtils} from "../../../../../shared/utils/DriverLicenseUtils";
import {PlanFeatureDefinitions} from "../../../../../shared/plan/PlanFeatureDefinitions";
import {PlanAccessType} from "../../../../../shared/types/PlanAccessType";
import {useAuthUser} from "../../../hooks/useAuthUser";

/******************************************************************
 * CreateEventModal
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function CreateEventModal() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {api, dict, state, router} = useServices();
    const {routeType} = useRouteStates()
    const {routeOwnerID, routeOwnerPath, isRouteOwnerUser} = useRouteOwner()
    const {groupID, groupPath} = useGroupState()
    const {authUserSubscription, authUserUseSubscription} = useAuthUser()

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        if (isRouteOwnerUser || !authUserUseSubscription) return;
        const validLicenseType = DriverLicenseUtils.validLicenseType(authUserSubscription)
        const isAllowed = PlanFeatureDefinitions.getFeatureAccess<PlanAccessType>("events", validLicenseType)?.type == "full";
        if (!isAllowed) {
            state.showSubscriptionUpgradeInfo.setValue({message: dict("subscription.upgrade.info.event")})
            state.showCreateEvent.setValue(null)
        }
    }, [])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function eventOwnerType(): EventOwnerType {
        switch (routeType) {
            case "member":
                return "user"
            case "group":
                return "group"
        }
        return null
    }

    function eventOwnerID(): MongoObjectIDType {
        switch (routeType) {
            case "member":
                return routeOwnerID
            case "group":
                return groupID
        }
    }

    function eventOwnerPath(): string {
        switch (routeType) {
            case "member":
                return routeOwnerPath
            case "group":
                return groupPath
        }
        return null
    }

    async function createEvent(data: { name: string }): Promise<Response> {
        const response = await api.event.createEvent({
            ...data,
            ownerID: eventOwnerID(),
            ownerType: eventOwnerType()
        });
        if (response.status == 200) {
            const eventData: IEventData = await response.json();
            closeModalAndShowEvent(eventData?.path)
        }
        return response;
    }

    function closeModalAndShowEvent(eventPath: string) {
        if (!eventPath) return;
        router.showRoute(
            FrontendRoute.EVENT(
                eventOwnerType(),
                eventOwnerPath(),
                eventPath
            ));
        state.showCreateEvent.setValue(false)
    }

    function title(): string {
        switch (routeType) {
            case "member":
                return dict("user.training.create")
            case "group":
                return dict("event.create")
        }
        return null
    }

    function inputLabel(): string {
        switch (routeType) {
            case "member":
                return dict("trainging.create.name")
            case "group":
                return dict("events.create.name")
        }
        return null
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal
            width="small"
            closeAction={() => state.showCreateEvent.setValue(false)}>
            <Headline text={title()} style="modal"/>
            {eventOwnerID() && eventOwnerType() && eventOwnerPath() &&
                <Form
                    actionLabel={title()}
                    action={createEvent}>
                    <Input
                        type="text"
                        id="name"
                        required={true}
                        label={inputLabel()}/>
                </Form>}
        </Modal>
    );

}
