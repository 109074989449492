import * as React from "react";
import {IScoreboardEntry} from "../../../../../../shared/types/IScoreboardEntry";
import {SessionResultBadge} from "./badge/SessionResultBadge";

/******************************************************************
 * TableScoreboardSessionsCell
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function TableScoreboardSessionsCell(props: {
    entry: IScoreboardEntry;
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="table-scoreboard-sessions-cell">
            {props.entry.sessions.map(session => {
                return <SessionResultBadge
                    key={session.sessionID}
                    position={session.position}
                    sessionPath={session.sessionPath}
                    sessionState={session.sessionState}
                    fastestLap={session.isFastestLap}/>
            })}
        </div>
    );

}
