import * as React from "react";
import {JoinedEventsList} from "../../../../ui/list/events/JoinedEventsList";
import {ActionBar} from "../../../../ui/bar/ActionBar";
import {LabelButton} from "../../../../ui/buttons/label/LabelButton";
import {useServices} from "../../../../hooks/useServices";
import {useRouteOwner} from "../../../../hooks/useRouteOwner";
import {IconButton} from "../../../../ui/buttons/icon/IconButton";
import {ActionBarSpacer} from "../../../../ui/bar/spacer/ActionBarSpacer";
import {Divider} from "../../../../ui/utils/divider/Divider";
import {ContentLayout} from "../../../../ui/layout/content/ContentLayout";
import {BasicPageTitle} from "../../../../ui/text/headings/BasicPageTitle";

/******************************************************************
 * UserEventsPage
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function UserEventsPage() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, state} = useServices();
    const {isRouteOwnerAuthUser} = useRouteOwner()

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return <>
        <ContentLayout
            className="user-events-page"
            gap="none"
            rowTemplate="min-content 1fr">
            <BasicPageTitle title={dict("events.user.title")}/>
            <JoinedEventsList/>
        </ContentLayout>
        {isRouteOwnerAuthUser && <ActionBar location="main">
            <LabelButton
                label={dict("event.join.create.button.label")}
                onClick={() => state.showArticle.setValue(dict("article.id.joinCreateEvent"))}
                style={"action-main"}/>
            <Divider style="action-bar"/>
            <IconButton
                type="academy"
                size="small"
                onClick={() => state.showAcademy.setValue(dict("academy.id.events"))}/>
            <ActionBarSpacer/>
        </ActionBar>}
    </>

}
