import {FrontendServices} from "../core/FrontendServices";
import {APIRoute} from "../../shared/routes/APIRoute";
import {TransferDictionaryType} from "../../shared/types/TransferDictionaryType";
import {IMergedCollectionResult} from "../../shared/types/IMergedCollectionResult";
import {IDictionaryEntryData} from "../../shared/models/IDictionaryEntryData";
import {ISystemFileData} from "../../shared/types/ISystemFileData";
import {ICommandLineResponse} from "../../shared/types/ICommandLineResponse";
import {IDatabaseInfoData} from "../../shared/types/IDatabaseInfoData";
import {IArticleData} from "../../shared/models/IArticleData";
import {AdminCacheInfo} from "../../shared/types/AdminCacheInfo";
import {IKeyValuePair} from "../../shared/types/IKeyValuePair";

/******************************************************************
 * AdminAPI
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class AdminAPI {

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(private _frontend: FrontendServices) {
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    async throwExceptionAtServer(): Promise<void> {
        await this._frontend.api.request("GET", APIRoute.ADMIN_THROW_EXCEPTION, {}, true)
        return null
    }

    async clearDocumentCaches(): Promise<Response> {
        return await this._frontend.api.request("GET", APIRoute.ADMIN_SERVER_CLEAR_DOCUMENT_CACHES, {}, true)
    }

    async clearLeaderboardCaches(): Promise<Response> {
        return await this._frontend.api.request("GET", APIRoute.ADMIN_SERVER_CLEAR_LEADERBOARD_CACHES, {}, true)
    }

    async clearUserCapiQueue(userID: string): Promise<Response> {
        return await this._frontend.api.request("GET", APIRoute.ADMIN_SERVER_CLEAR_USER_CAPI_QUEUE, {userID}, true)
    }

    async clearCapiQueues(): Promise<Response> {
        return await this._frontend.api.request("GET", APIRoute.ADMIN_SERVER_CLEAR_CAPI_QUEUES, {}, true)
    }

    async getCacheInfo(): Promise<AdminCacheInfo> {
        const response = await this._frontend.api.request("GET", APIRoute.ADMIN_SERVER_CACHE_INFO, {}, true)
        return await response.json() as AdminCacheInfo
    }

    async getDatabaseInfo(): Promise<IDatabaseInfoData> {
        const response = await this._frontend.api.request("GET", APIRoute.ADMIN_DB_INFO, {}, true)
        if (response.status !== 200) {
            const errorMessage = this._frontend.error.createMessage(await response.json())
            this._frontend.state.showMessage.setValue({type: "error", message: errorMessage})
            return null
        }
        return await response.json()
    }

    async getEnvironmentVariables(): Promise<IKeyValuePair> {
        const response = await this._frontend.api.request("GET", APIRoute.ADMIN_ENV_VARS, {}, true)
        if (response.status !== 200) {
            const errorMessage = this._frontend.error.createMessage(await response.json())
            this._frontend.state.showMessage.setValue({type: "error", message: errorMessage})
            return null
        }
        return await response.json()
    }

    async transferDictionary(direction: TransferDictionaryType): Promise<string | IMergedCollectionResult<IDictionaryEntryData>> {
        const response = await this._frontend.api.request("POST", APIRoute.ADMIN_DB_TRANSFER_DICTIONARY, {direction}, true)
        if (response.status !== 200) {
            return this._frontend.error.createMessage(await response.json()) as string
        }
        return await response.json() as IMergedCollectionResult<IDictionaryEntryData>
    }

    async getMasterArticles(): Promise<IArticleData[]> {
        const response = await this._frontend.api.request("GET", APIRoute.ADMIN_DB_MASTER_ARTICLES, {}, true)
        if (response.status !== 200) {
            const errorMessage = this._frontend.error.createMessage(await response.json())
            this._frontend.state.showMessage.setValue({type: "error", message: errorMessage})
            return null
        }
        return await response.json() as IArticleData[]
    }

    async upsertLocalArticleToMaster(articleID: string): Promise<boolean> {
        const response = await this._frontend.api.request("POST", APIRoute.ADMIN_DB_LOCAL_TO_MASTER_ARTICLE, {articleID}, true)
        if (response.status !== 200) {
            const errorMessage = this._frontend.error.createMessage(await response.json())
            this._frontend.state.showMessage.setValue({type: "error", message: errorMessage})
            return false
        }
        return true
    }

    async upsertMasterArticleToLocal(articleID: string): Promise<boolean> {
        const response = await this._frontend.api.request("POST", APIRoute.ADMIN_DB_MASTER_TO_LOCAL_ARTICLE, {articleID}, true)
        if (response.status !== 200) {
            const errorMessage = this._frontend.error.createMessage(await response.json())
            this._frontend.state.showMessage.setValue({type: "error", message: errorMessage})
            return false
        }
        return true
    }

    async getDatabaseBackups(): Promise<ISystemFileData[]> {
        const response = await this._frontend.api.request("GET", APIRoute.ADMIN_DB_BACKUPS, {}, true)
        if (response.status !== 200) {
            const errorMessage = this._frontend.error.createMessage(await response.json())
            this._frontend.state.showMessage.setValue({type: "error", message: errorMessage})
            return null
        }
        return await response.json() as ISystemFileData[]
    }

    async createDatabaseBackup(): Promise<ICommandLineResponse> {
        const response = await this._frontend.api.request("POST", APIRoute.ADMIN_DB_BACKUP_CREATE, {}, true)
        return await response.json()
    }

    async restoreDatabaseBackup(path: string): Promise<ICommandLineResponse> {
        const response = await this._frontend.api.request("POST", APIRoute.ADMIN_DB_BACKUP_RESTORE, {path}, true)
        return await response.json()
    }

    async deleteDatabaseBackup(path: string): Promise<boolean> {
        const response = await this._frontend.api.request("DELETE", APIRoute.ADMIN_DB_BACKUP, {path}, true)
        if (response.status !== 200) {
            const errorMessage = this._frontend.error.createMessage(await response.json())
            this._frontend.state.showMessage.setValue({type: "error", message: errorMessage})
            return false
        }
        return true
    }

    async installMongoDBTools(): Promise<ICommandLineResponse> {
        const response = await this._frontend.api.request("POST", APIRoute.ADMIN_DB_INSTALL_MONGO_DB_TOOLS, {}, true)
        return await response.json()
    }

}
