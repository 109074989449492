import * as React from "react";
import {useEffect, useState} from "react";
import {Modal} from "../../Modal";
import {Table} from "../../../../ui/table/Table";
import {IMailData, IMailSendData} from "../../../../../../shared/models/IMailData";
import {ITableColumnDefinition} from "../../../../ui/table/ITableColumnDefinition";
import {useServices} from "../../../../hooks/useServices";
import {TimeUtils} from "../../../../../../shared/utils/TimeUtils";
import {Icon} from "../../../../ui/icons/Icon";
import {ContentLayout} from "../../../../ui/layout/content/ContentLayout";

/******************************************************************
 * MailInfoListModal
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

interface IMailInfo extends IMailSendData {
    state: "sent" | "failed"
    nick: string
}

export function MailInfoListModal(props: {
    mailData: IMailData
    requestClose: () => void
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict} = useServices()

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [data, setData] = useState<IMailInfo[]>()

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        const sendedMails: IMailInfo[] = props.mailData?.sendedMails.map(data => ({
            recipient: data.recipient,
            nick: data.recipient?.nick,
            date: data.date,
            state: "sent"
        }))
        const unsendedMails: IMailInfo[] = props.mailData?.unsendRecipients.map(user => ({
            recipient: user,
            nick: user?.nick,
            date: null,
            state: "failed"
        } as IMailInfo))
        setData([...sendedMails, ...unsendedMails])
    }, [props.mailData])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function columnsDefinition(): ITableColumnDefinition<IMailInfo>[] {
        return [{
            type: "text",
            size: "130px",
            sortKey: "date",
            columnName: dict("mail.state.info.table.column.sendDate"),
            value: (data: IMailInfo) => TimeUtils.formatDate(data.date)
        }, {
            type: "driver",
            dataKey: "recipient",
            sortKey: "nick",
            columnName: dict("mail.state.info.table.column.recipient"),
            size: "2fr"
        }, {
            type: "custom",
            size: "80px",
            align: "center",
            sortKey: "state",
            columnName: dict("mail.state.info.table.column.state"),
            customCell: (key: number, data: IMailInfo) => (
                <ContentLayout key={key} justifyItems="center">
                    <Icon
                        type={data.date ? "save" : "cancel"}
                        iconColor={data.date ? "white" : "red"}/>
                </ContentLayout>
            )
        }];
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal
            className="mail-info-list-modal"
            width="medium"
            closeAction={props.requestClose}>
            <Table<IMailInfo>
                rowsData={data}
                style="standard-compact"
                sortKey="date"
                showFilter={true}
                sortDirection="asc"
                visibleRows={15}
                columnsDefinition={columnsDefinition()}/>
        </Modal>
    );

}
