import * as React from "react";
import {ITableColumnDefinition} from "../ITableColumnDefinition";
import {TableBodyRow} from "./TableBodyRow";
import {useServices} from "../../../hooks/useServices";
import {TableStyleType} from "../TableStyleType";
import {TableRowDataType} from "../TableDataRowType";
import {EmptyDataInfo} from "../../info/empty/EmptyDataInfo";
import {ProgressingAnimation} from "../../utils/progress/ProgressingAnimation";

/******************************************************************
 * TableBody
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function TableBody<RowDataType, GlobalDataType>(props: {
    rowsData: TableRowDataType<RowDataType>[]
    globalData?: GlobalDataType
    style: TableStyleType
    columnsDefinition: ITableColumnDefinition<RowDataType>[]
    gridTemplateColumns: string
    hideLowPrioColumns: boolean
    showLoadingData?: boolean
    noDataInfo?: string
    onRowClick?: (data: RowDataType) => void
}) {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {dict} = useServices();

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/


    return (
        <main className="table-body">
            {!props.showLoadingData && (!props.rowsData || props.rowsData.length === 0) &&
                <div className="table-body-rows-empty">
                    <EmptyDataInfo
                        text={props.noDataInfo ?? dict("table.body.noData.info")}
                        size={(props.style?.includes("compact") ? "small" : "normal") ?? "normal"}/>
                </div>}
            {!props.showLoadingData && props.rowsData && (props.rowsData.length > 0) &&
                <div className="table-body-rows">
                    {props.rowsData.map((rowData, i) => {
                        const isKeyUnique = props.rowsData.filter(e => e['.']?._key == rowData['.']?._key).length == 1
                        const key = isKeyUnique ? rowData['.']?._key ?? i : i
                        return <TableBodyRow<RowDataType, GlobalDataType>
                            key={key + props.columnsDefinition?.map(e => e.size)?.join("") ?? ""}
                            onRowClick={props.onRowClick}
                            gridTemplateColumns={props.gridTemplateColumns}
                            columnsDefinition={props.columnsDefinition}
                            rowID={rowData.rowID}
                            style={props.style}
                            rowData={rowData}
                            hideLowPrioColumns={props.hideLowPrioColumns}
                            globalData={props.globalData}/>
                    })}
                </div>}
            {props.showLoadingData &&
                <div className="table-body-loader">
                    <ProgressingAnimation/>
                </div>}
        </main>
    );
}
