import * as React from "react";
import {ISessionLeaderboardEntry} from "../../../../../../shared/types/ISessionLeaderboardEntry";
import {StarIcon} from "../../../icons/StarIcon";
import {ISessionData} from "../../../../../../shared/models/ISessionData";
import {CharUtils} from "../../../../../../shared/utils/CharUtils";

/******************************************************************
 * TableLeaderboardStintCell
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function TableLeaderboardStintCell(props: {
    entry: ISessionLeaderboardEntry
}) {

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function isBestStint(): boolean {
        const session: ISessionData = props.entry.session
        switch (session.setup.mode) {
            case "lap":
                return isStintsBestLap();
            case "race":
                return false
            case "gymkhana":
                return false

        }
    }

    function isStintsBestLap() {
        const bestLapTime = props.entry.bestLapStint.lapTime;
        if (!bestLapTime) {
            return false;
        }
        for (const entry of props.entry.entries) {
            if (entry.bestLapStint.lapTime && entry.bestLapStint.lapTime < bestLapTime) {
                return false;
            }
        }
        return true;
    }

    function stintNumber() {
        const sortedEntries = [...props.entry.entries]
        sortedEntries.sort((entryA, entryB) => {
            const dateA = new Date(parseInt(entryA.stintIDs[0].substring(0, 8), 16) * 1000);
            const dateB = new Date(parseInt(entryB.stintIDs[0].substring(0, 8), 16) * 1000);
            return dateA.getTime() - dateB.getTime()
        });
        return sortedEntries.indexOf(props.entry) + 1;
    }

    function value() {
        const session: ISessionData = props.entry.session
        switch (session.setup.leaderboardGrouping) {
            case "stint":
                return CharUtils.replaceZeroWithO(stintNumber())
            case "driver":
            case "team":
            case "engine-and-tuning":
            case "engine":
            case "tuning":
            case "car-name":
                return <>{isBestStint() && <StarIcon scale={0.5}/>} {CharUtils.replaceZeroWithO(props.entry.overall.drivenStints)}</>

        }
    }

    function theme(): "dark" | "light" {
        switch (props.entry.state) {
            case "ready":
            case "driving":
                return "light"
            case "invalid":
            case "finished":
                return "dark"
        }
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="table-leaderboard-stint-cell"
            data-all-rejected={props.entry.hasOnlyRejectedStints && props.entry.session.setup.useStintApproval}
            data-theme={theme()}>
            {value()}
        </div>
    );

}
