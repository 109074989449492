import * as React from "react";

/******************************************************************
 * AddIcon
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function AddIcon(props: {
    scale?: number
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="add-icon">
            <svg width={24 * (props.scale ?? 1)} height={24 * (props.scale ?? 1)} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.668945" y="0.5" width="25" height="25" rx="12.5" fill="#F50B35"/>
                <g clipPath="url(#clip0_651_1578)">
                    <path d="M17.276 11.9732H14.1956V8.8928C14.1956 8.5148 13.889 8.20825 13.511 8.20825H12.8265C12.4485 8.20825 12.1419 8.5148 12.1419 8.8928V11.9732H9.0615C8.6835 11.9732 8.37695 12.2798 8.37695 12.6578V13.3423C8.37695 13.7203 8.6835 14.0269 9.0615 14.0269H12.1419V17.1073C12.1419 17.4853 12.4485 17.7919 12.8265 17.7919H13.511C13.889 17.7919 14.1956 17.4853 14.1956 17.1073V14.0269H17.276C17.654 14.0269 17.9606 13.7203 17.9606 13.3423V12.6578C17.9606 12.2798 17.654 11.9732 17.276 11.9732Z" fill="white"/>
                </g>
                <defs>
                    <clipPath id="clip0_651_1578">
                        <rect width="9.58362" height="10.9527" fill="white" transform="translate(8.37695 7.52368)"/>
                    </clipPath>
                </defs>
            </svg>
        </div>
    );

}
