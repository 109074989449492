import * as React from "react";
import {Modal} from "../Modal";
import {useServices} from "../../../hooks/useServices";
import {ContentLayout} from "../../../ui/layout/content/ContentLayout";
import {Headline} from "../../../ui/text/headings/Headline";
import {Input} from "../../../ui/form/elements/input/Input";
import {InfoText} from "../../../ui/text/infotext/InfoText";
import {Form} from "../../../ui/form/Form";
import {IKeyValuePair} from "../../../../../shared/types/IKeyValuePair";

/******************************************************************
 * AddMailToWhitelistModal
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function AddMailToWhitelistModal() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {state, dict, api} = useServices()

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function addMailToWhitelist(formData: IKeyValuePair | any): Promise<Response> {
        const response = await api.user.addMailToWhitelist(formData.mail)
        if (response.status == 200) {
            state.showAddMailToWhitelist.setValue(false)
        }
        return response
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal
            className="addmailtowhitelist-modal"
            width="medium"
            closeAction={() => state.showAddMailToWhitelist.setValue(false)}>
            <Headline text={dict("addMailToWhitelist.title")} style="modal"/>
            <ContentLayout>
                <InfoText
                    text={dict("addMailToWhitelist.info")}/>
                <InfoText
                    text={dict("addMailToWhitelist.disclaimer")}
                    size="small"
                    style="warning"/>
                <Form
                    actionLabel={dict("addMailToWhitelist.action")}
                    action={addMailToWhitelist}>
                    <Input
                        type="email"
                        size="normal"
                        id="mail"
                        required={true}
                        clearOnSubmit={true}
                        defaultValue={""}
                        placeholder={dict("admin.mailFilter.mail.placeholder")}/>
                </Form>
            </ContentLayout>
        </Modal>
    );

}
