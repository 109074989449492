import * as React from "react";
import {useEffect, useState} from "react";
import {ContextMenu} from "../ContextMenu";
import {useServices} from "../../../hooks/useServices";
import {ContextMenuLabel} from "../elements/ContextMenuLabel";
import {ContextMenuDivider} from "../elements/ContextMenuDivider";
import {ISessionLeaderboardEntry} from "../../../../../shared/types/ISessionLeaderboardEntry";
import {SpeakerUtils} from "../../../../utils/SpeakerUtils";
import {useRouteOwner} from "../../../hooks/useRouteOwner";
import {useGroupState} from "../../../hooks/useGroupState";
import {useSessionState} from "../../../hooks/useSessionState";

/******************************************************************
 * LeaderboardEntryContextMenu
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function LeaderboardEntryContextMenu(props: {
    entry: ISessionLeaderboardEntry
}) {

    /* ----------------------------------------------------------------
     * SERVICES
     * --------------------------------------------------------------*/

    const {dict, api, state, speaker} = useServices();
    const {isRouteOwnerAuthUser} = useRouteOwner()
    const {isGroupAdmin} = useGroupState()
    const {sessionIsFinished} = useSessionState()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [userAnnouncementsEnabled, setUserAnnouncementsEnabled] = useState<boolean>(
        SpeakerUtils.areUserAnnouncementsEnabled(userID())
    )

    const [teamAnnouncementsEnabled, setTeamAnnouncementsEnabled] = useState<boolean>(
        SpeakerUtils.areTeamAnnouncementsEnabled(teamName())
    )

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        speaker.setUserAnnounceableState(userID(), userAnnouncementsEnabled)
    }, [userAnnouncementsEnabled])

    useEffect(() => {
        speaker.setTeamAnnounceableState(teamName(), teamAnnouncementsEnabled)
    }, [teamAnnouncementsEnabled])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function finishEntry() {
        await api.leaderboard.finishLeaderboardEntry(props.entry.stintIDs, props.entry.session._id)
    }

    async function deleteEntry() {
        state.showConfirm.setValue({
            type: "deleteLeaderboardEntry",
            payload: {
                stintIDs: props.entry.stintIDs,
                sessionID: props.entry.session._id,
            }
        })
    }

    function toggleAnnouncments() {
        if (props.entry.team) {
            setTeamAnnouncementsEnabled(!teamAnnouncementsEnabled)
            return
        }
        setUserAnnouncementsEnabled(!userAnnouncementsEnabled)
    }

    function showAnnouncmentsToggle() {
        if (props.entry.team) {
            return false
        }
        return !sessionIsFinished
    }

    function userID() {
        return props.entry?.latestStint?.user?._id
    }

    function teamName() {
        return props.entry?.team?.name
    }

    function announcementsEnabled() {
        return userAnnouncementsEnabled || teamAnnouncementsEnabled
    }

    function canEdit(): boolean {
        if (sessionIsFinished) return false
        return isRouteOwnerAuthUser || isGroupAdmin
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return <>
        {(showAnnouncmentsToggle() || canEdit()) &&
            <ContextMenu
                type="icon"
                iconType="menuDots"
                iconScale={0.9}
                stopPropagation={true}>
                {showAnnouncmentsToggle() &&
                    <ContextMenuLabel
                        label={dict("speaker.menu.driver.announcements")}
                        icon={announcementsEnabled() ? "speaker" : "speakerDisabled"}
                        iconScale={0.9}
                        onClick={toggleAnnouncments}/>}
                {canEdit() && props.entry.state !== "finished" && <>
                    <ContextMenuLabel
                        label={dict("leaderboard.entry.finish.label")}
                        icon="flag"
                        onClick={finishEntry}/>
                    <ContextMenuDivider style="small"/>
                </>}
                {canEdit() &&
                    <ContextMenuLabel
                        label={dict("leaderboard.entry.delete.label")}
                        icon="delete"
                        warning={true}
                        onClick={deleteEntry}/>}
            </ContextMenu>}
    </>

}
