import * as React from "react";
import {LabelButton} from "../../../ui/buttons/label/LabelButton";
import {Headline} from "../../../ui/text/headings/Headline";
import {FrontendRoute} from "../../../../../shared/routes/FrontendRoute";
import {useServices} from "../../../hooks/useServices";
import {useRouteStates} from "../../../hooks/useRouteStates";
import {Divider} from "../../../ui/utils/divider/Divider";

/******************************************************************
 * AdminSidebar
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function AdminSidebar() {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {dict, router} = useServices();
    const {
        showAdminDictionary,
        showAdminArticles,
        showAdminUsers,
        showAdminSimulator,
        showAdminMailFilter,
        showAdminDatabase,
        showAdminLogs,
        showAdminCaches,
        showAdminEnvVars,
        showAdminMailings,
        showAdminMailTemplates
    } = useRouteStates();

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <section className="admin-sidebar">
            <header>
                <Headline text={dict("admin.title")} style="modal"/>
            </header>
            <LabelButton
                label={dict("admin.mailings.title")}
                style="sidebar"
                selected={showAdminMailings}
                onClick={() => router.showRoute(FrontendRoute.ADMIN_MAILINGS)}/>
            <LabelButton
                label={dict("admin.mailTemplates.title")}
                style="sidebar"
                selected={showAdminMailTemplates}
                onClick={() => router.showRoute(FrontendRoute.ADMIN_MAILTEMPLATES)}/>
            <Divider style="sidebar-menu"/>
            <LabelButton
                label={dict("admin.users.title")}
                style="sidebar"
                selected={showAdminUsers}
                onClick={() => router.showRoute(FrontendRoute.ADMIN_USERS)}/>
            <LabelButton
                label={dict("admin.mailFilter.title")}
                style="sidebar"
                selected={showAdminMailFilter}
                onClick={() => router.showRoute(FrontendRoute.ADMIN_MAILFILTER)}/>
            <Divider style="sidebar-menu"/>
            <LabelButton
                label={dict("admin.dictionary.title")}
                style="sidebar"
                selected={showAdminDictionary}
                onClick={() => router.showRoute(FrontendRoute.ADMIN_DICTIONARY)}/>
            <LabelButton
                label={dict("admin.articles.title")}
                style="sidebar"
                selected={showAdminArticles}
                onClick={() => router.showRoute(FrontendRoute.ADMIN_ARTICLES)}/>
            <Divider style="sidebar-menu"/>
            <LabelButton
                label={dict("admin.simulator.title")}
                style="sidebar"
                selected={showAdminSimulator}
                onClick={() => router.showRoute(FrontendRoute.ADMIN_SIMULATOR)}/>
            <Divider style="sidebar-menu"/>
            <LabelButton
                label={dict("admin.logs.title")}
                style="sidebar"
                selected={showAdminLogs}
                onClick={() => router.showRoute(FrontendRoute.ADMIN_LOGS)}/>
            <LabelButton
                label={dict("admin.database.title")}
                style="sidebar"
                selected={showAdminDatabase}
                onClick={() => router.showRoute(FrontendRoute.ADMIN_DATABASE)}/>
            <LabelButton
                label={dict("admin.caches.title")}
                style="sidebar"
                selected={showAdminCaches}
                onClick={() => router.showRoute(FrontendRoute.ADMIN_CACHES)}/>
            <LabelButton
                label={dict("admin.envvars.title")}
                style="sidebar"
                selected={showAdminEnvVars}
                onClick={() => router.showRoute(FrontendRoute.ADMIN_ENVVARS)}/>
        </section>
    );

}
