import {useEffect, useState} from "react";
import {useSessionState} from "../../hooks/useSessionState";
import {ISessionLeaderboardEntry} from "../../../../shared/types/ISessionLeaderboardEntry";
import {useServices} from "../../hooks/useServices";
import {StateValue} from "@webfruits/toolbox/dist/state/StateValue";
import {SpeakerUtils} from "../../../utils/SpeakerUtils";
import {useSocketState} from "../../hooks/useSocketState";

/******************************************************************
 * useStateAnnouncer
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function useStateAnnouncer(props: {
    visibleSettings: StateValue<boolean>[]
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {speaker, state} = useServices()
    const {isSocketDisconnected} = useSocketState()
    const {
        sessionLeaderboard,
        sessionState,
    } = useSessionState();

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [previousLeaderboard, setPreviousLeaderboard] = useState<ISessionLeaderboardEntry[]>()
    const [currentLeaderboard, setCurrentLeaderboard] = useState<ISessionLeaderboardEntry[]>()
    const [isInitialized, setIsInitialized] = useState<boolean>(false)

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        setPreviousLeaderboard(currentLeaderboard?.map(entry => entry))
        setCurrentLeaderboard(sessionLeaderboard?.map(entry => entry))
        let timeout: number
        if (!isInitialized) {
            timeout = window.setTimeout(() => {
                setIsInitialized(true)
            }, 1000)
        }
        return () => clearTimeout(timeout)
    }, [sessionLeaderboard]);

    useEffect(() => {
        if (sessionState !== "running") return
        if (!isInitialized) return
        announceDriverState()
    }, [currentLeaderboard])

    useEffect(() => {
        announceSocketDisconnected()
    }, [isSocketDisconnected])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function isSettingVisible(annouceSettingState: StateValue<boolean>) {
        return props.visibleSettings?.some(setting => setting === annouceSettingState)
    }

    function announceSocketDisconnected() {
        if (!isSocketDisconnected) return
        if (!isSettingVisible(speaker.state.socketDisconnected)) return
        speaker.state.announceSocketDisconnected()
    }

    function announceDriverState() {
        currentLeaderboard?.forEach(entry => {
            const previousEntry = previousLeaderboard?.find(prevEntry => prevEntry.latestStint._id === entry.latestStint._id)
            const nick = SpeakerUtils.nickToSpeak(entry.latestStint?.user, state.language.getValue())
            switch (entry.state) {
                case "ready":
                    if (previousEntry?.state == "ready") return
                    if (!isSettingVisible(speaker.state.enteredDriver)) return
                    return speaker.state.announceEnteredDriver(nick)
                case "invalid":
                    if (previousEntry?.state === "invalid") return
                    if (!isSettingVisible(speaker.state.invalidDriver)) return
                    return speaker.state.announceInvalidDriver(nick)
                case "driving":
                    if (!previousEntry || previousEntry.state === "driving") return
                    if (!isSettingVisible(speaker.state.startedDriver)) return
                    return speaker.state.announceStartedDriver(nick)
                case "finished":
                    if (!previousEntry || previousEntry.state === "finished") return
                    if (!isSettingVisible(speaker.state.finishedDriver)) return
                    speaker.state.announceFinishedDriver(nick)
                    return
            }
        })
    }

}
