import * as React from "react";
import {useServices} from "../../../../hooks/useServices";
import {ContentLayout} from "../../../layout/content/ContentLayout";
import {EmptyDataInfo} from "../../../info/empty/EmptyDataInfo";
import {ArticleBlockActionBar} from "../../actionbar/ArticleBlockActionBar";
import {ArticleBlockType, IArticleData} from "../../../../../../shared/models/IArticleData";

/******************************************************************
 * ArticleEmptyBlock
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function ArticleEmptyBlock(props: {
    article: IArticleData
    availableBlocks: ArticleBlockType[]
    onChange: () => void
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict} = useServices()

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            className="article-empty-block"
            framed={true}>
            <EmptyDataInfo text={dict("article.block.empty.info")}/>
            <ArticleBlockActionBar
                article={props.article}
                availableBlocks={props.availableBlocks}
                block={null}
                onChange={props.onChange}/>
        </ContentLayout>
    );

}
