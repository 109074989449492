import * as React from "react";
import {IStintLapData} from "../../../../../../shared/types/IStintLapData";
import {ContextMenu} from "../../../context/ContextMenu";
import {ContextMenuLabel} from "../../../context/elements/ContextMenuLabel";
import {useServices} from "../../../../hooks/useServices";
import {StintUtils} from "../../../../../../shared/utils/StintUtils";

/******************************************************************
 * TableStintLapFlagsCell
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function TableStintLapFlagsCell(props: {
    lap: IStintLapData
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict} = useServices();

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function hasBeenEdited() {
        return props.lap?.targetData?.some(target => {
            return target._disabled || target._overwrittenTargetCode >= 0
        })
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="table-stint-lap-flags-cell">
            {props.lap.isJokerLap && <ContextMenu
                type="icon"
                iconType="joker"
                iconScale={0.9}
                stopPropagation={true}
                enabled={true}>
                <ContextMenuLabel
                    label={dict("stint.lap.joker")}
                    iconScale={0.9}
                    icon="joker"/>
            </ContextMenu>}
            {hasBeenEdited() && <ContextMenu
                type="icon"
                iconType="edit"
                iconScale={0.9}
                stopPropagation={true}
                enabled={true}>
                <ContextMenuLabel
                    label={dict("stint.lap.edited")}
                    iconScale={0.9}
                    icon="edit"/>
            </ContextMenu>}
            {!StintUtils.isLapValid(props.lap) && <ContextMenu
                type="icon"
                iconType="warning"
                iconScale={0.8}
                stopPropagation={true}
                enabled={true}>
                {props.lap.isMissingFinishTarget && <ContextMenuLabel
                    label={dict("stint.lap.warning.missingFinishTarget")}
                    iconScale={0.8}
                    icon="warning"/>}
                {props.lap.isMissingSectorTargets && <ContextMenuLabel
                    label={dict("stint.lap.warning.missingSectorTargets")}
                    iconScale={0.8}
                    icon="warning"/>}
                {props.lap.wasBelowMinLapTime && <ContextMenuLabel
                    label={dict("stint.lap.warning.wasBelowMinLapTime")}
                    iconScale={0.8}
                    icon="warning"/>}
                {props.lap.hasUnwantedTargets && <ContextMenuLabel
                    label={dict("stint.lap.warning.hasUnwantedTargets")}
                    iconScale={0.8}
                    icon="warning"/>}
            </ContextMenu>}
        </div>
    );

}
