import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {Modal} from "../Modal";
import {useServices} from "../../../hooks/useServices";
import {ActionBar} from "../../../ui/bar/ActionBar";
import {IconButton} from "../../../ui/buttons/icon/IconButton";
import {Divider} from "../../../ui/utils/divider/Divider";
import {ActionBarSpacer} from "../../../ui/bar/spacer/ActionBarSpacer";
import {MediaViewerItem} from "./item/MediaViewerItem";
import {useSwipeable} from "react-swipeable";
import {FrontendConfig} from "../../../../core/FrontendConfig";
import {IMediaViewerItem} from "./item/IMediaViewerItem";
import {Badge} from "../../../ui/info/badge/Badge";
import {KeyController} from "../../../ui/keyboard/KeyController";

/******************************************************************
 * ImageModal
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function MediaViewerModal() {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const itemsRef = useRef<HTMLDivElement>()

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {state} = useServices()
    const swipeableHandlers = useSwipeable({
        onSwipedRight: () => showPrev(),
        onSwipedLeft: () => showNext(),
        preventScrollOnSwipe: false,
        trackTouch: true,
        trackMouse: false,
        delta: FrontendConfig.SWIPE_DELTA,
        swipeDuration: FrontendConfig.SWIPE_DURATION
    });

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [items] = useState<IMediaViewerItem[]>(state.showMediaViewer.getValue())
    const [currentItem, setCurrentItem] = useState<IMediaViewerItem>(null)
    const [zoomLevel, setZoomLevel] = useState<number>(1)
    const [maxZoomLevel] = useState<number>(3)


    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        setCurrentItem(state.showMediaViewer.getValue()[0])
    }, [])

    useEffect(() => {
        if (!currentItem) return
        setZoomLevel(1)
        const currentIndex = items.indexOf(currentItem)
        const gap = parseFloat(window.getComputedStyle(itemsRef.current).gap)
        itemsRef.current.style.transform = `translateX(calc(${currentIndex * 100}% * -1 - ${currentIndex * gap}px))`
    }, [currentItem])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function zoomIn() {
        let newZoomLevel = zoomLevel + 1
        if (newZoomLevel > maxZoomLevel) {
            newZoomLevel = maxZoomLevel
        }
        setZoomLevel(newZoomLevel)
    }

    function zoomOut() {
        let newZoomLevel = zoomLevel - 1
        if (newZoomLevel < 1) {
            newZoomLevel = 1
        }
        setZoomLevel(newZoomLevel)
    }

    function showPrev() {
        const currentIndex = items.indexOf(currentItem)
        if (currentIndex <= 0) {
            setCurrentItem(items[0])
            return
        }
        setCurrentItem(items[items.indexOf(currentItem) - 1])

    }

    function showNext() {
        const currentIndex = items.indexOf(currentItem)
        if (currentIndex >= items.length - 1) {
            setCurrentItem(items[items.length - 1])
            return
        }
        setCurrentItem(items[items.indexOf(currentItem) + 1])
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal
            className="media-viewer-modal"
            width="max"
            height="max"
            type="viewer"
            padding="none"
            closeAction={() => state.showMediaViewer.setValue(null)}>
            <div
                {...swipeableHandlers}
                style={{overflow: "hidden", height: "100%"}}>
                <div ref={itemsRef} style={{
                    display: "grid",
                    gap: "10px",
                    height: "100%",
                    transition: "transform 0.3s ease-in-out",
                    gridTemplateColumns: items?.map(() => "100%").join(" ")
                }}>
                    {items?.map((item, i) => {
                        return <MediaViewerItem
                            key={i}
                            data={item}
                            zoomLevel={currentItem == item ? zoomLevel : 1}
                            maxZoomLevel={maxZoomLevel}
                            onZoomToggle={setZoomLevel}/>
                    })}
                </div>
            </div>
            <ActionBar location={"modal"}>
                <ActionBarSpacer/>
                <IconButton
                    type="zoomOut"
                    disabled={zoomLevel <= 1}
                    size="small"
                    onClick={zoomOut}/>
                <IconButton
                    type="zoom"
                    disabled={zoomLevel >= maxZoomLevel}
                    size="small"
                    onClick={zoomIn}/>
                {items?.length > 1 && <>
                    <Divider style="action-bar"/>
                    <IconButton
                        type="prev"
                        size="small"
                        disabled={items.indexOf(currentItem) === 0}
                        onClick={showPrev}/>
                    <Badge
                        label={`${items.indexOf(currentItem) + 1}/${items.length}`}
                        color="white"/>
                    <IconButton
                        type="next"
                        size="small"
                        disabled={items.indexOf(currentItem) === items.length - 1}
                        onClick={showNext}/>
                    <KeyController
                        key={items.indexOf(currentItem) + "prev"}
                        enabled={true}
                        code={"ArrowLeft"}
                        onKey={showPrev}/>
                    <KeyController
                        key={items.indexOf(currentItem) + "next"}
                        enabled={true}
                        code={"ArrowRight"}
                        onKey={showNext}/>
                </>}
                <ActionBarSpacer/>
            </ActionBar>
        </Modal>
    );
}
