import {useServices} from "./useServices";
import {IMultilangString} from "../../../shared/models/submodels/IMultilangString";
import {LanguageUtils} from "../../../shared/utils/LanguageUtils";
import {LanguageType} from "../../../shared/types/LanguageType";
import {IMultilangObjectID} from "../../../shared/models/submodels/IMultilangObjectID";

/******************************************************************
 * useUtils
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function useUtils() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {language} = useServices()

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return {
        multilangString: (multilangString: IMultilangString, forceLanguage?: LanguageType, skipAlt?: boolean) => {
            return LanguageUtils.getValueFromMultilangString(multilangString, forceLanguage ?? language, skipAlt)
        },
        updateMultilangString: (multilangString: IMultilangString, value: string, forceLanguage?: LanguageType) => {
            return LanguageUtils.updateMultiLangString(multilangString, forceLanguage ?? language, value)
        },
        multilangObjectID: (multilangObjectID: IMultilangObjectID, forceLanguage?: LanguageType, skipAlt?: boolean) => {
            return LanguageUtils.getValueFromMultilangObjectID(multilangObjectID, forceLanguage ?? language, skipAlt)
        },
        updateMultilangObjectID: (multilangObjectID: IMultilangObjectID, value: string, forceLanguage?: LanguageType) => {
            return LanguageUtils.updateMultiLangObjectID(multilangObjectID, forceLanguage ?? language, value)
        }
    }

}
