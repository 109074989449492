import {FrontendServices} from "../FrontendServices";
import {CheckoutEventNames, CheckoutEventsData, initializePaddle, Paddle} from "@paddle/paddle-js";
import {FrontendConfig} from "../FrontendConfig";
import {Signal} from "@webfruits/core/dist/signal/Signal";
import {CheckoutStateType} from "../../../shared/types/CheckoutStateType";

/******************************************************************
 * FrontendPaddleJS
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class FrontendPaddleJS {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _paddleJS: Paddle
    public onCheckoutDataSignal = new Signal<CheckoutEventsData>()

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(private _frontend: FrontendServices) {
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public async init() {
        if (this._paddleJS) {
            return;
        }
        this._paddleJS = await initializePaddle({
            environment: FrontendConfig.PADDLE_ENVIRONMENT,
            token: FrontendConfig.PADDLE_CLIENT_TOKEN,
            eventCallback: (event) => {
                switch (event.name) {
                    case CheckoutEventNames.CHECKOUT_PAYMENT_INITIATED:
                        this.savePaddleCheckoutState("paying")
                        break;
                    case CheckoutEventNames.CHECKOUT_PAYMENT_FAILED:
                        this.savePaddleCheckoutState("paymentFailed")
                        break;
                    case CheckoutEventNames.CHECKOUT_LOADED:
                    case CheckoutEventNames.CHECKOUT_DISCOUNT_APPLIED:
                    case CheckoutEventNames.CHECKOUT_DISCOUNT_REMOVED:
                        this.onCheckoutDataSignal.dispatch(event.data)
                        break
                }
            },
        })
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private savePaddleCheckoutState(checkoutState: CheckoutStateType) {
        this._frontend.api.user.update({checkoutState})
    }
}
