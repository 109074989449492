/******************************************************************
 * PathUtils
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class PathUtils {

    static getMinimalPathFromName(name: string, existingPaths: string[]): string {
        if (!name) return null;
        let basePath = PathUtils.getURLFriendlyPath(name)
            .toLowerCase()
            .split("-")
            .map(part => {
                if (parseInt(part).toString() == part) {
                    return part;
                }
                return part.substring(0, 1)
            })
            .join("")
        let minimalPath = basePath;
        let increaseNumber = 1;
        let numPathWidthSameName = existingPaths.filter(path => path == ("/" + minimalPath)).length;
        while (numPathWidthSameName > 0) {
            minimalPath = basePath + ++increaseNumber;
            numPathWidthSameName = existingPaths.filter(path => path == ("/" + minimalPath)).length;
        }
        return "/" + PathUtils.getURLFriendlyPath(minimalPath);
    }

    static getUniquePathFromPath(path: string, existingPaths: string[]): string {
        if (!existingPaths.includes(path)) {
            return path;
        }
        let counter = 1;
        let newPath = `${path}-${counter}`;
        while (existingPaths.includes(newPath)) {
            counter++;
            newPath = `${path}-${counter}`;
        }
        return newPath;
    }

    static getURLFriendlyPath(value: string): string {
        if (!value) return null;
        value = value.toLowerCase()
        value = value.trim()
            .replace(/ä/gi, 'ae')
            .replace(/ö/gi, 'oe')
            .replace(/ü/gi, 'ue')
            .replace(/ß/gi, 'ss')
            .replace(/\s+/g, '-')
            .replace(/[^a-z0-9_-]/gi, '')
        return value;
    }

}
