import * as React from "react";

/******************************************************************
 * ShiftIcon
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function ShiftIcon(props: {
    scale?: number
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="add-icon">
            <svg width={20 * (props.scale ?? 1)} height={21 * (props.scale ?? 1)} viewBox="0 0 20 21" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M13.2083 17.6328H6.79172C6.45148 17.6324 6.12529 17.4971 5.88471 17.2565C5.64413 17.0159 5.5088 16.6897 5.50841 16.3495V11.9329H2.30012C2.17323 11.9329 2.0492 11.8953 1.94371 11.8247C1.83821 11.7542 1.75599 11.654 1.70744 11.5368C1.65888 11.4196 1.64617 11.2906 1.67092 11.1661C1.69567 11.0417 1.75676 10.9274 1.84647 10.8376L9.54635 3.13774C9.60591 3.07813 9.67663 3.03084 9.75447 2.99858C9.83231 2.96631 9.91574 2.94971 10 2.94971C10.0843 2.94971 10.1677 2.96631 10.2455 2.99858C10.3234 3.03084 10.3941 3.07813 10.4537 3.13774L18.1535 10.8376C18.2433 10.9274 18.3043 11.0417 18.3291 11.1661C18.3538 11.2906 18.3411 11.4196 18.2926 11.5368C18.244 11.654 18.1618 11.7542 18.0563 11.8247C17.9508 11.8953 17.8268 11.9329 17.6999 11.9329H14.4916V16.3495C14.4911 16.6897 14.3558 17.0159 14.1152 17.2564C13.8747 17.497 13.5485 17.6324 13.2083 17.6328ZM3.84914 10.6496H6.79172V16.3495H13.2083V10.6496H16.1509L10 4.49875L3.84914 10.6496Z"
                    fill="white"/>
            </svg>
        </div>
    );

}
