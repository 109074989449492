import {FrontendSpeaker} from "../../../core/services/FrontendSpeaker";
import {StateValue} from "@webfruits/toolbox/dist/state/StateValue";
import {FrontendServices} from "../../../core/FrontendServices";
import {FrontendConfig} from "../../../core/FrontendConfig";
import {SpeakerUtils} from "../../../utils/SpeakerUtils";
import {ICapiTargetData} from "../../../../shared/types/ICapiTargetData";

/******************************************************************
 * DriverAnnouncements
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class DriverAnnouncements {

    /******************************************************************
     * Properties
     *****************************************************************/

    public lapTime = new StateValue<boolean>()
    public drivenTime = new StateValue<boolean>()
    public alreadyDrivenTime = new StateValue<boolean>()
    public newBestDrivenTime = new StateValue<boolean>()
    public newBestLapTime = new StateValue<boolean>()
    public stintDrivenLaps = new StateValue<boolean>()
    public overallDrivenLaps = new StateValue<boolean>()
    public position = new StateValue<boolean>()
    public gapToNext = new StateValue<boolean>()
    public gapToLeader = new StateValue<boolean>()
    public gapToFollower = new StateValue<boolean>()
    public lapsRemaining = new StateValue<boolean>()
    public timeRemaining = new StateValue<boolean>()
    public targetScore = new StateValue<boolean>()
    public totalScore = new StateValue<boolean>()
    public newBestScore = new StateValue<boolean>()
    public targetName = new StateValue<boolean>()

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(private _services: FrontendServices) {
        this.initAnnouncements();
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public announceDrivenTime(name: string, drivenTime: number) {
        if (!this.drivenTime.getValue()) return
        let text = this.dict("speaker.text.driver.drivenTime")
        text = text.replace("{NAME}", name)
        text = text.replace("{TIME}", SpeakerUtils.getSpeakableTime({
            services: this._services,
            time: drivenTime
        }))
        this.speaker.speak(text)
    }

    public announceNewBestDrivenTime(name: string, drivenTime: number) {
        if (!this.newBestDrivenTime.getValue()) return
        let text = this.dict("speaker.text.driver.newBestDrivenTime")
        text = text.replace("{NAME}", name)
        text = text.replace("{TIME}", SpeakerUtils.getSpeakableTime({
            services: this._services,
            time: drivenTime
        }))
        this.speaker.speak(text)
    }

    public announceLapTime(name: string, lapTime: number) {
        if (!this.lapTime.getValue()) return
        let text: string
        if (!lapTime) {
            text = this.dict("speaker.text.driver.lapTimeNotAvailable")
            text = text.replace("{NAME}", name)
        } else {
            text = this.dict("speaker.text.driver.lapTime")
            text = text.replace("{NAME}", name)
            text = text.replace("{TIME}", SpeakerUtils.getSpeakableTime({
                services: this._services,
                time: lapTime
            }))
        }
        this.speaker.speak(text)
    }

    public announceNewBestLapTime(name: string, lapTime: number) {
        if (!this.newBestLapTime.getValue()) return
        let text = this.dict("speaker.text.driver.newBestLapTime")
        text = text.replace("{NAME}", name)
        text = text.replace("{TIME}", SpeakerUtils.getSpeakableTime({
            services: this._services,
            time: lapTime
        }))
        this.speaker.speak(text)
    }

    public announceStintDrivenLaps(name: string, drivenLaps: number) {
        if (!this.stintDrivenLaps.getValue()) return
        if (drivenLaps < 1) return
        let text = this.dict("speaker.text.driver.drivenLaps")
        text = text.replace("{NAME}", name)
        text = text.replace("{LAPS}", drivenLaps.toString())
        this.speaker.speak(text)
    }

    public announceOverallDrivenLaps(name: string, drivenLaps: number) {
        if (!this.overallDrivenLaps.getValue()) return
        if (drivenLaps <= 1) return
        let text = this.dict("speaker.text.driver.drivenLaps")
        text = text.replace("{NAME}", name)
        text = text.replace("{LAPS}", drivenLaps.toString())
        this.speaker.speak(text)
    }

    public announcePosition(name: string, position: number) {
        if (!this.position.getValue()) return
        let text = this.dict("speaker.text.driver.position")
        text = text.replace("{NAME}", name)
        text = text.replace("{POS}", position.toString())
        this.speaker.speak(text)
    }

    public announceGapToNext(name: string, gap: number, nextName: string) {
        if (!this.gapToNext.getValue()) return
        let text = this.dict("speaker.text.driver.gapToNext")
        text = text.replace("{NAME}", name)
        text = text.replace("{NEXT}", nextName)
        text = text.replace("{TIME}", SpeakerUtils.getSpeakableTime({
            services: this._services,
            time: gap,
            speakSecondsLabel: true,
            speakOnlyTenthSeconds: true
        }))
        this.speaker.speak(text)
    }

    public announceGapToLeader(name: string, gap: number, leaderName: string) {
        if (!this.gapToLeader.getValue()) return
        let text = this.dict("speaker.text.driver.gapToLeader")
        text = text.replace("{NAME}", name)
        text = text.replace("{LEADER}", leaderName)
        text = text.replace("{TIME}", SpeakerUtils.getSpeakableTime({
            services: this._services,
            time: gap, speakSecondsLabel: true,
            speakOnlyTenthSeconds: true
        }))
        this.speaker.speak(text)
    }

    public announceGapToFollower(name: string, gap: number, followerName: string) {
        if (!this.gapToFollower.getValue()) return
        let text = this.dict("speaker.text.driver.gapToFollower")
        text = text.replace("{NAME}", name)
        text = text.replace("{FOLLOWER}", followerName)
        text = text.replace("{TIME}", SpeakerUtils.getSpeakableTime({
            services: this._services,
            time: gap,
            speakSecondsLabel: true,
            speakOnlyTenthSeconds: true
        }))
        this.speaker.speak(text)
    }

    public announceLapsRemaining(name: string, lapsRemaining: number) {
        if (!this.lapsRemaining.getValue()) return
        let text = this.dict("speaker.text.driver.lapsRemaining")
            .replace("{LAPS}", lapsRemaining.toString())
            .replace("{NAME}", name)
        if (lapsRemaining === 1) {
            text = this.dict("speaker.text.driver.lastLap")
                .replace("{NAME}", name)
        }
        this.speaker.speak(text)
    }

    public announceAlreadyDrivenTime(name: string, drivenTime: number, laps: number) {
        if (!this.alreadyDrivenTime.getValue()) return
        let text = this.dict("speaker.text.driver.alreadyDrivenTime")
        text = text.replace("{NAME}", name)
        text = text.replace("{LAPS}", laps.toString())
        text = text.replace("{TIME}", SpeakerUtils.getSpeakableTime({
            services: this._services,
            time: drivenTime
        }))
        this.speaker.speak(text)
    }

    public announceTimeRemaining(name: string, remainingSeconds: number) {
        if (!this.timeRemaining.getValue()) return
        let text = this.dict("speaker.text.driver.timeRemaining")
            .replace("{TIME}", SpeakerUtils.getSpeakableTime({
                services: this._services,
                time: remainingSeconds,
                removeMillisecondsSeconds: true,
                removeSecondsIfNull: true,
            }))
            .replace("{NAME}", name)
        if (remainingSeconds == 0) {
            text = this.dict("speaker.text.driver.timeover")
                .replace("{NAME}", name)
        } else if (remainingSeconds <= FrontendConfig.ANNOUNCE_TIME_REMAINING_MIN_SECONDS) {
            text = this.dict("speaker.text.driver.lastMinute")
                .replace("{NAME}", name)
        }
        this.speaker.speak(text)
    }

    public announceTarget(name: string, target: ICapiTargetData) {
        if (!target) return
        const targetName = this.dict("speaker.text.targetCode_" + target.target_code)
        if (this.targetName.getValue() && this.targetScore.getValue()) {
            let text = this.dict("speaker.text.driver.target")
                .replace("{NAME}", name)
                .replace("{TARGET}", targetName)
                .replace("{SCORE}", SpeakerUtils.getSpeakableScore(target.score, this._services.state.language.getValue()))
            return this.speaker.speak(text)
        }
        if (this.targetName.getValue()) {
            let text = this.dict("speaker.text.driver.targetName")
                .replace("{NAME}", name)
                .replace("{TARGET}", targetName)
            return this.speaker.speak(text)
        }
        if (this.targetScore.getValue()) {
            let text = this.dict("speaker.text.driver.targetScore")
                .replace("{SCORE}", SpeakerUtils.getSpeakableScore(target.score, this._services.state.language.getValue()))
                .replace("{NAME}", name)
            return this.speaker.speak(text)
        }
    }

    public announceTotalScore(name: string, totalScore: number) {
        if (!this.totalScore.getValue()) return
        let text = this.dict("speaker.text.driver.totalScore")
            .replace("{SCORE}", SpeakerUtils.getSpeakableScore(totalScore, this._services.state.language.getValue()))
            .replace("{NAME}", name)
        this.speaker.speak(text)
    }

    public announceNewBestScore(name: string, score: number) {
        if (!this.newBestScore.getValue()) return
        if (!score || score <= 0) return
        let text = this.dict("speaker.text.driver.newBestScore")
            .replace("{SCORE}", SpeakerUtils.getSpeakableScore(score, this._services.state.language.getValue()))
            .replace("{NAME}", name)
        this.speaker.speak(text)
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initAnnouncements() {
        SpeakerUtils.initAnnoucementSetting(this.drivenTime, "driver.drivenTime")
        SpeakerUtils.initAnnoucementSetting(this.newBestDrivenTime, "driver.newBestDrivenTime")
        SpeakerUtils.initAnnoucementSetting(this.alreadyDrivenTime, "driver.alreadyDrivenTime")
        SpeakerUtils.initAnnoucementSetting(this.lapTime, "driver.lapTime")
        SpeakerUtils.initAnnoucementSetting(this.newBestLapTime, "driver.newBestLapTime")
        SpeakerUtils.initAnnoucementSetting(this.stintDrivenLaps, "driver.stintDrivenLaps")
        SpeakerUtils.initAnnoucementSetting(this.overallDrivenLaps, "driver.overallDrivenLaps")
        SpeakerUtils.initAnnoucementSetting(this.position, "driver.position")
        SpeakerUtils.initAnnoucementSetting(this.gapToNext, "driver.gapToNext")
        SpeakerUtils.initAnnoucementSetting(this.gapToLeader, "driver.gapToLeader")
        SpeakerUtils.initAnnoucementSetting(this.gapToFollower, "driver.gapToFollower")
        SpeakerUtils.initAnnoucementSetting(this.lapsRemaining, "driver.lapsRemaining")
        SpeakerUtils.initAnnoucementSetting(this.timeRemaining, "driver.timeRemaining")
        SpeakerUtils.initAnnoucementSetting(this.totalScore, "driver.totalScore")
        SpeakerUtils.initAnnoucementSetting(this.newBestScore, "driver.newBestScore")
        SpeakerUtils.initAnnoucementSetting(this.targetScore, "driver.targetScore")
        SpeakerUtils.initAnnoucementSetting(this.targetName, "driver.targetName")
    }

    private get speaker(): FrontendSpeaker {
        return this._services.speaker
    }

    private dict(key: string): string {
        return this._services.dict.get(key)
    }


}
