import * as React from "react";
import {useEffect, useState} from "react";
import {Modal} from "../Modal";
import {Headline} from "../../../ui/text/headings/Headline";
import {useServices} from "../../../hooks/useServices";
import {Table} from "../../../ui/table/Table";
import {IArticleData} from "../../../../../shared/models/IArticleData";
import {ITableColumnDefinition} from "../../../ui/table/ITableColumnDefinition";
import {ContentLayout} from "../../../ui/layout/content/ContentLayout";

/******************************************************************
 * SelectArticleModal
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function SelectArticleModal(props: {
    onSelected: (articleID: string) => void
    onClose?: () => void
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {api, dict} = useServices()

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [articles, setArticles] = useState<IArticleData[]>([])

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        loadArticles()
    }, [])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function loadArticles() {
        const articles = await api.article.getArticles()
        setArticles(articles)
    }

    function columnsDefinition(): ITableColumnDefinition<IArticleData>[] {
        return [{
            dataKey: "language",
            type: "text-bold",
            size: "50px"
        }, {
            columnName: dict("article.select.column.title"),
            dataKey: "title",
            type: "text-bold"
        }, {
            columnName: dict("article.select.column.subTitle"),
            dataKey: "subTitle",
            type: "text"
        }, {
            columnName: dict("article.select.column.abstract"),
            dataKey: "abstract",
            type: "text"
        }]
    }

    function onArticleClicked(articleData: IArticleData) {
        props.onSelected(articleData._id)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal className="select-article-modal" width="large" closeAction={props.onClose}>
            <ContentLayout alignContent="start">
                <Headline text={dict("article.select.title")} style="modal"/>
                <Table<IArticleData>
                    sortKey="title"
                    showFilter={true}
                    rowsData={articles}
                    visibleRows={6}
                    onRowClick={onArticleClicked}
                    columnsDefinition={columnsDefinition()}/>
            </ContentLayout>
        </Modal>
    );

}
