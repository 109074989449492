import * as React from "react";
import {useEffect, useState} from "react";
import {ContentLayout} from "../../../../ui/layout/content/ContentLayout";
import {Modal} from "../../Modal";
import {useServices} from "../../../../hooks/useServices";
import {DriverLicenseType} from "../../../../../../shared/types/DriverLicenseType";
import {Headline} from "../../../../ui/text/headings/Headline";
import {SubscriptionChangePreviewHeader} from "./header/SubscriptionChangePreviewHeader";
import {InfoText} from "../../../../ui/text/infotext/InfoText";
import {ComponentInitializer} from "../../../../ui/utils/init/ComponentInitializer";
import {SubscriptionChangePreviewSummary} from "./header/SubscriptionChangePreviewSummary";
import {ISubscriptionChangePreviewData} from "../../../../../../shared/types/ISubscriptionChangePreviewData";
import {PaddleSandboxModeInfo} from "../../../../ui/info/sandbox/PaddleSandboxModeInfo";
import {DriverLicenseUtils} from "../../../../../../shared/utils/DriverLicenseUtils";
import {useAuthUser} from "../../../../hooks/useAuthUser";
import {LabelButton} from "../../../../ui/buttons/label/LabelButton";
import {TimeUtils} from "../../../../../../shared/utils/TimeUtils";

/******************************************************************
 * SubscriptionChangePreviewModal
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function SubscriptionChangePreviewModal() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, state, api} = useServices();
    const {authUserValidDriverLicense, authUserSubscription} = useAuthUser();

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [requestedLicense] = useState<DriverLicenseType>(state.showSubscriptionChangePreview.getValue().requestedLicense)
    const [errorMessage, setErrorMessage] = useState<string>(null)
    const [previewData, setPreviewData] = useState<ISubscriptionChangePreviewData>(null)
    const [busy, setBusy] = useState(true)

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        requestPreview()
    }, [])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    async function requestPreview() {
        const previewData = await api.paddle.updateSubscription(requestedLicense, true) as ISubscriptionChangePreviewData
        setPreviewData(previewData)
        if (!previewData) {
            setErrorMessage(dict("plan.change.preview.error"))
        }
        setBusy(false)
    }

    function isDowngrade(): boolean {
        return DriverLicenseUtils.isDowngrade(requestedLicense, authUserValidDriverLicense)
    }

    function isUpgrade(): boolean {
        return DriverLicenseUtils.isUpgrade(requestedLicense, authUserValidDriverLicense)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal
            className="subscription-change-preview-modal"
            width="medium"
            closeAction={() => state.showSubscriptionChangePreview.setValue(null)}>
            <Headline text={dict("plan.change.preview.title")} style="modal"/>
            <ContentLayout>
                {isUpgrade() && <>
                    <PaddleSandboxModeInfo/>
                    <SubscriptionChangePreviewHeader
                        requestedLicense={requestedLicense}/>
                    <ComponentInitializer isPropertyAvailable={!busy}>
                        {errorMessage
                            ? <InfoText text={errorMessage} style="error"/>
                            : <SubscriptionChangePreviewSummary
                                requestedLicense={requestedLicense}
                                data={previewData}/>}
                    </ComponentInitializer>
                </>}
                {isDowngrade() && <>
                    <InfoText
                        text={dict("plan.change.downgrade.info")
                            .replaceAll("{DATE}", TimeUtils.formatDate(authUserSubscription.nextBilledAt, false, true))}/>
                    <LabelButton
                        label={dict("plan.change.downgrade.accept.button.label")}
                        style="primary"
                        onClick={() => state.showConfirm.setValue({type: "cancelSubscription"})}/>
                </>}
            </ContentLayout>
        </Modal>
    );

}
