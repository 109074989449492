import * as React from "react";
import {useEffect, useState} from "react";
import {ITabbedContentChildProps} from "../../../../ui/layout/tabbed/ITabbedContentChildProps";
import {ContentLayout} from "../../../../ui/layout/content/ContentLayout";
import {IGroupData} from "../../../../../../shared/models/IGroupData";
import {Map, MapProjection, MapStyle} from "../../../../ui/map/Map";
import {IMapMarkerData} from "../../../../ui/map/marker/IMapMarkerData";
import {MarkerFactory} from "../../../../ui/map/marker/MarkerFactory";

/******************************************************************
 * GroupFinderMap
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function GroupFinderMap(props: {
    tabbedContent?: ITabbedContentChildProps
    initialZoomLevel?: number
    projection?: MapProjection
    style?: MapStyle
    publicGroups: IGroupData[]
    fullHeight?: boolean
    onTouchStart?: () => void
    onTouchEnd?: () => void
}) {

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [groupMarkers, setGroupMarkers] = useState<IMapMarkerData[]>([])

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        const groups = props.publicGroups?.filter(group => {
            return group.location?.latitude && group.location?.longitude
        })
        setGroupMarkers(groups?.map(group => MarkerFactory.createGroupMarker(group)))
    }, [props.publicGroups])

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            fullHeight={props.fullHeight ? true : null}
            className="group-finder-map">
            <Map
                projection={props.projection}
                style={props.style}
                maxZoomLevel={12}
                initialZoomLevel={props.initialZoomLevel}
                height={props.fullHeight ? "full" : null}
                onTouchStart={props.onTouchStart}
                onTouchEnd={props.onTouchEnd}
                markers={groupMarkers}/>
        </ContentLayout>
    );

}
