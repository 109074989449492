import * as React from "react";
import {Modal} from "../Modal";
import {Headline} from "../../../ui/text/headings/Headline";
import {useServices} from "../../../hooks/useServices";
import {AvailableDrivers} from "../../main/event/settings/drivers/lists/AvailableDrivers";

/******************************************************************
 * SelectDriversModal
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function SelectDriversModal() {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {state, dict} = useServices();

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function close() {
        state.showSelectDrivers.setValue(false);
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal
            closeAction={close}
            width="medium"
            position="global"
            className="select-drivers-modal">
            <Headline text={dict("drivers.add.title")} style="modal"/>
            <AvailableDrivers />
        </Modal>
    );

}
