import * as React from "react";

/******************************************************************
 * ActionBarSpacer
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function ActionBarSpacer() {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="action-bar-spacer" />
    );

}
