import * as React from "react";
import {useEffect, useRef, useState} from "react";

/******************************************************************
 * Scroller
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export type ScrollBoxFooterSpace = "none" | "overscroll" | "actionbar"

export function ScrollBox(props: {
    children: React.ReactNode | React.ReactNode[] | null
    footerSpace?: ScrollBoxFooterSpace
    scrollX?: boolean
    scrollY?: boolean
    className?: string
    useFlexX?: boolean
    gapFlexX?: "small"
    useFadeOut?: boolean
}) {

    /* ----------------------------------------------------------------
     * REFS
     * --------------------------------------------------------------*/

    const ref = useRef<HTMLDivElement>()

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const scrollCheckTimeoutID = useRef<number>()
    const [stopTouchProgagation, setStopTouchProgagation] = useState(false)
    const [fadeOutStyle, setFadeOutStyle] = useState<"left" | "right" | "both" | null>(null)
    const [isTouching, setIsTouching] = useState(false)

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        updateFadeOuts()
    }, [ref.current])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function keepScrollable() {
        if (isTouching) return
        const scrollTarget = ref?.current
        clearTimeout(scrollCheckTimeoutID.current)
        scrollCheckTimeoutID.current = window.setTimeout(() => {
            if (scrollTarget.scrollTop <= 0) {
                scrollTarget.scrollTop = 1
            }
            if (scrollTarget.scrollTop >= scrollTarget.scrollHeight - scrollTarget.clientHeight) {
                scrollTarget.scrollTop = scrollTarget.scrollHeight - scrollTarget.clientHeight - 1
            }
        }, 100)
    }

    function updateFadeOuts() {
        if (!props.useFadeOut) {
            setFadeOutStyle(null)
            return
        }
        const scrollTarget = ref.current
        const isScrolledToLeft = scrollTarget.scrollLeft > 0
        const isScrolledToRight = scrollTarget.scrollLeft < scrollTarget.scrollWidth - scrollTarget.clientWidth
        if (isScrolledToLeft && isScrolledToRight) {
            return setFadeOutStyle("both")
        }
        if (isScrolledToLeft) {
            return setFadeOutStyle("left")
        }
        if (isScrolledToRight) {
            return setFadeOutStyle("right")
        }
        setFadeOutStyle(null)
    }

    /* ----------------------------------------------------------------
 	 * EVENTS
 	 * --------------------------------------------------------------*/

    function onTouchStarted(e: React.TouchEvent<HTMLDivElement>) {
        setIsTouching(true)
        const stop = ref.current.scrollLeft > 0
        setStopTouchProgagation(stop)
        if (!stop) return
        e.stopPropagation()
    }

    function onTouchMoved(e: React.TouchEvent<HTMLDivElement>) {
        if (!stopTouchProgagation) return
        e.stopPropagation()
    }

    function onTouchEnd() {
        setIsTouching(false)
        keepScrollable()
    }

    function onScroll() {
        keepScrollable()
        updateFadeOuts()
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            ref={ref}
            onTouchStartCapture={onTouchStarted}
            onTouchMoveCapture={onTouchMoved}
            onTouchEndCapture={onTouchEnd}
            onScroll={onScroll}
            className={"scrollbox" + (props.className ? " " + props.className : "")}
            data-use-flex-x={props.useFlexX}
            data-gap-flex-x={props.gapFlexX}
            data-footer-space={props.footerSpace}
            data-scroll-x={props.scrollX}
            data-scroll-y={props.scrollY}
            data-fade-out-style={fadeOutStyle}>
            {props.children}
        </div>
    );

}
