import * as React from "react";
import {useServices} from "../../../../hooks/useServices";
import {Input} from "../input/Input";
import {TrackMinLapTimeValidation} from "../../../../../../shared/validation/TrackMinLapTimeValidation";

/******************************************************************
 * TrackMinLapTimeInput
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function TrackMinLapTimeInput(props: {
    editable: boolean
    lapTime?: string
    action?: (value: string) => Promise<Response>
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict} = useServices();

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Input
            type="number"
            label={dict("session.minLapTime")}
            minNumber={1}
            maxNumber={999}
            readonly={!props.editable}
            helpTopic="track.minLapTime"
            showInvalid={true}
            placeholder={dict("track.minLapTime.placeholder")}
            defaultValue={props.lapTime}
            pattern={TrackMinLapTimeValidation.REGEXP.source}
            action={props.action}/>
    );

}
