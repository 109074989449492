import * as React from "react";
import {useGroupState} from "../../../../hooks/useGroupState";
import {useAuthUser} from "../../../../hooks/useAuthUser";
import {useRouteOwner} from "../../../../hooks/useRouteOwner";
import {IStintData} from "../../../../../../shared/models/IStintData";
import {StintContextMenu} from "../../../context/stint/StintContextMenu";

/******************************************************************
 * TableStintMenuCell
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function TableStintMenuCell(props: {
    stint: IStintData
}) {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {authUserID} = useAuthUser()
    const {isRouteOwnerAuthUser} = useRouteOwner()
    const {isGroupAdmin} = useGroupState()

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function showContextMenu(): boolean {
        if (!authUserID) {
            return false
        }
        if (isRouteOwnerAuthUser || isGroupAdmin) {
            return true
        }
        return (props.stint.user?._id ?? props.stint.user) === authUserID
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="table-stintmenu-cell">
            {showContextMenu() && <StintContextMenu
                stint={props.stint}/>}
        </div>
    );

}
