import * as React from "react";
import {useEffect, useState} from "react";

/******************************************************************
 * useElementSize
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function useElementSize(elementRef: React.RefObject<HTMLElement>) {

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [width, setWidth] = useState<number>(elementRef?.current?.clientWidth)
    const [height, setHeight] = useState<number>(elementRef?.current?.clientHeight)
    const [scrollWidth, setScrollWidth] = useState<number>(elementRef?.current?.scrollWidth)

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        const observer = new ResizeObserver(entries => {
            setScrollWidth(entries[0].target.scrollWidth)
            setWidth(entries[0].target.clientWidth);
            setHeight(entries[0].target.clientHeight);
        });
        if (elementRef.current) {
            observer.observe(elementRef.current);
        }
        return () => {
            if (elementRef.current) {
                observer.unobserve(elementRef.current);
            }
        };
    }, [elementRef?.current]);

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return [width, height, scrollWidth]

}
