import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {Modal} from "../../Modal";
import {Headline} from "../../../../ui/text/headings/Headline";
import {useServices} from "../../../../hooks/useServices";
import {ContentLayout} from "../../../../ui/layout/content/ContentLayout";
import {DriverLicenseType} from "../../../../../../shared/types/DriverLicenseType";
import ReactMarkdown from "react-markdown";
import {ComponentInitializer} from "../../../../ui/utils/init/ComponentInitializer";
import {IPaddleDriverLicensePlanData} from "../../../../../../shared/models/IPaddleDriverLicensePlanData";
import {InfoText} from "../../../../ui/text/infotext/InfoText";
import {useAuthUser} from "../../../../hooks/useAuthUser";
import {CheckoutCustomer} from "@paddle/paddle-js/types/checkout/customer";
import {IPaddleCustomerAddressData} from "../../../../../../shared/types/IPaddleCustomerAddressData";
import {useMobileStyle} from "../../../../hooks/useMobileStyle";
import {SubscriptionCheckoutSummary} from "./item/SubscriptionCheckoutSummary";
import {usePaddleJS} from "../../../../hooks/usePaddleJS";
import {PaddleCheckoutType} from "../../../../../utils/types/PaddleCheckoutType";
import {CheckoutOpenOptions} from "@paddle/paddle-js";
import {PaddleSandboxModeInfo} from "../../../../ui/info/sandbox/PaddleSandboxModeInfo";

/******************************************************************
 * SubscriptionCheckoutModal
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function SubscriptionCheckoutModal() {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const contentRootRef = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, state, api, language} = useServices();
    const {checkoutData, paddleAvailable} = usePaddleJS()
    const [isMobileStyle] = useMobileStyle(contentRootRef, 680)
    const {
        authUserExists,
        authUserMail,
        authUserPaddleCustomerID,
        authUserSubscription,
        authUserDiscountAlreadyUsed,
        authUserPaddleCheckoutState,
        authUserUseSubscription
    } = useAuthUser()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [checkoutType] = useState<PaddleCheckoutType>(state.showSubscriptionCheckout.getValue()?.type)
    const [licenseData, setLicenseData] = useState<IPaddleDriverLicensePlanData>()
    const [errorMessage, setErrorMessage] = useState<string>()

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        if (!authUserExists) {
            setErrorMessage(dict("plan.checkout.no.user.found.error"))
            return
        }
        if (!authUserUseSubscription) {
            setErrorMessage(dict("plan.checkout.subscription.not.necessary"))
            return
        }
        if (authUserPaddleCheckoutState === "paying") {
            setErrorMessage(dict("plan.checkout.payment.in.progress"))
            return
        }
        switch (checkoutType) {
            case "pay":
                if (!authUserSubscription?.subscriptionID) {
                    setErrorMessage(dict("plan.update.paymentmethode.no.subscription.found.error"))
                    return
                }
                break
            case "subscribe":
                if (authUserSubscription?.subscriptionID) {
                    setErrorMessage(dict("plan.checkout.subscription.already.exists.error"))
                    return
                }
                break
        }
        if (!paddleAvailable) return
        init()
    }, [paddleAvailable])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    async function init() {
        const licenseData = await loadAvailableLicences()
        if (!licenseData || !licenseData.priceID) {
            setErrorMessage(dict("plan.checkout.no.product.found.error"))
            return
        }
        let transactionID: string
        switch (checkoutType) {
            case "pay":
                transactionID = await api.paddle.getSubscriptionTransactionID(false)
                if (!transactionID) {
                    setErrorMessage(dict("plan.update.paymentmethode.transaction.id.missing"))
                    return
                }
                break
        }
        const customerAddress = await api.paddle.getCustomerAddress()
        initCheckout(licenseData, customerAddress, transactionID)
        setLicenseData(licenseData)
    }

    async function loadAvailableLicences(): Promise<IPaddleDriverLicensePlanData> {
        const licenses = await api.paddle.getDriverLicensePlans()
        return licenses.find(license => license.type === licenseType())
    }

    function initCheckout(
        licenseData: IPaddleDriverLicensePlanData,
        customerAddress: IPaddleCustomerAddressData,
        transactionID: string) {
        let customer: CheckoutCustomer;
        if (authUserPaddleCustomerID) customer = {id: authUserPaddleCustomerID}
        else customer = {email: authUserMail}
        if (customer && customerAddress) {
            customer.address = {id: customerAddress.id}
        }
        // @ts-ignore
        const config: CheckoutOpenOptions = {
            customer,
            settings: {
                displayMode: "inline",
                theme: "dark",
                locale: language,
                showAddTaxId: false,
                allowLogout: false,
                showAddDiscounts: !authUserDiscountAlreadyUsed,
                frameTarget: "paddle-checkout-container",
                frameStyle: "position: static; width: 100%; min-width: 312px; background-color: transparent; border: none;"
            }
        }
        switch (checkoutType) {
            case "pay":
                config.transactionId = transactionID
                break
            case "subscribe":
                config.items = [{
                    priceId: licenseData.priceID,
                    quantity: 1
                }]
                break
        }
        window.Paddle.Checkout.open(config);
    }

    function modalTitle() {
        switch (checkoutType) {
            case "pay":
                return dict("plan.checkout.pay.title")
            case "subscribe":
                return <ReactMarkdown>
                    {dict("plan.checkout.title").replaceAll("{DRIVER_LICENSE_TYPE}", dict("plan.type." + licenseType() + ".title"))}
                </ReactMarkdown>
        }

    }

    function hasRequiredCheckoutItem(): boolean {
        if (checkoutData?.items.length > 1) return false
        return !!checkoutData?.items.find(item => item.price_id === licenseData.priceID)
    }

    function licenseType(): DriverLicenseType {
        switch (checkoutType) {
            case "pay":
                return authUserSubscription.driverLicense
            case "subscribe":
                return state.showSubscriptionCheckout.getValue()?.requestedLicenseType
        }
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal
            className="subscription-checkout-modal"
            width="extra-large"
            minHeight="medium"
            closeAction={() => state.showSubscriptionCheckout.setValue(null)}>
            <Headline
                text={modalTitle()}
                style="modal"/>
            <ContentLayout>
                <ContentLayout
                    framed={true}
                    justifyItems="center"
                    gap="small">
                    <Headline
                        text={dict("plan.checkout.paddle.service.title")}
                        style="h5-underlined"
                        align="center"/>
                    <InfoText
                        text={dict("plan.checkout.paddle.service.info")}
                        size="small"
                        align="center"/>
                </ContentLayout>
                <ComponentInitializer isPropertyAvailable={!!checkoutData || !!errorMessage}>
                    {errorMessage &&
                        <ContentLayout justifyItems="center">
                            <InfoText
                                text={errorMessage}
                                align="center"
                                style="error"/>
                        </ContentLayout>}
                    {!errorMessage &&
                        <ContentLayout columns={isMobileStyle ? 1 : 2} ref={contentRootRef}>
                            {hasRequiredCheckoutItem() &&
                                <ContentLayout framed={true}>
                                    <PaddleSandboxModeInfo/>
                                    <SubscriptionCheckoutSummary
                                        type={licenseData.type}
                                        checkoutData={checkoutData}/>
                                </ContentLayout>}
                            <ContentLayout framed={true} className="paddle-checkout-container"/>
                        </ContentLayout>}
                </ComponentInitializer>
            </ContentLayout>
        </Modal>
    );

}
