import * as React from "react";
import {ITableColumnDefinition} from "../ITableColumnDefinition";
import {TableHeaderCell} from "../cells/header/TableHeaderCell";
import {SortDirectionType} from "../../../../utils/types/SortDirectionType";
import {TableSessionsHeaderCell} from "../cells/header/TableSessionsHeaderCell";

/******************************************************************
 * TableHeader
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function TableHeader(props: {
    gridTemplateColumns: string
    activeSortKey: string
    activeSortDirection: SortDirectionType
    columnsDefinition: ITableColumnDefinition<any>[]
    hideLowPriorityColumns: boolean
    onHeaderCellClick: (sortKey: string, sortDirection: SortDirectionType) => void
    minWidth?: number
    disabled?: boolean
}) {

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function getKey(i: number): string {
        return i + props.columnsDefinition?.map(e => e.size)?.join("") ?? ""
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <header
            className="table-header"
            data-disabled={props.disabled}
            style={{
                gridTemplateColumns: props.gridTemplateColumns,
                width: props.minWidth
            }}>
            {props.columnsDefinition.map((colDef, i) => {
                if (colDef.size === null
                    || (colDef.priority === "low" && props.hideLowPriorityColumns)) {
                    return null;
                }
                switch (colDef.type) {
                    case "scoreboard-sessions":
                        return <TableSessionsHeaderCell
                            key={getKey(i)}
                            columnDefinition={colDef}/>
                    default:
                        return <TableHeaderCell
                            key={getKey(i)}
                            columnDefinition={colDef}
                            activeSortKey={props.activeSortKey}
                            activeSortDirection={props.activeSortDirection}
                            onClick={props.onHeaderCellClick}/>
                }
            })}
        </header>
    );

}
