import * as React from "react";
import {IAcademyChapterData} from "../../../../../../shared/models/submodels/IAcademyChapterData";
import {IconButton} from "../../../../ui/buttons/icon/IconButton";
import {Divider} from "../../../../ui/utils/divider/Divider";
import {ActionBar} from "../../../../ui/bar/ActionBar";
import {useServices} from "../../../../hooks/useServices";

/******************************************************************
 * ChapterActionBar
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function ChapterActionBar(props: {
    currentChapterData: IAcademyChapterData
    chaptersData: IAcademyChapterData[]
    onAddChapter: () => void
    onDeleteChapter: () => void
    onEditChapter: () => void
    onUpdatedPosition: () => void
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {api} = useServices()

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function moveChapterUp() {
        const response = await api.academy.updateChapter(props.currentChapterData._id, {
            position: computeNewPrevChapterPosition()
        })
        if (response.status === 200) {
            props.onUpdatedPosition()
        }
    }

    async function moveChapterDown() {
        const response = await api.academy.updateChapter(props.currentChapterData._id, {
            position: computeNewNextChapterPosition()
        })
        if (response.status === 200) {
            props.onUpdatedPosition()
        }
    }

    function computeNewPrevChapterPosition() {
        const chapters = props.chaptersData
        const currentChapter = props.currentChapterData
        const prevChapter = chapters[chapters.indexOf(currentChapter) - 1]
        const prevPrevChapter = chapters[chapters.indexOf(currentChapter) - 2]
        if (prevPrevChapter) {
            const delta = (prevChapter.position - prevPrevChapter.position) / 2
            return prevChapter.position - delta
        }
        if (prevChapter) {
            return prevChapter.position - 0.5
        }
        return currentChapter.position
    }

    function computeNewNextChapterPosition() {
        const chapters = props.chaptersData
        const currentChapter = props.currentChapterData
        const nextChapter = chapters[chapters.indexOf(currentChapter) + 1]
        const nextNextChapter = chapters[chapters.indexOf(currentChapter) + 2]
        if (nextNextChapter) {
            const delta = (nextNextChapter.position - nextChapter.position) / 2
            return nextChapter.position + delta
        }
        if (nextChapter) {
            return nextChapter.position + 0.5
        }
        return currentChapter.position
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ActionBar location="splitview-sidebar">
            <IconButton
                size="small"
                type="add"
                onClick={props.onAddChapter}/>
            {props.currentChapterData && <>
                <Divider style="action-bar"/>
                <IconButton
                    size="small"
                    type="edit"
                    onClick={props.onEditChapter}/>
                <IconButton
                    size="small"
                    type="up"
                    disabled={props.chaptersData?.[0]?._id === props.currentChapterData?._id}
                    onClick={moveChapterUp}/>
                <IconButton
                    size="small"
                    type="down"
                    disabled={props.chaptersData?.[props.chaptersData?.length - 1]?._id === props.currentChapterData?._id}
                    onClick={moveChapterDown}/>
                <Divider style="action-bar"/>
                <IconButton
                    size="small"
                    type="delete"
                    onClick={props.onDeleteChapter}/>
            </>}
        </ActionBar>
    );

}
