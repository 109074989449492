import * as React from "react";
import {useEffect, useState} from "react";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {useEventState} from "../../../../../hooks/useEventState";
import {Table} from "../../../../../ui/table/Table";
import {ITableColumnDefinition} from "../../../../../ui/table/ITableColumnDefinition";
import {ISessionData} from "../../../../../../../shared/models/ISessionData";
import {useServices} from "../../../../../hooks/useServices";
import {FrontendRoute} from "../../../../../../../shared/routes/FrontendRoute";

/******************************************************************
 * EventLatestSessionsInfo
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function EventLatestSessionsInfo() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {router, dict} = useServices()
    const {eventChildren, eventOwnerType, eventOwner, eventPath} = useEventState();

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [tableData, setTableData] = useState<ISessionData[]>([])
    const [isTraining] = useState<boolean>(eventOwnerType == "user")

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        const sessions = eventChildren?.sessions ? [...eventChildren.sessions] : []
        sessions.sort((a, b) => {
            if (!a.latestStintActivityTimestamp) return 1
            if (!b.latestStintActivityTimestamp) return -1
            const sessionALatestActivity = new Date(a.latestStintActivityTimestamp).getTime()
            const sessionBLatestActivity = new Date(b.latestStintActivityTimestamp).getTime()
            return sessionBLatestActivity - sessionALatestActivity
        })
        setTableData(sessions)
    }, [eventChildren?.sessions])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function columnsDefinition(): ITableColumnDefinition<ISessionData>[] {
        return [{
            type: "text-bold",
            dataKey: "name",
        }, {
            type: "state",
            dataKey: "state",
            size: isTraining ? null : "80px",
            align: "right"
        }];
    }

    function showSession(sessionData: ISessionData) {
        router.showRoute(FrontendRoute.EVENT_SESSION(eventOwnerType, eventOwner.path, eventPath, sessionData.path))
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout className="event-sessions-info">
            <Table<ISessionData>
                rowsData={tableData}
                sortKey="latestStintActivityTimestamp"
                columnsDefinition={columnsDefinition()}
                showHeader={false}
                showPagination={false}
                visibleRows={5}
                noDataInfo={dict("event.sessions.empty.info")}
                onRowClick={showSession}
                style="standard-compact"/>
        </ContentLayout>
    );

}
