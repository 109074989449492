import {useEffect, useRef, useState} from "react";
import {useElementSize} from "./useElementSize";

/******************************************************************
 * useMainframeSystemContentSize
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function useMainframeSystemContentStyles() {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const mainframeSystemContentRef = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const [width] = useElementSize(mainframeSystemContentRef)

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [paddingRight, setPaddingRight] = useState<number>(0)
    const [paddingLeft, setPaddingLeft] = useState<number>(0)

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        mainframeSystemContentRef.current = document.getElementsByClassName("mainframe-system-content")[0] as HTMLDivElement
    }, []);

    useEffect(() => {
        if (!mainframeSystemContentRef.current) return
        const computedStyles = window.getComputedStyle(mainframeSystemContentRef.current)
        setPaddingRight(parseInt(computedStyles.paddingRight))
        setPaddingLeft(parseInt(computedStyles.paddingLeft))
    }, [width])

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return {
        mainFramePaddingRight: paddingRight,
        mainFramePaddingLeft: paddingLeft
    }

}
