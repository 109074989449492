import * as React from "react";

/******************************************************************
 * SearchIcon
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function SearchIcon(props: {
    scale?: number
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="search-icon">
            <svg width={18 * (props.scale ?? 1)} height={17 * (props.scale ?? 1)} viewBox="0 0 18 17" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M17.2676 14.699L13.9572 11.3887C13.8078 11.2393 13.6053 11.1562 13.3928 11.1562H12.8516C13.768 9.98418 14.3125 8.50996 14.3125 6.90625C14.3125 3.09121 11.2213 0 7.40625 0C3.59121 0 0.5 3.09121 0.5 6.90625C0.5 10.7213 3.59121 13.8125 7.40625 13.8125C9.00996 13.8125 10.4842 13.268 11.6562 12.3516V12.8928C11.6562 13.1053 11.7393 13.3078 11.8887 13.4572L15.199 16.7676C15.5111 17.0797 16.0158 17.0797 16.3246 16.7676L17.2643 15.8279C17.5764 15.5158 17.5764 15.0111 17.2676 14.699ZM7.40625 11.1562C5.05879 11.1562 3.15625 9.25703 3.15625 6.90625C3.15625 4.55879 5.05547 2.65625 7.40625 2.65625C9.75371 2.65625 11.6562 4.55547 11.6562 6.90625C11.6562 9.25371 9.75703 11.1562 7.40625 11.1562Z"
                    fill="white"/>
            </svg>

        </div>
    );

}
