import * as React from "react";
import {useServices} from "../../../hooks/useServices";
import {DriftClubLogo} from "../../../logo/DriftClubLogo";
import {useDeviceInfo} from "../../../hooks/useDeviceInfo";
import {HomeActionBar} from "../../../ui/bar/home/HomeActionBar";
import {Article} from "../../../ui/article/Article";
import {ContentLayout} from "../../../ui/layout/content/ContentLayout";
import {Spacer} from "../../../ui/utils/spacer/Spacer";
import {HomeMainFooter} from "./footer/HomeMainFooter";
import {DriverLicensePlans} from "../../../ui/plans/DriverLicensePlans";
import {Headline} from "../../../ui/text/headings/Headline";
import {InfoText} from "../../../ui/text/infotext/InfoText";
import {FrontendConfig} from "../../../../core/FrontendConfig";

/******************************************************************
 * HomeMain
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function HomeMain() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict} = useServices();
    const {isMobile} = useDeviceInfo();

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return <>
        <ContentLayout
            className="home-main"
            justifyItems="center">
            {isMobile &&
                <DriftClubLogo minimized={false}/>}
            <Spacer height="extra-large"/>
            <Article
                articleID={dict("article.id.homepage")}
                hideTitle={true}
                hideSubTitle={true}
                style="home"/>
            {FrontendConfig.PADDLE_ENABLED_PER_DEFAULT &&
                <ContentLayout size="content-wide">
                    <Spacer height="extra-large"/>
                    <Headline text={dict("plans.title")} style="h1"/>
                    <InfoText
                        text={dict("plans.info")}
                        style="abstract"/>
                    <DriverLicensePlans showSignupButton={true}/>
                    <Spacer height="extra-large"/>
                </ContentLayout>}
            <HomeMainFooter/>
        </ContentLayout>
        <HomeActionBar/>
    </>

}
