import * as React from "react";

/******************************************************************
 * CheckIocn
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function CheckIcon(props: {
    scale?: number
}) {
    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="check-icon">
            <svg width={14 * (props.scale ?? 1)} height={11 * (props.scale ?? 1)} viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.9251 10.4138L0.719818 5.98715C0.467173 5.7212 0.467173 5.29001 0.719818 5.02404L1.63474 4.06093C1.88739 3.79496 2.29705 3.79496 2.5497 4.06093L5.38257 7.04288L11.4503 0.655847C11.7029 0.389905 12.1126 0.389905 12.3652 0.655847L13.2802 1.61895C13.5328 1.8849 13.5328 2.31609 13.2802 2.58206L5.84005 10.4138C5.58738 10.6797 5.17774 10.6797 4.9251 10.4138V10.4138Z" fill="white"/>
            </svg>
        </div>
    );

}
