import * as React from "react";
import {useEffect, useState} from "react";
import {ISimulatorDriverData} from "../drivers/card/ISimulatorDriverData";
import {useServices} from "../../../../../hooks/useServices";
import {PromisedDelay} from "@webfruits/toolbox/dist/timer/PromisedDelay";

/******************************************************************
 * useSimulatorStore
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function useSimulatorStore() {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {signal} = useServices()

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [gameID, setGameID] = useState<string>()
    const [timeScale, setTimeScale] = useState<number>()
    const [minLapTime, setMinLapTime] = useState<number>()
    const [maxLapTime, setMaxLapTime] = useState<number>()
    const [sectors, setSectors] = useState<number>()
    const [sectorTargetCodes, setSectorTargetCodes] = useState<string>()
    const [finishFailureRate, setFinishFailureRate] = useState<number>()
    const [sectorsFailureRate, setSectorsFailureRate] = useState<number>()
    const [simulationDrivers, setSimulationDrivers] = useState<ISimulatorDriverData[]>()

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        updateStates()
        return () => {
            signal.onAdminSimulatorDataChanged.remove(updateStates)
        }
    }, [])

    useEffect(() => {
        setValue("gameID", gameID)
        setValue("timeScale", timeScale)
        setValue("minLapTime", minLapTime)
        setValue("maxLapTime", maxLapTime)
        setValue("sectors", sectors)
        setValue("sectorTargetCodes", sectorTargetCodes)
        setValue("finishFailureRate", finishFailureRate)
        setValue("sectorsFailureRate", sectorsFailureRate)
        signal.onAdminSimulatorDataChanged.dispatch()
    }, [gameID, timeScale, minLapTime, maxLapTime, sectors, sectorTargetCodes, finishFailureRate, sectorsFailureRate])

    useEffect(() => {
        if (!simulationDrivers) return
        localStorage.setItem("admin.simulator.drivers", JSON.stringify(simulationDrivers))
        signal.onAdminSimulatorDataChanged.dispatch()
    }, [simulationDrivers])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    async function updateStates() {
        signal.onAdminSimulatorDataChanged.remove(updateStates)
        setGameID(getValue("gameID", "") as string)
        setTimeScale(getValue("timeScale", 1) as number)
        setMinLapTime(getValue("minLapTime", 5) as number)
        setMaxLapTime(getValue("maxLapTime", 10) as number)
        setSectors(getValue("sectors", 1) as number)
        setSectorTargetCodes(getValue("sectorTargetCodes", "4") as string)
        setFinishFailureRate(getValue("finishFailureRate", 0) as number)
        setSectorsFailureRate(getValue("sectorsFailureRate", 0) as number)
        setSimulationDrivers(getDrivers())
        await PromisedDelay.wait(0.1)
        signal.onAdminSimulatorDataChanged.add(updateStates)
    }

    function getValue(key: string, defaultValue?: string | number | boolean): string | number | boolean {
        switch (typeof defaultValue) {
            case "boolean":
                return localStorage.getItem("admin.simulator." + key) == "true"
            case "number":
                const storageValue = localStorage.getItem("admin.simulator." + key)
                return storageValue ? parseFloat(storageValue) : defaultValue
            case "string":
                return localStorage.getItem("admin.simulator." + key) ?? defaultValue
        }
    }

    function setValue(key: string, value: string | number | boolean) {
        if (!value || getValue(key) == value) return
        let storageValue = null
        switch (typeof value) {
            case "boolean":
            case "string":
                storageValue = value
                break
            case "number":
                storageValue = value.toString()
                break
        }
        localStorage.setItem("admin.simulator." + key, storageValue)
    }

    function getDrivers(): ISimulatorDriverData[] {
        const stringifiedData = localStorage.getItem("admin.simulator.drivers")
        if (!stringifiedData || stringifiedData == "undefined") return []
        return JSON.parse(stringifiedData);
    }

    /* ----------------------------------------------------------------
 	 * RENDER
 	 * --------------------------------------------------------------*/

    return {
        gameID, setGameID,
        timeScale, setTimeScale,
        minLapTime, setMinLapTime,
        maxLapTime, setMaxLapTime,
        sectors, setSectors,
        sectorTargetCodes, setSectorTargetCodes,
        finishFailureRate, setFinishFailureRate,
        sectorsFailureRate, setSectorsFailureRate,
        simulationDrivers, setSimulationDrivers
    }

}
