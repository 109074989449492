import {SharedConfig} from "../config/SharedConfig";

/******************************************************************
 * AppVersionUtils
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class AppVersionUtils {

    static isVersionAllowed(version: string) {
        const minimumVersionArray = SharedConfig.MIN_DRIFT_APP_VERSION.split(".").map(str => parseInt(str, 10));
        const availableVersionArray = version.split(".").map(str => parseInt(str, 10));
        for (let i = 0; i < minimumVersionArray.length; i++) {
            if (availableVersionArray[i] < minimumVersionArray[i]) {
                return false;
            } else if (availableVersionArray[i] > minimumVersionArray[i]) {
                return true;
            }
        }
        return true;
    }

}
