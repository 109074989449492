import * as React from "react";
import {useEffect, useState} from "react";
import {
    ANY_DRIFT_TUNING,
    BASIC_DRIFT_TUNINGS,
    CLUB_DRIFT_TUNING,
    DRIFT_TUNINGS,
    DriftTuningType,
    MAX_DRIFT_TUNINGS,
    PERFORMANCE_DRIFT_TUNINGS
} from "../../../../../../shared/types/DriftTuningType";
import {useServices} from "../../../../hooks/useServices";
import {useRouteOwner} from "../../../../hooks/useRouteOwner";
import {MultiSelectInput} from "../select/MultiSelectInput";
import {useGroupState} from "../../../../hooks/useGroupState";
import {MultiSelectOptionType, MultiSelectPresetOptionType} from "../select/options/MultiSelectOption";

/******************************************************************
 * DriftTuningsInput
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function DriftTuningsInput(props: {
    tunings: DriftTuningType[]
    onChange: (tunings: DriftTuningType[]) => void
    readonly?: boolean
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict} = useServices()
    const {isRouteOwnerAuthUser} = useRouteOwner()
    const {isGroupAdmin} = useGroupState()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [label, setLabel] = useState<string>(computeLabel())

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        updateLabel()
    }, [props.tunings])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function defaultValue(): DriftTuningType[] {
        return props.tunings?.map(tuning => tuning.replace("FAI-GT1", "FIA-GT1") as DriftTuningType)
    }

    function options(): MultiSelectOptionType<DriftTuningType>[] {
        return DRIFT_TUNINGS
            .filter(tuning => tuning !== "5,6L FAI-GT1 600 PS")
            .map(engine => ({
                value: engine, text: dict(`drift.tuning.${engine}`)
            }))
    }

    function presetOptions(): MultiSelectPresetOptionType[] {
        return [
            {
                optionValues: ANY_DRIFT_TUNING,
                label: dict("drift.tuning.preselection.any")
            },
            {
                optionValues: CLUB_DRIFT_TUNING,
                label: dict("drift.tuning.preselection.club")
            },
            {
                optionValues: BASIC_DRIFT_TUNINGS,
                label: dict("drift.tuning.preselection.basic")
            },
            {
                optionValues: PERFORMANCE_DRIFT_TUNINGS,
                label: dict("drift.tuning.preselection.performance")
            },
            {
                optionValues: MAX_DRIFT_TUNINGS,
                label: dict("drift.tuning.preselection.max")
            }
        ]
    }

    function onChanged(tunings: DriftTuningType[]) {
        updateLabel()
        props.onChange(tunings)
    }

    function selectedPresetOptionByTunings(tunings: string[]): MultiSelectPresetOptionType {
        return presetOptions().find(presetOption => {
            return presetOption.optionValues?.length == tunings?.length
                && presetOption.optionValues?.filter(value => tunings.includes(value)).length == presetOption.optionValues.length
        })
    }

    function updateLabel() {
        setLabel(computeLabel())
    }

    function computeLabel() {
        const selectedPresetOption = selectedPresetOptionByTunings(props.tunings)
        const presetLabel = selectedPresetOption
            ? selectedPresetOption.label
            : dict("drift.tuning.preselection.custom")
        return dict("drift.tunings").replaceAll("{TUNING_GROUP}", presetLabel)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <MultiSelectInput
            label={label}
            helpTopic="drift.tunings"
            showFilter={true}
            readonly={(!isRouteOwnerAuthUser && !isGroupAdmin) || props.readonly}
            defaultValue={defaultValue()}
            keepFilterOnOptionsChange={true}
            sortOptionsByText={true}
            onChange={onChanged}
            minSelected={1}
            options={options()}
            presetOptions={presetOptions()}/>
    );

}
