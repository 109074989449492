import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {MongoObjectIDType} from "../../../../../shared/types/MongoObjectIDType";
import {IArticleData} from "../../../../../shared/models/IArticleData";
import {useServices} from "../../../hooks/useServices";
import {InfoText} from "../../text/infotext/InfoText";
import {ContentLayout} from "../../layout/content/ContentLayout";
import {Headline} from "../../text/headings/Headline";
import {SelectArticleModal} from "../../../systems/modals/article/SelectArticleModal";
import {Icon} from "../../icons/Icon";
import {LabelButton} from "../../buttons/label/LabelButton";
import {useMobileStyle} from "../../../hooks/useMobileStyle";
import {ComponentInitializer} from "../../utils/init/ComponentInitializer";

/******************************************************************
 * ArticleTeaser
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function ArticleTeaser(props: {
    articleID: MongoObjectIDType
    editable?: boolean
    onSelected?: (articleID: MongoObjectIDType) => void
}) {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const rootRef = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {api, dict, state} = useServices()
    const [isMobileMode] = useMobileStyle(rootRef, 600)

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [articleData, setArticleData] = useState<IArticleData>(null)
    const [showSelectArticleModal, setShowSelectArticleModal] = useState<boolean>(false)
    const [busy, setBusy] = useState<boolean>(false)

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        loadArticleData()
    }, [props.articleID])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function loadArticleData() {
        setBusy(true)
        if (!props.articleID) {
            setArticleData(null)
            setBusy(false)
            return
        }
        const articleData = await api.article.getArticleByID(props.articleID, true)
        setArticleData(articleData)
        setBusy(false)
    }

    function onArticleSelected(articleID: MongoObjectIDType) {
        setShowSelectArticleModal(false)
        props.onSelected?.(articleID)
    }

    function actionLabel() {
        return props.editable
            ? dict("article.teaser.select.label")
            : dict("article.teaser.read.label")
    }

    async function onActionClick() {
        if (props.editable) {
            setShowSelectArticleModal(true)
        } else {
            state.showArticle.setValue(props.articleID)
        }
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="article-teaser"
            data-use-max-linewidth={!props.editable}>
            <ContentLayout
                ref={rootRef}
                framed={true}>
                <ComponentInitializer isPropertyAvailable={!busy}>
                    <ContentLayout
                        columnTemplate={isMobileMode ? "min-content 1fr" : "min-content 1fr min-content"}
                        alignItems="start">
                        <Icon type="link"/>
                        {articleData
                            ? <ContentLayout gap="tiny">
                                <Headline
                                    text={articleData.title}
                                    style="h4"/>
                                {articleData.subTitle &&
                                    <Headline
                                        text={articleData.subTitle}
                                        style="h5"/>}
                                {articleData.abstract &&
                                    <InfoText
                                        text={articleData.abstract}
                                        size="small"/>}
                            </ContentLayout>
                            : <InfoText
                                text={dict("article.teaser.empty.info")}
                                align="center"/>}
                        {!isMobileMode && (articleData || props.editable) &&
                            <LabelButton
                                label={actionLabel()}
                                style="primary-small"
                                onClick={onActionClick}/>}
                    </ContentLayout>
                    {isMobileMode && (articleData || props.editable) &&
                        <ContentLayout justifyItems="end">
                            <LabelButton
                                label={actionLabel()}
                                style="primary-small"
                                onClick={onActionClick}/>
                        </ContentLayout>}
                </ComponentInitializer>
            </ContentLayout>
            {showSelectArticleModal && <SelectArticleModal
                onSelected={onArticleSelected}
                onClose={() => setShowSelectArticleModal(false)}/>}
        </div>
    );

}
