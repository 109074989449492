import {ErrorCode} from "../keys/ErrorCode";

/******************************************************************
 * PasswordValidation
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class PasswordValidation {

    static get REGEXP() {
        return /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d).{8,}$/g;
    }

    static get UPPERCASE_LETTER_REGEXP() {
        return /(?=.*?[A-Z])/
    }

    static get LOWERCASE_LETTER_REGEXP() {
        return /(?=.*?[a-z])/
    }

    static get NUMBER_REGEXP() {
        return /(?=.*?\d)/
    }

    static get LENGTH_REGEXP() {
        return /^.{8,}$/
    }

    static schemaValidator() {
        return {
            validate: {
                validator: value => {
                    return PasswordValidation.isValid(value)
                },
                message: ErrorCode.USER_PASSWORD_INVALID
            },
        }
    };

    static isValid(password: string): boolean {
        return PasswordValidation.REGEXP.test(password);
    }

}
