import * as React from "react";
import {useRef, useState} from "react";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {SelectInput, SelectInputOptionType} from "../../../../../ui/form/elements/select/SelectInput";
import {useServices} from "../../../../../hooks/useServices";
import {Input} from "../../../../../ui/form/elements/input/Input";
import {SessionLapsValidation} from "../../../../../../../shared/validation/SessionLapsValidation";
import {useSessionState} from "../../../../../hooks/useSessionState";
import {SessionGameModeType} from "../../../../../../../shared/types/SessionGameModeType";
import {SessionFinishType} from "../../../../../../../shared/types/SessionFinishType";
import {useRouteOwner} from "../../../../../hooks/useRouteOwner";
import {SessionStintsValidation} from "../../../../../../../shared/validation/SessionStintsValidation";
import {SessionTimingType} from "../../../../../../../shared/types/SessionTimingType";
import {SessionClassificationModeType} from "../../../../../../../shared/types/SessionClassificationModeType";
import {useGroupState} from "../../../../../hooks/useGroupState";
import {useEventState} from "../../../../../hooks/useEventState";
import {SessionStintValidationModeType} from "../../../../../../../shared/types/SessionStintValidationModeType";
import {SessionSyncedLapRaceFulfillMode} from "../../../../../../../shared/types/SessionSyncedLapRaceFulfillMode";
import {FrontendConfig} from "../../../../../../core/FrontendConfig";
import {SharedConfig} from "../../../../../../../shared/config/SharedConfig";
import {useMobileStyle} from "../../../../../hooks/useMobileStyle";
import moment = require("moment");

/******************************************************************
 * SessionBasicSetup
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function SessionBasicSetup() {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const ref = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {dict, api} = useServices()
    const {isRouteOwnerAuthUser} = useRouteOwner()
    const {eventTeams, eventOwnerType} = useEventState()
    const {isGroupAdmin, groupID} = useGroupState()
    const [isMobileMode] = useMobileStyle(ref, 500)
    const {
        sessionID,
        sessionMode,
        sessionClassificationMode,
        sessionSimluataneousTeamDrivers,
        sessionFinishType,
        sessionSyncedLapRaceFulfillMode,
        sessionLaps,
        sessionUseStartDelay,
        sessionDuration,
        sessionTiming,
        sessionAvailableStints,
        sessionStartTime,
        sessionStintValidationType,
        sessionAdditionalLapsToSync,
        sessionIsFinished
    } = useSessionState()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [isTraining] = useState<boolean>(eventOwnerType == "user")

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    async function updateSessionMode(mode: SessionGameModeType) {
        await api.session.update(sessionID, {setup: {mode: mode}})
    }

    async function updateSessionFinishType(finishType: SessionFinishType) {
        await api.session.update(sessionID, {setup: {finishType: finishType}})
    }

    async function updateSessionStintValidationType(stintValidationType: SessionStintValidationModeType) {
        await api.session.update(sessionID, {setup: {stintValidationMode: stintValidationType}})
    }

    async function updateSessionSimultaneousTeamDrivers(simultaneousTeamDrivers: string): Promise<Response> {
        return await api.session.update(sessionID, {setup: {simultaneousTeamDrivers: parseInt(simultaneousTeamDrivers)}})
    }

    async function updateSessionLaps(laps: string): Promise<Response> {
        return await api.session.update(sessionID, {setup: {laps: parseInt(laps)}})
    }

    async function updateSessionDuration(duration: string): Promise<Response> {
        return await api.session.update(sessionID, {setup: {duration: duration}})
    }

    async function updateSessionClassificationMode(value: SessionClassificationModeType): Promise<Response> {
        return await api.session.update(sessionID, {setup: {classificationMode: value}})
    }

    async function updateSessionTiming(timing: SessionTimingType) {
        await api.session.update(sessionID, {setup: {timing: timing}})
    }

    async function updateSessionStints(stints: string): Promise<Response> {
        return await api.session.update(sessionID, {setup: {stints: parseInt(stints)}})
    }

    async function updateSessionSyncedLapRaceFulfillMode(syncedLapRaceFulfillMode: SessionSyncedLapRaceFulfillMode): Promise<Response> {
        return await api.session.update(sessionID, {setup: {syncedLapRaceFulfillMode: syncedLapRaceFulfillMode}})
    }

    function isReadonly(): boolean {
        if (sessionIsFinished) return true
        return !isRouteOwnerAuthUser && !isGroupAdmin
    }

    function sessionFinishTypeLabel() {
        switch (sessionTiming) {
            case "sync": {
                return dict("session.finishType.sync.label");
            }
            case "async": {
                return dict("session.finishType.async.label");
            }

        }
    }

    function sessionModeOptions(): SelectInputOptionType<SessionGameModeType>[] {
        return [
            {value: "lap", text: dict("session.mode.lap")},
            {value: "race", text: dict("session.mode.race")},
            {value: "gymkhana", text: dict("session.mode.gymkhana")}
        ]
    }

    function classifictionModeOptions(): SelectInputOptionType[] {
        const options: SelectInputOptionType<SessionClassificationModeType>[] = []
        if (!groupID) {
            options.push({value: "solo", text: dict("session.classificationMode.solo")})
        }
        options.push({value: "drivers", text: dict("session.classificationMode.drivers")})
        if (eventTeams?.length > 0) {
            options.push({value: "teams", text: dict("session.classificationMode.teams")})
        }
        return options
    }

    function stintValidationTypeOptions(): SelectInputOptionType<SessionStintValidationModeType>[] {
        const validWhenStarted = {value: "valid-when-started", text: dict("session.stintValidationType.valid-when-started")}
        const validWhenCompleted = {value: "valid-when-completed", text: dict("session.stintValidationType.valid-when-completed")}
        const validWhenAtLeastOneLap = {value: "valid-when-at-least-one-lap", text: dict("session.stintValidationType.valid-when-at-least-one-lap")}
        const validWhenAtLeast50Secs = {value: "valid-when-at-least-50secs", text: dict("session.stintValidationType.valid-when-at-least-50secs")}
        const validWhenImproved = {value: "valid-when-improved", text: dict("session.stintValidationType.valid-when-improved")}
        switch (sessionMode) {
            case "race":
                const raceOptions = []
                raceOptions.push(validWhenStarted)
                raceOptions.push(validWhenAtLeastOneLap)
                raceOptions.push(validWhenCompleted)
                raceOptions.push(validWhenImproved)
                return raceOptions
            case "gymkhana":
                const gymkhanaOptions = []
                gymkhanaOptions.push(validWhenStarted)
                gymkhanaOptions.push(validWhenAtLeast50Secs)
                gymkhanaOptions.push(validWhenCompleted)
                gymkhanaOptions.push(validWhenImproved)
                return gymkhanaOptions
        }
        return null

    }

    function simulaneousTeamDriversOptions(): SelectInputOptionType[] {
        return [{
            value: "0",
            text: dict("session.simultaneousTeamDrivers.all")
        }, {
            value: "1",
            text: dict("session.simultaneousTeamDrivers.one")
        }]
    }

    function syncedLapRaceFulfillModeOptions(): SelectInputOptionType<SessionSyncedLapRaceFulfillMode>[] {
        return [{
            value: "leader",
            text: dict("session.syncedLapRaceFulfillMode.leader")
        }, {
            value: "driver",
            text: dict("session.syncedLapRaceFulfillMode.driver")
        }]
    }

    function showTiming(): boolean {
        switch (sessionClassificationMode) {
            case "drivers":
            case "teams":
                return true
            case "solo":
                return false
        }
    }

    function showStintValidation(): boolean {
        if (sessionTiming == "sync") {
            return false
        }
        switch (sessionMode) {
            case "lap":
                return false
            case "race":
                return true
            case "gymkhana":
                return true
        }
    }

    function showSimulaneousTeamDrivers(): boolean {
        switch (sessionClassificationMode) {
            case "drivers":
            case "solo":
                return false
            case "teams":
                return true
        }
    }

    function showSyncedLapRaceFulfillMode() {
        return FrontendConfig.SHOW_SESSION_SYNC_LAP_RACE_FULLFILLMODE_SETTING
            && sessionMode == "race"
            && sessionTiming == "sync"
            && sessionFinishType == "laps"
            && sessionClassificationMode != "solo"
    }

    async function updateSessionStartTime(datestamp: string): Promise<Response> {
        if (datestamp) {
            datestamp = moment(datestamp).format();
        }
        return await api.session.update(sessionID, {setup: {startTime: datestamp}})
    }

    async function updateSessionUseStartDelay(useStartDelay: "true" | "false") {
        await api.session.update(sessionID, {setup: {useStartDelay: useStartDelay == "true"}})
    }

    async function updateSessionAdditionalLapsToSync(value: "none" | "default" | "max") {
        let laps: number = 0;
        switch (value) {
            case "default":
                laps = SharedConfig.DEFAULT_SYNC_LAPS_ADDITIONAL_LAPS
                break
            case "max":
                laps = SharedConfig.DRIFT_MAX_LAPS
                break
        }
        await api.session.update(sessionID, {setup: {additionalSyncLaps: laps}})
    }

    function showStartTime(): boolean {
        switch (sessionClassificationMode) {
            case "drivers":
            case "teams":
                return true
        }
        return false
    }

    function showUseStartDelay(): boolean {
        return sessionUseStartDelay === true || sessionUseStartDelay === false
    }

    function sessionAdditionalLapsToSyncValue() {
        if (sessionAdditionalLapsToSync == SharedConfig.DEFAULT_SYNC_LAPS_ADDITIONAL_LAPS) {
            return "default"
        }
        if (sessionAdditionalLapsToSync == SharedConfig.DRIFT_MAX_LAPS) {
            return "max"
        }
        return "none"
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            ref={ref}
            className="session-basic-setup">
            <ContentLayout columns={isMobileMode ? 1 : 2}>
                <SelectInput
                    label={dict("session.mode")}
                    defaultValue={sessionMode}
                    helpTopic="session.mode"
                    readonly={isReadonly()}
                    onChange={updateSessionMode}
                    options={sessionModeOptions()}/>
                {(!isTraining && eventTeams?.length > 0) &&
                    <SelectInput
                        label={dict("session.classificationMode")}
                        helpTopic="session.classificationMode"
                        defaultValue={sessionClassificationMode}
                        readonly={isReadonly()}
                        onChange={updateSessionClassificationMode}
                        options={classifictionModeOptions()}/>}
            </ContentLayout>
            {sessionMode != "gymkhana" &&
                <ContentLayout columns={isMobileMode ? 1 : 2}>
                    <SelectInput
                        label={sessionFinishTypeLabel()}
                        readonly={isReadonly()}
                        helpTopic="session.finishType"
                        defaultValue={sessionFinishType}
                        onChange={updateSessionFinishType}
                        options={[
                            {value: "laps", text: dict("session.finishType.laps")},
                            {value: "duration", text: dict("session.finishType.duration")},
                        ]}/>
                    {sessionFinishType == "laps" &&
                        <Input
                            label={dict("session.laps")}
                            action={updateSessionLaps}
                            readonly={isReadonly()}
                            pattern={SessionLapsValidation.REGEXP.source}
                            helpTopic="session.setup.laps"
                            placeholder={(sessionMode == "race" ? "1" : "0") + " ... " + SharedConfig.SESSION_MAX_LAPS}
                            required={true}
                            minNumber={sessionMode == "race" ? 1 : 0}
                            maxNumber={SharedConfig.SESSION_MAX_LAPS}
                            showInvalid={true}
                            defaultValue={sessionLaps == 0
                                ? (isReadonly()
                                    ? dict("session.setup.laps.unlimited")
                                    : "0")
                                : sessionLaps?.toString()}
                            type="number"/>}
                    {sessionFinishType == "duration" &&
                        <Input
                            label={dict("session.duration")}
                            helpTopic="session.setup.duration"
                            readonly={isReadonly()}
                            placeholder="HH:MM:SS"
                            action={updateSessionDuration}
                            showInvalid={true}
                            defaultValue={sessionDuration ?? null}
                            type="duration"/>}
                </ContentLayout>}
            {showTiming() &&
                <ContentLayout columns={isMobileMode ? 1 : 2}>
                    {sessionMode != "gymkhana" &&
                        <SelectInput
                            label={dict("session.timing.label")}
                            readonly={isReadonly()}
                            defaultValue={sessionTiming}
                            helpTopic="session.timing"
                            onChange={updateSessionTiming}
                            options={[
                                {value: "sync", text: dict("session.timing.sync")},
                                {value: "async", text: dict("session.timing.async")}
                            ]}/>}
                    <Input
                        type="number"
                        label={dict("session.stints")}
                        defaultValue={sessionAvailableStints == 0
                            ? (isReadonly()
                                ? dict("session.setup.stints.unlimited")
                                : "0")
                            : sessionAvailableStints?.toString()}
                        readonly={isReadonly()}
                        minNumber={0}
                        pattern={SessionStintsValidation.REGEXP.source}
                        helpTopic="session.stints"
                        placeholder="0 ... "
                        showInvalid={true}
                        action={updateSessionStints}/>
                </ContentLayout>}
            {(showStintValidation() || showSimulaneousTeamDrivers() || showSyncedLapRaceFulfillMode()) &&
                <ContentLayout columns={isMobileMode ? 1 : 2}>
                    {showStintValidation() &&
                        <SelectInput
                            label={dict("session.stintValidationType")}
                            readonly={isReadonly()}
                            helpTopic="session.stintValidationType"
                            defaultValue={sessionStintValidationType}
                            onChange={updateSessionStintValidationType}
                            options={stintValidationTypeOptions()}/>}
                    {showSimulaneousTeamDrivers() &&
                        <SelectInput
                            label={dict("session.simultaneousTeamDrivers")}
                            readonly={isReadonly()}
                            helpTopic="session.simultaneousTeamDrivers"
                            defaultValue={sessionSimluataneousTeamDrivers?.toString()}
                            onChange={updateSessionSimultaneousTeamDrivers}
                            options={simulaneousTeamDriversOptions()}/>}
                    {showSyncedLapRaceFulfillMode() &&
                        <SelectInput
                            label={dict("session.syncedLapRaceFulfillMode")}
                            readonly={isReadonly()}
                            helpTopic="session.syncedLapRaceFulfillMode"
                            defaultValue={sessionSyncedLapRaceFulfillMode}
                            onChange={updateSessionSyncedLapRaceFulfillMode}
                            options={syncedLapRaceFulfillModeOptions()}/>}
                </ContentLayout>}
            {showStartTime() &&
                <ContentLayout columns={isMobileMode ? 1 : 2}>
                    <Input
                        type="datetime-local"
                        readonly={isReadonly()}
                        label={dict("session.startTime")}
                        defaultValue={sessionStartTime ? moment(sessionStartTime).format("YYYY-MM-DDTHH:mm") : null}
                        helpTopic={sessionStartTime ? null : "session.startTime"}
                        customLinkLabel={sessionStartTime && dict("date.delete.label")}
                        onCustomLinkClick={() => updateSessionStartTime(null)}
                        placeholder={(isRouteOwnerAuthUser || isGroupAdmin) ? dict("input.optional") : dict("input.not.set")}
                        showInvalid={true}
                        action={updateSessionStartTime}/>
                    {showUseStartDelay() &&
                        <SelectInput
                            label={dict("session.useStartDelay")}
                            defaultValue={sessionUseStartDelay?.toString() ?? "false"}
                            readonly={isReadonly()}
                            helpTopic="session.useStartDelay"
                            onChange={updateSessionUseStartDelay}
                            options={[
                                {value: "false", text: dict("session.useStartDelay.false")},
                                {value: "true", text: dict("session.useStartDelay.true")}
                            ]}/>}
                </ContentLayout>}
            {sessionMode != "gymkhana" && sessionFinishType != "duration" &&
                <ContentLayout columns={isMobileMode ? 1 : 2}>
                    <SelectInput
                        label={dict("session.additionalLapsToSync")}
                        defaultValue={sessionAdditionalLapsToSyncValue()}
                        readonly={isReadonly()}
                        helpTopic="session.additionalLapsToSync"
                        onChange={updateSessionAdditionalLapsToSync}
                        options={[{
                            value: "none", text: dict("session.additionalLapsToSync.none")
                        }, {
                            value: "default", text: dict("session.additionalLapsToSync.default")
                                .replace("{LAPS}", SharedConfig.DEFAULT_SYNC_LAPS_ADDITIONAL_LAPS.toString())
                        }, {
                            value: "max", text: dict("session.additionalLapsToSync.max")
                                .replace("{LAPS}", SharedConfig.DRIFT_MAX_LAPS.toString())
                        }]}/>
                </ContentLayout>}
        </ContentLayout>
    );

}
