import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {ContentLayout} from "../../../../ui/layout/content/ContentLayout";
import {Input} from "../../../../ui/form/elements/input/Input";
import {UploadableImage} from "../../../../ui/image/uploadable/UploadableImage";
import {IGroupData} from "../../../../../../shared/models/IGroupData";
import {FrontendRoute} from "../../../../../../shared/routes/FrontendRoute";
import {useServices} from "../../../../hooks/useServices";
import {LabelButton} from "../../../../ui/buttons/label/LabelButton";
import {FormMessage} from "../../../../ui/form/elements/message/FormMessage";
import {SharedConfig} from "../../../../../../shared/config/SharedConfig";
import {SelectInput, SelectInputOptionType} from "../../../../ui/form/elements/select/SelectInput";
import {useMobileStyle} from "../../../../hooks/useMobileStyle";
import {GroupType} from "../../../../../../shared/types/GroupType";
import {ILocationData} from "../../../../../../shared/types/ILocationData";
import {PrivacyType} from "../../../../../../shared/types/PrivacyType";
import {MapInput} from "../../../../ui/map/input/MapInput";

/******************************************************************
 * CreateGroupContent
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function CreateGroupContent() {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const rootRef = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {router, state, dict, api, error} = useServices()
    const [isMobileMode] = useMobileStyle(rootRef, 500)

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [isValid, setIsValid] = useState<boolean>()
    const [errorMessage, setErrorMessage] = useState<string>()
    const [busy, setBusy] = useState<boolean>()
    const [file, setFile] = useState<File>()
    const [name, setName] = useState<string>()
    const [location, setLocation] = useState<ILocationData>()
    const [groupType, setGroupType] = useState<GroupType>()
    const [groupPrivacy, setGroupPrivacy] = useState<PrivacyType>("private")

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        setGroupType("local")
    }, [])

    useEffect(() => {
        switch (groupType) {
            case "online":
                setIsValid(
                    !!name &&
                    !!file)
                break
            case "local":
                setIsValid(
                    !!name &&
                    !!file &&
                    !!location &&
                    !!location.latitude &&
                    !!location.longitude &&
                    !!location.country &&
                    !!location.place)
                break
        }
    }, [name, file, location, groupType])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function createGroup() {
        setBusy(true)
        const response = await api.group.createGroup({
            type: groupType,
            privacy: groupPrivacy,
            name: name ?? null,
            location: location ?? null
        })
        const responseJSON = await response.json()
        if (response.status == 200) {
            const groupData: IGroupData = responseJSON;
            const formData = new FormData()
            formData.append(SharedConfig.FILE_UPLOAD_KEY, file)
            formData.append("groupID", groupData._id)
            await api.group.uploadAvatar(formData)
            setBusy(false)
            state.showCreateGroup.setValue(null);
            router.showRoute(FrontendRoute.GROUP(groupData.path))
        } else {
            setBusy(false)
            setErrorMessage(error.createMessage(responseJSON))
        }
    }

    function groupTypeOptions(): SelectInputOptionType[] {
        return [{
            value: "local" as GroupType,
            text: dict("group.type.local")
        }, {
            value: "online" as GroupType,
            text: dict("group.type.online")
        }]
    }

    function groupPrivacyOptions(): SelectInputOptionType[] {
        return [{
            value: "public" as PrivacyType,
            text: dict("group.privacy.public")
        }, {
            value: "private" as PrivacyType,
            text: dict("group.privacy.private")
        }]
    }

    function updateLocaction(value: ILocationData): Promise<Response> {
        setLocation(value)
        return null
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return <>
        <ContentLayout ref={rootRef} columns={isMobileMode ? 1 : 2}>
            <ContentLayout>
                <div
                    className="create-group-modal-avatar">
                    <UploadableImage
                        id="trackfile"
                        editable={true}
                        fit="cover"
                        onChange={setFile}
                        buttonLabel={dict("group.avatar.select.label")}
                    />
                </div>
            </ContentLayout>
            <ContentLayout>
                <Input
                    type="text"
                    required={true}
                    onChange={setName}
                    label={dict("group.name.label")}/>
                <SelectInput
                    label={dict("group.type.label")}
                    required={true}
                    helpTopic={"group.type"}
                    defaultValue={groupType}
                    options={groupTypeOptions()}
                    onChange={(value) => setGroupType(value as GroupType)}/>
                <SelectInput
                    label={dict("group.privacy.label")}
                    required={true}
                    helpTopic={"group.privacy"}
                    defaultValue={groupPrivacy}
                    options={groupPrivacyOptions()}
                    onChange={(value) => setGroupPrivacy(value as PrivacyType)}/>
                {groupType == "local" &&
                    <MapInput
                        type={"group"}
                        location={location}
                        onSave={updateLocaction}/>}
                {errorMessage &&
                    <FormMessage message={errorMessage} type="error"/>}
                <LabelButton
                    label={dict("group.create")}
                    style="primary"
                    progressing={busy}
                    disabled={!isValid}
                    onClick={createGroup}/>
            </ContentLayout>
        </ContentLayout>
    </>

}
