import * as React from "react";
import {Modal} from "../Modal";
import {Form} from "../../../ui/form/Form";
import {Headline} from "../../../ui/text/headings/Headline";
import {Input} from "../../../ui/form/elements/input/Input";
import {useServices} from "../../../hooks/useServices";
import {ISessionData} from "../../../../../shared/models/ISessionData";
import {PathValidation} from "../../../../../shared/validation/PathValidation";
import {FrontendRoute} from "../../../../../shared/routes/FrontendRoute";
import {IEventData} from "../../../../../shared/models/IEventData";
import {useDeviceInfo} from "../../../hooks/useDeviceInfo";

/******************************************************************
 * EditSessionPathModal
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function EditSessionPathModal() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {api, dict, state, router} = useServices();
    const {isMobile} = useDeviceInfo()

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    async function renameSessionPath(formData: { path: string }): Promise<Response> {
        const sessionData: ISessionData = state.showEditSessionPath.getValue();
        const eventData: IEventData = state.event.getValue();
        const currentSessionID = state.session.getValue()?._id;
        if (formData.path.substring(0, 1) != "/") {
            formData.path = "/" + formData.path;
        }
        const response = await api.session.update(sessionData._id, formData);
        if (response.status == 200) {
            if (state.showEditSessionPath.hasValue() && currentSessionID == sessionData._id) {
                await router.showRoute(FrontendRoute.EVENT_SESSION(eventData?.ownerType, eventData?.owner?.path, eventData.path, formData.path));
            }
            state.showEditSessionPath.setValue(null)
        }
        return response;
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal
            width="small"
            closeAction={() => state.showEditSessionPath.setValue(null)}
            position="global">
            <Headline text={dict("session.path.edit.title")} style="modal"/>
            <Form
                actionLabel={dict("session.path.edit.button.label")}
                action={renameSessionPath}>
                <Input
                    type="text"
                    id="path"
                    defaultValue={state.showEditSessionPath.getValue().path}
                    pattern={PathValidation.REGEXP.source}
                    helpTopic={"session.path"}
                    focus={!isMobile}
                    required={true}
                    label={dict("session.path.edit.label")}/>
            </Form>
        </Modal>
    );

}
