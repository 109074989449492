import * as React from "react";
import {Headline} from "../../../../../ui/text/headings/Headline";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {InfoText} from "../../../../../ui/text/infotext/InfoText";
import {LabelButton} from "../../../../../ui/buttons/label/LabelButton";
import {Modal} from "../../../../modals/Modal";
import {useServices} from "../../../../../hooks/useServices";
import {IUserData} from "../../../../../../../shared/models/IUserData";

/******************************************************************
 * AddToNextModal
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function AddToNextModal(props: {
    onRequestClose: () => void
    progressing: boolean
    user: IUserData
    nextList: "pending" | "registered"
    onRequestAddToNext: (userID: string) => void
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict} = useServices()

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal
            width="small"
            closeAction={props.onRequestClose}>
            <Headline
                text={dict(`event.request.participation.addToList.${props.nextList}.title`)}
                style="modal"/>
            <ContentLayout>
                <InfoText
                    size="small"
                    text={dict(`event.request.participation.addToList.${props.nextList}.text`)
                        .replaceAll("{NICK}", props.user.nick)}/>
                <ContentLayout columns={2}>
                    <LabelButton
                        label={dict("generic.abort")}
                        style="secondary-small"
                        onClick={props.onRequestClose}/>
                    <LabelButton
                        label={dict(`event.request.participation.addToList.${props.nextList}.confirm`)}
                        style="primary-small"
                        progressing={props.progressing}
                        onClick={props.onRequestAddToNext}/>
                </ContentLayout>
            </ContentLayout>
        </Modal>
    );

}
