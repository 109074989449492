import * as React from "react";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {useServices} from "../../../../../hooks/useServices";
import {Input} from "../../../../../ui/form/elements/input/Input";
import {useSessionState} from "../../../../../hooks/useSessionState";
import {useRouteOwner} from "../../../../../hooks/useRouteOwner";
import moment = require("moment");
import {useGroupState} from "../../../../../hooks/useGroupState";
import {useRef} from "react";
import {useMobileStyle} from "../../../../../hooks/useMobileStyle";
import {FormMessage} from "../../../../../ui/form/elements/message/FormMessage";

/******************************************************************
 * SessionTimingSetup
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function SessionTimingSetup() {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const ref = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {dict, api} = useServices()
    const {isRouteOwnerAuthUser} = useRouteOwner()
    const {isGroupAdmin} = useGroupState()
    const [isMobileMode] = useMobileStyle(ref, 500)
    const {
        sessionID,
        sessionActiveFrom,
        sessionActiveUntil,
        sessionIsFinished
    } = useSessionState()

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    async function updateSessionActiveFrom(datetime: string): Promise<Response> {
        if (datetime) {
            datetime = moment(datetime).format();
        }
        return await api.session.update(sessionID, {setup: {activeFrom: datetime}})
    }

    async function updateSessionActiveUntil(datetime: string): Promise<Response> {
        if (datetime) {
            datetime = moment(datetime).format();
        }
        return await api.session.update(sessionID, {setup: {activeUntil: datetime}})
    }

    function isFormAfterUntil() {
        if (!sessionActiveFrom || !sessionActiveUntil) {
            return false;
        }
        return moment(sessionActiveFrom).isAfter(sessionActiveUntil);
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout ref={ref} className="session-timing-setup">
            <ContentLayout columns={isMobileMode ? 1 : 2}>
                <Input
                    type="datetime-local"
                    label={dict("session.activeFrom")}
                    readonly={(!isRouteOwnerAuthUser && !isGroupAdmin) || sessionIsFinished}
                    defaultValue={sessionActiveFrom ? moment(sessionActiveFrom).format("YYYY-MM-DDTHH:mm") : null}
                    helpTopic={sessionActiveFrom ? null : "session.active"}
                    placeholder={isRouteOwnerAuthUser ? dict("input.optional") : dict("input.not.set")}
                    showInvalid={true}
                    customLinkLabel={sessionActiveFrom && dict("date.delete.label")}
                    onCustomLinkClick={() => updateSessionActiveFrom(null)}
                    action={updateSessionActiveFrom}/>
                <Input
                    type="datetime-local"
                    label={dict("session.activeUntil")}
                    readonly={(!isRouteOwnerAuthUser && !isGroupAdmin) || sessionIsFinished}
                    defaultValue={sessionActiveUntil ? moment(sessionActiveUntil).format("YYYY-MM-DDTHH:mm") : null}
                    helpTopic={sessionActiveUntil ? null : "session.active"}
                    placeholder={isRouteOwnerAuthUser ? dict("input.optional") : dict("input.not.set")}
                    showInvalid={true}
                    customLinkLabel={sessionActiveUntil && dict("date.delete.label")}
                    onCustomLinkClick={() => updateSessionActiveUntil(null)}
                    action={updateSessionActiveUntil}/>
            </ContentLayout>
            {isFormAfterUntil() && <FormMessage message={dict("session.timing.fromAfterUntil")} type="error"/>}
        </ContentLayout>
    );

}
