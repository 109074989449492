/******************************************************************
 * Article Types
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export const ARTICLE_TYPES = [
    "default",
    "marketing",
    "feature",
    "homepage"
] as const

export type ArticleType = typeof ARTICLE_TYPES[number];

export function isArticleType(type: string): boolean {
    return ARTICLE_TYPES.includes(type as ArticleType)
}
