import * as React from "react";
import {useRef, useState} from "react";
import {ContentLayout} from "../../../../ui/layout/content/ContentLayout";
import {Input} from "../../../../ui/form/elements/input/Input";
import {useServices} from "../../../../hooks/useServices";
import {useMobileStyle} from "../../../../hooks/useMobileStyle";
import {LabelButton} from "../../../../ui/buttons/label/LabelButton";
import {IStintData} from "../../../../../../shared/models/IStintData";
import {InfoText} from "../../../../ui/text/infotext/InfoText";

/******************************************************************
 * StintRaceDirectorEntry
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function StintRaceDirectorEntry(props: {
    stint: IStintData
    requestClose: () => void
}) {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const rootRef = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, api, error} = useServices()
    const [isMobile] = useMobileStyle(rootRef, 400)

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [lapsOffset, setLapsOffset] = useState<number>(props?.stint?.raceDirector?.lapsOffset ?? 0)
    const [timeOffset, setTimeOffset] = useState<number>(props?.stint?.raceDirector?.timeOffset ?? 0)
    const [message, setMessage] = useState<string>(props?.stint?.raceDirector?.message ?? "")
    const [isBusy, setIsBusy] = useState<boolean>(false)
    const [errorMessge, setErrorMessage] = useState<string>("")

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function save(reset: boolean = false) {
        setIsBusy(true)
        setErrorMessage(null)
        const response = await api.stint.updateStint(props.stint._id, {
            raceDirector: reset
                ? {
                    lapsOffset: 0,
                    timeOffset: 0,
                    message: ""
                }
                : {
                    lapsOffset,
                    timeOffset,
                    message
                }
        })
        if (response.status !== 200) {
            const result = await response.json()
            setErrorMessage(error.createMessage(result))
            setIsBusy(false)
            return null
        }
        props.requestClose()
        setIsBusy(false)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout className="race-director-entry" ref={rootRef}>
            <ContentLayout columns={isMobile ? 1 : 2}>
                <Input
                    type="number"
                    label={dict("raceDirector.lapsOffset.label")}
                    defaultValue={props?.stint?.raceDirector?.lapsOffset?.toString() ?? "0"}
                    onChange={setLapsOffset}/>
                <Input
                    type="number"
                    label={dict("raceDirector.timeOffset.label")}
                    defaultValue={props?.stint?.raceDirector?.timeOffset?.toString() ?? "0"}
                    onChange={setTimeOffset}/>
            </ContentLayout>
            {errorMessge &&
                <InfoText
                    text={errorMessge}
                    size="small"
                    style="error"/>}
            <Input
                type="textarea"
                label={dict("raceDirector.message.label")}
                defaultValue={props?.stint?.raceDirector?.message ?? ""}
                onChange={setMessage}/>
            <ContentLayout columns={isMobile ? 1 : 2}>
                <LabelButton
                    label={dict("raceDirector.delete.button.label")}
                    progressing={isBusy}
                    style="secondary"
                    onClick={() => save(true)}/>
                <LabelButton
                    label={dict("raceDirector.save.button.label")}
                    style="primary"
                    progressing={isBusy}
                    onClick={() => save(false)}/>
            </ContentLayout>
        </ContentLayout>
    );

}
