import * as React from "react";
import {ContentLayout} from "../../../../ui/layout/content/ContentLayout";
import {Headline} from "../../../../ui/text/headings/Headline";
import {EventRequestsKanban} from "./EventRequestsKanban";
import {useServices} from "../../../../hooks/useServices";
import {RegisteredDrivers} from "../settings/drivers/lists/RegisteredDrivers";
import {useEventState} from "../../../../hooks/useEventState";
import {useGroupState} from "../../../../hooks/useGroupState";
import {ActionBar} from "../../../../ui/bar/ActionBar";
import {LabelButton} from "../../../../ui/buttons/label/LabelButton";
import {useAuthUser} from "../../../../hooks/useAuthUser";
import {GroupUtils} from "../../../../../../shared/utils/GroupUtils";

/******************************************************************
 * EventRequestsPage
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function EventRequestsPage() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, state} = useServices()
    const {eventData, eventAccess, eventIsFinished, eventContact} = useEventState();
    const {groupData, isGroupAdmin} = useGroupState();
    const {authUserID} = useAuthUser()

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function showRegisteredDrivers() {
        switch (eventAccess) {
            case "only-registered-drivers":
                return true
            case "only-registered-drivers-after-approval":
            case "only-registered-drivers-after-paid":
                return !isGroupAdmin || eventIsFinished
            default:
                return false
        }
    }

    function showRequestKanban() {
        switch (eventAccess) {
            case "only-registered-drivers-after-approval":
            case "only-registered-drivers-after-paid":
                return isGroupAdmin && !eventIsFinished
            default:
                return false
        }
    }

    function showContactActionBar() {
        return !eventIsFinished
            && ((eventContact ?? GroupUtils.getContact(groupData))?._id != authUserID)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return <>
        <ContentLayout
            alignContent="start"
            className="event-requests-page">
            <Headline
                text={dict("event.requests.page.title")}
                style="h1"/>
            {showRegisteredDrivers() &&
                <RegisteredDrivers/>}
            {showRequestKanban() &&
                <EventRequestsKanban/>}
            {showContactActionBar() &&
                <ActionBar location="main">
                    <LabelButton
                        label={dict("event.sendMessage.button.label")}
                        style="action-main"
                        onClick={() => state.showContact.setValue({
                            sendMessageTo: eventData,
                            messageType: "event"
                        })}/>
                </ActionBar>}
        </ContentLayout>
    </>

}
