import * as React from "react";
import {Signal} from "@webfruits/core/dist/signal/Signal";

/******************************************************************
 * useSimulatorSignals
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

const startAllDrivers: Signal = new Signal()

export function useSimulatorSignals() {
    return {
        startAllDrivers
    }
}
