import * as React from "react";

/******************************************************************
 * AcademyIcon
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function AcademyIcon(props: {
    scale?: number
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="add-icon">
            <svg width={20 * (props.scale ?? 1)} height={18 * (props.scale ?? 1)} viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.0004 13.6354C9.88387 13.6354 9.76936 13.605 9.66819 13.5471L3.80783 10.1977C3.7569 10.1683 3.69914 10.1528 3.64034 10.1528C3.58154 10.1529 3.52378 10.1684 3.47288 10.1978C3.42198 10.2272 3.37973 10.2696 3.35039 10.3205C3.32104 10.3715 3.30564 10.4293 3.30573 10.4881V13.6354C3.30563 13.7549 3.33751 13.8722 3.39806 13.9752C3.45861 14.0782 3.54563 14.1632 3.65008 14.2212L9.6753 17.5685C9.77475 17.6238 9.88664 17.6528 10.0004 17.6528C10.1142 17.6528 10.2261 17.6238 10.3255 17.5685L16.3507 14.2212C16.4552 14.1632 16.5422 14.0782 16.6028 13.9752C16.6633 13.8722 16.6952 13.7549 16.6951 13.6354V10.4881C16.6952 10.4293 16.6798 10.3715 16.6504 10.3205C16.6211 10.2696 16.5788 10.2272 16.5279 10.1978C16.477 10.1684 16.4193 10.1529 16.3605 10.1528C16.3017 10.1528 16.2439 10.1683 16.193 10.1977L10.3326 13.5471C10.2315 13.605 10.1169 13.6354 10.0004 13.6354Z" fill="white"/>
                <path d="M20.0391 6.20851C20.0391 6.20851 20.0391 6.20516 20.0391 6.20391C20.0282 6.09779 19.9922 5.9958 19.934 5.90642C19.8758 5.81704 19.797 5.74288 19.7043 5.69009L10.3318 0.334343C10.2306 0.276513 10.1161 0.246094 9.99954 0.246094C9.88301 0.246094 9.76849 0.276513 9.66732 0.334343L0.294761 5.69009C0.192327 5.74866 0.107192 5.83326 0.047979 5.93532C-0.0112335 6.03738 -0.0424194 6.15328 -0.0424194 6.27127C-0.0424194 6.38927 -0.0112335 6.50517 0.047979 6.60723C0.107192 6.70929 0.192327 6.79389 0.294761 6.85246L9.66732 12.2082C9.76849 12.266 9.88301 12.2965 9.99954 12.2965C10.1161 12.2965 10.2306 12.266 10.3318 12.2082L18.5779 7.4964C18.5907 7.48904 18.6052 7.48518 18.6199 7.48519C18.6346 7.4852 18.649 7.4891 18.6618 7.49649C18.6745 7.50387 18.6851 7.51448 18.6924 7.52725C18.6997 7.54002 18.7035 7.55449 18.7035 7.56921V13.6166C18.7035 13.9769 18.9805 14.2861 19.3407 14.3041C19.4312 14.3084 19.5217 14.2944 19.6066 14.2627C19.6915 14.2311 19.7692 14.1825 19.8348 14.12C19.9004 14.0575 19.9526 13.9823 19.9883 13.899C20.024 13.8157 20.0424 13.7261 20.0424 13.6354V6.27127C20.0424 6.25031 20.0412 6.22936 20.0391 6.20851Z" fill="white"/>
            </svg>
        </div>
    );

}
