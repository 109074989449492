/******************************************************************
 * AmountUtils
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class AmountUtils {

    public static format(amount: number, currency: string, prefix: string = "", postfix: string = ""): string {
        if (amount == undefined) return "";
        return prefix + new Intl.NumberFormat('de-DE', {style: 'currency', currency}).format(amount) + postfix
    }

}
