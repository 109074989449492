import * as React from "react";

/******************************************************************
 * SpeakerIcon
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function SpeakerIcon(props: {
    scale?: number
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="speaker-icon">
            <svg width={14 * (props.scale ?? 1)} height={19 * (props.scale ?? 1)} viewBox="0 0 14 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.2134 8.56896V9.94971C13.2134 11.539 12.683 12.9216 11.6223 14.0973C10.5615 15.2731 9.2509 15.9473 7.69037 16.1199V17.5438H10.4519C10.6388 17.5438 10.8006 17.6121 10.9373 17.7488C11.0739 17.8854 11.1422 18.0472 11.1422 18.2342C11.1422 18.4212 11.0739 18.583 10.9373 18.7196C10.8006 18.8562 10.6388 18.9246 10.4519 18.9246H3.54812C3.36114 18.9246 3.19934 18.8562 3.0627 18.7196C2.92606 18.583 2.85775 18.4212 2.85775 18.2342C2.85775 18.0472 2.92606 17.8854 3.0627 17.7488C3.19934 17.6121 3.36114 17.5438 3.54812 17.5438H6.30961V16.1199C4.74908 15.9473 3.43845 15.2731 2.37772 14.0973C1.31699 12.9215 0.786621 11.539 0.786621 9.94971V8.56896C0.786621 8.38198 0.854939 8.22018 0.991575 8.08354C1.12821 7.94691 1.29002 7.87859 1.47699 7.87859C1.66397 7.87859 1.82578 7.94691 1.96241 8.08354C2.09905 8.22018 2.16737 8.38198 2.16737 8.56896V9.94971C2.16737 11.2801 2.6402 12.4182 3.58587 13.3638C4.53154 14.3095 5.66957 14.7823 6.99998 14.7823C8.33039 14.7823 9.46843 14.3095 10.4141 13.3638C11.3598 12.4182 11.8326 11.2801 11.8326 9.94971V8.56896C11.8326 8.38198 11.9009 8.22018 12.0375 8.08354C12.1742 7.94691 12.336 7.87859 12.523 7.87859C12.7099 7.87859 12.8718 7.94691 13.0084 8.08354C13.145 8.22018 13.2134 8.38198 13.2134 8.56896ZM10.4519 4.42672V9.94971C10.4519 10.899 10.1139 11.7116 9.43788 12.3876C8.76189 13.0636 7.94927 13.4016 7 13.4016C6.05074 13.4016 5.23811 13.0636 4.56212 12.3876C3.88613 11.7116 3.54814 10.899 3.54814 9.94971V4.42672C3.54814 3.47746 3.88613 2.66483 4.56212 1.98884C5.23811 1.31285 6.05074 0.974854 7 0.974854C7.94927 0.974854 8.76189 1.31285 9.43788 1.98884C10.1139 2.66483 10.4519 3.47746 10.4519 4.42672Z" fill="white"/>
            </svg>
        </div>
    );

}
