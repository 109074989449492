import * as React from "react";
import {useEffect, useState} from "react";
import {ITableColumnDefinition} from "./ITableColumnDefinition";
import {TableHeader} from "./header/TableHeader";
import {TableBody} from "./body/TableBody";
import {TableFilter} from "./filter/TableFilter";
import {PaginationControl} from "../pagination/PaginationControl";
import {TableFooter} from "./footer/TableFooter";
import {TableStyleType} from "./TableStyleType";
import {SortDirectionType} from "../../../utils/types/SortDirectionType";
import {TableRowDataType} from "./TableDataRowType";
import {JsonUtils} from "@webfruits/toolbox/dist/utils/JsonUtils";
import {useElementSize} from "../../hooks/useElementSize";

/******************************************************************
 * Table
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function Table<RowDataType, GlobalDataType = any>(props: {
    rowsData: RowDataType[]
    globalData?: GlobalDataType
    style?: TableStyleType
    columnsDefinition: ITableColumnDefinition<RowDataType>[]
    onRowClick?: (data: RowDataType) => void
    showFilter?: boolean
    showHeader?: boolean
    showPagination?: boolean
    sortKey?: string
    sortDirection?: SortDirectionType
    scrollContainerType?: "content" | "modal"
    showNoDataWhenFilterIsEmpty?: boolean
    visibleRows?: number
    noDataInfo?: string
    lowPriorityMinWidth?: number
    isLoadingData?: boolean
}) {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const tableRef = React.createRef<HTMLElement>()
    const scrollRef = React.createRef<HTMLDivElement>()

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const [tableWidth] = useElementSize(tableRef)

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [visibleData, setVisibleData] = useState<TableRowDataType<RowDataType>[]>()
    const [processedData, setProcessedData] = useState<TableRowDataType<RowDataType>[]>()
    const [filter, setFilter] = useState<string>("")
    const [sortKey, setSortKey] = useState<string>(props.sortKey)
    const [sortDirection, setSortDirection] = useState<SortDirectionType>(props.sortDirection ?? "desc")
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [totalPages, setTotalPages] = useState<number>(1)
    const [currentVisibleRows, setCurrentVisibleRows] = useState<number>(props.visibleRows)
    const [tableTemplateColumns, setTableTemplateColumns] = useState<string>(computeGridTemplateColumns())
    const [isScrollable, setIsScrollable] = useState<boolean>(false)
    const [minTableWidth, setMinTableWidth] = useState(null)
    const [stopTouchProgagation, setStopTouchProgagation] = useState(false)

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        processData()
    }, [props.rowsData])

    useEffect(() => {
        computeTableMinWidth()
    }, [props.columnsDefinition])

    useEffect(() => {
        if (!tableWidth || tableWidth <= 0) return
        setTableTemplateColumns(computeGridTemplateColumns())
        let scrollable = tableWidth < minTableWidth
        setIsScrollable(scrollable)
    }, [tableWidth, minTableWidth])

    useEffect(() => {
        setCurrentVisibleRows(props.visibleRows)
    }, [props.visibleRows])

    useEffect(() => {
        setSortKey(props.sortKey)
    }, [props.sortKey])

    useEffect(() => {
        setSortDirection(props.sortDirection ?? "desc")
    }, [props.sortDirection])

    useEffect(() => {
        const numRows = currentVisibleRows ?? processedData?.length;
        setVisibleData(processedData?.filter((entry, i) => {
            return i >= (currentPage - 1) * numRows && i < currentPage * numRows
        }))
        setTotalPages(Math.ceil(processedData?.length / numRows))
    }, [processedData, currentPage, currentVisibleRows])

    useEffect(() => {
        setCurrentVisibleRows(props.visibleRows)
        setCurrentPage(1)
        processData()
    }, [filter, sortKey, sortDirection])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function computeGridTemplateColumns(): string {
        const hideLowPriorityColumns = tableWidth < props.lowPriorityMinWidth
        return props.columnsDefinition.reduce((gridTemplateColumns, currentColDef) => {
            if (currentColDef.size === null
                || currentColDef.priority == "low" && hideLowPriorityColumns) {
                return gridTemplateColumns
            }
            return gridTemplateColumns + " " + (currentColDef.size ?? "1fr")
        }, "")
    }

    function processData() {
        let data = filterData(props.rowsData) as TableRowDataType<RowDataType>[]
        data.forEach((dataset, i) => {
            if (typeof dataset === "object") {
                dataset.rowID = i
            }
        })
        sortData(data)
        setProcessedData(data)
    }

    function filterData(data: RowDataType[]): RowDataType[] {
        if (filter.length < 2 && (!props.showFilter || !props.showNoDataWhenFilterIsEmpty)) {
            return data?.map(dataset => dataset) ?? []
        }
        if (filter.length < 2 && props.showNoDataWhenFilterIsEmpty) {
            return []
        }
        return data?.filter((dataset) => {
            return Object.values(dataset).filter((value: any) => {
                switch (typeof value) {
                    case "string":
                        return value.toLowerCase()?.includes(filter.toLowerCase())
                    case "number":
                        return value.toString().includes(filter.toLowerCase())
                    case "object":
                        return JSON.stringify(value).toLowerCase().includes(filter.toLowerCase())
                    default:
                        return false
                }
            }).length > 0;
        })
    }

    function sortData(data: TableRowDataType<RowDataType>[] | RowDataType[]): void {
        if (!sortKey) {
            return
        }
        data?.sort((a: TableRowDataType<RowDataType>, b: TableRowDataType<RowDataType>) => {
            const valueA = JsonUtils.getValueFromObject(sortKey, a);
            const valueB = JsonUtils.getValueFromObject(sortKey, b);
            let sortValueA: number | string;
            let sortValueB: number | string;
            if (typeof valueA === 'number' && typeof valueB === 'number') {
                sortValueA = valueA;
                sortValueB = valueB;
            } else {
                sortValueA = valueA?.toString()?.toLowerCase() || '';
                sortValueB = valueB?.toString()?.toLowerCase() || '';
            }
            if (sortValueA < sortValueB) {
                return sortDirection === "asc" ? -1 : 1;
            }
            if (sortValueA > sortValueB) {
                return sortDirection === "asc" ? 1 : -1;
            }
            return 0;
        });
    }

    function showPage(page: number) {
        if (page < 1) page = 1
        if (page > totalPages) page = totalPages
        setCurrentPage(page)
    }

    function increaseVisibleRows() {
        setCurrentPage(1)
        setCurrentVisibleRows(currentVisibleRows + props.visibleRows)
    }

    function showHeader() {
        if (props.style === "leaderboard-compact" || props.showHeader === false) return false
        return visibleData && visibleData.length > 0
    }

    function showUI() {
        if (props.showPagination == false) return false
        return props.showFilter || totalPages > 1
    }

    function updateSorting(sortKey: string, sortDirection: SortDirectionType) {
        setSortKey(sortKey)
        setSortDirection(sortDirection)
    }

    function hideLowPriorityColumns() {
        return tableWidth < props.lowPriorityMinWidth
    }

    function computeTableMinWidth() {
        if (!props.columnsDefinition) {
            setMinTableWidth(null)
            return
        }
        let width = 0
        props.columnsDefinition?.forEach(column => {
            let size = 0;
            if (column.size?.includes("fr")) {
                size = parseInt(column.size.replace("fr", "")) * 75
            }
            if (column.size?.includes("px")) {
                size = parseInt(column.size.replace("px", ""))
            }
            width += size
        })
        setMinTableWidth(width)
    }

    function onTouchStarted(e: React.TouchEvent<HTMLDivElement>) {
        const stop = scrollRef.current.scrollLeft > 0
        setStopTouchProgagation(stop)
        if (!stop) return
        e.stopPropagation()
    }

    function onTouchMoved(e: React.TouchEvent<HTMLDivElement>) {
        if (!stopTouchProgagation) return
        e.stopPropagation()
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <section
            className="table"
            ref={tableRef}
            data-scrollable={isScrollable}
            data-scroll-container-type={props.scrollContainerType ?? "content"}
            data-style={props.style ?? "standard"}
            onTouchStartCapture={onTouchStarted}
            onTouchMoveCapture={onTouchMoved}>
            {showUI() && <div
                className="table-ui"
                data-disabled={props.isLoadingData}
                data-has-filter={props.showFilter}
                data-has-pages={totalPages > 1}>
                {props.showFilter && <TableFilter onChange={setFilter}/>}
                {totalPages > 1 && <PaginationControl
                    numEntries={processedData?.length}
                    currentPage={currentPage}
                    numPages={totalPages}
                    onPageChange={(page) => showPage(page)}
                />}
            </div>}
            <div className="table-main" ref={scrollRef}>
                {showHeader() &&
                    <TableHeader
                        gridTemplateColumns={tableTemplateColumns}
                        columnsDefinition={props.columnsDefinition}
                        activeSortKey={sortKey}
                        minWidth={isScrollable ? minTableWidth : null}
                        activeSortDirection={sortDirection}
                        hideLowPriorityColumns={hideLowPriorityColumns()}
                        disabled={props.isLoadingData}
                        onHeaderCellClick={updateSorting}/>}
                <TableBody<RowDataType, GlobalDataType>
                    rowsData={visibleData}
                    globalData={props.globalData}
                    style={props.style}
                    onRowClick={props.onRowClick}
                    noDataInfo={props.noDataInfo}
                    showLoadingData={props.isLoadingData}
                    hideLowPrioColumns={hideLowPriorityColumns()}
                    gridTemplateColumns={tableTemplateColumns}
                    columnsDefinition={props.columnsDefinition}/>
            </div>
            {!props.isLoadingData && totalPages > 1 &&
                <TableFooter
                    onIncreaseVisibleRows={increaseVisibleRows}/>}
        </section>
    );

}
