import * as React from "react";
import {ValidationIndicatorElement} from "./ValidationIndicatorElement";
import {useServices} from "../../../hooks/useServices";
import {PasswordValidation} from "../../../../../shared/validation/PasswordValidation";
import {ContentLayout} from "../../layout/content/ContentLayout";

/******************************************************************
 * PasswordValidationIndicator
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function PasswordValidationIndicator(props: {
    password: string
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict} = useServices()

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout className="password-validation-indicator" gap="tiny">
            <ValidationIndicatorElement
                label={dict("password.validation.length.label")}
                valid={PasswordValidation.LENGTH_REGEXP.test(props.password)}/>
            <ValidationIndicatorElement
                label={dict("password.validation.uppercaseLetter.label")}
                valid={PasswordValidation.UPPERCASE_LETTER_REGEXP.test(props.password)}/>
            <ValidationIndicatorElement
                label={dict("password.validation.lowercaseLetter.label")}
                valid={PasswordValidation.LOWERCASE_LETTER_REGEXP.test(props.password)}/>
            <ValidationIndicatorElement
                label={dict("password.validation.number.label")}
                valid={PasswordValidation.NUMBER_REGEXP.test(props.password)}/>
        </ContentLayout>
    );

}
