import * as React from "react";
import {useEffect} from "react";
import {IGroupData} from "../../../../../../shared/models/IGroupData";
import {Picture} from "../../../image/Picture";
import {Tooltip} from "../../../tooltip/Tooltip";
import {useServices} from "../../../../hooks/useServices";

/******************************************************************
 * GroupMarker
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function GroupMarker(props: {
    group: IGroupData
    markerElement: HTMLElement
}) {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const rootRef = React.useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {state} = useServices()

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        function bringMarkerToFront() {
            if (!props.markerElement) return
            props.markerElement.style.zIndex = "1"
        }

        function resetMarkerZIndex() {
            if (!props.markerElement) return
            props.markerElement.style.zIndex = null
        }

        const markerElement = props.markerElement
        markerElement?.addEventListener("mouseover", bringMarkerToFront)
        markerElement?.addEventListener("mouseout", resetMarkerZIndex)
        return () => {
            markerElement?.removeEventListener("mouseover", bringMarkerToFront)
            markerElement?.removeEventListener("mouseout", resetMarkerZIndex)
        }
    }, [props.markerElement])

    function showGroupPreview() {
        state.showGroupPreview.setValue(props.group._id)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="group-marker"
            ref={rootRef}
            onClick={showGroupPreview}>
            <Picture
                file={props.group.avatar}
                fit="cover"
                defaultAspectRatio={1}
                framing="circle"/>
            <Tooltip
                label={props.group.name}
                positionMode="size"
                hoverElement={props.markerElement}/>
        </div>
    );

}
