import * as React from "react";
import {Icon} from "../../icons/Icon";
import {Badge} from "../../info/badge/Badge";

/******************************************************************
 * SidebarButton
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function SidebarButton(props: {
    label: string
    selected?: boolean
    useMobileStyle?: boolean
    badgeLabel?: string
    badgeColor?: "white" | "red"
    onClick: (label: string) => void
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <button
            className="sidebar-button"
            type="button"
            data-use-mobile-style={props.useMobileStyle}
            data-selected={props.selected}
            data-has-badge={!!props.badgeLabel}
            onClick={() => props.onClick(props.label)}>
            {props.label}
            {props.badgeLabel &&
                <Badge
                    label={props.badgeLabel}
                    size="small"
                    color={props.badgeColor ?? "white"}/>}
            {props.useMobileStyle &&
                <Icon type="next" scale={0.8}/>}
        </button>
    );

}
