import * as React from "react";

/******************************************************************
 * AvgIcon
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function AvgIcon(props: {
    scale?: number
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="avg-icon">
            <svg width={15 * (props.scale ?? 1)} height={15 * (props.scale ?? 1)} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.36241 1.37514C7.92572 1.26878 7.46945 1.2124 7.00002 1.2124C3.83145 1.2124 1.26282 3.78103 1.26282 6.9496C1.26282 7.39191 1.31287 7.82253 1.40762 8.2361M6.01422 12.6024C6.3345 12.6579 6.66388 12.6868 7.00002 12.6868C10.1686 12.6868 12.7372 10.1182 12.7372 6.9496C12.7372 6.67434 12.7178 6.40361 12.6804 6.1387" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                <line x1="1.43521" y1="12.9598" x2="13.1374" y2="1.25758" stroke="white" strokeWidth="2" strokeLinecap="round"/>
            </svg>
        </div>
    );

}
