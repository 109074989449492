import * as React from "react";
import {IconButton} from "../buttons/icon/IconButton";
import {Badge} from "../info/badge/Badge";

/******************************************************************
 * PaginationControl
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function PaginationControl(props: {
    numEntries: number
    numPages: number
    currentPage: number
    onPageChange: (page: number) => void
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="pagination-control">
            <div className="pagination-control-ui">
                <IconButton
                    type="prev"
                    disabled={!(props.currentPage > 1)}
                    onClick={() => props.onPageChange(props.currentPage - 1)}/>
                <div className="pagination-control-pageinfo">
                    {props.currentPage + " / " + props.numPages}
                </div>
                <IconButton
                    type="next"
                    disabled={!(props.currentPage < props.numPages)}
                    onClick={() => props.onPageChange(props.currentPage + 1)}/>
            </div>
            <Badge label={props.numEntries?.toString() ?? ""}/>
        </div>
    );

}
