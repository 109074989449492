import * as React from "react";
import {LabelButton} from "../label/LabelButton";
import {useServices} from "../../../hooks/useServices";

/******************************************************************
 * HelpButton
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function HelpButton(props: {
    helpTopic: string
}) {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {dict, state} = useServices();

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function onClicked(e: MouseEvent) {
        e.preventDefault();
        state.showHelp.setValue(props.helpTopic);
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return <>
        {props.helpTopic &&
            <LabelButton
                label={dict("form.input.help.label")}
                style="small-link"
                onClick={onClicked}/>}
    </>

}
