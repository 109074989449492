import * as React from "react";
import {ReactElement} from "react";
import {IStintLapData} from "../../../../../../shared/types/IStintLapData";
import {TimeUtils} from "../../../../../../shared/utils/TimeUtils";
import {useSessionState} from "../../../../hooks/useSessionState";
import {StintUtils} from "../../../../../../shared/utils/StintUtils";

/******************************************************************
 * TableStintLapSectorTimesCell
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function TableStintLapSectorTimesCell(props: {
    lap: IStintLapData
}) {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {sessionNumSectors} = useSessionState()

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function sectorElements(): ReactElement[] {
        const elements: ReactElement[] = [];
        const times = props.lap.splitTimes;
        for (let i = 0; i < sessionNumSectors; i++) {
            let time = "–";
            if (times
                && times[i] !== undefined
                && times.length == sessionNumSectors
                && StintUtils.isLapValid(props.lap)) {
                time = TimeUtils.formatDrivenTime(times[i]);
            }
            elements.push(
                <div key={i} className="table-stint-lap-sector-times-cell-sector">
                    {time}
                </div>
            );
        }
        return elements
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="table-stint-lap-sector-times-cell">
            {sectorElements()}
        </div>
    );

}
