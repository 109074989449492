import * as React from "react";
import {ContentLayout} from "../../../../ui/layout/content/ContentLayout";
import {LabelButton} from "../../../../ui/buttons/label/LabelButton";
import {useServices} from "../../../../hooks/useServices";
import {useDeviceInfo} from "../../../../hooks/useDeviceInfo";
import {Divider} from "../../../../ui/utils/divider/Divider";
import {Spacer} from "../../../../ui/utils/spacer/Spacer";

/******************************************************************
 * HomeMainFooter
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function HomeMainFooter() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {state, dict} = useServices();
    const {isMobile} = useDeviceInfo();

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout className="home-main-footer">
            <Spacer height="large"/>
            <Divider style="modal"/>
            <ContentLayout
                columnTemplate={isMobile ? "1fr" : "min-content min-content"}
                justifyContent="space-between"
                alignItems="center">
                <ContentLayout
                    columnTemplate="repeat(3, min-content)"
                    alignItems="center"
                    justifyContent={isMobile ? "center" : null}>
                    <LabelButton
                        label={dict("meta.imprint.termsofuse")}
                        style="link"
                        onClick={() => state.showArticle.setValue(dict("article.id.termsofuse"))}/>
                    <LabelButton
                        label={dict("meta.imprint.title")}
                        style="link"
                        onClick={() => state.showArticle.setValue(dict("article.id.imprint"))}/>
                    <LabelButton
                        label={dict("meta.privacy.title")}
                        style="link"
                        onClick={() => state.showArticle.setValue(dict("article.id.privacy"))}/>
                </ContentLayout>
                {!isMobile &&
                    <LabelButton
                        label={dict("home.nav.login")}
                        style="action-main"
                        onClick={() => state.showLogin.setValue("login")}/>}
            </ContentLayout>
        </ContentLayout>
    );

}
