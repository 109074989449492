import {APIRoute} from "../../shared/routes/APIRoute";
import {FrontendServices} from "../core/FrontendServices";

/******************************************************************
 * TranslateAPI
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class TranslateAPI {

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(private _frontend: FrontendServices) {
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public async text(text: string, sourceLanguage: string, targetLanguage: string): Promise<string> {
        const response = await this._frontend.api.request("POST", APIRoute.TRANSLATE, {
            text,
            sourceLanguage,
            targetLanguage
        }, true)
        if (response.status == 200) {
            const body = await response.json();
            return body.result;
        }
        return null;
    }

}
