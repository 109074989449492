import * as React from "react";
import {useState} from "react";
import {useGroupState} from "../../../../hooks/useGroupState";
import {useServices} from "../../../../hooks/useServices";
import {ContentLayout} from "../../../../ui/layout/content/ContentLayout";
import {Icon} from "../../../../ui/icons/Icon";
import {useAuthUser} from "../../../../hooks/useAuthUser";
import {FavoriteButton} from "../../../../ui/buttons/favorite/FavoriteButton";
import {PinComponent} from "../../../../ui/utils/observer/PinComponent";
import {LanguageUtils} from "../../../../../../shared/utils/LanguageUtils";
import {LabelButton} from "../../../../ui/buttons/label/LabelButton";
import {MapLocationModal} from "../../../modals/map/MapLocationModal";
import {ILocationData} from "../../../../../../shared/types/ILocationData";
import {PrivacySelector} from "../../../../ui/context/privacy/PrivacySelector";
import {MarkerFactory} from "../../../../ui/map/marker/MarkerFactory";

/******************************************************************
 * GroupSidebarInfo
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function GroupSidebarInfo() {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {dict, language, api, state} = useServices()
    const {groupData, groupID, isGroupAdmin, groupName, groupLocation, groupType} = useGroupState()
    const {authUserID} = useAuthUser()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [showMap, setShowMap] = useState<boolean>(false)

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function isOnlineGroup() {
        if (!groupType) {
            if (groupLocation) {
                return false
            }
        }
        return groupType == "online"
    }

    function hasLocation() {
        return groupLocation && groupLocation.place
    }

    async function updateLocation(value: ILocationData): Promise<Response> {
        const response = await api.group.update(groupID, {location: value})
        setShowMap(false)
        return response
    }

    function showMapModal() {
        if (!groupLocation) return
        state.showMapLocation.setValue({
            type: "group",
            location: groupLocation,
            markers: [MarkerFactory.createGroupMarker(groupData)]
        })
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return <>

        <PinComponent className="group-sidebar-info">
            <h2>{groupName}</h2>
            <ContentLayout
                className="group-sidebar-info-secondary"
                columnTemplate={"min-content 1fr min-content"}
                alignItems="center"
                gap="tiny">
                {isOnlineGroup()
                    ? <Icon type="online" scale={0.8}/>
                    : <Icon type="location" scale={0.5}/>}
                {isOnlineGroup() &&
                    <h3>{dict("group.type.online")}</h3>}
                {!isOnlineGroup() && (!isGroupAdmin || hasLocation()) &&
                    <h3
                        className={hasLocation() ? "group-sidebar-info-location-clickable" : ""}
                        onClick={showMapModal}>
                        {LanguageUtils.getValueFromMultilangString(groupLocation?.place, language) ?? "–"}
                    </h3>}
                {isGroupAdmin && !hasLocation() && !isOnlineGroup() &&
                    <ContentLayout justifyItems={"start"}>
                        <LabelButton
                            label={dict("group.reselect.location.button.label")}
                            style={"primary-tiny"}
                            onClick={() => setShowMap(true)}/>
                    </ContentLayout>}
                <ContentLayout
                    className="group-sidebar-info-right-icons"
                    columnTemplate={authUserID ? "min-content min-content" : null} gap="none">
                    <PrivacySelector/>
                    {authUserID &&
                        <div style={{position: "relative", top: -1}}>
                            <FavoriteButton favoriteID={groupID} type={"group"}/>
                        </div>}
                </ContentLayout>
            </ContentLayout>
        </PinComponent>
        {showMap &&
            <MapLocationModal
                type="group"
                location={groupLocation}
                requestClose={() => setShowMap(false)}
                requestSave={updateLocation}/>}
    </>

}
