import * as React from "react";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {InfoText} from "../../../../../ui/text/infotext/InfoText";
import {useServices} from "../../../../../hooks/useServices";

/******************************************************************
 * MailVerficiationBodyBlock
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function MailVerficiationBodyBlock() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict} = useServices()

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout>
            <InfoText
                size={"small"}
                align={"center"}
                text={dict("mail.body.block.verificationCode.info")}/>
        </ContentLayout>
    );

}
