import * as React from "react";
import {useEffect} from "react";
import {MetaBarSystem} from "../../../systems/metabar/MetaBarSystem";
import {SideBarSystem} from "../../../systems/sidebar/SideBarSystem";
import {MainFrameSystem} from "../../../systems/main/MainFrameSystem";
import {useDeviceInfo} from "../../../hooks/useDeviceInfo";
import {useServices} from "../../../hooks/useServices";
import {useSwipeable} from "react-swipeable";
import {SwipeEventData} from "react-swipeable/src/types";
import {useRouteStates} from "../../../hooks/useRouteStates";
import {FrontendConfig} from "../../../../core/FrontendConfig";
import {AppLayoutResizer} from "./resizer/AppLayoutResizer";

/******************************************************************
 * AppLayout
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function AppLayout() {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {state} = useServices()
    const {isMobile} = useDeviceInfo()
    const {showSideBar} = useRouteStates()

    const swipeableHandlers = useSwipeable({
        onSwiping: liveSwipingMenu,
        onSwiped: toggleMobileMenu,
        onSwipeStart: setSwipeDirection,
        preventScrollOnSwipe: false,
        trackTouch: true,
        trackMouse: false,
    });

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [isSwipingHorizontal, setIsSwipingHorizontal] = React.useState<boolean>(false)

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        window.addEventListener("touchcancel", closeMobileMenu)
        return () => {
            window.removeEventListener("touchcancel", closeMobileMenu)
        }
    }, [])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function setSwipeDirection(e: SwipeEventData) {
        setIsSwipingHorizontal(e.dir === "Left" || e.dir === "Right")
    }

    function toggleMobileMenu(e: SwipeEventData) {
        state.swipingMobileMenuDeltaX.setValue(0)
        if (!isMobile) return
        if (!isSwipingHorizontal) return
        if (Math.abs(e.deltaX) < FrontendConfig.SWIPE_DELTA) return
        if (e.dir === 'Left') {
            state.showMobileMenu.setValue(false)
        }
        if (e.dir === 'Right') {
            state.showMobileMenu.setValue(true)
        }
    }

    function liveSwipingMenu(e: SwipeEventData) {
        if (e.event?.target?.['className'] === "mapboxgl-canvas") return
        if (!isMobile) return
        if (!isSwipingHorizontal) return
        state.swipingMobileMenuDeltaX.setValue(e.deltaX)
    }

    function closeMobileMenu() {
        state.showMobileMenu.setValue(false)
        state.swipingMobileMenuDeltaX.setValue(0)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="app-layout"
            data-show-sidebar={showSideBar}
            {...swipeableHandlers}>
            <MetaBarSystem/>
            <SideBarSystem/>
            {showSideBar && !isMobile &&
                <AppLayoutResizer/>}
            <MainFrameSystem/>
        </div>
    );

}
