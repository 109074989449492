import * as React from "react";
import {IStintLapData} from "../../../../../../shared/types/IStintLapData";
import {TimeUtils} from "../../../../../../shared/utils/TimeUtils";
import {IStintData} from "../../../../../../shared/models/IStintData";
import {StintUtils} from "../../../../../../shared/utils/StintUtils";

/******************************************************************
 * TableStintLapTimeCell
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function TableStintLapTimeCell(props: {
    lap: IStintLapData
    stint: IStintData
}) {

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function lapTime() {
        if (!props.lap?.time) {
            return "–"
        }
        if (!StintUtils.isLapValid(props.lap)) {
            return "(" + TimeUtils.formatDrivenTime(props.lap.time) + ")"
        }
        return TimeUtils.formatDrivenTime(props.lap.time)
    }

    function isBestLapTime(): boolean {
        return TimeUtils.isBestLapTime(props.lap, props.stint.computedLaps)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="table-stint-lap-time-cell"
            data-is-best-lap={isBestLapTime()}>
            {lapTime()}
        </div>
    );

}
