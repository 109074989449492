import * as React from "react";
import {ContentLayout} from "../../../../ui/layout/content/ContentLayout";
import {TabbedContent} from "../../../../ui/layout/tabbed/TabbedContent";
import {LogViewer} from "./log/LogViewer";
import {useServices} from "../../../../hooks/useServices";

/******************************************************************
 * AdminLogs
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function AdminLogs() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict} = useServices()

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout className="admin-logs">
            <TabbedContent>
                <LogViewer
                    tabbedContent={{name: dict("admin.logs.drift.title")}}
                    logType="drift"/>
                <LogViewer
                    tabbedContent={{name: dict("admin.logs.backend.title")}}
                    logType="backend"/>
                <LogViewer
                    tabbedContent={{name: dict("admin.logs.mail.title")}}
                    logType="mail"/>
                <LogViewer
                    tabbedContent={{name: dict("admin.logs.paddle.title")}}
                    logType="paddle"/>
                <LogViewer
                    tabbedContent={{name: dict("admin.logs.frontend.title")}}
                    logType="frontend"/>
            </TabbedContent>
        </ContentLayout>
    );

}
