import * as React from "react";

/******************************************************************
 * PersonIcon
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function PersonIcon(props: {
    scale?: number
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="person-icon">
            <svg width={20 * (props.scale ?? 1)} height={18 * (props.scale ?? 1)} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.9938 2.52266C12.2336 1.70195 11.1719 1.25 10 1.25C8.82188 1.25 7.75665 1.69922 7.00001 2.51484C6.23516 3.33945 5.86251 4.46016 5.95001 5.67031C6.12344 8.05781 7.94024 10 10 10C12.0598 10 13.8734 8.0582 14.0496 5.67109C14.1383 4.47187 13.7633 3.35352 12.9938 2.52266Z" fill="white"/>
                <path d="M16.875 18.75H3.12501C2.94503 18.7523 2.7668 18.7145 2.60328 18.6393C2.43975 18.5641 2.29505 18.4534 2.1797 18.3152C1.92579 18.0117 1.82345 17.5973 1.89923 17.1781C2.22892 15.3492 3.25782 13.8129 4.87501 12.7344C6.31173 11.777 8.13165 11.25 10 11.25C11.8684 11.25 13.6883 11.7773 15.125 12.7344C16.7422 13.8125 17.7711 15.3488 18.1008 17.1777C18.1766 17.5969 18.0742 18.0113 17.8203 18.3148C17.705 18.4531 17.5603 18.5639 17.3968 18.6391C17.2333 18.7144 17.055 18.7523 16.875 18.75Z" fill="white"/>
            </svg>
        </div>
    );

}
