import * as React from "react";

/******************************************************************
 * Spacer
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function Spacer(props: {
    height:
        | "modal-actionbar"
        | "modal-footer"
        | "normal"
        | "small"
        | "large"
        | "extra-large"
        | "footer-scroll"
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="spacer"
            data-height={props.height}/>
    );

}
