import * as React from "react";

/******************************************************************
 * StarIcon
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function StarIcon(props: {
    scale?: number
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="star-icon">
            <svg width={16 * (props.scale ?? 1)} height={16 * (props.scale ?? 1)} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.5995 15.4164C12.4872 15.4168 12.3777 15.3818 12.2865 15.3163L7.99917 12.2081L3.71186 15.3163C3.62027 15.3828 3.50992 15.4184 3.39677 15.418C3.28362 15.4175 3.17353 15.3812 3.08242 15.3141C2.99131 15.247 2.9239 15.1526 2.88993 15.0447C2.85597 14.9368 2.85721 14.8208 2.89347 14.7136L4.56559 9.76095L0.231949 6.78907C0.138079 6.72477 0.0672314 6.63214 0.0297578 6.5247C-0.00771591 6.41727 -0.00985196 6.30067 0.0236618 6.19194C0.0571755 6.0832 0.124583 5.98804 0.216034 5.92034C0.307486 5.85264 0.418189 5.81597 0.53197 5.81567H5.87835L7.4918 0.850314C7.52656 0.743114 7.59438 0.649677 7.68553 0.583409C7.77668 0.51714 7.88648 0.481445 7.99917 0.481445C8.11187 0.481445 8.22166 0.51714 8.31281 0.583409C8.40396 0.649677 8.47178 0.743114 8.50654 0.850314L10.12 5.81734H15.4664C15.5803 5.81728 15.6912 5.8537 15.783 5.92127C15.8747 5.98883 15.9424 6.08399 15.9761 6.19281C16.0098 6.30163 16.0078 6.41838 15.9704 6.52598C15.933 6.63357 15.862 6.72636 15.7681 6.79074L11.4327 9.76095L13.1039 14.7123C13.1309 14.7925 13.1386 14.8779 13.1261 14.9616C13.1136 15.0453 13.0814 15.1248 13.0321 15.1936C12.9829 15.2624 12.9179 15.3185 12.8427 15.3572C12.7675 15.3959 12.6841 15.4162 12.5995 15.4164Z" fill="white"/>
            </svg>
        </div>
    );

}
