import * as React from "react";
import ReactMarkdown from "react-markdown";

/******************************************************************
 * LabelValueText
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function LabelValueText(props: {
    label: string
    value: string
    onClick?: () => void
    stopPropagation?: boolean
    size?: "normal" | "small"
    warning?: boolean
}) {

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function onClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        if (props.stopPropagation) {
            event.stopPropagation()
        }
        props.onClick?.()
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="label-value-text"
            data-clickable={!!props.onClick}
            data-warning={props.warning}
            data-size={props.size ?? "normal"}
            onClick={onClick}>
            <div className="label-value-text-label">{props.label}</div>
            <ReactMarkdown className="label-value-text-value">{props.value}</ReactMarkdown>
        </div>
    );

}
