import * as React from "react";
import {Overlay} from "../Overlay";
import {useServices} from "../../../hooks/useServices";
import {InfoText} from "../../../ui/text/infotext/InfoText";
import {Headline} from "../../../ui/text/headings/Headline";
import {ContentLayout} from "../../../ui/layout/content/ContentLayout";
import {ProgressingAnimation} from "../../../ui/utils/progress/ProgressingAnimation";

/******************************************************************
 * SocketDisconnectedMessage
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function SocketDisconnectedOverlay() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict} = useServices();

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Overlay className="socket-disconnected-overlay">
            <ContentLayout justifyItems="center">
                <Headline text={dict("socket.disconnected.overlay.message")} style="h1-overlay"/>
                <ProgressingAnimation/>
                <InfoText text={dict("socket.disconnected.overlay.info")} align="center"/>
            </ContentLayout>
        </Overlay>
    );

}
