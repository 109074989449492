import * as React from "react";
import {useState} from "react";
import {Input} from "../elements/input/Input";
import {Form} from "../Form";
import {useServices} from "../../../hooks/useServices";
import {PasswordValidationIndicator} from "../validation/PasswordValidationIndicator";
import {ContentLayout} from "../../layout/content/ContentLayout";

/******************************************************************
 * ForgotPasswordForm
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function ForgotPasswordForm() {

    /* ----------------------------------------------------------------
 	 * CORE
 	 * --------------------------------------------------------------*/

    const {state, api, dict} = useServices();

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [step, setStep] = useState<"requestVerificationCode" | "setNewPassword">("requestVerificationCode");
    const [usedMail, setUsedMail] = useState<string>();
    const [password, setPassword] = useState<string>();

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    async function showNextStep(formData) {
        setUsedMail(formData.email);
        setStep("setNewPassword");
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return <>
        {step === "requestVerificationCode"
            ? <Form
                actionLabel={dict("form.forgotpassword.verificationcode.title")}
                action={(formData) => api.user.requestVerificationCode(formData)}
                onSuccess={async (formData) => showNextStep(formData)}>
                <Input
                    type="email"
                    id="email"
                    label={dict("form.element.mail.label")}
                    required={true}/>
            </Form>
            : <Form
                actionLabel={dict("form.forgotpassword.title")}
                action={formData => api.user.changePassword({...formData, email: usedMail})}
                onSuccess={async () => {
                    state.showForgotPassword.setValue(false)
                    state.showLogin.setValue("login")
                }}>
                <Input
                    id="code"
                    type="text"
                    label={dict("form.element.forgotpassword.verificationcode.label")}/>
                <ContentLayout gap="small">
                    <Input
                        type="password"
                        id="password"
                        label={dict("form.element.forgotpassword.label")}
                        placeholder={dict("form.element.forgotpassword.placeholder")}
                        required={true}
                        onChange={setPassword}/>
                    <PasswordValidationIndicator password={password ?? ""}/>
                </ContentLayout>
            </Form>
        }
    </>

}
