import * as React from "react";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {LabelButton} from "../../../../../ui/buttons/label/LabelButton";
import {Headline} from "../../../../../ui/text/headings/Headline";
import {useSimulatorStore} from "../hooks/useSimulatorStore";
import {SimulatorDriver} from "./card/SimulatorDriver";
import {useServices} from "../../../../../hooks/useServices";
import {ISessionData} from "../../../../../../../shared/models/ISessionData";
import {ISimulatorDriverData} from "./card/ISimulatorDriverData";
import {ICapiPingData} from "../../../../../../../shared/types/ICapiPingData";
import {useSimulatorSignals} from "../hooks/useSimulatorSignals";

/******************************************************************
 * AdminSimulatorDrivers
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function AdminSimulatorDrivers(props: {
    session: ISessionData
    ping: ICapiPingData
    isMobileMode: boolean
}) {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {state} = useServices()
    const {startAllDrivers} = useSimulatorSignals()
    const {
        simulationDrivers, setSimulationDrivers,
        gameID,
        timeScale,
        minLapTime,
        maxLapTime,
        sectors,
        sectorTargetCodes,
        finishFailureRate,
        sectorsFailureRate
    } = useSimulatorStore()

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    async function addDriver() {
        state.showSelectDrivers.setValue(true)
    }

    function syncSessionSetup() {
        if (!props.session) return
        setSimulationDrivers(simulationDrivers.map(driver => {
            const data: ISimulatorDriverData = {...driver}
            data.gameID = gameID
            data.gameID = gameID
            data.timeScale = timeScale
            data.minLapTime = minLapTime
            data.maxLapTime = maxLapTime
            data.mode = props.session.setup.mode == "gymkhana" ? "GYMKHANA" : "RACE"
            data.sectors = sectors
            data.sectorTargetCodes = sectorTargetCodes
            data.finishFailureRate = finishFailureRate
            data.sectorsFailureRate = sectorsFailureRate
            data.falseStart = driver.falseStart ?? false
            data.laps = props.ping.lap_count
            data.setup = props.ping.setup_mode
            data.trackCondition = props.ping.track_condition
            data.tires = props.ping.wheels
            data.trackBundle = props.ping.track_bundle
            data.startTime = props.ping.start_time
            data.startDelay = props.ping.start_delay
            data.carName = driver.carName ?? "Simulator V8"
            data.carEngine = driver.carEngine ?? "V8"
            data.carTuning = driver.carTuning ?? "club"
            return data
        }))
    }

    function startAll() {
        startAllDrivers.dispatch()
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <section
            className="admin-simulator-drivers">
            <div className="admin-simulator-drivers-header">
                <Headline
                    text="Drivers"
                    style="modal"/>
                <div className="admin-simulator-drivers-header-buttons">
                    <LabelButton
                        label={props.isMobileMode ? null : "Sync All"}
                        icon="sync"
                        iconScale={0.8}
                        style="primary-small"
                        disabled={props.session == null}
                        onClick={syncSessionSetup}/>
                    <LabelButton
                        label={props.isMobileMode ? null : "Start All"}
                        icon="next"
                        iconScale={0.8}
                        style="primary-small"
                        onClick={startAll}
                        disabled={props.session == null}/>
                    <LabelButton
                        label={props.isMobileMode ? null : "Add Driver"}
                        icon="plus"
                        iconScale={0.8}
                        style="primary-small"
                        onClick={addDriver}/>
                </div>
            </div>
            <ContentLayout
                columns={1}
                alignItems="end">
                {simulationDrivers?.map((driverData, i) => {
                    return <SimulatorDriver
                        key={i}
                        driver={driverData}
                        session={props.session}
                        ping={props.ping}
                        isMobileMode={props.isMobileMode}/>
                })}
            </ContentLayout>
        </section>
    );
}
