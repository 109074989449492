import * as React from "react";
import {useEffect, useState} from "react";
import {ContentLayout} from "../../../../../../ui/layout/content/ContentLayout";
import {ISimulatorDriverData} from "./ISimulatorDriverData";
import {UserInfo} from "../../../../../../ui/info/user/UserInfo";
import {IconButton} from "../../../../../../ui/buttons/icon/IconButton";
import {useSimulatorStore} from "../../hooks/useSimulatorStore";
import {ISessionData} from "../../../../../../../../shared/models/ISessionData";
import {SimulatorDriverProperty} from "./prop/SimulatorDriverProperty";
import {ICapiPingData} from "../../../../../../../../shared/types/ICapiPingData";
import {useSimulatorSignals} from "../../hooks/useSimulatorSignals";
import {useSimulatorComputer} from "../../hooks/useSimulatorComputer";

/******************************************************************
 * SimulatorDriver
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function SimulatorDriver(props: {
    driver: ISimulatorDriverData
    session: ISessionData
    ping: ICapiPingData
    isMobileMode: boolean
}) {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const [driverData, setDriverData] = useState<ISimulatorDriverData>(props.driver)
    const {runSimulator, endSimulator} = useSimulatorComputer({driver: driverData})
    const {startAllDrivers} = useSimulatorSignals()
    const {
        simulationDrivers, setSimulationDrivers,
        gameID,
        timeScale,
        minLapTime,
        maxLapTime,
        sectors,
        sectorTargetCodes,
        finishFailureRate,
        sectorsFailureRate
    } = useSimulatorStore()

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        return () => startAllDrivers.remove(runSimulator)
    }, [])

    useEffect(() => {
        startAllDrivers.remove(runSimulator)
        if (gameID) {
            startAllDrivers.add(runSimulator)
        }
    }, [gameID])

    useEffect(() => {
        if (!simulationDrivers) return
        setDriverData(simulationDrivers.filter(driver => {
            return driver.user._id == props.driver.user._id
        })[0])
    }, [simulationDrivers])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function syncWithSession() {
        const data: ISimulatorDriverData = {...props.driver}
        data.gameID = gameID
        data.timeScale = timeScale
        data.minLapTime = minLapTime
        data.maxLapTime = maxLapTime
        data.sectors = sectors
        data.sectorTargetCodes = sectorTargetCodes
        data.falseStart = props.driver.falseStart ?? false
        data.mode = props.session.setup.mode == "gymkhana" ? "GYMKHANA" : "RACE"
        data.finishFailureRate = finishFailureRate
        data.sectorsFailureRate = sectorsFailureRate
        data.laps = props.ping.lap_count
        data.setup = props.ping.setup_mode ?? "RACE"
        data.trackCondition = props.ping.track_condition ?? "drift_asphalt"
        data.tires = props.ping.wheels ?? "normal"
        data.trackBundle = props.ping.track_bundle
        data.startTime = props.ping.start_time
        data.startDelay = props.ping.start_delay
        data.carName = props.driver.carName ?? "Simulator V8"
        data.carEngine = props.driver.carEngine ?? "V8"
        data.carTuning = props.driver.carTuning ?? "club"
        setSimulationDrivers(simulationDrivers.map(driver => {
            if (driver.user._id == props.driver.user._id) {
                return data
            } else {
                return driver
            }
        }))
    }

    function removeDriver() {
        const drivers = simulationDrivers.filter(driver => driver.user._id != props.driver.user._id)
        setSimulationDrivers(drivers)
    }

    function updateValue(key: string, value: string) {
        const data: ISimulatorDriverData = {...props.driver}
        data[key] = value
        if (value == "true" || value == "false") {
            data[key] = value == "true"
        }
        setSimulationDrivers(simulationDrivers.map(driver => {
            if (driver.user._id == props.driver.user._id) {
                return data
            } else {
                return driver
            }
        }))
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout className="simulator-driver">
            <header>
                <UserInfo
                    user={props.driver?.user}
                    clickToShowProfile={false}
                    style="normal"/>
                <div className="simulator-driver-header-buttons">
                    <IconButton
                        type="delete"
                        onClick={removeDriver}/>
                    <IconButton
                        type="sync"
                        disabled={props.session == null}
                        onClick={syncWithSession}/>
                    <IconButton
                        type="next"
                        disabled={props.session == null}
                        onClick={runSimulator}/>
                    <IconButton
                        type="flag"
                        disabled={props.session == null}
                        onClick={endSimulator}/>
                </div>
            </header>
            <main>
                <ContentLayout columns={props.isMobileMode ? 1 : 2}>
                    <div>
                        <SimulatorDriverProperty
                            label="GameID"
                            value={props.driver?.gameID}
                            onChange={value => updateValue("gameID", value)}/>
                        <SimulatorDriverProperty
                            label="GameMode"
                            value={props.driver?.mode}
                            onChange={value => updateValue("mode", value)}/>
                        <SimulatorDriverProperty
                            label="TimeScale"
                            value={props.driver?.timeScale?.toString()}
                            onChange={value => updateValue("timeScale", value)}/>
                        <SimulatorDriverProperty
                            label="MinLapTime"
                            value={props.driver?.minLapTime?.toString()}
                            onChange={value => updateValue("minLapTime", value)}/>
                        <SimulatorDriverProperty
                            label="MaxLapTime"
                            value={props.driver?.maxLapTime?.toString()}
                            onChange={value => updateValue("maxLapTime", value)}/>
                        <SimulatorDriverProperty
                            label="Sectors"
                            value={props.driver?.sectors?.toString()}
                            onChange={value => updateValue("sectors", value)}/>
                        <SimulatorDriverProperty
                            label="SectorTargetCodes"
                            value={props.driver?.sectorTargetCodes}
                            onChange={value => updateValue("sectorTargetCodes", value)}/>
                        <SimulatorDriverProperty
                            label="FalseStart"
                            value={props.driver?.falseStart?.toString()}
                            onChange={value => updateValue("falseStart", value)}/>
                        <SimulatorDriverProperty
                            label="FinishFailureRate"
                            value={props.driver?.finishFailureRate?.toString()}
                            onChange={value => updateValue("finishFailureRate", value)}/>
                        <SimulatorDriverProperty
                            label="SectorsFailureRate"
                            value={props.driver?.sectorsFailureRate?.toString()}
                            onChange={value => updateValue("sectorsFailureRate", value)}/>
                    </div>
                    <div>
                        <SimulatorDriverProperty
                            label="Laps"
                            value={props.driver?.laps?.toString()}
                            onChange={value => updateValue("laps", value)}/>
                        <SimulatorDriverProperty
                            label="Setup"
                            value={props.driver?.setup}
                            onChange={value => updateValue("setup", value)}/>
                        <SimulatorDriverProperty
                            label="Track Conditions"
                            value={props.driver?.trackCondition}
                            onChange={value => updateValue("trackCondition", value)}/>
                        <SimulatorDriverProperty
                            label="Tires"
                            value={props.driver?.tires}
                            onChange={value => updateValue("tires", value)}/>
                        <SimulatorDriverProperty
                            label="Track Bundle"
                            value={props.driver?.trackBundle}
                            onChange={value => updateValue("trackBundle", value)}/>
                        <SimulatorDriverProperty
                            label="Starttime"
                            value={props.driver?.startTime}
                            onChange={value => updateValue("startTime", value)}/>
                        <SimulatorDriverProperty
                            label="Starttime Delay"
                            value={props.driver?.startDelay?.toString()}
                            onChange={null}/>
                        <SimulatorDriverProperty
                            label="Car Name"
                            value={props.driver?.carName}
                            onChange={value => updateValue("carName", value)}/>
                        <SimulatorDriverProperty
                            label="Car Engine"
                            value={props.driver?.carEngine}
                            onChange={value => updateValue("carEngine", value)}/>
                        <SimulatorDriverProperty
                            label="Car Tuning"
                            value={props.driver?.carTuning}
                            onChange={value => updateValue("carTuning", value)}/>
                    </div>
                </ContentLayout>
            </main>
        </ContentLayout>
    );

}
