import * as React from "react";
import {useEffect, useState} from "react";
import {IconButton} from "../../../buttons/icon/IconButton";
import {InlineVerifyAction} from "../verify/InlineVerifyAction";
import {useDeviceInfo} from "../../../../hooks/useDeviceInfo";

/******************************************************************
 * InlineDeleteAction
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function InlineDeleteAction(props: {
    isBusy: boolean;
    size?: "small" | "normal";
    onSave: () => void;
}) {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {hasTouch} = useDeviceInfo()

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [verify, setVerify] = useState<boolean>(false);
    const [pointerEntered, setPointerEntered] = useState<boolean>(false);

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        if (!pointerEntered && !props.isBusy && !hasTouch) {
            setVerify(false)
        }
    }, [props.isBusy, pointerEntered])

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="inline-delete-action"
            data-verifying={verify}
            data-size={props.size ?? "normal"}
            onPointerEnter={() => setPointerEntered(true)}
            onPointerLeave={() => setPointerEntered(false)}>
            <div className="inline-delete-action-delete-button">
                <IconButton
                    type="delete"
                    size={props.size ?? "normal"}
                    stopPropagation={true}
                    onClick={() => setVerify(true)}/>
            </div>
            <InlineVerifyAction
                isBusy={props.isBusy}
                show={verify}
                reverse={true}
                onReset={() => setVerify(false)}
                onSave={props.onSave}
            />
        </div>
    );

}
