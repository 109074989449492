import * as React from "react";
import {useRef} from "react";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {Input} from "../../../../../ui/form/elements/input/Input";
import {useServices} from "../../../../../hooks/useServices";
import {useGroupState} from "../../../../../hooks/useGroupState";
import {PathValidation} from "../../../../../../../shared/validation/PathValidation";
import {FrontendRoute} from "../../../../../../../shared/routes/FrontendRoute";
import {SelectInput, SelectInputOptionType} from "../../../../../ui/form/elements/select/SelectInput";
import {GroupType} from "../../../../../../../shared/types/GroupType";
import {ILocationData} from "../../../../../../../shared/types/ILocationData";
import {PrivacyType} from "../../../../../../../shared/types/PrivacyType";
import {AvatarImage} from "../../../../../ui/image/avatar/AvatarImage";
import {useMobileStyle} from "../../../../../hooks/useMobileStyle";
import {MapInput} from "../../../../../ui/map/input/MapInput";

/******************************************************************
 * GroupGeneralSettings
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function GroupGeneralSettings() {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const ref = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, api, router} = useServices()
    const [isMobile] = useMobileStyle(ref, 560)
    const {
        groupID,
        groupData,
        groupName,
        groupPath,
        groupLocation,
        groupType,
        groupPrivacy
    } = useGroupState()

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    async function updateName(value: string): Promise<Response> {
        return await api.group.update(groupID, {name: value})
    }

    async function updateLocation(value: ILocationData): Promise<Response> {
        return await api.group.update(groupID, {location: value})
    }

    async function updateGroupType(value: GroupType): Promise<Response> {
        return await api.group.update(groupID, {type: value})
    }

    async function updateGroupPrivacy(value: PrivacyType): Promise<Response> {
        return await api.group.update(groupID, {privacy: value})
    }

    async function updatePath(newPath: string): Promise<Response> {
        const response = await api.group.update(groupID, {path: newPath});
        if (response.status == 200) {
            router.showRoute(FrontendRoute.GROUP(newPath))
        }
        return response
    }

    function groupTypeOptions(): SelectInputOptionType[] {
        return [{
            value: "local" as GroupType,
            text: dict("group.type.local")
        }, {
            value: "online" as GroupType,
            text: dict("group.type.online")
        }]
    }

    function groupPrivacyOptions(): SelectInputOptionType[] {
        return [{
            value: "public" as PrivacyType,
            text: dict("group.privacy.public")
        }, {
            value: "private" as PrivacyType,
            text: dict("group.privacy.private")
        }]
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return <>
        <ContentLayout
            ref={ref}
            className="group-general-settings"
            columnTemplate={isMobile ? null : "min-content 1fr"}>
            <ContentLayout>
                <AvatarImage
                    group={groupData}
                    mode="settings"
                    editable={true}/>
            </ContentLayout>
            <ContentLayout>
                <Input
                    label={dict("group.name.label")}
                    defaultValue={groupName}
                    required={true}
                    action={updateName}
                    type="text"/>
                <SelectInput
                    label={dict("group.type.label")}
                    required={true}
                    helpTopic={"group.type"}
                    defaultValue={groupType}
                    options={groupTypeOptions()}
                    onChange={updateGroupType}/>
                <SelectInput
                    label={dict("group.privacy.label")}
                    required={true}
                    helpTopic={"group.privacy"}
                    defaultValue={groupPrivacy}
                    options={groupPrivacyOptions()}
                    onChange={updateGroupPrivacy}/>
                {groupType == "local" &&
                    <MapInput
                        type={"group"}
                        location={groupLocation}
                        onSave={updateLocation}/>}
                <Input
                    label={dict("group.path.label")}
                    defaultValue={groupPath}
                    required={true}
                    pattern={PathValidation.REGEXP.source}
                    helpTopic="group.path.help"
                    showInvalid={true}
                    action={updatePath}
                    type="text"/>
            </ContentLayout>
        </ContentLayout>

    </>

}
